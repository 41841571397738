/**
 *  Imports Material UI
 */
import { makeStyles } from "@material-ui/core";

/**
 * Imports theme
 */
import { theme } from "../../themes/default";

/**
 * Styles the component
 */
const useStyles = makeStyles({
  cellHeader: (props) => {
    const { align } = props;

    const styles = {
      fontWeight: 800,
      color: theme.palette.sidebar.text,
      background: theme.palette.sidebar.main,
      fontSize: "0.8rem",
    };

    if (align) {
      styles["textAlign"] = `${align} !important`;
    }

    return styles;
  },
  small: {
    minWidth: 40,
  },
});

export { useStyles };

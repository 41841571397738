/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: "0.5rem"
  },
  field: theme.mixins.searchField,
  label: theme.mixins.searchFieldLabel,
  selectField: theme.mixins.searchSelect,
  btnContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem"
    }
  },
  undoBtn: {
    padding: "0.25rem",
    minWidth: 0,
    borderRadius: 0,
    background: theme.palette.button.light,
    color: theme.palette.secondary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    },
    margin: "0 0 0.25em",
    marginRight: "0.25rem"
  },
  submitButton: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 0,
    fontWeight: 400,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    }
  },
  orderIDContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.success.main
    }
  },
  orderIDField: {
    marginBottom: "1rem"
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import OrderItem, {
  OrderItemDefaultProps,
  OrderItemPropTypes
} from "../OrderItem";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  orderItem: PropTypes.shape(OrderItemPropTypes),
  expanded: PropTypes.bool,
  handleChange: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  orderItem: OrderItemDefaultProps,
  expanded: false,
  handleChange: () => {}
};

/**
 * Displays the component
 */
const Orders = props => {
  const { orderItem, expanded, handleChange } = props;

  /**
   * Gets the global user object
   */
  const { user } = useUser();

  /**
   * Handles rendering the users
   */
  const renderOrders = () => {
    const { orders } = user;

    return orders && orders.length > 0
      ? orders.map(order => {
          const { id: key } = order;

          return (
            <Zoom key={key} in={true} timeout={500}>
              <Grid item xs={12}>
                <OrderItem
                  {...orderItem}
                  {...order}
                  expanded={expanded}
                  onChange={handleChange}
                />
              </Grid>
            </Zoom>
          );
        })
      : null;
  };

  return renderOrders();
};

Orders.propTypes = propTypes;
Orders.defaultProps = defaultProps;

export default Orders;
export { propTypes as OrdersPropTypes, defaultProps as OrdersDefaultProps };

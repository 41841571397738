import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";
import Zoom from "@material-ui/core/Zoom";

/**
 * Styles Imports
 */
import { useStyles } from "./ClientsSearchForm.styles";

/**
 * Defaults Imports
 */
import { defaults } from "../WorkstationSettingsClients/WorkstationSettingsClients.defaults";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  defaultValues: PropTypes.shape({
    quick_text: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
    is_company: PropTypes.bool,
    email: PropTypes.string,
    company_vat: PropTypes.string,
    company_reg: PropTypes.string
  }),
  orderByList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  path: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  defaultValues: {
    quick_text: "",
    name: "",
    organization: "",
    orderBy: "name",
    orderDir: "asc",
    is_company: true,
    email: "",
    company_vat: "",
    company_reg: ""
  },
  orderByList: defaults.orderBy,
  pageSizeOptions: defaults.pageSizeOptions,
  path: "/clients"
};

/**
 * Displays the component
 */
const ClientsSearchForm = (props) => {
  const {
    form,
    input,
    button,
    defaultValues,
    tabChanged,
    setTabChanged,
    defaultSearch,
    setInputs: updateInputs,
    setModels: updateModels,
    setTotal,
    pageSize,
    setPageSize,
    setPageCount,
    setBackdropLoading,
    hasFidelity,
    setUpdated,
    orderByList,
    loadingText,
    resetFilters,
    setResetFilters,
    handleDefaultSearch,
    setClients
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the state that holds the options for the select type search models
   */
  const [organizations, setOrganizations] = useState([]);
  const [orderByOptions, setOrderByOptions] = useState([]);

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles searching for clients
   */
  const searchClients = async (data) => {
    try {
      const clients = await apiClient.post("/clients/search", data);
      if (clients) {
        const { data } = clients;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);

        setClients(items);

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        updateModels(models);

        /**
         * Handles updating the inputs
         */
        const handlePrevState = (prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        };

        setInputs((prevState) => handlePrevState(prevState));
        updateInputs((prevState) => handlePrevState(prevState));

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message
      });
    }
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];

    /**
     * Gets the inputs
     */
    const {
      quick_text,
      organization,
      name,
      is_company,
      email,
      company_vat,
      company_reg
    } = inputs;

    /**
     * Gets the default models
     */
    const {
      quickTextModel,
      organizationIdModel,
      nameModel,
      fidelityModel,
      isCompanyModel,
      companyVatModel,
      companyRegModel,
      companyEmailModel
    } = defaults.models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      const modelItem = { ...model };
      modelItem["selected"] = input;
      searchModels.push(modelItem);
    };

    if (quick_text) {
      if (modelExists("quick_text")) {
        removeModel("quick_text");
        addModel(quick_text, quickTextModel);
      } else {
        addModel(quick_text, quickTextModel);
      }
    }

    if (organization) {
      let org = organization !== "all" ? organization : null;

      if (modelExists("organization_id")) {
        removeModel("organization_id");
        addModel(org, organizationIdModel);
      } else {
        addModel(org, organizationIdModel);
      }
    }

    if (name) {
      if (modelExists("name")) {
        removeModel("name");
        addModel(name, nameModel);
      } else {
        addModel(name, nameModel);
      }
    }

    if (modelExists("is_company")) {
      removeModel("is_company");
      addModel(is_company, isCompanyModel);
    } else {
      addModel(is_company, isCompanyModel);
    }

    if (email) {
      if (modelExists("company.email")) {
        removeModel("company.email");
        addModel(email, companyEmailModel);
      } else {
        addModel(email, companyEmailModel);
      }
    }

    if (company_reg) {
      if (modelExists("company.reg_number")) {
        removeModel("company.reg_number");
        addModel(company_reg, companyRegModel);
      } else {
        addModel(company_reg, companyRegModel);
      }
    }

    if (company_vat) {
      if (modelExists("company.vat_number")) {
        removeModel("company.vat_number");
        addModel(company_vat, companyVatModel);
      } else {
        addModel(company_vat, companyVatModel);
      }
    }

    if (hasFidelity !== "" && hasFidelity !== "any") {
      if (modelExists("fidelity")) {
        removeModel("fidelity");
        addModel(hasFidelity, fidelityModel);
      } else {
        addModel(hasFidelity, fidelityModel);
      }
    }

    return searchModels.length < 1 ? defaultSearch.models : searchModels;
  };

  useEffect(() => {
    const orderBys = orderByList.map((orderBy) => {
      return {
        label: t(orderBy),
        value: orderBy
      };
    });
    setOrderByOptions(orderBys);
    // eslint-disable-next-line
  }, [orderByList]);

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: 1
    };

    searchClients(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    quick_text,
    name,
    organization,
    is_company,
    email,
    company_vat,
    company_reg,
    orderBy,
    orderDir
  } = inputs;

  /**
   * Handles resetting the filters
   */
  const handleResetFilters = () => {
    resetInputs();
    setBackdropLoading(true);
    setModels(defaults.models);
    updateModels(defaults.models);
    setPageCount(1);
    handleDefaultSearch();
  };

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.organizations) {
      const organizations = [
        {
          id: "all",
          name: t("all")
        },
        ...user.organizations
      ].map((org) => {
        return {
          label: org.name,
          value: org.id
        };
      });

      setOrganizations(organizations);
    }

    if (user.organization && user.organization.id) {
      if (!inputs.organization) {
        setInputs((prevState) => ({
          ...prevState,
          organization: user.organization.id
        }));
      }
    }

    // eslint-disable-next-line
  }, [user]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    updateModels(defaultSearch.models);

    // eslint-disable-next-line
  }, []);

  /**
   * Handles the tab change
   */
  useEffect(() => {
    if (tabChanged) {
      updateModels(models);
      setTabChanged(false);
      setUpdated(true);
    }
    // eslint-disable-next-line
  }, [tabChanged]);

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false);
      handleResetFilters();
    }
    // eslint-disable-next-line
  }, [resetFilters]);

  useEffect(() => {
    updateInputs(inputs);
    // eslint-disable-next-line
  }, [inputs]);

  return (
    <Form
      {...form}
      id="search-user"
      onSubmit={handleSubmit}
      className={classes.form}
    >
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={12} sm={6} md={3} lg={4} xl={is_company ? 4 : 3}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "quick_text",
                  value: quick_text,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label
                  },
                  showHelper: false,
                  label: t("quick_textLabel")
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={6} lg={4} xl={is_company ? 4 : 3}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "organization",
                  value: organization,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("organizationLabel"),
                  options: organizations,
                  optionLabel: "label",
                  optionValue: "value"
                }}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderBy",
                  value: orderBy,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderByLabel"),
                  variant: "outlined",
                  options: orderByOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderDir",
                  value: orderDir,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderDirLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("asc"), value: "asc" },
                    { label: t("desc"), value: "desc" }
                  ],
                  optionLabel: "label",
                  optionValue: "value"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "name",
                  value: name,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label
                  },
                  showHelper: false,
                  label: t("nameLabel")
                }}
              />
            </Grid>
            <Zoom in={is_company} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "email",
                    value: email,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label
                    },
                    showHelper: false,
                    label: t("emailLabel")
                  }}
                />
              </Grid>
            </Zoom>

            <Zoom in={is_company} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={3} md={3} lg={4} xl={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "company_vat",
                    value: company_vat,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label
                    },
                    showHelper: false,
                    label: t("company_vatLabel")
                  }}
                />
              </Grid>
            </Zoom>
            <Zoom in={is_company} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={3} md={3} lg={4} xl={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "company_reg",
                    value: company_reg,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label
                    },
                    showHelper: false,
                    label: t("company_regLabel")
                  }}
                />
              </Grid>
            </Zoom>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Grid container spacing={1} wrap={"nowrap"}>
            <Grid item xs={12} container justify="center" direction="column">
              <div className={classes.btnContainer}>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  title={t("undo")}
                  onClick={handleResetFilters}
                  className={classes.undoBtn}
                >
                  <UndoIcon />
                </Button>
                <Button
                  {...button}
                  type="submit"
                  variant="filled"
                  onClick={handleSubmit}
                  className={classes.submitButton}
                >
                  <LoadingText
                    {...loadingText}
                    size={24}
                    loading={loading}
                    text={t("submitSearch")}
                    icon={<SearchIcon />}
                  />
                </Button>
              </div>
              <div className={classes.orderIDContainer}>
                <div className={classes.orderIDField}>
                  <Input
                    {...input}
                    type="checkbox"
                    className={classes.field}
                    inputCheckbox={{
                      name: "is_company",
                      value: is_company,
                      onChange: handleInputChange,
                      variant: "outlined",
                      labelClass: classes.label,
                      InputLabelProps: {
                        className: classes.label
                      },
                      showHelper: false,
                      label: t("is_companyLabel")
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

ClientsSearchForm.propTypes = propTypes;
ClientsSearchForm.defaultProps = defaultProps;

export default ClientsSearchForm;
export {
  propTypes as ClientsSearchFormPropTypes,
  defaultProps as ClientsSearchFormDefaultProps
};

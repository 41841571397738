import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import { useTheme, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

/**
 * Styles Imports
 */
import { useStyles } from "./ModulesFooter.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  user: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  user: {},
};

/**
 * Displays the component
 */

const ModulesFooter = (props) => {
  const { user } = props;
  const { organization } = user;

  /**
   * Handles the translation
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the theme and the breakdown rezolution
   */
  const theme = useTheme();
  const triggerHide = useMediaQuery(theme.breakpoints.down("md"));

  const currentYear = new Date().getFullYear();

  /**
   * Renders the footer item
   */
  const renderFooterItem = (type) => {
    if (!type) return;
    if (!organization) return null;

    /**
     * Handles getting the footer item data (icon and title)
     */
    const getData = () => {
      switch (type) {
        case "location":
          return {
            icon: <LocationOnIcon className={classes.infoIcon} />,
            title: organization && organization.address,
          };
        case "phone":
          return {
            icon: <PhoneIcon className={classes.infoIcon} />,
            title: organization && organization.phone,
          };
        case "email":
          return {
            icon: <EmailIcon className={classes.infoIcon} />,
            title: organization && organization.email,
          };
        case "reg_numberLabel":
          return {
            icon: <InfoIcon className={classes.infoIcon} />,
            title: organization && organization.reg_number,
          };
        case "vat_number":
          return {
            icon: <InfoIcon className={classes.infoIcon} />,
            title: organization && organization.vat_number,
          };
        default:
          return null;
      }
    };

    /**
     * Gets the tooltip variant
     */
    const getTooltip = (title, icon) => {
      return (
        <Tooltip
          title={title}
          TransitionComponent={Zoom}
          placement="top"
          classes={{
            tooltip: classes.tooltip,
            popper: classes.popper,
          }}
        >
          {icon}
        </Tooltip>
      );
    };

    /**
     * Extracts the title and icon
     */
    const { title, icon } = getData();

    /**
     * Handles the rendering logic
     */
    if (type === "vat_number" || type === "reg_numberLabel") {
      return (
        <Fragment>
          <Tooltip
            title={t(type)}
            TransitionComponent={Zoom}
            placement="top"
            classes={{
              tooltip: classes.tooltip,
              popper: classes.popper,
            }}
          >
            {icon}
          </Tooltip>
          {title}
        </Fragment>
      );
    } else if (!triggerHide) {
      return (
        <Fragment>
          {icon} {title}
        </Fragment>
      );
    } else {
      return getTooltip(title, icon);
    }
  };

  /**
   * Builds the footer item components
   */
  const Location = () => {
    if (organization && organization.location) {
      return renderFooterItem("location");
    }
    return null;
  };
  const Phone = () => {
    if (organization && organization.phone) {
      return renderFooterItem("phone");
    }
    return null;
  };
  const Email = () => {
    if (organization && organization.email) {
      return renderFooterItem("email");
    }
    return null;
  };
  const VATNumber = () => {
    if (organization && organization.vat_number) {
      return renderFooterItem("vat_number");
    }
    return null;
  };
  const REGNumber = () => {
    if (organization && organization.reg_number) {
      return renderFooterItem("reg_numberLabel");
    }
    return null;
  };

  /**
   * Handles rendering the ModulesFooter
   */
  const renderModulesFooter = () => {
    return (
      <div className={classes.footer}>
        <Grid container>
          <Grid item container xs={12}>
            <div className={classes.companyInfo}>
              <Typography variant="h3" className={classes.info}>
                <REGNumber />
              </Typography>
              <Typography variant="h3" className={classes.info}>
                <VATNumber />
              </Typography>
            </div>
            <div className={classes.footerItems}>
              <Typography variant="h3" className={classes.footerItem}>
                <Location />
              </Typography>
              <Typography variant="h3" className={classes.footerItem}>
                <Phone />
              </Typography>
              <Typography variant="h3" className={classes.footerItem}>
                <Email />
              </Typography>
            </div>
            <div className={classes.copyRight}>
              <Typography variant="h3" className={classes.copyRightText}>
                {t("copyRightText",{year: currentYear})}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return renderModulesFooter();
};

ModulesFooter.propTypes = propTypes;
ModulesFooter.defaultProps = defaultProps;

export default ModulesFooter;
export {
  propTypes as ModulesFooterPropTypes,
  defaultProps as ModulesFooterDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modalContent: {
    paddingTop: 0,
    margin: "0.25rem 0.5rem"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: "transparent"
  },
  conditions: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  priceCondition: {
    padding: "0.5rem",
    margin: "0.5rem",
    background: "#f0edec",
    color: theme.palette.sidebar.text,
    borderRadius: 3,
    fontFamily: theme.font.family,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "0.9em",
      height: "0.9em"
    }
  },
  conditonPrice: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    padding: "0.25rem",
    paddingLeft: "0.5rem",
    marginLeft: "0.4rem",
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  conditionText: {
    padding: "0.25rem",
    paddingRight: "0.5rem",
    background: theme.palette.sidebar.text,
    color: theme.palette.common.white,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  title: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.sidebar.text,
    fontFamily: theme.font.family,
    fontSize: "0.8rem",
    margin: "0 0.5rem",
    padding: "0 0.5rem",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    "& svg": {
      marginRight: "0.35rem",
      fontSize: "1.6rem",
      width: "1.7rem",
      height: "1.7rem"
    }
  },
  conditionsContainer: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column"
  },
  section: {
    border: `1px solid ${theme.palette.border.main}`,
    borderBottom: "none",
    borderTop: "none"
  },
  flat: {
    background: theme.palette.primary.main
  },
  up: {
    background: theme.palette.success.main
  },
  upIcon: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    padding: 2,
    borderRadius: "50%",
    marginBottom: "0.3rem"
  },
  flatIcon: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 2,
    borderRadius: "50%",
    marginBottom: "0.3rem"
  },
  downIcon: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: 2,
    borderRadius: "50%",
    marginBottom: "0.3rem"
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontWeight: 800
  },
  value: {
    fontWeight: 800,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "0.15rem 1.5rem",
    borderRadius: 3,
    marginLeft: "0.5rem"
  },
  openModalBtn: {
    ...theme.mixins.submitBtn,
    maxWidth: 200,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none"
    }
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0.5rem 0",
    paddingBottom: "0.25rem"
  }
}));

export { useStyles };

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditOrganizationModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditOrganizationModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    document_prefix: PropTypes.string,
    document_suffix: PropTypes.string,
    time_sheet: PropTypes.string,
    description: PropTypes.string,
  }),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  editData: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultValues: {
    name: "",
    document_prefix: "",
    document_suffix: "",
    time_sheet: "",
    description: "",
  },
  errorMessages: ErrorMessagesDefaultProps,
  editData: {},
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditOrganizationModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    editData,
    errorMessages,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user setter
   */
  const { setUser } = useUser();

  /**
   * Gets the organization id
   */
  const { id } = editData;

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { name, document_prefix, document_suffix, time_sheet, description } =
    inputs;

  const {
    name: nameError,
    document_prefix: document_prefixError,
    document_suffix: document_suffixError,
    time_sheet: time_sheetError,
    description: descriptionError,
  } = errors;

  /**
   * Handles getting the user organizations
   */
  const getUserOrganizations = async () => {
    try {
      const organizations = await apiClient.get("/organizations");
      if (organizations) {
        setLoading(false);
        resetInputs();

        /**
         * Dispatch the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });

        setUser((prevState) => {
          return { ...prevState, organizations: organizations.data };
        });
        handleCloseModal();
      }
    } catch (error) {
      setReady(false);
      /**
       * Dispatch the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles updating the user organization by id
   */
  const updateUserOrganization = async (data, id) => {
    try {
      const updatedOrg = await apiClient.put(`/organizations/${id}`, data);
      if (updatedOrg) {
        getUserOrganizations();
      }
    } catch (error) {
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setReady(false);
    setErrors({});
    closeModal();
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        name,
        document_prefix,
        document_suffix,
      };

      if (time_sheet) data["time_sheet"] = time_sheet;
      if (description) data["description"] = description;

      updateUserOrganization(data, id);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the inputs with the props data
   */
  useEffect(() => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        name: editData.name ? editData.name : "",
        document_prefix: editData.document_prefix
          ? editData.document_prefix
          : "",
        document_suffix: editData.document_suffix
          ? editData.document_suffix
          : "",
        time_sheet: editData.time_sheet
          ? editData.time_sheet
          : t("time_sheetPlaceholder"),
        description: editData.description ? editData.description : "",
      };
    });
    // eslint-disable-next-line
  }, [editData, open]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={`${t("titleEditOrganization")}${editData.name}`}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-org-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "name",
                              required: true,
                              name: "name",
                              value: name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("nameLabel"),
                              error: nameError,
                              autoFocus: getAutoFocus().name,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "document_prefix",
                              required: true,
                              name: "document_prefix",
                              value: document_prefix,
                              onChange: handleInputChange,
                              maxSize: 4,
                              variant: "standard",
                              label: t("document_prefixLabel"),
                              placeholder: t("document_prefixPlaceholder"),
                              error: document_prefixError,
                              autoFocus: getAutoFocus().document_prefix,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "document_suffix",
                              required: true,
                              name: "document_suffix",
                              value: document_suffix,
                              onChange: handleInputChange,
                              maxSize: 4,
                              variant: "standard",
                              label: t("document_suffixLabel"),
                              placeholder: t("document_suffixPlaceholder"),
                              error: document_suffixError,
                              autoFocus: getAutoFocus().document_suffix,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "time_sheet",
                              name: "time_sheet",
                              value: time_sheet,
                              onChange: handleInputChange,
                              maxSize: 50,
                              variant: "standard",
                              label: t("time_sheetLabel"),
                              placeholder: t("time_sheetPlaceholder"),
                              error: time_sheetError,
                              autoFocus: getAutoFocus().time_sheet,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            className={classes.textarea}
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 6,
                              rowsMax: 14,
                              maxChars: 200,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel"),
                              error: descriptionError,
                              autoFocus: getAutoFocus().description,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-org-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditOrganizationModal.propTypes = propTypes;
EditOrganizationModal.defaultProps = defaultProps;

export default EditOrganizationModal;
export {
  propTypes as EditOrganizationModalPropTypes,
  defaultProps as EditOrganizationModalDefaultProps,
};

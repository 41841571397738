/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderRight: `1px solid ${theme.palette.border.main}`,
      padding: "6px 12px",
      textAlign: "center",
      color: theme.palette.sidebar.text,
      border: `1px solid ${theme.palette.border.main}`,
      background: theme.palette.common.white,
      fontSize: "0.8rem",
    },
    "& .MuiTableCell-head": {
      fontWeight: 800,
      color: theme.palette.sidebar.text,
      background: theme.palette.sidebar.main,
      fontSize: "0.8rem",
    },
  },
  tableHeaderHidden: {
    display: "none",
  },
}));

export { useStyles };

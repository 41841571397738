/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  workers: {
    padding: "0 0.5rem",
    fontWeight: 400,
    fontSize: "0.7rem"
  },
  title: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 0,
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    borderBottom: `1px solid #e0e0e0`,
    paddingLeft: "0.5rem",
    display: "flex",
    alignItems: "center"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  footer: {
    ...theme.mixins.flex.rowCenter,
    background: theme.palette.sidebar.main,
    fontFamily: theme.font.family,
    flex: 1,
    fontSize: "1.1rem",
    padding: 0,
    width: "100%",
    minHeight: 30,
    zIndex: 1200,
    position: "fixed",
    bottom: 0,
    right: 0,
    borderTop: `1px solid #cecece`
  },
  footerItems: {
    display: "flex",
    flex: 4,
    alignItems: "center"
  },
  copyRight: {
    display: "flex",
    alignItems: "center"
  },
  footerItem: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    padding: "0.5rem",
    display: "flex",
    alignItems: "center"
  },
  copyRightText: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    padding: "0.5rem"
  },
  companyInfo: {
    display: "flex",
    flex: 1
  },
  info: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    padding: "0.5rem",
    display: "flex",
    alignItems: "center"
  },
  infoIcon: {
    marginRight: "0.25rem",
    color: theme.palette.primary.main
  },
  popper: {
    marginTop: -5
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #929292",
    color: theme.palette.common.white,
    fontSize: "1rem",
    maxWidth: "none"
  }
}));

export { useStyles };

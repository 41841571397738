/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  tablePagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    "& .MuiIconButton-root": {
      padding: 5,
    },
  },
}));

export { useStyles };

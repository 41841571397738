import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import WorkOrderHeader, {
  WorkOrderHeaderDefaultProps,
  WorkOrderHeaderPropTypes,
} from "../WorkOrderHeader";
import WorkOrderDisclaimer, {
  WorkOrderDisclaimerDefaultProps,
  WorkOrderDisclaimerPropTypes,
} from "../WorkOrderDisclaimer";
import DataTable, {
  DataTableDefaultProps,
  DataTablePropTypes,
} from "../DataTable";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./PrintTyreHotelsClient.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  header: PropTypes.shape(WorkOrderHeaderPropTypes),
  disclaimer: PropTypes.shape(WorkOrderDisclaimerPropTypes),
  dataTable: PropTypes.shape(DataTablePropTypes),
  printData: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  header: WorkOrderHeaderDefaultProps,
  disclaimer: WorkOrderDisclaimerDefaultProps,
  dataTable: DataTableDefaultProps,
  printData: {},
};

/**
 * Displays the component
 */
const PrintTyreHotelsClient = (props) => {
  const { printData, header, disclaimer, dataTable } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the component print data
   */
  const {
    car_number,
    client_name,
    work_order_uuid,
    tyre_dim,
    created_at,
    tyre_quantity,
    tyre_name,
    description,
  } = printData;

  /**
   * Defines the car data
   */
  const tableData = [
    { label: t("work_order_uuid"), value: work_order_uuid, render: true },
    {
      label: t("date"),
      value: created_at,
      render: true,
    },
    { label: t("plate_number"), value: car_number, render: true },
    { label: t("client"), value: client_name, render: true },
    {
      label: t("quantity"),
      value: tyre_quantity ? tyre_quantity : " ",
      render: true,
    },
    { label: t("tyre_dim"), value: tyre_dim, render: true },
    { label: t("workOrder"), value: work_order_uuid, render: true },
    { label: t("tyre_name"), value: tyre_name, render: true },
    {
      label: t("description"),
      value: description ? description : " ",
      render: true,
    },
  ];

  const tableFormat = {
    label: {
      xs: 3,
      sm: 3,
      md: 3,
      lg: 3,
      xl: 3,
    },
    value: {
      xs: 9,
      sm: 9,
      md: 9,
      lg: 9,
      xl: 9,
    },
  };

  return (
    <Grid item container xs={12}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.blank}>
            <CardContent>
              <Grid container direction="column" justify="center">
                <WorkOrderHeader {...header} />
                <Typography variant="h1" className={classes.title}>
                  {t("titleTyreHotelsDeposit")}
                </Typography>
              </Grid>
              <Grid container justify="center" className={classes.table}>
                <DataTable
                  {...dataTable}
                  data={tableData}
                  classes={{
                    value: classes.tableFontSize,
                    label: classes.tableFontSize,
                  }}
                  format={tableFormat}
                />
              </Grid>
              <Grid container justify="flex-end" alignItems="center">
                <Typography variant="caption" className={classes.signatureCont}>
                  {t("signature")}
                  <div className={classes.signatureLine} />
                </Typography>
              </Grid>
              <Grid container>
                <WorkOrderDisclaimer
                  {...disclaimer}
                  className={classes.disclaimer}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

PrintTyreHotelsClient.propTypes = propTypes;
PrintTyreHotelsClient.defaultProps = defaultProps;

export default PrintTyreHotelsClient;
export {
  propTypes as PrintTyreHotelsClientPropTypes,
  defaultProps as PrintTyreHotelsClientDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { ro } from "date-fns/locale";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./ContractsTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  dateFormat: PropTypes.string,
  dateFormatOptions: PropTypes.object,
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFiltersReset: PropTypes.func,
  clients: PropTypes.array,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  dateFormat: "dd MMMM yyyy",
  dateFormatOptions: { locale: ro },
  handleDelete: null,
  handleAdd: () => {},
  handleEdit: () => {},
  handlePageChange: () => {},
  handleChangeRowsPerPage: () => {},
  handleFiltersReset: () => {},
  clients: [],
  rowsPerPage: 0,
  page: 0,
  count: 0,
};

/**
 * Displays the component
 */
const ContractsTable = (props) => {
  const {
    dateFormat,
    dateFormatOptions,
    editButton,
    deleteButton,
    dynamicTable,
    handleAdd,
    handleEdit,
    handleDelete,
    handlePageChange,
    handleChangeRowsPerPage,
    handleFiltersReset,
    rowsPerPage,
    page,
    count,
    clients,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Handles mapping the id  with the organization name
   * @param {String|Number} id
   */
  const getOrganizationName = (id) => {
    if (organizations) {
      const organization = organizations.find((org) => org.id === id);
      return organization ? organization.name : "";
    }
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () =>
    user.contracts && user.contracts.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const {
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    } = props;

    let tableOptions = {
      withCount: true,
      withAdd: true,
      withSearch: true,
      withPagination: true,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: true,
        addResult: true,
      },
      pagination: {
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        total: count,
        currentPage: page,
      },
      addResult: handleAdd,
      resetFilters: handleFiltersReset,
      defaultOrderBy: "contract_number",
      fields: [
        {
          name: "contract_number",
          label: t("contract_number"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "organization_id",
          label: t("organization_id"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "organization_client_id",
          label: t("organization_client_id"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "payment_type_id",
          label: t("payment_type_id"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "time_interval",
          label: t("time_interval"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "description",
          label: t("description"),
          align: "start",
          minSize: 250,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "operations",
          label: t("operations"),
          type: "actions",
          key: false,
          align: "center",
          searchField: false,
          maxSize: 100,
          buttons: [
            {
              name: "edit_product",
              type: "edit",
              icon: null,
              component: (
                <EditButton
                  {...editButton}
                  className={classes.button}
                  title={t("edit")}
                />
              ),
              action: handleEdit,
            },
            {
              name: "delete_product",
              type: "delete",
              icon: null,
              component: (
                <DeleteButton
                  {...deleteButton}
                  className={classes.button}
                  title={t("delete")}
                />
              ),
              action: handleDelete,
            },
          ],
        },
      ],
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [count, page, rowsPerPage]);

  const getPaymentType = (id) => {
    const paymentTypes = user.settings.account.payment_types;
    if (!paymentTypes) return;
    const paymentType = paymentTypes.find((payment) => payment.id === id);
    return paymentType ? paymentType.name : "-";
  };

  /**
   * Gets the client name
   */
  const getClientName = (client_id) => {
    const clientFound = clients.find((client) => client.id === client_id);
    return clientFound ? clientFound.name : "-";
  };

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.organizations) {
      setOrganizations(user.organizations);
    }
    if (user.contracts && clients && clients.length > 0) {
      const data = user.contracts.map((contract) => {
        const {
          created_at,
          organization_id,
          organization_client_id,
          payment_type_id,
          from,
          to,
          description,
        } = contract;

        /**
         * Formats the dates
         */
        const fromDate = formatDate(
          new Date(from),
          dateFormat,
          dateFormatOptions
        );
        const toDate = formatDate(new Date(to), dateFormat, dateFormatOptions);
        const createdAtDate = formatDate(
          new Date(created_at),
          dateFormat,
          dateFormatOptions
        );

        return {
          ...contract,
          description: description ? description : "-",
          created_at: createdAtDate,
          time_interval: `${fromDate} - ${toDate}`,
          payment_type_id: getPaymentType(payment_type_id),
          organization_client_id: getClientName(organization_client_id),
          organization_id: getOrganizationName(organization_id),
        };
      });
      setCollection(data);
    }
    // eslint-disable-next-line
  }, [user, clients]);

  return renderDynamicTable();
};

ContractsTable.propTypes = propTypes;
ContractsTable.defaultProps = defaultProps;

export default ContractsTable;
export {
  propTypes as ContractsTablePropTypes,
  defaultProps as ContractsTableDefaultProps,
};

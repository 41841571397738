import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Styles Imports
 */
import { useStyles } from "./CarServiceMain.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  componentName: PropTypes.string,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  componentName: "CarServiceMain",
  path: "/",
};

/**
 * Displays the component
 */
const CarServiceMain = (props) => {
  const { componentName } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  return <div className={classes.CarServiceMain}>{t(componentName)}</div>;
};

CarServiceMain.propTypes = propTypes;
CarServiceMain.defaultProps = defaultProps;

export default CarServiceMain;
export {
  propTypes as CarServiceMainPropTypes,
  defaultProps as CarServiceMainDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  timeLeft: {
    color: theme.palette.common.white,
    "& svg": {
      color: theme.palette.common.white
    }
  },
  redTag: {
    background: theme.palette.error.main,
    boxShadow: "none",
    borderRadius: 2,
    fontWeight: 400
  },
  warningTag: {
    background: "#fdad1a"
  },
  greenTag: {
    background: theme.palette.success.main,
    boxShadow: "none",
    borderRadius: 2,
    fontWeight: 400
  },
  accountPlan: {
    display: "flex",
    alignItems: "center",
    "&.MuiTypography-caption": {
      fontSize: "0.9rem",
      fontWeight: 800,
      color: theme.palette.sidebar.text
    },
    "& svg": {
      color: theme.palette.warning.main
    }
  },
  packageIcon: {
    marginLeft: "0.5rem",
    color: theme.palette.warning.main,
    cursor: "pointer"
  },
  accountStatus: {
    display: "flex",
    alignItems: "center",
    "&.MuiTypography-caption": {
      fontSize: "0.9rem",
      fontWeight: 800,
      color: theme.palette.sidebar.text
    },
    "& $checked": {
      marginLeft: "0.5rem",
      color: theme.palette.success.main,
      cursor: "initial"
    },
    "& $warning": {
      marginLeft: "0.5rem",
      color: theme.palette.warning.main,
      cursor: "initial"
    }
  },
  checked: {
    marginLeft: "0.5rem",
    color: theme.palette.success.main,
    cursor: "initial"
  },
  warning: {
    marginLeft: "0.5rem",
    color: theme.palette.warning.main,
    cursor: "initial"
  },
  flexColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  itemContainer: {
    display: "flex",
    flex: 1,
    margin: "1rem",
    marginLeft: "0.55rem",
    marginBottom: 0
  },
  label: {
    fontWeight: 800,
    fontSize: "0.7rem",
    color: theme.palette.sidebar.text,
    display: "flex",
    alignItems: "center"
  },
  container: theme.mixins.submoduleContainer,
  box: {
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.sidebar.main,
    margin: "1rem 0",
    padding: "0.5rem",
    paddingBottom: "1rem"
  },
  title: {
    fontSize: "1rem",
    display: "flex",
    fontWeight: 800,
    color: "#656e73",
    borderBottom: "1px solid #d4d4d4",
    padding: "0.5rem 1rem 0"
  }
}));

export { useStyles };

import React, {
  useReducer,
  useState,
  useEffect,
  useContext,
  createContext
} from "react";
import PropTypes from "prop-types";

/**
 * Hooks
 */
import { useSessionStorage } from ".";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultActiveStep: PropTypes.number
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultActiveStep: 0
};

/**
 * Provides the state of the modal
 * By default it will be open
 */
const useRegisterHook = props => {
  const { defaultActiveStep } = defaultProps;

  const [stepLocal, setStepLocal] = useSessionStorage("step", {
    activeStep: defaultActiveStep
  });
  const [formDataLocal, setFormDataLocal] = useSessionStorage("formData");

  /**
   * Initialize the global state using the local storage cart
   */
  const [formData, setFormData] = useState(formDataLocal);

  /**
   * Setting up the initial state
   */
  const initState = { activeStep: stepLocal.activeStep };

  /**
   * Handles syncronizing the local storage and the global state for the form state
   */
  useEffect(() => {
    setFormDataLocal(formData);
    // eslint-disable-next-line
  }, [formData]);

  /**
   * Sets up the reducer
   */
  const reducer = (state, action) => {
    const { type, step } = action;
    const { activeStep } = state;

    switch (type) {
      case "STEP_NEXT":
        return { activeStep: activeStep + 1 };
      case "STEP_BACK":
        return { activeStep: activeStep - 1 };
      case "STEP_RESET":
        return { activeStep: 0 };
      case "STEP_UPDATE":
        return { activeStep: step };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);
  const { activeStep } = state;

  /**
   * Defines the base dispatch actions
   */
  const nextStep = () => dispatch({ type: "STEP_NEXT" });
  const prevStep = () => dispatch({ type: "STEP_BACK" });
  const resetSteps = () => dispatch({ type: "STEP_NEXT" });
  const updateStep = step => dispatch({ type: "STEP_UPDATE", step });

  /**
   * Updates the profile completion stepper based on inputs and validation results
   */
  const sync = (inputs, step, errors) => {
    /**
     * Checks for any errors
     */
    const hasErrors = Object.values(errors).some(error =>
      error ? errors[error] !== null : null
    );

    /**
     * Checks if the input has any values
     */
    const valid = Object.values(inputs).every(input =>
      input ? input.length > 0 && activeStep >= step : null
    );

    /**
     * Handles the logic of the stepper
     */
    if (activeStep > step && hasErrors) {
      resetSteps();
    } else if (valid && !hasErrors && activeStep <= step) {
      nextStep();
    } else if (!valid && hasErrors && activeStep > step) {
      prevStep();
    } else if (!valid && !hasErrors && activeStep > step) {
      prevStep();
    }
  };

  /**
   * Resets the progress of the stepper to 0
   */
  const resetProgress = () => dispatch({ type: "STEP_RESET" });

  useEffect(() => {
    setStepLocal({ activeStep });
    // eslint-disable-next-line
  }, [activeStep]);

  return {
    dispatch,
    activeStep,
    nextStep,
    prevStep,
    updateStep,
    resetSteps,
    sync,
    resetProgress,
    formData,
    setFormData
  };
};

/**
 * Sets the prop types
 */
useRegisterHook.propTypes = propTypes;

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const registerContext = createContext();

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const RegisterProvider = props => {
  const { children } = props;

  const completionData = useRegisterHook();

  return (
    <registerContext.Provider value={{ ...completionData }}>
      {children}
    </registerContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 * - To be used inside components
 */
const useRegister = () => {
  return useContext(registerContext);
};

export { useRegister, RegisterProvider };

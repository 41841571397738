/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;

  const validatorFn = (props) => {
    const { name, value } = props;

    let error = null;

    switch (name) {
      case "client_name":
        if (!value) return (error = t("LanguageProvider:error_isRequired"));

        return error;
      case "workersArr":
        if (value.length < 1)
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

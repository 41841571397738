/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;
  const { isEmpty, isNumber } = useValidator();

  const validatorFn = (props) => {
    const { name, value } = props;
    let error = null;

    switch (name) {
      case "field":
      case "condition":
      case "value":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "new_price":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        if (!isNumber(value))
          return (error = t("LanguageProvider:error_invalidNumber"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

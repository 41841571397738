import React, { useEffect } from "react";

/**
 * External Imports
 */
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ScrollToTop from "../ScrollToTop";
import AuthPage from "../AuthPage";
import MessagePopup from "../MessagePopup";
import Modules from "../Modules";
import SelectModuleButton from "../SelectModuleButton";
import CreateWorkOrderButton from "../CreateWorkOrderButton";

/**
 *  Material UI Imports
 */
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";

/**
 * Hooks
 */
import { useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./Routes.styles";

/**
 * Displays the component
 */
const Routes = () => {
  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user setter
   */
  const { user, setUser } = useUser();

  /**
   * Gets the message dispatcher
   */
  const { resetMessage, message } = useMessage();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient();

  /**
   * Handles pinging the server
   */
  const pingServer = async () => {
    const response = await apiClient.get("/ping");

    if (response && message) {
      resetMessage();
      if (
        !user.accounts ||
        !user.organizations ||
        !user.settings ||
        !user.organization
      ) {
        setUser((prevState) => {
          return { ...prevState, restart: true };
        });
      }
    }
  };

  /**
   * Pings the server every 10 seconds
   & @Szabi - Disabled until otherwise instructed.
   */
  useEffect(() => {
    if (process.env.NODE_ENV === "DISABLED") {
      /**
       * Sets the api call params every 6 seconds
       */
      const interval = setInterval(() => {
        pingServer();
      }, 10000);

      /**
       * Cleanup
       */
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Container className={classes.routes}>
          <Switch>
            <Route path="/modules">
              <Modules />
              <SelectModuleButton />
              <CreateWorkOrderButton />
            </Route>
            <Route path="/">
              <AuthPage />
            </Route>
          </Switch>
          <MessagePopup />
          <Backdrop
            className={classes.backdrop}
            open={message === t("serverDown")}
          />
        </Container>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;

import React, { useRef, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import PrintWorkOrder, {
  PrintWorkOrderDefaultProps,
  PrintWorkOrderPropTypes,
} from "../PrintWorkOrder";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import SettingsIcon from "@material-ui/icons/Settings";
import EditWorkOrderSettingsModal, {
  EditWorkOrderSettingsModalDefaultProps,
  EditWorkOrderSettingsModalPropTypes,
} from "../EditWorkOrderSettingsModal";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import PrintIcon from "@material-ui/icons/Print";

/**
 * Hooks
 */
import {
  useUser
} from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./TyreServiceWorkOrdersTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  printWorkOrder: PropTypes.shape(PrintWorkOrderPropTypes),
  editModal: PropTypes.shape(EditWorkOrderSettingsModalPropTypes),
  handleEdit: PropTypes.func,
  preventScrollTopOnSearch: PropTypes.bool,
  data: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  button: ButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  printWorkOrder: PrintWorkOrderDefaultProps,
  editModal: EditWorkOrderSettingsModalDefaultProps,
  handleEdit: () => { },
  setUpdated: () => { },
  preventScrollTopOnSearch: false,
  data: [],
};

/**
 * Displays the component
 */
const TyreServiceWorkOrdersTable = (props) => {
  const {
    editButton,
    button,
    dynamicTable,
    handleEdit,
    data,
    title,
    printWorkOrder,
    preventScrollTopOnSearch,
    setUpdated,
    editModal,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Initializes the worker id state
   */
  const [workOrderID, setWorkOrderID] = useState(0);

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  const [userType, setUserType] = useState("");

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  const { user } = useUser();

  const [rowInEdit, setRowInEdit] = useState("");

  const updateRowData = (props) => {
    setRowInEdit(props);
  };

  /**
   * Handles printing the component
   */
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const closeWorkOrderModal = () => {
    setRowInEdit("");
  };

  /**
   * Handles initiating the printing
   */
  const printComponent = (props) => {
    setWorkOrderID(props.id);
    setLoading(true);
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () => data && data.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const { userType } = props;

    let tableOptions = {
      withCount: true,
      withAdd: false,
      withSearch: true,
      withPagination: false,
      withStats: false,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: false,
        addResult: false,
      },
      pagination: {},
      defaultOrderBy: "finished",
      defaultOrder: "desc",
      actionsWrapped: false,
      specialStyles: [
        {
          key: "status",
          match: t("new"),
          className: clsx(classes.newWorkOrder, {
            [classes.isWorker]: userType === "worker",
          }),
        },
        {
          key: "status",
          match: t("done"),
          className: clsx(classes.doneWorkOrder, {
            [classes.isWorker]: userType === "worker",
          }),
        },
        {
          key: "status",
          match: t("on-hold"),
          className: clsx(classes.holdWorkOrder, {
            [classes.isWorker]: userType === "worker",
          }),
        },
        {
          key: "status",
          match: t("in-progress"),
          className: clsx(classes.progressWorkOrder, {
            [classes.isWorker]: userType === "worker",
          }),
        },
      ],
      fields: [
        {
          name: "status",
          label: t("status"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
          minSize: 100,
          styles: {
            cell: classes.bold,
          },
        },
        {
          name: "finished",
          label: t("finishedLabel"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
          type: "date"
        },
        {
          name: "plate_number",
          label: t("plate_number"),
          align: "center",
          key: true,
          searchField: true,
          sort: false,
          minSize: 100,
        },
        {
          name: "client_name",
          label: t("client_name"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "tyre_dimensions",
          label: t("tyre_dimensions"),
          align: "center",
          key: true,
          searchField: true,
          sort: false,
          maxSize: 100,
        },
        {
          name: "duration",
          label: t("duration"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "subtotal",
          label: t("subtotal"),
          align: "right",
          key: true,
          searchField: true,
          sort: false,
          minSize: 120,
          hidden: userType === "worker",
        },
        {
          name: "discount",
          label: t("discount"),
          align: "right",
          key: true,
          searchField: true,
          sort: false,
          minSize: 130,
          hidden: userType === "worker",
        },
        {
          name: "paid",
          label: t("paid"),
          align: "right",
          key: true,
          searchField: true,
          minSize: 100,
          sort: false,
        },
        {
          name: "payment_type",
          label: t("payment_type"),
          align: "start",
          key: true,
          searchField: true,
          hidden: userType === "worker",
          sort: false,
        },
        {
          name: "operations",
          label: t("operations"),
          type: "actions",
          key: false,
          align: "center",
          searchField: false,
          hidden: userType === "worker",
          buttons: [
            {
              name: "edit_work_order",
              type: "edit",
              icon: null,
              component: (
                <EditButton
                  {...editButton}
                  className={classes.button}
                  title={t("edit")}
                />
              ),
              action: handleEdit,
            },
            {
              name: "print_work_order",
              type: "print",
              icon: null,
              component: (
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  className={classes.printButton}
                  title={t("printWorkOrder")}
                >
                  <PrintIcon className={classes.icon} />
                </Button>
              ),
              action: printComponent,
            },
            {
              name: "change_status",
              type: "button",
              icon: null,
              component: (
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  className={classes.settingsButton}
                  title={t("workOrderSettings")}
                >
                  <SettingsIcon className={classes.icon} />
                </Button>
              ),
              action: (props) => updateRowData(props),
            },
          ],
        },
      ],
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        preventScrollTopOnSearch={preventScrollTopOnSearch}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setWorkOrderID(0);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({ rowInEdit, loading, userType });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [rowInEdit, loading, userType]);

  /**
   * Handles building the tyre dimensions string
   */
  const buildTyreDimString = (tyre_service) => {
    if (!tyre_service) return " - ";
    const { tyre_width, tyre_height, tyre_rim } = tyre_service;
    if (!tyre_width || !tyre_height || !tyre_rim) return " - ";
    return `${tyre_width} / ${tyre_height} R${tyre_rim}`;
  };

  useEffect(() => {
    if (user.type) setUserType(user.type);
  }, [user]);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (data) {
      const collection = data.map((workOrder) => {
        const {
          payment_type,
          car_type,
          work_order_type,
          organization_client,
          car,
          status,
          client_name,
          finished,
          tyre_service,
          duration,
          total,
          subtotal,
          discount,
        } = workOrder;


        return {
          ...workOrder,
          tyre_dimensions: buildTyreDimString(tyre_service),
          duration: duration ? duration : "-",
          finished: new Date(finished),
          paid: parseFloat(total).toFixed(2),
          subtotal: parseFloat(subtotal).toFixed(2),
          discount: parseFloat(discount).toFixed(2),
          payment_type: payment_type ? t(payment_type.name) : "-",
          car_type: car_type ? car_type.name : "-",
          work_order_type: work_order_type ? work_order_type.name : "-",
          status: status ? t(status) : "-",
          statusValue: status ? status : "-",
          plate_number: car ? car.plate_number : " - ",
          client_name: client_name ? client_name : organization_client.name,
        };
      });
      setCollection(collection);
    }
    // eslint-disable-next-line
  }, [data]);

  /**
   * Defines the table title
   */
  const TableTitle = (props) => {
    const { title } = props;

    return <Typography className={classes.tableTitle}>{title}</Typography>;
  };

  return (
    <Fragment>
      <TableTitle title={title} />
      {renderDynamicTable()}
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <PrintWorkOrder
            {...printWorkOrder}
            mode="print"
            workOrderID={workOrderID}
            setReadyToPrint={setReadyToPrint}
          />
        </div>
      </div>
      <EditWorkOrderSettingsModal
        {...editModal}
        closeModal={closeWorkOrderModal}
        open={Boolean(rowInEdit)}
        data={rowInEdit}
        setUpdated={setUpdated}
      />
    </Fragment>
  );
};

TyreServiceWorkOrdersTable.propTypes = propTypes;
TyreServiceWorkOrdersTable.defaultProps = defaultProps;

export default TyreServiceWorkOrdersTable;
export {
  propTypes as TyreServiceWorkOrdersTablePropTypes,
  defaultProps as TyreServiceWorkOrdersTableDefaultProps,
};

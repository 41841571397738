/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  title: {
    ...theme.mixins.submoduleTitle,
    "& svg": {
      marginRight: "0.5rem",
      color: theme.palette.primary.main,
      fontSize: "2rem",
      width: "1.2em",
      height: "1.2em"
    }
  }
}));

export { useStyles };

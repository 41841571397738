import { PropTypes } from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
    defaultTruncatedTextEnding: PropTypes.string
};

/**
 * Defines the default props
 * Disabled linting for the regex
 * @see https://eslint.org/docs/rules/no-useless-escape
 */
const defaultProps = {
    defaultTruncatedTextEnding: "..."
};

/**
 * Defines the main hook
 */
const useUtils = props => {
    const { defaultTruncatedTextEnding } = defaultProps;

    /**
     * Truncates the input
     */
    const truncateText = (str, length, ending) => {
        if (length === null) length = 100;
        const endingString = ending ? ending : defaultTruncatedTextEnding;

        if (str.length > length) {
            return str.substr(0, length - endingString.length) + endingString;
        } else {
            return str;
        }
    };

    /**
     * Compares the endDate of the experiences
     * Used to compare work exp, educations, certificates.
     * Sort order (latest first, oldest last)
     */
    const compareDates = (item1, item2) => {
        if (item1.present) return -1;
        if (item2.present) return 1;
        if (item1.present && item2.present) return 0;

        const dateA = new Date(item1.endDate);
        const dateB = new Date(item2.endDate);
        let comparison = 0;

        if (dateA < dateB) {
            comparison = 1;
        } else if (dateA > dateB) {
            comparison = -1;
        }
        return comparison;
    };

    /**
     * Sorts by id - integers
     */
    const sortById = (item1, item2) => {
        let comparison = 0;

        if (item1.id < item2.id) {
            comparison = -1;
        } else if (item1.id > item2.id) {
            comparison = 1;
        }
        return comparison;
    };

    /**
     * Sorts by name
     */
    const sortByName = (item1, item2) => {
        let comparison = 0;

        if (item1.name < item2.name) {
            comparison = -1;
        } else if (item1.name > item2.name) {
            comparison = 1;
        }
        return comparison;
    };

    /**
     * Separates the filder models into the previous filters and the one that is being changed.
     */
    const separateFilterModels = (models, target) => {
        const previousFilterModels = models.filter(
            model => model.field !== target
        );

        let currentFilterModel = models.filter(model => model.field === target);
        return {
            previousFilterModels,
            currentFilterModel: currentFilterModel[0]
        };
    };

    /**
     * Gets the difference in days between the date provided and the current date
     */
    const getDateDifference = date => {
        const today = new Date();
        const dateToTest = new Date(date);
        const timeDifference = today.getTime() - dateToTest.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);

        return daysDifference;
    };

    /**
     * Handles the tax calculations
     */
    const tax = props => {
        const { basePrice, ecoTax } = props;
        const TVA = 0.19;
        const addedTaxPrice = Number(basePrice) + Number(ecoTax);
        const result = parseFloat(addedTaxPrice + addedTaxPrice * TVA);
        const taxPrice = result.toFixed(2);

        return { taxPrice: parseFloat(Number(taxPrice)).toFixed(2) };
    };

    /**
     * Handles formatting a number to have commas
     */
    const formatNumber = number => {
        if (!number) return;
        var regex = /(\d)(?=(\d{3})+(?:(\.)\d+)?$)/g;
        return (+number).toFixed(2 || 0).replace(regex, function(a, b, c, d) {
            return d ? b + "," : b;
        });
    };

    /**
     * Gets the prices for billing
     */
    const getPrices = cart => {
        let noTVATotal = 0;
        let TVATotal = 0;
        let shippingTotal = 0;

        cart.forEach(item => {
            noTVATotal +=
                parseFloat(item.price_net * item.qty) +
                parseFloat(item.qty * 15);
            shippingTotal += parseFloat(item.qty * 15);
        });

        TVATotal = noTVATotal * 0.19;

        const finalTotal = Number(
            parseFloat(noTVATotal) + parseFloat(TVATotal)
        );

        return {
            noTVATotal: Number(parseFloat(noTVATotal).toFixed(2)),
            TVATotal: Number(parseFloat(TVATotal).toFixed(2)),
            shippingTotal: Number(parseFloat(shippingTotal).toFixed(2)),
            finalTotal: Number(parseFloat(finalTotal).toFixed(2))
        };
    };

    const getInitials = name =>
        name
            .replace(/[^A-Za-z0-9À-ÿ ]/gi, "") // taking care of accented characters as well
            .replace(/ +/gi, " ") // replace multiple spaces to one
            .split(/ /) // break the name into parts
            .reduce((acc, item) => acc + item[0], "") // assemble an abbreviation from the parts
            .concat(name.substr(1)) // what if the name consist only one part
            .concat(name) // what if the name is only one character
            .substr(0, 2) // get the first two characters an initials
            .toUpperCase(); // uppercase, but you can format it with CSS as well

    return {
        getInitials,
        tax,
        getPrices,
        formatNumber,
        truncateText,
        compareDates,
        separateFilterModels,
        sortById,
        sortByName,
        getDateDifference
    };
};

useUtils.propTypes = propTypes;
useUtils.defaultProps = defaultProps;

export default useUtils;

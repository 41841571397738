import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate, sub } from "date-fns";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import PrintTimesheetReport, {
  PrintTimesheetReportDefaultProps,
  PrintTimesheetReportPropTypes,
} from "../PrintTimesheetReport";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";

/**
 * Hooks
 */
import { useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TimesheetReport.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./TimesheetReport.defaults";

/**
 * Defines the prop types
 */
const propTypes = {
  printReport: PropTypes.shape(PrintTimesheetReportPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  defaultSearch: PropTypes.object,
  activeView: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  printReport: PrintTimesheetReportDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  loadingText: LoadingTextDefaultProps,
  organizations: [],
  defaultSearch: defaults.searchParams,
  activeView: null,
};

/**
 * Displays the component
 */
const TimesheetReport = (props) => {
  const {
    activeView,
    input,
    button,
    errorMessages,
    organizations,
    loadingText,
    printReport,
    defaultSearch,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the print loading state
   */
  const [printLoading, setPrintLoading] = useState(false);

  /**
   * Initializes the updated flag, used to trigger getting the workers after an attendance update
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the workers list
   */
  const [workers, setWorkers] = useState([]);

  const [searchedData, setSearchedData] = useState({
    organization: "",
    fromDate: new Date(),
    toDate: new Date(),
  });

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the workers table
   */
  const [resultsTable, setResultsTable] = useState([]);

  /**
   * Initializes the start date
   */
  const [fromDate, setFromDate] = useState(sub(new Date(), { days: 7 }));

  /**
   * Initializes the end date
   */
  const [toDate, setToDate] = useState(new Date());

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  /**
   * Initializes the order (asc/desc)
   */
  const [order, setOrder] = useState("asc");

  const [organizationsList, setOrganizationsList] = useState([]);

  /**
   * Initializes the order by field
   */
  const [orderBy, setOrderBy] = useState(t("operatorName"));

  /**
   * Initializes the component ref, used for printing the component
   */
  const componentRef = useRef();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Defines the handle print function
   */
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**
   * Handles updating the active organization
   */
  const handleOrganizationChange = (e) => setActiveOrg(e.target.value);

  /**
   * Handles getting the worker's name
   */
  const getWorkerName = (id) => {
    const worker = workers.find((worker) => worker.id === id);
    return worker ? worker.name : "";
  };

  /**
   * Handles getting the organization's name
   */
  const getOrganizationName = (id) => {
    if (id === "all") return t("total");
    const foundOrg = organizations.find((org) => org.value === id);
    return foundOrg ? foundOrg.label : "";
  };

  /**
   * Handles initiating the printing
   */
  const printComponent = () => {
    setPrintLoading(true);
  };

  /**
   * Gets the default search model
   */
  const getDefaultSearchData = () => {
    const orgIdModel = { ...defaultSearch.models[0] };
    orgIdModel["selected"] = activeOrg ? activeOrg : user.organization.id;

    return {
      ...defaultSearch,
      models: [...defaultSearch.models, { ...orgIdModel }],
    };
  };

  /**
   * Handles updating the start date value
   */
  const handleFromDate = (value) => setFromDate(value);

  /**
   * Handles updating the end date
   */
  const handleToDate = (value) => setToDate(value);

  const getSearchOrgIdValue = () => {
    if (activeOrg === "all") return null;
    if (!activeOrg) return user.organization.id;
    return activeOrg;
  };

  /**
   * Handles searching for attendances
   */
  const searchAttendance = async (data) => {
    try {
      const attendances = await apiClient.post("/attendances/search", data);
      if (attendances) {
        const { data } = attendances;
        const { items } = data;

        /**
         * Resets the loading and updated flag
         */
        setLoading(false);
        setUpdated(false);

        /**
         * Initializes the results array
         */
        let results = [];

        /**
         * Gets the unique workers (array of worker id's)
         */
        const workers = getUniqueWorkers(items);

        /**
         * Builds the results array
         */
        workers.forEach((worker, idx) => {
          /**
           * Gets the duplicate workers
           */
          const duplicates = getWorkerDuplicates(items, worker);

          /**
           * Initializes the worked hours
           */
          let worked_hours = 0;

          /**
           * For each duplicate, increment the total worked hours
           */
          duplicates.forEach((item) => (worked_hours += item.worked_hours));

          /**
           * Gets the worker data
           */
          const workerData = getWorkerData(items, worker);

          /**
           * Fill up the results array with the built object
           */
          results.push({
            ...workerData,
            worked_hours: worked_hours || workerData.worked_hours,
            name: getWorkerName(workerData.worker_id),
            idx: idx + 1,
          });
        });

        setResultsTable(results);
        setSearchedData({
          organization: getOrganizationName(activeOrg),
          fromDate: formatDate(new Date(fromDate), "dd-MM-yyyy"),
          toDate: formatDate(new Date(toDate), "dd-MM-yyyy"),
        });
      }
    } catch (error) {
      setUpdated(false);
      setLoading(false);
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles the search
   */
  const handleSearch = () => {
    setLoading(true);

    /**
     * Defines the org id model
     */
    const orgIdModel = { ...defaultSearch.models[0] };
    orgIdModel["selected"] = getSearchOrgIdValue();

    /**
     * Defines the start date model
     */
    const fromDateModel = { ...defaultSearch.models[1] };
    fromDateModel["selected"] = formatDate(new Date(fromDate), "yyyy-MM-dd");

    /**
     * Defines the end date model
     */
    const toDateModel = { ...defaultSearch.models[2] };
    toDateModel["selected"] = formatDate(new Date(toDate), "yyyy-MM-dd");

    /**
     * Builds the request body
     */
    const data = {
      ...defaultSearch,
      models: [{ ...orgIdModel }, { ...fromDateModel }, { ...toDateModel }],
    };
    searchAttendance(data);
  };

  /**
   * Gets the order by field
   */
  const getOrderByField = (orderBy) => {
    switch (orderBy) {
      case t("countSymbol"):
        return "idx";
      case t("operatorName"):
        return "name";
      case t("totalHours"):
        return "worked_hours";
      default:
        break;
    }
  };

  /**
   * Defines the comparator
   */
  const descendingComparator = (a, b, orderBy) => {
    const orderByField = getOrderByField(orderBy);

    if (b[orderByField] < a[orderByField]) return -1;
    if (b[orderByField] > a[orderByField]) return 1;
    return 0;
  };

  /**
   * Handles getting the comparator
   */
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /**
   * Handles sorting the array
   */
  const stableSort = (array, comparator) => {
    const formattedArray = array.map((el, index) => [el, index]);
    formattedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return formattedArray.map((el) => el[0]);
  };

  /**
   * Handles updating the sorting related states
   */
  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * Handles creating the sort handler
   */
  const createSortHandler = (property) => (event) => {
    if (property === t("countSymbol")) return;
    handleSort(event, property);
  };

  /**
   * Handles getting the unique workers
   */
  const getUniqueWorkers = (dataPool) => {
    return dataPool
      .map((item) => item.worker_id)
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  /**
   * Gets the worker duplicates
   */
  const getWorkerDuplicates = (workers, worker_id) => {
    return workers.filter((worker) => worker.worker_id === worker_id);
  };

  /**
   * Gets the worker data
   */
  const getWorkerData = (workers, worker_id) =>
    workers.find((worker) => worker.worker_id === worker_id);

  /**
   * Handles resetting the search form
   */
  const resetSearch = () => {
    setLoading(true);
    setActiveOrg(user.organization.id);
    setFromDate(sub(new Date(), { days: 7 }));
    setToDate(new Date());
    setSearchedData({
      organization: getOrganizationName(activeOrg),
      fromDate: formatDate(sub(new Date(), { days: 7 }), "dd-MM-yyyy"),
      toDate: formatDate(new Date(), "dd-MM-yyyy"),
    });
    searchAttendance(getDefaultSearchData());
  };

  /**
   * Handles getting the print button content
   */
  const getPrintButtonContent = () =>
    printLoading ? (
      <CircularProgress size="1.45rem" className={classes.loader} />
    ) : (
      <PrintIcon className={classes.icon} />
    );

  /**
   * Gets the time range string
   */
  const getTimeRangeString = () => {
    const { fromDate, toDate } = searchedData;

    return (
      <Fragment>
        <BoldSpan>{t("fromLabel")}</BoldSpan>
        {` ${fromDate.length < 11 ? fromDate : ""} / `}
        <BoldSpan>{t("toLabel")}</BoldSpan>
        {` ${toDate.length < 11 ? toDate : ""}`}
      </Fragment>
    );
  };

  /**
   * Defines the timesheet report title component
   */
  const TimesheetReportTitle = () => {
    return (
      <div className={classes.titleContainer}>
        <Typography varianat="h3" className={classes.title}>
          {t("timesheetReport")} {searchedData.organization}
        </Typography>
        <Typography variant="caption" className={classes.titleDates}>
          {getTimeRangeString()}
        </Typography>
      </div>
    );
  };

  /**
   * Defines the Print Button component
   */
  const PrintButton = () => {
    return (
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.printButton}
        title={t("printReport")}
        onClick={printComponent}
      >
        {getPrintButtonContent()}
      </Button>
    );
  };

  /**
   * Defines the Hidden Print Time Sheet Component
   */
  const HiddenPrintTimesheet = () => {
    const tableData = stableSort(resultsTable, getComparator(order, orderBy));

    return (
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <PrintTimesheetReport
            {...printReport}
            headCells={headCells}
            resultsTable={tableData}
            getWorkerName={getWorkerName}
            setReadyToPrint={setReadyToPrint}
            printLoading={printLoading}
            title={<TimesheetReportTitle />}
          />
        </div>
      </div>
    );
  };

  /**
   * Defines the Search Actions Component
   */
  const SearchActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.resetButton}
          onClick={resetSearch}
        >
          <UndoIcon />
        </Button>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.submitButton}
          onClick={handleSearch}
        >
          <LoadingText
            {...loadingText}
            size={24}
            loading={loading}
            text={t("submitSearch")}
            icon={<SearchIcon />}
          />
        </Button>
      </div>
    );
  };

  /**
   * Defines the Bold Span component
   */
  const BoldSpan = ({ children }) => (
    <span className={classes.bold}>{children}</span>
  );

  /**
   * Defines the Table data component
   */
  const TableData = () => {
    const tableData = stableSort(resultsTable, getComparator(order, orderBy));

    return tableData.map((row, idx) => {
      const worked_hours =
        row.worked_hours >= 0.1 ? parseFloat(row.worked_hours).toFixed(1) : 0;
      return (
        <TableRow key={row.id}>
          <TableCell>{idx + 1}</TableCell>
          <TableCell>{getWorkerName(row.worker_id)}</TableCell>
          <TableCell>{worked_hours}</TableCell>
        </TableRow>
      );
    });
  };

  /**
   * Defines the header cells
   */
  const headCells = [
    { label: t("countSymbol") },
    { label: t("operatorName") },
    { label: t("totalHours") },
  ];

  useEffect(() => {
    if (organizations.length > 0) {
      const list = [
        {
          label: t("all"),
          value: "all",
        },
        ...organizations,
      ];
      setOrganizationsList(list);
    }
    // eslint-disable-next-line
  }, [organizations]);

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setPrintLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Gets the workers by setting the api call params
   */
  useEffect(() => {
    if (activeView === 2 || updated) {
      setActiveOrg(user.organization.id);
      setFromDate(sub(new Date(), { days: 7 }));
      setToDate(new Date());
      searchAttendance(getDefaultSearchData());
    }
    // eslint-disable-next-line
  }, [activeView, updated]);

  /**
   * Handles updating the active organization on mount
   */
  useEffect(() => {
    if (activeView === 2 && organizations && organizations.length > 0) {
      setActiveOrg(user.organization.id);
    }
    // eslint-disable-next-line
  }, [activeView, organizations]);

  useEffect(() => {
    if (user.workers) setWorkers(user.workers);
  }, [user]);

  return activeView === 2 ? (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Card className={classes.blank}>
          <CardContent>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={12} md={2} lg={3} xl={3}>
                <Input
                  {...input}
                  className={classes.dateField}
                  type="date"
                  inputDate={{
                    value: fromDate,
                    onChange: handleFromDate,
                    margin: "normal",
                    label: t("fromLabel"),
                    variant: "outlined",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={3} xl={3}>
                <Input
                  {...input}
                  className={classes.dateField}
                  type="date"
                  inputDate={{
                    value: toDate,
                    onChange: handleToDate,
                    margin: "normal",
                    label: t("toLabel"),
                    variant: "outlined",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    value: activeOrg,
                    onChange: handleOrganizationChange,
                    label: t("organization_idLabel"),
                    labelClass: classes.label,
                    variant: "outlined",
                    options: organizationsList,
                    optionLabel: "label",
                    optionValue: "value",
                    showHelper: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SearchActions />
              </Grid>
              <HiddenPrintTimesheet />
              <Grid item xs={12}>
                <TimesheetReportTitle />
                <TableContainer>
                  <PrintButton />
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => {
                          const { label } = headCell;

                          const active = orderBy === label;
                          const sortDirection = active ? order : false;
                          const isSortCell = label !== t("countSymbol");
                          const direction = active ? order : "asc";
                          const handleClick = createSortHandler(label);

                          /**
                           * Defines the Cell component that allows for sorting
                           */
                          const sortingCell = (
                            <TableSortLabel
                              active={active}
                              IconComponent={KeyboardArrowDownIcon}
                              direction={direction}
                              onClick={handleClick}
                            >
                              {label}
                            </TableSortLabel>
                          );

                          return (
                            <TableCell
                              key={headCell.label}
                              sortDirection={sortDirection}
                            >
                              {isSortCell ? sortingCell : label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableData />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ) : null;
};

TimesheetReport.propTypes = propTypes;
TimesheetReport.defaultProps = defaultProps;

export default TimesheetReport;
export {
  propTypes as TimesheetReportPropTypes,
  defaultProps as TimesheetReportDefaultProps,
};

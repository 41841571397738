import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import AddIcon from "@material-ui/icons/Add";

/**
 * Styles Imports
 */
import { useStyles } from "./CreateButton.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.any,
  text: PropTypes.string,
  title: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  type: "button",
  variant: "filled",
  onClick: () => {},
  className: "",
  icon: "",
  text: "",
  title: ""
};

/**
 * Displays the component
 */
const CreateButton = props => {
  const {
    button,
    type,
    variant,
    onClick,
    className,
    icon,
    text,
    title
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the button classes
   */
  const btnClasses = clsx(classes.createButton, {
    [className]: className ? true : false
  });

  /**
   * Handles rendering the icon
   */
  const renderIcon = () => {
    if (icon !== false) {
      return icon ? icon : <AddIcon className={classes.icon} />;
    }
  };

  return (
    <Button
      {...button}
      type={type}
      variant={variant}
      onClick={onClick}
      className={btnClasses}
      title={title}
    >
      {renderIcon()}
      {text}
    </Button>
  );
};

CreateButton.propTypes = propTypes;
CreateButton.defaultProps = defaultProps;

export default CreateButton;
export {
  propTypes as CreateButtonPropTypes,
  defaultProps as CreateButtonDefaultProps
};

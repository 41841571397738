import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditOrgInfoModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditOrgInfoModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    vat_number: PropTypes.string,
    reg_number: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    webpage: PropTypes.string,
  }),
  orgData: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    vat_number: "",
    reg_number: "",
    phone: "",
    email: "",
    webpage: "",
  },
  orgData: {
    company: {},
  },
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditOrgInfoModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    orgData,
    open,
    loadingText,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    errorMessages,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user setter
   */
  const { setUser } = useUser();

  /**
   * Gets the org info data
   */
  const { company } = orgData;

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { vat_number, reg_number, phone, email, webpage } = inputs;

  const {
    vat_number: vat_numberError,
    reg_number: reg_numberError,
    phone: phoneError,
    email: emailError,
    webpage: webpageError,
  } = errors;

  /**
   * Handles updating the user organization data
   */
  const updateUserOrganizationData = async (data, id) => {
    try {
      const updatedOrgData = await apiClient.put(`/companies/${id}`, data);
      if (updatedOrgData) {
        getUserOrganizations();
      }
    } catch (error) {
      setLoading(false);
      setReady(false);

      /**
       * Dispatch the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles getting the user organizations
   */
  const getUserOrganizations = async () => {
    try {
      const organizations = await apiClient.get("/organizations");
      if (organizations) {
        setUser((prevState) => {
          return { ...prevState, organizations: organizations.data };
        });
        getAccBaseData();
      }
    } catch (error) {
      setReady(false);
    }
  };

  /**
   * Handles getting the account base data
   */
  const getAccBaseData = async () => {
    try {
      const accData = await apiClient.get("/accounts");
      if (accData) {
        const { data } = accData;
        setLoading(false);
        resetInputs();

        /**
         * Dispatch the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });
        /**
         * Orders the modules
         */
        const modules = Array.isArray(data.modules)
          ? data.modules.sort((module_A, module_B) =>
              module_A.order > module_B.order ? 1 : -1
            )
          : data.modules;

        setUser((prevState) => {
          return {
            ...prevState,
            ...data,
            modules,
          };
        });
        handleCloseModal();
      }
    } catch (error) {
      dispatchMessage({
        severity: "error",
        message: error,
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setReady(false);
    setErrors({});
    closeModal();
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        vat_number,
        reg_number,
        phone,
        email,
      };

      if (webpage) data["webpage"] = webpage;

      updateUserOrganizationData(data, company.id);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the input with the company data
   */
  useEffect(() => {
    if (company) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          vat_number: company.vat_number ? company.vat_number : "",
          reg_number: company.reg_number ? company.reg_number : "",
          phone: company.phone ? company.phone : "",
          email: company.email ? company.email : "",
          webpage: company.webpage ? company.webpage : "",
        };
      });
    }
    // eslint-disable-next-line
  }, [company, open]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleEditGeneralInfo")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-org-info-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "vat_number",
                              required: true,
                              name: "vat_number",
                              value: vat_number,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("vat_numberLabel"),
                              error: vat_numberError,
                              autoFocus: getAutoFocus().vat_number,
                            }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "reg_number",
                              required: true,
                              name: "reg_number",
                              value: reg_number,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("reg_numberLabel"),
                              error: reg_numberError,
                              autoFocus: getAutoFocus().reg_number,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                            {...input}
                            type="phone"
                            className={classes.field}
                            inputPhone={{
                              id: "phone",
                              required: true,
                              name: "phone",
                              value: phone,
                              onChange: handleInputChange,
                              placeholder: "",
                              variant: "standard",
                              label: t("phoneLabel"),
                              error: phoneError,
                              autoFocus: getAutoFocus().phone,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "email",
                              required: true,
                              name: "email",
                              value: email,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("emailLabel"),
                              error: emailError,
                              autoFocus: getAutoFocus().email,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "webpage",
                              name: "webpage",
                              value: webpage,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("webpageLabel"),
                              error: webpageError,
                              autoFocus: getAutoFocus().webpage,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-org-info-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditOrgInfoModal.propTypes = propTypes;
EditOrgInfoModal.defaultProps = defaultProps;

export default EditOrgInfoModal;
export {
  propTypes as EditOrgInfoModalPropTypes,
  defaultProps as EditOrgInfoModalDefaultProps,
};

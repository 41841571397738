import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * External Imports
 */
import { useLocation, matchPath } from "react-router-dom";

/**
 * Component Imports
 */
import Sidebar, { SidebarDefaultProps, SidebarPropTypes } from "../Sidebar";

/**
 *  Material UI Imports
 */
import DonutSmallOutlinedIcon from "@material-ui/icons/DonutSmallOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./CarServiceSidebar.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(SidebarPropTypes),

  paths: PropTypes.shape({
    main: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */

const defaultProps = {
  sidebar: SidebarDefaultProps,

  paths: {},
};

/**
 * Displays the component
 */
const CarServiceSidebar = (props) => {
  const { sidebar, paths } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the paths from the components
   */
  const { main } = paths;

  /**
   * Gets the location path
   */
  const location = useLocation();

  /**
   * Gets the module title
   */
  const getModuleTitle = () => {
    /**
     * Checks if the current path matches the path of the menu item
     */
    const checkPathMatch = (pathName) => {
      const result = matchPath(location.pathname, pathName);
      return result ? (result.isExact ? true : false) : false;
    };

    if (checkPathMatch(main)) return t("work-in-progress");
    return t("notFound");
  };

  /**
   * Configures the sidebar menu
   */
  const config = {
    moduleTitleFn: getModuleTitle,
    menu: [
      {
        path: main,
        text: "dashboard",
        icon: <DonutSmallOutlinedIcon className={classes.icon} />,
      },
    ],
  };

  return <Sidebar {...sidebar} {...config} />;
};

CarServiceSidebar.propTypes = propTypes;
CarServiceSidebar.defaultProps = defaultProps;

export default CarServiceSidebar;
export {
  propTypes as CarServiceSidebarPropTypes,
  defaultProps as CarServiceSidebarDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Styles Imports
 */
import { useStyles } from "./ProductsSearchForm.styles";

/**
 * Defaults Imports
 */
import { defaults } from "../WorkstationSettingsProducts/WorkstationSettingsProducts.defaults";

/**
 * Hooks
 */
import { useUser, useApiClient, useForm, useMessage } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  defaultValues: PropTypes.shape({
    quick_text: PropTypes.string,
    work_order_type: PropTypes.string,
    car_type: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
    organization: PropTypes.string,
    name: PropTypes.string,
    um: PropTypes.string,
    price: PropTypes.string,
    is_count_price: PropTypes.bool,
  }),
  orderByList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "products",
  defaultValues: {
    quick_text: "",
    work_order_type: "",
    car_type: "",
    orderBy: "price",
    orderDir: "asc",
    organization: "",
    name: "",
    um: "",
    price: "",
    is_count_price: false,
  },
  orderByList: defaults.orderBy,
  pageSizeOptions: defaults.pageSizeOptions,
  path: "/products",
};

/**
 * Displays the component
 */
const ProductsSearchForm = (props) => {
  const {
    activeTab,
    form,
    input,
    button,
    defaultValues,
    tabChanged,
    setTabChanged,
    defaultSearch,
    setInputs: updateInputs,
    setModels: updateModels,
    setTotal,
    pageSize,
    setPageSize,
    setPageCount,
    setBackdropLoading,
    setUpdated,
    orderByList,
    loadingText,
    resetFilters,
    setResetFilters,
    handleDefaultSearch,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes organizations list
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the car types list
   */
  const [carTypes, setCarTypes] = useState([]);

  /**
   * Initializes the work order types list
   */
  const [workOrderTypes, setWorkOrderTypes] = useState([]);

  /**
   * Initializes the order by options
   */
  const [orderByOptions, setOrderByOptions] = useState([
    { label: t("price"), value: "price" },
  ]);

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Handles searching products
   */
  const searchProducts = async (data) => {
    try {
      const products = await apiClient.post("/products/search", data);
      if (products) {
        const { data } = products;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, products: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        updateModels(models);

        /**
         * Handles updating the inputs
         */
        const handlePrevState = (prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        };

        setInputs((prevState) => handlePrevState(prevState));
        updateInputs((prevState) => handlePrevState(prevState));

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];

    /**
     * Gets the inputs
     */
    const {
      quick_text,
      work_order_type,
      car_type,
      organization,
      name,
      um,
      price,
      is_count_price,
    } = inputs;

    /**
     * Gets the default models
     */
    const {
      quickTextModel,
      organizationIdModel,
      nameModel,
      workOrderTypeModel,
      carTypeModel,
      umModel,
      priceModel,
      countPriceModel,
      serviceModel,
      expendableModel,
    } = defaults.models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model in order to update it
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      const modelItem = { ...model };
      modelItem["selected"] = input;
      searchModels.push(modelItem);
    };

    if (quick_text) {
      if (modelExists("quick_text")) {
        removeModel("quick_text");
        addModel(quick_text, quickTextModel);
      } else {
        addModel(quick_text, quickTextModel);
      }
    }

    if (organization) {
      let org = organization !== "all" ? organization : null;

      if (modelExists("organization_id")) {
        removeModel("organization_id");
        addModel(org, organizationIdModel);
      } else {
        addModel(org, organizationIdModel);
      }
    }

    if (name) {
      if (modelExists("name")) {
        removeModel("name");
        addModel(name, nameModel);
      } else {
        addModel(name, nameModel);
      }
    }

    if (work_order_type) {
      if (modelExists("work_order_type")) {
        removeModel("work_order_type");
        addModel(work_order_type, workOrderTypeModel);
      } else {
        addModel(work_order_type, workOrderTypeModel);
      }
    }

    if (car_type && car_type !== "all") {
      if (modelExists("car_type")) {
        removeModel("car_type");
        addModel(car_type, carTypeModel);
      } else {
        addModel(car_type, carTypeModel);
      }
    }

    if (um) {
      if (modelExists("um")) {
        removeModel("um");
        addModel(um, umModel);
      } else {
        addModel(um, umModel);
      }
    }

    if (price) {
      if (modelExists("price")) {
        removeModel("price");
        addModel(price, priceModel);
      } else {
        addModel(price, priceModel);
      }
    }

    if (is_count_price) {
      if (modelExists("is_count_price")) {
        removeModel("is_count_price");
        addModel(is_count_price, countPriceModel);
      } else {
        addModel(is_count_price, countPriceModel);
      }
    }

    if (modelExists("is_service")) {
      removeModel("is_service");
      addModel(activeTab === 1 ? true : false, serviceModel);
    } else {
      addModel(activeTab === 1 ? true : false, serviceModel);
    }

    if (modelExists("is_expendable")) {
      removeModel("is_expendable");
      addModel(activeTab === 2 ? true : false, expendableModel);
    } else {
      addModel(activeTab === 2 ? true : false, expendableModel);
    }

    return searchModels.length < 1 ? defaultSearch.models : searchModels;
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: 1,
    };

    searchProducts(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    quick_text,
    work_order_type,
    organization,
    car_type,
    price,
    um,
    is_count_price,
    name,
    orderBy,
    orderDir,
  } = inputs;

  /**
   * Handles resetting the filters
   */
  const handleResetFilters = () => {
    resetInputs();
    setBackdropLoading(true);
    setModels(defaults.models);
    updateModels(defaults.models);
    setPageCount(1);
    handleDefaultSearch();
  };

  /**
   * Updates the order by list
   */
  useEffect(() => {
    const orderBys = orderByList.map((orderBy) => {
      return {
        label: t(orderBy),
        value: orderBy,
      };
    });
    setOrderByOptions(orderBys);
    // eslint-disable-next-line
  }, [orderByList]);

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.organizations) {
      const organizations = [
        {
          name: t("all"),
          id: "all",
        },
        ...user.organizations,
      ].map((org) => {
        return {
          label: org.name,
          value: org.id,
        };
      });

      setOrganizations(organizations);
    }

    if (user.organization && user.organization.id) {
      if (!inputs.organization) {
        setInputs((prevState) => ({
          ...prevState,
          organization: user.organization.id,
        }));
      }
    }

    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const workorderTypes = user.settings.account.work_order_types;

      setCarTypes([{ id: "all", name: t("all") }, ...carTypes]);
      setWorkOrderTypes(workorderTypes);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    updateModels(defaultSearch.models);
    // eslint-disable-next-line
  }, []);

  /**
   * Handles the tab change
   */
  useEffect(() => {
    if (tabChanged) {
      updateModels(models);
      setTabChanged(false);
      setUpdated(true);
    }
    // eslint-disable-next-line
  }, [tabChanged]);

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false);
      handleResetFilters();
    }
    // eslint-disable-next-line
  }, [resetFilters]);

  useEffect(() => {
    updateInputs(inputs);
    // eslint-disable-next-line
  }, [inputs]);

  return (
    <Form {...form} onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={12} sm={6} md={3} lg={4} xl={4}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "quick_text",
                  value: quick_text,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("quick_textLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={4} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "work_order_type",
                  value: work_order_type,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("work_order_typeLabel"),
                  options: workOrderTypes,
                  optionLabel: "name",
                  optionValue: "id",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={4} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "car_type",
                  value: car_type,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("car_typeLabel"),
                  options: carTypes,
                  optionLabel: "name",
                  optionValue: "id",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "price",
                  value: price,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("priceLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "um",
                  value: um,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("umLabel"),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={2} lg={3} xl={4}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "organization",
                  value: organization,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("organizationLabel"),
                  options: organizations,
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={4}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "name",
                  value: name,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("nameLabel"),
                }}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderBy",
                  value: orderBy,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderByLabel"),
                  variant: "outlined",
                  options: orderByOptions,
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderDir",
                  value: orderDir,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderDirLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("asc"), value: "asc" },
                    { label: t("desc"), value: "desc" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Grid container spacing={1} wrap={"nowrap"}>
            <Grid item xs={12} container justify="center" direction="column">
              <div className={classes.btnContainer}>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  title={t("undo")}
                  onClick={handleResetFilters}
                  className={classes.undoBtn}
                >
                  <UndoIcon />
                </Button>
                <Button
                  {...button}
                  type="submit"
                  variant="filled"
                  onClick={handleSubmit}
                  className={classes.submitButton}
                >
                  <LoadingText
                    {...loadingText}
                    size={24}
                    loading={loading}
                    text={t("submitSearch")}
                    icon={<SearchIcon />}
                  />
                </Button>
              </div>
              <div className={classes.orderIDContainer}>
                <div className={classes.orderIDField}>
                  <Input
                    {...input}
                    type="checkbox"
                    className={classes.field}
                    inputCheckbox={{
                      name: "is_count_price",
                      value: is_count_price,
                      onChange: handleInputChange,
                      variant: "outlined",
                      labelClass: classes.label,
                      InputLabelProps: {
                        className: classes.label,
                      },
                      showHelper: false,
                      label: t("is_count_priceLabel"),
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

ProductsSearchForm.propTypes = propTypes;
ProductsSearchForm.defaultProps = defaultProps;

export default ProductsSearchForm;
export {
  propTypes as ProductsSearchFormPropTypes,
  defaultProps as ProductsSearchFormDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import shortid from "shortid";
import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";

/**
 * Styles Imports
 */
import { useStyles } from "./ModalTitle.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    title: PropTypes.string,
    iconContainer: PropTypes.string,
    icon: PropTypes.string,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  id: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  classes: {
    root: "",
    container: "",
    title: "",
    iconContainer: "",
    icon: "",
  },
  title: "",
  onClick: () => {},
  id: shortid.generate(),
};

/**
 * Displays the component
 */
const ModalTitle = (props) => {
  const { classes, onClick, id, title } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Defines the classes
   */
  const rootClasses = clsx(styles.dialogTitle, {
    [classes.root]: classes.root ? true : false,
  });
  const containerClasses = clsx(styles.container, {
    [classes.container]: classes.container ? true : false,
  });
  const titleClasses = clsx(styles.title, {
    [classes.title]: classes.title ? true : false,
  });
  const iconContainerClasses = clsx(styles.iconContainer, {
    [classes.iconContainer]: classes.iconContainer ? true : false,
  });
  const iconClasses = clsx(styles.icon, {
    [classes.icon]: classes.icon ? true : false,
  });

  /**
   * Handles rendering the title
   */
  const handleTitleRendering = () => {
    if (typeof title === "string") {
      return (
        <Typography variant="h3" className={titleClasses}>
          {title}
        </Typography>
      );
    }
    return title;
  };

  return (
    <DialogTitle id={id} className={rootClasses}>
      <div className={containerClasses}>
        {handleTitleRendering()}
        <div className={iconContainerClasses} title={t("close")}>
          <CloseIcon className={iconClasses} onClick={onClick} />
        </div>
      </div>
    </DialogTitle>
  );
};

ModalTitle.propTypes = propTypes;
ModalTitle.defaultProps = defaultProps;

export default ModalTitle;
export {
  propTypes as ModalTitlePropTypes,
  defaultProps as ModalTitleDefaultProps,
};

import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import DocumentViewer, {
  DocumentViewerDefaultProps,
  DocumentViewerPropTypes,
} from "../DocumentViewer";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
 * Hooks
 */
import { useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./DocumentModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.string,
  documentName: PropTypes.string,
  documentViewer: PropTypes.shape(DocumentViewerPropTypes),
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: "",
  documentName: "",
  documentViewer: DocumentViewerDefaultProps,
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const DocumentModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    documentName,
    document,
    updateDocument,
    title,
    documentViewer,
    open,
    closeModal,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Handles getting a document by name
   */
  const getDocument = async (name) => {
    const document = await apiClient.get(`/documents/official/${name}`, {
      responseType: "blob",
    });
    if (document) {
      const fileURL = generateUrl(document);
      updateDocument({
        name: documentName,
        document: fileURL,
      });
    }
  };

  /**
   * Generates a url for the pdf returned from the request
   */
  const generateUrl = (data) => {
    const blob = data;
    const file = new Blob([blob], {
      type: "application/pdf",
    });

    const url = URL.createObjectURL(file);
    return url;
  };

  /**
   * Renders the document viewer
   */
  const renderDocument = () => {
    return document ? (
      <DocumentViewer
        {...documentViewer}
        document={document}
        documentName={documentName}
      />
    ) : null;
  };

  /**
   * Gets the pdf
   */
  const pdf = renderDocument();

  /**
   * Gets the document if there's no document
   */
  useEffect(() => {
    if (!document) {
      getDocument(documentName);
    }
    // eslint-disable-next-line
  }, [document]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={closeModal}
        ariaLabel={documentName}
        keepMounted={false}
      >
        <ModalTitle
          {...modalTitle}
          id={documentName}
          title={title}
          onClick={closeModal}
        />
        <ModalContent {...modalContent} className={classes.content}>
          <div className={classes.pdfContainer}>{pdf}</div>
        </ModalContent>
        <ModalActions {...modalActions} onClick={closeModal} />
      </Modal>
    </Fragment>
  );
};

DocumentModal.propTypes = propTypes;
DocumentModal.defaultProps = defaultProps;

export default DocumentModal;
export {
  propTypes as DocumentModalPropTypes,
  defaultProps as DocumentModalDefaultProps,
};

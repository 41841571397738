/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  suspended: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    marginLeft: "1rem",
    background: theme.palette.warning.main,
    color: theme.palette.common.white,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    padding: "0.25rem 1rem",
  },
  statusIcon: {
    color: theme.palette.common.white,
    marginRight: "0.25rem",
  },
  deleted: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    marginLeft: "1rem",
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    padding: "0.25rem 1rem",
  },
  activeTo: {
    fontSize: "0.75rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    marginLeft: "1rem",
    background: theme.palette.sidebar.main,
    color: theme.palette.sidebar.text,
    padding: "0.25rem",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
  },
  activeToIcon: {
    color: theme.palette.success.main,
    marginRight: "0.25rem",
  },
  companyContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: "10px"
    }
  },
  company: {
    fontSize: "0.9rem",
    fontWeight: 800,
    color: theme.palette.common.white,
    fontFamily: theme.font.display,
  },
  navigation: {
    background: theme.palette.primary.main,
    boxShadow: "none",
    [theme.breakpoints.only('xs')]: {
      overflowY: "scroll"
    }
  },
  toolbar: {
    ...theme.mixins.flex.column,
    flex: 1,
    minHeight: 45,
    [theme.breakpoints.only('xs')]: {
      minWidth: "450px"
    }
  },
  container: {
    ...theme.mixins.flex.rowCenter,
    flex: 1,
  },
  flexContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  user: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    padding: "0 0.5rem",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    "& svg": {
      cursor: "pointer"
    }
  },
  userIcon: {
    marginRight: "0.25rem",
  },
  timesheet: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    padding: "0.5rem",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    cursor: "pointer",
    height: theme.navHeight,
    width: "max-content",
    "&:hover": {
      backgroundColor: theme.palette.hover.main,
    },
  },
  logout: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    padding: "0.5rem",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    cursor: "pointer",
    height: theme.navHeight,
    "&:hover": {
      backgroundColor: theme.palette.hover.main,
    },
  },
  logoutIcon: {
    marginRight: "0.25rem",
  },
  activeLanguageItem: {
    display: "flex",
    alignItems: "center",
    // padding: "1.3rem 0",
    "&:hover": {
      background: "transparent",
    },
    "& img": {
      maxWidth: "100%",
      width: 21,
      marginRight: 7,
      marginLeft: 7,
    },
    "& svg": {
      color: theme.palette.success.main,
      fontSize: "0.9rem",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  activeLanguage: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    fontWeight: 800,
    fontFamily: theme.font.family,
    padding: "0rem 1.5rem",
    borderRadius: 0,
    fontSize: "0.9rem",
    textTransform: "uppercase",
    cursor: "pointer",
    textDecoration: "none",
    transition: "padding 0.35s ease-in-out, background 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.secondary.main,
      "& svg": {
        color: theme.palette.primary.accent,
      },
    },
    "& $langMenuItem": {
      padding: 0,
    },
  },
  menuContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  dropdownContainer: {
    position: "absolute",
    top: 10,
    left: -5,
    background: theme.palette.common.white,
    minWidth: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#000",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    zIndex: 150,
  },
  langMenuItem: {
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#f3f3f3",
    },
    "& img": {
      maxWidth: "100%",
      width: 21,
      marginRight: 7,
      marginLeft: 7,
    },
    "& svg": {
      color: theme.palette.success.main,
      fontSize: "0.9rem",
      marginLeft: 10,
      marginRight: 10,
    },
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  box: {
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.sidebar.main,
    margin: "1rem 0",
    padding: "0.5rem 2rem",
    paddingBottom: "1rem",
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.common.white
    }
  }
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicSearchForm, {
  DynamicSearchFormPropTypes,
  DynamicSearchFormDefaultProps,
} from "../DynamicSearchForm";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  dynamicSearch: PropTypes.shape(DynamicSearchFormPropTypes),
  apiCallSetter: PropTypes.func,
  setBackdropLoading: PropTypes.func,
  resetFilters: PropTypes.bool,
  setActiveOrg: PropTypes.func,
  setData: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  dynamicSearch: DynamicSearchFormDefaultProps,
  apiCallSetter: () => {},
  setBackdropLoading: () => {},
  resetFilters: false,
  setActiveOrg: () => {},
  setData: () => {},
};

/**
 * Displays the component
 */
const ReportsWorkersSearch = (props) => {
  const {
    dynamicSearch,
    setBackdropLoading,
    setActiveOrg,
    setApiCallMade,
    setData,
    updateInputs,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the table options
   */
  const [searchOptions, setSearchOptions] = useState({});

  /**
   * Initializes the form options
   */
  const [formOptions, setFormOptions] = useState({});

  /**
   * Initializes the models
   */

  const [models, setModels] = useState({});

  /**
   * Initializes the user data ready flag
   */
  const [userDataReady, setUserDataReady] = useState(false);

  /**
   * Handles getting the organizations
   */
  const getUserOrganizations = () => {
    if (user.organizations) {
      const organizations = [
        {
          name: t("all"),
          id: "all",
        },
        ...user.organizations,
      ].map((org) => {
        return {
          name: org.name,
          id: org.id,
        };
      });

      return organizations;
    }
  };

  const getCarTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.car_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  const getWorkOrderTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.work_order_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  const getPaymentTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.payment_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  /**
   * Checks if the table options are ready
   */
  const isReady = (config) => Object.keys(config).length > 0;

  const carTypes = getCarTypes();
  const workOrderTypes = getWorkOrderTypes();
  const userOrganizations = getUserOrganizations();
  const paymentTypes = getPaymentTypes();

  const getDateIntervals = () => {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth() - 1;
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    const startDate = formatDate(new Date(firstDay), "yyyy-MM-dd");
    const endDate = formatDate(new Date(lastDay), "yyyy-MM-dd");

    return {
      startDate,
      endDate,
    };
  };

  /**
   * Handles getting the table options
   */
  const getSearchOptions = () => {
    const { startDate, endDate } = getDateIntervals();
    let searchOptions = {
      formID: "tyre-waste-reports",
      separateContainers: true,
      updateState: {
        key: "organization_id",
        fn: setActiveOrg,
      },
      cleanModels: (models) => {
        let newModels = [];

        models.forEach((model) => {
          if (model.field === "finished") {
            const dates = model.selected.trim().split(" - ");
            if (dates.length > 1) {
              const startDate = formatDate(
                new Date(dates[0]).setHours(0, 0, 0),
                "yyyy-MM-dd HH:mm"
              );
              const endDate = formatDate(
                new Date(dates[1]).setHours(23, 59, 59),
                "yyyy-MM-dd HH:mm"
              );

              newModels.push({
                ...model,
                selected: [startDate, endDate],
              });
              return;
            }
          }

          if (model.field === "organization_id") {
            newModels.push({
              ...model,
              selected: model.selected !== "all" ? model.selected : null,
            });
            return;
          }
          newModels.push({ ...model });
        });

        return newModels;
      },
      gridConfig: {
        justify: "flex-start",
        actionsContainer: {
          xs: 12,
          sm: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
      },
      submitBtnText: t("submitSearch"),
      grid: {
        form: {
          xs: 12,
          sm: 9,
        },
        actions: {
          xs: 12,
          sm: 3,
        },
      },
      defaultValues: {
        organization_id: user.organization && user.organization.id,
        work_order_type_id: workOrderTypes[2].id,
        payment_type_id: "",
        order_by: "created_at",
        order_dir: "desc",
        car_type_id: "",
        car_plate_number: "",
        finished: `${startDate} - ${endDate}`,
      },
    };

    return searchOptions;
  };

  const getFormOptions = () => {
    const formOptions = [
      {
        name: "organization_id",
        inputType: "select",
        label: t("organization_id"),
        grid: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 4,
          xl: 4,
        },
        props: {
          options: userOrganizations,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "work_order_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("work_order_type"),
        props: {
          options: workOrderTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "payment_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("payment_type_id"),
        props: {
          options: paymentTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "order_by",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("order_by"),
        props: {
          options: [{ id: "created_at", name: t("created_at") }],
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "order_dir",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("order_dir"),
        props: {
          options: [
            { id: "asc", name: t("asc") },
            { id: "desc", name: t("desc") },
          ],
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "car_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
        },
        label: t("car_type_id"),
        props: {
          options: carTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "car_plate_number",
        inputType: "text",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
        },
        label: t("car_plate_number"),
        props: {},
      },

      {
        name: "finished",
        inputType: "dateRange",
        grid: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        label: t("finished"),
        props: {},
      },
    ];

    return formOptions;
  };

  const getModels = () => {
    const { startDate, endDate } = getDateIntervals();

    const _startDate = formatDate(
      new Date(startDate).setHours(0, 0, 0),
      "yyyy-MM-dd HH:mm"
    );
    const _endDate = formatDate(
      new Date(endDate).setHours(23, 59, 59),
      "yyyy-MM-dd HH:mm"
    );

    const organizationID_model = {
      label: "Organization id",
      field: "organization_id",
      type: "dropdown",
      order: 2,
      options: [],
      selected: user && user.organization && user.organization.id,
    };

    const workOrderType_model = {
      label: "Work Order Type",
      field: "work_order_type_id",
      type: "dropdown",
      order: 1,
      options: [],
      selected: workOrderTypes[2].id,
    };

    const carTypeID_model = {
      label: "Car Type ID",
      field: "car_type_id",
      type: "equals",
      order: 1,
      options: [],
      selected: null,
    };

    const carPlateNumber_model = {
      label: "Car Plate Number",
      field: "car_plate_number",
      type: "like",
      order: 1,
      options: [],
      selected: null,
    };

    const finished_model = {
      label: "Finished",
      field: "finished",
      type: "range",
      order: 1,
      options: [],
      selected: [_startDate, _endDate],
    };

    const paymentType_model = {
      label: "Payment Type",
      field: "payment_type_id",
      type: "dropdown",
      order: 1,
      options: [],
      selected: null,
    };

    const models = {
      organization_id: organizationID_model,
      car_type_id: carTypeID_model,
      payment_type_id: paymentType_model,
      work_order_type_id: workOrderType_model,
      car_plate_number: carPlateNumber_model,
      finished: finished_model,
    };

    return models;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicSearchForm = () => {
    const ready =
      userDataReady &&
      isReady(searchOptions) &&
      isReady(formOptions) &&
      isReady(models);

    return ready ? (
      <DynamicSearchForm
        {...dynamicSearch}
        preventDefaultSearch={true}
        formOptions={formOptions}
        searchOptions={searchOptions}
        models={models}
        setApiCallMade={setApiCallMade}
        apiCallProps={{
          type: "workers-totaled",
          format: "normal",
        }}
        setBackdropLoading={setBackdropLoading}
        setData={setData}
        updateInputs={updateInputs}
      />
    ) : null;
  };

  const getConfig = () => {
    const searchOptions = getSearchOptions();
    const formOptions = getFormOptions();
    const models = getModels();

    return { searchOptions, formOptions, models };
  };

  useEffect(() => {
    if (userOrganizations && carTypes && workOrderTypes && paymentTypes) {
      setUserDataReady(true);
    }
  }, [userOrganizations, carTypes, workOrderTypes, paymentTypes]);

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    if (userDataReady) {
      const { searchOptions, formOptions, models } = getConfig();

      setSearchOptions(searchOptions);
      setFormOptions(formOptions);
      setModels(models);
    }

    // eslint-disable-next-line
  }, [userDataReady]);

  return renderDynamicSearchForm();
};

ReportsWorkersSearch.propTypes = propTypes;
ReportsWorkersSearch.defaultProps = defaultProps;

export default ReportsWorkersSearch;
export {
  propTypes as ReportsWorkersSearchPropTypes,
  defaultProps as ReportsWorkersSearchDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 660,
  },
  modulesContainer: {
    margin: "0.5rem 0",
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem",
    },
  },
  form: {
    background: "transparent",
    boxShadow: "none",
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
  },
  checkboxContainer: {
    marginTop: "1rem",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  checkbox: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
  },
  selectField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 15,
    },
  },
  submitBtn: theme.mixins.submitBtn,
  textarea: {
    marginBottom: "0.75rem",
    marginTop: "1rem",
  },
}));

export { useStyles };

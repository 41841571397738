import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import AddLoyaltyCardModal, {
  AddLoyaltyCardModalDefaultProps,
  AddLoyaltyCardModalPropTypes,
} from "../AddLoyaltyCardModal";
import EditLoyaltyCardModal, {
  EditLoyaltyCardModalDefaultProps,
  EditLoyaltyCardModalPropTypes,
} from "../EditLoyaltyCardModal";
import DeleteLoyaltyCardModal, {
  DeleteLoyaltyCardModalDefaultProps,
  DeleteLoyaltyCardModalPropTypes,
} from "../DeleteLoyaltyCardModal";
import LoyaltyCardsSearchForm, {
  LoyaltyCardsSearchFormDefaultProps,
  LoyaltyCardsSearchFormPropTypes,
} from "../LoyaltyCardsSearchForm";
import LoyaltyCards, {
  LoyaltyCardsDefaultProps,
  LoyaltyCardsPropTypes,
} from "../LoyaltyCards";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import OrganizationsTabs, {
  OrganizationsTabsDefaultProps,
  OrganizationsTabsPropTypes,
} from "../OrganizationsTabs";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import Pagination from "@material-ui/lab/Pagination";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkstationSettingsLoyaltyCards.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./WorkstationSettingsLoyaltyCards.defaults";

/**
 * Hooks
 */
import {
  useLocalStorage,
  useUser,
  useMessage,
  useApiClient,
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  addCardModal: PropTypes.shape(AddLoyaltyCardModalPropTypes),
  editCardModal: PropTypes.shape(EditLoyaltyCardModalPropTypes),
  deleteCardModal: PropTypes.shape(DeleteLoyaltyCardModalPropTypes),
  searchForm: PropTypes.shape(LoyaltyCardsSearchFormPropTypes),
  organizationTabs: PropTypes.shape(OrganizationsTabsPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  createButton: PropTypes.shape(CreateButtonPropTypes),
  loyaltyCards: PropTypes.shape(LoyaltyCardsPropTypes),
  input: PropTypes.shape(InputPropTypes),
  pageSizeOptions: PropTypes.array,
  defaultSearch: PropTypes.object,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  addCardModal: AddLoyaltyCardModalDefaultProps,
  editCardModal: EditLoyaltyCardModalDefaultProps,
  deleteCardModal: DeleteLoyaltyCardModalDefaultProps,
  searchForm: LoyaltyCardsSearchFormDefaultProps,
  organizationTabs: OrganizationsTabsDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  createButton: CreateButtonDefaultProps,
  loyaltyCards: LoyaltyCardsDefaultProps,
  input: InputDefaultProps,
  pageSizeOptions: defaults.pageSizeOptions,
  defaultSearch: defaults.searchParams,
  path: "/loyalty-cards",
};

/**
 * Displays the component
 */
const WorkstationSettingsLoyaltyCards = (props) => {
  const {
    title,
    wrapper,
    container,
    addCardModal,
    editCardModal,
    deleteCardModal,
    searchForm,
    organizationTabs,
    loadingBackdrop,
    createButton,
    loyaltyCards,
    input,
    defaultSearch,
    pageSizeOptions,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the selected organization
   */
  const [selectedOrg, setSelectedOrg] = useState("");

  /**
   * Initializes the clients list
   */
  const [clients, setClients] = useState([]);

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the organizations array
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the page size
   */
  const [pageSize, setPageSize] = useLocalStorage("loyalty_cardsPageSize");

  /**
   * Initializes total items
   */
  const [total, setTotal] = useState(0);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the updated flag
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the initial mount flag
   */
  const [initMount, setInitMount] = useState(true);

  /**
   * Initializes the state that hold the data for the edit modal
   */
  const [editData, setEditData] = useState({});

  /**
   * Initializes the state that hold the data for the delete modal
   */
  const [deleteData, setDeleteData] = useState({});

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Initializes the tab changed flag
   */
  const [tabChanged, setTabChanged] = useState(false);

  /**
   * Initializes the inputs state
   */
  const [inputs, setInputs] = useState({});

  /**
   * Initializes the changed value state of the tabs
   */
  const [valueChanged, setValueChanged] = useState(false);

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    addCard: false,
    editCard: false,
    deleteCard: false,
  });

  /**
   * Handles opening the edit modal
   */
  const handleEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const handleDelete = (props) => setDeleteData(props);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAddCard = () => handleOpenModal("addCard");
  const closeAddCard = () => handleCloseModal("addCard");

  /**
   * Defines the edit User modal handlers
   */
  const openEditCard = () => handleOpenModal("editCard");
  const closeEditCard = () => handleCloseModal("editCard");

  /**
   * Defines the delete User modal handlers
   */
  const openDeleteCard = () => handleOpenModal("deleteCard");
  const closeDeleteCard = () => handleCloseModal("deleteCard");

  /**
   * Gets the breakpoints for lower rez
   */
  const theme = useTheme();
  const isOnlyXL = useMediaQuery(theme.breakpoints.only("xl"));
  const isDownXl = useMediaQuery(theme.breakpoints.down("xl"));
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Gets the inputs
   */
  const { orderBy, orderDir } = inputs;

  /**
   * Handles getting the organization data based on the active tab
   */
  const getUserOrganization = (idx) => {
    if (idx === 0) return "any";
    let count = idx - 1;

    if (user.organizations) {
      const org = user.organizations[count];
      return org ? org.id : "";
    }
  };

  /**
   * Handles changing the tab value flag
   */
  const handleTabValueChange = () => {
    setValueChanged(true);
    setPageCount(1);
  };

  /**
   * Handles changing the tab value flag
   */
  const handleTabChange = () => {
    setTabChanged(true);
  };

  /**
   * Handles updating the states once the tab is updated
   */
  const handleTabUpdate = (newValue) => {
    setSelectedOrg(getUserOrganization(newValue));
    handleTabValueChange();
  };

  /**
   * Handles searching for loyalty cards
   */
  const searchLoyaltyCards = async (data) => {
    try {
      const cards = await apiClient.post("/loyalty-cards/search", data);
      if (cards) {
        const { data } = cards;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setUpdated(false);
        setTabChanged(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, loyalty_cards: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setInitMount(false);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setUpdated(false);
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
      setInitMount(false);
    }
  };

  /**
   * Checks if the pagination is allowed to change
   */
  const validatePaginationChange = () => {
    const { loyalty_cards } = user;
    if (loyalty_cards && loyalty_cards.length < 1 && pageCount === 0)
      return true;
    return false;
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (validatePaginationChange()) return;

    setBackdropLoading(true);

    /**
     * Defines the request body data
     */
    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page,
    };

    searchLoyaltyCards(data);
    window.scrollTo(0, 0);
  };

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Checks if the user is an admin
   */
  const isAdmin = () => user.type === "admin";

  /**
   * Finds the model by field
   */
  const findModelByField = (field) => {
    const { models } = defaultSearch;
    return models.find((model) => model.field === field);
  };

  /**
   * Handles updating the client model
   */
  const updateClientModel = (model) => {
    const { organization } = user;

    let updatedValue = selectedOrg === "any" ? "" : selectedOrg;
    if (!isAdmin()) updatedValue = organization.id;

    model["selected"] = updatedValue;
    return model;
  };

  /**
   * Handles building the default search models
   */
  const buildDefaultSearchModels = (otherModels) => {
    const { models: defaultModels, page_size: defaultPageSize } = defaultSearch;

    const page_size = pageSize || defaultPageSize;
    const models = [...defaultModels];
    const page_count = 1;

    if (otherModels && Object.keys(otherModels).length > 0) {
      models.push({ ...otherModels });
    }

    return { ...defaultSearch, models, page_size, page_count };
  };

  /**
   * Handles building the search models
   */
  const buildSearchModels = (baseModels, otherModels, pgCount, pageSize) => {
    /**
     * Eliminate potential duplicate org_ids objects
     */
    const modelsFiltered = baseModels.filter(
      (model) => model.field !== "organization_id"
    );

    /**
     * Defines the request body data for the search
     */
    const models = [...modelsFiltered, { ...otherModels }];
    const order_by = inputs.orderBy || defaultSearch.order_by;
    const order_dir = inputs.orderDir || defaultSearch.order_dir;
    const page_size = pageSize || defaultSearch.page_size;
    const page_count = pgCount || pageCount || 1;

    return { models, order_by, order_dir, page_size, page_count };
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const foundClientModel = findModelByField("organization_id");
    const clientModel = updateClientModel(foundClientModel);

    return buildDefaultSearchModels(clientModel);
  };

  /**
   * Handles constructing the data object for the search
   */
  const getSearchParams = (pgCount, pageSize) => {
    const foundClientModel = findModelByField("organization_id");
    const clientModel = updateClientModel(foundClientModel);

    return buildSearchModels(models, clientModel, pgCount, pageSize);
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchLoyaltyCards(getDefaultSearch());
  };

  /**
   * Handles searching for work orders
   */
  const handleSearch = (pgCount, pageSize) => {
    const data = getSearchParams(pgCount, pageSize);

    if (Object.keys(data).length > 0) {
      searchLoyaltyCards(data);
    }
  };

  /**
   * Handles getting the submodule title classes
   */
  const getTitleClasses = () =>
    clsx({
      [classes.marginsTitle]: !isAdmin(),
    });

  /**
   * Updates the organizations state using the user organizations
   */
  const handleUpdateOrganizations = () => {
    const { organization, organizations } = user;
    const { id: orgID } = organization;

    /**
     * Initializes the organizations list
     */
    let organizations_list = [];

    /**
     * Gets the user's own organization
     */
    const userOrganization = organizations.find((org) => org.id === orgID);
    const { id, name } = userOrganization;

    if (!isAdmin()) {
      organizations_list.push({ value: id, label: name });
    } else {
      organizations_list = organizations.map((org) => ({
        value: org.id,
        label: org.name,
      }));
    }

    return organizations_list;
  };

  /**
   * Gets the spinner classes
   */
  const getSpinnerClasses = () => {
    const { loyalty_cards } = user;
    const renderCondition = loyalty_cards && loyalty_cards.length > 1;

    return { spinner: renderCondition ? classes.spinner : "" };
  };

  /**
   * Handles pagination responsiveness by getting dynamic siblings
   */
  const getSiblingsCount = () => {
    let count = 4;
    if (pageCount > 105 && pageSize <= 15) return 3;
    if (pageCount > 999) return 3;
    if (isOnlyXL) return 4;
    if (isDownXl) count = 3;
    if (isDownMd || isDownLg) count = 2;
    if (isDownSm) count = 1;
    return count;
  };

  /**
   * Handles pagination responsiveness by getting dynamic boundaries
   */
  const getBoundaryCount = () => {
    let count = 4;
    if (pageCount > 105 && pageSize <= 15) return 4;
    if (pageCount > 999) return 3;
    if (isOnlyXL) return 4;
    if (isDownXl || isDownMd || isDownLg) count = 2;
    if (isDownSm) count = 1;
    return count;
  };

  /**
   * Handles the page size change
   */
  const handlePageSize = (e) => {
    setBackdropLoading(true);
    setPageSize(e.target.value);
    window.scrollTo(0, 0);
    handleSearch(1, e.target.value);
  };

  /**
   * Handles rendering the pagination
   */
  const CardsPagination = () => {
    /**
     * Gets the loyalty cards
     */
    const { loyalty_cards } = user;

    /**
     * Checks if items exists
     */
    const itemsExist = loyalty_cards && loyalty_cards.length > 0;

    /**
     * Defines the pagination properties
     */
    const count = Math.ceil(total / pageSize);
    const siblingCount = getSiblingsCount();
    const boundaryCount = getBoundaryCount();
    const paginationClasses = { root: classes.listRoot, ul: classes.list };

    return itemsExist ? (
      <div className={classes.footer}>
        <Pagination
          count={count}
          page={pageCount}
          siblingCount={siblingCount}
          size="large"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          boundaryCount={boundaryCount}
          classes={paginationClasses}
        />
        <div className={classes.pageSizeContainer}>
          <div className={classes.displayOption}>
            <Input
              {...input}
              className={classes.selectField}
              type="select"
              inputSelect={{
                name: "pageSize",
                value: pageSize,
                onChange: handlePageSize,
                labelClass: classes.label,
                label: t("pageSizeLabel"),
                variant: "outlined",
                options: pageSizeOptions,
              }}
            />
          </div>
        </div>
      </div>
    ) : null;
  };

  /**
   * Gets the clients
   */
  useEffect(() => {
    if (user.clients) {
      setClients(user.clients);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles triggering a search if updated
   */
  useEffect(() => {
    if (updated) handleSearch();
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles performing a search on tab change
   */
  useEffect(() => {
    if (valueChanged) {
      setBackdropLoading(true);
      handleTabChange();
      setValueChanged(false);
    }
  }, [valueChanged]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEditCard();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDeleteCard();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    handleDefaultSearch();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      const organizations = handleUpdateOrganizations();
      setOrganizations(organizations);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        className={getTitleClasses()}
        icon={<CardGiftcardIcon />}
        title={t("titleLoyaltyCards")}
      />
      <SubmoduleWrapper {...wrapper}>
        <OrganizationsTabs
          {...organizationTabs}
          renderCondition={isAdmin()}
          triggerChange={handleTabUpdate}
          setBackdropLoading={setBackdropLoading}
        />
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <LoyaltyCardsSearchForm
                  {...searchForm}
                  setInputs={setInputs}
                  setModels={setModels}
                  setTotal={setTotal}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  setPageCount={setPageCount}
                  setBackdropLoading={setBackdropLoading}
                  selectedOrg={selectedOrg}
                  tabChanged={tabChanged}
                  setTabChanged={setTabChanged}
                  setUpdated={setUpdated}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CreateButton
                {...createButton}
                className={classes.createBtn}
                text={t("addNewCard")}
                onClick={openAddCard}
              />
            </Grid>
            <Grid container spacing={2} className={classes.workOrder}>
              <LoyaltyCards
                {...loyaltyCards}
                initMount={initMount}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                clients={clients}
              />
            </Grid>
            <LoadingBackdrop
              {...loadingBackdrop}
              open={backdropLoading}
              classes={getSpinnerClasses()}
            />
            <Grid container className={classes.pagination}>
              <CardsPagination />
            </Grid>
            <AddLoyaltyCardModal
              {...addCardModal}
              open={modalState.addCard}
              closeModal={closeAddCard}
              setUpdated={setUpdated}
              organizations={organizations}
              defaultSearch={defaultSearch}
              clients={clients}
            />
            <EditLoyaltyCardModal
              {...editCardModal}
              open={modalState.editCard}
              closeModal={closeEditCard}
              editData={editData}
              setUpdated={setUpdated}
            />
            <DeleteLoyaltyCardModal
              {...deleteCardModal}
              open={modalState.deleteCard}
              closeModal={closeDeleteCard}
              deleteData={deleteData}
              setUpdated={setUpdated}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

WorkstationSettingsLoyaltyCards.propTypes = propTypes;
WorkstationSettingsLoyaltyCards.defaultProps = defaultProps;

export default WorkstationSettingsLoyaltyCards;
export {
  propTypes as WorkstationSettingsLoyaltyCardsPropTypes,
  defaultProps as WorkstationSettingsLoyaltyCardsDefaultProps,
};

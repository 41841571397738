/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;
  const { isEmpty, isEmail } = useValidator();

  const validatorFn = (props) => {
    const { name, value, inputs } = props;
    const { is_company } = inputs;
    let error = null;

    switch (name) {
      case "name":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "country":
      case "county":
      case "city":
      case "street":
      case "number":
      case "reg_number":
      case "vat_number":
        if (is_company && isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "email":
        if (is_company && value && !isEmail(value))
          return (error = t("LanguageProvider:error_emailInvalid"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./WorkerAttendanceModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkerAttendanceModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    password: PropTypes.string,
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  data: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    password: "",
  },
  open: false,
  closeModal: () => {},
  data: {},
};

/**
 * Displays the component
 */
const WorkerAttendanceModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    data,
    form,
    input,
    button,
    defaultValues,
    errorMessages,
    loadingText,
    attendanceID,
    setUpdated,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);

  /**
   * Gets the org name and id
   */
  const { name, id } = data;

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles creating an attendance
   */
  const createAttendance = async (data) => {
    try {
      const createdAttendance = await apiClient.post("/attendances", data);
      if (createdAttendance) {
        /**
         * Resets the loading state
         */
        setLoading(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          message: attendanceID
            ? t("successMessageExit")
            : t("successMessageEntry"),
        });
        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the loading state
       */
      setLoading(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles updating an attendance by id
   */
  const updateAttendance = async (data, id) => {
    try {
      const updatedAttendance = await apiClient.put(`/attendances/${id}`, data);
      if (updatedAttendance) {
        /**
         * Resets the loading state
         */
        setLoading(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          message: attendanceID
            ? t("successMessageExit")
            : t("successMessageEntry"),
        });
        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the loading state
       */
      setLoading(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handle setting the api call params
   */
  const handleApiCall = (data, id) => {
    return id ? updateAttendance(data, id) : createAttendance(data);
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    /**
     * Defines the request body
     */
    const data = {
      worker_id: id,
      password: inputs.password,
    };

    setLoading(true);
    handleApiCall(data, attendanceID);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { password } = inputs;
  const { password: passwordError } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();

    closeModal();
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAttendanceRule")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="confirmAttendance"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Card className={classes.blank}>
                    <CardContent className={classes.cardContent}>
                      <Grid container>
                        <Grid item container xs={12} justify="center">
                          <Typography className={classes.label}>
                            {t("workerLabel")}
                            <Typography
                              variant="caption"
                              className={classes.value}
                            >
                              {`${name}`}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} justify="center">
                        <Input
                          {...input}
                          type="password"
                          className={classes.field}
                          inputPassword={{
                            id: "password",
                            name: "password",
                            value: password,
                            onChange: handleInputChange,
                            maxSize: 70,
                            label: t("passwordLabel"),
                            variant: "outlined",
                            showHelper: !!passwordError,
                            InputLabelProps: {
                              className: classes.inputLabel,
                            },
                            error: passwordError,
                            autoFocus: getAutoFocus().password,
                          }}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            disabled={!password || passwordError}
            className={classes.submitBtn}
            form="confirmAttendance"
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("confirmAttendance")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

WorkerAttendanceModal.propTypes = propTypes;
WorkerAttendanceModal.defaultProps = defaultProps;

export default WorkerAttendanceModal;
export {
  propTypes as WorkerAttendanceModalPropTypes,
  defaultProps as WorkerAttendanceModalDefaultProps,
};

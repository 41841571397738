import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

/**
 * Component Imports
 */
import Span, { SpanDefaultProps, SpanPropTypes } from "../Span";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderHeader.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  span: PropTypes.shape(SpanPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  span: SpanDefaultProps,
};

/**
 * Displays the component
 */
const WorkOrderHeader = (props) => {
  const { span } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the user
   */
  const { user } = useUser();
  const { organization } = user;

  /**
   * Handles rendering the company data
   */
  const renderHeaderItems = () => {
    if (organization) {
      const {
        name,
        vat_number,
        reg_number,
        address,
        phone,
        email,
        webpage,
      } = organization;
      /**
       * Defines a separator string
       */
      const separator = ` | `;

      /**
       * Handles Rendering the organization name
       */
      const renderOrgName = () => {
        return name ? (
          <Fragment>
            {t("orgLabel")}
            <Span {...span} className={classes.value}>
              {name}
            </Span>
          </Fragment>
        ) : null;
      };

      /**
       * Handles Rendering the organization C.U.I
       */
      const renderOrgCui = () => {
        return vat_number ? (
          <Fragment>
            {separator}
            {t("cuiLabel")}
            <Span {...span} className={classes.value}>
              {vat_number}
            </Span>
          </Fragment>
        ) : null;
      };

      /**
       * Handles Rendering the organization reg number
       */
      const renderOrgRegNum = () => {
        return reg_number ? (
          <Fragment>
            {separator}
            {t("regLabel")}
            <Span {...span} className={classes.value}>
              {reg_number}
            </Span>
          </Fragment>
        ) : null;
      };

      /**
       * Handles Rendering the organization address
       */
      const renderOrgAddress = () => {
        return address ? (
          <Fragment>
            {t("adrLabel")}
            <Span {...span} className={classes.value}>
              {address}
            </Span>
          </Fragment>
        ) : null;
      };

      /**
       * Handles Rendering the organization phone
       */
      const renderOrgPhone = () => {
        return phone ? (
          <Fragment>
            {separator}
            <Span {...span} className={classes.value}>
              <PhoneIcon className={classes.icon} />
              {phone}
            </Span>
          </Fragment>
        ) : null;
      };

      /**
       * Handles Rendering the organization email
       */
      const renderOrgEmail = () => {
        return email ? (
          <Fragment>
            {separator}
            <Span {...span} className={classes.value}>
              <EmailIcon className={classes.icon} />
              {email}
            </Span>
          </Fragment>
        ) : null;
      };

      /**
       * Handles Rendering the organization webpage
       */
      const renderOrgWebpage = () => {
        return webpage ? (
          <Fragment>
            {separator}
            <Span {...span} className={classes.value}>
              <PublicIcon className={classes.icon} />
              {webpage}
            </Span>
          </Fragment>
        ) : null;
      };

      return (
        <div className={classes.headerItems}>
          <div className={classes.headerSection}>
            {renderOrgName()}
            {renderOrgCui()}
            {renderOrgRegNum()}
          </div>
          <div className={classes.headerSection}>
            {renderOrgAddress()}
            {renderOrgPhone()}
            {renderOrgEmail()}
            {renderOrgWebpage()}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.header}>
      <Typography variant="caption" className={classes.text}>
        {renderHeaderItems()}
      </Typography>
    </div>
  );
};

WorkOrderHeader.propTypes = propTypes;
WorkOrderHeader.defaultProps = defaultProps;

export default WorkOrderHeader;
export {
  propTypes as WorkOrderHeaderPropTypes,
  defaultProps as WorkOrderHeaderDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 *  Material UI Imports
 */
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useMessage } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./MessagePopup.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  origin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),

  active: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  origin: {
    vertical: "top",
    horizontal: "center",
  },
  active: false,
};

/**
 * Displays the component
 */
const MessagePopup = (props) => {
  const { origin, active: defaultActive } = props;
  const { vertical, horizontal } = origin;

  /**
   * Gets the global message controllers
   */
  const {
    open,
    message,
    resetMessage,
    severity,
    component,
    icon,
    delay,
    perma,
    timer,
  } = useMessage();

  /**
   * Initializes the active state
   */
  const [active, setActive] = useState(defaultActive);

  const [closed, setClosed] = useState(false);

  /**
   * Gets the component styles
   */
  const classes = useStyles({ open, message, component, active });

  /**
   * Renders the alert
   */
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  /**
   * Closes the alert
   */
  const closeAlert = (e) => {
    if (perma) return;
    setClosed(true);
    e.stopPropagation();
    resetMessage();
    setActive(false);
  };

  /**
   * Gets the content of the message
   */
  const getContent = () => {
    return component ? component : message;
  };

  /**
   * Renders a blank icon if icon is false
   */
  const getBlankIcon = () => <div className={classes.icon} />;

  useEffect(() => {
    if (!active) setClosed(false);
  }, [active]);

  useEffect(() => {
    if (open) {
      const isComponent = typeof component === "object";
      const hasContent = Object.keys(component).length > 0;
      const messageExists = message.length > 0;
      const ready = (isComponent && hasContent) || messageExists;
      let timer;

      /**
       * Sets the active state
       */
      const activate = () => {
        timer = setTimeout(() => {
          setActive(true);
        }, [delay]);
      };

      /**
       * Disables the active state
       */
      const deactivate = () => clearTimeout(timer);

      if (delay === 1 && ready) {
        setActive(true);
      } else if (delay !== 1 && ready) {
        activate();
        return () => deactivate();
      } else if (!ready) {
        setActive(false);
      }
    }
  }, [open, component, delay, message.length]);

  /**
   * Deactivates the message
   */
  useEffect(() => {
    if (!perma) {
      if (
        (!message && !component && active) ||
        (!message && !component && open)
      )
        setActive(false);
    }
  }, [component, message, active, open, perma]);

  useEffect(() => {
    if (!perma) {
      if (component && active && open) {
        const timeout = setTimeout(() => {
          resetMessage();
        }, [timer]);

        return () => clearTimeout(timeout);
      }
    }

    // eslint-disable-next-line
  }, [component, active, open, perma]);

  useEffect(() => {
    if (!perma) {
      if (message && active && open) {
        const timeout = setTimeout(() => {
          resetMessage();
        }, [timer]);

        return () => clearTimeout(timeout);
      }
    }

    // eslint-disable-next-line
  }, [message, active, open, perma]);

  const alertProps = {
    icon: !perma && icon && getBlankIcon(),
    severity,
    classes: {
      actions: classes.action,
    },
  };

  if (!perma) alertProps["onClose"] = closeAlert;

  return (
    !closed && (
      <Snackbar
        open={active}
        className={classes.snackbar}
        onClose={closeAlert}
        TransitionComponent={Zoom}
        transitionDuration={250}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
      >
        <Alert {...alertProps}>{getContent()}</Alert>
      </Snackbar>
    )
  );
};

MessagePopup.propTypes = propTypes;
MessagePopup.defaultProps = defaultProps;

export default MessagePopup;
export {
  propTypes as MessagePopupPropTypes,
  defaultProps as MessagePopupDefaultProps,
};

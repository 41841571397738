/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  wrapper: {
    background: `${theme.palette.sidebar.main}`,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.border.main}`
  },
  header: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    position: "relative",
    minHeight: 130
  },
  title: {
    fontFamily: theme.font.display,
    fontSize: "1.1rem",
    fontWeight: 800,
    color: theme.palette.primary.main
  },
  description: {
    fontFamily: theme.font.family,
    fontSize: "0.9rem",
    color: theme.palette.secondary.main
  },
  body: {
    background: theme.palette.common.white,
    padding: "0.5rem 1rem",
    margin: "0.5rem",
    display: "flex",
    flexDirection: "column"
  },
  priceContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9rem"
  },
  priceValue: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: theme.font.family,
    fontWeight: 800,
    fontSize: "2.5rem",
    color: theme.palette.primary.main,
    border: `1px solid #ececec`,
    margin: "0.5rem 0"
  },
  priceCurrency: {
    fontSize: "0.85rem",
    paddingBottom: "0.75rem",
    paddingRight: "0.25rem",
    fontFamily: theme.font.display,
    fontWeight: 800,
    color: theme.palette.secondary.main
  },
  priceMonthly: {
    fontSize: "0.85rem",
    paddingTop: "0.75rem",
    paddingLeft: "0.25rem",
    fontFamily: theme.font.display,
    fontWeight: 800,
    color: theme.palette.secondary.main
  },
  duration: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    fontSize: "0.95rem",
    color: theme.palette.secondary.light,
    "& svg": {
      color: theme.palette.primary.main,
      marginRight: "0.25rem"
    }
  },
  durationLabel: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    marginRight: "0.25rem",
    fontSize: "0.9rem",
    color: theme.palette.secondary.main
  },
  button: {
    margin: "1rem 0",
    borderRadius: 0,
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    padding: "0.45rem 1rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      boxShadow: "none"
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.45rem 0.6rem"
    },
    "&.MuiButton-root.Mui-disabled": {
      color: theme.palette.common.white,
      background: `#aba6a6d1`
    }
  },
  activeHeader: {
    position: "absolute",
    top: -15,
    right: 20,
    padding: "0.25rem 1rem",
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    fontWeight: 400,
    fontFamily: theme.font.family,
    borderRadius: 2,
    boxShadow: "none"
  }
}));

export { useStyles };

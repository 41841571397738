/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  viewButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  printButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 200,
    color: "#fff",
    background: "#0000000d",
    display: "flex",
    flexDirection: "column",
  },
  spinner: {
    color: theme.palette.primary.main,
    zIndex: 102,
    animation:
      "MuiCircularProgress-keyframes-circular-rotate 0.5s linear infinite",
  },
  textContainer: {
    color: theme.palette.common.white,
    fontWeight: 800,
    fontFamily: theme.font.family,
    fontSize: "1.1rem",
    zIndex: 101,
    marginTop: "0.5rem",
  },
  screen: {
    background: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 100,
  },
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

/**
 * Styles Imports
 */
import { useStyles } from "./UserDataForm.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
};

/**
 * Displays the component
 */
const UserDataForm = (props) => {
  const {
    input,
    button,
    inputs,
    errors,
    handleNext,
    handleInputChange,
    getAutoFocus,
    prevStep,
    handleLoginPageRouting,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the inputs and errors
   */
  const { username, password } = inputs;
  const { username: usernameError, password: passwordError } = errors;

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} sm={10} md={10} className={classes.form}>
        <Card className={classes.blank}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.inputContainer}>
                  <Input
                    {...input}
                    type="text"
                    className={classes.field}
                    inputText={{
                      id: "username",
                      required: true,
                      name: "username",
                      value: username,
                      onChange: handleInputChange,
                      maxSize: 70,
                      variant: "standard",
                      label: t("usernameLabel"),
                      error: usernameError,
                      autoFocus: getAutoFocus().username,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.inputContainer}>
                  <Input
                    {...input}
                    type="password"
                    className={classes.field}
                    inputPassword={{
                      id: "password",
                      required: true,
                      name: "password",
                      value: password,
                      onChange: handleInputChange,
                      maxSize: 70,
                      variant: "standard",
                      label: t("password"),
                      error: passwordError,
                      autoFocus: getAutoFocus().password,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Typography
              className={classes.loginLink}
              onClick={handleLoginPageRouting}
            >
              {t("haveAnAccount")}
            </Typography>
            <Box className={classes.buttonsContainer}>
              <Button
                {...button}
                type="button"
                variant="filled"
                onClick={prevStep}
                className={classes.submitBtn}
              >
                {t("previous")}
              </Button>
              <Button
                {...button}
                type="button"
                variant="filled"
                onClick={handleNext}
                className={classes.submitBtn}
              >
                {t("next")}
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

UserDataForm.propTypes = propTypes;
UserDataForm.defaultProps = defaultProps;

export default UserDataForm;
export {
  propTypes as UserDataFormPropTypes,
  defaultProps as UserDataFormDefaultProps,
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import {
  format as formatDate, isDate
} from "date-fns";
import { ro } from "date-fns/locale";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./DataTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  format: PropTypes.shape({
    label: PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
    value: PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
  }),
  className: PropTypes.string,
  classes: PropTypes.shape({
    labelContainer: PropTypes.string,
    label: PropTypes.string,
    valueContainer: PropTypes.string,
    value: PropTypes.string,
  }),
  data: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  format: {
    label: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4,
      xl: 4,
    },
    value: {
      xs: 6,
      sm: 6,
      md: 8,
      lg: 8,
      xl: 8,
    },
  },
  className: "",
  classes: {
    labelContainer: "",
    label: "",
    valueContainer: "",
    value: "",
  },
  data: [{ label: "", value: "" }],
};

/**
 * Displays the component
 */
const DataTable = (props) => {
  const { className, classes, data, format } = props;
  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const componentClasses = useStyles();

  /**
   * Defines the label component
   */
  const TableLabel = (props) => {
    const { value } = props;

    /**
     * Gets the grid size
     */
    const { label: labelFormat } = format;
    const { xs, sm, md, lg, xl } = labelFormat;

    /**
     * Defines the component classes
     */
    const labelContainerClasses = clsx(componentClasses.labelContainer, {
      [classes.labelContainer]: classes.labelContainer ? true : false,
    });
    const labelClasses = clsx(componentClasses.label, {
      [classes.label]: classes.label ? true : false,
    });
    return (
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        className={labelContainerClasses}
      >
        <Typography variant="caption" className={labelClasses}>
          {value ? value : " - "}
        </Typography>
      </Grid>
    );
  };

  /**
   * Defines the value component
   */
  const TableValue = (props) => {
    const { value, valueType } = props;

    /**
     * Gets the grid size
     */
    const { value: valueFormat } = format;
    const { xs, sm, md, lg, xl } = valueFormat;

    /**
     * Defines the component classes
     */
    const valueContainerClasses = clsx(componentClasses.valueContainer, {
      [classes.valueContainer]: classes.valueContainer ? true : false,
    });
    const valueClasses = clsx(componentClasses.value, {
      [classes.value]: classes.value ? true : false,
    });

    if (valueType === "component")
      return value ? (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={valueContainerClasses}
        >
          <div className={valueClasses}>{value}</div>
        </Grid>
      ) : null;

    const getValue = () => {
      if (value === null) return " - ";
      let display = value;

      if (typeof value === "boolean" && value === true) display = t("true");
      if (typeof value === "boolean" && value === false) display = t("false");
      if (isDate(value)) {
        display = formatDate(new Date(value), "dd MM yyyy hh:ii", {locale: ro});
      }
      return display ? display : " - ";
    };

    return (
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        className={valueContainerClasses}
      >
        <Typography variant="caption" className={valueClasses}>
          {getValue()}
        </Typography>
      </Grid>
    );
  };

  /**
   * Defines the container classes
   */
  const containerClasses = clsx(componentClasses.wrapper, {
    [className]: className ? true : false,
  });

  return (
    <Grid container className={containerClasses}>
      {data &&
        data.map((item, idx) => {
          const { label, value, valueType, render } = item;

          if (render !== null && !render) return null;
          return (
            <Fragment key={idx}>
              <TableLabel value={label} />
              <TableValue value={value} valueType={valueType} />
            </Fragment>
          );
        })}
    </Grid>
  );
};

DataTable.propTypes = propTypes;
DataTable.defaultProps = defaultProps;

export default DataTable;
export {
  propTypes as DataTablePropTypes,
  defaultProps as DataTableDefaultProps,
};

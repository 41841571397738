/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  flash: {
    padding: "0.25rem",
    minWidth: 0,
    borderRadius: 0,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.error.dark,
      boxShadow: "none"
    },
    margin: "0 0 0.25em",
    marginRight: "0.25rem"
  },
  flashOn: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none"
    }
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #929292",
    color: theme.palette.common.white,
    fontSize: "0.75rem",
    maxWidth: "none"
  },
  popper: {
    marginTop: -5
  },

  spacing: {
    marginBottom: "0.5rem"
  },
  field: theme.mixins.searchField,
  label: theme.mixins.searchFieldLabel,
  selectField: theme.mixins.searchSelect,
  visibleContainer: {
    transition: "all .3s ease-in-out",
    [theme.breakpoints.down("lg")]: {
      minHeight: 90,
      height: 0
    },
    [theme.breakpoints.only("md")]: {
      minHeight: 65,
      height: 0
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 65,
      height: "auto",
      marginBottom: "0.5rem"
    }
  },
  hideContainer: {
    transition: "all .3s ease-in-out",
    minHeight: 40,
    [theme.breakpoints.down("lg")]: {
      minHeight: 0,
      height: 0
    }
  },
  orderIDContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.success.main
    }
  },
  orderIDField: {
    marginBottom: "1rem"
  },
  btnContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem"
    }
  },
  undoBtn: {
    padding: "0.25rem",
    minWidth: 0,
    borderRadius: 0,
    background: theme.palette.button.light,
    color: theme.palette.secondary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    },
    margin: "0 0 0.25em",
    marginRight: "0.25rem"
  },
  submitButton: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 0,
    fontWeight: 400,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    }
  },
  form: {
    minHeight: 120
  }
}));

export { useStyles };

import React, { useState, Fragment } from "react";

/**
 * Imports Components
 */
import ModulesModal from "../ModulesModal";

/**
 * Imports Material UI Components
 */
import Typography from "@material-ui/core/Typography"

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Styles Imports
 */
import { useStyles } from "./SelectModuleButton.styles";

/**
 * Displays the component
 */
const SelectModuleButton = () => {
  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Fragment>
      <Typography className={classes.name} onClick={handleOpenModal}>
        {t("selectModule")}
      </Typography>
      <ModulesModal open={open} closeModal={handleCloseModal} />
    </Fragment>
  );
};

export default SelectModuleButton;

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch } from "react-router-dom";

/**
 * Component Imports
 */
import CarWashSidebar, {
  CarWashSidebarDefaultProps,
  CarWashSidebarPropTypes
} from "../CarWashSidebar";
import CarWashMain, {
  CarWashMainDefaultProps,
  CarWashMainPropTypes
} from "../CarWashMain";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes
} from "../PageNotFound";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(CarWashSidebarPropTypes),
  main: PropTypes.shape(CarWashMainPropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  path: PropTypes.string,
  paths: PropTypes.shape({
    main: PropTypes.string
  })
};

/**
 * Defines the default props
 */
const defaultProps = {
  sidebar: CarWashSidebarDefaultProps,
  main: CarWashMainDefaultProps,
  notFound: PageNotFoundDefaultProps,
  path: "/modules/car-wash",
  paths: {
    main: CarWashMainDefaultProps.path
  }
};

/**
 * Displays the component
 */
const CarWashPage = props => {
  const { notFound, sidebar, main, path: basePath, paths } = props;

  /**
   * Gets the paths from the components
   */
  const { main: mainPath } = paths;

  /**
   * Defines the path building function
   * Attaches the base path of the module to each individual path
   */
  const buildPath = path => `${basePath}${path}`;

  /**
   * Defines and builds the paths
   */
  const buildedPaths = {
    main: buildPath(mainPath)
  };

  return (
    <Fragment>
      <CarWashSidebar {...sidebar} paths={buildedPaths} />
      <Switch>
        <Route exact path={buildedPaths.main}>
          <CarWashMain {...main} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
    </Fragment>
  );
};

CarWashPage.propTypes = propTypes;
CarWashPage.defaultProps = defaultProps;

export default CarWashPage;
export {
  propTypes as CarWashPagePropTypes,
  defaultProps as CarWashPageDefaultProps
};

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    textCount: {
        opacity: 0.6,
        fontSize: "0.8rem"
    }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 660,
  },
  modulesContainer: {
    margin: "0.5rem 0",
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem",
    },
  },
  form: {
    background: "transparent",
    boxShadow: "none",
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "4px 0",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.85rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
      fontSize: "0.75rem",
    },
    "& .MuiAutocomplete-tagSizeSmall": {
      background: theme.palette.primary.accent,
      color: theme.palette.common.white,
      margin: 4,
      marginTop: 6,
      borderRadius: 3,
      "& svg": {
        color: "inherit",
      },
      "& svg:hover": {
        opacity: 0.7,
      },
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input": {
      padding: "6px 4px",
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  selectField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: "4px 0",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
      background: theme.palette.common.white,
      padding: "0 0.5rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 15,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(5px, 14px) scale(1)",
      fontSize: "0.75rem",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem",
    },
  },
  submitBtn: theme.mixins.submitBtn,
  workersContainer: {
    display: "flex",
    flex: 1,
    width: "100%",
    marginTop: "1rem",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  workersList: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  addServiceBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  choice: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "1rem",
    borderBottom: "1px solid #bfbfbf",
    paddingBottom: "0.25rem",
    justifyContent: "space-between",
  },
  sectionTitle: {
    fontSize: "0.9rem",
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    marginRight: "0.35rem",
  },
  list: {
    paddingTop: 0,
    background: "#f9f9f9",
    padding: 6,
    paddingBottom: 0,
    marginTop: "0.5rem",
    borderRadius: 3,
    border: "1px solid #d8d8d8",
    "& .MuiListItem-secondaryAction": {
      paddingLeft: 0,
    },
    "& .MuiListItem-container": {
      borderBottom: "1px solid #d4d4d4",
      padding: "0 0.25rem",
    },
    "& .MuiListItem-container:last-of-type": {
      borderBottom: "none",
      padding: "0 0.25rem",
    },
    "& .MuiListItemText-primary": {
      color: "#656565",
      fontWeight: 800,
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: -35,
    },
  },
  smallBtn: {
    padding: "0.1rem",
    fontSize: "0.7rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      width: 24,
      height: 24,
    },
  },
}));

export { useStyles };

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
// import { Redirect } from "react-router-dom";

/**
 * Component Imports
 */
import ModuleCard, {
  ModuleCardDefaultProps,
  ModuleCardPropTypes,
} from "../ModuleCard";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Hooks
 */
import { useUser, useAuth, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ModuleSelectionPage.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  moduleCard: PropTypes.shape(ModuleCardPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      allowedRoles: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  moduleCard: ModuleCardDefaultProps,
  button: ButtonDefaultProps,
  modules: [],
  path: "/modules",
};

/**
 * Displays the component
 */
const ModuleSelectionPage = (props) => {
  const { moduleCard, button } = props;

  /**
   * Handles the translation
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the auth controllers
   */
  const { logout } = useAuth();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the account deleted flag
   */
  const [isDeleted, setIsDeleted] = useState(false);

  /**
   * Handles logging the user out
   */
  const logoutUser = async () => {
    try {
      const loggedOut = await apiClient.post("/logout");
      if (loggedOut) {
        logout();
      }
    } catch (error) {
      handleAPIError(error);
    }
  };

  /**
   * Handles the api errors
   */
  const handleAPIError = (error) => {
    if (error) {
      dispatchMessage({
        severity: "error",
        message: error,
      });
      logout();
    }
  };

  /**
   * Handles rendering the user modules
   */
  const renderUserModules = () => {
    if (user) {
      const { modules } = user;
      const baseDelay = 150;
      const delayIncrement = 150;

      if (modules && !Array.isArray(modules)) {
        const delay = baseDelay;
        return (
          <Grid item xs={6} md={4} lg={3} xl={3}>
            <ModuleCard {...moduleCard} {...modules} delay={delay} />
          </Grid>
        );
      } else {
        return (
          modules &&
          modules.map((module, idx) => {
            /**
             * Defines the delay value
             */
            const delay = baseDelay + idx * delayIncrement;
            const { path } = module;

            return (
              <Grid key={path} item xs={6} md={4} lg={3} xl={3}>
                <ModuleCard {...moduleCard} {...module} delay={delay} />
              </Grid>
            );
          })
        );
      }
    }
  };

  /**
   * Updates the deleted acc state
   */
  useEffect(() => {
    if (user.account) {
      const isDeleted = user.account.status === "deleted";
      setIsDeleted(isDeleted);
    }
  }, [user]);

  return (
    <Fragment>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={10}
        xl={8}
        justify="center"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Typography variant="h1" className={classes.title}>
          {t("titleSelectModule")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={10}
        xl={8}
        className={classes.modulesContainer}
      >
        {isDeleted && (
          <div className={classes.deletedScreen}>
            <Typography variant="h2" className={classes.deletedText}>
              {t("accountDeletedPermanently")}
            </Typography>
            <Button
              {...button}
              type="button"
              variant="filled"
              onClick={logoutUser}
              className={classes.logout}
            >
              <ExitToAppIcon className={classes.logoutIcon} />
              {t("logout")}
            </Button>
          </div>
        )}
        {renderUserModules()}
      </Grid>
    </Fragment>
  );
};

ModuleSelectionPage.propTypes = propTypes;
ModuleSelectionPage.defaultProps = defaultProps;

export default ModuleSelectionPage;
export {
  propTypes as ModuleSelectionPagePropTypes,
  defaultProps as ModuleSelectionPageDefaultProps,
};

import React, { useState, useEffect } from "react";

/**
 * External Imports
 */
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

/**
 * Imports Components
 */
import ModulesHeader from "../ModulesHeader";
import ModulesFooter from "../ModulesFooter";
import ModuleSelectionPage from "../ModuleSelectionPage";
import AccountSettingsPage from "../AccountSettingsPage";
import ReportsPage from "../ReportsPage";
import CarServicePage from "../CarServicePage";
import WorkstationSettingsPage from "../WorkstationSettingsPage";
import CarWashPage from "../CarWashPage";
import TyreServicePage from "../TyreServicePage";
import PageNotFound from "../PageNotFound";

/**
 * Imports Material UI Components
 */
import Grid from "@material-ui/core/Grid";

/**
 * Imports hooks
 */
import { useAuth, useUser, useApiClient } from "../../hooks";

/**
 * Imports styling
 */
import { useStyles } from "./Modules.styles";

/**
 * Displays the component
 */
const Modules = () => {
  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Gets the auth state
   */
  const { isAuthenticated } = useAuth();

  /**
   * Initializes the authorized state
   */
  const [isAuthorized, setIsAuthorized] = useState(true);

  /**
   * Initializes the user checked state
   */
  const [userChecked, setUserChecked] = useState(false);

  /**
   * Initializes the server restarted flag
   */
  const [serverRestarted, setServerRestart] = useState(false);

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the location path
   */
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const { path } = match;

  /**
   * Checks the authority of the user to access the module
   */
  const checkAuthority = (user) => {
    const { account } = user;

    if(!account) return false

    const suspended = account.status === "suspended";
    const deleted = account.status === "deleted";

    if (!suspended && !deleted) {
      return setIsAuthorized(true);
    }

    const activeModule =
      user.modules && Array.isArray(user.modules)
        ? user.modules.filter((module) => path.includes(module.path))[0]
        : true;

    setIsAuthorized(activeModule ? activeModule.active : path === "/modules");
  };

  /**
   * Defines the sort function
   * Sorts based on the default order coming from the API
   * Otherwise sort by price
   */
  const sortModulesByOrder = (module_A, module_B) =>
    module_A.order > module_B.order ? 1 : -1;

  /**
   * Gets the role level
   * @param {String} role
   */
  const getRoleLevel = (role) => {
    switch (role) {
      case "worker":
        return 0;
      case "operator":
        return 1;
      case "admin":
        return 2;
      case "super-admin":
        return 3;
      default:
        return 0;
    }
  };

  /**
   * Handles getting the main role
   * @param {Array} roles
   */
  const getUserMainRole = (roles) => {
    if (roles.length === 1) return roles[0];

    const rolesWithHierarchy = roles
      .map((role) => {
        return {
          roleName: role,
          level: getRoleLevel(role),
        };
      })
      .sort((a, b) => b.level - a.level);

    return rolesWithHierarchy[0].roleName;
  };

  /**
   * Handles getting the accounts
   */
  const getAccounts = async () => {
    const accounts = await apiClient.get("/accounts");

    if (accounts) {
      const { data } = accounts;
      /**
       * Orders the modules
       */
      const modules = Array.isArray(data.modules)
        ? data.modules.sort((module_A, module_B) =>
            module_A.order > module_B.order ? 1 : -1
          )
        : data.modules;

      setUser((prevState) => {
        return {
          ...prevState,
          ...data,
          modules,
        };
      });
    }
  };

  /**
   * Handles getting the user roles
   */
  const getUserRoles = async () => {
    const roles = await apiClient.get("/user/roles");

    if (roles) {
      const { data } = roles;

      if (getUserMainRole(data) !== "super-admin") {
        getUserOrganizations();
        getUserAccountSettings();
        getOrganizationClients();
        getOrganizationWorkers();
      }

      setUser((prevState) => {
        return { ...prevState, type: getUserMainRole(data) };
      });
    }
  };

  /**
   * Handles getting the user organizations
   */
  const getUserOrganizations = async () => {
    const organizations = await apiClient.get("/organizations");

    if (organizations) {
      const { data } = organizations;

      setUser((prevState) => {
        return { ...prevState, organizations: data };
      });
    }
  };

  /**
   * Handles getting the user account settings
   */
  const getUserAccountSettings = async () => {
    const settings = await apiClient.get("/accounts/settings/information");

    if (settings) {
      const { data } = settings;

      const appointmentGroups = [];
      const organizations = data.account.organizations;
      const accountModules = data.modules;

      organizations.forEach((organization) => {
        const { appointmentGroups: apptGroups } = organization;

        if (apptGroups && apptGroups.length > 0) {
          apptGroups.forEach((group) => appointmentGroups.push(group));
        }
      });

      /**
       * Orders the modules
       */
      const modules = Array.isArray(accountModules)
        ? accountModules.sort(sortModulesByOrder)
        : [];

      setUser((prevState) => {
        return {
          ...prevState,
          settings: data,
          appointmentGroups: appointmentGroups,
          modules,
        };
      });
    }
  };

  /**
   * Handles getting the organization clients
   */
  const getOrganizationClients = async () => {
    const clients = await apiClient.get("/search/clients");

    if (clients) {
      const { data } = clients;

      setUser((prevState) => {
        return {
          ...prevState,
          clients: data,
        };
      });
    }
  };

  /**
   * Handles getting the organization workers
   */
  const getOrganizationWorkers = async () => {
    const workers = await apiClient.get("/search/workers");

    if (workers) {
      const { data } = workers;

      setUser((prevState) => {
        return {
          ...prevState,
          workers: data,
        };
      });
    }
  };

  /**
   * Gets the users data
   */
  useEffect(() => {
    if (isAuthenticated) {
      getAccounts();
      getUserRoles();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  /**
   * Handles getting the user related data when the server is restarted
   */
  useEffect(() => {
    if (serverRestarted) {
      setUser((prevState) => ({ ...prevState, restart: false }));
      setServerRestart(false);
      getUserAccountSettings();
      getUserRoles();
      getAccounts();
    }
    // eslint-disable-next-line
  }, [serverRestarted]);

  /**
   * Handles setting the server restart flag
   */
  useEffect(() => {
    if (user.restart) setServerRestart(true);
  }, [user]);

  /**
   * Handles refreshing the user state
   */
  useEffect(() => {
    if (user.refresh) {
      setUser((prevState) => ({ ...prevState, refresh: false }));
      getUserAccountSettings();
      getUserRoles();
    }
    // eslint-disable-next-line
  }, [user]);

  //@ToDO - Socket implementation - Now Disabled
  // /**
  //  * Handles the websocket object listeners
  //  */
  // useEffect(() => {
  //   if(webSocket && webSocket.connector && auth && auth.user) {
  //     console.log('User data ', `user.${auth.user.id}`);
  //     webSocket.private(`user.organizations.${auth.user.organization_id}`).listen('WorkOrderCreated', workOrder => {
  //       console.log('### SEC => WorkOrder ', workOrder);
  //     });
  //
  //     webSocket.private(`user.organizations.${auth.user.organization_id}`).listen('AppointmentCreated', appointment => {
  //       console.log('### SEC => Appointment ', appointment);
  //     }, error => {
  //       console.log('Chanel listen error', error);
  //     });
  //
  //   }
  // }, [auth]);

  useEffect(() => {
    setUserChecked(false);
    if (user.type) setUserChecked(true);
  }, [user]);

  /**
   * Handles triggering the authority check on path or user change
   */
  useEffect(() => {
    if (user.modules && user.type !== "super-admin") {
      checkAuthority(user);
    }
    if (user.type === "super-admin") setIsAuthorized(true);
    // eslint-disable-next-line
  }, [user, path]);

  /**
   * Handles redirecting if the user is not authenticated or not authorized
   */
  if (!isAuthenticated) return <Redirect to="/" />;
  if (!isAuthorized) return <Redirect to="/modules" />;
  if (!userChecked) return null;

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.modulePage}
    >
      <ModulesHeader user={user} />
      <Switch>
        <Route exact path="/modules/">
          <ModuleSelectionPage userType={user.type} />
        </Route>
        <Route path="/modules/tyre-service">
          <TyreServicePage />
        </Route>
        <Route path="/modules/reports">
          <ReportsPage />
        </Route>
        <Route path="/modules/car-wash">
          <CarWashPage />
        </Route>
        <Route path="/modules/car-service">
          <CarServicePage />
        </Route>
        <Route path="/modules/workstation-settings">
          <WorkstationSettingsPage />
        </Route>
        <Route path="/modules/account-settings">
          <AccountSettingsPage />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
      <ModulesFooter user={user} />
    </Grid>
  );
};

export default Modules;

import React, { useRef, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import PrintWorkOrder, {
  PrintWorkOrderDefaultProps,
  PrintWorkOrderPropTypes,
} from "../PrintWorkOrder";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./WorkOrdersTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  printWorkOrder: PropTypes.shape(PrintWorkOrderPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFiltersReset: PropTypes.func,
  rowsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  page: PropTypes.number,
  count: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  button: ButtonDefaultProps,
  printWorkOrder: PrintWorkOrderDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  handleDelete: null,
  handleEdit: () => {},
  handlePageChange: () => {},
  handleChangeRowsPerPage: () => {},
  handleFiltersReset: () => {},
  rowsPerPage: 0,
  page: 0,
  count: 0,
};

/**
 * Displays the component
 */
const WorkOrdersTable = (props) => {
  const {
    editButton,
    deleteButton,
    button,
    printWorkOrder,
    dynamicTable,
    handleView,
    handleEdit,
    handleDelete,
    handlePageChange,
    handleChangeRowsPerPage,
    handleFiltersReset,
    rowsPerPage,
    page,
    count,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Initializes the worker id state
   */
  const [workOrderID, setWorkOrderID] = useState(0);

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Handles printing the component
   */
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**
   * Handles initiating the printing
   */
  const printComponent = (props) => {
    setWorkOrderID(props.id);
    setLoading(true);
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () =>
    user.work_orders && user.work_orders.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const {
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    } = props;

    let tableOptions = {
      withCount: true,
      withAdd: false,
      withSearch: true,
      withPagination: true,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: true,
        addResult: false,
      },
      pagination: {
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        total: count,
        currentPage: page,
      },
      resetFilters: handleFiltersReset,
      defaultOrderBy: "finished",
      defaultOrder: "desc",
      actionsWrapped: true,
      fields: [
        {
          name: "uuid",
          label: t("uuid"),
          align: "start",
          maxSize: 150,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "finished",
          label: t("finished"),
          align: "start",
          min: 175,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "plate_number",
          label: t("plate_number"),
          align: "start",
          min: 150,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "client_name",
          label: t("client_name"),
          align: "start",
          minSize: 200,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "work_order_type",
          label: t("work_order_type"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "car_type",
          label: t("car_type"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "payment_type",
          label: t("payment_type"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "status",
          label: t("status"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "operations",
          label: t("operations"),
          type: "actions",
          key: false,
          align: "center",
          minSize: 85,
          maxSize: 85,
          searchField: false,
          buttons: [
            {
              name: "view_work_order",
              type: "view",
              icon: null,
              component: (
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  className={classes.viewButton}
                  title={t("view")}
                >
                  <VisibilityIcon />
                </Button>
              ),
              action: handleView,
            },
            {
              name: "edit_work_order",
              type: "edit",
              icon: null,
              component: (
                <EditButton
                  {...editButton}
                  className={classes.button}
                  title={t("edit")}
                />
              ),
              action: handleEdit,
            },
            {
              name: "print_work_order",
              type: "print",
              icon: null,
              component: (
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  className={classes.printButton}
                  title={t("printWorkOrder")}
                >
                  {loading ? (
                    <CircularProgress
                      size="1.45rem"
                      className={classes.loader}
                    />
                  ) : (
                    <PrintIcon className={classes.icon} />
                  )}
                </Button>
              ),
              action: printComponent,
            },
            {
              name: "delete_work_order",
              type: "delete",
              icon: null,
              component: (
                <DeleteButton
                  {...deleteButton}
                  className={classes.button}
                  title={t("delete")}
                />
              ),
              action: handleDelete,
            },
          ],
        },
      ],
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setWorkOrderID(0);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [count, page, rowsPerPage]);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.work_orders) {
      const data = user.work_orders.map((workOrder) => {
        const {
          payment_type,
          car_type,
          work_order_type,
          organization_client,
          car,
          status,
          client_name,
          finished,
        } = workOrder;

        return {
          ...workOrder,
          finished: formatDate(new Date(finished), "dd-MM-yyyy HH:mm"),
          payment_type: payment_type ? payment_type.name : "-",
          car_type: car_type ? car_type.name : "-",
          work_order_type: work_order_type ? work_order_type.name : "-",
          status: status ? t(status) : "-",
          plate_number: car ? car.plate_number : " - ",
          client_name: client_name
            ? client_name
            : organization_client
            ? organization_client.name
            : "",
        };
      });
      setCollection(data);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Fragment>
      {renderDynamicTable()}
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <PrintWorkOrder
            {...printWorkOrder}
            mode="print"
            workOrderID={workOrderID}
            setReadyToPrint={setReadyToPrint}
          />
        </div>
      </div>
    </Fragment>
  );
};

WorkOrdersTable.propTypes = propTypes;
WorkOrdersTable.defaultProps = defaultProps;

export default WorkOrdersTable;
export {
  propTypes as WorkOrdersTablePropTypes,
  defaultProps as WorkOrdersTableDefaultProps,
};

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useForm, useMessage, useUser, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditWorkerModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkerModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    organization_id: PropTypes.string,
    birth_date: PropTypes.any,
    user_id: PropTypes.number,
    password: PropTypes.string
  }),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultValues: {
    name: "",
    role: "",
    organization_id: "",
    birth_date: new Date(),
    user_id: 0,
    password: ""
  },
  organizations: [],
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const EditWorkerModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    editData,
    form,
    input,
    button,
    organizations,
    setUpdated,
    loadingText,
    setEditData
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the user
   */
  const { user } = useUser();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Initializes the users list
   */
  const [usersList, setUsersList] = useState();

  /**
   * Handles updating a worker
   */
  const updateWorker = async (data, id) => {
    try {
      const updatedWorker = await apiClient.put(`/workers/${id}`, data);

      if (updatedWorker) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          delay: 300,
          message: t("successMessage")
        });

        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);
    }
  };

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    setInputs,
    setErrors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    handleDateChange,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { name, role, password, organization_id, birth_date, user_id } = inputs;

  const {
    password: passwordError,
    name: nameError,
    role: roleError,
    organization_id: organization_idError,
    birth_date: birth_dateError,
    user_id: user_idError
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    setEditData({});
    setErrors({});
    closeModal();
  };

  /**
   * Checks if the age of the worker is over 18
   */
  const underAgeValidate = (date) => {
    const dateString = formatDate(date, "yyyy-MM-dd");
    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    let optimizedBirthday = dateString.replace(/-/g, "/");

    //set date based on date at 01:00:00 hours GMT+0100 (CET)
    let myBirthday = new Date(optimizedBirthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    let currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";

    // calculate age comparing current date and borthday
    let myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

    return myAge > 18;
  };

  const getOrgIdByName = (name) => {
    const found = organizations.find((org) => {
      return org.label === name;
    });
    return found ? found.value : "";
  };

  /**
   * Handles updating the inputs with the data
   */
  useEffect(() => {
    if (editData) {
      setInputs((prevState) => {
        return {
          ...prevState,
          name: editData.name ? editData.name : "",
          role:
            editData.function && editData.function !== " - "
              ? editData.function
              : "",
          user_id:
            editData.user_id &&
            editData.user_id !== " - " &&
            editData.user_id !== 0
              ? editData.user_id
              : "",
          organization_id: editData.organization_id
            ? getOrgIdByName(editData.organization_id)
            : "",
          birth_date:
            editData.birth_date && editData.birth_date !== " - "
              ? new Date(editData.birth_date)
              : new Date()
        };
      });
    }
    // eslint-disable-next-line
  }, [editData, open]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {};
      if (name) data["name"] = name;
      if (organization_id) data["organization_id"] = organization_id;
      if (password) data["password"] = password;
      if (role) data["function"] = role;
      if (user_id) data["user_id"] = user_id;
      if (birth_date && underAgeValidate(birth_date))
        data["birth_date"] = birth_date;

      if (Object.keys(data).length > 0) {
        updateWorker(data, editData.id);
      } else {
        setReady(false);
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (user.settings && user.settings.account.users) {
      const users = user.settings.account.users;

      const list = users.map((item) => {
        return {
          id: item.id,
          username: item.username
        };
      });

      setUsersList(list);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={false}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={`${t("titleEditWorker")} ${editData.name}`}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-worker-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "name",
                              name: "name",
                              value: name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("nameLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: nameError,
                              autoFocus: getAutoFocus().name
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "role",
                              name: "role",
                              value: role,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("functionLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: roleError,
                              autoFocus: getAutoFocus().role
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "organization_id",
                              name: "organization_id",
                              value: organization_id,
                              onChange: handleInputChange,
                              label: t("organization_idLabel"),
                              labelClass: classes.label,
                              variant: "outlined",
                              options: organizations,
                              optionLabel: "label",
                              optionValue: "value",
                              error: organization_idError,
                              autoFocus: getAutoFocus().organization_id
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "user_id",
                              name: "user_id",
                              value: user_id,
                              onChange: handleInputChange,
                              label: t("user_idLabel"),
                              labelClass: classes.label,
                              variant: "outlined",
                              options: usersList,
                              optionLabel: "username",
                              optionValue: "id",
                              error: user_idError,
                              autoFocus: getAutoFocus().user_idError
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="password"
                            className={classes.field}
                            inputPassword={{
                              id: "password",
                              name: "password",
                              value: password,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("passwordLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: passwordError,
                              autoFocus: getAutoFocus().password
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            className={classes.dateField}
                            type="date"
                            inputDate={{
                              required: true,
                              name: "birth_date",
                              value: birth_date,
                              onChange: handleDateChange,
                              margin: "normal",
                              label: t("birth_dateLabel"),
                              variant: "standard",
                              error: birth_dateError,
                              autoFocus: getAutoFocus().birth_date
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-worker-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditWorkerModal.propTypes = propTypes;
EditWorkerModal.defaultProps = defaultProps;

export default EditWorkerModal;
export {
  propTypes as EditWorkerModalPropTypes,
  defaultProps as EditWorkerModalDefaultProps
};

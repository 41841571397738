/**
 *  Imports Material UI
 */
import { makeStyles } from "@material-ui/core";

/**
 * Imports theme
 */
import { theme } from "../../themes/default";

/**
 * Styles the component
 */
const useStyles = makeStyles({
  cellValue: (props) => {
    const { minSize, maxSize, align } = props;

    const styles = {
      borderRight: `1px solid ${theme.palette.border.main}`,
      padding: "6px 12px",
      color: theme.palette.sidebar.text,
      border: `1px solid ${theme.palette.border.main}`,
      background: theme.palette.common.white,
      fontSize: "0.8rem",
      textAlign: "center",
    };

    if (minSize) {
      styles["minWidth"] = minSize;
    }

    if (maxSize) {
      styles["maxWidth"] = maxSize;
    }

    if (align) {
      styles["textAlign"] = `${align} !important`;
    }

    return styles;
  },
  text: {
    display: "flex",
    padding: "0 8px",
    textAlign: "left",
    cursor: "pointer",
    fontFamily: theme.font.family,
    fontSize: "0.9rem",
  },
  icon: {
    fontSize: "1.1rem",
    minWidth: 60,
  },
  large: {},
  status: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  done: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    background: "rgba(97, 171, 79, 0.05)",
    minWidth: 50,
    height: 25,
  },
  cancel: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    background: "rgba(232, 91, 91, 0.05)",
    minWidth: 50,
    height: 25,
  },
  attended: {
    background: `${theme.palette.success.main} !important`,
    color: "#fff !important",
    textShadow: "1px 1px 3px black",
  },
  notAttended: {
    background: `${theme.palette.error.main} !important`,
    color: "#fff",
  },
  cellHeader: {
    background: "red",
  },
});

export { useStyles };

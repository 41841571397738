import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

/**
 * Styles Imports
 */
import { useStyles } from "./ViewPriceConditionsModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  open: PropTypes.bool,
  currency: PropTypes.string,
  viewData: PropTypes.object,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  open: false,
  currency: "RON",
  viewData: {},
  closeModal: () => {},
};

/**
 * Displays the component
 */
const ViewPriceConditionsModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    viewData,
    setViewData,
    loadingBackdrop,
    open,
    closeModal,
    currency,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const { name: title, price: currentPrice } = viewData;

  /**
   * Initializes the conditions array
   * Holds all the conditions
   */
  const [conditions, setConditions] = useState([]);

  /**
   * Initializes the down trend conditions
   * New price < Standard price
   */
  const [downTrend, setDownTrend] = useState([]);

  /**
   * Initializes the flat trend conditions
   * New price === Standard price
   */
  const [flatTrend, setFlatTrend] = useState([]);

  /**
   * Initializes the up trend conditions
   * New price > Standard price
   */
  const [upTrend, setUpTrend] = useState([]);

  const handleClose = () => {
    setViewData({});
    closeModal();
  };

  /**
   * Defines the Condition Component
   */
  const Condition = (props) => {
    const { new_price, field, condition, value, flat, up } = props;

    /**
     * Defines the condition price classes
     */
    const conditionPriceClasses = clsx(classes.conditonPrice, {
      [classes.flat]: flat,
      [classes.up]: up,
    });

    /**
     * Builds the condition string
     */
    const getConditionText = () =>
      `${t("if")} ${t(field)} ${condition} ${value}`;

    return (
      <li className={classes.priceCondition}>
        <span className={conditionPriceClasses}>
          {new_price} {currency}
        </span>
        <span className={classes.conditionText}>{getConditionText()}</span>
      </li>
    );
  };

  /**
   * Handles getting the trend by type
   */
  const getTrendArray = (type) => {
    switch (type) {
      case "flat":
        return flatTrend;
      case "down":
        return downTrend;
      case "up":
        return upTrend;
      default:
        return [];
    }
  };

  /**
   * Handles getting the condition props unique for each trend
   */
  const getConditionSpecialProps = (type) => {
    switch (type) {
      case "flat":
        return { flat: true };
      case "down":
        return { down: true };
      case "up":
        return { up: true };
      default:
        return [];
    }
  };

  /**
   * Handles rendering the conditions
   */
  const renderConditions = (type) => {
    const trend = getTrendArray(type);
    const conditionProps = getConditionSpecialProps(type);
    return (
      trend &&
      trend.map((condition, idx) => {
        return <Condition key={idx} {...condition} {...conditionProps} />;
      })
    );
  };

  /**
   * Defines the trends
   */
  const downTrends = renderConditions("down");
  const flatTrends = renderConditions("flat");
  const upTrends = renderConditions("up");

  /**
   * Updates the conditions if the incoming data changes
   */
  useEffect(() => {
    const { price_conditions } = viewData;

    if (price_conditions && price_conditions.length > 0) {
      setConditions(price_conditions);
    }
  }, [viewData]);

  /**
   * Handles distributing the conditions in their respective trend categories
   */
  useEffect(() => {
    let downTrends = [];
    let flatTrends = [];
    let upTrends = [];

    conditions &&
      conditions.forEach((condition) => {
        if (condition.new_price > Number(currentPrice))
          upTrends.push(condition);
        if (condition.new_price < Number(currentPrice))
          downTrends.push(condition);
        if (condition.new_price === Number(currentPrice))
          flatTrends.push(condition);
      });

    setDownTrend(downTrends);
    setFlatTrend(flatTrends);
    setUpTrend(upTrends);
  }, [conditions, currentPrice]);

  return (
    <Modal
      {...modal}
      fullScreen={false}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      keepMounted={false}
      scroll="paper"
    >
      <ModalTitle
        {...modalTitle}
        title={`${t("titlePriceConditionsFor")}${title}`}
        onClick={handleClose}
      />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid container>
          <Grid item xs={12} container justify="center">
            <div className={classes.actions}>
              <Typography variant="caption" className={classes.label}>
                {t("standardPrice")}
                <Typography variant="caption" className={classes.value}>
                  {currentPrice} {currency}
                </Typography>
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item container xs={12} md={4} direction="column">
            <Typography variant="caption" className={classes.title}>
              <TrendingDownIcon className={classes.downIcon} /> {t("downTrend")}
            </Typography>
            <div className={classes.conditionsContainer}>{downTrends}</div>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={4}
            direction="column"
            className={classes.section}
          >
            <Typography variant="caption" className={classes.title}>
              <TrendingFlatIcon className={classes.flatIcon} /> {t("flatTrend")}
            </Typography>
            <div className={classes.conditionsContainer}>{flatTrends}</div>
          </Grid>
          <Grid item container xs={12} md={4} direction="column">
            <Typography variant="caption" className={classes.title}>
              <TrendingUpIcon className={classes.upIcon} /> {t("upTrend")}
            </Typography>
            <div className={classes.conditionsContainer}>{upTrends}</div>
          </Grid>
        </Grid>
        <LoadingBackdrop
          {...loadingBackdrop}
          open={conditions && conditions.length < 1}
          classes={{ backdrop: classes.backdrop }}
        />
      </ModalContent>
      <ModalActions {...modalActions} onClick={handleClose} />
    </Modal>
  );
};

ViewPriceConditionsModal.propTypes = propTypes;
ViewPriceConditionsModal.defaultProps = defaultProps;

export default ViewPriceConditionsModal;
export {
  propTypes as ViewPriceConditionsModalPropTypes,
  defaultProps as ViewPriceConditionsModalDefaultProps,
};

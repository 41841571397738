/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  listRoot: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  list: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "1rem",
    "& .MuiPaginationItem-page.Mui-selected": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
    "& .MuiPaginationItem-page:hover": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
    "& .MuiPaginationItem-sizeSmall": {
      margin: "0 3px",
    },
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.common.white,
    border: `1px solid #eaeaea`,
    borderTop: "none",
    justifyContent: "space-between",
  },
  muiPagination: {
    "&:last-child": {
      padding: 0,
      borderBottom: "none",
      paddingRight: "0.5rem",
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
  },
}));

export { useStyles };

/**
 * Defines the useProducts hook
 */
export const useProducts = () => {
  const findLastIndex = (array, searchkey, searchValue) => {
    const index = array
      .slice()
      .reverse()
      .findIndex((x) => x[searchkey] === searchValue);

    const count = array.length - 1;
    const finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
  };

  const filterServices = (services, inputs, user, workOrderType = null) => {
    return services.filter((service) => {
        const { car_type_id, work_order_type_id } = service;

        if(workOrderType && work_order_type_id) {
          return (workOrderType === work_order_type_id && (car_type_id === null || car_type_id === inputs.car_type));
        } else {
          return (car_type_id === null || car_type_id === inputs.car_type);
        }
      })
      .map((item, index, services) => {
        const itemIndex = findLastIndex(
          services,
          "organization_id",
          user.organization.id
        );

        return {
          ...item,
          topOrder: item.organization_id === user.organization.id ? true : null,
          lastFromUser: index === itemIndex,
        };
      })
      .sort((a, b) => {
        if (b.topOrder) {
          return 1;
        }
        if (a.topOrder) {
          return -1;
        }
        return a.organization_id - b.organization_id;
      });
  };

  const filterProducts = (products, inputs) => {
    return products
      .filter((product) => {
        const { car_type_id } = product;
        if (car_type_id === null || car_type_id === inputs.car_type) {
          return true;
        }
        return false;
      })
      .sort((a, b) => a.organization_id - b.organization_id);
  };

  /**
   * Parses the input as a float with 2 decimals.
   */
  const formatInputNum = (number) => parseFloat(number).toFixed(2);

  /**
   * Handles checking if an object has any data
   */
  const objectHasData = (data) => Object.keys(data).length > 0;

  /**
   * Handles formatting the number
   */
  const handleItemCostRendering = (item, key) => {
    const { is_service, price, is_count_price } = item;

    if ((is_count_price || !is_service) && price > 0) {
      return `${item[key].toFixed(2)} RON`;
    }

    return `0.00 RON`;
  };

  /**
   * Calculates the subtotal
   */
  const calculateSubtotal = (products) => {
    /**
     * Initializes the subtotal
     */
    let subtotal = 0;

    /**
     * Handles incrementing the result;
     */
    const incrementResult = (array) => {
      if (!array) return;
      array.forEach((item) => {
        const { is_count_price, price, quantity } = item;
        if (is_count_price && price > 0) {
          subtotal += price * quantity;
        }
      });
    };

    /**
     * Increments the total using the table data
     */
    incrementResult(products);

    return subtotal;
  };

  /**
   * Handles getting the base pricing.
   */
  const getBasePricing = (products, workOrder) => {
    /**
     * Initializes the pricing
     */
    let total = workOrder && workOrder.total ? workOrder.total : 0;
    let tax = workOrder && workOrder.tax ? workOrder.tax : 0;
    let subtotal = calculateSubtotal(products);

    /**
     * Calculates the pricing
     */
    total = tax + subtotal;

    return {
      subtotal: formatInputNum(subtotal),
      tax: formatInputNum(tax),
      total: formatInputNum(total),
    };
  };

  const getServicesRequestBody = () => {
    return {
      models: [
        {
          label: "Is Service",
          field: "is_service",
          type: "boolean",
          order: 5,
          options: [],
          selected: true,
        },
      ],
      order_by: "name",
      order_dir: "asc",
      page_size: 500000, // needed in order to get all products
      page_count: 1,
    };
  };

  const getProductsRequestBody = () => {
    return {
      models: [
        {
          label: "Is Service",
          field: "is_service",
          type: "boolean",
          order: 5,
          options: [],
          selected: false,
        },
      ],
      order_by: "name",
      order_dir: "asc",
      page_size: 500000, // needed in order to get all products
      page_count: 1,
    };
  };

  const calculateTotal = (total, discount, specialDiscount) => {
    const t = parseFloat(parseFloat(total).toFixed(2));
    const d = discount ? parseFloat(parseFloat(discount).toFixed(2)) : 0;
    const sd = specialDiscount
      ? parseFloat(parseFloat(specialDiscount).toFixed(2))
      : 0;

    if (d + sd > t) return 0;

    const result = parseFloat(t - (d + sd)).toFixed(2);
    if (!Number(result)) return 0;
    return result;
  };

  /**
   * Handles formatting the value, to normalize it for search comparisons
   * @param {String|Number|Boolean} value
   */
  const formatValue = (value, dict) => {
    const excessWhitespace = /^\s+|\s+$|\s+(?=\s)/g;
    if (!value) return "";
    return value
      .toString()
      .toLowerCase()
      .replace(excessWhitespace, "")
      .replace(/[^\w ]/g, function (char) {
        return dict[char] || char;
      });
  };

  const getCarTypeName = (id, carTypes) => {
    const found = carTypes.find((carType) => {
      return carType.id.toString() === id.toString();
    });
    return found ? found.name : "";
  };

  const calculateDiscountSpecial = (products, carTypeName) => {
    if (products.length > 0) {
      const specialServicesKeys = [
        "Demontat+Montat Anvelopa",
        "Demontat+Montat Roata",
        "Echilibrat Roti",
      ];

      const specialArr = products
        .filter((product) => {
          if(product.is_service) return false;
          return true;
        })

      if (specialArr.length < 1) return 0;

      let dict = { â: "a", ă: "a", ș: "s", î: "i", ț: "t" };
      let specialQty = 0;
      let specialDiscount = 0;

      specialArr.forEach(product => {
         specialQty += product.quantity
      })

      products.forEach((product) => {
        let matchFound = false;
        const productName = formatValue(product.name, dict);
        specialServicesKeys.forEach((key) => {
          const keyValue = formatValue(key, dict);
          if (productName.includes("echilibra") && carTypeName === "Camion") {
            return;
          } else if (productName.includes(keyValue)) {
            matchFound = true;
            return;
          }
        });

        if (matchFound) {
          if (product.quantity <= specialQty) {
            specialDiscount += product.quantity * product.price;
          }

          if (product.quantity > specialQty) {
            specialDiscount += specialQty * product.price;
          }
    
        }
      });

      return specialDiscount
        ? parseFloat(Number(specialDiscount)).toFixed(2)
        : 0;
    }
  };

  const checkCondition = (a, b, condition) => {
    if (condition === "=") {
      return a === b;
    } else if (condition === ">=") {
      return a >= b;
    } else if (condition === "<=") {
      return a <= b;
    } else if (condition === ">") {
      return a > b;
    } else if (condition === "<") {
      return a < b;
    }
  };

  const applyPriceConditions = (services, tyreService) => {
    let newServices = [];

    const keyFields = {
      tyre_rim: "tyre_rim",
      tyre_height: "tyre_height",
      tyre_width: "tyre_width",
    };

    services.forEach((service) => {
      if (service.price_conditions && service.price_conditions.length > 0) {
        let newPrice = service.price;

        const price_cond = service.price_conditions;
        price_cond.forEach((condition) => {
          if (keyFields[condition.field]) {
            const key = keyFields[condition.field];
            let value = tyreService[key];
            const conditionOK = checkCondition(
              Number(value),
              Number(condition.value),
              condition.condition
            );
            if (conditionOK) {
              newPrice = condition.new_price;
              return;
            }
          }
        });

        newServices.push({
          ...service,
          old_price: service.price,
          total: parseFloat(newPrice) * parseFloat(service.quantity),
          price: parseFloat(newPrice),
        });
      } else {
        newServices.push(service);
      }
    });
    return newServices;
  };

  return {
    filterServices,
    filterProducts,
    findLastIndex,
    formatInputNum,
    objectHasData,
    handleItemCostRendering,
    calculateSubtotal,
    getBasePricing,
    getProductsRequestBody,
    getServicesRequestBody,
    calculateTotal,
    formatValue,
    calculateDiscountSpecial,
    checkCondition,
    getCarTypeName,
    applyPriceConditions,
  };
};

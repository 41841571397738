import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import AddTyreHotelModal, {
  AddTyreHotelModalDefaultProps,
  AddTyreHotelModalPropTypes,
} from "../AddTyreHotelModal";
import EditTyreHotelModal, {
  EditTyreHotelModalDefaultProps,
  EditTyreHotelModalPropTypes,
} from "../EditTyreHotelModal";
import TyreHotelsSearchForm, {
  TyreHotelsSearchFormDefaultProps,
  TyreHotelsSearchFormPropTypes,
} from "../TyreHotelsSearchForm";
import TyreHotelsTable, {
  TyreHotelsTableDefaultProps,
  TyreHotelsTablePropTypes,
} from "../TyreHotelsTable";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreServiceTyreHotels.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./TyreServiceTyreHotels.defaults";

/**
 * Hooks
 */
import {
  useLocalStorage,
  useUser,
  useMessage,
  useApiClient,
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  addModal: PropTypes.shape(AddTyreHotelModalPropTypes),
  editModal: PropTypes.shape(EditTyreHotelModalPropTypes),
  searchForm: PropTypes.shape(TyreHotelsSearchFormPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  tyreHotelsTable: PropTypes.shape(TyreHotelsTablePropTypes),
  defaultSearch: PropTypes.object,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  addModal: AddTyreHotelModalDefaultProps,
  editModal: EditTyreHotelModalDefaultProps,
  searchForm: TyreHotelsSearchFormDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  tyreHotelsTable: TyreHotelsTableDefaultProps,
  defaultSearch: defaults.searchParams,
  path: "/tyre-hotels",
};

/**
 * Displays the component
 */
const TyreServiceTyreHotels = (props) => {
  const {
    title,
    wrapper,
    container,
    addModal,
    editModal,
    searchForm,
    loadingBackdrop,
    defaultSearch,
    tyreHotelsTable,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the page size
   */
  const [pageSize, setPageSize] = useLocalStorage("tyre_hotelsPageSize");

  /**
   * Initializes total items
   */
  const [total, setTotal] = useState(0);

  /**
   * Initializes the clients list
   */
  const [clients, setClients] = useState([]);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the updated flag
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the reset filters flag
   */
  const [resetFilters, setResetFilters] = useState(false);

  /**
   * Initializes the state that hold the data for the edit modal
   */
  const [editData, setEditData] = useState("");

  const [viewData, setViewData] = useState(0);

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Initializes the inputs state
   */
  const [inputs, setInputs] = useState({});

  /**
   * Initializes the changed value state of the tabs
   */
  const [valueChanged, setValueChanged] = useState(false);

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    view: false,
    add: false,
    edit: false,
  });

  /**
   * Handles opening the edit modal
   */
  const handleView = (props) => setViewData(props.id);

  /**
   * Handles opening the edit modal
   */
  const handleEdit = (props) => setEditData(props.id);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openView = () => handleOpenModal("view");
  const closeView = () => handleCloseModal("view");

  /**
   * Defines the add organization modal handlers
   */
  const openAdd = () => handleOpenModal("add");
  const closeAdd = () => handleCloseModal("add");

  /**
   * Defines the edit User modal handlers
   */
  const openEdit = () => handleOpenModal("edit");
  const closeEdit = () => handleCloseModal("edit");

  /**
   * Gets the inputs
   */
  const { orderBy, orderDir } = inputs;

  /**
   * Checks if the pagination is allowed to change
   */
  const validatePaginationChange = () => {
    const { tyre_hotels } = user;
    if (tyre_hotels && tyre_hotels.length < 1 && pageCount === 0) return true;
    return false;
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (validatePaginationChange()) return;

    setBackdropLoading(true);

    /**
     * Defines the request body data
     */
    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page,
    };

    searchTyreHotels(data);
    window.scrollTo(0, 0);
  };

  /**
   * Handles building the default search models
   */
  const buildDefaultSearchModels = () => {
    const { models: defaultModels, page_size: defaultPageSize } = defaultSearch;

    const page_size = pageSize || defaultPageSize;
    const models = [...defaultModels];
    const page_count = 1;

    return { ...defaultSearch, models, page_size, page_count };
  };

  /**
   * Handles building the search models
   */
  const buildSearchModels = (baseModels, pgCount) => {
    /**
     * Defines the request body data for the search
     */
    const models = [...baseModels];
    const order_by = inputs.orderBy || defaultSearch.order_by;
    const order_dir = inputs.orderDir || defaultSearch.order_dir;
    const page_size = pageSize || defaultSearch.page_size;
    const page_count = pgCount || pageCount || 1;

    return { models, order_by, order_dir, page_size, page_count };
  };

  /**
   * Handles constructing the data object for the search
   */
  const getSearchParams = (pgCount) => {
    return buildSearchModels(models, pgCount);
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchTyreHotels(buildDefaultSearchModels());
  };

  /**
   * Handles searching for work orders
   */
  const handleSearch = (pgCount) => {
    const data = getSearchParams(pgCount);
    if (Object.keys(data).length > 0) {
      searchTyreHotels(data);
    }
  };

  /**
   * Handles searching for tyre hotels
   */
  const searchTyreHotels = async (data) => {
    try {
      const tyreHotels = await apiClient.post("/tyre-hotels/search", data);
      if (tyreHotels) {
        const { data } = tyreHotels;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setUpdated(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, tyre_hotels: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setUpdated(false);
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setResetFilters(true);

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    setPageSize(e.target.value);
    setUpdated(true);
    window.scrollTo(0, 0);
  };

  /**
   * Gets the clients
   */
  useEffect(() => {
    if (user.clients) {
      setClients(user.clients);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles triggering a search if updated
   */
  useEffect(() => {
    if (updated) handleSearch();
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles performing a search on tab change
   */
  useEffect(() => {
    if (valueChanged) {
      setBackdropLoading(true);
      setValueChanged(false);
    }
  }, [valueChanged]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (viewData) openView();
    // eslint-disable-next-line
  }, [viewData]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (editData) openEdit();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    handleDefaultSearch();
    // eslint-disable-next-line
  }, []);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        className={classes.marginsTitle}
        icon={<HomeWorkIcon />}
        title={t("titleTyreHotels")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <TyreHotelsSearchForm
                  {...searchForm}
                  setInputs={setInputs}
                  setModels={setModels}
                  setTotal={setTotal}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  setPageCount={setPageCount}
                  setBackdropLoading={setBackdropLoading}
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                  clients={clients}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.tyreHotels}>
              <TyreHotelsTable
                {...tyreHotelsTable}
                handleAdd={openAdd}
                handleEdit={handleEdit}
                handleView={handleView}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleFiltersReset={handleFiltersReset}
                rowsPerPage={pageSize}
                page={pageCount}
                count={total}
                parent="service"
              />
            </Grid>
            <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
            <AddTyreHotelModal
              {...addModal}
              open={modalState.add}
              closeModal={closeAdd}
              setUpdated={setUpdated}
              defaultSearch={defaultSearch}
              clients={clients}
            />
            <EditTyreHotelModal
              {...editModal}
              open={modalState.view}
              closeModal={closeView}
              id={viewData}
              setEditData={setViewData}
              clients={clients}
              setUpdated={setUpdated}
              disabled={true}
            />
            <EditTyreHotelModal
              {...editModal}
              open={modalState.edit}
              closeModal={closeEdit}
              id={editData}
              setEditData={setEditData}
              clients={clients}
              setUpdated={setUpdated}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

TyreServiceTyreHotels.propTypes = propTypes;
TyreServiceTyreHotels.defaultProps = defaultProps;

export default TyreServiceTyreHotels;
export {
  propTypes as TyreServiceTyreHotelsPropTypes,
  defaultProps as TyreServiceTyreHotelsDefaultProps,
};

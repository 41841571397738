import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import PaymentMethodsTable, {
  PaymentMethodsTableDefaultProps,
  PaymentMethodsTablePropTypes,
} from "../PaymentMethodsTable";
import AddPaymentMethodModal, {
  AddPaymentMethodModalDefaultProps,
  AddPaymentMethodModalPropTypes,
} from "../AddPaymentMethodModal";
import EditPaymentMethodModal, {
  EditPaymentMethodModalDefaultProps,
  EditPaymentMethodModalPropTypes,
} from "../EditPaymentMethodModal";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import DeletePaymentMethodModal from "../DeletePaymentMethodModal";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CreditCardIcon from "@material-ui/icons/CreditCard";

/**
 * Hooks
 */
import { useUser, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsPaymentMethods.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  paymentMethodsTable: PropTypes.shape(PaymentMethodsTablePropTypes),
  addModal: PropTypes.shape(AddPaymentMethodModalPropTypes),
  editModal: PropTypes.shape(EditPaymentMethodModalPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    organization: PropTypes.string,
    function: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
  }),
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  paymentMethodsTable: PaymentMethodsTableDefaultProps,
  addModal: AddPaymentMethodModalDefaultProps,
  editModal: EditPaymentMethodModalDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  defaultValues: {
    name: "",
    organization: "",
    function: "",
    orderBy: "",
    orderDir: "",
  },
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: "/payment-types",
};

/**
 * Displays the component
 */
const AccountSettingsPaymentMethods = (props) => {
  const {
    title,
    wrapper,
    container,
    paymentMethodsTable,
    addModal,
    editModal,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the updated flag - used for retriggering the search when needed
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the modal state
   */
  const [modal, setModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  /**
   * Initializes the states that hold the data for the modals
   */
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});

  /**
   * Gets the global user
   */
  const { setUser } = useUser();

  /**
   * Handles getting the payment types
   */
  const getPaymentTypes = async () => {
    const paymentTypes = await apiClient.get("/payment-types");

    if (paymentTypes) {
      const { data } = paymentTypes;

      /**
       * Resets the state
       */
      setUpdated(false);
      setUser((prevState) => {
        return {
          ...prevState,
          payment_methods: data.sort((a, b) => b.id - a.id),
        };
      });
    }
  };

  /**
   * Checks if the object is empty
   */
  const isEmptyObject = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles opening the edit modal
   */
  const handleEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const handleDelete = (props) => setDeleteData(props);

  /**
   *  Handles updating the modal state
   * @param {string} type
   * Modal name (Eg. add / edit / delete etc)
   * @param {boolean} state
   * State of open / closed
   */
  const updateModal = (type, state) =>
    setModal((prevState) => ({ ...prevState, [type]: state }));

  /**
   * Defines the add organization modal handlers
   */
  const openAdd = () => updateModal("add", true);
  const closeAdd = () => updateModal("add", false);

  /**
   * Defines the edit User modal handlers
   */
  const openEdit = () => updateModal("edit", true);
  const closeEdit = () => updateModal("edit", false);

  /**
   * Handles the delete user modal handlers
   */
  const openDelete = () => updateModal("delete", true);
  const closeDelete = () => updateModal("delete", false);

  /**
   * Handles getting the payment methods
   */
  useEffect(() => {
    getPaymentTypes();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles making a search when updated is triggered
   */
  useEffect(() => {
    if (updated) getPaymentTypes();
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmptyObject(editData)) openEdit();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmptyObject(deleteData)) openDelete();
    // eslint-disable-next-line
  }, [deleteData]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<CreditCardIcon />}
        title={t("titlePaymentMethods")}
        className={classes.marginsTitle}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container>
          <PaymentMethodsTable
            {...paymentMethodsTable}
            handleAdd={openAdd}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Grid>
        <AddPaymentMethodModal
          {...addModal}
          open={modal.add}
          closeModal={closeAdd}
          setUpdated={setUpdated}
        />
        <EditPaymentMethodModal
          {...editModal}
          open={modal.edit}
          closeModal={closeEdit}
          setUpdated={setUpdated}
          editData={editData}
        />
        <DeletePaymentMethodModal
          open={modal.delete}
          closeModal={closeDelete}
          deleteData={deleteData}
          setUpdated={setUpdated}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

AccountSettingsPaymentMethods.propTypes = propTypes;
AccountSettingsPaymentMethods.defaultProps = defaultProps;

export default AccountSettingsPaymentMethods;
export {
  propTypes as AccountSettingsPaymentMethodsPropTypes,
  defaultProps as AccountSettingsPaymentMethodsDefaultProps,
};

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import TextCount, {
  TextCountDefaultProps,
  TextCountPropTypes,
} from "../TextCount";

/**
 * Material UI Imports
 */
import TextField from "@material-ui/core/TextField";

/**
 * Styles Imports
 */
import { useStyles } from "./InputTextarea.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  textCount: PropTypes.shape(TextCountPropTypes),
  error: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.any,
  placeholder: PropTypes.string,
  maxChars: PropTypes.number,
  inputClasses: PropTypes.any,
  size: PropTypes.string,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  showCount: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  textCount: TextCountDefaultProps,
  error: null,
  label: "",
  ariaLabel: "",
  multiline: true,
  rows: 6,
  rowsMax: 12,
  fullWidth: true,
  name: "textarea",
  onChange: () => {},
  value: "",
  variant: "standard",
  required: false,
  className: "",
  placeholder: "",
  maxChars: 500,
  inputClasses: {},
  size: "medium",
  autoFocus: false,
  id: "textarea",
  readOnly: false,
  showCount: false,
  disabled: false,
  defaultValue: "",
};

/**
 * Displays the component
 */
const InputTextarea = (props) => {
  const {
    textCount,
    error,
    label,
    ariaLabel,
    multiline,
    rows,
    rowsMax,
    fullWidth,
    name,
    onChange,
    value,
    variant,
    required,
    className,
    placeholder,
    maxChars,
    inputClasses,
    size,
    autoFocus,
    id,
    readOnly,
    defaultValue,
    showCount,
    disabled,
  } = props;

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Renders the hidden text if there's no erros or helper messages
   */
  const hiddenText = () => <span className={styles.hidden}>error_msg</span>;

  /**
   * Gets the helper text/or error
   */
  const getHelperText = () => (error ? error : hiddenText());
  const helperText = getHelperText();

  const textAreaClasses = clsx(styles.textarea, {
    [className]: className ? true : false,
  });

  /**
   * Prepares the component props
   */
  const getTextareaProps = () => {
    let baseProps = {
      id,
      inputRef,
      className: textAreaClasses,
      name,
      label,
      disabled: disabled,
      "aria-label": ariaLabel,
      multiline,
      rows,
      rowsMax,
      fullWidth,
      placeholder,
      variant,
      size,
      helperText,
      error: error ? true : false,
      InputProps: { ...inputClasses, readOnly },
      inputProps: { maxLength: maxChars },
    };

    if (defaultValue) {
      baseProps["defaultValue"] = defaultValue;
    } else {
      baseProps["value"] = value ? value : "";
      baseProps["onChange"] = onChange;
      baseProps["required"] = required;
    }
    return { ...baseProps };
  };

  /**
   * Gets the textarea props;
   */
  const textareaProps = getTextareaProps();

  /**
   * Applies autofocus on the input upon errors
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  const renderTextCount = () => {
    if (showCount) {
      return (
        <div className={styles.modalInfo}>
          <div className={styles.count}>
            <TextCount {...textCount} text={value} maxChars={maxChars} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.textareaContainer}>
      <TextField {...textareaProps} />
      {!disabled && renderTextCount()}
    </div>
  );
};

InputTextarea.propTypes = propTypes;
InputTextarea.defaultProps = defaultProps;

export default InputTextarea;
export {
  propTypes as InputTextareaPropTypes,
  defaultProps as InputTextareaDefaultProps,
};

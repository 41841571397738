import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ContactItem, {
  ContactItemDefaultProps,
  ContactItemPropTypes,
} from "../ContactItem";
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import HelpIcon from "@material-ui/icons/Help";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./OrganizationItem.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  contactItem: PropTypes.shape(ContactItemPropTypes),
  createButton: PropTypes.shape(CreateButtonPropTypes),
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  contactItem: ContactItemDefaultProps,
  createButton: CreateButtonDefaultProps,
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
};

/**
 * Displays the component
 */
const OrganizationItem = (props) => {
  const {
    uuid,
    id,
    expanded,
    onChange,
    address,
    company,
    time_sheet,
    name,
    description,
    contacts,
    contactItem,
    createButton,
    editButton,
    deleteButton,
    openModal,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Handles opening the details panel
   */
  const openDetails = (e) => {
    e.preventDefault();

    onChange(uuid);
  };

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initialize the contacts list
   */
  const [contactsList, setContactsList] = useState(contacts);

  /**
   * Initialize the contact id
   */
  const [contactID, setContactID] = useState("");

  /**
   * Defines the expansion panel classes
   */
  const expansionPanel = {
    root: {
      root: classes.expansionPanelRoot,
    },
    summary: {
      root: classes.panelSummaryRoot,
      content: classes.panelSummaryContent,
    },
    details: {
      root: classes.panelDetailsRoot,
    },
  };

  /**
   * Handles opening the edit modal
   */
  const handleOpenEditModal = (e) => {
    e.stopPropagation();
    openModal("edit", props);
  };

  /**
   * Handles opening the delete modal
   */
  const handleOpenDeleteModal = (e) => {
    e.stopPropagation();
    openModal("delete", props);
  };

  /**
   * Handles opening the edit address modal
   */
  const handleEditAddress = (e) => {
    e.stopPropagation();
    openModal("editAddress", props);
  };

  /**
   * Handles opening the edit info modal
   */
  const handleEditInfo = (e) => {
    e.stopPropagation();
    openModal("editOrganization", props);
  };

  /**
   * Handles opening the add contact modal
   */
  const handleAddContact = (e) => {
    e.stopPropagation();
    openModal("addContact", id);
  };

  /**
   * Handles deleting an organization contact by id
   */
  const deleteOrganizationContact = async (id) => {
    try {
      const deletedContact = await apiClient.delete(`/contacts/${id}`);
      if (deletedContact) {
        /**
         * Filteres out the deleted contact
         */
        const contacts = contactsList.filter(
          (contact) => contact.id.toString() !== contactID
        );

        setContactsList(contacts);
        setContactID("");
        dispatchMessage({
          message: t("contactDeleted"),
        });
      }
    } catch (error) {
      dispatchMessage({
        message: error,
        severity: "error",
      });
    }
  };

  /**
   * Handles opening the delete contact modal
   */
  const handleDelete = (id) => {
    setContactID(id);
    deleteOrganizationContact(id);
  };

  /**
   * Handles rendering the contacts
   */
  const renderContacts = () => {
    return (
      contactsList &&
      contactsList.map((contact) => {
        return (
          <ContactItem
            {...contactItem}
            {...contact}
            key={contact.id}
            id={contact.id.toString()}
            openModal={openModal}
            handleDelete={handleDelete}
          />
        );
      })
    );
  };

  /**
   * Handles rendering the time sheet
   */
  const renderTimeSheet = () =>
    time_sheet ? (
      <div className={classes.whiteTagFlex}>
        <Typography variant="caption">
          <ScheduleIcon />
          {time_sheet}
        </Typography>
      </div>
    ) : null;

  /**
   * Handles rendering the delete button
   */
  const renderDeleteBtn = () =>
    user.organizations && user.organizations.length > 1 ? (
      <DeleteButton
        {...deleteButton}
        className={classes.deleteButton}
        onClick={handleOpenDeleteModal}
        title={t("deleteOrganization")}
      />
    ) : null;

  /**
   * Handles rendering the address
   */
  const renderAddress = () =>
    address.name ? (
      <Typography variant="caption" className={classes.addressName}>
        {address.name}
      </Typography>
    ) : null;

  /**
   * Handles rendering the description
   */
  const renderDescription = () =>
    address.description ? (
      <Typography variant="caption" className={classes.addressDescription}>
        <Typography variant="caption" className={classes.descriptionLabel}>
          {t("description")}
        </Typography>
        <Typography variant="caption" className={classes.descriptionValue}>
          {address.description}
        </Typography>
      </Typography>
    ) : null;

  /**
   * Handles rendering the webpage
   */
  const renderWebpage = () =>
    company.webpage && (
      <div className={classes.whiteTagFlex}>
        <Typography variant="caption">
          <PublicIcon />
          {company.webpage}
        </Typography>
      </div>
    );

  /**
   * Handles updating the contacts list with the data from the api
   */
  useEffect(() => {
    setContactsList(contacts);
  }, [contacts]);

  return (
    <ExpansionPanel
      classes={expansionPanel.root}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded === uuid}
    >
      <ExpansionPanelSummary
        classes={expansionPanel.summary}
        aria-controls={`${uuid}-content`}
        id={`${uuid}-header`}
        onClick={openDetails}
      >
        <div className={classes.summary}>
          <Typography className={classes.summaryTitle}>
            {name}
            <Typography variant="caption" className={classes.description}>
              {description}
            </Typography>
          </Typography>
          {renderTimeSheet()}
          <div className={classes.summaryActions}>
            <EditButton
              {...editButton}
              className={classes.button}
              onClick={handleOpenEditModal}
              title={t("editOrganization")}
            />
            {renderDeleteBtn()}
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={expansionPanel.details}>
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <div className={classes.addressContainer}>
                <div className={classes.companyInfodiv}>
                  <div className={classes.flexColumn}>
                    <Grid container>
                      <Grid item xs={12}>
                        {renderAddress()}
                        <div className={classes.whiteTagFlex}>
                          <Typography variant="caption">
                            <LocationOnIcon />
                            {address.full_address}
                          </Typography>
                          <EditButton
                            {...editButton}
                            className={classes.button}
                            onClick={handleEditAddress}
                            title={t("editAddress")}
                          />
                        </div>
                        {renderDescription()}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div>
                <Typography className={classes.companyTitle}>
                  {t("generalInfo")}
                  <EditButton
                    {...editButton}
                    className={classes.buttonSmall}
                    onClick={handleEditInfo}
                    title={t("editInfo")}
                  />
                </Typography>
                <div className={classes.companyInfodiv}>
                  <div className={classes.flexColumn}>
                    <Grid container>
                      <Grid item xs={12}>
                        {company.email && (
                          <div className={classes.whiteTagFlex}>
                            <Typography variant="caption">
                              <EmailIcon />
                              {company.email}
                            </Typography>
                          </div>
                        )}
                        {company.phone && (
                          <div className={classes.whiteTagFlex}>
                            <Typography variant="caption">
                              <PhoneIcon />
                              {company.phone}
                            </Typography>
                          </div>
                        )}
                        {renderWebpage()}
                        <div className={classes.whiteTagFlex}>
                          <Typography variant="caption">
                            <Tooltip
                              title={t("reg_number")}
                              TransitionComponent={Zoom}
                              placement="top"
                              classes={{
                                tooltip: classes.tooltip,
                                popper: classes.popper,
                              }}
                            >
                              <HelpIcon />
                            </Tooltip>
                            {company.reg_number}
                          </Typography>
                        </div>
                        <div className={classes.whiteTagFlex}>
                          <Typography variant="caption">
                            <Tooltip
                              title={t("vat_number")}
                              TransitionComponent={Zoom}
                              placement="top"
                              classes={{
                                tooltip: classes.tooltip,
                                popper: classes.popper,
                              }}
                            >
                              <HelpIcon />
                            </Tooltip>
                            {company.vat_number}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.contactsHeader}>
                <Typography className={classes.contactsTitle}>
                  {t("contacts")}
                </Typography>
                <div className={classes.contactsAction}>
                  <CreateButton
                    {...createButton}
                    onClick={handleAddContact}
                    className={classes.newButton}
                    title={t("addContact")}
                    text={t("addContact")}
                  />
                </div>
              </div>
              <div className={classes.contactsdiv}>{renderContacts()}</div>
            </Grid>
          </Grid>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

OrganizationItem.propTypes = propTypes;
OrganizationItem.defaultProps = defaultProps;

export default OrganizationItem;
export {
  propTypes as OrganizationItemPropTypes,
  defaultProps as OrganizationItemDefaultProps,
};

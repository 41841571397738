import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import WorkOrderTable, {
  WorkOrderTableDefaultProps,
  WorkOrderTablePropTypes,
} from "../WorkOrderTable";
import WorkOrderWorkers, {
  WorkOrderWorkersDefaultProps,
  WorkOrderWorkersPropTypes,
} from "../WorkOrderWorkers";
import DataTable, {
  DataTableDefaultProps,
  DataTablePropTypes,
} from "../DataTable";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

/**
 * Hooks
 */
import { useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderView.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  table: PropTypes.shape(WorkOrderTablePropTypes),
  workOrderWorkers: PropTypes.shape(WorkOrderWorkersPropTypes),
  dataTable: PropTypes.shape(DataTablePropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  workOrderID: PropTypes.number,
  data: PropTypes.object,
  setWorkOrderSummary: PropTypes.func,
  setSummaryUpdated: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  table: WorkOrderTableDefaultProps,
  workOrderWorkers: WorkOrderWorkersDefaultProps,
  dataTable: DataTableDefaultProps,
  closeModal: () => {},
  workOrderID: 0,
  data: {
    car: {},
    car_type: {},
    organization_client: {},
    payment_type: {},
    products: [],
    tyre_hotels: [],
    tyre_service: {},
    work_order_type: {},
    workers: [],
  },
  setWorkOrderSummary: () => {},
  setSummaryUpdated: () => {},
};

/**
 * Displays the component
 */
const WorkOrderView = (props) => {
  const {
    workOrderID,
    table,
    workOrderWorkers,
    dataTable,
    setOrderID,
    summaryUpdated,
    setSummaryUpdated,
    workOrderSummary,
    setWorkOrderSummary,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the data state
   */
  const [data, setData] = useState(props.data);

  const {
    uuid,
    work_order_type,
    car,
    tyre_service,
    organization_client,
    workers,
    discount,
    delegate_name,
    client_name,
    car_plate_number,
    finished,
    description: workOrderDescription,
  } = data;

  /**
   * Initializes the products state
   */
  const [products, setProducts] = useState([]);

  /**
   * Initializes the requested and executed services state
   */
  const [servicesReq, setServicesReq] = useState("");
  const [servicesExec, setServicesExec] = useState([]);

  /**
   * Handles initializing the tables data
   */
  const initTableData = (size) => {
    const result = Array(size).fill({ label: "", value: "", render: true });
    return result;
  };

  /**
   * Intializes the tables data
   */
  const [orderData, setOrderData] = useState(initTableData(3));
  const [clientData, setClientData] = useState(initTableData(3));
  const [carData, setCarData] = useState(initTableData(10));

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles getting a work order by id
   */
  const getWorkOrder = async (id) => {
    try {
      const workOrder = await apiClient.get(`/work-orders/${id}`);
      if (workOrder) {
        const { data } = workOrder;

        setUpWorkOrderViewData(data);
      }
    } catch (error) {
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
      setReady(false);
      setSummaryUpdated(false);
    }
  };

  /**
   * Handles checking if an object has any data
   */
  const objectHasData = (data) => Object.keys(data).length > 0;

  /**
   * Handles building the tyre dimensions string
   * width / height R rim
   */
  const buildTyreDimString = (tyre_dimensions) => {
    const { tyre_width, tyre_height, tyre_rim } = tyre_dimensions;

    if (!tyre_width || !tyre_height || !tyre_rim) return " - ";

    return `${tyre_width} / ${tyre_height} R${tyre_rim}`;
  };

  /**
   * Handles rendering the services
   */
  const renderRequestedServices = () => {
    return (
      <div className={classes.services}>
        {servicesReq &&
          servicesReq.map((service, idx) => {
            return (
              <div key={idx} className={classes.serviceBox}>
                {service}
              </div>
            );
          })}
      </div>
    );
  };

  /**
   * Halves an array in 2
   */
  const sliceArray = (array, returnedPart) => {
    let carDataArray = array;
    let halfwayThrough = Math.ceil(carDataArray.length / 2);

    let arrayFirstHalf = carDataArray.slice(0, halfwayThrough);
    let arraySecondHalf = carDataArray.slice(
      halfwayThrough,
      carDataArray.length
    );

    if (returnedPart === 1) {
      return arrayFirstHalf;
    } else {
      return arraySecondHalf;
    }
  };

  /**
   * Defines the table title component
   */
  const TableTitle = (props) => {
    const { title } = props;

    return (
      <Typography variant="caption" className={classes.tableTitle}>
        {title}
      </Typography>
    );
  };

  /**
   * Set up work order view data
   *
   * @param data
   */
  const setUpWorkOrderViewData = (data) => {
    setData(data);
    setReady(true);
    setWorkOrderSummary(data);
    setTimeout(() => {
      setOrderID(data.uuid);
      setSummaryUpdated(true);
    }, 300);
  };

  /**
   * Makes the initial search
   */
  useEffect(() => {
    if (!summaryUpdated && !props.data?.id) {
      getWorkOrder(workOrderID);
    }
    // eslint-disable-next-line
  }, [summaryUpdated]);

  useEffect(() => {
    if (
      data &&
      data.car &&
      Object.keys(data.car).length < 1 &&
      summaryUpdated
    ) {
      setReady(true);
      setOrderID(workOrderSummary.uuid);
      setData(workOrderSummary);
    }
    // eslint-disable-next-line
  }, [data.car]);

  /**
   * Builds the data tables if the api call is resolved
   */
  useEffect(() => {
    if (ready) {
      /**
       * Defines the base render condition
       * Some data is rendered conditionally
       */
      const render = true;

      /**
       * Gets the data
       */
      const org_client = organization_client ? organization_client : {};

      const { is_company, type, loyalty_card } = org_client;
      const { car_km, new_tyre, tyre_pressure, bolt_torque } = tyre_service;
      const {
        make,
        model,
        manufacturing_year,
        type: carType,
        description,
      } = car;
      const { name } = work_order_type;

      /**
       * Builds the tyre dimensions
       */
      const tyreDimensions = buildTyreDimString(tyre_service);

      /**
       * Defines the order meta data
       */
      const orderData = [
        { label: t("orderID"), value: uuid, render },
        { label: t("updatedAt"), value: finished, render },
        { label: t("workOrderType"), value: name, render },
      ];

      /**
       * Defines the client data
       */
      const clientData = [
        { label: t("clientName"), value: client_name, render },
        { label: t("delegate"), value: delegate_name, render: is_company },
        { label: t("clientType"), value: t(type), render },
        {
          label: t("loyalty_card"),
          value: loyalty_card && loyalty_card.card_number,
          render,
        },
      ];

      /**
       * Defines the car data
       */
      const carData = [
        { label: t("carPlate"), value: car_plate_number, render },
        { label: t("carMake"), value: make, render },
        { label: t("carModel"), value: model, render },
        { label: t("carType"), value: carType, render },
        { label: t("observations"), value: workOrderDescription, render },
        { label: t("carDescription"), value: description, render },
      ];

      if (name === "Service Auto") {
        carData.push({ label: t("carKm"), value: car_km, render });
        carData.push({
          label: t("manufacturing_yearLabel"),
          value: manufacturing_year,
          render,
        });
      }

      if (name === "Vulcanizare") {
        carData.push({ label: t("newTyre"), value: t(new_tyre), render });
        carData.push({ label: t("carKm"), value: car_km, render });
        carData.push({
          label: t("tyreDimensions"),
          value: tyreDimensions,
          render,
        });
        carData.push({
          label: t("tyrePressure"),
          value: tyre_pressure,
          render,
        });
        carData.push({ label: t("boltTorque"), value: bolt_torque, render });
      }

      /**
       * Updates the state
       */
      setOrderData(orderData);
      setClientData(clientData);
      setCarData(carData);
      setReady(false);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Updates the products and services state
   */
  useEffect(() => {
    if (data && data.products) {
      const items = data.products.map((product) => {
        /**
         * Gets the is service flag
         */
        const isService =
          product.original_product && product.original_product.is_service;

        /**
         * Gets the is count price flag
         */
        const isCountPrice = product.price > 0;

        /**
         * Gets the unit of measure
         */
        const unitOfMeasure =
          product.original_product && product.original_product.um;

        return {
          ...product,
          is_service: isService,
          is_count_price: isCountPrice,
          um: unitOfMeasure,
        };
      });

      /**
       * Defines the products array
       */
      const products = items.filter(
        (item) =>
          item.is_service === false ||
          item.is_service === null ||
          !item.is_service
      );

      /**
       * Defines the services array
       */
      const services = items.filter(
        (item) => item.is_service === true || item.is_service
      );

      /**
       * Defines the requested services array
       */
      const reqServices = services.map((service) => service.name);

      setProducts(products);
      setServicesReq(reqServices);
      setServicesExec(services);
    }
  }, [data]);

  /**
   * Handles inherited work order data
   */
  useEffect(() => {
    if (objectHasData(props.data)) {
      setUpWorkOrderViewData(props.data);
    }
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <Grid item container xs={12}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.blank}>
            <CardContent>
              <Grid container direction="column" justify="center">
                <div className={classes.titleContainer}>
                  <Typography variant="h1" className={classes.title}>
                    {t("workOrder")}
                  </Typography>
                </div>
              </Grid>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.box}
              >
                <Grid item container xs={6} sm={6}>
                  <Grid item container xs={12} direction="column">
                    <TableTitle title={t("orderDetails")} />
                    <DataTable {...dataTable} data={orderData} />
                  </Grid>
                </Grid>
                <Grid item container xs={6} sm={6}>
                  <Grid item container xs={12} direction="column">
                    <TableTitle title={t("clientData")} />
                    <DataTable {...dataTable} data={clientData} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className={classes.spacing}>
                    <TableTitle title={t("car")} />
                  </Grid>
                  <Grid item container xs={6} sm={6}>
                    <DataTable
                      {...dataTable}
                      className={classes.spacing}
                      data={sliceArray(carData, 1)}
                    />
                  </Grid>
                  <Grid item container xs={6} sm={6}>
                    <DataTable
                      {...dataTable}
                      className={classes.spacing}
                      data={sliceArray(carData, 2)}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <WorkOrderWorkers {...workOrderWorkers} workers={workers} />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.box}
              >
                <Grid container className={classes.boxItem}>
                  <WorkOrderTable
                    {...table}
                    title={t("products")}
                    data={{ items: products, discount }}
                    triggerHide={true}
                  />
                </Grid>
                <Grid container className={classes.boxItem}>
                  <Grid item container xs={12} direction="column">
                    <Typography
                      variant="caption"
                      className={classes.tableTitle}
                    >
                      {t("servicesRequested")}
                    </Typography>
                    <Grid container direction="column">
                      {renderRequestedServices()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.boxItem}>
                  <WorkOrderTable
                    {...table}
                    title={t("servicesExecuted")}
                    data={{ items: servicesExec, discount }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

WorkOrderView.propTypes = propTypes;
WorkOrderView.defaultProps = defaultProps;

export default WorkOrderView;
export {
  propTypes as WorkOrderViewPropTypes,
  defaultProps as WorkOrderViewDefaultProps,
};

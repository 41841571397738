/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    height: 25,
    width: 25
  }
}));

export { useStyles };

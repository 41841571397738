/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modalContent: {
    maxHeight: 660
  },
  modulesContainer: {
    margin: "0.5rem 0"
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem"
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem"
    }
  },
  form: {
    background: "transparent",
    boxShadow: "none"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  field: theme.mixins.searchField,
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white
    }
  },
  selectField: theme.mixins.searchSelect,
  submitBtn: theme.mixins.submitBtn
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */

const useStyles = makeStyles(theme => ({
  modalContent: {
    maxHeight: 660
  },
  modulesContainer: {
    margin: "0.5rem 0"
  },
  form: {
    background: "transparent",
    boxShadow: "none"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "0 1rem",
    width: 45,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  textField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: "0 1rem",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  selectField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    },
    "& label + .MuiInput-formControl": {
      marginTop: 14
    }
  },
  submitBtn: {
    ...theme.mixins.submitBtn,
    minWidth: 146
  },
  wrapper: {
    background: `${theme.palette.sidebar.main}`,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.border.main}`
  },
  header: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    position: "relative"
  },
  title: {
    fontFamily: theme.font.display,
    fontSize: "1.1rem",
    fontWeight: 800,
    color: theme.palette.primary.main
  },
  description: {
    fontFamily: theme.font.family,
    fontSize: "0.7rem",
    color: theme.palette.secondary.main
  },
  body: {
    background: theme.palette.common.white,
    padding: "0.5rem 1rem",
    margin: "0.5rem",
    display: "flex",
    flexDirection: "column"
  },
  priceContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9rem"
  },
  priceValue: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: theme.font.family,
    fontWeight: 800,
    fontSize: "2.5rem",
    color: theme.palette.primary.main,
    border: `1px solid #ececec`,
    margin: "0.5rem 0"
  },
  priceCurrency: {
    fontSize: "0.85rem",
    paddingBottom: "0.75rem",
    paddingRight: "0.25rem",
    fontFamily: theme.font.display,
    fontWeight: 800,
    color: theme.palette.secondary.main
  },
  priceMonthly: {
    fontSize: "0.85rem",
    paddingTop: "0.75rem",
    paddingLeft: "0.25rem",
    fontFamily: theme.font.display,
    fontWeight: 800,
    color: theme.palette.secondary.main
  },
  duration: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    fontSize: "0.95rem",
    color: theme.palette.secondary.light,
    "& svg": {
      color: theme.palette.primary.main,
      marginRight: "0.25rem"
    }
  },
  durationLabel: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    marginRight: "0.25rem",
    fontSize: "0.9rem",
    color: theme.palette.secondary.main
  },
  labelContainer: {
    backgroundColor: "#f0edec",
    padding: "0.25rem",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    display: "flex",
    alignItems: "center"
  },
  label: {
    paddingLeft: "0.25rem",
    color: "rgba(0,0,0,.8)",
    fontSize: "0.7rem",
    height: "100%",
    display: "flex",
    flex: 1,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem"
    }
  },
  valueContainer: {
    backgroundColor: "#fff",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    borderLeft: "none",
    textAlign: "end"
  },
  value: {
    color: "rgba(0,0,0,.9)",
    fontSize: "0.95rem",
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
    fontFamily: theme.font.family,
    height: "100%",
    paddingLeft: "0.25rem",
    paddingRight: "0.5rem",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem"
    }
  },
  price: {
    borderBottom: "1px solid #c2b9b3"
  },
  formContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    margin: "2rem 0"
  },
  qtyLabel: {
    position: "absolute",
    top: -25,
    marginLeft: 0,
    fontSize: "0.8rem",
    opacity: 1,
    fontWeight: 800
  },
  qtyValue: {
    margin: "0 1rem",
    fontSize: "1rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.main,
    padding: "0 1rem",
    width: 50
  },
  substraction: {
    padding: 0,
    margin: 0,
    width: 35,
    minWidth: 35,
    height: 30,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: 5,
    "&:hover": {
      background: theme.palette.success.light,
      color: theme.palette.common.white
    }
  },
  icon: {
    margin: "0 0.25rem",
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem"
    }
  },
  addition: {
    padding: 0,
    margin: 0,
    width: 35,
    minWidth: 35,
    height: 30,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: 5,
    "&:hover": {
      background: theme.palette.success.light,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

/**
 * Styles Imports
 */
import { useStyles } from "./CompanyDataForm.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
};

/**
 * Displays the component
 */
const CompanyDataForm = (props) => {
  const {
    input,
    button,
    inputs,
    errors,
    handleNext,
    handleInputChange,
    getAutoFocus,
    handleLoginPageRouting,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the inputs and errors
   */
  const {
    company_name,
    vat_number,
    reg_number,
    city,
    number,
    street,
    county,
    country,
    company_phone,
    company_email,
    company_webpage,
    company_time_sheet,
  } = inputs;

  const {
    company_name: company_nameError,
    vat_number: vat_numberError,
    reg_number: reg_numberError,
    city: cityError,
    number: numberError,
    street: streetError,
    county: countyError,
    country: countryError,
    company_phone: company_phoneError,
    company_email: company_emailError,
    company_webpage: company_webpageError,
    company_time_sheet: company_time_sheetError,
  } = errors;

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} sm={10} md={10} className={classes.form}>
        <Card className={classes.blank}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "company_name",
                    required: true,
                    name: "company_name",
                    value: company_name,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("company_nameLabel"),
                    placeholder: t("company_namePlaceholder"),
                    error: company_nameError,
                    autoFocus: getAutoFocus().company_name,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "vat_number",
                    required: true,
                    name: "vat_number",
                    value: vat_number,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("vat_numberLabel"),
                    placeholder: t("vat_numberPlaceholder"),
                    error: vat_numberError,
                    autoFocus: getAutoFocus().vat_number,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "reg_number",
                    required: true,
                    name: "reg_number",
                    value: reg_number,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("reg_numberLabel"),
                    placeholder: t("reg_numberPlaceholder"),
                    error: reg_numberError,
                    autoFocus: getAutoFocus().reg_number,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "country",
                    required: true,
                    name: "country",
                    value: country,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("countryLabel"),
                    placeholder: t("countryPlaceholder"),
                    error: countryError,
                    autoFocus: getAutoFocus().country,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "city",
                    required: true,
                    name: "city",
                    value: city,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("cityLabel"),
                    placeholder: t("cityPlaceholder"),
                    error: cityError,
                    autoFocus: getAutoFocus().city,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "county",
                    required: true,
                    name: "county",
                    value: county,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("countyLabel"),
                    placeholder: t("countyPlaceholder"),
                    error: countyError,
                    autoFocus: getAutoFocus().county,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "street",
                    required: true,
                    name: "street",
                    value: street,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("streetLabel"),
                    placeholder: t("streetPlaceholder"),
                    error: streetError,
                    autoFocus: getAutoFocus().street,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "number",
                    required: true,
                    name: "number",
                    value: number,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("numberLabel"),
                    error: numberError,
                    autoFocus: getAutoFocus().number,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "company_email",
                    required: true,
                    name: "company_email",
                    value: company_email,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("company_emailLabel"),
                    placeholder: t("company_emailPlaceholder"),
                    error: company_emailError,
                    autoFocus: getAutoFocus().company_email,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  {...input}
                  type="phone"
                  className={classes.field}
                  inputPhone={{
                    id: "company_phone",
                    required: true,
                    name: "company_phone",
                    value: company_phone,
                    onChange: handleInputChange,
                    variant: "standard",
                    label: t("company_phoneLabel"),
                    builtInErrorMsg: t("phoneAllowedChars"),
                    placeholder: t("company_phonePlaceholder"),
                    error: company_phoneError,
                    autoFocus: getAutoFocus().company_phone,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "company_webpage",
                    name: "company_webpage",
                    value: company_webpage,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("company_webpageLabel"),
                    placeholder: t("company_webpagePlaceholder"),
                    error: company_webpageError,
                    autoFocus: getAutoFocus().company_webpage,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "company_time_sheet",
                    name: "company_time_sheet",
                    value: company_time_sheet,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "standard",
                    label: t("company_time_sheetLabel"),
                    placeholder: t("company_time_sheetPlaceholder"),
                    error: company_time_sheetError,
                    autoFocus: getAutoFocus().company_time_sheet,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Typography
              className={classes.loginLink}
              onClick={handleLoginPageRouting}
            >
              {t("haveAnAccount")}
            </Typography>
            <Button
              {...button}
              type="button"
              variant="filled"
              onClick={handleNext}
              className={classes.submitBtn}
            >
              {t("next")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

CompanyDataForm.propTypes = propTypes;
CompanyDataForm.defaultProps = defaultProps;

export default CompanyDataForm;
export {
  propTypes as CompanyDataFormPropTypes,
  defaultProps as CompanyDataFormDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./Widget.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  name: PropTypes.string,
  year: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  name: "",
  value: 0,
  index: 0,
  year: "",
};

/**
 * Displays the component
 */
const Widget = (props) => {
  const { name, value, year, index } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {index === 0 && (
        <Typography variant="caption" className={classes.year}>
          {t(year)}
        </Typography>
      )}
      <div className={classes.widget}>
        <Typography variant="caption" className={classes.title}>
          {t(name)}
        </Typography>
        <Typography variant="caption" className={classes.value}>
          {value}
        </Typography>
      </div>
    </div>
  );
};

Widget.propTypes = propTypes;
Widget.defaultProps = defaultProps;

export default Widget;
export { propTypes as WidgetPropTypes, defaultProps as WidgetDefaultProps };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./CarTypesTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  loading: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  handleDelete: null,
  handleAdd: () => {},
  handleEdit: () => {},
  loading: false,
};

/**
 * Displays the component
 */
const CarTypesTable = (props) => {
  const {
    editButton,
    deleteButton,
    dynamicTable,
    handleAdd,
    handleEdit,
    handleDelete,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () =>
    user.car_types && user.car_types.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = () => {
    let tableOptions = {
      withCount: true,
      withAdd: true,
      withSearch: true,
      withPagination: false,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: false,
        addResult: true,
      },
      addResult: handleAdd,
      defaultOrderBy: "name",
      fields: [
        {
          name: "name",
          label: t("name"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "short_name",
          label: t("short_name"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "description",
          label: t("description"),
          align: "start",
          minSize: 400,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "operations",
          label: t("operations"),
          type: "actions",
          key: false,
          align: "center",
          searchField: false,
          buttons: [
            {
              name: "edit_method",
              type: "edit",
              icon: null,
              component: (
                <EditButton
                  {...editButton}
                  className={classes.button}
                  title={t("edit")}
                />
              ),
              action: handleEdit,
            },
            {
              name: "delete_method",
              type: "delete",
              icon: null,
              component: (
                <DeleteButton
                  {...deleteButton}
                  className={classes.button}
                  title={t("delete")}
                />
              ),
              action: handleDelete,
            },
          ],
        },
      ],
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions();
    setTableOptions(options);
    // eslint-disable-next-line
  }, []);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.car_types) {
      const data = user.car_types.map((user) => {
        const { description, short_name } = user;

        return {
          ...user,
          description: description ? description : " - ",
          short_name: short_name ? short_name : " - ",
        };
      });
      setCollection(data);
    }
  }, [user]);

  return renderDynamicTable();
};

CarTypesTable.propTypes = propTypes;
CarTypesTable.defaultProps = defaultProps;

export default CarTypesTable;
export {
  propTypes as CarTypesTablePropTypes,
  defaultProps as CarTypesTableDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate, isDate } from "date-fns";
import { ro } from "date-fns/locale";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes
} from "../DeleteButton";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./WorkersTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFiltersReset: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  handleDelete: null,
  handleAdd: () => {},
  handleEdit: () => {},
  handlePageChange: () => {},
  handleChangeRowsPerPage: () => {},
  handleFiltersReset: () => {},
  rowsPerPage: 0,
  page: 0,
  count: 0
};

/**
 * Displays the component
 */
const WorkersTable = (props) => {
  const {
    editButton,
    deleteButton,
    dynamicTable,
    handleAdd,
    handleEdit,
    handleDelete,
    handlePageChange,
    handleChangeRowsPerPage,
    handleFiltersReset,
    rowsPerPage,
    page,
    count
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Handles mapping the id  with the organization name
   * @param {String|Number} id
   */
  const getOrganizationName = (id) => {
    if (organizations) {
      const organization = organizations.find((org) => org.id === id);
      return organization ? organization.name : "";
    }
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () => user.workers && user.workers.length < 1;

  const isDate18orMoreYearsOld = (day, month, year) => {
    return new Date(year + 18, month - 1, day) <= new Date();
  };

  const verifyDate = (birth_date) => {
    if (!isDate(birth_date)) return false;
    const day = Number(formatDate(new Date(birth_date), "d"));
    const month = Number(formatDate(new Date(birth_date), "MM"));
    const year = Number(formatDate(new Date(birth_date), "yyyy"));

    return isDate18orMoreYearsOld(day, month, year);
  };

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const {
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage
    } = props;

    let tableOptions = {
      withCount: true,
      withAdd: true,
      withSearch: true,
      withPagination: true,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: true,
        addResult: true
      },
      pagination: {
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        total: count,
        currentPage: page
      },
      addResult: handleAdd,
      resetFilters: handleFiltersReset,
      defaultOrderBy: "name",
      fields: [
        {
          name: "name",
          label: t("name"),
          align: "start",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "organization_id",
          label: t("organization_id"),
          align: "start",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "role",
          label: t("role"),
          align: "start",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "birth_date",
          label: t("birth_date"),
          align: "start",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "operations",
          label: t("operations"),
          type: "actions",
          key: false,
          align: "center",
          searchField: false,
          buttons: [
            {
              name: "edit_worker",
              type: "edit",
              icon: null,
              component: (
                <EditButton
                  {...editButton}
                  className={classes.button}
                  title={t("edit")}
                />
              ),
              action: handleEdit
            },
            {
              name: "delete_worker",
              type: "delete",
              icon: null,
              component: (
                <DeleteButton
                  {...deleteButton}
                  className={classes.button}
                  title={t("delete")}
                />
              ),
              action: handleDelete
            }
          ]
        }
      ]
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage
    });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [count, page, rowsPerPage]);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.organizations) {
      setOrganizations(user.organizations);
    }
    if (user.workers) {
      const data = user.workers.map((user) => {
        const { birth_date, organization_id, function: role } = user;

        return {
          ...user,
          birth_date: verifyDate(birth_date)
            ? formatDate(new Date(birth_date), "d MMMM yyyy", {
                locale: ro
              })
            : " - ",
          role: role ? t(role) : t("roleMissing"),
          organization_id: getOrganizationName(organization_id)
        };
      });
      setCollection(data);
    }
    // eslint-disable-next-line
  }, [user]);

  return renderDynamicTable();
};

WorkersTable.propTypes = propTypes;
WorkersTable.defaultProps = defaultProps;

export default WorkersTable;
export {
  propTypes as WorkersTablePropTypes,
  defaultProps as WorkersTableDefaultProps
};

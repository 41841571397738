import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { formatDistanceToNow, differenceInSeconds } from "date-fns";
import { ro } from "date-fns/locale";

/**
 * Imports Material UI Components
 */
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";

/**
 * Imports hooks
 */
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUser, useAuth } from "../../hooks";

/**
 * Imports the component styles
 */
import { useStyles } from "./RecentlyCreatedWorkOrders.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  recentlyCreated: PropTypes.array,
  setRecentlyCreated: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  recentlyCreated: [],
  setRecentlyCreated: () => {},
};

/**
 * Displays the component
 */
const RecentlyCreatedWorkOrders = (props) => {
  const { recentlyCreated, setRecentlyCreated } = props;

  /**
   * Gets the translator
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global auth state
   */
  const { auth } = useAuth();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Gets the history object
   */
  const history = useHistory();

  const renderRecentlyCreated = () => {
    if (user.settings) {
      return recentlyCreated && recentlyCreated.length > 0 ? (
        recentlyCreated.map((work, idx) => {
          const hidden = work.user !== auth.user.uuid;
          if (hidden) return null;

          const handleSearch = () =>
            history.push(
              `/modules/workstation-settings/work-orders?search=${work.uuid}`
            );

          const handleDelete = () => {
            setRecentlyCreated(
              recentlyCreated.filter((item) => item.uuid !== work.uuid)
            );
          };

          const handleTimeSpent = () => {
            let diffInSeconds = differenceInSeconds(
              new Date(),
              new Date(work.created_at)
            );
            if (diffInSeconds < 60) {
              return `${t("now")} 1 minut.`;
            } else {
              return `${t("now")} ${formatDistanceToNow(
                new Date(work.created_at),
                {
                  locale: ro,
                }
              )}`;
            }
          };

          return (
            <ListItem
              key={work.uuid}
              className={
                idx === recentlyCreated.length - 1 ? "" : classes.listItem
              }
            >
              {user.type !== "worker" && (
                <ListItemIcon className={classes.listIcon}>
                  <SearchIcon onClick={handleSearch} />
                </ListItemIcon>
              )}

              <div className={classes.flexContainer}>
                <div className={classes.listText}>
                  <div className={classes.primary}>
                    <div className={classes.plate_number}>
                      {work.plate_number}
                    </div>
                    <div className={classes.uuid}>{`(${work.uuid})`}</div>
                  </div>
                  <div className={classes.secondary}>
                    <div className={classes.created_at}>
                      {handleTimeSpent()}
                    </div>
                  </div>
                </div>
                <ListItemIcon className={classes.listIcon}>
                  <DeleteIcon onClick={handleDelete} />
                </ListItemIcon>
              </div>
            </ListItem>
          );
        })
      ) : (
        <ListItem>
          <Typography variant="caption" className={classes.notFound}>
            {t("noRecentlyAddedOrdersFound")}
          </Typography>
        </ListItem>
      );
    }
  };

  return (
    <div className={classes.recentlyCreated}>
      <List dense={true} className={classes.list}>
        <ListItem className={classes.listHeader}>
          <Typography variant="caption" className={classes.listTitleText}>
            {t("recentlyCreated")}
          </Typography>
        </ListItem>
        <div className={classes.box}>{renderRecentlyCreated()}</div>
        <ListItem
          className={classes.listFooter}
          classes={{ gutters: classes.lowGutters }}
        >
          <Typography variant="caption" className={classes.disclaimer}>
            <InfoIcon />
            {t("disclaimer")}
          </Typography>
        </ListItem>
      </List>
    </div>
  );
};

RecentlyCreatedWorkOrders.propTypes = propTypes;
RecentlyCreatedWorkOrders.defaultProps = defaultProps;

export default RecentlyCreatedWorkOrders;
export {
  propTypes as RecentlyCreatedWorkOrdersPropTypes,
  defaultProps as RecentlyCreatedWorkOrdersDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

/**
 * Styles Imports
 */
import { useStyles } from "./ContactDataForm.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
};

/**
 * Displays the component
 */
const ContactDataForm = (props) => {
  const {
    input,
    button,
    inputs,
    errors,
    handleNext,
    handleInputChange,
    getAutoFocus,
    prevStep,
    handleLoginPageRouting,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the inputs and errors
   */
  const { first_name, last_name, phone } = inputs;

  const {
    first_name: first_nameError,
    last_name: last_nameError,
    phone: phoneError,
  } = errors;

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} sm={10} md={10} className={classes.form}>
        <Card className={classes.blank}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <div className={classes.inputContainer}>
                  <Input
                    {...input}
                    type="text"
                    className={classes.field}
                    inputText={{
                      id: "first_name",
                      required: true,
                      name: "first_name",
                      value: first_name,
                      onChange: handleInputChange,
                      maxSize: 70,
                      variant: "standard",
                      label: t("first_nameLabel"),
                      error: first_nameError,
                      autoFocus: getAutoFocus().first_name,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.inputContainer}>
                  <Input
                    {...input}
                    type="text"
                    className={classes.field}
                    inputText={{
                      id: "last_name",
                      required: true,
                      name: "last_name",
                      value: last_name,
                      onChange: handleInputChange,
                      maxSize: 70,
                      variant: "standard",
                      label: t("last_nameLabel"),
                      error: last_nameError,
                      autoFocus: getAutoFocus().last_name,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.inputContainer}>
                  <Input
                    {...input}
                    type="phone"
                    className={classes.field}
                    inputPhone={{
                      required: true,
                      id: "phone",
                      name: "phone",
                      value: phone,
                      onChange: handleInputChange,
                      placeholder: "",
                      variant: "standard",
                      label: t("phoneLabel"),
                      error: phoneError,
                      builtInErrorMsg: t("phoneAllowedChars"),
                      autoFocus: getAutoFocus().phone,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions className={classes.actions}>
            <Typography
              className={classes.loginLink}
              onClick={handleLoginPageRouting}
            >
              {t("haveAnAccount")}
            </Typography>
            <div className={classes.buttonsContainer}>
              <Button
                {...button}
                type="button"
                variant="filled"
                onClick={prevStep}
                className={classes.submitBtn}
              >
                {t("previous")}
              </Button>
              <Button
                {...button}
                type="button"
                variant="filled"
                onClick={handleNext}
                className={classes.submitBtn}
              >
                {t("next")}
              </Button>
            </div>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

ContactDataForm.propTypes = propTypes;
ContactDataForm.defaultProps = defaultProps;

export default ContactDataForm;
export {
  propTypes as ContactDataFormPropTypes,
  defaultProps as ContactDataFormDefaultProps,
};

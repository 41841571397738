import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import Widget, { WidgetDefaultProps, WidgetPropTypes } from "../Widget";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./Widgets.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  widget: PropTypes.shape(WidgetPropTypes),
  ready: PropTypes.bool,
  widgets: PropTypes.array,
  transitionTimeout: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  widget: WidgetDefaultProps,
  ready: false,
  widgets: [],
  transitionTimeout: {
    appear: 300,
    enter: 300,
    exit: 300,
  },
};

/**
 * Displays the component
 */
const Widgets = (props) => {
  const { ready, widgets, widget, transitionTimeout } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles rendering the widgets
   */
  const renderWidgets = () => {
    const isAuthorized = user.type !== "worker";
    const isArray = Array.isArray(widgets);

    return ready && isAuthorized && isArray
      ? widgets.map((widgetData) => {
          return widgetData.map((w, index) => {
            return (
              <Zoom key={w.name} in={true} timeout={transitionTimeout}>
                <Grid item xs={4}>
                  <Widget {...widget} {...w} index={index} />
                </Grid>
              </Zoom>
            );
          });
        })
      : null;
  };

  return (
    <Grid container justify="center">
      <Grid
        container
        item
        xs={12}
        md={8}
        spacing={2}
        className={classes.widgets}
      >
        {renderWidgets()}
      </Grid>
    </Grid>
  );
};

Widgets.propTypes = propTypes;
Widgets.defaultProps = defaultProps;

export default Widgets;
export { propTypes as WidgetsPropTypes, defaultProps as WidgetsDefaultProps };

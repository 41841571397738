/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modalContent: {
    paddingTop: 0
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: "transparent"
  }
}));

export { useStyles };

import React, { useState, useContext, createContext } from "react";

/**
 * Imports hooks
 */
import { useLocalStorage } from "./index";

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const workOrderContext = createContext();

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const WorkOrderProvider = (props) => {
  const { children } = props;

  /**
   * Gets the provider from the context
   */
  const { Provider } = workOrderContext;

  /**
   * Initializes the work order state
   */
  const [workOrder, setWorkOrder] = useState({});

  /**
   * Initializes the drafts
   */
  const [drafts, setDrafts] = useLocalStorage("drafts", []);

  /**
   * Initializes the current work order type
   */
  const [workOrderType, setWorkOrderType] = useState("");

  /**
   * Defines the provider data
   */
  const providerData = {
    workOrder,
    setWorkOrder,
    workOrderType,
    setWorkOrderType,
    drafts,
    setDrafts,
  };

  return <Provider value={providerData}>{children}</Provider>;
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 * - To be used inside components
 */
const useWorkOrder = () => {
  return useContext(workOrderContext);
};

export { useWorkOrder, WorkOrderProvider };

import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";
/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ReCaptcha, {
  ReCaptchaDefaultProps,
  ReCaptchaPropTypes,
} from "../ReCaptcha";
import DocumentModal, {
  DocumentModalDefaultProps,
  DocumentModalPropTypes,
} from "../DocumentModal";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

/**
 * Styles Imports
 */
import { useStyles } from "./PromotionCodeForm.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  reCaptcha: PropTypes.shape(ReCaptchaPropTypes),
  documentModal: PropTypes.shape(DocumentModalPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  reCaptcha: ReCaptchaDefaultProps,
  documentModal: DocumentModalDefaultProps,
  loadingText: LoadingTextDefaultProps,
  genders: [
    { label: "Masculin", value: "male" },
    { label: "Feminin", value: "female" },
    { label: "Altul", value: "other" },
  ],
};

/**
 * Displays the component
 */
const PromotionCodeForm = (props) => {
  const {
    input,
    button,
    inputs,
    errors,
    handleInputChange,
    getAutoFocus,
    prevStep,
    handleLoginPageRouting,
    reCaptcha,
    resetCaptcha,
    setResetCaptcha,
    handleCaptcha,
    documentModal,
    loading,
    loadingText,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the inputs and errors
   */
  const { promotion_code, terms, captcha } = inputs;
  const { promotion_code: promotion_codeError } = errors;

  /**
   * Initializes the state of the modal
   */
  const [modalState, setModalState] = useState({
    terms_and_conditions: false,
  });

  /**
   * Initializes the documents state
   */
  const [documents, setDocuments] = useState({
    terms_and_conditions: "",
  });

  /**
   * Handles updating the documents state
   */
  const updateDocument = (props) => {
    const { name, document } = props;

    setDocuments((prevState) => {
      return { ...prevState, [name]: document };
    });
  };

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the Terms and conditions Modal Handlers
   */
  const openTermsModal = () => handleOpenModal("terms_and_conditions");
  const closeTermsModal = () => handleCloseModal("terms_and_conditions");

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} sm={10} md={10} className={classes.form}>
        <Card className={classes.blank}>
          <CardContent>
            <DocumentModal
              {...documentModal}
              title={t("termsAndConditions")}
              documentName="terms_and_conditions"
              document={documents.terms_and_conditions}
              updateDocument={updateDocument}
              open={modalState.terms_and_conditions}
              closeModal={closeTermsModal}
            />
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.inputContainer}>
                  <Input
                    {...input}
                    type="text"
                    className={classes.field}
                    inputText={{
                      id: "promotion_code",
                      name: "promotion_code",
                      value: promotion_code,
                      onChange: handleInputChange,
                      maxSize: 70,
                      variant: "outlined",
                      label: t("promotion_codeLabel"),
                      error: promotion_codeError,
                      autoFocus: getAutoFocus().promotion_code,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.inputContainer}>
                  <Box className={classes.checkboxContainer}>
                    <Input
                      {...input}
                      type="checkbox"
                      className={classes.checkboxField}
                      inputCheckbox={{
                        id: "terms",
                        required: true,
                        name: "terms",
                        value: terms,
                        variant: "standard",
                        onChange: handleInputChange,
                        classes: { root: classes.checkbox },
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.disclaimer}
                      onClick={openTermsModal}
                    >
                      {t("acceptTermsAndConditions")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ReCaptcha
                  {...reCaptcha}
                  className={classes.reCaptcha}
                  setResetCaptcha={setResetCaptcha}
                  resetCaptcha={resetCaptcha}
                  handleCaptcha={handleCaptcha}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Typography
              className={classes.loginLink}
              onClick={handleLoginPageRouting}
            >
              {t("haveAnAccount")}
            </Typography>
            <Box className={classes.buttonsContainer}>
              <Button
                {...button}
                type="button"
                variant="filled"
                onClick={prevStep}
                className={classes.submitBtn}
              >
                {t("previous")}
              </Button>
              <Button
                {...button}
                type="submit"
                variant="filled"
                disabled={!terms || !captcha}
                className={classes.submitBtn}
              >
                <LoadingText
                  {...loadingText}
                  loading={loading}
                  text={t("finalizeRegister")}
                />
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

PromotionCodeForm.propTypes = propTypes;
PromotionCodeForm.defaultProps = defaultProps;

export default PromotionCodeForm;
export {
  propTypes as PromotionCodeFormPropTypes,
  defaultProps as PromotionCodeFormDefaultProps,
};

import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import TabPanel, { TabPanelDefaultProps, TabPanelPropTypes } from "../TabPanel";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import EditWorkOrderStage1, {
  EditWorkOrderStage1DefaultProps,
  EditWorkOrderStage1PropTypes,
} from "../EditWorkOrderStage1";
import EditWorkOrderStage2, {
  EditWorkOrderStage2DefaultProps,
  EditWorkOrderStage2PropTypes,
} from "../EditWorkOrderStage2";
import EditWorkOrderStage3, {
  EditWorkOrderStage3DefaultProps,
  EditWorkOrderStage3PropTypes,
} from "../EditWorkOrderStage3";
import BarcodeReader, {
  BarcodeReaderDefaultProps,
  BarcodeReaderPropTypes,
} from "../BarcodeReader";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

/**
 * Hooks
 */
import { useMessage, useApiClient, useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkOrderModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  tabPanel: PropTypes.shape(TabPanelPropTypes),
  stage1: PropTypes.shape(EditWorkOrderStage1PropTypes),
  stage2: PropTypes.shape(EditWorkOrderStage2PropTypes),
  stage3: PropTypes.shape(EditWorkOrderStage3PropTypes),
  barcode: PropTypes.shape(BarcodeReaderPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  data: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  tabPanel: TabPanelDefaultProps,
  stage1: EditWorkOrderStage1DefaultProps,
  stage2: EditWorkOrderStage2DefaultProps,
  stage3: EditWorkOrderStage3DefaultProps,
  barcode: BarcodeReaderDefaultProps,
  open: false,
  closeModal: () => {},
  data: {},
};

/**
 * Handles returning the props for each tab
 */
const a11yProps = (index) => {
  return {
    id: `stage-tab-${index}`,
    "aria-controls": `stage-tabpanel-${index}`,
  };
};

/**
 * Displays the component
 */
const EditWorkOrderModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    stage1,
    stage2,
    stage3,
    barcode,
    closeModal,
    data,
    tabPanel,
    errorMessages,
    setEditData,
    setUpdated,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the user
   */
  const { user } = useUser();

  const [tyreService, setTyreService] = useState({});
  const [selectedCarType, setSelectedCarType] = useState("");
  const [summaryUpdated, setSummaryUpdated] = useState(false);
  const [workOrderSummary, setWorkOrderSummary] = useState({});

  const [workOrderItems, setWorkOrderItems] = useState({
    products: [],
    services: [],
  });

  /**
   * Initializes the work order data
   */
  const [workOrder, setWorkOrder] = useState({});

  const [scanning, setScanning] = useState(false);

  /**
   * Initializes the workers state
   */
  const [workers, setWorkers] = useState([]);

  /**
   * Initializes the data updated flag
   */
  const [dataUpdated, setDataUpdated] = useState(false);
  const [triggerPricingUpdate, setTriggerPricingUpdate] = useState(false);
  const [loyaltyCardData, setLoyaltyCardData] = useState({});
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerScanning, setTriggerScanning] = useState(false);

  /**
   * Handles getting a work order by id
   */
  const getWorkOrder = async (id) => {
    try {
      const workOrder = await apiClient.get(`/work-orders/${id}`);
      if (workOrder) {
        const { data } = workOrder;
        setWorkOrder(data);
        setWorkOrderSummary(data);
        setDataUpdated(false);
        setTriggerPricingUpdate(false);
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
      setDataUpdated(false);
      setTriggerPricingUpdate(false);
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetStep();
    if (triggerUpdate) {
      setTriggerUpdate(false);
      setUpdated(true);
    }
    setEditData({});
    closeModal();
  };

  /**
   * Setting up the initial state
   */
  const initState = { activeStep: 0 };

  /**
   * Sets up the reducer
   */
  const reducer = (state, action) => {
    const { type, step } = action;
    const { activeStep } = state;

    switch (type) {
      case "STEP_NEXT":
        return { activeStep: activeStep + 1 };
      case "STEP_BACK":
        return { activeStep: activeStep - 1 };
      case "STEP_RESET":
        return { activeStep: 0 };
      case "STEP_UPDATE":
        return { activeStep: step };
      default:
        return state;
    }
  };

  const [triggerSave, setTriggerSave] = useState(false);
  const [saved, setSaved] = useState(false);

  /**
   * Initializes the client names list
   */
  const [clientNames, setClientNames] = useState([]);

  /**
   * Initializes the active step state
   */
  const [state, dispatch] = useReducer(reducer, initState);
  const { activeStep } = state;

  /**
   * Defines the base dispatch actions
   */
  const nextStep = () => dispatch({ type: "STEP_NEXT" });
  const prevStep = () => dispatch({ type: "STEP_BACK" });
  const updateStep = (step) => dispatch({ type: "STEP_UPDATE", step });
  const resetStep = () => dispatch({ type: "STEP_RESET" });

  /**
   * Handles rendering the tabs
   */
  const renderTabs = () => {
    /**
     * Defines the tabs data
     */
    const tabs = [
      { uuid: "stage_1", name: t("editStage1") },
      { uuid: "stage_2", name: t("editStage2") },
      { uuid: "stage_3", name: t("editStage3") },
    ];

    return (
      tabs &&
      tabs.map((tab, idx) => {
        return <Tab key={tab.uuid} label={tab.name} {...a11yProps(idx)} />;
      })
    );
  };

  /**
   * Handles the tab change
   */
  const handleChange = (event, newValue) => {
    updateStep(newValue);
  };

  /**
   * Handles changing the tab/step once data is saved
   */
  useEffect(() => {
    if (saved) {
      setTriggerUpdate(true);
      setSaved(false);
    }
  }, [saved]);

  /**
   * Handles getting the workOrder
   */
  useEffect(() => {
    if (data.id && open) {
      getWorkOrder(data.id);
    }
    // eslint-disable-next-line
  }, [data.id, open]);

  /**
   * Handles getting the workers
   */
  useEffect(() => {
    if (dataUpdated) getWorkOrder(data.id);
    // eslint-disable-next-line
  }, [dataUpdated]);

  /**
   * Handles barcode scanner output object
   */
  const handleBarcodeScanner = (loyaltyCard) => {
    if (loyaltyCard && loyaltyCard.organization_client_id) {
      setLoyaltyCardData(loyaltyCard);
    }
  };

  /**
   * Handles first mount changes
   */
  useEffect(() => {
    if (user.clients) {
      setClientNames(user.clients);
    }

    if (user.workers) {
      setWorkers(user.workesr);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (triggerScanning) {
      setTimeout(() => {
        setScanning(false);
        setTriggerScanning(false);
      }, [1500]);
    }
  }, [triggerScanning]);

  useEffect(() => {
    if (Object.keys(workOrder).length > 0)
      setSelectedCarType(workOrder.car_type.id);
  }, [workOrder]);

  return (
    <Modal
      {...modal}
      fullScreen={triggerFullScreen}
      maxWidth="lg"
      open={open}
      onClose={handleCloseModal}
      keepMounted={false}
      scroll="paper"
    >
      <ModalTitle
        {...modalTitle}
        title={`${t("titleEditWorkOrder")}${data.uuid}`}
        onClick={handleCloseModal}
      />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid item container xs={12}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank}>
                <CardContent className={classes.content}>
                  <Grid container justify="center">
                    <Grid item xs={12} md={12}>
                      <div className={classes.root}>
                        <AppBar
                          position="static"
                          color="default"
                          className={classes.appbar}
                        >
                          <Tabs
                            className={classes.tabs}
                            value={activeStep}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="standard"
                          >
                            {renderTabs()}
                          </Tabs>
                        </AppBar>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <TabPanel {...tabPanel} value={activeStep} index={0}>
                        {open && activeStep === 0 && (
                          <EditWorkOrderStage1
                            {...stage1}
                            open={open}
                            scanning={scanning}
                            activeStep={activeStep}
                            nextStep={nextStep}
                            workOrder={workOrder}
                            workers={workers}
                            setDataUpdated={setDataUpdated}
                            triggerSave={triggerSave}
                            setTriggerSave={setTriggerSave}
                            setTriggerUpdate={setTriggerUpdate}
                            setSaved={setSaved}
                            loyaltyCardData={loyaltyCardData}
                            setLoyaltyCardData={setLoyaltyCardData}
                            clientNames={clientNames}
                            setSelectedCarType={setSelectedCarType}
                            setTyreService={setTyreService}
                          />
                        )}
                      </TabPanel>
                      <TabPanel {...tabPanel} value={activeStep} index={1}>
                        {open && activeStep === 1 && (
                          <EditWorkOrderStage2
                            {...stage2}
                            activeStep={activeStep}
                            prevStep={prevStep}
                            nextStep={nextStep}
                            setDataUpdated={setDataUpdated}
                            triggerPricingUpdate={triggerPricingUpdate}
                            setTriggerPricingUpdate={setTriggerPricingUpdate}
                            workOrder={workOrder}
                            setWorkOrder={setWorkOrder}
                            workOrderID={workOrder.id}
                            carType={selectedCarType}
                            tyreService={tyreService}
                            setTriggerUpdate={setTriggerUpdate}
                            workOrderItems={workOrderItems}
                            setWorkOrderItems={setWorkOrderItems}
                            clients={clientNames}
                            setWorkOrderSummary={setWorkOrderSummary}
                          />
                        )}
                      </TabPanel>
                      <TabPanel {...tabPanel} value={activeStep} index={2}>
                        {open && activeStep === 2 && (
                          <EditWorkOrderStage3
                            {...stage3}
                            activeStep={activeStep}
                            prevStep={prevStep}
                            nextStep={nextStep}
                            workOrderID={workOrder.id}
                            handleClose={handleCloseModal}
                            setTriggerUpdate={setTriggerUpdate}
                            dataUpdated={dataUpdated}
                            setSummaryUpdated={setSummaryUpdated}
                            summaryUpdated={summaryUpdated}
                            setWorkOrderSummary={setWorkOrderSummary}
                            workOrderSummary={workOrderSummary}
                            data={workOrder}
                          />
                        )}
                      </TabPanel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <BarcodeReader
        {...barcode}
        setScanning={setScanning}
        setTriggerScanning={setTriggerScanning}
        handleBarcodeScanner={handleBarcodeScanner}
        open={true}
      />
      <ModalActions {...modalActions} onClick={handleCloseModal} />
    </Modal>
  );
};

EditWorkOrderModal.propTypes = propTypes;
EditWorkOrderModal.defaultProps = defaultProps;

export default EditWorkOrderModal;
export {
  propTypes as EditWorkOrderModalPropTypes,
  defaultProps as EditWorkOrderModalDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    marginTop: "0.5rem",
    border: `1px solid #b9b9b9`,
  },
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  viewButton: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  changeStatus: {
    padding: "0.25rem 1rem",
    margin: "0.5rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.accent,
      boxShadow: "none",
    },
    display: "flex",
    flex: 1,
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem",
    },
  },
  popoverTitle: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "0.8rem",
    margin: 0,
    display: "block",
    padding: "0.5rem",
    paddingBottom: 0,
  },
  doneStatus: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  progressStatus: {
    background: theme.palette.warning.main,
    "&:hover": {
      background: theme.palette.warning.dark,
      boxShadow: "none",
    },
  },
  holdStatus: {
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

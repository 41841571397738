import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Imports i18n
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import Popover from "@material-ui/core/Popover";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";

/**
 * Imports the component styles
 */
import { useStyles } from "./SchedulerStatusChanger.styles";

/**
 * Hooks
 */
import { useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  disabled: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  disabled: false,
};

/**
 * Displays the component
 */
const SchedulerStatusChanger = (props) => {
  const { button, data, errorMessages, setUpdated, disabled } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Handles updating an appointment's status by id
   */
  const updateAppointmentStatus = async (id, status) => {
    try {
      const updatedAppointment = await apiClient.put(
        `/appointments/${id}/statuses/${status}`
      );
      if (updatedAppointment) {
        setUpdated(true);
        handleClosePopover();
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverID = open ? "scheduler-status-changer" : undefined;

  const triggerStatusChange = (data, status) => {
    updateAppointmentStatus(data.id, status);
  };

  const getStatusButtons = (data) => {
    const { status } = data;
    const toggleDone = () => triggerStatusChange(data, "done");
    const toggleNew = () => triggerStatusChange(data, "new");
    const toggleProgress = () => triggerStatusChange(data, "in-progress");
    const toggleSkipped = () => triggerStatusChange(data, "skipped");

    const buttons = [
      {
        name: "new",
        label: t("new"),
        className: clsx(classes.changeStatus),
        onClick: toggleNew,
      },
      {
        name: "done",
        label: t("done"),
        className: clsx(classes.changeStatus, classes.doneStatus),
        onClick: toggleDone,
      },
      {
        name: t("in-progress"),
        label: t("in-progress"),
        className: clsx(classes.changeStatus, classes.progressStatus),
        onClick: toggleProgress,
      },
      {
        name: "skipped",
        label: t("skipped"),
        className: clsx(classes.changeStatus, classes.holdStatus),
        onClick: toggleSkipped,
      },
    ];

    return buttons.filter((button) => button.name !== status);
  };

  return (
    <Fragment>
      <Button
        {...button}
        type="button"
        variant="filled"
        title={t("change_status")}
        className={clsx(classes.viewButton, {
          [classes.activeButton]: open,
        })}
        disabled={disabled}
        onClick={handleOpenPopover}
      >
        <SettingsIcon />
      </Button>
      <Popover
        id={popoverID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        TransitionComponent={Zoom}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.actions}>
          <Typography variant="caption" className={classes.popoverTitle}>
            {t("popoverTitle")}
          </Typography>
          <Fragment>
            {getStatusButtons(data).map((btn, index) => {
              return (
                <Button
                  {...button}
                  key={index}
                  type="button"
                  variant="filled"
                  onClick={btn.onClick}
                  className={btn.className}
                >
                  {btn.label}
                </Button>
              );
            })}
          </Fragment>
        </div>
      </Popover>
    </Fragment>
  );
};

SchedulerStatusChanger.propTypes = propTypes;
SchedulerStatusChanger.defaultProps = defaultProps;

export default SchedulerStatusChanger;
export {
  propTypes as SchedulerStatusChangerPropTypes,
  defaultProps as SchedulerStatusChangerDefaultProps,
};

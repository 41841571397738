import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useForm, useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkOrderSettingsModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  defaultValues: PropTypes.shape({
    payment_type: PropTypes.string,
    status: PropTypes.string,
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultValues: {
    payment_type: "",
    status: "",
  },
  organizations: [],
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditWorkOrderSettingsModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    button,
    loadingText,
    data,
    errorMessages,
    setUpdated,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  const { user } = useUser();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  const [statusList, setStatusList] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentTypeChanged, setPaymentTypeChanged] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const [statusChangedOK, setStatusChangedOK] = useState(false);
  const [paymentChangedOK, setPaymentChangeOK] = useState(false);

  const allStatuses = [
    {
      value: "new",
      label: t("new"),
    },
    {
      value: "in-progress",
      label: t("in-progress"),
    },
    { value: "on-hold", label: t("on-hold") },
    {
      value: "done",
      label: t("done"),
    },
  ];

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    autoFocus: true,
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { payment_type, status } = inputs;

  /**
   * Handles updating a work order by ID
   */
  const updateWorkOrderByID = async (data, id) => {
    try {
      const updatedWO = await apiClient.put(`/work-orders/${id}`, data);
      if (updatedWO) {
        setPaymentChangeOK(true);
      }
    } catch (error) {
      setLoading(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles updating a work order's status
   */
  const updateWorkOrderStatus = async (id, status) => {
    try {
      const updatedWOStatus = await apiClient.put(
        `/work-orders/${id}/status/${status}`
      );
      if (updatedWOStatus) {
        setStatusChangedOK(true);
      }
    } catch (error) {
      setLoading(false);
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    setStatusChanged(false);
    setPaymentTypeChanged(false);
    setStatusChangedOK(false);
    setPaymentChangeOK(false);
    closeModal();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const { statusValue, payment_type_id, payment_type } = data;

      const statusList = [...allStatuses];

      if (!payment_type_id || !payment_type) {
        statusList.pop();
      }

      setStatusList(statusList.filter((s) => s.value !== status));
      setInputs({
        payment_type: payment_type_id || paymentTypes[0]?.id,
        status: statusValue,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.settings) {
      const paymentTypes = user.settings.account.payment_types;
      setPaymentTypes(paymentTypes);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (payment_type) {
      setStatusList(allStatuses);
    }
    // eslint-disable-next-line
  }, [payment_type]);

  useEffect(() => {
    if (ready) {
      if (data.status !== status) setStatusChanged(true);
      if (data.payment_type_id !== payment_type) setPaymentTypeChanged(true);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (statusChanged) {
      updateWorkOrderStatus(data.id, status);
    }
    // eslint-disable-next-line
  }, [statusChanged]);

  useEffect(() => {
    if (paymentTypeChanged) {
      const workOrderData = {
        payment_type_id: payment_type,
      };

      updateWorkOrderByID(workOrderData, data.id);
    }
    // eslint-disable-next-line
  }, [paymentTypeChanged]);

  useEffect(() => {
    if (paymentChangedOK || statusChangedOK) {
      dispatchMessage({
        message: t("success"),
      });
      setLoading(false);
      setUpdated(true);
      handleCloseModal();
    }
    // eslint-disable-next-line
  }, [paymentChangedOK, statusChangedOK]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="xs"
        open={open}
        onClose={handleCloseModal}
        keepMounted={false}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={data.car_plate_number}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-worker-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "payment_type",
                              name: "payment_type",
                              value: payment_type,
                              onChange: handleInputChange,
                              label: t("payment_typeLabel"),
                              labelClass: classes.label,
                              variant: "outlined",
                              options: paymentTypes,
                              optionLabel: "name",
                              optionValue: "id",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "status",
                              name: "status",
                              value: status,
                              onChange: handleInputChange,
                              label: t("statusLabel"),
                              labelClass: classes.label,
                              variant: "outlined",
                              options: statusList,
                              optionLabel: "label",
                              optionValue: "value",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-worker-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditWorkOrderSettingsModal.propTypes = propTypes;
EditWorkOrderSettingsModal.defaultProps = defaultProps;

export default EditWorkOrderSettingsModal;
export {
  propTypes as EditWorkOrderSettingsModalPropTypes,
  defaultProps as EditWorkOrderSettingsModalDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Imports assets
 */
import ro_flag from "./ro_flag.png";
import hu_flag from "./hu_flag.png";
import en_flag from "./en_flag.png";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { ro, hu } from "date-fns/locale";
import { useHistory } from "react-router-dom";

/**
 * i18n Imports
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import TimesheetModal, {
  TimesheetModalDefaultProps,
  TimesheetModalPropTypes,
} from "../TimesheetModal";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import EventIcon from "@material-ui/icons/Event";
import CheckIcon from "@material-ui/icons/Check";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Brightness3RoundedIcon from "@material-ui/icons/Brightness3Rounded";
import Brightness7RoundedIcon from "@material-ui/icons/Brightness7Rounded";

/**
 * Hooks
 */
import {
  useAuth,
  useMessage,
  useTheme,
  useLanguage,
  useApiClient,
} from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ModulesHeader.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  user: PropTypes.object,
  timesheet: PropTypes.shape(TimesheetModalPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  user: {},
  timesheet: TimesheetModalDefaultProps,
};

/**
 * Displays the component
 */
const ModulesHeader = (props) => {
  const { timesheet, user } = props;

  /**
   * Handles the translation
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the organization and the account from the user object
   */
  const { organization, account } = user;

  /**
   * Gets the auth controllers
   */
  const { logout, auth } = useAuth();

  /**
   * Initializes the modal state
   */
  const [open, setOpen] = useState(false);

  /**
   * Gets active language
   */
  const { activeLanguage, setActiveLanguage } = useLanguage();

  /**
   * Initializes the suspended acc state
   */
  const [isSuspended, setIsSuspended] = useState(false);

  /**
   * Initializes the deleted acc state
   */
  const [isDeleted, setIsDeleted] = useState(false);

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the language dropdown menu
   */
  const [openDropdown, setOpenDropdown] = useState(false);

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the theme handlers
   */
  const { activeTheme, changeTheme } = useTheme();

  /**
   * Handles toggling the language dropdown menu
   */
  const toggleLanguageMenuDropdown = () =>
    setOpenDropdown((prevState) => !prevState);

  /**
   * Handles changing the language
   * @param {String} lang
   */
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
    setOpenDropdown(false);
  };

  /**
   * Handles going to the scheduler
   */
  const routeToScheduler = () => {
    history.push("/modules/tyre-service/scheduler");
  };

  /**
   * Handles opening the modal
   */
  const openModal = () => setOpen(true);

  /**
   * Handles closing the modal
   */
  const closeModal = () => setOpen(false);

  /**
   * Handles logging the user out
   */
  const logoutUser = async () => {
    try {
      const loggedOut = await apiClient.post("/logout");
      if (loggedOut) {
        logout();
      }
    } catch (error) {
      handleAPIError(error);
    }
  };

  /**
   * Handles the api errors
   */
  const handleAPIError = (error) => {
    if (error) {
      dispatchMessage({
        severity: "error",
        message: error,
      });
      logout();
    }
  };

  const renderActiveLanguageMenuItem = () => {
    if (activeLanguage === "en_ro") {
      return (
        <div
          className={classes.activeLanguageItem}
          onClick={toggleLanguageMenuDropdown}
        >
          <img src={ro_flag} alt="Romanian" /> RO
        </div>
      );
    }

    if (activeLanguage === "en_hu") {
      return (
        <div
          className={classes.activeLanguageItem}
          onClick={toggleLanguageMenuDropdown}
        >
          <img src={hu_flag} alt="Hungarian" /> HU
        </div>
      );
    }

    if (activeLanguage === "en_en") {
      return (
        <div
          className={classes.activeLanguageItem}
          onClick={toggleLanguageMenuDropdown}
        >
          <img src={en_flag} alt="English" /> EN
        </div>
      );
    }
  };

  /**
   * Defines the account status component
   */
  const AccountStatus = (props) => {
    const { isSuspended, isDeleted, activeUntilText } = props;

    if (isSuspended) {
      return (
        <Typography variant="h3" className={classes.suspended}>
          <WarningIcon className={classes.statusIcon} />
          {t("suspended")}
        </Typography>
      );
    }

    if (isDeleted) {
      return (
        <Typography variant="h3" className={classes.deleted}>
          <ErrorIcon className={classes.statusIcon} />
          {t("deleted")}
        </Typography>
      );
    }

    return (
      <Typography variant="h3" className={classes.activeTo}>
        <CheckCircleIcon className={classes.activeToIcon} />
        {activeUntilText}
      </Typography>
    );
  };

  /**
   * Defines the company data component
   */
  const CompanyData = () => {
    if (organization && account) {
      /**
       * Defines and builds the active until text
       */
      let locale = null;

      if (activeLanguage === "en_ro") {
        locale = ro;
      }

      if (activeLanguage === "en_hu") {
        locale = hu;
      }

      const dateValue = new Date(account.active_to);
      const dateFormat = "d MMMM yyyy";
      const dateFormatOptions = { locale: locale };
      const activeToDate = formatDate(dateValue, dateFormat, dateFormatOptions);
      const activeUntilText = `${t("activeUntil")} ${activeToDate}`;

      return (
        <div className={classes.companyContainer}>
          <Typography variant="h3" className={classes.company}>
            {organization.name}
          </Typography>
          <AccountStatus
            isSuspended={isSuspended}
            isDeleted={isDeleted}
            activeUntilText={activeUntilText}
          />
        </div>
      );
    }
    return null;
  };

  /**
   * Defines the flex container component
   */
  const FlexContainer = ({ children }) => (
    <div className={classes.flexContainer}>{children}</div>
  );

  /**
   * Updates the organizations and the account status
   */
  useEffect(() => {
    if (user.account) {
      const isSuspended = user.account.status === "suspended";
      const isDeleted = user.account.status === "deleted";

      setIsSuspended(isSuspended);
      setIsDeleted(isDeleted);
    }

    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      });
      setOrganizations(orgs);
    }
  }, [user]);

  /**
   * Handles toggling the theme
   */
  const toggleTheme = () => {
    if (activeTheme === "default") return changeTheme("dark-theme");
    return changeTheme("default");
  };

  return (
    <AppBar position="fixed" className={classes.navigation}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <CompanyData />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <FlexContainer>
              <Typography
                variant="h3"
                className={classes.timesheet}
                onClick={toggleTheme}
              >
                {activeTheme === "default" ? (
                  <Brightness7RoundedIcon />
                ) : (
                  <Brightness3RoundedIcon />
                )}
              </Typography>
              <Typography
                variant="h3"
                className={classes.timesheet}
                style={{ position: "relative" }}
              >
                {renderActiveLanguageMenuItem()}
                {openDropdown && (
                  <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
                    <div className={classes.dropdownContainer}>
                      <div
                        onClick={() => changeLanguage("en_ro")}
                        className={classes.langMenuItem}
                      >
                        <img src={ro_flag} alt="Romanian" /> RO
                        {activeLanguage === "en_ro" && <CheckIcon />}
                      </div>
                      <div
                        onClick={() => changeLanguage("en_hu")}
                        className={classes.langMenuItem}
                      >
                        <img src={hu_flag} alt="Hungarian" /> HU
                        {activeLanguage === "en_hu" && <CheckIcon />}
                      </div>
                      <div
                        onClick={() => changeLanguage("en_en")}
                        className={classes.langMenuItem}
                      >
                        <img src={en_flag} alt="English" /> EN
                        {activeLanguage === "en_en" && <CheckIcon />}
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </Typography>
              <Typography variant="h3" className={classes.user}>
                <VerifiedUserIcon className={classes.userIcon} />
                {auth.username}
              </Typography>
              {user.type !== "worker" && (
                <Typography
                  variant="h3"
                  className={classes.timesheet}
                  onClick={routeToScheduler}
                >
                  <EventIcon className={classes.userIcon} />
                  {t("scheduler")}
                </Typography>
              )}

              <Typography
                variant="h3"
                className={classes.timesheet}
                onClick={openModal}
              >
                <WatchLaterIcon className={classes.userIcon} />
                {t("timesheet")}
              </Typography>
              <Typography
                variant="h3"
                className={classes.logout}
                onClick={logoutUser}
              >
                <ExitToAppIcon className={classes.logoutIcon} />
                {t("logout")}
              </Typography>
            </FlexContainer>
          </Grid>
          <TimesheetModal
            {...timesheet}
            organizations={organizations}
            open={open}
            closeModal={closeModal}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

ModulesHeader.propTypes = propTypes;
ModulesHeader.defaultProps = defaultProps;

export default ModulesHeader;
export {
  propTypes as ModulesHeaderPropTypes,
  defaultProps as ModulesHeaderDefaultProps,
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

/**
 * Styles Imports
 */
import { useStyles } from "./ViewOrgContactModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    document_prefix: PropTypes.string,
    document_suffix: PropTypes.string,
    time_sheet: PropTypes.string,
    description: PropTypes.string
  }),
  editData: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  defaultValues: {
    name: "",
    document_prefix: "",
    document_suffix: "",
    time_sheet: "",
    description: ""
  },
  editData: {},
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const ViewOrgContactModal = props => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    contactData
  } = props;

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={`${contactData.first_name} ${contactData.last_name}`}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} className={classes.wrapper}>
              <Card className={classes.blank}>
                <CardContent className={classes.cardContent}>
                  {contactData.phone_mobile && (
                    <Box className={classes.whiteTagFlex}>
                      <Typography variant="caption">
                        <PhoneAndroidIcon />
                        {contactData.phone_mobile}
                      </Typography>
                    </Box>
                  )}
                  {contactData.phone_fix && (
                    <Box className={classes.whiteTagFlex}>
                      <Typography variant="caption">
                        <PhoneIcon />
                        {contactData.phone_fix}
                      </Typography>
                    </Box>
                  )}
                  {contactData.email && (
                    <Box className={classes.whiteTagFlex}>
                      <Typography variant="caption">
                        <EmailIcon />
                        {contactData.email}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal} />
      </Modal>
    </Fragment>
  );
};

ViewOrgContactModal.propTypes = propTypes;
ViewOrgContactModal.defaultProps = defaultProps;

export default ViewOrgContactModal;
export {
  propTypes as ViewOrgContactModalPropTypes,
  defaultProps as ViewOrgContactModalDefaultProps
};

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./DeleteAppointmentModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteData: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  open: false,
  closeModal: () => {},
  deleteData: {},
};

/**
 * Displays the component
 */
const DeleteAppointmentModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    data,
    deleteData,
    setDeleteData,
    button,
    errorMessages,
    loadingText,
    setData,
    setAppointmentDeleted,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);

  /**
   * Gets the org name and id
   */
  const { id } = deleteData;

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles deleting the appointment
   */
  const deleteAppointment = async (id) => {
    try {
      const deletedAppointment = await apiClient.delete(`/appointments/${id}`);
      if (deletedAppointment) {
        setLoading(false);
        setAppointmentDeleted(true);
        setData(data.filter((item) => item.id !== id));
        dispatchMessage({
          icon: false,
          delay: 400,
          message: t("successMessage"),
        });
        handleCloseModal();
      }
    } catch (error) {
      setLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles the submit
   */
  const handleDelete = () => {
    setLoading(true);
    deleteAppointment(id);
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setDeleteData({});
    closeModal();
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="xs"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
        className={classes.dialog}
      >
        <ModalTitle {...modalTitle} onClick={handleCloseModal} />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Card className={classes.blank}>
                  <CardContent className={classes.cardContent}>
                    <Grid item xs={12} container justify="center">
                      <Typography className={classes.label}>
                        {t("deleteConfirmationText")}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            className={classes.submitBtn}
            onClick={handleDelete}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("deleteConfirm")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

DeleteAppointmentModal.propTypes = propTypes;
DeleteAppointmentModal.defaultProps = defaultProps;

export default DeleteAppointmentModal;
export {
  propTypes as DeleteAppointmentModalPropTypes,
  defaultProps as DeleteAppointmentModalDefaultProps,
};

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Material UI Imports
 */
import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";

/**
 * Defines the prop types
 */
const propTypes = {
    error: PropTypes.string,
    builtInErrorMsg: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    variant: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.any,
    margin: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
    id: PropTypes.string,
    type: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
    error: "",
    builtInErrorMsg: "Doar numere, -, +, ( ) și spațiu e permis.",
    label: "",
    name: "phoneInput",
    onChange: () => {},
    value: "",
    variant: "outlined",
    required: false,
    className: "",
    margin: "none",
    placeholder: "",
    fullWidth: false,
    autoFocus: false,
    id: "phoneInput",
    type: "text"
};

/**
 * Creates the mask
 */
const TextMask = props => {
    const { inputRef, ...otherProps } = props;

    /**
     * Gets the ref
     */
    const getRef = ref => inputRef(ref ? ref.inputElement : null);

    /**
     * Sets up the mask
     */
    const mask = Array(1)
        .fill(/[+-\d()\s]/)
        .concat(Array(15).fill(/[\d-()\s]/));

    /**
     * @see https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
     */
    return (
        <MaskedInput
            {...otherProps}
            ref={getRef}
            mask={mask}
            guide={false}
            keepCharPositions={false}
            placeholderChar={"\u2000"}
        />
    );
};

/**
 * Defines the prop types for the TextMask
 */
TextMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

/**
 * Displays the component
 */
const InputPhone = props => {
    const {
        label,
        value,
        variant,
        inputClasses,
        onChange,
        name,
        error,
        required,
        className,
        placeholder,
        margin,
        fullWidth,
        autoFocus,
        id,
        builtInErrorMsg,
        type
    } = props;

    /**
     * Creates a ref used for autofocus
     */
    const inputRef = useRef();
    useEffect(() => {
        if (autoFocus) {
            inputRef.current.focus();
        }
    }, [autoFocus, inputRef]);

    return (
        <TextField
            id={id}
            inputRef={inputRef}
            className={className}
            name={name}
            label={label}
            placeholder={placeholder}
            variant={variant}
            margin={margin}
            error={error ? true : false}
            helperText={error ? error : builtInErrorMsg}
            type={type}
            InputProps={{ ...inputClasses, inputComponent: TextMask }}
            inputProps={{ autoFocus: autoFocus, maxLength: 15 }}
            value={value}
            fullWidth={fullWidth}
            onChange={onChange}
            required={required}
        />
    );
};

InputPhone.propTypes = propTypes;
InputPhone.defaultProps = defaultProps;

export default InputPhone;
export {
    propTypes as InputPhonePropTypes,
    defaultProps as InputPhoneDefaultProps
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreHotelsSearchForm.styles";

/**
 * Defaults Imports
 */
import {
  defaults,
  clientNameModel,
} from "../TyreServiceTyreHotels/TyreServiceTyreHotels.defaults";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  defaultValues: PropTypes.shape({
    quick_text: PropTypes.string,
    tyre_width: PropTypes.string,
    tyre_height: PropTypes.string,
    tyre_rim: PropTypes.string,
    tyre_name: PropTypes.string,
    organization_id: PropTypes.string,
    organization_client_id: PropTypes.string,
    car_number: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
  }),
  orderByList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  defaultValues: {
    quick_text: "",
    tyre_width: "",
    tyre_height: "",
    tyre_rim: "",
    tyre_name: "",
    organization_id: "",
    organization_client_id: "",
    car_number: "",
    orderBy: "created_at",
    orderDir: "desc",
  },
  orderByList: defaults.orderBy,
  pageSizeOptions: defaults.pageSizeOptions,
  path: "/tyre-hotels",
};

/**
 * Displays the component
 */
const TyreHotelsSearchForm = (props) => {
  const {
    form,
    input,
    button,
    defaultValues,
    defaultSearch,
    setInputs: updateInputs,
    setModels: updateModels,
    setTotal,
    pageSize,
    pageCount,
    setPageSize,
    setPageCount,
    setBackdropLoading,
    loadingText,
    clients,
    resetFilters,
    setResetFilters,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes organizations list
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Gets the global user
   */
  const { setUser, user } = useUser();

  const getClientID = (name) => {
    const found = clients.find((client) => client.name === name);
    return found ? found.id : "";
  };

  /**
   * Handles searching tyre hotels
   */
  const searchTyreHotels = async (data) => {
    try {
      const tyreHotels = await apiClient.post("/tyre-hotels/search", data);
      if (tyreHotels) {
        const { data } = tyreHotels;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, tyre_hotels: items };
        });

        /**
         * Updates the search models and the inputs
         */
        updateModels(models);

        /**
         * Handles updating the inputs
         */
        const handlePrevState = (prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        };

        setInputs((prevState) => handlePrevState(prevState));
        updateInputs((prevState) => handlePrevState(prevState));

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];

    /**
     * Gets the inputs
     */
    const {
      quick_text,
      tyre_width,
      tyre_height,
      tyre_rim,
      tyre_name,
      organization_id,
      organization_client_id,
      car_number,
    } = inputs;

    /**
     * Gets the default models
     */
    const {
      quickTextModel,
      tyreWidthModel,
      tyreHeightModel,
      tyreRimModel,
      tyreNameModel,
      organizationIdModel,
      organizationClientIdModel,
      carNumberModel,
    } = defaults.models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      const modelItem = { ...model };
      modelItem["selected"] = input;
      searchModels.push(modelItem);
    };

    if (quick_text) {
      if (modelExists("quick_text")) {
        removeModel("quick_text");
        addModel(quick_text, quickTextModel);
      } else {
        addModel(quick_text, quickTextModel);
      }
    }

    if (tyre_width) {
      if (modelExists("tyre_width")) {
        removeModel("tyre_width");
        addModel(tyre_width, tyreWidthModel);
      } else {
        addModel(tyre_width, tyreWidthModel);
      }
    }

    if (tyre_height) {
      if (modelExists("tyre_height")) {
        removeModel("tyre_height");
        addModel(tyre_height, tyreHeightModel);
      } else {
        addModel(tyre_height, tyreHeightModel);
      }
    }

    if (tyre_rim) {
      if (modelExists("tyre_rim")) {
        removeModel("tyre_rim");
        addModel(tyre_rim, tyreRimModel);
      } else {
        addModel(tyre_rim, tyreRimModel);
      }
    }

    if (tyre_name) {
      if (modelExists("tyre_name")) {
        removeModel("tyre_name");
        addModel(tyre_name, tyreNameModel);
      } else {
        addModel(tyre_name, tyreNameModel);
      }
    }

    if (car_number) {
      if (modelExists("car_number")) {
        removeModel("car_number");
        addModel(car_number, carNumberModel);
      } else {
        addModel(car_number, carNumberModel);
      }
    }

    if (organization_id) {
      let org = organization_id !== "all" ? organization_id : null;

      if (modelExists("organization_id")) {
        removeModel("organization_id");
        addModel(org, organizationIdModel);
      } else {
        addModel(org, organizationIdModel);
      }
    }

    if (organization_client_id) {
      const id = getClientID(organization_client_id);

      if (id) {
        if (modelExists("organization_client_id")) {
          removeModel("organization_client_id");
          addModel(id, organizationClientIdModel);
        } else {
          addModel(id, organizationClientIdModel);
        }
      } else {
        if (modelExists("client_name")) {
          removeModel("client_name");
          addModel(organization_client_id, clientNameModel);
        } else {
          addModel(organization_client_id, clientNameModel);
        }
      }
    }
    return searchModels.length < 1 ? defaultSearch.models : searchModels;
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: pageCount,
    };

    searchTyreHotels(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    quick_text,
    tyre_width,
    tyre_height,
    tyre_rim,
    tyre_name,
    orderBy,
    orderDir,
    organization_id,
    organization_client_id,
    car_number,
  } = inputs;

  /**
   * Handles resetting the filters
   */
  const handleFiltersReset = () => {
    resetInputs();
    setBackdropLoading(true);
    updateModels(defaults.models);
    setPageCount(1);
    handleDefaultSearch();
  };

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.organizations) {
      const organizations = [
        {
          name: t("all"),
          id: "all",
        },
        ...user.organizations,
      ].map((org) => {
        return {
          label: org.name,
          value: org.id,
        };
      });

      setOrganizations(organizations);
    }

    if (user.organization && user.organization.id) {
      if (!inputs.organization_id) {
        setInputs((prevState) => ({
          ...prevState,
          organization_id: user.organization.id,
        }));
      }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false);
      handleFiltersReset();
    }
    // eslint-disable-next-line
  }, [resetFilters]);

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const data = {
      ...defaultSearch,
      page_size: pageSize || defaultSearch.page_size,
      page_count: 1,
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchTyreHotels(getDefaultSearch());
  };

  /**
   * Handles changing the product
   */
  const handleClientChange = (event, newValue) => {
    setInputs((prevState) => {
      return { ...prevState, organization_client_id: newValue };
    });
  };

  /**
   * Makes the initial search
   */
  useEffect(() => {
    updateModels(defaultSearch.models);

    // eslint-disable-next-line
  }, []);

  return (
    <Form {...form} id="search-user" onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "quick_text",
                  value: quick_text,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("quick_textLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "car_number",
                  value: car_number,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("car_numberLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "tyre_width",
                  value: tyre_width,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("tyre_widthLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "tyre_height",
                  value: tyre_height,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("tyre_heightLabel"),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={2} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "tyre_rim",
                  value: tyre_rim,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("tyre_rimLabel"),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={2} lg={3} xl={3}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "tyre_name",
                  value: tyre_name,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("tyre_nameLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "organization_id",
                  value: organization_id,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("organization_idLabel"),
                  options: organizations,
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3}>
              <Input
                {...input}
                type="autocomplete"
                className={classes.field}
                inputAutocomplete={{
                  id: "organization_client_id",
                  name: "organization_client_id",
                  inputValue: organization_client_id,
                  onChange: handleClientChange,
                  variant: "outlined",
                  freeSolo: true,
                  autoComplete: false,
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  label: t("clientsLabel"),
                  options: clients,
                  getOptionSelected: (option, value) => {
                    return option.id === value.id;
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderBy",
                  value: orderBy,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderByLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("tyre_name"), value: "tyre_name" },
                    { label: t("created_at"), value: "created_at" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderDir",
                  value: orderDir,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderDirLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("asc"), value: "asc" },
                    { label: t("desc"), value: "desc" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Grid container spacing={1} wrap={"nowrap"}>
            <Grid item xs={12} container justify="center" direction="column">
              <div className={classes.btnContainer}>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  title={t("undo")}
                  onClick={handleFiltersReset}
                  className={classes.undoBtn}
                >
                  <UndoIcon />
                </Button>
                <Button
                  {...button}
                  type="submit"
                  variant="filled"
                  className={classes.submitBtn}
                >
                  <LoadingText
                    {...loadingText}
                    size={22}
                    loading={loading}
                    text={t("submitSearch")}
                    icon={<SearchIcon />}
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

TyreHotelsSearchForm.propTypes = propTypes;
TyreHotelsSearchForm.defaultProps = defaultProps;

export default TyreHotelsSearchForm;
export {
  propTypes as TyreHotelsSearchFormPropTypes,
  defaultProps as TyreHotelsSearchFormDefaultProps,
};

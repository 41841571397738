/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontFamily: theme.font.family,
    paddingLeft: "0.5rem",
    fontWeight: 800,
    color: "#4e4e4e",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.95rem",
    },
    display: "flex",
    alignItems: "center",
  },
  dateText: {
    fontSize: "1rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: "#4e4e4e",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: "0.5rem",
      color: theme.palette.primary.main,
    },
  },
  modalContent: {
    maxHeight: 660,
    minHeight: 660,
  },
  modulesContainer: {
    margin: "0.5rem 0",
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  timesheetActions: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    marginLeft: "1rem",
    borderBottom: "1px solid #e4e4e4",
    paddingBottom: "0.25rem",
  },
  tabBtn: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 3,
    fontWeight: 800,
    height: 30,
    background: "#f3f3f3",
    color: "#5f5f5f",
    boxShadow: "none",
    margin: "0.5rem",
    marginLeft: 0,
    border: "1px solid #cacaca",
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "none",
    },
  },
  activeTabBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    span: {
        paddingLeft: "0.25rem"
    }
}));

export { useStyles };

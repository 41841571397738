/**
 * Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1.5)
  },
  label: {
    marginBottom: theme.spacing(1),
    opacity: props => (props.disabled ? 0.4 : 1)
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    margin: 0,
    marginTop: 3,
    textAlign: "left",
    lineHeight: 1.66,
    letterSpacing: "0.03333em"
  },
  hidden: {
    visibility: "hidden"
  },
  helper: {
    color: "#383838",
    fontWeight: 800,
    fontSize: "0.75rem",
    margin: 0,
    marginTop: 3,
    textAlign: "left",
    lineHeight: 1.66,
    letterSpacing: "0.03333em"
  },
  select: {
    paddingRight: 0
  },
  selectNative: {
    "&:before": {
      borderColor: "#9a9a9a"
    },
    paddingRight: 30,
    marginTop: "3px !important"
  }
}));

export { useStyles };

import React, { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import WorkOrderHeader, {
  WorkOrderHeaderDefaultProps,
  WorkOrderHeaderPropTypes,
} from "../WorkOrderHeader";

/**
 *  Material UI Imports
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Styles Imports
 */
import { useStyles } from "./PrintTimesheetReport.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  header: PropTypes.shape(WorkOrderHeaderPropTypes),
  headCells: PropTypes.array,
  dataTable: PropTypes.array,
  getWorkerName: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  header: WorkOrderHeaderDefaultProps,
  headCells: [],
  dataTable: [],
  getWorkerName: () => {},
};

/**
 * Displays the component
 */
const PrintTimesheetReport = (props) => {
  const {
    header,
    headCells,
    resultsTable,
    getWorkerName,
    setReadyToPrint,
    printLoading,
    title,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the Table data component
   */
  const TableData = () => {
    return resultsTable.map((row, idx) => {
      return (
        <TableRow key={row.id}>
          <TableCell>{idx + 1}</TableCell>
          <TableCell>{getWorkerName(row.worker_id)}</TableCell>
          <TableCell>{parseFloat(row.worked_hours).toFixed(1)}</TableCell>
        </TableRow>
      );
    });
  };

  useEffect(() => {
    if (resultsTable.length > 0 && printLoading) {
      setTimeout(() => {
        setReadyToPrint(true);
      }, [300]);
    }
    // eslint-disable-next-line
  }, [resultsTable, printLoading]);

  return (
    <div className={classes.container}>
      <WorkOrderHeader {...header} />
      {title}
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                const { label } = headCell;
                return <TableCell key={label}>{label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableData />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

PrintTimesheetReport.propTypes = propTypes;
PrintTimesheetReport.defaultProps = defaultProps;

export default PrintTimesheetReport;
export {
  propTypes as PrintTimesheetReportPropTypes,
  defaultProps as PrintTimesheetReportDefaultProps,
};

const quickTextModel = {
  label: "Quick Text",
  field: "quick_text",
  type: "like",
  order: 1,
  options: [],
  selected: null,
};

const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

const nameModel = {
  label: "Name",
  field: "name",
  type: "like",
  order: 3,
  options: [],
  selected: null,
};

const fidelityModel = {
  label: "Fidelity",
  field: "fidelity",
  type: "boolean",
  order: 3,
  options: [],
  selected: null,
};

const isCompanyModel = {
  label: "Is company",
  field: "is_company",
  type: "boolean",
  order: 4,
  options: [],
  selected: true,
};

const companyVatModel = {
  label: "Company VAT",
  field: "company.vat_number",
  type: "equal",
  order: 5,
  options: [],
  selected: null,
};

const companyRegModel = {
  label: "Company REG",
  field: "company.reg_number",
  type: "equal",
  order: 6,
  options: [],
  selected: null,
};

const companyEmailModel = {
  label: "Company Email",
  field: "company.email",
  type: "equal",
  order: 7,
  options: [],
  selected: null,
};

const defaults = {
  models: {
    quickTextModel,
    fidelityModel,
    organizationIdModel,
    nameModel,
    isCompanyModel,
    companyVatModel,
    companyRegModel,
    companyEmailModel,
  },
  searchParams: {
    models: [
      quickTextModel,
      fidelityModel,
      organizationIdModel,
      nameModel,
      isCompanyModel,
      companyVatModel,
      companyRegModel,
      companyEmailModel,
    ],
    order_by: "name",
    order_dir: "asc",
    page_size: Number(localStorage.getItem("clientsViewPageSize")) || 15,
  },
  orderBy: ["name"],
  pageSizeOptions: [10, 15, 30, 50, 100],
};

export { defaults };

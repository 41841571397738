import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import Tooltip, { TooltipDefaultProps, TooltipPropTypes } from "../Tooltip";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./CompanyContactDataBox.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.shape(TooltipPropTypes),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

/**
 * Defines the default props
 */
const defaultProps = {
  icon: null,
  title: "",
  className: "",
  tooltip: TooltipDefaultProps,
  value: ""
};

/**
 * Displays the component
 */
const CompanyContactDataBox = props => {
  const { icon, value, title, className, tooltip } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the container classes
   */
  const valueClasses = clsx(classes.value, {
    [className]: !!className
  });

  /**
   * Handles rendering the icon
   */
  const renderIcon = () => (icon ? icon : null);

  /**
   * Handles rendering the org modal
   */
  const renderComponent = () => {
    return value ? (
      <div className={classes.container}>
        <Typography variant="caption" className={valueClasses}>
          <Tooltip {...tooltip} title={title} icon={renderIcon()} />
          {value}
        </Typography>
      </div>
    ) : null;
  };

  return renderComponent();
};

CompanyContactDataBox.propTypes = propTypes;
CompanyContactDataBox.defaultProps = defaultProps;

export default CompanyContactDataBox;
export {
  propTypes as CompanyContactDataBoxPropTypes,
  defaultProps as CompanyContactDataBoxDefaultProps
};

const en_ro = {
  "30 Days": "30 de zile",
  "<": "mai mic decat",
  "<=": "mai mic sau egal decat",
  "<=_label": "mai mică sau egal decat",
  "<_label": "mai mică decat",
  "=": "egal cu",
  "=_label": "egală cu",
  ">": "mai mare decat",
  ">=": "mai mare sau egal decat",
  ">=_label": "mai mare sau egal decat",
  ">_label": "mai mare decat",
  "Account settings": "Setările contului",
  AccountSettingsAppointmentGroups: "AccountSettingsAppointmentGroups",
  AccountSettingsCarTypes: "AccountSettingsCarTypes",
  AccountSettingsMain: "AccountSettingsMain",
  AccountSettingsOrders: "AccountSettingsOrders",
  AccountSettingsOrganizations: "AccountSettingsOrganizations",
  AccountSettingsPage: "AccountSettingsPage",
  AccountSettingsPaymentMethods: "AccountSettingsPaymentMethods",
  AccountSettingsSidebar: "AccountSettingsSidebar",
  AccountSettingsUpgrade: "AccountSettingsUpgrade",
  AccountSettingsUsers: "AccountSettingsUsers",
  AccountSettingsWorkers: "AccountSettingsWorkers",
  AddAppointmentGroupModal: "AddAppointmentGroupModal",
  AddAppointmentModal: "AddAppointmentModal",
  AddCarTypeModal: "AddCarTypeModal",
  AddClientModal: "AddClientModal",
  AddContractModal: "AddContractModal",
  AddLoyaltyCardModal: "AddLoyaltyCardModal",
  AddOrgContactModal: "AddOrgContactModal",
  AddOrganizationModal: "AddOrganizationModal",
  AddPaymentMethodModal: "AddPaymentMethodModal",
  AddProductConditionModal: "AddProductConditionModal",
  AddProductModal: "AddProductModal",
  AddTyreHotelModal: "AddTyreHotelModal",
  AddUserModal: "AddUserModal",
  AddWorkOrderProductDraftModal: "AddWorkOrderProductDraftModal",
  AddWorkOrderProductModal: "AddWorkOrderProductModal",
  AddWorkOrderServiceDraftModal: "AddWorkOrderServiceDraftModal",
  AddWorkOrderServiceModal: "AddWorkOrderServiceModal",
  AddWorkOrderWorkerModal: "AddWorkOrderWorkerModal",
  AddWorkerModal: "AddWorkerModal",
  AppointmentGroupsTable: "AppointmentGroupsTable",
  AppointmentsScheduler: "AppointmentsScheduler",
  AttendanceTable: "AttendanceTable",
  AuthPage: "AuthPage",
  BarcodeReader: "BarcodeReader",
  CARD: "CARD",
  CASH: "CASH",
  "Car Service": "Service auto",
  "Car Wash": "Spălătorie auto",
  CarServiceMain: "CarServiceMain",
  CarServicePage: "CarServicePage",
  CarServiceSidebar: "CarServiceSidebar",
  CarTypesTable: "CarTypesTable",
  CarWashMain: "CarWashMain",
  CarWashPage: "CarWashPage",
  CarWashSidebar: "CarWashSidebar",
  ClientsSearchForm: "ClientsSearchForm",
  ClientsTable: "ClientsTable",
  CompanyContactDataBox: "CompanyContactDataBox",
  CompanyDataForm: "CompanyDataForm",
  ConfirmUpgradeModal: "ConfirmUpgradeModal",
  ContactDataForm: "ContactDataForm",
  ContactItem: "ContactItem",
  ContractCard: "ContractCard",
  ContractsSearchForm: "ContractsSearchForm",
  ContractsTable: "ContractsTable",
  CreateButton: "CreateButton",
  CreateWorkOrderModal: "CreateWorkOrderModal",
  CreateWorkOrderStage1: "CreateWorkOrderStage1",
  CreateWorkOrderStage2: "CreateWorkOrderStage2",
  CreateWorkOrderStage3: "CreateWorkOrderStage3",
  CreateWorkOrderStage4: "CreateWorkOrderStage4",
  DataTable: "DataTable",
  DeleteAccountModal: "DeleteAccountModal",
  DeleteAppointmentModal: "DeleteAppointmentModal",
  DeleteButton: "DeleteButton",
  DeleteClientModal: "DeleteClientModal",
  DeleteContractModal: "DeleteContractModal",
  DeleteLoyaltyCardModal: "DeleteLoyaltyCardModal",
  DeleteModal: "DeleteModal",
  DeleteProductModal: "DeleteProductModal",
  DeleteTyreHotelModal: "DeleteTyreHotelModal",
  DeleteUserModal: "DeleteUserModal",
  DeleteWorkOrderModal: "DeleteWorkOrderModal",
  DeleteWorkOrderTimerModal: "DeleteWorkOrderTimerModal",
  DeleteWorkerModal: "DeleteWorkerModal",
  DetailedAttendanceModal: "DetailedAttendanceModal",
  DocumentModal: "DocumentModal",
  DocumentPage: "DocumentPage",
  DocumentViewer: "DocumentViewer",
  DynamicSearchForm: "DynamicSearchForm",
  DynamicTable: "DynamicTable",
  EditAppointmentGroupModal: "EditAppointmentGroupModal",
  EditAppointmentModal: "EditAppointmentModal",
  EditButton: "EditButton",
  EditCarTypeModal: "EditCarTypeModal",
  EditClientModal: "EditClientModal",
  EditContractModal: "EditContractModal",
  EditLoyaltyCardModal: "EditLoyaltyCardModal",
  EditOrgAddressModal: "EditOrgAddressModal",
  EditOrgContactModal: "EditOrgContactModal",
  EditOrganizationModal: "EditOrganizationModal",
  EditPaymentMethodModal: "EditPaymentMethodModal",
  EditProductConditionModal: "EditProductConditionModal",
  EditProductModal: "EditProductModal",
  EditTyreHotelModal: "EditTyreHotelModal",
  EditUserModal: "EditUserModal",
  EditWorkOrderModal: "EditWorkOrderModal",
  EditWorkOrderProductDraftModal: "EditWorkOrderProductDraftModal",
  EditWorkOrderProductModal: "EditWorkOrderProductModal",
  EditWorkOrderServiceDraftModal: "EditWorkOrderServiceDraftModal",
  EditWorkOrderServiceModal: "EditWorkOrderServiceModal",
  EditWorkOrderSettingsModal: "EditWorkOrderSettingsModal",
  EditWorkOrderStage1: "EditWorkOrderStage1",
  EditWorkOrderStage2: "EditWorkOrderStage2",
  EditWorkOrderStage3: "EditWorkOrderStage3",
  EditWorkerModal: "EditWorkerModal",
  ErrorMessages: "ErrorMessages",
  "FACT TERMENI": "FACT TERMENI",
  Form: "Form",
  GDPR: "GDPR",
  GridViewMode: "GridViewMode",
  GuestSidebar: "GuestSidebar",
  Importer: "Importer",
  InfoButton: "InfoButton",
  InputAutocomplete: "InputAutocomplete",
  InputDate: "InputDate",
  InputDateRange: "InputDateRange",
  InputDateTime: "InputDateTime",
  InputNumeric: "InputNumeric",
  InputPassword: "InputPassword",
  InputPostCode: "InputPassword",
  InputSearch: "InputSearch",
  ListBox: "ListBox",
  LoadingBackdrop: "LoadingBackdrop",
  LoadingText: "LoadingText",
  LoginForm: "LoginForm",
  LoginPage: "Pagina de login",
  LoyaltyCard: "LoyaltyCard",
  LoyaltyCards: "LoyaltyCards",
  LoyaltyCardsSearchForm: "LoyaltyCardsSearchForm",
  MessagePopup: "MessagePopup",
  Modal: "Modal",
  ModalActions: "ModalActions",
  ModalContent: "ModalContent",
  ModalTitle: "ModalTitle",
  ModuleCard: "ModuleCard",
  ModuleCardMini: "ModuleCardMini",
  ModulePackageCard: "ModulePackageCard",
  ModulePackages: "ModulePackages",
  ModuleSelectionPage: "ModuleSelectionPage",
  Modules: "Modules",
  ModulesFooter: "ModulesFooter",
  ModulesHeader: "ModulesHeader",
  ModulesModal: "ModulesModal",
  Nou: "new",
  OrderCard: "OrderCard",
  OrderItem: "OrderItem",
  Orders: "Orders",
  OrganizationItem: "OrganizationItem",
  OrganizationsTabs: "OrganizationsTabs",
  PDFNavigation: "PDFNavigation",
  PROTOCOL: "PROTOCOL",
  PageNotFound: "PageNotFound",
  PaymentMethodCard: "PaymentMethodCard",
  PaymentMethods: "PaymentMethods",
  PaymentMethodsTable: "PaymentMethodsTable",
  "Pentru fiecare punct de lucru consecutiv se adaugă o reducere adițională de 33%":
    " ",
  Popper: "Popper",
  PrintTimesheetReport: "PrintTimesheetReport",
  PrintTyreHotels: "PrintTyreHotels",
  PrintTyreHotelsClient: "PrintTyreHotelsClient",
  ProductCard: "ProductCard",
  ProductsSearchForm: "ProductsSearchForm",
  ProductsTable: "ProductsTable",
  PromotionCodeForm: "PromotionCodeForm",
  Providers: "Providers",
  REG: "Nr. de ordine în registrul comerțului",
  ReCaptcha: "ReCaptcha",
  ReactVrSelect: "ReactVrSelect",
  RegisterPage: "RegisterPage",
  ReportsAdvanced: "ReportsAdvanced",
  ReportsAdvancedSearch: "ReportsAdvancedSearch",
  ReportsAdvancedTable: "ReportsAdvancedTable",
  ReportsCompanies: "ReportsCompanies",
  ReportsCompaniesObservationsTable: "ReportsCompaniesObservationsTable",
  ReportsCompaniesSearch: "ReportsCompaniesSearch",
  ReportsCompaniesTable: "ReportsCompaniesTable",
  ReportsCompaniesTableTotalization: "ReportsCompaniesTableTotalization",
  ReportsDaily: "ReportsDaily",
  ReportsDailySearch: "ReportsDailySearch",
  ReportsDailyTable: "ReportsDailyTable",
  ReportsMonthly: "ReportsMonthly",
  ReportsMonthlySearch: "ReportsMonthlySearch",
  ReportsMonthlyTable: "ReportsMonthlyTable",
  ReportsPage: "ReportsPage",
  ReportsSidebar: "ReportsSidebar",
  ReportsTyreWaste: "ReportsTyreWaste",
  ReportsTyreWasteSearch: "ReportsTyreWasteSearch",
  ReportsTyreWasteTable: "ReportsTyreWasteTable",
  ReportsWorkers: "ReportsWorkers",
  ReportsWorkersSearch: "ReportsWorkersSearch",
  ReportsWorkersTable: "ReportsWorkersTable",
  Routes: "Routes",
  SchedulerStatusChanger: "SchedulerStatusChanger",
  ScrollToTop: "ScrollToTop",
  SelectWorkOrderPaymentType: "SelectWorkOrderPaymentType",
  Sidebar: "Sidebar",
  SmallModuleCards: "SmallModuleCards",
  Span: "Span",
  SubmoduleContainer: "SubmoduleContainer",
  SubmoduleTitle: "SubmoduleTitle",
  SubmoduleWrapper: "SubmoduleWrapper",
  SuperAdminMain: "SuperAdminMain",
  SuperAdminPage: "SuperAdminPage",
  SuperAdminSidebar: "SuperAdminSidebar",
  TabPanel: "TabPanel",
  TableActions: "TableActions",
  TableCellHeader: "TableCellHeader",
  TableCellValue: "TableCellValue",
  TableHeader: "TableHeader",
  TablePagination: "TablePagination",
  TablePaginationActions: "TablePaginationActions",
  TablePopover: "TablePopover",
  TableResultsNotFound: "TableResultsNotFound",
  TableRows: "TableRows",
  TableSearchInput: "TableSearchInput",
  Terminat: "done",
  TextCount: "TextCount",
  TextMask: "TextMask",
  "This contains all modules (Tyre Service, Car Wash, Car Service) with all functionalities":
    " ",
  "This is the car service account with all functionalities": " ",
  "This is the car wash account with all functionalities": " ",
  "This is the tyre service account with all functionalities": " ",
  TimesheetAdminView: "TimesheetAdminView",
  TimesheetLogEntry: "TimesheetLogEntry",
  TimesheetModal: "TimesheetModal",
  TimesheetReport: "TimesheetReport",
  Tooltip: "Tooltip",
  "Tyre Service": "Serviciu de anvelope",
  TyreHotelCard: "TyreHotelCard",
  TyreHotelsChoiceModal: "TyreHotelsChoiceModal",
  TyreHotelsSearchForm: "TyreHotelsSearchForm",
  TyreHotelsTable: "TyreHotelsTable",
  TyreServiceControlPanel: "TyreServiceControlPanel",
  TyreServiceCreateWorkOrders: "TyreServiceCreateWorkOrders",
  TyreServiceMain: "TyreServiceMain",
  TyreServicePage: "TyreServicePage",
  TyreServiceScheduler: "TyreServiceScheduler",
  TyreServiceSidebar: "TyreServiceSidebar",
  TyreServiceTyreHotels: "TyreServiceTyreHotels",
  TyreServiceWorkOrderTable: "TyreServiceWorkOrderTable",
  TyreServiceWorkOrdersTable: "TyreServiceWorkOrdersTable",
  UpgradeExplanation: "UpgradeExplanation",
  UpgradePackageModal: "UpgradePackageModal",
  "User with this username does not exist!": " ",
  UserCard: "UserCard",
  UserDataForm: "UserDataForm",
  UserOrganizations: "UserOrganizations",
  Users: "Users",
  UsersTable: "UsersTable",
  VAT: "C.I.F / C.U.I",
  ViewMode: "ViewMode",
  ViewOrgContactModal: "ViewOrgContactModal",
  ViewPriceConditionsModal: "ViewPriceConditionsModal",
  ViewWorkOrderModal: "ViewWorkOrderModal",
  Widget: "Widget",
  Widgets: "Widgets",
  WorkOrderCard: "WorkOrderCard",
  WorkOrderDisclaimer: "WorkOrderDisclaimer",
  WorkOrderHeader: "WorkOrderHeader",
  WorkOrderSearchForm: "WorkOrderSearchForm",
  WorkOrderServices: "WorkOrderServices",
  WorkOrderSignature: "WorkOrderSignature",
  WorkOrderTable: "WorkOrderTable",
  WorkOrderTimerCard: "WorkOrderTimerCard",
  WorkOrderView: "WorkOrderView",
  WorkOrderWorkers: "WorkOrderWorkers",
  WorkOrdersTable: "WorkOrdersTable",
  WorkerAttendanceCard: "WorkerAttendanceCard",
  WorkerAttendanceModal: "WorkerAttendanceModal",
  WorkerCard: "WorkerCard",
  WorkersTable: "WorkersTable",
  "Workstation settings": "Setări stație de lucru",
  WorkstationSettingsContracts: "WorkstationSettingsContracts",
  WorkstationSettingsLoyaltyCards: "WorkstationSettingsLoyaltyCards",
  WorkstationSettingsPage: "WorkstationSettingsPage",
  WorkstationSettingsProducts: "WorkstationSettingsProducts",
  WorkstationSettingsSidebar: "WorkstationSettingsSidebar",
  WorkstationSettingsTyreHotels: "WorkstationSettingsTyreHotels",
  WorkstationSettingsWorkOrders: "WorkstationSettingsWorkOrders",
  "Wrong password!": "Parola este greșită, încearcă din nou.",
  aboutGDPR: "Despre GDPR",
  acceptTermsAndConditions: "Accept termenii și condițiile de utilizare",
  accountChanged: "Cont modificat cu success!",
  accountCreated: "Cont creat cu success!",
  accountData: "Date de cont",
  accountDeleted: "Contul a fost șters cu success!",
  accountDeletedPermanently: "Acest cont este dezactivat permanent",
  accountDeletedSuccessfuly: "Contul a fost șters cu success!",
  accountRecovered: "Contul a fost recuperat cu success!",
  accountSettings: "Account Settings",
  accountStatus: "Status cont",
  accountType: "Tip Cont",
  actions: "Operațiuni",
  activate: "Activează",
  activated: "Serviciu Activat",
  active: "Activat",
  activeService: "Serviciu activ",
  activeUntil: "Activ până în",
  add: "Adaugă",
  addContact: "Adaugă contact",
  addNew: "Adaugă rezultatul",
  addNewCard: "Adaugă un card nou",
  addNewClient: "Adaugă Client",
  addNewContract: "Adaugă Contract",
  addNewHotel: "Adaugă hotel",
  addNewOrganization: "Adaugă Organizație",
  addNewProduct: "Adaugă Produs",
  addNewUser: "Adaugă Utilizator",
  addNewWorker: "Adaugă Muncitor",
  addProduct: "Adaugă produs",
  addService: "Adaugă serviciu",
  addWorkerBtn: "Adaugă",
  address: "Adresă",
  admin: "Administrator",
  adrLabel: "Adresa",
  advSearchActive: "Căutare avansată (activat)",
  advSearchInactive: "Căutare avansată (dezactivat)",
  advanced: "Raport detaliat",
  advancedReports: "Raport detaliat",
  all: "Toate",
  allOrgs: "Toate",
  appointmentDeleted: "Programarea a fost ștersă cu success!",
  appointmentGroups: "Grupuri programări",
  appointment_group_id: "Selectează grup",
  areYouSureYouWantToDelete: "Sunteți sigur că doriți să ștergeți ",
  areYouSureYouWantToDeleteTheAppointment:
    "Sunteți sigur că doriți să ștergeți programarea?",
  areYouSureYouWantToDeleteTheCard:
    "Sunteți sigur că doriți să ștergeți cardul cu nr. ",
  areYouSureYouWantToDeleteTheClient:
    "Sunteți sigur că doriți să ștergeți clientul ",
  areYouSureYouWantToDeleteTheContract:
    "Sunteți sigur că doriți să ștergeți contractul ",
  areYouSureYouWantToDeleteTheProduct:
    "Sunteți sigur că doriți să ștergeți produsul ",
  areYouSureYouWantToDeleteTheWorkOrder:
    "Sunteți sigur că doriți să ștergeți comanda ",
  areYouSureYouWantToDeleteTheWorker:
    "Sunteți sigur că doriți să ștergeți muncitorul ",
  areYouSureYouWantToDeleteTheAccount:
    "Sunteți sigur că doriți să ștergeți contul asociate cu ",
  asc: "Ascendent",
  attendanceReport: "Raport prezență",
  backToMenu: "Înapoi la meniu",
  baseHelper: "Doar numere / litere și spații goale sunt permise.",
  baseReductionExplanation: " ",
  billingInfo: "Date facturare",
  birth_date: "Data nașterii",
  birth_dateLabel: "Data nașterii",
  boltTorque: "Cuplu de strângere",
  bolt_torqueLabel: "Cuplu de strângere",
  cancelLabel: "Anulează",
  car: "Auto",
  carDescription: "Descriere",
  carKm: "Km auto",
  carMake: "Marca auto",
  carModel: "Model",
  carPlate: "Nr. înmatr.",
  carService: "Service Auto",
  carType: "Tip de mașină",
  carTypeCreated: "Tip de mașină creat cu success!",
  carTypes: "Tipuri de mașini",
  carWash: "Spălătorie auto",
  car_description: "DOT / Mențiuni",
  car_km: "Km la bord",
  car_kmLabel: "Km la bord",
  car_modelLabel: "Model Mașină",
  car_number: "Nr. Înmatriculare",
  car_numberLabel: "Nr. înmatriculare",
  car_plate_number: "Nr. înmatriculare",
  car_plate_numberLabel: "Nr. înmatriculare",
  car_type: "Tip",
  car_typeLabel: "Tip de masină",
  car_type_id: "Tip de mașină",
  car_types: "Tipuri de mașini",
  cardAdded: "Card adăugat cu success!",
  cardDeleted: "Cardul a fost șters cu success!",
  cardFound: "Card de loialitate identificat",
  cardModified: "Card modificat cu success!",
  cardNotFound: "Ne pare rău. Nu am detectat un card cu codul",
  card_numberLabel: "Nr. card",
  card_typeLabel: "Tip de card",
  change_status: "Schimbă statutul",
  changesUpdatedSuccessfuly: "Modificările au fost salvate cu success",
  charText: "caractere",
  characters: "caractere.",
  cityLabel: "Oraș",
  cityPlaceholder: "Ex: Cluj-Napoca",
  clearStatus: "Anulează statusul",
  client: "Fișă - Client",
  clientCreated: "Client creat cu success!",
  clientData: "Datele clientului",
  clientDeleted: "Clientul a fost șters cu success!",
  clientLabel: "Nume client",
  clientModified: "Client modificat cu success!",
  clientName: "Nume",
  clientType: "Tip client",
  client_name: "Client",
  client_nameLabel: "Nume client",
  clients: "Clienți",
  clientsLabel: "Client",
  close: "Închide",
  closeList: "Închide",
  companies: "Raport firme",
  companiesReports: "Raport firme",
  company: "Persoană juridică",
  companyData: "Date Companie",
  companyName: "Firmă",
  company_emailLabel: "Email",
  company_emailPlaceholder: "Ex: office@pangus.ro",
  company_nameLabel: "Denumire",
  company_namePlaceholder: "Ex: SC Atelierulmeu SRL",
  company_phoneLabel: "Număr de telefon",
  company_phonePlaceholder: "Ex: 0123 123 123",
  company_regLabel: "Nr. Înreg.",
  company_time_sheetLabel: "Program de lucru",
  company_time_sheetPlaceholder:
    "Ex: L-V 08:00 - 18:00 | S 08:00 - 13:00 | D Închis",
  company_vatLabel: "C.U.I / C.I.F",
  company_webpageLabel: "Pagina web",
  company_webpagePlaceholder: "Ex: www.pangus.ro",
  conditionAdded: "Condiție adăugată cu success!",
  conditionDeleted: "Condiția a fost ștearsă cu succes.",
  conditionLabel: "Condiție",
  conditionModified: "Condiție modificată cu success!",
  config: "Configurație",
  confirmAttendance: "Trimite",
  confirm_textLabel: "Confirmați numele companiei",
  contactData: "Date de contact",
  contactDeleted: "Contactul a fost șters",
  contactMe: "Contactează-ne!",
  contactUs: "Contactați-ne",
  contacts: "Contacte",
  contractAdded: "Contract adăugat cu success!",
  contractCreated: "Contact creat cu success!",
  contractDeleted: "Contractul a fost șters cu success!",
  contractModified: "Contract modificat cu success!",
  contract_number: "Nr. contract",
  contract_numberLabel: "Nr. contract",
  contracts: "Contracte",
  controlPanel: "Panou de control",
  cookiePolicy: "Politică Cookie",
  cookieSettings: "Setări Cookie",
  count: "#",
  countSymbol: "#",
  countryLabel: "Țară",
  countryPlaceholder: "Ex: Romania",
  countyLabel: "Județ",
  countyPlaceholder: "Ex: Cluj",
  create: "Creează comenzi de lucru",
  createWorkOrder: "Creează comenzi de lucru",
  createdBy: "Creat de",
  createdSuccessfully: " a fost creată cu succes.",
  created_at: "Dată creare",
  created_atLabel: "Dată creare",
  cuiLabel: "C.I.F / C.U.I",
  // "cumulatedReductionExplanation": " ",
  copyRightText: "© {{year}} Atelierulmeu. Toate drepturile rezervate.",
  cumulatedReductionExplanationExample:
    "Exemplu: Punctul 1 are 33% reducere, punctul 2 va avea 66% reducere. Punctul 3 va fi gratuit, punctul 4 va începe din nou la 33% reducere.",
  daily: "Raport zilnic",
  dailyReports: "Raport zilnic",
  dashboard: "Administrare",
  dataUpdated: "Datele au fost actualizate",
  date: "Dată",
  day: "Zi",
  day_1: "Luni",
  day_2: "Marți",
  day_3: "Miercuri",
  day_4: "Joi",
  day_5: "Vineri",
  day_6: "Sâmbătă",
  day_7: "Duminică",
  days: "zile",
  delegate: "Nume delegat",
  delegate_name: "Nume / Prenume",
  delegate_nameLabel: "Nume delegat",
  deletHotel: "Șterge hotel",
  delete: "Șterge",
  deleteAcc: "Ștergeți contul",
  deleteAppointment: "Șterge",
  deleteCard: "Șterge card",
  deleteCondition: "Șterge condiția",
  deleteConfirm: "Șterge",
  deleteConfirmation: "Sunteți sigur că doriți să ștergeți contul asociat cu ",
  deleteConfirmationText: "Sunteți sigur că doriți să ștergeți ",
  deleteContact: "Șterge",
  deleteItem: "Șterge",
  deleteNoCardNum: "Sunteți sigur că doriți să ștergeți acest card ?",
  deleteOrganization: "Șterge",
  deleteSuccessMessage: "Șters cu success",
  deleteUser: "Șterge",
  deleteWorkOrder: "Șterge comanda",
  deleted: "Account permanently suspended",
  deletedMessage: "Metoda de plată a fost ștearsă cu success",
  deleted_success: "Contul este dezactivat cu success.",
  deletegateName: "Delegate name",
  deletingAccount: " ",
  desc: "Descendent",
  description: "Descriere",
  descriptionLabel: "Descriere",
  descriptionLabel_1: "Mențiuni",
  descriptionLabel_10: "Descriere",
  descriptionLabel_11: "Descriere",
  descriptionLabel_12: "Descriere",
  descriptionLabel_13: "Mențiuni",
  descriptionLabel_14: "Descriere",
  descriptionLabel_15: "Descriere client",
  descriptionLabel_16: "Descriere",
  descriptionLabel_17: "Descriere",
  descriptionLabel_18: "Descriere",
  descriptionLabel_19: "Descriere",
  descriptionLabel_2: "Descriere",
  descriptionLabel_20: "Descriere",
  descriptionLabel_21: "Descriere",
  descriptionLabel_22: "Descriere",
  descriptionLabel_23: "Descriere",
  descriptionLabel_3: "Descriere client",
  descriptionLabel_4: "Descriere",
  descriptionLabel_5: "Descriere",
  descriptionLabel_6: "Descriere",
  descriptionLabel_7: "Descriere",
  descriptionLabel_8: "Descriere",
  descriptionLabel_9: "Descriere",
  detailed: "Detaliat",
  disabled: "Modul inactiv",
  disclaimer: "Se afișează doar comenzile din ultimele 12 ore.",
  tyreServiceDisclaimerText1:
    "Unitatea service garantează lucrarea executată*) și conformitatea acesteia**), potrivit OUG140/2021 *3 luni de la dată recepției autovehiculului, dacă lucrarea nu a necesitat înlocuiri de piese sau dacă s-a executat cu piesă clientului",
  tyreServiceDisclaimerText2:
    "** Garanția pieselor furnizate de unitate, în baza OUG140/2021 - conf. declarației producătorului/prin convenție între părți, condițional, de resp. indicațiilor de exploatare prevăzute de producătorul autovehiculului este de ___ luni.",
  tyreServiceDisclaimerText3:
    "*** La cererea BENEFICIARULUI unitatea va asigura vizualizarea activităților desfășurate și înregistrări care să dovedească efectuarea inspecțiilor finale",
  tyreServiceDisclaimerText4: "**** Nu se acordă garanție pentru piese furnizate de client.",
  tyreServiceDisclaimerTitle: "Certificat de calitate și garanție",
  discount: "Reducere",
  discountCalcFormula: "Formula de calcul a reducerii",
  discountLabel: "Reducere ",
  discountTotal: "Reducerea calculată",
  discount_typeLabel: "Tip reducere",
  discount_valueLabel: "Reducere",
  document_prefixLabel: "Prefix document",
  document_prefixPlaceholder: " ",
  document_suffixLabel: "Sufix document",
  document_suffixPlaceholder: " ",
  documents: "Documente",
  done: "Finalizare",
  doneWorkOrders: "Finalizat",
  downTrend: "Prețul nou este mai mic decât prețul standard",
  download: "Descărcare",
  downloadLabel: "Descarcă factura",
  duration: "Durată",
  durationLabel: "Durată serviciu",
  edit: "Modifică",
  editAddress: "Modifică adresa",
  editAppointment: "Modifică",
  editCard: "Modifică card",
  editCondition: "Modifică condiția",
  editContact: "Modifică datele contactului",
  editHotel: "Modifică hotel",
  editInfo: "Modifică datele",
  editItem: "Modifică",
  editOrganization: "Modifică datele organizației",
  editUser: "Modifică muncitor",
  editWorkOrder: "Modifică comanda",
  email: "Adresa de email a companiei",
  emailLabel: "Email",
  end: "Dată sfârșit",
  endAttendance: "Ieșire",
  endDate: "Data ieșire",
  endDateLabel: "Data de sfârșit",
  error_emailInvalid: "Adresa de email este invalidă",
  error_invalidNumber: "Nu este un număr valid",
  error_invalidPostalCode: "Doar numere / litere și spații goale sunt permise.",
  error_isRequired: "Câmp obligatoriu.",
  error_maxLength: "Trebuie să conțină cel mult ",
  error_minLength: "Trebuie să conțină cel puțin ",
  error_noPasswordConfirm: "Vă rugăm să confirmați parola",
  error_passwordsNotMatch: "Parolele trebuie să se potrivească",
  error_telNumInvalid: "Numărul de telefon este invalid",
  error_telNumNoDigits: "Trebuie să conțină cel puțin 4 cifre",
  error_urlInvalid: "Vă rugăm introduceți un URL valid.",
  expendable: "Consumabil",
  expendable_products: "Materiale consumabile",
  explanation: "Cum este calculat prețul?",
  extend: "Prelungeste",
  facebook: "Facebook",
  false: "Nu",
  female: "Femeie",
  fidelity: "Client fidel",
  fidelityLabel: "Client fidel",
  fieldLabel: "Câmp",
  finalizeRegister: "Finalizare",
  finished: "Finalizat",
  finishedLabel: "Data finalizare",
  firstPage: "Prima pagină",
  first_nameLabel: "Prenume",
  flatTrend: "Prețul nou este la fel ca prețul standard",
  forgotPassword: "Ai uitat parola?",
  free30: "Gratuit 30 de zile",
  from: "De la",
  fromLabel: "De la",
  function: "Funcție",
  functionLabel: "Funcție",
  functionPlaceholder: "Ex",
  gender: "Sex",
  genderLabel: "Sex",
  genderMissing: " - ",
  generalAndWorkers: "Date generale / Muncitori",
  generalInfo: "Informații Generale",
  groupCreated: "Grup creat cu success!",
  groupless: "Fără grup",
  groups: "Filtrare după grupuri",
  groupsPlaceholder: "Caută grup",
  guaranteeForms: "Formulare de garanție",
  haveAnAccount: "Am deja un cont.",
  helperText: " ",
  home: "Acasă",
  hotelChanged: "Hotel modificat cu success!",
  hotelData: "Depozitare anvelope",
  hotels: "Hotel anvelope",
  hoursWorked: "Ore lucrate",
  id: "Nr. Fișă",
  if: "dacă",
  import: "Încărcare fișier excel",
  "in-progress": "În progres",
  invalid_promo: "Codul de promoție este invalid.",
  ireversible: "Această acțiune este ireversibilă!",
  is: "este ",
  isCountPrice: "Preț activat",
  is_active: "Activat",
  is_activeLabel: "Card activat",
  is_company: "Persoană juridică",
  is_companyLabel: "Persoană juridică",
  is_count_price: "Preț activat",
  is_count_priceLabel: "Preț activat",
  is_expendable: "Consumabil",
  is_expendableLabel: "Material consumabil",
  is_inactive: "Dezactivat",
  is_service: "Serviciu",
  is_serviceLabel: "Serviciu",
  itemName: "Denumire",
  lastAttendance: "Ultima actualizare",
  lastPage: "Ultima pagină",
  lastWeek: "Săptămâna anterioară",
  last_nameLabel: "Nume de familie",
  left: "rămase",
  linkedin: "LinkedIn",
  loginBtnText: "Login",
  logout: "Ieșire din cont",
  loyaltyCard: "Card de loialitate",
  loyaltyCards: "Carduri de loialitate",
  loyaltyClients: "Clienți Fideli",
  loyaltyTitle: "Card de loialitate",
  loyalty_card: "Card de loialitate",
  main: "Administrare",
  mainLogoTitle: "Atelierulmeu",
  makeLabel: "Marcă auto",
  male: "Bărbat",
  messages: "Mesaje",
  modelLabel: "Model",
  modifiedSuccessfuly: "Modificat cu succes!",
  "module.Reports": "Rapoarte",
  modulePrice: "Prețul modulului",
  modules: "Module",
  modulesMenu: "Înapoi la meniu",
  month: "(lună)",
  monthly: "Raport lunar",
  monthlyReports: "Raport lunar",
  months: "(luni)",
  more: "Mai multe",
  moreActions: "Mai multe acțiuni",
  mountingStation: "Stații de montaj",
  mountingStations: "Stații de montare",
  myAccount: "Contul meu",
  name: "Nume",
  nameLabel: "Nume",
  productNameLabel: "Nume produs",
  searchProductNameLabel: "Caută un produs după nume",
  serviceNameLabel: "Nume serviciu",
  searchServiceNameLabel: "Caută un serviciu după nume",
  new: "Nou",
  newTyre: "Anvelope noi",
  new_priceBecomes: "noul preț devine",
  new_priceLabel: "Preț nou",
  new_tyreLabel: "Anvelope noi",
  new_tyres: "Anvelope noi",
  next: "Pasul următor",
  nextWeek: "Săptămâna următoare",
  noBarcode: "Ne pare rău. Nu am detectat un cod de bare",
  noGDPR: "Este necesar să acceptați cookie-urile pentru a continua.",
  noRecentlyAddedOrdersFound: "Nu am găsit comenzi adăugate recent.",
  noResultsFound: "Nici un rezultat găsit. Încercați opțiunile de mai jos.",
  none: "Niciunul",
  normalClients: "Clienți",
  notActivated: " Preț inactiv",
  notCountPriceExplanation:
    "Prețul produsului este inactiv, acesta trebuie modificat in sectiunea 'Produse'",
  notFound: "Pagina nu este valabilă",
  notFoundExtended: " ",
  notFoundNoHelpers:
    "Nici un rezultat găsit. Încercați să resetați filtrele de căutare",
  now: "acum ",
  numberLabel: "Număr",
  observation: "Observație",
  observations: "Observații",
  ok: "OK",
  "old-data": "Date vechi",
  "on-hold": "În așteptare",
  operations: "Operațiuni",
  operator: "Operator",
  operatorName: "Nume operator",
  orderByLabel: "Ordonat după",
  orderData: "Detalii comandă",
  orderDetails: "Detalii comandă",
  orderDirLabel: "Ordine",
  orderID: "Număr comanda",
  orderNumber: "Nr. comandă",
  order_by: "Ordonat după",
  order_dir: "Ordine",
  order_idLabel: "Nr. comandă",
  orders: "Comenzi",
  org: "Organizație",
  orgLabel: "Firma",
  organization: "Organizație",
  organizationCreated: "Organizație creată cu success!",
  organizationLabel: "Organizație",
  organization_client_id: "Client",
  organization_client_idLabel: "Selectează client",
  organization_id: "Organizație",
  organization_idLabel: "Punct de lucru",
  organizations: "Organizații",
  organizationsCount: "Nr. puncte de lucru",
  outOf: " din ",
  page: "Pagina",
  pageNotAvailable: "Pagina nu este valabilă",
  pageNotFound: "Pagina nu a fost găsită",
  pageSizeLabel: "Articole per pagină",
  paid: "Plătit",
  password: "Parolă",
  passwordHelperText: "Parola trebuie să conțină cel puțin 6 caractere.",
  passwordLabel: "Confirmați parola",
  pause: "Pauză",
  payment: "Plată",
  paymentDetails: "Detalii plată",
  paymentMethodChanged: "Metodă de plată modificată cu success!",
  paymentMethodCreated: "Metodă de plată creată cu success!",
  paymentMethods: "Metode de plată",
  paymentType: "Tip de plată",
  paymentTypes: "Metode de plată",
  payment_type: "Tip plată",
  payment_typeLabel: "Tip de plată",
  payment_type_id: "Tip de plată",
  payment_type_idLabel: "Tip de plată",
  payment_types: "Metode de plată",
  percent: "Procentaj",
  percent_1: "Procentaj",
  percent_2: "Procentaj",
  person: "Individual",
  piece: "BUC",
  phone: "Nr. telefon",
  phoneAllowedChars: "Doar numere, -, +, ( ) și spațiu e permis.",
  phoneLabel: "Număr de telefon",
  phone_fixLabel: "Telefon fix",
  phone_mobileLabel: "Telefon mobil",
  physical: "Fizic",
  physical_products: "Produse fizice",
  plate: "Nr. Înmatriculare",
  plate_number: "Înmatr.",
  plate_numberLabel: "Nr. înmatriculare",
  popoverTitle: "Selectați un statut",
  post_codeLabel: "Cod poștal",
  previous: "Înapoi",
  price: "Preț",
  priceCalcFormula: "Formula de calcul a prețului",
  priceLabel: "Preț",
  price_conditions: "Condiții de preț",
  printReport: "Imprimare raport",
  printWorkOrder: "Imprimare comandă",
  prinț: "Imprimare",
  privacy: "Politica de confidențialitate",
  privacyPolicy: "Politică de confidențialitate",
  product: "Denumire produs",
  productAdded: "Produs adăugat cu success!",
  productChanged: "Produsul a fost modificat cu succes.",
  productDeleted: "Produsul a fost șters cu success!",
  products: "Produse",
  productsEmpty: "Va rugăm adăugați cel puțin un produs sau serviciu.",
  products_name: "Produse noi",
  programmingAdded: "Programarea a fost adăugată cu succes.",
  progress: "În progres",
  progress_saved: "Datele au fost actualizate.",
  promoText: "Cu noi puteți ține totul sub control!",
  promotionLabel: "Cod de promoție",
  promotion_codeLabel: "Cod de promoție",
  qty: "Cantitate",
  quantity: "Cant",
  quantityLabel: "Cantitate",
  quick_text: "Căutare rapidă",
  quick_textLabel: "Căutare rapidă",
  rangeLabel: "Interval de timp",
  recentlyCreated: "Recent adăugate",
  recoverAcc: "Recuperare cont",
  regLabel: "Nr. Înreg",
  reg_number: "J",
  reg_numberLabel: "Numărul de ordine în registrul comerţului",
  reg_numberPlaceholder: "Ex: J12/345/2000",
  registerLabel: "Nu aveți încă un cont? Înscrieți-vă!",
  requested_services: "Servicii cerute",
  reset: "Reîncepe",
  resetFilters: "Resetează filtrele de căutare",
  resetSearch: "Resetează căutarea",
  resume: "Continuă",
  returnForms: "Formulare de returnare",
  reversible: "Această acțiune este reversibilă. ",
  role: "Funcție",
  roleMissing: " - ",
  rotateLeft: "Rotește stanga",
  rotateRight: "Rotește dreapta",
  rowsPerPage: "Rânduri pe pagină",
  save: "Salvează",
  scanCard: "Scanează cardul",
  scanningPossible: "Scanarea cardului de loialitate este posibilă.",
  scheduler: "Programări",
  selectModule: "Selectare Modul",
  selectProduct: "Selectați un serviciu",
  send: "Trimite",
  serverDown:
    "Oops! Atelierul pare să fie indisponibil momentan. Facem tot posibilul să aducem platforma înapoi online. Va rugăm încercați mai târziu.",
  service: "Serviciu",
  serviceAdded: "Serviciul a fost adăugat cu succes.",
  serviceBought: "Serviciu achiziționat",
  serviceChanged: "Serviciul a fost modificat cu succes.",
  serviceDeleted: "Serviciul a fost șters cu succes.",
  serviceInterval: "Intervalul de timp al serviciului activ",
  service_products: "Servicii",
  services: "Servicii",
  servicesExecuted: "Servicii executate",
  servicesRequested: "Servicii solicitate",
  short_name: "Prescurtare",
  short_nameLabel: "Prescurtare",
  shownResults: "Rezultate afișate.",
  signature: "Semnătură",
  signatureClient: "Semnătură client",
  signatureCompany: "Semnătură firmă",
  skipped: "Anulat",
  socialMedia: "Rețele sociale",
  stage1: "Date generale",
  stage2: "Date client",
  stage3: "Produse / Servicii",
  stage4: "Sumar",
  editStage1: "Date client",
  editStage2: "Produse / Servicii",
  editStage3: "Sumar",

  standardPrice: "Prețul standard",
  start: "Dată început",
  startAttendance: "Intrare",
  startDate: "Data intrare",
  startDateLabel: "Data de început",
  startTimer: "Începe",
  status: "Statut",
  statusLabel: "Statut comandă",
  step0Message:
    "Introduceți datele companiei pentru a putea utiliza aplicația.",
  step1Message: "Vă rugăm completați datele de contact de mai jos.",
  step2Message: "Alegeți un utilizator și o parolă.",
  step3Message:
    "Dacă aveți un cod de promoție, îl puteți introduce aici, dacă nu, doar finalizați procesul.",
  stop: "Oprește",
  stopTimer: "Oprește timpul",
  streetLabel: "Strada",
  streetPlaceholder: "Sediul firmei",
  submitAdd: "Adaugă",
  submitAddContact: "Add contact",
  submitConfirmOrder: "Confirmă comanda",
  submitEdit: "Modifică",
  submitFinalize: "Finalize",
  submitPlaceOrder: "Place order",
  submitSave: "Salvează",
  submitSend: "Trimite",
  submitSearch: "Caută",
  submitSearchClient: "Caută client",
  submitSearchContract: "Caută contract",
  submitSearchWorker: "Caută muncitor",
  submitSearchCard: "Caută card",
  submitSearchProduct: "Search product",
  submitSearchHotel: "Search hotel",
  submitSearchOrder: "Search order",
  subtotal: "Subtotal",
  success: "Modificat cu succes",
  successMessage: "Operațiunea a fost finalizată cu succes",
  successMessageEntry: "Intrare adăugată cu success!",
  successMessageExit: "Ieșire adăugată cu success!",
  successMessage_54: "Datele au fost actualizate",
  successMessage_55: "Datele au fost actualizate",
  successMessage_56: "Modified successfuly",
  successMessage_57: "Statut actualizat.",
  summary: "Sumar",
  "super-admin": "Administrator",
  superAdmin: "Super Admin",
  suspendAcc: "Suspendați contul",
  suspendConfirm: "Suspendă",
  suspendConfirmation: " ",
  suspended: "În așteptare",
  suspended_success: "Contul este suspendat cu success.",
  suspendingAccount: " ",
  tax: "Taxă",
  terms: " ",
  termsAndConditions: "Termeni și condiții",
  termsLabel: "Termeni şi condiţii de utilizare",
  then: " atunci ",
  time: "Oră / Dată",
  timeInterval: "Interval de timp",
  timeLeft: "Timpul rămas din serviciul activ",
  time_interval: "Interval de timp",
  time_sheetLabel: "Program de lucru",
  time_sheetPlaceholder: "Ex",
  timesheet: "Pontaj",
  timesheetReport: "Raport pontaj - ",
  titleAddAppointment: "Adaugă programare",
  titleAddCarType: "Adaugă tip de mașină",
  titleAddClient: "Adaugă Client",
  titleAddCondition: "Adaugă condiție",
  titleAddContact: "Adaugă Contact",
  titleAddContract: "Adaugă contract",
  titleAddGroup: "Adaugă grup",
  titleAddLoyaltyCard: "Adaugă card de loialitate",
  titleAddNewProduct: "Adaugă un nou produs",
  titleAddNewService: "Adaugă un nou serviciu",
  titleAddNewWorker: "Adaugă muncitor",
  titleAddOrganization: "Adaugă Organizație",
  titleAddPaymentMethod: "Adaugă metodă de plată",
  titleAddProduct: "Adaugă Produs",
  titleAddTyreHotel: "Adaugă hotel de anvelope",
  titleAddUser: "Adaugă Utilizator",
  titleAddWorker: "Adaugă Muncitor",
  titleApp: "Atelierul meu",
  titleAppointmentGroups: "Grupuri programări",
  titleAppointments: "Programări",
  titleAttendanceRule: "Condica de prezență a angajaților",
  titleAvailableModules: "Module valabile",
  titleCarTypes: "Tipuri de mașini",
  titleChooseDate: "Alege dată",
  titleChoosePrintingMethod: "Vă rugăm alegeți una din metodele de imprimare",
  titleClients: "Clienți",
  titleCompanyReport: "Raport firme",
  titleConfirmOrder: "Confirmare comandă",
  titleContracts: "Contracte",
  titleControlPanel: "Panou de control",
  titleCreateNewWorkOrder: "Creează o comandă nouă",
  titleCreateWorkOrders: "Creează comenzi de lucru",
  titleDashboard: "Administrare",
  titleDeleteOrder: "Șterge comanda ",
  titleDetailedReport: "Raport detaliat",
  titleDetailedTimesheet: "Pontaj detaliat",
  titleEditAddress: " Modifică adresa",
  titleEditAppointment: "Modifică programarea ",
  titleEditCarType: "Modifică tipul de mașină - ",
  titleEditClient: "Modifică Client",
  titleEditCondition: "Modifică condiția",
  titleEditContact: "Modifică contact - ",
  titleEditContract: "Modifică contract",
  titleEditGeneralInfo: " Modifică informațiile generale",
  titleEditLoyaltyCard: "Modifică card de loialitate",
  titleEditOrganization: "Modifică Organizația - ",
  titleEditPaymentMethod: "Modifică metoda de plată - ",
  titleEditProduct: "Modifică produsul - ",
  titleEditService: "Modifică serviciul - ",
  titleEditTyreHotel: "Modifică hotel de anvelope",
  titleEditUser: "Modifică Utilizatorul - ",
  titleEditWorkOrder: "Modifică comanda ",
  titleEditWorker: "Modifică Muncitorul - ",
  titleLogin: "Autentificare",
  titleLoyaltyCards: "Carduri de loialitate",
  titleModifyGroup: "Modifică grupul - ",
  titleOrders: "Comenzi",
  titleOrganizations: "Organizații",
  titlePaymentMethods: "Metode de plată",
  titlePriceConditionsFor: "Condiții de preț pentru ",
  titleProducts: "Produse",
  titleRegister: "Înregistrare",
  titleReportDaily: "Raport zilnic",
  titleReportMonthly: "Raport lunar",
  titleReportTyreWaste: "Raport deșeuri",
  titleReportWorkers: "Raport muncitori",
  titleSelectModule: "Selectați unul din modulele valabile",
  titleTimesheet: "Pontaj ",
  titleTyreHotels: "Hotel anvelope",
  titleTyreHotelsDeposit: "Depozitare / Hotel anvelope",
  titleUpdateAccount: "Actualizare",
  titleUpdateActiveService: "Actualizare serviciu activ",
  titleUsers: "Utilizatori",
  titleWorkOrder: "Comanda ",
  titleWorkOrders: "Comenzi de lucru",
  titleWorkers: "Muncitori",
  to: "Până la",
  toLabel: "Până la",
  today: "Azi",
  total: "Total",
  totalHours: "Total ore lucrate",
  totalHoursWorked: "Total ore lucrate",
  totalLabel: "Total cu TVA",
  totalTitle: "Raport Total",
  totalization: "Totalizare",
  totalizationTitle: "Raport lunar totalizare",
  totalized: "Raport Firme Lunar Totalizare",
  true: "Da",
  type: "Tip cont",
  typeLabel: "Tip cont",
  tyreDim: "Dim. Anvelope",
  tyreDimensions: "Dimensiune anvelope",
  tyreHotel: "Hotel / Depozitare anvelope",
  tyreHotelDeleted: "Hotelul a fost șters cu success!",
  tyreHotelDescription: "Descriere",
  tyreHotelsAdded: "Hotel adăugat cu success!",
  tyrePressure: "Presiune",
  tyreService: "Vulcanizare",
  tyre_dim: "Dim. anvelope",
  tyre_dimension: "Dim. Anvelopei",
  tyre_dimensions: "Dim. Anvelope",
  tyre_height: "înălțimea anvelopei",
  tyre_heightLabel: "Înălțime anvelopă",
  tyre_name: "Nume",
  tyre_nameLabel: "Nume anvelopă",
  tyre_pressure: "presiunea anvelopei",
  tyre_pressureLabel: "Presiune anv.",
  tyre_quantity: "Cantitate",
  tyre_quantityLabel: "Cantitate",
  tyre_rim: "diametrul anvelopei",
  tyre_rimLabel: "Diametru anvelopă",
  tyre_waste: "deșeurile anvelopei",
  tyre_wasteLabel: "Deșeuri anv.",
  tyre_width: "lățimea anvelopei",
  tyre_widthLabel: "Lățime anvelopă",
  tyres: "Anvelope",
  um: "UM",
  umLabel: "Unitate de măsură",
  undo: "Resetează opțiunile de căutare",
  undoRotation: "Anulează rotirea",
  undoZoom: "Anulează mărirea/micșorarea",
  upTrend: "Prețul nou este mai mare decât prețul standard",
  updateStatus: "Modifică statutul",
  updatedAt: "Data",
  upgrade: "Actualizare",
  user_idLabel: "Asociat cu contul",
  username: "Nume",
  usernameLabel: "Utilizator",
  users: "Utilizatori",
  uuid: "Nr. Comandă",
  value: "Valoare (RON)",
  valueLabel: "Valore",
  value_1: "Valoare (RON)",
  value_2: "Valoare",
  vat_number: "C.I.F / C.U.I",
  vat_numberLabel: "C.I.F / C.U.I",
  vat_numberPlaceholder: "Ex: CIF: RO123456",
  view: "Vizualizare",
  viewAdmin: "Vizualizare Admin",
  viewContact: "Vizualizare date de contact",
  viewMode: "Mod de vizualizare",
  viewNormal: "Vizualizare Atelier",
  viewPriceConditions: "Deschide",
  viewWorkOrder: "Vizualizare comandă",
  views: "Interval de timp",
  vin_numberLabel: "Nr. vin",
  waitingWorkOrders: "În procesare",
  warehouse: "Etichete - Depozit",
  waste: "Raport deșeuri",
  wasteReports: "Raport deșeuri",
  webpageLabel: "Pagina web",
  week: "Săptămâna",
  welcomeBack: "Bine ai revenit!",
  wheelTypes: "Tipuri de mașini",
  wheel_type: "Tip de roată",
  wheel_typeLabel: "Tip de roată",
  wheel_types: "Anvelope",
  wheels: "Anvelope",
  withGroupingLabel: "Vizualizare pe grupe",
  "work-orders-daily": "Fișe de lucru - Azi",
  "work-orders-monthly": "Fișe de lucru - Lunar",
  "work-orders-weekly": "Fișe de lucru - Săptămânal",
  workInterval: "Interval de lucru",
  workOrder: "Fișă de lucru",
  workOrderCreated: "Comanda a fost creată",
  workOrderDeleted: "Comanda a fost ștersă cu success!",
  workOrderLabel: "Comanda",
  workOrderSettings: "Setări fișă de lucru",
  workOrderType: "Tipul comenzii de lucru",
  workOrders: "Comenzi de lucru",
  workPlace: "Punct de lucru",
  work_order_id: "Nr. fisă de lucru",
  work_order_type: "Serviciu",
  work_order_typeLabel: "Tip de comandă",
  work_order_type_id: "Tip de comandă",
  work_order_types: "Comenzi de lucru",
  work_order_uuid: "Nr. fisă de lucru",
  work_order_uuidLabel: "Fișă de lucru",
  work_orders_count: "Fișe de lucru totalizat cantitativ",
  work_week: "Săptămână",
  worked_hours: "Total ore",
  worker: "Muncitor",
  workerChanged: "Muncitor modificat cu success!",
  workerCreated: "Muncitor creat cu success!",
  workerDeleted: "Muncitorul a fost șters cu success!",
  workerLabel: "Utilizator",
  workers: "Muncitori",
  workersLabel: "Caută după nume",
  workersReports: "Raport muncitori",
  workersSearchLabel: "Caută muncitor",
  workstationSettings: "Workstation Settings",
  zoomIn: "Mărește",
  zoomOut: "Micșorează",
  addTyreHotel: "Adaugă hotel anvelope",
  deleteTyreHotelForm: "Șterge hotel anvelope",
  carServiceDisclaimerTitle: "Certificat de calitate și garanție",
  carServiceDisclaimerText1:
    "Termenul de execuție a lucrărilor comandate este de ……………………… . Acest termen poate suferi modificări în următoarele cazuri: a.)pentru realizarea lucrărilor comandate la desfacerea unui ansamblu se descoperă defecțiuni noi, care necesită comenzi/ piese și/sau lucrări suplimentare. b.) piesele comandate nu sunt livrate la termenele prestabilite de furnizori. c.) în caz de forță majoră sau caz fortuit.",
  carServiceDisclaimerText2:
    "Unitatea poate efectua lucrări suplimentare și înlocuiri piese până la nivelul sumei maxime de ……………….. RON, acestea fiind acceptate prin prezența.",
  carServiceDisclaimerText3:
    "Clientul se obligă să asigure o exploatare corectă a autovehicului reparat în conformitate cu cerințele impuse de producător. Piesele și materialele aduse de client nu beneficiază de garanție.",
  carServiceDisclaimerText4:
    "Beneficiarul/Clientul declar că sunt de acord că prestatorul are drept de retenție asupra autovehicului reparat până la achitarea integrală a contravalorii facturii(lor) de service/reparație acceptat de mine/societatea noastră.",
  guaranteeConditions: "CONDIȚII DE GARANȚIE",
  ourUnitGrantsGuarantee: "Unitatea noastră acordă garanție",
  forProducts: "pentru produse",
  toIndividuals24Months:
    "la persoane fizice: 24 luni conform Legii nr. 449/2003",
  toCompanies12Months: "la persoane juridice: 12 luni",
  toCompaniesExceptions6Months:
    "la persoane juridice (școli de șofer, taxi): 6 luni",
  workGuarantee3Months:
    "pentru manopere garanția este de 3 luni de la data recepției",
  manufacturing_yearLabel: "An de fabricație",
  accounts: "Conturi",
  titleAccounts: "Conturi",
  accountNameLabel: "Nume cont",
  accountUsers: "Nr. utilizatori",
  inactive: "Inactiv",
  activeFromLabel: "Activ de la",
  activeToLabel: "Activ pana la",
  titleEditAccount: "Modifică cont - ",
  accountOrganizations: "Nr. organizații",
  workOrderTypes: "Tipuri de comenzi",
  accountUUID: "Cont uuid",
  openCalendar: "Deschide Calendarul",
  closeCalendar: "Închide Calendarul",
  GroupByDay: "Grupează pe zile",
  PlateNumberAlreadyInUse: "Numărul de înmatriculare este deja folosit."
};

export { en_ro };

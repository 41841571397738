import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderSignature.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  label: PropTypes.string,
  justify: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  label: "",
  justify: "flex-end",
};

/**
 * Displays the component
 */
const WorkOrderSignature = (props) => {
  const { label, justify } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  return (
    <Grid container justify={justify} alignItems="center">
      <Typography variant="caption" className={classes.signatureCont}>
        {label ? label : t("signature")}
        <div className={classes.signatureLine} />
      </Typography>
    </Grid>
  );
};

WorkOrderSignature.propTypes = propTypes;
WorkOrderSignature.defaultProps = defaultProps;

export default WorkOrderSignature;
export {
  propTypes as WorkOrderSignaturePropTypes,
  defaultProps as WorkOrderSignatureDefaultProps,
};

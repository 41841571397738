import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports
 */
import Grid from "@material-ui/core/Grid";

/**
 * Styles Imports
 */
import { useStyles } from "./PrintTyreHotels.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  printData: PropTypes.object,
  setReadyToPrint: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  printData: {},
  setReadyToPrint: () => {},
};

/**
 * Displays the component
 */
const PrintTyreHotels = (props) => {
  const { printData } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const renderCards = () => {
    const instances = Array(4).fill(printData);

    return (
      <Grid container>
        {instances.map((instance, key) => {
          return (
            <Grid item container xs={6} key={key}>
              <Grid container className={classes.box}>
                <Grid item container xs={12} className={classes.row}>
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    className={classes.border}
                  >
                    <div className={classes.label}>{t("plate_number")}</div>
                  </Grid>
                  <Grid item xs={8} container alignItems="center">
                    <div className={classes.value}>
                      {instance.car_number ? instance.car_number : "-"}
                    </div>
                  </Grid>
                </Grid>

                <Grid item container xs={12} className={classes.row}>
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    className={classes.border}
                  >
                    <div className={classes.label}>{t("tyre_dim")}</div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className={classes.value}>
                      {instance.tyre_dim ? instance.tyre_dim : "-"}
                    </div>
                  </Grid>
                </Grid>

                <Grid item container xs={12} className={classes.row}>
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    className={classes.border}
                  >
                    <div className={classes.label}>{t("tyre_name")}</div>
                  </Grid>
                  <Grid item xs={8} container alignItems="center">
                    <div className={classes.valueMedium}>
                      {instance.tyre_name ? instance.tyre_name : "-"}
                    </div>
                  </Grid>
                </Grid>

                <Grid item container xs={12} className={classes.row}>
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    className={classes.border}
                  >
                    <div className={classes.label}>{t("quantity")}</div>
                  </Grid>
                  <Grid item xs={8} container alignItems="center">
                    <div className={classes.valueMedium}>
                      {instance.tyre_quantity
                        ? `${instance.tyre_quantity} BUC`
                        : "0 BUC"}
                    </div>
                  </Grid>
                </Grid>

                <Grid item container xs={12} className={classes.row}>
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    className={classes.border}
                  >
                    <div className={classes.label}>{t("client_name")}</div>
                  </Grid>
                  <Grid item xs={8} container alignItems="center">
                    <div className={classes.valueMedium}>
                      {instance.client_name ? instance.client_name : "-"}
                    </div>
                  </Grid>
                </Grid>

                <Grid item container xs={12} className={classes.row}>
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    className={classes.border}
                  >
                    <div className={classes.label}>{t("description")}</div>
                  </Grid>
                  <Grid item xs={8} container alignItems="center">
                    <div className={classes.valueSmall}>
                      {instance.description ? instance.description : "-"}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}{" "}
      </Grid>
    );
  };

  return <div className={classes.container}>{renderCards()}</div>;
};

PrintTyreHotels.propTypes = propTypes;
PrintTyreHotels.defaultProps = defaultProps;

export default PrintTyreHotels;
export {
  propTypes as PrintTyreHotelsPropTypes,
  defaultProps as PrintTyreHotelsDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Redirect, useHistory } from "react-router-dom";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import TabPanel, { TabPanelDefaultProps, TabPanelPropTypes } from "../TabPanel";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import CompanyDataForm, {
  CompanyDataFormDefaultProps,
  CompanyDataFormPropTypes,
} from "../CompanyDataForm";
import ContactDataForm, {
  ContactDataFormDefaultProps,
  ContactDataFormPropTypes,
} from "../ContactDataForm";
import UserDataForm, {
  UserDataFormDefaultProps,
  UserDataFormPropTypes,
} from "../UserDataForm";
import PromotionCodeForm, {
  PromotionCodeFormDefaultProps,
  PromotionCodeFormPropTypes,
} from "../PromotionCodeForm";
import { LoginPageDefaultProps } from "../LoginPage";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import {
  useForm,
  useAuth,
  useRegister,
  useMessage,
  useApiClient,
} from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./RegisterPage.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./RegisterPage.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  tabPanel: PropTypes.shape(TabPanelPropTypes),
  path: PropTypes.string,
  companyDataForm: PropTypes.shape(CompanyDataFormPropTypes),
  contactDataForm: PropTypes.shape(ContactDataFormPropTypes),
  userDataForm: PropTypes.shape(UserDataFormPropTypes),
  promotionCodeForm: PropTypes.shape(PromotionCodeFormPropTypes),
  form: PropTypes.shape(FormPropTypes),
  defaultValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    company_name: PropTypes.string,
    vat_number: PropTypes.string,
    reg_number: PropTypes.string,
    city: PropTypes.string,
    number: PropTypes.string,
    street: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string,
    company_phone: PropTypes.string,
    company_email: PropTypes.string,
    company_webpage: PropTypes.string,
    company_time_sheet: PropTypes.string,
    promotion_code: PropTypes.string,
    captcha: PropTypes.string,
    terms: PropTypes.bool,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  tabPanel: TabPanelDefaultProps,
  path: "/register",
  companyDataForm: CompanyDataFormDefaultProps,
  contactDataForm: ContactDataFormDefaultProps,
  userDataForm: UserDataFormDefaultProps,
  promotionCodeForm: PromotionCodeFormDefaultProps,
  form: FormDefaultProps,
  defaultValues: {
    first_name: "",
    last_name: "",
    phone: "",
    username: "",
    password: "",
    company_name: "",
    vat_number: "",
    reg_number: "",
    city: "",
    number: "",
    street: "",
    county: "",
    country: "",
    company_phone: "",
    company_email: "",
    company_webpage: "",
    company_time_sheet: "",
    promotion_code: "",
    captcha: "",
    terms: false,
  },
};

/**
 * Displays the component
 */
const RegisterPage = (props) => {
  const {
    tabPanel,
    defaultValues,
    form,
    companyDataForm,
    contactDataForm,
    userDataForm,
    promotionCodeForm,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the login controller and the global user setter
   */
  const { login, isAuthenticated } = useAuth();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Handles routing to the login page
   */
  const handleLoginPageRouting = () => history.push(LoginPageDefaultProps.path);

  /**
   * Gets the active step
   */
  const {
    activeStep,
    nextStep,
    prevStep: goBackStep,
    setFormData,
    formData,
  } = useRegister();

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [formInputs, setFormInputs] = useState({});

  /**
   * Initialize the state flag responsible for resetting the captcha
   */
  const [resetCaptcha, setResetCaptcha] = useState(false);

  /**
   * Handles capturing the captcha token
   */
  const handleCaptcha = (token) => {
    setInputs((inputs) => {
      return { ...inputs, captcha: token };
    });
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    setFormInputs(inputs);
    if (inputs.promotion_code) {
      verifyPromo(inputs.promotion_code);
    } else {
      setReady(true);
    }
  };

  /**
   * Handles going to the previous step
   */
  const prevStep = () => {
    goBackStep();
    setErrors({});
    setLoading(false);
  };

  /**
   * Handles going to the next step
   */
  const handleNext = () => {
    validateInputs(inputs);
    const newErrors = validateInputs(inputs);
    if (!hasErrors(newErrors)) {
      setFormData(inputs);
      nextStep();
    } else {
      setFocused(true);
    }
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
    activeStep,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    hasErrors,
    setErrors,
    setInputs,
    resetInputs,
    validateInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
    setFocused,
  } = useForm(useFormConfig);

  /**
   * Handles registering a user
   */
  const registerUser = async (data) => {
    try {
      const registeredUser = await apiClient.post("/registration", data);
      if (registeredUser) {
        const { data } = registeredUser;
        setLoading(false);
        resetInputs();
        login(data);
      }
    } catch (error) {
      setLoading(false);
      const ErrorMessages = () => {
        const errors = Object.values(error);
        return (
          <ul>
            {errors &&
              errors.map((error, idx) => {
                return <li key={idx}> {error[0]} </li>;
              })}
          </ul>
        );
      };

      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages />,
      });
    }
  };

  /**
   * Handles verifying the promo code
   */
  const verifyPromo = async (promoCode) => {
    try {
      const verifiedPromoCode = await apiClient.post(
        "/promotion-code/validate",
        {
          promotion_code: promoCode,
        }
      );

      if (verifiedPromoCode) {
        const { data } = verifiedPromoCode;
        data
          ? setReady(true)
          : setErrors((prevState) => {
              return { ...prevState, promotion_code: t("invalid_promo") };
            });
      }
    } catch (error) {
      setErrors((prevState) => {
        return { ...prevState, promotion_code: error };
      });
    }
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        company_data: {
          name: formInputs.company_name,
          vat_number: formInputs.vat_number,
          reg_number: formInputs.reg_number,
          city: formInputs.city,
          number: formInputs.number,
          street: formInputs.street,
          county: formInputs.county,
          country: formInputs.country,
          phone: formInputs.company_phone,
          email: formInputs.company_email,
        },
        contact_data: {
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          phone: formInputs.phone,
        },
        user_data: {
          username: formInputs.username,
          password: formInputs.password,
        },
        promotion_code: formInputs.promotion_code,
      };
      if (formInputs.company_webpage)
        data.company_data["webpage"] = formInputs.company_webpage;
      if (formInputs.company_time_sheet)
        data.company_data["time_sheet"] = formInputs.company_time_sheet;

      registerUser(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Updates the inputs with the data from the session storage
   */
  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      setInputs(formData);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Redirects to the modules page if is authenticated
   */
  if (isAuthenticated) return <Redirect to="/modules" />;

  return (
    <Grid item xs={12} md={12} lg={12} xl={12}>
      <Form {...form} className={classes.formContainer} onSubmit={handleSubmit}>
        <Typography className={classes.title} color="textSecondary">
          {t("titleRegister")}
        </Typography>
        <Typography className={classes.subtitle} color="textSecondary">
          {t("free30")}
        </Typography>
        <TabPanel
          {...tabPanel}
          value={activeStep}
          index={0}
          className={classes.tabPanel}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={12} lg={10}>
              <Card className={classes.message}>{t("step0Message")}</Card>
            </Grid>
          </Grid>
          <Zoom in={activeStep === 0} timeout={500}>
            <Paper className={classes.blank}>
              <CompanyDataForm
                {...companyDataForm}
                inputs={inputs}
                errors={errors}
                prevStep={prevStep}
                handleNext={handleNext}
                handleInputChange={handleInputChange}
                handleLoginPageRouting={handleLoginPageRouting}
                getAutoFocus={getAutoFocus}
              />
            </Paper>
          </Zoom>
        </TabPanel>
        <TabPanel
          {...tabPanel}
          value={activeStep}
          index={1}
          className={classes.tabPanel}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={10} md={10}>
              <Card className={classes.message}>{t("step1Message")}</Card>
            </Grid>
          </Grid>
          <Zoom in={activeStep === 1} timeout={500}>
            <Paper className={classes.blank}>
              <ContactDataForm
                {...contactDataForm}
                inputs={inputs}
                errors={errors}
                prevStep={prevStep}
                handleNext={handleNext}
                handleInputChange={handleInputChange}
                handleLoginPageRouting={handleLoginPageRouting}
                getAutoFocus={getAutoFocus}
              />
            </Paper>
          </Zoom>
        </TabPanel>
        <TabPanel
          {...tabPanel}
          value={activeStep}
          index={2}
          className={classes.tabPanel}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={10} md={10}>
              <Card className={classes.message}>{t("step2Message")}</Card>
            </Grid>
          </Grid>
          <Zoom in={activeStep === 2} timeout={500}>
            <Paper className={classes.blank}>
              <UserDataForm
                {...userDataForm}
                inputs={inputs}
                errors={errors}
                prevStep={prevStep}
                handleNext={handleNext}
                handleInputChange={handleInputChange}
                handleLoginPageRouting={handleLoginPageRouting}
                getAutoFocus={getAutoFocus}
              />
            </Paper>
          </Zoom>
        </TabPanel>

        <TabPanel
          {...tabPanel}
          value={activeStep}
          index={3}
          name="register-panel"
          className={classes.tabPanel}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={10} md={10}>
              <Card className={classes.message}>{t("step3Message")}</Card>
            </Grid>
          </Grid>
          <Zoom in={activeStep === 3} timeout={500}>
            <Paper className={classes.blank}>
              <PromotionCodeForm
                {...promotionCodeForm}
                inputs={inputs}
                errors={errors}
                prevStep={prevStep}
                handleNext={handleNext}
                loading={loading}
                handleInputChange={handleInputChange}
                handleLoginPageRouting={handleLoginPageRouting}
                getAutoFocus={getAutoFocus}
                handleCaptcha={handleCaptcha}
                resetCaptcha={resetCaptcha}
                setResetCaptcha={setResetCaptcha}
              />
            </Paper>
          </Zoom>
        </TabPanel>
      </Form>
    </Grid>
  );
};

RegisterPage.propTypes = propTypes;
RegisterPage.defaultProps = defaultProps;

export default RegisterPage;
export {
  propTypes as RegisterPagePropTypes,
  defaultProps as RegisterPageDefaultProps,
};

import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: "25px",
        padding: "0.85em",
        fontSize: theme.font.size.s,
        textTransform: "none",
        fontWeight: theme.font.weight.bold,
        color: theme.palette.secondary.main
    },
    filled: {
        margin: "0 0 0.25em",
        background: theme.palette.primary.main
    },
    outlined: {
        margin: "0 0 2em",
        border: `2px solid ${theme.palette.primary.main}`
    }
}));

export { useStyles };

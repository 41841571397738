/**
 * Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  hidden: {
    visibility: "hidden"
  },
  textareaContainer: {
    position: "relative",
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
       transform: "translate(14px, -6px) scale(1)"
    }
  },
  textarea: theme.mixins.flex.column,
  modalInfo: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    position: "absolute",
    bottom: 0,
    left: 0
  },
  count: {
    marginTop: 0
  }
}));

export { useStyles };

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes
} from "../ErrorMessages";

/**
 * Hooks
 */
import { useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./BarcodeReader.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  open: PropTypes.bool,
  setScanning: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  errorMessages: ErrorMessagesDefaultProps,
  open: false,
  setScanning: () => {}
};

/**
 * Displays the component
 */
const BarcodeReader = (props) => {
  const {
    handleBarcodeScanner,
    errorMessages,
    open,
    setScanning,
    setTriggerScanning
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the scanprogress object
   */
  const [scanProgress, setScanProgress] = useState({
    barcodeText: "",
    shiftIndex: 0,
    endBarcode: false
  });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  const [responseData, setResponseData] = useState({});

  /**
   * Handles check is shift key
   */
  const isShiftKeyEvent = (event) => {
    return event.keyCode === 16 && event.key === "Shift";
  };

  /**
   * Handles check is shift key
   */
  const isEnterKeyEvent = (event) => {
    return event.keyCode === 13 && event.key === "Enter";
  };

  /**
   * Handles the keydown event
   */
  const reportKeyEvent = useCallback(
    (event) => {
      if (isShiftKeyEvent(event)) {
        setScanProgress({
          ...scanProgress,
          ...{
            shiftIndex: scanProgress.shiftIndex + 1
          }
        });
      } else if (isEnterKeyEvent(event)) {
        setScanProgress({
          ...scanProgress,
          ...{
            endBarcode: true
          }
        });
      } else {
        if (
          scanProgress.shiftIndex >= 1 &&
          scanProgress.shiftIndex <= 4 &&
          !scanProgress.endBarcode
        ) {
          setScanProgress({
            ...scanProgress,
            ...{
              barcodeText: scanProgress.barcodeText + event.key
            }
          });
        }
      }
    },
    [scanProgress]
  );

  /**
   * Handles searching for loyalty cards
   */
  const searchLoyaltyCards = async (data) => {
    try {
      const cards = await apiClient.post("/loyalty-cards/search", data);
      if (cards) {
        const { data } = cards;
        /**
         * Handles dispatching the error message
         */
        const message =
          data.items.length > 0
            ? `${t("cardFound")} ${data.models[0].selected}`
            : `${t("cardNotFound")} ${data.models[0].selected}`;

        if (data.items.length > 0) {
          setResponseData(data.items[0]);
        }

        dispatchMessage({
          delay: 300,
          severity: data.items.length > 0 ? "success" : "error",
          message: message
        });
        setTimeout(() => {
          setTriggerScanning(true);
        }, 1000);
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />
      });
      setScanning(false);
    }
  };

  /**
   * Handles resetting the scanProgress object
   */
  const resetScanProgress = () => {
    setScanProgress({
      barcodeText: "",
      shiftIndex: 0,
      endBarcode: false,
      keyEvents: []
    });
  };

  /**
   * Handles getting loyalty card by barcode
   */
  const getCardByBarcode = (barcode) => {
    const data = {
      models: [
        {
          label: "Card Number",
          field: "card_number",
          type: "equal",
          order: 2,
          options: [],
          selected: barcode
        }
      ],
      order_by: "created_at",
      order_dir: "asc",
      page_size: 1,
      page_count: 1
    };

    if (!barcode) {
      dispatchMessage({
        delay: 300,
        severity: "error",
        message: t("noBarcode")
      });
    } else {
      searchLoyaltyCards(data);
      setScanning(true);
    }
  };

  /**
   * Handles add event listener
   */
  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", reportKeyEvent);
      return () => window.removeEventListener("keydown", reportKeyEvent);
    }
  }, [reportKeyEvent, open]);

  /**
   * Handles change in scanProgress object
   */
  useEffect(() => {
    if (
      scanProgress.shiftIndex >= 1 &&
      scanProgress.shiftIndex <= 2 &&
      scanProgress.endBarcode
    ) {
      getCardByBarcode(scanProgress.barcodeText);
      resetScanProgress();
    }
    // eslint-disable-next-line
  }, [
    scanProgress.shiftIndex,
    scanProgress.endBarcode,
    scanProgress.barcodeText
  ]);

  useEffect(() => {
    if (Object.keys(responseData).length > 0) {
      handleBarcodeScanner(responseData);
    }
    // eslint-disable-next-line
  }, [responseData]);

  return <div className={classes.root} />;
};

BarcodeReader.propTypes = propTypes;
BarcodeReader.defaultProps = defaultProps;

export default BarcodeReader;
export {
  propTypes as BarcodeReaderPropTypes,
  defaultProps as BarcodeReaderDefaultProps
};

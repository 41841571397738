/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.mixins.flex.column,
    justifyContent: "center",
    flex: 1
  },
  title: {
    fontSize: "1rem",
    display: "flex",
    fontWeight: 800,
    color: "#656e73",
    borderBottom: "1px solid #d4d4d4",
    padding: "0.5rem 1rem 0"
  },
  cards: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  autocompleteField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem"
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.85rem"
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px"
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem"
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
      transform: "translate(14px, 12px) scale(1)",
      fontSize: "0.85rem"
    }
  },
  hidden: {
    visibility: "hidden"
  }
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ClientsTable, {
  ClientsTableDefaultProps,
  ClientsTablePropTypes
} from "../ClientsTable";
import ClientsSearchForm, {
  ClientsSearchFormDefaultProps,
  ClientsSearchFormPropTypes
} from "../ClientsSearchForm";
import AddClientModal, {
  AddClientModalDefaultProps,
  AddClientModalPropTypes
} from "../AddClientModal";
import DeleteClientModal, {
  DeleteClientModalDefaultProps,
  DeleteClientModalPropTypes
} from "../DeleteClientModal";
import EditClientModal, {
  EditClientModalDefaultProps,
  EditClientModalPropTypes
} from "../EditClientModal";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes
} from "../LoadingBackdrop";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes
} from "../SubmoduleContainer";
import Importer, { ImporterDefaultProps, ImporterPropTypes } from "../Importer";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkstationSettingsClients.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./WorkstationSettingsClients.defaults";

/**
 * Hooks
 */
import {
  useLocalStorage,
  useUser,
  useMessage,
  useApiClient
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  importer: PropTypes.shape(ImporterPropTypes),
  searchForm: PropTypes.shape(ClientsSearchFormPropTypes),
  addClientModal: PropTypes.shape(AddClientModalPropTypes),
  editClientModal: PropTypes.shape(EditClientModalPropTypes),
  deleteClientModal: PropTypes.shape(DeleteClientModalPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  clientsTable: PropTypes.shape(ClientsTablePropTypes),
  path: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  importer: ImporterDefaultProps,
  searchForm: ClientsSearchFormDefaultProps,
  addClientModal: AddClientModalDefaultProps,
  editClientModal: EditClientModalDefaultProps,
  deleteClientModal: DeleteClientModalDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  clientsTable: ClientsTableDefaultProps,
  path: "/clients"
};

/**
 * Handles returning the props for each tab
 */
const a11yProps = (index) => {
  return {
    id: `fidelity-tab-${index}`,
    "aria-controls": `fidelity-tabpanel-${index}`
  };
};

/**
 * Displays the component
 */
const WorkstationSettingsClients = (props) => {
  const {
    title,
    wrapper,
    container,
    clientsTable,
    defaultSearch,
    searchForm,
    addClientModal,
    deleteClientModal,
    editClientModal,
    loadingBackdrop,
    importer
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the tab panel
   */
  const [value, setValue] = useState(0);

  /**
   * Initializes the selected organization
   */
  const [hasFidelity, setHasFidelity] = useState("");

  /**
   * Initializes the reset filters flag
   */
  const [resetFilters, setResetFilters] = useState(false);

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the clients state
   */
  const [clients, setClients] = useState([]);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    addClient: false,
    editClient: false,
    deleteClient: false
  });

  /**
   * Initializes the state that hold the data for the modals
   */
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});

  /**
   * Handles searching for clients
   */
  const searchClients = async (data) => {
    try {
      const clients = await apiClient.post("/clients/search", data);

      if (clients) {
        const { data } = clients;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setUpdated(false);
        setTabChanged(false);
        setClients(items);

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
        setDefaultSearchDone(true);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setUpdated(false);
      setDefaultSearchDone(true);
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message
      });
    }
  };

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setResetFilters(true);

  /**
   * Handles opening the edit modal
   */
  const openEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const openDelete = (props) => setDeleteData(props);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAddClient = () => handleOpenModal("addClient");
  const closeAddClient = () => handleCloseModal("addClient");

  /**
   * Defines the edit User modal handlers
   */
  const openEditClient = () => handleOpenModal("editClient");
  const closeEditClient = () => handleCloseModal("editClient");

  /**
   * Defines the delete User modal handlers
   */
  const openDeleteClient = () => handleOpenModal("deleteClient");
  const closeDeleteClient = () => handleCloseModal("deleteClient");

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the state related to the Pagination
   */
  const [pageSize, setPageSize] = useLocalStorage("clientsPageSize");
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [updated, setUpdated] = useState(false);

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the tab changed flag
   */
  const [tabChanged, setTabChanged] = useState(false);

  /**
   * Initializes the inputs state
   */
  const [inputs, setInputs] = useState({});

  /**
   * Initializes the default search done flag
   */
  const [defaultSearchDone, setDefaultSearchDone] = useState(false);

  /**
   * Initializes the changed value state of the tabs
   */
  const [valueChanged, setValueChanged] = useState(false);

  /**
   * Gets the inputs
   */
  const { orderBy, orderDir } = inputs;

  /**
   * Handles getting the organization data based on the active tab
   */
  const getFidelityValue = (idx) => {
    if (idx === 0) return false;
    return true;
  };

  /**
   * Handles rendering the tabs
   */
  const renderTabs = () => {
    const tabs = [
      { uuid: "normalClients", name: t("normalClients") },
      { uuid: "loyaltyClients", name: t("loyaltyClients") }
    ];

    return (
      tabs &&
      tabs.map((tab, idx) => {
        return <Tab key={tab.uuid} label={tab.name} {...a11yProps(idx)} />;
      })
    );
  };

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles changing the tab value flag
   */
  const handleTabValueChange = () => {
    setValueChanged(true);
    setPageCount(1);
  };

  /**
   * Handles changing the tab value flag
   */
  const handleTabChange = () => {
    setTabChanged(true);
  };

  /**
   * Handles the tab change
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setHasFidelity(getFidelityValue(newValue));
    handleTabValueChange();
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (user.clients && user.clients.length < 1 && pageCount === 0) return;
    setBackdropLoading(true);

    /**
     * Defines the request data
     */
    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page
    };
    searchClients(data);
    window.scrollTo(0, 0);
  };

  /**
   * Handles getting the default model
   */
  const getDefaultModel = (model) => ({ ...defaults.models[model] });

  const getUserOrganizationModelValue = (mode, inputs) => {
    if (mode === "default") return user.organization && user.organization.id;
    if (inputs.organization) {
      const value = inputs.organization !== "all" ? inputs.organization : null;
      return value;
    }
  };

  /**
   * Handles building the search models
   */
  const buildSearchModels = (mode) => {
    /**
     * Gets the dynamic models (tabs affected by which tab is open)
     */
    const fidelityModel = getDefaultModel("fidelityModel");
    const isCompanyModel = getDefaultModel("isCompanyModel");
    const organizationIdModel = getDefaultModel("organizationIdModel");

    /**
     * Updates the model values based on the tabs open
     */
    fidelityModel["selected"] = hasFidelity;
    organizationIdModel["selected"] = getUserOrganizationModelValue(
      mode,
      inputs
    );

    isCompanyModel["selected"] = mode === "default" ? true : inputs.is_company;

    let models = [
      { ...organizationIdModel },
      { ...fidelityModel },
      { ...isCompanyModel }
    ];

    return models;
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const data = {
      ...defaultSearch,
      models: buildSearchModels("default"),
      page_size: pageSize || defaultSearch.page_size,
      page_count: 1
    };

    return data;
  };

  /**
   * Handles constructing the data object for the search
   */
  const getSearchParams = () => {
    /**
     * Defines the org id model
     */
    const fidelityModel = getDefaultModel("fidelityModel");
    fidelityModel["selected"] = hasFidelity ? true : false;

    /**
     * Eliminate potential duplicate org_ids objects
     */
    const modelsFiltered =
      models && models.length > 0
        ? models.filter((model) => model.field !== "fidelity")
        : [];

    const data = {
      models: [...modelsFiltered, { ...fidelityModel }],
      order_by: inputs.orderBy || defaultSearch.order_by,
      order_dir: inputs.orderDir || defaultSearch.order_dir,
      page_size: pageSize || defaultSearch.page_size,
      page_count: 1
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => searchClients(getDefaultSearch());

  /**
   * Handles searching for work orders
   */
  const handleSearch = (pgCount) => {
    const data = getSearchParams(pgCount);

    if (Object.keys(data).length > 0) {
      searchClients(data);
    }
  };

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    setPageSize(e.target.value);
  };

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEditClient();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDeleteClient();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Handles triggering a search if updated
   */
  useEffect(() => {
    if (updated) {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles searching on page change
   */
  useEffect(() => {
    if (defaultSearchDone) {
      window.scrollTo(0, 0);
      handleSearch();
    }
    // eslint-disable-next-line
  }, [pageSize]);

  /**
   * Handles performing a search on tab change
   */
  useEffect(() => {
    if (valueChanged) {
      setBackdropLoading(true);
      handleTabChange();
      setValueChanged(false);
    }
  }, [valueChanged]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    if (user.organization) {
      setModels(defaultSearch.models);
      handleDefaultSearch();
    }

    // eslint-disable-next-line
  }, [user.organization]);

  /**
   * Handles setting the selected organization based on active tab
   */
  useEffect(() => {
    setHasFidelity(getFidelityValue(value));
    // eslint-disable-next-line
  }, [value]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<AssignmentOutlinedIcon />}
        title={t("titleClients")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            <div className={classes.root}>
              <AppBar
                position="static"
                color="default"
                className={classes.appbar}
              >
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="standard"
                >
                  {renderTabs()}
                </Tabs>
              </AppBar>
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <ClientsSearchForm
                  {...searchForm}
                  setInputs={setInputs}
                  setModels={setModels}
                  setTotal={setTotal}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  setPageCount={setPageCount}
                  setBackdropLoading={setBackdropLoading}
                  handleDefaultSearch={handleDefaultSearch}
                  hasFidelity={hasFidelity}
                  tabChanged={tabChanged}
                  setTabChanged={setTabChanged}
                  setUpdated={setUpdated}
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                  setClients={setClients}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.tyreHotels}>
              <Importer {...importer} />
              <ClientsTable
                {...clientsTable}
                handleAdd={openAddClient}
                handleEdit={openEdit}
                handleDelete={openDelete}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleFiltersReset={handleFiltersReset}
                rowsPerPage={pageSize}
                page={pageCount}
                count={total}
                clients={clients}
              />
            </Grid>
            <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
            <AddClientModal
              {...addClientModal}
              open={modalState.addClient}
              closeModal={closeAddClient}
              organizations={user.organizations}
              defaultSearch={defaultSearch}
              tab={value}
              setTab={setValue}
              hasFidelity={hasFidelity}
              handleDefaultSearch={handleDefaultSearch}
              setUpdated={setUpdated}
            />
            <EditClientModal
              {...editClientModal}
              open={modalState.editClient}
              closeModal={closeEditClient}
              editData={editData}
              setUpdated={setUpdated}
              organizations={user.organizations}
            />
            <DeleteClientModal
              {...deleteClientModal}
              open={modalState.deleteClient}
              closeModal={closeDeleteClient}
              deleteData={deleteData}
              setUpdated={setUpdated}
              setDeleteData={setDeleteData}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

WorkstationSettingsClients.propTypes = propTypes;
WorkstationSettingsClients.defaultProps = defaultProps;

export default WorkstationSettingsClients;
export {
  propTypes as WorkstationSettingsClientsPropTypes,
  defaultProps as WorkstationSettingsClientsDefaultProps
};

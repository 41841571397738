import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import ConfirmUpgradeModal, {
  ConfirmUpgradeModalDefaultProps,
  ConfirmUpgradeModalPropTypes,
} from "../ConfirmUpgradeModal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import UpgradeExplanation, {
  UpgradeExplanationDefaultProps,
  UpgradeExplanationPropTypes,
} from "../UpgradeExplanation";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

/**
 * Hooks
 */
import { useForm, useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./UpgradePackageModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./UpgradePackageModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    package_id: PropTypes.string,
    quantity: PropTypes.string,
    promotion_code: PropTypes.string,
  }),
  upgradeExplanation: PropTypes.shape(UpgradeExplanationPropTypes),
  confirmUpgradeModal: PropTypes.shape(ConfirmUpgradeModalPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */

const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    package_id: "",
    quantity: "1",
    promotion_code: "",
  },
  upgradeExplanation: UpgradeExplanationDefaultProps,
  confirmUpgradeModal: ConfirmUpgradeModalDefaultProps,
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const UpgradePackageModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    errorMessages,
    modalData,
    renew,
    setRenew,
    confirmUpgradeModal,
    loadingText,
    upgradeExplanation,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initialize the form data state
   */
  const [data, setData] = useState({});

  /**
   * Initializes the calculated discount
   */
  const [calculatedDiscount, setCalculatedDiscount] = useState(0);

  /**
   * Initializes the final price
   */
  const [finalPrice, setFinalPrice] = useState(0);

  /**
   * Initialize the confirm modal state
   */
  const [confirmModal, setConfirmModal] = useState(false);

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles closing the confirm modal
   */
  const closeConfirm = () => setConfirmModal(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    if (promotion_code) {
      verifyPromo(promotion_code);
    } else {
      setReady(true);
    }
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    setErrors,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the data from the props
   */
  const { currency, description, name, price, uuid } = modalData;

  /**
   * Defines the base price and duration in days
   */
  const basePrice = price;
  const baseDays = 30;

  /**
   * Initialize the price, duration and the summary state
   */
  const [serviceDuration, setServiceDuration] = useState(30);
  const [summary, setSummary] = useState("");

  /**
   * Gets the inputs and errors
   */
  const { package_id, quantity, promotion_code } = inputs;
  const { promotion_code: promotion_codeError } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setReady(false);
    setErrors({});
    closeModal();
  };

  /**
   * Handles verifying the promo code
   */
  const verifyPromo = async (promoCode) => {
    const verifiedPromoCode = await apiClient.post("/promotion-code/validate", {
      promotion_code: promoCode,
    });

    if (verifiedPromoCode.data) {
      setReady(true);
    } else {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Map the error to the input
       */
      setErrors((prevState) => {
        return {
          ...prevState,
          promotion_code: t("invalid_promo"),
        };
      });
    }
  };

  /**
   * Handles creating a new order
   */
  const createOrder = async (data, type) => {
    try {
      const createdOrder = await apiClient.post(
        `/accounts/orders/${type}`,
        data
      );
      if (createdOrder) {
        const { data } = createdOrder;
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);
        setSummary({
          ...data,
          subtotal: calculatedDiscount + finalPrice,
          discount: calculatedDiscount,
          tax: 0,
          total: finalPrice,
          serviceDuration,
        });

        setConfirmModal(true);
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles closing the modal if renew is triggered
   */
  useEffect(() => {
    if (renew) handleCloseModal();
    // eslint-disable-next-line
  }, [renew]);

  /**
   * Defines the label component
   */
  const TableLabel = (props) => {
    const { value } = props;

    return (
      <Grid item xs={6} md={4} className={classes.labelContainer}>
        <Typography variant="caption" className={classes.label}>
          {value}
        </Typography>
      </Grid>
    );
  };

  /**
   * Defines the value component
   */
  const TableValue = (props) => {
    const { value, children } = props;

    return (
      <Grid item xs={6} md={8} className={classes.valueContainer}>
        <Typography variant="caption" className={classes.value}>
          {value}
          {children}
        </Typography>
      </Grid>
    );
  };

  /**
   * Handles updating the inputs with the data
   */
  useEffect(() => {
    if (modalData) {
      setInputs((prevState) => {
        return {
          ...prevState,
          package_id: modalData.package_id,
          quantity: "1",
          promotion_code: modalData.promotion_code,
        };
      });
    }
    // eslint-disable-next-line
  }, [modalData]);

  /**
   * Updates the state for price and duration when the quantity changes
   */
  useEffect(() => {
    setServiceDuration(baseDays * quantity);
    // eslint-disable-next-line
  }, [quantity]);

  /**
   * Updates the package id when the props changes
   * Initially it starts out as undefined until you open the modal
   */
  useEffect(() => {
    if (uuid)
      setInputs((prevState) => {
        return {
          ...prevState,
          package_id: uuid,
        };
      });
    // eslint-disable-next-line
  }, [uuid]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = { package_id, quantity };

      if (promotion_code) data["promotion_code"] = promotion_code;

      /**
       * Sets the form data
       */
      setData(data);

      /**
       * Sets the api call params
       */
      createOrder(data, "fake");
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles incrementing the quantity
   */
  const handleAddition = () => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        quantity: (Number(prevInputs.quantity) + 1).toString(),
      };
    });
  };

  /**
   * Handles decrementing the quantity
   */
  const handleSubstraction = () => {
    if (quantity <= 1) return;
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        quantity: (Number(prevInputs.quantity) - 1).toString(),
      };
    });
  };

  useEffect(() => {
    if (price) {
      const orgNumber =
        user && user.organizations ? user.organizations.length : 0; // ON
      const discountVar = 0.33; // DC
      let discountSum = 0; // DS
      let discVarIndex = 1; // DVI
      let calculatedDiscount = calculateDiscount(); // CD

      const finalPrice = price * quantity * orgNumber - calculatedDiscount;

      setCalculatedDiscount(
        parseFloat(parseFloat(calculatedDiscount).toFixed(2))
      );
      setFinalPrice(parseFloat(parseFloat(finalPrice).toFixed(2)));

      function calculateDiscount() {
        let index = 1;

        while (index < orgNumber) {
          if (discVarIndex * discountVar < 1) {
            discountSum += discVarIndex * discountVar * price * quantity;
          } else {
            discVarIndex = 1;
            discountSum += discVarIndex * discountVar * price * quantity;
          }
          discVarIndex++;
          index++;
        }
        return discountSum;
      }
    }
    // eslint-disable-next-line
  }, [price, quantity, user.organizations]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleUpdateActiveService")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <ConfirmUpgradeModal
              {...confirmUpgradeModal}
              renew={renew}
              setRenew={setRenew}
              data={data}
              summary={summary}
              open={confirmModal}
              closeModal={closeConfirm}
            />
            <Form
              {...form}
              id="upgrade-package-modal"
              className={classes.formWrapper}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box className={classes.wrapper}>
                            <Box className={classes.header}>
                              <Typography
                                variant="caption"
                                className={classes.title}
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.description}
                              >
                                {t(description)}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container justify="center">
                        <Grid
                          item
                          container
                          xs={12}
                          md={6}
                          className={classes.price}
                        >
                          <TableLabel value={t("subtotal")} />
                          <TableValue
                            value={`${finalPrice + calculatedDiscount} EUR`}
                          >
                            <UpgradeExplanation
                              {...upgradeExplanation}
                              modulePrice={basePrice}
                              quantity={quantity}
                              finalPrice={finalPrice}
                              orgCount={
                                user.organizations && user.organizations.length
                              }
                              organizations={user.organizations}
                              calculatedDiscount={calculatedDiscount}
                            />
                          </TableValue>
                          <TableLabel value={t("discount")} />
                          <TableValue value={`${calculatedDiscount} EUR`} />
                          <TableLabel value={t("total")} />
                          <TableValue value={`${finalPrice} ${currency}`} />
                          <TableLabel value={t("durationLabel")} />
                          <TableValue
                            value={`${serviceDuration} ${t("days")}`}
                          />
                        </Grid>
                      </Grid>
                      <Grid container justify="center">
                        <Grid item xs={12} md={3}>
                          <Box className={classes.formContainer}>
                            <Button
                              {...button}
                              type="button"
                              variant="filled"
                              className={classes.substraction}
                              onClick={handleSubstraction}
                            >
                              <RemoveIcon className={classes.icon} />
                            </Button>

                            <Typography
                              variant="caption"
                              className={classes.qtyLabel}
                            >
                              {t("quantityLabel")}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.qtyValue}
                            >
                              {quantity}
                            </Typography>
                            <Button
                              {...button}
                              type="button"
                              variant="filled"
                              className={classes.addition}
                              onClick={handleAddition}
                            >
                              <AddIcon className={classes.icon} />
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.textField}
                            inputText={{
                              id: "promotion_code",
                              name: "promotion_code",
                              value: promotion_code,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("promotion_codeLabel"),
                              error: promotion_codeError,
                              autoFocus: getAutoFocus().promotion_code,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="upgrade-package-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitConfirmOrder")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

UpgradePackageModal.propTypes = propTypes;
UpgradePackageModal.defaultProps = defaultProps;

export default UpgradePackageModal;
export {
  propTypes as UpgradePackageModalPropTypes,
  defaultProps as UpgradePackageModalDefaultProps,
};

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { useReactToPrint } from "react-to-print";
import { format as formatDate } from "date-fns";
import { ro, hu } from "date-fns/locale";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Widgets, { WidgetsDefaultProps, WidgetsPropTypes } from "../Widgets";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import PrintWorkOrder, {
  PrintWorkOrderDefaultProps,
  PrintWorkOrderPropTypes,
} from "../PrintWorkOrder";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import EditWorkOrderModal, {
  EditWorkOrderModalDefaultProps,
  EditWorkOrderModalPropTypes,
} from "../EditWorkOrderModal";
import TyreServiceWorkOrdersTable, {
  TyreServiceWorkOrdersTableDefaultProps,
  TyreServiceWorkOrdersTablePropTypes,
} from "../TyreServiceWorkOrdersTable";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import GraphicEqOutlinedIcon from "@material-ui/icons/GraphicEqOutlined";

/**
 * Hooks
 */
import { useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreServiceControlPanel.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  input: PropTypes.shape(InputPropTypes),
  printWorkOrder: PropTypes.shape(PrintWorkOrderPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  widgetsProps: PropTypes.shape(WidgetsPropTypes),
  editModal: PropTypes.shape(EditWorkOrderModalPropTypes),
  workOrdersTable: PropTypes.shape(TyreServiceWorkOrdersTablePropTypes),
  transitionTimeout: PropTypes.number,
  /**
   * Used in TyreServicePage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  printWorkOrder: PrintWorkOrderDefaultProps,
  input: InputDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  widgetsProps: WidgetsDefaultProps,
  editModal: EditWorkOrderModalDefaultProps,
  workOrdersTable: TyreServiceWorkOrdersTableDefaultProps,
  transitionTimeout: 500,
  /**
   * Used in TyreServicePage
   * @see defaultProps.paths
   */
  path: "/control-panel",
};

/**
 * Displays the component
 */
const TyreServiceControlPanel = (props) => {
  const {
    title,
    wrapper,
    container,
    errorMessages,
    widgetsProps,
    transitionTimeout,
    loadingBackdrop,
    input,
    printWorkOrder,
    editModal,
    workOrdersTable,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles printing the component
   */
  const componentRef = useRef();

  /**
   * Init showWidgets
   */
  const [showWidgets, setShowWidgets] = useState(false);

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the organizations list
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the finished work orders
   */
  const [finishedWorkOrders, setFinishedWorkOrders] = useState([]);

  /**
   * Initializes the work orders that are in progress
   */
  const [suspendedWorkOrders, setSuspendedWorkOrders] = useState([]);

  /**
   * Initializes the work orders types
   */
  const [workOrderTypes, setWorkOrderTypes] = useState([]);

  /**
   * Initializes the selected work order type
   */
  const [selectedWorkOrderType, setSelectedWorkOrderType] = useState("");

  /**
   * Initializes the widgets state
   */
  const [widgets, setWidgets] = useState([]);

  /**
   * Initializes the work orders list
   * Holds all work orders from the api call
   */
  const [workOrders, setWorkOrders] = useState([]);
  const [allSuspended, setAllSuspended] = useState([]);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Initializes the edit modalState
   */
  const [editModalState, setEditModalState] = useState(false);

  const [updated, setUpdated] = useState(false);
  const [updateType, setUpdateType] = useState("");

  /**
   * Initializes the state that hold the data for the modals
   */
  const [editData, setEditData] = useState({});

  /**
   * Initializes the worker id state
   */
  const [workOrderID, setWorkOrderID] = useState(0);

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Init the backdrop loading flag
   */
  const [backdropLoading, setBackdropLoading] = useState(false);

  /**
   * Handles getting the widgets
   */
  const getWidgets = async () => {
    try {
      const widgets = await apiClient.get("/widgets/work-orders-all");
      if (widgets) {
        const { data } = widgets;
        const widgetsList = Object.entries(data);

        setWidgets(
          widgetsList
            .map((widget) => {
              return [
                ...widget[1].map((widgetItem) => ({
                  ...widgetItem,
                  year: widget[0],
                })),
              ];
            })
            .sort((a, b) => a.order - b.order)
        );
      }
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Handles getting the daily work orders
   */
  const getDailyWorkOrders = async (type, subType) => {
    try {
      const dailyWorkOrders = await apiClient.get(
        "/service-controls/work-orders/daily"
      );
      if (dailyWorkOrders) {
        const { data } = dailyWorkOrders;

        setReady(true);
        setWorkOrders(data);
        setBackdropLoading(false);
        if (updated) {
          if (updateType) {
            /**
             * Handles dispatching the error message
             */
            dispatchMessage({
              message: t("successMessage"),
            });
            setUpdateType("");
          }

          setUpdated(false);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Handles getting the suspended work orders
   */
  const getSuspendedWorkOrders = async (type, subType) => {
    try {
      const suspendedWorkOrders = await apiClient.get(
        "/service-controls/work-orders/suspended"
      );
      if (suspendedWorkOrders) {
        const { data } = suspendedWorkOrders;
        setAllSuspended(data);

        setBackdropLoading(false);
        if (updated) {
          /**
           * Handles dispatching the error message
           */
          dispatchMessage({
            message: t("successMessage"),
          });
          setUpdated(false);
          setUpdateType("");
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Handles updating a work order's status
   */
  const updateWorkOrderStatus = async (id, status) => {
    try {
      const updatedWo = await apiClient.put(
        `/work-orders/${id}/status/${status}`
      );
      if (updatedWo) {
        setUpdated(true);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleWorkOrderTypeChange = (e) => {
    const workOrderTypeId = e.target.value;

    setSelectedWorkOrderType(workOrderTypeId);
  };

  /**
   * Handles updating the active organization
   */
  const handleOrganizationChange = (e) => setActiveOrg(e.target.value);

  /**
   * Handles updating the edit modal data
   */
  const handleEdit = (props) => setEditData(props);

  /**
   * Handles opening the edit modal
   */
  const openEdit = () => setEditModalState(true);

  /**
   * Handles closing the edit modal
   */
  const closeEdit = () => setEditModalState(false);

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => obj && Object.keys(obj).length < 1;

  /**
   * Handles initiating the printing
   */
  const printComponent = (id) => {
    setWorkOrderID(id);
    setLoading(true);
  };

  /**
   * Handles printing the component
   */
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**
   * Handles capitalizing the first letter of a string
   */
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const Title = () => {
    const dateFormat = "EEEE do MMMM yyyy";
    const today = new Date();
    const activeLanguage = localStorage.getItem("i18nextLng");

    let locale = null;

    if (activeLanguage === "en_ro") {
      locale = ro;
    }

    if (activeLanguage === "en_hu") {
      locale = hu;
    }
    const date = formatDate(today, dateFormat, {
      locale: locale,
    });

    return (
      <div className={classes.titleContainer}>
        <Typography className={classes.dateTitle}>
          {capitalize(date)}
        </Typography>
      </div>
    );
  };

  /**
   * Handles status toggling - variant 1
   */
  const handleStatusToggling = (id, status, type) => {
    setBackdropLoading(true);
    setUpdateType(type);

    updateWorkOrderStatus(id, status === "done" ? "in-progress" : "done");
  };

  /**
   * Handles the api call error
   */
  const handleError = (error) => {
    /**
     * Handles dispatching the error message
     */
    dispatchMessage({
      severity: "error",
      component: <ErrorMessages {...errorMessages} error={error} />,
    });
  };

  /**
   * Handles setting the api call params to get the daily work orders
   */
  useEffect(() => {
    setBackdropLoading(true);
    getDailyWorkOrders();
    getSuspendedWorkOrders();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles setting the api call params to get the daily work orders
   */
  useEffect(() => {
    if (updated) {
      setBackdropLoading(true);
      getDailyWorkOrders();
      getSuspendedWorkOrders();
    }

    // eslint-disable-next-line
  }, [updated]);

  /**
   * Updates the work orders table based on the active org change
   */
  useEffect(() => {
    if (workOrders.length > 0) {
      if (activeOrg === "all" && selectedWorkOrderType === "all") {
        setFinishedWorkOrders(workOrders);
      } else if (activeOrg === "all" && selectedWorkOrderType !== "all") {
        setFinishedWorkOrders(
          workOrders.filter(
            (workOrder) =>
              workOrder.work_order_type_id === selectedWorkOrderType
          )
        );
      } else if (activeOrg !== "all" && selectedWorkOrderType === "all") {
        setFinishedWorkOrders(
          workOrders.filter(
            (workOrder) => workOrder.organization_id === activeOrg
          )
        );
      } else {
        const newWorkOrders = workOrders.filter(
          (workOrder) =>
            workOrder.work_order_type_id === selectedWorkOrderType &&
            workOrder.organization_id === activeOrg
        );

        setFinishedWorkOrders(newWorkOrders);
      }
    }

    if (allSuspended.length > 0) {
      if (activeOrg === "all" && selectedWorkOrderType === "all") {
        setSuspendedWorkOrders(allSuspended);
      } else if (activeOrg === "all" && selectedWorkOrderType !== "all") {
        setSuspendedWorkOrders(
          allSuspended.filter(
            (workOrder) =>
              workOrder.work_order_type_id === selectedWorkOrderType
          )
        );
      } else if (activeOrg !== "all" && selectedWorkOrderType === "all") {
        setSuspendedWorkOrders(
          allSuspended.filter(
            (workOrder) => workOrder.organization_id === activeOrg
          )
        );
      } else {
        const newWorkOrders = allSuspended.filter(
          (workOrder) =>
            workOrder.work_order_type_id === selectedWorkOrderType &&
            workOrder.organization_id === activeOrg
        );

        setSuspendedWorkOrders(newWorkOrders);
      }
    }
  }, [
    workOrders,
    activeOrg,
    organizations,
    allSuspended,
    selectedWorkOrderType,
  ]);

  /**
   * Handles updating the ready state
   */
  useEffect(() => {
    if (!ready && workOrders.length > 0) setReady(true);
  }, [ready, workOrders]);

  /**
   * Updates the active organization and the organizations list
   */
  useEffect(() => {
    const { organization, organizations, settings } = user;

    if (organization) setActiveOrg(organization.id);
    if (organizations) {
      const list = [
        {
          name: t("all"),
          id: "all",
        },
        ...organizations,
      ];
      setOrganizations(list);
    }

    if (settings) {
      const workorderTypes = [
        {
          name: t("all"),
          id: "all",
        },
        ...settings.account.work_order_types.map((woType) => ({
          name: woType.name,
          id: woType.id,
        })),
      ];

      setWorkOrderTypes(workorderTypes);
      setSelectedWorkOrderType(workorderTypes[0].id);

      if (settings.account && settings.account.id === 3) {
        setShowWidgets(true);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setWorkOrderID(0);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEdit();
    // eslint-disable-next-line
  }, [editData]);

  useEffect(() => {
    if (showWidgets) getWidgets();
    // eslint-disable-next-line
  }, [showWidgets]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<GraphicEqOutlinedIcon />}
        title={t("titleControlPanel")}
      />
      <Title />
      <SubmoduleWrapper {...wrapper}>
        <LoadingBackdrop
          {...loadingBackdrop}
          open={!ready || backdropLoading}
        />
        {showWidgets ? (
          <Widgets {...widgetsProps} widgets={widgets} ready={ready} />
        ) : null}
        <Zoom
          in={ready && user.type === "admin"}
          timeout={transitionTimeout}
          mountOnEnter
          unmountOnExit
        >
          <Grid container item xs={12} md={8} spacing={2}>
            <Grid item xs={12} md={3}>
              <Input
                {...input}
                className={classes.selectField}
                type="select"
                inputSelect={{
                  value: activeOrg,
                  onChange: handleOrganizationChange,
                  label: t("organization_idLabel"),
                  labelClass: classes.label,
                  variant: "outlined",
                  options: organizations,
                  optionLabel: "name",
                  optionValue: "id",
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "selectedWorkOrderType",
                  value: selectedWorkOrderType,
                  onChange: handleWorkOrderTypeChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("work_order_typeLabel"),
                  options: workOrderTypes,
                  optionLabel: "name",
                  optionValue: "id",
                }}
              />
            </Grid>
          </Grid>
        </Zoom>
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <PrintWorkOrder
              {...printWorkOrder}
              mode="print"
              setLoading={setLoading}
              workOrderID={workOrderID}
              setReadyToPrint={setReadyToPrint}
            />
          </div>
        </div>
        <Grid container justify="center">
          <Zoom in={ready} timeout={transitionTimeout}>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <TyreServiceWorkOrdersTable
                  {...workOrdersTable}
                  data={finishedWorkOrders}
                  title={t("doneWorkOrders")}
                  activeOrg={activeOrg}
                  type="daily"
                  toggleStatus={handleStatusToggling}
                  organizations={organizations}
                  workOrderID={workOrderID}
                  loading={loading}
                  printComponent={printComponent}
                  handleEdit={handleEdit}
                  setUpdated={setUpdated}
                />
              </Grid>
            </Grid>
          </Zoom>
        </Grid>
        <Grid container justify="center">
          <Zoom
            in={ready && user.type !== "worker"}
            mountOnEnter
            unmountOnExit
            timeout={transitionTimeout}
          >
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <TyreServiceWorkOrdersTable
                  {...workOrdersTable}
                  data={suspendedWorkOrders}
                  title={t("waitingWorkOrders")}
                  activeOrg={activeOrg}
                  type="suspended"
                  toggleStatus={handleStatusToggling}
                  handleEdit={handleEdit}
                  preventScrollTopOnSearch={true}
                  setUpdated={setUpdated}
                />
              </Grid>
            </Grid>
          </Zoom>
        </Grid>
        <EditWorkOrderModal
          {...editModal}
          open={editModalState}
          closeModal={closeEdit}
          data={editData}
          setEditData={setEditData}
          setUpdated={setUpdated}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

TyreServiceControlPanel.propTypes = propTypes;
TyreServiceControlPanel.defaultProps = defaultProps;

export default TyreServiceControlPanel;
export {
  propTypes as TyreServiceControlPanelPropTypes,
  defaultProps as TyreServiceControlPanelDefaultProps,
};

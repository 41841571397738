import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ConfirmUpgradeModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  loadingText: LoadingTextDefaultProps,
  button: ButtonDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  open: true,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const ConfirmUpgradeModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    setRenew,
    button,
    errorMessages,
    summary,
    data,
    loadingText,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Handles creating an order
   */
  const createOrder = async (data, type) => {
    try {
      const createdOrder = await apiClient.post(
        `/accounts/orders/${type}`,
        data
      );
      if (createdOrder) {
        dispatchMessage({
          icon: false,
          delay: 500,
          message: t("successMessage"),
        });

        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);
        setRenew(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    closeModal();
  };

  /**
   * Handles the submit
   */
  const handleSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   * Defines the label component
   */
  const TableLabel = (props) => {
    const { value } = props;

    return (
      <Grid item xs={6} md={4} className={classes.labelContainer}>
        <Typography variant="caption" className={classes.label}>
          {value}
        </Typography>
      </Grid>
    );
  };

  /**
   * Defines the value component
   */
  const TableValue = (props) => {
    const { value } = props;

    return (
      <Grid item xs={6} md={8} className={classes.valueContainer}>
        <Typography variant="caption" className={classes.value}>
          {value}
        </Typography>
      </Grid>
    );
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      createOrder(data, "real");
    }
    // eslint-disable-next-line
  }, [ready]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleConfirmOrder")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} className={classes.form}>
                <Card className={classes.blank}>
                  <CardContent>
                    <Grid container justify="center">
                      <Grid item container xs={12} className={classes.price}>
                        <TableLabel value={t("durationLabel")} />
                        <TableValue
                          value={`${summary.serviceDuration} ${t("days")}`}
                        />
                        <TableLabel value={t("subtotal")} />
                        <TableValue value={`${summary.subtotal} EUR`} />
                        <TableLabel value={t("discount")} />
                        <TableValue value={`${summary.discount} EUR`} />
                        {/* <TableLabel value={t("tax")} />
                        <TableValue value={`${summary.tax} EUR`} /> */}
                        <TableLabel value={t("total")} />
                        <TableValue value={`${summary.total} EUR`} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitConfirmOrder")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

ConfirmUpgradeModal.propTypes = propTypes;
ConfirmUpgradeModal.defaultProps = defaultProps;

export default ConfirmUpgradeModal;
export {
  propTypes as ConfirmUpgradeModalPropTypes,
  defaultProps as ConfirmUpgradeModalDefaultProps,
};

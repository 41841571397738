/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  modalContent: {
    maxHeight: 660,
  },
  modulesContainer: {
    margin: "0.5rem 0",
  },
  submitBtn: theme.mixins.deleteBtn,
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem",
    },
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  label: {
    fontFamily: theme.font.display,
    color: theme.palette.secondary.main,
    fontWeight: 800,
    fontSize: "0.95rem",
    marginBottom: "0.25rem",
    display: "inline",
    wordBreak: "break-all",
  },
  value: {
    fontFamily: theme.font.display,
    color: theme.palette.error.main,
    fontWeight: 800,
    fontSize: "0.95rem",
    marginBottom: 0,
    paddingLeft: "0.35rem",
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
  },
}));

export { useStyles };

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import ReportsAdvancedSearch, {
  ReportsAdvancedSearchDefaultProps,
  ReportsAdvancedSearchPropTypes,
} from "../ReportsAdvancedSearch";
import ReportsAdvancedTable, {
  ReportsAdvancedTableDefaultProps,
  ReportsAdvancedTablePropTypes,
} from "../ReportsAdvancedTable";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ReportsAdvanced.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  search: PropTypes.shape(ReportsAdvancedSearchPropTypes),
  table: PropTypes.shape(ReportsAdvancedTablePropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
  dateFormat: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  search: ReportsAdvancedSearchDefaultProps,
  table: ReportsAdvancedTableDefaultProps,
  button: ButtonDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: "/advanced",
  dateFormat: "yyyy-MM-dd HH:mm",
};

/**
 * Displays the component
 */
const ReportsAdvanced = (props) => {
  const {
    title,
    wrapper,
    container,
    search,
    table,
    button,
    loadingBackdrop,
    dateFormat,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the print loading state
   */
  const [printLoading, setPrintLoading] = useState(false);

  /**
   * Initializes the data
   */
  const [data, setData] = useState({});

  /**
   * Initalizes the items
   */
   const [items, setItems] = useState([]);

  /**
   * Initializes the divide by date flag
   */
   const [divideByDate, setDivideByDate] = useState(true);

  /**
   * Initializes the init api call flag
   */
  const [apiCallMade, setApiCallMade] = useState(false);

  /**
   * Initializes the not found flag
   */
  const [notFound, setNotFound] = useState(false);

  /**
   * Gets the user
   */
  const { user } = useUser();

  /**
   * Initializes the clients list
   */
  const [clientsList, setClientsList] = useState([]);

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  /**
   * Initializes the component ref, used for printing the component
   */
  const componentRef = useRef();

  /**
   * Initializes the flag that will mount the print component
   */
  const [mountPrint, setMountPrint] = useState(false);

  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Defines the handle print function
   */
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleChange = (event) => {
    setDivideByDate(event.target.checked);
  };

  /**
   * Handles formatting the number
   * @param {String} value
   */
  const formatNumber = (value) => `${value.toFixed(2)} RON`;

  /**
   * Initializes the time interval
   */
  const [timeInterval, setTimeInterval] = useState({
    start: new Date(),
    end: new Date(),
  });

  /**
   * Handles getting the start date
   */
  const getStartDate = (startDate) => {
    const date = new Date(startDate).setHours(0, 0, 0);

    const newDate = new Date(date);

    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    const d = newDate.getDate();

    const start = new Date(y, m, d).setHours(0, 0, 0);

    const a = formatDate(new Date(start), dateFormat);
    return formatDate(new Date(a), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles getting the end date
   */
  const getEndDate = (endDate) => {
    const date = new Date(endDate).setHours(0, 0, 0);
    const newDate = new Date(date);
    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    const d = newDate.getDate();
    const end = new Date(y, m, d).setHours(23, 59, 59);

    const b = formatDate(new Date(end), dateFormat);
    return formatDate(new Date(b), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles initiating the printing
   */
  const printComponent = () => {
    setMountPrint(true);
    setPrintLoading(true);
  };

  /**
   * Handles getting the print button content
   */
  const getPrintButtonContent = () =>
    printLoading ? (
      <CircularProgress size="1.45rem" className={classes.loader} />
    ) : (
      <PrintIcon className={classes.icon} />
    );

  /**
   * Defines the Print Button component
   */
  const PrintButton = () => {
    return (
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.printButton}
        title={t("printReport")}
        onClick={printComponent}
      >
        {getPrintButtonContent()}
      </Button>
    );
  };

  /**
   * Defines the Flex Center Component
   */
  const FlexCenter = ({ children }) => (
    <div className={classes.flexCenter}>{children}</div>
  );

  /**
   * Defines the Flex End Component
   */
  const FlexEnd = ({ children }) => (
    <div className={classes.flexEnd}>{children}</div>
  );

  /**
   * Defines the Flex Column Component
   */
  const FlexColumn = ({ children }) => (
    <div className={classes.flexColumn}>{children}</div>
  );

  /**
   * Defines the Container Component
   */
  const Container = ({ children }) => (
    <div className={classes.container}>{children}</div>
  );

  /**
   * Defines the Label Component
   */
  const Label = (props) => {
    const { value } = props;
    return <span className={classes.label}>{value}</span>;
  };

  /**
   * Defines the Value Component
   */
  const Value = (props) => {
    const { value } = props;

    return <span className={classes.value}>{value}</span>;
  };

  /**
   * Defines the Report Data Component
   */
  const ReportData = () => {
    if (data.report_data) {
      const { report_data } = data;
      const { total, discount, subtotal, new_tyre_quantity } = report_data;

      return (
        <Container>
          <FlexCenter>
            <Label value={t("new_tyres")} />
            <Value value={new_tyre_quantity} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("subtotal")} />
            <Value value={formatNumber(subtotal)} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("discount")} />
            <Value value={formatNumber(discount)} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("total")} />
            <Value value={formatNumber(total)} />
          </FlexCenter>
        </Container>
      );
    }
    return null;
  };

  /**
   * Defines the Report Title Component
   */
  const ReportTitle = () => {
    return (
      <FlexColumn>
        <Typography variant="caption" className={classes.title}>
          {getReportTitle()}
        </Typography>
        <FlexCenter>
          <Label value={t("from")} />
          <Value value={getStartDate(timeInterval.start)} />
          <Label value="/" />
          <Label value={t("to")} />
          <Value value={getEndDate(timeInterval.end)} />
        </FlexCenter>
      </FlexColumn>
    );
  };

  /**
   * Defines the Report Payment Types Data Component
   */
  const ReportPaymentTypesData = () => {
    if (data.report_data && data.report_data.payment_type_sum) {
      const paymentTypes = Object.entries(data.report_data.payment_type_sum);

      return (
        <Container>
          {paymentTypes.map((entry) => {
            const label = entry[0];
            const values = entry[1];

            const { total } = values;

            return (
              <FlexEnd>
                <Label value={`${label}:`} />
                <Value value={formatNumber(total)} />
              </FlexEnd>
            );
          })}
        </Container>
      );
    }
    return null;
  };

  const DataNotFound = () => {
    return notFound ? (
      <Grid container>
        <Typography variant="h2" className={classes.notFoundText}>
          {t("notFound")}
        </Typography>
      </Grid>
    ) : null;
  };

  const updateInputs = (inputs) => {
    if (typeof inputs.finished === "string") {
      const interval = inputs.finished.split(" - ");
      setTimeInterval({
        start: new Date(interval[0]),
        end: new Date(interval[1]),
      });
    } else {
      setTimeInterval({
        start: new Date(inputs.finished[0]),
        end: new Date(inputs.finished[1]),
      });
    }
  };

  const getUserOrganizationByID = (id) => {
    if (user.organizations) {
      const found = user.organizations.find((org) => org.id === id);
      const name = found ? found.name : false;
      const companyBase = user.account ? user.account.name : "";
      if (!name) return "";
      return name.replace(`${companyBase} - `, "");
    }
  };

  const getReportTitle = () => {
    if (activeOrg === "all") return t("totalTitle");
    const activeOrgName = getUserOrganizationByID(activeOrg);
    return `${t("titleDetailedReport")} - ${activeOrgName}`;
  };

  useEffect(() => {
    if (data.items && data.items.length < 1 && apiCallMade) {
      setNotFound(true);
    } else if (data.items && data.items.length > 0 && apiCallMade) {
      setNotFound(false);
    }
  }, [data, apiCallMade]);

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setPrintLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Resets the loading state
   */
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let items = data.items;

      if(divideByDate) {
        const dataItems = [];
        let count = 0;
  
        data.items.forEach((item, index, items) => {
          if(index !== items.length - 1){
  
            const currentItemDate = new Date(item.finished);
            const currentItemDateDay = currentItemDate.getDay();
  
            const nextItemDate = new Date(items[index +1].finished);
            const nextItemDateDay = nextItemDate.getDay();
  
            if(index === 1){
              count = 1;
            }
  
            count = count + 1;
  
  
            if(currentItemDateDay !== nextItemDateDay){
              dataItems.push({...item, count });
              dataItems.push({...item, isDivider: true, nextFinished: nextItemDate, count })
            } else {
              dataItems.push({...item, count });
            } 
          }   else {
            dataItems.push({...item, count });
          } 
        })
        
        items = dataItems;
      }
      setItems(items);
    }
  },[data, divideByDate])

  /**
   * Handles triggering the printing
   */
  useEffect(() => {
    if (printLoading) {
      setTimeout(() => {
        setReadyToPrint(true);
      }, [1000]);
    }
  }, [printLoading]);

  useEffect(() => {
    if (user.clients) {
      setClientsList(user.clients);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<RecentActorsOutlinedIcon />}
        title={t("titleDetailedReport")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12}>
            {clientsList.length > 0 && (
              <ReportsAdvancedSearch
                {...search}
                setData={setData}
                clientsList={clientsList}
                apiCallMade={apiCallMade}
                setApiCallMade={setApiCallMade}
                setBackdropLoading={setLoading}
                setActiveOrg={setActiveOrg}
                updateInputs={updateInputs}
              />
            )}
          </Grid>
          {mountPrint && (
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                <Grid container className={classes.printContainer}>
                  <Grid item container xs={12}>
                    <Grid item xs={3}>
                      <ReportData />
                    </Grid>
                    <Grid item xs={6}>
                      <ReportTitle />
                    </Grid>
                    <Grid item xs={3}>
                      <ReportPaymentTypesData />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <ReportsAdvancedTable
                      {...table}
                      isPrinting={true}
                      data={data.items}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          {data.items && data.items.length > 0 && (
            <Grid container className={classes.mainContainer}>
              <Grid item container xs={12}>
                <Grid item xs={3}>
                  <PrintButton />
                  <ReportData />
                </Grid>
                <Grid item xs={6}>
                  <ReportTitle />
                </Grid>
                <Grid item xs={3}>
                  <ReportPaymentTypesData />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={divideByDate}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={t("GroupByDay")}
              />
                <ReportsAdvancedTable {...table} data={items} />
              </Grid>
            </Grid>
          )}

          <DataNotFound />
          <LoadingBackdrop {...loadingBackdrop} open={loading} />
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

ReportsAdvanced.propTypes = propTypes;
ReportsAdvanced.defaultProps = defaultProps;

export default ReportsAdvanced;
export {
  propTypes as ReportsAdvancedPropTypes,
  defaultProps as ReportsAdvancedDefaultProps,
};

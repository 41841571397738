import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import Form from "../Form";
import Input from "../Input";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useMessage, useApiClient, useForm } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./DeleteOrganizationModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteData: PropTypes.object,
  defaultValues: PropTypes.shape({
    confirm_text: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  open: false,
  closeModal: () => {},
  deleteData: {},
  defaultValues: {
    confirm_text: "",
  },
};

/**
 * Displays the component
 */
const DeleteOrganizationModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    deleteData,
    setDeleteData,
    button,
    errorMessages,
    loadingText,
    defaultValues,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user setter
   */
  const { setUser } = useUser();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);

  /**
   * Gets the org name and id
   */
  const { id, name } = deleteData;

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles deleting a user by id
   */
  const deleteOrganization = async (id) => {
    try {
      const deletedGroup = await apiClient.delete(`/organizations/${id}`);
      if (deletedGroup) {
        getUserOrganizations();
      }
    } catch (error) {
      setLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles getting the user organizations
   */
  const getUserOrganizations = async () => {
    try {
      const organizations = await apiClient.get("/organizations");
      if (organizations) {
        setLoading(false);
        resetInputs();

        /**
         * Dispatch the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("deleteSuccessMessage"),
        });

        setUser((prevState) => {
          return { ...prevState, organizations: organizations.data };
        });
        handleCloseModal();
      }
    } catch (error) {
      setLoading(false);
      /**
       * Dispatch the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles the submit
   */
  const handleDelete = () => {
    setLoading(true);
    deleteOrganization(id);
  };

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    handleDelete();
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const { inputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { confirm_text } = inputs;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setDeleteData({});
    closeModal();
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle {...modalTitle} onClick={handleCloseModal} />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              id={id}
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Card className={classes.blank}>
                    <CardContent className={classes.cardContent}>
                      <Grid container>
                        <Grid item container xs={12}>
                          <Typography className={classes.label}>
                            {t("deleteConfirmationText")}
                            <Typography
                              variant="caption"
                              className={classes.value}
                            >
                              {` ${name}`}
                            </Typography>
                            {` ?`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} justify="center">
                        <Input
                          type="text"
                          className={classes.field}
                          inputText={{
                            id: "confirm_text",
                            required: true,
                            name: "confirm_text",
                            value: confirm_text,
                            onChange: handleInputChange,
                            variant: "standard",
                            label: t("confirm_textLabel"),
                            placeholder: name,
                          }}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            className={classes.submitBtn}
            onClick={handleSubmit}
            disabled={confirm_text !== name}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("deleteConfirm")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

DeleteOrganizationModal.propTypes = propTypes;
DeleteOrganizationModal.defaultProps = defaultProps;

export default DeleteOrganizationModal;
export {
  propTypes as DeleteOrganizationModalPropTypes,
  defaultProps as DeleteOrganizationModalDefaultProps,
};

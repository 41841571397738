const quickTextModel = {
  label: "Quick Text",
  field: "quick_text",
  type: "like",
  order: 1,
  options: [],
  selected: null
};

const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null
};

const nameModel = {
  label: "Name",
  field: "name",
  type: "like",
  order: 3,
  options: [],
  selected: null
};

const carTypeModel = {
  label: "Car Type",
  field: "car_type_id",
  type: "dropdown",
  order: 5,
  options: [],
  selected: null
};

const workOrderTypeModel = {
  label: "Work Order Type",
  field: "work_order_type_id",
  type: "dropdown",
  order: 6,
  options: [],
  selected: null
};

const serviceModel = {
  label: "Is Service",
  field: "is_service",
  type: "boolean",
  order: 5,
  options: [],
  selected: null
};

const expendableModel = {
  label: "Is Expendable",
  field: "is_expendable",
  type: "boolean",
  order: 6,
  options: [],
  selected: null
};

const countPriceModel = {
  label: "Is Count Price",
  field: "is_count_price",
  type: "boolean",
  order: 7,
  options: [],
  selected: null
};

const umModel = {
  label: "Um",
  field: "um",
  type: "equal",
  order: 9,
  options: [],
  selected: null
};

const priceModel = {
  label: "Price",
  field: "price",
  type: "equal",
  order: 10,
  options: [],
  selected: null
};

const defaults = {
  models: {
    quickTextModel,
    carTypeModel,
    workOrderTypeModel,
    organizationIdModel,
    nameModel,
    serviceModel,
    expendableModel,
    countPriceModel,
    umModel,
    priceModel
  },
  searchParams: {
    models: [
      quickTextModel,
      carTypeModel,
      workOrderTypeModel,
      organizationIdModel,
      nameModel,
      serviceModel,
      expendableModel,
      countPriceModel,
      umModel,
      priceModel
    ],
    order_by: "price",
    order_dir: "asc",
    page_size: Number(localStorage.getItem("productsViewPageSize")) || 15
  },
  orderBy: ["price", "name"],
  pageSizeOptions: [10, 15, 30, 50, 100]
};

export { defaults };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 280,
    overflow: "auto",
    padding: "0 15px"
  },
  onlyOne: {
    overflow: "initial"
  },
  sectionTitle: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 10,
    borderBottom: `1px solid #e0e0e0`,
    display: "flex",
    alignItems: "center",
    margin: "0.75rem 0",
  },
  badge: {
    top: 10,
    right: -15,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main
  },
  saveBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    marginLeft: 30,
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

import PropTypes from "prop-types";

/**
 * External Imports
 * mergeDeep - Returns a copy of the collection with the remaining collections merged in deeply (recursively).
 * fromJS - Deeply converts plain JS objects and arrays to Immutable Maps and Lists.
 * @see https://immutable-js.github.io/immutable-js/docs
 */
import { fromJS, mergeDeep } from "immutable";

/**
 * Hooks
 */
import { useData } from "../index";

/**
 * Defines the prop types
 */
const propTypes = {
  apiKey: PropTypes.shape({
    local: PropTypes.string,
    production: PropTypes.string,
  }),
  watch: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  apiKey: {
    // local: "http://localhost:3000/api/v1", // with proxy
    local: "http://127.0.0.1:8000/api/v1",
    // production: `${window.location.origin.toString()}/api/v1`,
    // for ClausWeb api
    production: "https://api-staging.atelierulmeu.ro/api/v1"
    // for gcloud api
    // production: "https://atelierulmeu-v2-api.ey.r.appspot.com/api/v1"
  },
  watch: false,
};

/**
 * Checks if the response is an error
 */
const isApiError = (data) => {
  if (!data.errorMessage) return false;
  if (data.successMessage) return false;

  if (typeof data.errorMessage === "object") {
    if (Object.keys(data.errorMessage).length > 0) {
      return true;
    }
    return false;
  }
  if (typeof data.errorMessage === "string") {
    if (data.errorMessage.length > 0) return true;
    return false;
  }
};

/**
 * Returns the error message from the response
 */
const getApiErrorMessage = (data) => {
  return data && data.errorMessage !== "" ? data.errorMessage : null;
};

/**
 * Returns the token from the response
 */
const getApiToken = (data) => (data && data.token ? data.token : null);

/**
 * Checks if the API call was made.
 *
 * - Sometimes the underlying data library doesn't makes the API call.
 * - Maybe because of caching, or other errors
 */
const wasApiCallMade = (data) => {
  return data !== undefined;
};

/**
 * Returns the authorization header for protected resources
 */
const getAuthHeader = (token) => {
  if (!token) return;
  return { Authorization: `Bearer ${token}` };
};

/**
 * Returns the API call status and a message
 */
const getAPICallStatus = (data) => {
  if (data === "Failed to fetch") {
    return {
      successful: false,
      error: "server_down",
    };
  }

  if (!data) {
    return {
      successful: false,
      error: "",
    };
  }
  if (data.success) {
    return {
      successful: true,
      error: "",
    };
  }
  if (data.successMessage && data.successMessage.length > 0) {
    return {
      successful: true,
      error: "",
    };
  } else if (data.type !== "application/pdf" && Object.keys(data).length < 1) {
    return {
      successful: false,
      error: "",
    };
  } else if (!wasApiCallMade(data)) {
    return {
      successful: false,
      error: "",
    };
  } else if (isApiError(data)) {
    return {
      successful: false,
      error: getApiErrorMessage(data),
    };
  } else {
    return {
      successful: true,
      error: "",
    };
  }
};

/**
 * Deep merges various API props to form the final params
 *
 * - requestProps - the request specific props, usually defined in the caller component's PropTypes
 * - requestLiveProps - the request specific props which, defined inside the component
 */
const mergeAPIParams = (props) => {
  const { requestProps, requestLiveProps } = props;

  return mergeDeep(
    fromJS(defaultProps),
    fromJS(requestProps),
    fromJS(requestLiveProps)
  ).toJS();
};

/**
 * A general fetcher function
 *
 * - Both `SWR` and `react-async` are built on `fetch`
 */
const fetcher = async ({ props }) => {
  try {
    const { token, request, contentType } = props;

    const { apiKey } = defaultProps;

    /**
     * Builds the headers based on content type provided
     */
    const buildHeaders = (contentType) => {
      switch (contentType) {
        case undefined:
        case "json":
          return {
            "Content-Type": "application/json; charset=utf-8",
          };
        default:
          return getAuthHeader(token);
      }
    };

    /**
     * Gets the request body and decides if it needs to be converted to json.
     */
    const getRequestBody = (data, contentType) => {
      if (contentType === "formData") return data;
      return JSON.stringify(data);
    };

    /**
     * Constructs the url for the request
     */
    const base =
      process.env.NODE_ENV === "development" ? apiKey.local : apiKey.production;
    const url = `${base}${request.url}`;

    /**
     * Constructs the body for the request
     */
    const data = request.data;
    const body = getRequestBody(data, contentType);

    /**
     * Constructs the headers for the request
     */
    const headers = {
      ...buildHeaders(contentType),
      ...request.headers,
    };

    if (token) headers["Authorization"] = `Bearer ${token}`;

    /**
     * Construct the method for the request
     * by default it will be a GET request
     */
    const method = request.method ? request.method : "GET";

    /**
     * Builds the options object with all relevant data for the request.
     */
    let options = { method, headers, body, ...request };

    const response = await fetch(url, options);

    /**
     * Handles the response
     */
    const handleResponse = () => {
      switch (contentType) {
        case "blob":
          return response.blob();
        default:
          return response.json();
      }
    };

    // if (response.status === 500) return response;
    return await handleResponse();
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

/**
 * Displays the component
 */
const useAPI = (props) => {
  const { token, request, initialData, watch, contentType } = props;

  /**
   * Bundles the promise function params
   */
  const promiseFnParams = {
    props: {
      token,
      request,
      contentType,
    },
  };

  const { data, error } = useData({
    options: {
      promiseFn: request ? fetcher : null,
      promiseFnParams,
      initialValue: initialData,
      watch: watch ? request : null,
    },
  });

  return { data, error };
};

useAPI.propTypes = propTypes;
useAPI.defaultProps = defaultProps;

export default useAPI;
export {
  isApiError,
  getApiErrorMessage,
  getApiToken,
  mergeAPIParams,
  getAPICallStatus,
  getAuthHeader,
  propTypes as useAPIPropTypes,
  defaultProps as useAPIDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 780,
    padding: 0,
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
  content: {
    padding: 4,
  },

  appbar: {
    boxShadow: "none",
    border: "none",
    background: "transparent",
    maxWidth: "inherit",
    width: "inherit",
    display: "flex",
    justifyContent: "center",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      display: "flex",
    },
    "& .MuiTab-wrapper": {
      border: `1px solid #ded6d6`,
      padding: "0.5rem 1rem",
      background: theme.palette.sidebar.main,
      textTransform: "none",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: theme.palette.common.white,
      "& .MuiTab-wrapper": {
        background: theme.palette.primary.main,
        // border: `1px solid ${theme.palette.primary.accent}`,
      },
    },
  },
  indicator: {
    display: "none",
  },
  //
  stepCard: {
    boxShadow: "none",
  },
  cardContent: {
    paddingBottom: 0,
  },
  selectField: theme.mixins.searchSelect,
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  field: theme.mixins.searchField,
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: "0.5rem",
    border: `1px solid #cac2c2`,
    background: "#f3f3f3",
    marginBottom: "1rem",
  },
  timerBox: {
    fontSize: "3.5rem",
    fontWeight: 800,
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  actions: {
    padding: "0.25rem",
    display: "flex",
    justifyContent: "center",
  },
  timerBtn: {
    margin: "0.15rem",
    borderRadius: 3,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    boxShadow: "none",
    minWidth: 0,
    transform: "scale(1.3)",
    marginLeft: "2rem",
  },
  timerOn: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  stopTimer: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
  pauseTimer: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  resetTimer: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
  helperText: {
    fontFamily: theme.font.family,
    fontSize: "0.9rem",
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  cardActions: {
    paddingTop: 0,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  saveBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    height: 30,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  nextIcon: {
    marginLeft: "0.25rem",
  },
  closeBtn: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    minWidth: 125,
    borderRadius: 0,
    boxShadow: "none",
    fontWeight: 400,
    height: 30,
    "&:hover": {
      background: theme.palette.error.dark,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Hooks
 */
import { useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./DynamicSearchForm.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  apiCallSetter: PropTypes.func,
  apiCallProps: PropTypes.object,
  setBackdropLoading: PropTypes.func,
  setApiCallMade: PropTypes.func,
  resetFilters: PropTypes.bool,
  setResetFilters: PropTypes.func,
  setData: PropTypes.func,
  updateInputs: PropTypes.func,
  options: PropTypes.object,
  preventDefaultSearch: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  apiCallSetter: () => {},
  apiCallProps: {},
  setBackdropLoading: () => {},
  resetFilters: false,
  setResetFilters: () => {},
  setApiCallMade: () => {},
  setData: () => {},
  updateInputs: () => {},
  searchOptions: {
    separateContainers: true,
  },
  preventDefaultSearch: false,
};

/**
 * Displays the component
 */
const DynamicSearchForm = (props) => {
  const {
    searchOptions,
    formOptions,
    models,
    button,
    input: inputProps,
    form,
    loadingText,
    apiCallProps,
    setBackdropLoading,
    resetFilters,
    setResetFilters,
    setData, 
    setApiCallMade,
    updateInputs,
    preventDefaultSearch,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the table options
   */
  const {
    defaultValues,
    grid,
    separateContainers,
    gridConfig,
    submitBtnText,
    cleanModels,
    watch,
    updateState,
  } = searchOptions;

  /**
   * Gets the api client
   */
   const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the prevent default flag
   */
  const [preventDefault, setPreventDefault] = useState(preventDefaultSearch);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  const [callMade, setCallMade] = useState(false);

  /**
   * Handles logging a user in
   */
   const generateReport = async (data, type, format) => {
    try {
      const result = await apiClient.post(`/reports/${type}/${format}`, data);

      if(result && result.data){
        const { data } = result;

        /**
         * Resets the states
         */
        updateInputs(inputs);
        setBackdropLoading(false);
        setLoading(false);
        setData(data);
        setApiCallMade(true);
      }
    } catch (error) {
     /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);
      setApiCallMade(true);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        severity: "error",
        message: error.message,
      });
      updateInputs(inputs);
    }
  };

  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];
    const defaultModels = models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      if (!model || Object.keys(model).length < 1) return;
      const modelItem = { ...model };

      modelItem["selected"] = input;
      searchModels.push(modelItem);
    };

    Object.entries(inputs).forEach((entry) => {
      if (entry[1]) {
        if (modelExists(entry[0])) {
          removeModel(entry[0]);
          addModel(entry[1], defaultModels[entry[0]]);
        } else {
          addModel(entry[1], defaultModels[entry[0]]);
        }
      }
    });

    return searchModels.length < 1 ? defaultModels : searchModels;
  };

  /**
   * Handles the submit
   */
  const onSubmit = async (inputs) => {
    setLoading(true);
    setCallMade(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: inputs.order_by,
      order_dir: inputs.order_dir,
    };

    if (cleanModels) {
      data["models"] = cleanModels(data.models);
    }

    const { type, format } = apiCallProps;
    await generateReport(data, type, format)
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const {
    inputs,
    resetInputs,
    setInputs,
    handleSubmit,
    handleInputChange,
    handleDateChange,
  } = useForm(useFormConfig);

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const data = {
      models: !Array.isArray(models)
        ? Object.entries(models).map((entry) => {
            return { ...entry[1] };
          })
        : models,
      order_by: defaultValues.order_by,
      order_dir: defaultValues.order_dir,
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = async () => {
    const { type, format } = apiCallProps;
    await generateReport(getDefaultSearch(), type, format)
  };

  /**
   * Handles resetting the filters
   */
  const handleFiltersReset = () => {
    resetInputs();
    if (setBackdropLoading) setBackdropLoading(true);
    handleDefaultSearch();
  };

  useEffect(() => {
    if (!preventDefault) {
      handleDefaultSearch();
    }
    // eslint-disable-next-line
  }, [watch]);

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false);
      handleFiltersReset();
    }
    // eslint-disable-next-line
  }, [resetFilters]);

  useEffect(() => {
    resetInputs();
    // eslint-disable-next-line
  }, [watch]);

  useEffect(() => {
    setTimeout(() => {
      setPreventDefault(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (updateState && updateState.key) {
      updateState.fn(inputs[updateState.key]);
      if (callMade) setCallMade(false);
    }
    // eslint-disable-next-line
  }, [updateState, callMade]);

  const getInputConfig = (input_name) => {
    const found =
      formOptions &&
      formOptions.length > 0 &&
      formOptions.find((option) => option.name === input_name);
  
    return found ? found : {};
  };

  const getInputTypePropName = (inputType) => {
    switch (inputType) {
      case "text":
        return "inputText";
      case "password":
        return "inputPassword";
      case "checkbox":
        return "inputCheckbox";
      case "select":
        return "inputSelect";
      case "textarea":
        return "inputTextarea";
      case "date":
        return "inputDate";
      case "phone":
        return "inputPhone";
      case "search":
        return "inputSearch";
      case "postCode":
        return "inputPostCode";
      case "dateRange":
        return "inputDateRange";
      case "numeric":
        return "inputNumeric";
      case "autocomplete":
        return "inputAutocomplete";
      default:
        return "inputText";
    }
  };

  const getInputValue = (name) => {
    return inputs[name];
  };

  const getInputChangeHandler = (config, input) => {
    /**
     * Handles updating autoComplete inputs
     */
    const handleAutoCompleteChange = (event, newValue) => {
      setInputs((inputs) => ({
        ...inputs,
        [input]: newValue,
      }));
    };

    switch (config.inputType) {
      case "text":
        return handleInputChange;
      case "date":
      case "dateRange":
        return handleDateChange;
      case "autocomplete":
        return handleAutoCompleteChange;
      default:
        return handleInputChange;
    }
  };

  const buildInputProps = (config, input) => {
    let props = {
      type: config.inputType,
      className:
        config.inputType === "select" ? classes.selectField : classes.field,
    };
    const inputTypePropName = getInputTypePropName(config.inputType);
    let inputData = {
      name: input,
      onChange: getInputChangeHandler(config, input),
      label: config.label,
      maxSize: 70,
      variant: "outlined",
      labelClass: classes.label,
      InputLabelProps: {
        className: classes.label,
      },
      showHelper: false,
      ...config.props,
    };

    if (config.inputType === "autocomplete") {
      inputData["inputValue"] = getInputValue(input);
    } else {
      inputData["value"] = getInputValue(input);
    }
    props[inputTypePropName] = inputData;
    return props;
  };

  const renderDynamicInputs = () => {
    const inputsArray = Object.keys(defaultValues);
    return inputsArray.map((input, idx) => {
      const config = getInputConfig(input);
      const inputBuiltProps = buildInputProps(config, input);

      return config.hidden ? null : (
        <Grid key={idx} item {...config.grid}>
          <Input {...inputProps} {...inputBuiltProps} />
        </Grid>
      );
    });
  };

  return (
    <Form {...form} id="search-user" onSubmit={handleSubmit}>
      <Grid
        container
        justify={gridConfig.justify ? gridConfig.justify : "center"}
      >
        <Grid item {...grid.form}>
          <Grid container spacing={1} className={classes.spacing}>
            {renderDynamicInputs()}
            {!separateContainers && (
              <Grid
                item
                {...gridConfig.actionsContainer}
                container
                justify="center"
                direction="column"
              >
                <div className={classes.btnContainer}>
                  <Button
                    {...button}
                    type="button"
                    variant="filled"
                    title={t("undo")}
                    onClick={handleFiltersReset}
                    className={classes.undoBtn}
                  >
                    <UndoIcon />
                  </Button>
                  <Button
                    {...button}
                    type="submit"
                    variant="filled"
                    className={classes.submitBtn}
                  >
                    <LoadingText
                      {...loadingText}
                      size={22}
                      loading={loading}
                      text={submitBtnText}
                      icon={<SearchIcon />}
                    />
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        {separateContainers && (
          <Grid item {...grid.actions}>
            <Grid container spacing={1} wrap={"nowrap"}>
              <Grid item xs={12} container justify="center" direction="column">
                <div className={classes.btnContainer}>
                  <Button
                    {...button}
                    type="button"
                    variant="filled"
                    title={t("undo")}
                    onClick={handleFiltersReset}
                    className={classes.undoBtn}
                  >
                    <UndoIcon />
                  </Button>
                  <Button
                    {...button}
                    type="submit"
                    variant="filled"
                    className={classes.submitBtn}
                  >
                    <LoadingText
                      {...loadingText}
                      size={22}
                      loading={loading}
                      text={submitBtnText}
                      icon={<SearchIcon />}
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

DynamicSearchForm.propTypes = propTypes;
DynamicSearchForm.defaultProps = defaultProps;

export default DynamicSearchForm;
export {
  propTypes as DynamicSearchFormPropTypes,
  defaultProps as DynamicSearchFormDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./OrganizationsTabs.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  triggerChange: PropTypes.func,
  setBackdropLoading: PropTypes.func,
  renderCondition: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  triggerChange: () => {},
  setBackdropLoading: () => {},
  renderCondition: null,
};

/**
 * Handles returning the props for each tab
 */
const a11yProps = (index) => {
  return {
    id: `organizations-tab-${index}`,
    "aria-controls": `organizations-tabpanel-${index}`,
  };
};

/**
 * Displays the component
 */
const OrganizationsTabs = (props) => {
  const { triggerChange, setBackdropLoading, renderCondition } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the tab panel
   */
  const [value, setValue] = useState(0);

  /**
   * Initializes the tab changed state
   */
  const [tabChanged, setTabChanged] = useState(false);

  /**
   * Handles the tab change
   */
  const handleChange = (event, newValue) => {
    setBackdropLoading(true);
    setValue(newValue);

    setTimeout(() => {
      setTabChanged(true);
    }, [1]);
  };

  /**
   * Handles rendering the tabs
   */
  const renderTabs = () => {
    const orgs = user.organizations ? user.organizations : [];
    const tabs = [{ uuid: "all-orgs", name: t("allOrgs") }, ...orgs];

    return (
      tabs &&
      tabs.map((org, idx) => {
        return <Tab key={org.uuid} label={org.name} {...a11yProps(idx)} />;
      })
    );
  };

  const handleRendering = () => {
    if (renderCondition === null || renderCondition) {
      return (
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            <div className={classes.root}>
              <AppBar
                position="static"
                color="default"
                className={classes.appbar}
              >
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  {renderTabs()}
                </Tabs>
              </AppBar>
            </div>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  useEffect(() => {
    if (tabChanged) {
      triggerChange(value);
      setTabChanged(false);
    }
    // eslint-disable-next-line
  }, [tabChanged]);

  return handleRendering();
};

OrganizationsTabs.propTypes = propTypes;
OrganizationsTabs.defaultProps = defaultProps;

export default OrganizationsTabs;
export {
  propTypes as OrganizationsTabsPropTypes,
  defaultProps as OrganizationsTabsDefaultProps,
};

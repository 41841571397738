import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * External Imports
 */
import { useLocation, matchPath } from "react-router-dom";

/**
 * Component Imports
 */
import Sidebar, { SidebarDefaultProps, SidebarPropTypes } from "../Sidebar";

/**
 *  Material UI Imports
 */
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./ReportsSidebar.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(SidebarPropTypes),
  paths: PropTypes.shape({
    daily: PropTypes.string,
    monthly: PropTypes.string,
    companies: PropTypes.string,
    waste: PropTypes.string,
    workers: PropTypes.string,
    advanced: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */

const defaultProps = {
  sidebar: SidebarDefaultProps,
  paths: {
    daily: "/",
    monthly: "/",
    companies: "/",
    waste: "/",
    workers: "/",
    advanced: "/",
  },
};

/**
 * Displays the component
 */
const ReportsSidebar = (props) => {
  const { sidebar, paths } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the paths from the components
   */
  const { daily, monthly, companies, waste, workers, advanced } = paths;

  /**
   * Gets the location path
   */
  const location = useLocation();

  /**
   * Checks if the current path matches the path of the menu item
   */
  const checkPathMatch = (pathName) => {
    const result = matchPath(location.pathname, pathName);
    return result ? (result.isExact ? true : false) : false;
  };

  /**
   * Gets the module title
   */
  const getModuleTitle = () => {
    /**
     * Initializes the title variable
     */
    let title = "";

    Object.entries(paths).forEach((path) => {
      if (checkPathMatch(path[1])) {
        title = t(path[0]);
        return;
      }
    });

    return title ? title : t("notFound");
  };

  /**
   * Configures the sidebar menu
   */
  const config = {
    moduleTitleFn: getModuleTitle,
    showDeleteAccount: false,
    menu: [
      {
        path: daily,
        text: "dailyReports",
        icon: <EventNoteOutlinedIcon className={classes.icon} />,
      },
      {
        path: monthly,
        text: "monthlyReports",
        icon: <EqualizerOutlinedIcon className={classes.icon} />,
      },
      {
        path: companies,
        text: "companiesReports",
        icon: <TimelineOutlinedIcon className={classes.icon} />,
      },
      {
        path: waste,
        text: "wasteReports",
        icon: <WidgetsOutlinedIcon className={classes.icon} />,
      },
      {
        path: workers,
        text: "workersReports",
        icon: <RecentActorsOutlinedIcon className={classes.icon} />,
      },
      {
        path: advanced,
        text: "advancedReports",
        icon: <TrackChangesOutlinedIcon className={classes.icon} />,
      },
    ],
  };

  return <Sidebar {...sidebar} {...config} />;
};

ReportsSidebar.propTypes = propTypes;
ReportsSidebar.defaultProps = defaultProps;

export default ReportsSidebar;
export {
  propTypes as ReportsSidebarPropTypes,
  defaultProps as ReportsSidebarDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Material UI Imports
 */
import Grid from "@material-ui/core/Grid";

/**
 * Hooks
 */
import { useSidebar } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./SubmoduleContainer.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  xs: PropTypes.shape({
    open: PropTypes.number,
    closed: PropTypes.number,
  }),
  sm: PropTypes.shape({
    open: PropTypes.number,
    closed: PropTypes.number,
  }),
  md: PropTypes.shape({
    open: PropTypes.number,
    closed: PropTypes.number,
  }),
  lg: PropTypes.shape({
    open: PropTypes.number,
    closed: PropTypes.number,
  }),
  xl: PropTypes.shape({
    open: PropTypes.number,
    closed: PropTypes.number,
  }),
  children: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  xs: {
    open: 12,
    closed: 12,
  },
  sm: {
    open: 12,
    closed: 12,
  },
  md: {
    open: 12,
    closed: 12,
  },
  lg: {
    open: 12,
    closed: 12,
  },
  xl: {
    open: 12,
    closed: 12,
  },
  children: null,
};

/**
 * Displays the component
 */
const SubmoduleContainer = (props) => {
  const { xs, sm, md, lg, xl, children } = props;

  /**
   * Gets the sidebar status
   */
  const { open } = useSidebar();

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Handles getting the grid size
   */
  const getGridSize = () => {
    const _xs = open ? xs.open : xs.closed;
    const _sm = open ? sm.open : sm.closed;
    const _md = open ? md.open : md.closed;
    const _lg = open ? lg.open : lg.closed;
    const _xl = open ? xl.open : xl.closed;

    return { xs: _xs, sm: _sm, md: _md, lg: _lg, xl: _xl };
  };

  return (
    <Grid
      item
      container
      {...getGridSize()}
      className={clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClosed]: !open,
      })}
    >
      {children}
    </Grid>
  );
};

SubmoduleContainer.propTypes = propTypes;
SubmoduleContainer.defaultProps = defaultProps;

export default SubmoduleContainer;
export {
  propTypes as SubmoduleContainerPropTypes,
  defaultProps as SubmoduleContainerDefaultProps,
};

/**
 * Handles getting the services and products from the work order
 */
const getServicesAndProducts = (workOrder) => {
  const items = workOrder.products.map((product) => {
    /**
     * Gets the is service flag
     */
    const isService =
      product.original_product && product.original_product.is_service;

    /**
     * Gets the is count price flag
     */
    const isCountPrice = product.original_product
      ? product.original_product.is_count_price
      : product.price > 0;

    /**
     * Gets the unit of measure
     */
    const unitOfMeasure =
      product.original_product && product.original_product.um;

    return {
      ...product,
      price_conditions: product.original_product
        ? product.original_product.price_conditions
          ? product.original_product.price_conditions
          : null
        : null,
      is_service: isService,
      is_count_price: isCountPrice,
      um: unitOfMeasure,
    };
  });

  /**
   * Defines the products array
   */
  const products = items.filter(
    (item) =>
      item.is_service === false || item.is_service === null || !item.is_service
  );

  /**
   * Defines the services array
   */
  const services = items.filter(
    (item) => item.is_service === true || item.is_service
  );

  return { products, services };
};

const utils = {
  getServicesAndProducts,
};

export { utils };

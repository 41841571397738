import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import DoneIcon from "@material-ui/icons/Done";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

/**
 * Component Imports
 */
import WorkOrderView, {
  WorkOrderViewDefaultProps,
  WorkOrderViewPropTypes,
} from "../WorkOrderView";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkOrderStage3.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  workOrderID: PropTypes.number,
  button: PropTypes.shape(ButtonPropTypes),
  workOrderView: PropTypes.shape(WorkOrderViewPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  activeStep: PropTypes.number,
  handleClose: PropTypes.func,
  prevStep: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  workOrderID: 0,
  button: ButtonDefaultProps,
  workOrderView: WorkOrderViewDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  activeStep: 0,
  handleClose: () => {},
  prevStep: () => {},
};

/**
 * Displays the component
 */
const EditWorkOrderStage3 = (props) => {
  const {
    button,
    workOrderID,
    workOrderView,
    loadingBackdrop,
    activeStep,
    prevStep,
    handleClose,
    setTriggerUpdate,
    dataUpdated,
    summaryUpdated,
    setSummaryUpdated,
    workOrderSummary,
    setWorkOrderSummary
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the data received flag
   */
  const [orderID, setOrderID] = useState(0);

  const handleDone = () => {
    setTriggerUpdate(true);
    handleClose();
  };

  return (
    <Card className={classes.stepCard}>
      <CardContent className={classes.content}>
        <WorkOrderView
          {...workOrderView}
          data={props.data}
          setOrderID={setOrderID}
          workOrderID={workOrderID}
          setTriggerUpdate={setTriggerUpdate}
          dataUpdated={dataUpdated}
          summaryUpdated={summaryUpdated}
          setSummaryUpdated={setSummaryUpdated}
          workOrderSummary={workOrderSummary}
          setWorkOrderSummary={setWorkOrderSummary}
        />
        <LoadingBackdrop
          {...loadingBackdrop}
          open={!orderID}
          classes={{ backdrop: classes.backdrop }}
        />
      </CardContent>
      <CardActions>
        <div className={classes.buttonsContainer}>
          <Button
            {...button}
            type="button"
            variant="filled"
            disabled={activeStep === 0}
            onClick={prevStep}
            className={classes.submitButton}
          >
            <NavigateBeforeIcon className={classes.beforeIcon} />
            {t("previous")}
          </Button>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleDone}
            className={clsx(classes.submitButton, classes.finalStep)}
          >
            {t("done")}
            <DoneIcon className={classes.nextIcon} />
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

EditWorkOrderStage3.propTypes = propTypes;
EditWorkOrderStage3.defaultProps = defaultProps;

export default EditWorkOrderStage3;
export {
  propTypes as EditWorkOrderStage3PropTypes,
  defaultProps as EditWorkOrderStage3DefaultProps,
};

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Material UI Imports
 */
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

/**
 * Styles imports
 */
import { useStyles } from "./InputSearch.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.any,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: "text",
  label: "",
  value: "",
  variant: "filled",
  onChange: () => {},
  name: "textInput",
  error: "",
  required: false,
  placeholder: "",
  margin: "none",
  fullWidth: false,
  autoFocus: false,
  id: "textInput",
  disabled: false,
  className: "",
  classes: {}
};

/**
 * Displays the component
 */
const InputSearch = props => {
  const {
    type,
    label,
    value,
    variant,
    onChange,
    name,
    error,
    required,
    className,
    placeholder,
    margin,
    fullWidth,
    autoFocus,
    id,
    disabled,
    classes
  } = props;

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Applies autofocus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  return (
    <div
      className={clsx(styles.root, {
        [classes.root]: classes.root ? classes.root : false
      })}
    >
      <InputBase
        className={clsx(styles.input, {
          [className]: className ? true : false
        })}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        inputRef={inputRef}
        name={name}
        label={label}
        variant={variant}
        margin={margin}
        error={error ? true : false}
        autoFocus={autoFocus}
        type={type}
        value={value}
        fullWidth={fullWidth}
        onChange={onChange}
        required={required}
      />
      <IconButton
        type="submit"
        className={styles.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </div>
  );
};

InputSearch.propTypes = propTypes;
InputSearch.defaultProps = defaultProps;

export default InputSearch;
export {
  propTypes as InputSearchPropTypes,
  defaultProps as InputSearchDefaultProps
};

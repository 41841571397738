import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import {
  format as formatDate,
  sub,
  add,
  eachDayOfInterval,
  isSaturday,
  isSunday,
} from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import AttendanceTable, {
  AttendanceTableDefaultProps,
  AttendanceTablePropTypes,
} from "../AttendanceTable";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import UndoIcon from "@material-ui/icons/Undo";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

/**
 * Hooks
 */
import { useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TimesheetAdminView.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./TimesheetAdminView.defaults";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  attendanceTable: PropTypes.shape(AttendanceTablePropTypes),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  defaultSearch: PropTypes.object,
  activeView: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  attendanceTable: AttendanceTableDefaultProps,
  organizations: [],
  defaultSearch: defaults.searchParams,
  activeView: null,
};

/**
 * Displays the component
 */
const TimesheetAdminView = (props) => {
  const {
    activeView,
    input,
    button,
    errorMessages,
    organizations,
    attendanceTable,
    defaultSearch,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  const [organizationsList, setOrganizationsList] = useState([]);

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the start date
   */
  const [fromDate, setFromDate] = useState(sub(new Date(), { days: 7 }));

  /**
   * Initializes the end date
   */
  const [toDate, setToDate] = useState(new Date());

  /**
   * Initializes the active week number
   */
  const [weekNumber, setWeekNumber] = useState(1);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Handles updating the active organization
   */
  const handleOrganizationChange = (e) => setActiveOrg(e.target.value);

  const [tableData, setTableData] = useState([]);

  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const [timeInterval, setTimeInterval] = useState([]);

  const [initSearch, setInitSearch] = useState(true);

  const getSearchOrgIdValue = () => {
    if (activeOrg === "all") return null;
    if (!activeOrg) return user.organization.id;
    return activeOrg;
  };

  /**
   * Handles searching for the attendances
   */
  const searchAttendanceVisual = async (data) => {
    try {
      const attendances = await apiClient.post(
        "/attendances/search/visual",
        data
      );
      if (attendances) {
        const { data } = attendances;
        const { items } = data;
        setInitSearch(false);
        if (items.length === 0) {
          setTableData([]);
          return;
        }

        const tableData = Object.values(items).map((worker, idx) => {
          const entries = Object.entries(worker.attendances);

          const timesheet = timeInterval.map((date) => {
            const foundDate = entries.find((entry) => {
              return entry[0] === date;
            });

            const isWeekend =
              isSaturday(new Date(date)) || isSunday(new Date(date));
            if (foundDate) {
              return {
                date: date,
                worked: true,
                hours: foundDate[1].worked_hours,
                weekend: isWeekend,
              };
            } else {
              return {
                date: date,
                worked: false,
                hours: 0,
                weekend: isWeekend,
              };
            }
          });

          let totalHours = 0;
          timesheet.forEach((worker) => (totalHours += worker.hours));

          return {
            ...worker,
            timesheet,
            totalHours: parseFloat(totalHours.toFixed(1)),
          };
        });

        setTableData(tableData);
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
      setInitSearch(false);
    }
  };

  /**
   * Handles the search
   */
  const handleSearch = () => {
    /**
     * Defines the org id model
     */
    const orgIdModel = { ...defaultSearch.models[0] };
    orgIdModel["selected"] = getSearchOrgIdValue();

    /**
     * Defines the start date model
     */
    const fromDateModel = { ...defaultSearch.models[1] };
    fromDateModel["selected"] = formatDate(new Date(fromDate), "yyyy-MM-dd");

    /**
     * Defines the end date model
     */
    const toDateModel = { ...defaultSearch.models[2] };
    toDateModel["selected"] = formatDate(new Date(toDate), "yyyy-MM-dd");

    /**
     * Builds the request body
     */
    const data = {
      ...defaultSearch,
      models: [{ ...orgIdModel }, { ...fromDateModel }, { ...toDateModel }],
    };
    searchAttendanceVisual(data);
  };

  /**
   * Handles resetting the search form
   */
  const resetSearch = () => {
    setInitSearch(true);
  };

  /**
   * Defines the Search Actions Component
   */
  const SearchActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.resetButton}
          onClick={resetSearch}
        >
          <UndoIcon />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (triggerSubmit) {
      setTriggerSubmit(false);
      handleSearch();
    }
    // eslint-disable-next-line
  }, [triggerSubmit]);

  /**
   * Handles updating the active organization on mount
   */
  useEffect(() => {
    if (activeView === 1 && organizations && organizations.length > 0) {
      setActiveOrg(user.organization.id);
    }
    // eslint-disable-next-line
  }, [activeView, organizations]);

  const [weekDate, setWeekDate] = useState(new Date());

  const handleWeekDate = (value) => setWeekDate(value);

  // Returns a Date object for Monday at the
  // start of the specified week
  function dateFromWeekNumber(year, week) {
    var d = new Date(year, 0, 1);
    var dayNum = d.getDay();
    var diff = --week * 7;

    // If 1 Jan is Friday to Sunday, go to next week
    if (!dayNum || dayNum > 4) {
      diff += 7;
    }

    // Add required number of days
    d.setDate(d.getDate() - d.getDay() + ++diff);
    return d;
  }

  const handleWeekAddition = () => {
    const newWeekDate = add(new Date(weekDate), { days: 7 });
    setWeekDate(newWeekDate);
  };

  const handleWeekSubstraction = () => {
    const prevWeekDate = sub(new Date(weekDate), { days: 7 });
    setWeekDate(prevWeekDate);
  };

  const getInterval = (date) => {
    const weekNumber = formatDate(date, "w");
    const year = date.getFullYear();
    const startDate = dateFromWeekNumber(year, weekNumber);
    const endDate = add(new Date(startDate), { days: 6 });
    const datesInterval = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
    const interval = datesInterval.map((date) => {
      return formatDate(new Date(date), "yyyy-MM-dd");
    });

    return { weekNumber, startDate, endDate, interval };
  };

  useEffect(() => {
    if (organizations.length > 0) {
      const list = [
        {
          label: t("all"),
          value: "all",
        },
        ...organizations,
      ];
      setOrganizationsList(list);
    }
    // eslint-disable-next-line
  }, [organizations]);

  useEffect(() => {
    if (!initSearch) {
      const { weekNumber, startDate, endDate, interval } =
        getInterval(weekDate);

      setActiveOrg(activeOrg);
      setWeekNumber(weekNumber);
      setFromDate(startDate);
      setToDate(endDate);
      setTimeInterval(interval);
      setTriggerSubmit(true);
    }
    // eslint-disable-next-line
  }, [weekDate, initSearch]);

  useEffect(() => {
    if (!initSearch) {
      const { weekNumber, startDate, endDate, interval } =
        getInterval(weekDate);

      setActiveOrg(activeOrg);
      setWeekNumber(weekNumber);
      setFromDate(startDate);
      setToDate(endDate);
      setTimeInterval(interval);
      setTriggerSubmit(true);
    }
    // eslint-disable-next-line
  }, [activeOrg, initSearch]);

  useEffect(() => {
    if (activeView === 1 && initSearch) {
      const { weekNumber, startDate, endDate, interval } = getInterval(
        new Date()
      );
      setActiveOrg(user.organization.id);
      setWeekNumber(weekNumber);
      setFromDate(startDate);
      setToDate(endDate);
      setTimeInterval(interval);
      setWeekDate(new Date());
      setTriggerSubmit(true);
    }
    // eslint-disable-next-line
  }, [activeView, initSearch]);

  return activeView === 1 ? (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Card className={classes.blank}>
          <CardContent>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={12} md={12} lg={5} xl={7}>
                <div className={classes.weekDate}>
                  <Button
                    {...button}
                    type="button"
                    variant="filled"
                    className={clsx(classes.navButton, classes.navPrev)}
                    onClick={handleWeekSubstraction}
                  >
                    <NavigateBeforeIcon />
                    {t("lastWeek")}
                  </Button>
                  <Input
                    {...input}
                    className={classes.dateField}
                    type="date"
                    inputDate={{
                      value: weekDate,
                      onChange: handleWeekDate,
                      margin: "normal",
                      label: `${t("week")} ${weekNumber}`,
                      variant: "outlined",
                    }}
                  />
                  <Button
                    {...button}
                    type="button"
                    variant="filled"
                    className={clsx(classes.navButton, classes.navNext)}
                    onClick={handleWeekAddition}
                  >
                    {t("nextWeek")}
                    <NavigateNextIcon />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <Input
                  {...input}
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    value: activeOrg,
                    onChange: handleOrganizationChange,
                    label: t("organization_idLabel"),
                    labelClass: classes.label,
                    variant: "outlined",
                    options: organizationsList,
                    optionLabel: "label",
                    optionValue: "value",
                    showHelper: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <SearchActions />
              </Grid>
              <Grid item xs={12}>
                <AttendanceTable
                  {...attendanceTable}
                  tableData={tableData}
                  timeInterval={timeInterval}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ) : null;
};

TimesheetAdminView.propTypes = propTypes;
TimesheetAdminView.defaultProps = defaultProps;

export default TimesheetAdminView;
export {
  propTypes as TimesheetAdminViewPropTypes,
  defaultProps as TimesheetAdminViewDefaultProps,
};

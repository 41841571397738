/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  addBtn: {
    zIndex: 999,
    marginTop: "0.5rem",
    position: "fixed",
    top: 50,
    right: 5,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    ...theme.mixins.submitBtn,
    background: theme.palette.success.main,
    marginBottom: "1rem",
    padding: "1rem",
    fontSize: 16,
    borderRadius: 4,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
    "& svg": {
      marginRight: "0.5rem",
    },
  },
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import OrganizationItem, {
  OrganizationItemDefaultProps,
  OrganizationItemPropTypes,
} from "../OrganizationItem";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./UserOrganizations.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  organizationItem: PropTypes.shape(OrganizationItemPropTypes),
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  modalActions: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  organizationItem: OrganizationItemDefaultProps,
  expanded: false,
  onChange: () => {},
  modalActions: {},
};

/**
 * Displays the component
 */
const UserOrganizations = (props) => {
  const { organizationItem, expanded, onChange, modalHandler } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user object
   */
  const { user } = useUser();

  /**
   * Handles rendering the users
   */
  const renderUserOrganizations = () => {
    const { organizations } = user;

    return organizations && organizations.length > 0
      ? organizations.map((organization) => {
          const { uuid: key } = organization;

          return (
            <Zoom key={key} in={true} timeout={500}>
              <Grid container className={classes.box}>
                <Grid item xs={12}>
                  <OrganizationItem
                    {...organizationItem}
                    {...organization}
                    openModal={modalHandler}
                    expanded={expanded}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </Zoom>
          );
        })
      : null;
  };

  return renderUserOrganizations();
};

UserOrganizations.propTypes = propTypes;
UserOrganizations.defaultProps = defaultProps;

export default UserOrganizations;
export {
  propTypes as UserOrganizationsPropTypes,
  defaultProps as UserOrganizationsDefaultProps,
};

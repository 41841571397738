import React, { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * Imports i18n
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EventIcon from "@material-ui/icons/Event";

/**
 * Imports the component styles
 */
import { useStyles } from "./EditTyreHotelForm.styles";

/**
 * Validations Import
 */
import Validator from "./EditTyreHotelForm.validations";

/**
 * Hooks
 */
import { useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  data: PropTypes.object,
  workOrder: PropTypes.object,
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  triggerApiCalls: PropTypes.bool,
  input: PropTypes.shape(InputPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    tyre_width: PropTypes.string,
    tyre_height: PropTypes.string,
    tyre_rim: PropTypes.string,
    tyre_name: PropTypes.string,
    tyre_quantity: PropTypes.string,
    description: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  data: {},
  workOrder: {},
  onStart: () => {},
  onFinish: () => {},
  onReset: () => {},
  triggerApiCalls: false,
  input: InputDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    tyre_width: "",
    tyre_height: "",
    tyre_rim: "",
    tyre_name: "",
    tyre_quantity: "",
    description: "",
  },
};

/**
 * Displays the component
 */
const EditTyreHotelForm = (props) => {
  const {
    input,
    defaultValues,
    data,
    workOrder,
    onFinish,
    onStart,
    onReset,
    triggerApiCalls,
    errorMessages,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles updating a tyre hotel by id
   */
  const updateTyreHotel = async (data, id) => {
    try {
      const updatedTyreHotel = await apiClient.put(`/tyre-hotels/${id}`, data);
      if (updatedTyreHotel) {
        dispatchMessage({
          message: t("successMessage"),
        });
        onFinish();
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
      onFinish();
    }
  };

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    const formData = {
      work_order_uuid: workOrder.uuid,
      organization_client_id: workOrder.organization_client_id,
      client_name: workOrder.client_name,
      car_number: workOrder.car_plate_number,
    };

    if (tyre_width) formData["tyre_width"] = tyre_width;
    if (tyre_height) formData["tyre_height"] = tyre_height;
    if (tyre_rim) formData["tyre_rim"] = tyre_rim;
    if (tyre_name) formData["tyre_name"] = tyre_name;
    if (tyre_quantity) formData["tyre_quantity"] = tyre_quantity.toString();
    if (description) formData["description"] = description;

    updateTyreHotel(formData, data.id);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  /**
   * Gets the useForm handlers
   */
  const { inputs, setInputs, handleInputChange } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    tyre_width,
    tyre_height,
    tyre_rim,
    tyre_name,
    tyre_quantity,
    description,
  } = inputs;

  useEffect(() => {
    if (Object.keys(data).length > 0 && Object.keys(workOrder).length > 0) {
      setInputs((prevState) => {
        return {
          ...prevState,
          tyre_width: data.tyre_width || "",
          tyre_height: data.tyre_height || "",
          tyre_rim: data.tyre_rim || "",
          tyre_name: data.tyre_name || "",
          tyre_quantity: data.tyre_quantity || "",
          description: data.description || "",
        };
      });
    }
    // eslint-disable-next-line
  }, [data, workOrder]);

  useEffect(() => {
    if (triggerApiCalls) {
      const tempInputs = {
        tyre_rim: inputs.tyre_rim,
        tyre_height: inputs.tyre_height,
        tyre_width: inputs.tyre_width,
        tyre_quantity: inputs.tyre_quantity,
        tyre_name: inputs.tyre_name,
        description: inputs.description,
      };

      const tempData = {
        tyre_rim: data.tyre_rim,
        tyre_height: data.tyre_height,
        tyre_width: data.tyre_width,
        tyre_quantity: data.tyre_quantity,
        tyre_name: data.tyre_name,
        description: data.description,
      };

      const tempInputsValues = Object.values(tempInputs);
      const tempDataValues = Object.values(tempData);

      if (
        tempInputsValues.some(
          (inputValue, index) => tempDataValues[index] !== inputValue
        )
      ) {
        onStart();
        onSubmit();
      } else {
        onReset();
      }
    }
    // eslint-disable-next-line
  }, [triggerApiCalls, inputs]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={3}>
        <Typography variant="caption" className={classes.title}>
          <EventIcon />
          {formatDate(new Date(data.created_at), "dd-MM-yyyy HH:mm")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          {...input}
          type="numeric"
          className={classes.field}
          inputNumeric={{
            id: "tyre_width",
            name: "tyre_width",
            value: tyre_width,
            variant: "outlined",
            onChange: handleInputChange,
            label: t("tyre_widthLabel"),
            showHelper: false,
            InputLabelProps: {
              className: classes.label,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          {...input}
          type="numeric"
          className={classes.field}
          inputNumeric={{
            id: "tyre_height",
            name: "tyre_height",
            value: tyre_height,
            variant: "outlined",
            onChange: handleInputChange,
            label: t("tyre_heightLabel"),
            showHelper: false,
            InputLabelProps: {
              className: classes.label,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          {...input}
          type="numeric"
          className={classes.field}
          inputNumeric={{
            id: "tyre_rim",
            name: "tyre_rim",
            value: tyre_rim,
            variant: "outlined",
            onChange: handleInputChange,
            label: t("tyre_rimLabel"),
            showHelper: false,
            InputLabelProps: {
              className: classes.label,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Input
          {...input}
          type="text"
          className={classes.field}
          inputText={{
            id: "tyre_name",
            name: "tyre_name",
            value: tyre_name,
            variant: "outlined",
            onChange: handleInputChange,
            label: t("tyre_nameLabel"),
            showHelper: false,
            InputLabelProps: {
              className: classes.label,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          {...input}
          type="numeric"
          className={classes.field}
          inputNumeric={{
            id: "tyre_quantity",
            name: "tyre_quantity",
            value: tyre_quantity,
            variant: "outlined",
            onChange: handleInputChange,
            label: t("tyre_quantityLabel"),
            showHelper: false,
            InputLabelProps: {
              className: classes.label,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          {...input}
          type="textarea"
          inputTextarea={{
            id: "description",
            name: "description",
            value: description,
            onChange: handleInputChange,
            showCount: true,
            rows: 3,
            rowsMax: 3,
            maxChars: 100,
            size: "medium",
            variant: "outlined",
            label: t("descriptionLabel"),
            ariaLabel: t("descriptionLabel"),
          }}
        />
      </Grid>
    </Grid>
  );
};

EditTyreHotelForm.propTypes = propTypes;
EditTyreHotelForm.defaultProps = defaultProps;

export default EditTyreHotelForm;
export {
  propTypes as EditTyreHotelFormPropTypes,
  defaultProps as EditTyreHotelFormDefaultProps,
};

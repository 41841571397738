/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  activeLanguageItem: {
    display: "flex",
    alignItems: "center",
    // padding: "1.3rem 0",
    "&:hover": {
      background: "transparent",
    },
    "& img": {
      maxWidth: "100%",
      width: 21,
      marginRight: 7,
      marginLeft: 7,
    },
    "& svg": {
      color: theme.palette.success.main,
      fontSize: "0.9rem",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  timesheet: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    padding: "0.5rem",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    cursor: "pointer",
    height: theme.navHeight,
    "&:hover": {
      backgroundColor: "#425e79",
    },
  },
  dropdownContainer: {
    position: "absolute",
    top: 10,
    left: -5,
    background: theme.palette.common.white,
    minWidth: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#000",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    zIndex: 150,
  },
  langMenuItem: {
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#f3f3f3",
    },
    "& img": {
      maxWidth: "100%",
      width: 21,
      marginRight: 7,
      marginLeft: 7,
    },
    "& svg": {
      color: theme.palette.success.main,
      fontSize: "0.9rem",
      marginLeft: 10,
      marginRight: 10,
    },
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  form: {
    padding: "1.5rem",
    background: theme.palette.common.white,
    borderRadius: 15,
    minWidth: 600,
    maxWidth: 850,
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 0"
    },
    [theme.breakpoints.down("md")]: {
      // minWidth: 525
      minWidth: "unset"
    }
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  loginLink: {
    margin: "0 0.5rem",
    fontWeight: 800,
    fontSize: "0.8rem",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.accent
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15
    },
  },
  submitBtn: {
    ...theme.mixins.submitBtn,
    borderRadius: 3,
    fontSize: "0.8rem",
    "&:hover": {
      backgroundColor: theme.palette.hover.main
    }
  }
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import WorkOrderHeader, {
  WorkOrderHeaderDefaultProps,
  WorkOrderHeaderPropTypes,
} from "../WorkOrderHeader";
import WorkOrderDisclaimer, {
  WorkOrderDisclaimerDefaultProps,
  WorkOrderDisclaimerPropTypes,
} from "../WorkOrderDisclaimer";
import WorkOrderTable, {
  WorkOrderTableDefaultProps,
  WorkOrderTablePropTypes,
} from "../WorkOrderTable";
import WorkOrderWorkers, {
  WorkOrderWorkersDefaultProps,
  WorkOrderWorkersPropTypes,
} from "../WorkOrderWorkers";
import WorkOrderSignature, {
  WorkOrderSignatureDefaultProps,
  WorkOrderSignaturePropTypes,
} from "../WorkOrderSignature";
import DataTable, {
  DataTableDefaultProps,
  DataTablePropTypes,
} from "../DataTable";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

/**
 * Hooks
 */
import { useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./PrintWorkOrder.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  header: PropTypes.shape(WorkOrderHeaderPropTypes),
  disclaimer: PropTypes.shape(WorkOrderDisclaimerPropTypes),
  table: PropTypes.shape(WorkOrderTablePropTypes),
  workOrderWorkers: PropTypes.shape(WorkOrderWorkersPropTypes),
  workOrderSignature: PropTypes.shape(WorkOrderSignaturePropTypes),
  dataTable: PropTypes.shape(DataTablePropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  workOrderID: PropTypes.number,
  data: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  header: WorkOrderHeaderDefaultProps,
  disclaimer: WorkOrderDisclaimerDefaultProps,
  table: WorkOrderTableDefaultProps,
  workOrderWorkers: WorkOrderWorkersDefaultProps,
  workOrderSignature: WorkOrderSignatureDefaultProps,
  dataTable: DataTableDefaultProps,
  closeModal: () => {},
  workOrderID: 0,
  data: {
    car: {},
    car_type: {},
    organization_client: {},
    payment_type: {},
    products: [],
    tyre_hotels: [],
    tyre_service: {},
    work_order_type: {},
    workers: [],
  },
};

/**
 * Displays the component
 */
const PrintWorkOrder = (props) => {
  const {
    header,
    disclaimer,
    workOrderID,
    table,
    workOrderWorkers,
    workOrderSignature,
    dataTable,
    setReadyToPrint,
    setLoading,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the data state
   */
  const [data, setData] = useState(props.data);

  const {
    uuid,
    work_order_type,
    car,
    tyre_service,
    organization_client,
    workers,
    discount,
    delegate_name,
    client_name,
    car_plate_number,
    finished,
    description: workOrderDescription,
  } = data;

  /**
   * Initializes the products state
   */
  const [products, setProducts] = useState([]);

  /**
   * Initializes the requested and executed services state
   */
  const [servicesReq, setServicesReq] = useState("");
  const [servicesExec, setServicesExec] = useState([]);

  const [triggerPrint, setTriggerPrint] = useState(false);

  /**
   * Handles initializing the tables data
   */
  const initTableData = (size) => {
    const result = Array(size).fill({ label: "", value: "", render: true });
    return result;
  };

  /**
   * Intializes the tables data
   */
  const [orderData, setOrderData] = useState(initTableData(3));
  const [clientData, setClientData] = useState(initTableData(3));
  const [carData, setCarData] = useState(initTableData(10));
  const [maxReached, setMaxReached] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles getting the work order by id
   */
  const getWorkOrder = async (id) => {
    try {
      const workOrder = await apiClient.get(`/work-orders/${id}`);
      if (workOrder) {
        const { data } = workOrder;
        if (setLoading) setLoading(false);
        setData(data);
        setReady(true);
      }
    } catch (error) {
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
      if (setLoading) setLoading(false);
      setReadyToPrint(false);
      setReady(false);
    }
  };

  /**
   * Handles building the tyre dimensions string
   * width / height R rim
   */
  const buildTyreDimString = (tyre_dimensions) => {
    const { tyre_width, tyre_height, tyre_rim } = tyre_dimensions;

    if (!tyre_width || !tyre_height || !tyre_rim) return " - ";

    return `${tyre_width} / ${tyre_height} R${tyre_rim}`;
  };

  /**
   * Handles rendering the services
   */
  const renderRequestedServices = () => {
    return <div className={classes.services}>{servicesReq}</div>;
  };

  /**
   * Halves an array in 2
   */
  const sliceArray = (array, returnedPart) => {
    let carDataArray = array;
    let halfwayThrough = Math.ceil(carDataArray.length / 2);

    let arrayFirstHalf = carDataArray.slice(0, halfwayThrough);
    let arraySecondHalf = carDataArray.slice(
      halfwayThrough,
      carDataArray.length
    );

    if (returnedPart === 1) {
      return arrayFirstHalf;
    } else {
      return arraySecondHalf;
    }
  };

  /**
   * Defines the table title component
   */
  const TableTitle = (props) => {
    const { title } = props;

    return (
      <Typography variant="caption" className={classes.tableTitle}>
        {title}
      </Typography>
    );
  };

  /**
   * Makes the initial search
   */
  useEffect(() => {
    if (workOrderID) {
      getWorkOrder(workOrderID);
    }

    // eslint-disable-next-line
  }, [workOrderID]);

  /**
   * Builds the data tables if the api call is resolved
   */
  useEffect(() => {
    if (ready) {
      /**
       * Defines the base render condition
       * Some data is rendered conditionally
       */
      const render = true;

      /**
       * Gets the data
       */
      const org_client = organization_client ? organization_client : {};

      const { is_company, type, loyalty_card } = org_client;

      const _tyre_service = tyre_service ? tyre_service : {};

      const { car_km, new_tyre, tyre_pressure, bolt_torque } = _tyre_service;
      const { make, model, type: carType, description } = car;
      const { name } = work_order_type;

      /**
       * Builds the tyre dimensions
       */
      const tyreDimensions = buildTyreDimString(tyre_service);

      /**
       * Defines the order meta data
       */
      const orderData = [
        { label: t("orderID"), value: uuid, render },
        { label: t("updatedAt"), value: finished, render },
        { label: t("workOrderType"), value: name, render },
      ];

      /**
       * Defines the client data
       */
      const clientData = [
        { label: t("clientName"), value: client_name, render },
        { label: t("delegate"), value: delegate_name, render: is_company },
        { label: t("clientType"), value: t(type), render },
        { label: t("loyalty_card"), value: loyalty_card, render },
      ];

      /**
       * Defines the car data
       */
      const carData = [
        { label: t("carPlate"), value: car_plate_number, render },
        { label: t("carMake"), value: make, render },
        { label: t("carModel"), value: model, render },
        { label: t("carKm"), value: car_km, render },
        { label: t("carType"), value: carType, render },
        { label: t("observations"), value: workOrderDescription, render },
        { label: t("tyreDimensions"), value: tyreDimensions, render },
        { label: t("newTyre"), value: t(new_tyre), render },
        { label: t("tyrePressure"), value: tyre_pressure, render },
        { label: t("boltTorque"), value: bolt_torque, render },
        { label: t("carDescription"), value: description, render },
      ];

      /**
       * Updates the state
       */

      setOrderData(orderData);
      setClientData(clientData);
      setCarData(carData);
      setReady(false);
      setTriggerPrint(true);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles triggering the print command
   */
  useEffect(() => {
    /**
     * Defines the printing conditions
     */
    const orderDataExists = orderData.length > 0;
    const clientDataExists = clientData.length > 0;
    const carDataExists = clientData.length > 0;

    if (orderDataExists && clientDataExists && carDataExists && triggerPrint)
      setReadyToPrint(true);
    // eslint-disable-next-line
  }, [orderData, clientData, carData, triggerPrint]);

  /**
   * Updates the products and services state
   */
  useEffect(() => {
    if (data && data.products) {
      const items = data.products.map((product) => {
        /**
         * Gets the is service flag
         */
        const isService =
          product.original_product && product.original_product.is_service;

        /**
         * Gets the is count price flag
         */
        const isCountPrice = product.original_product
          ? product.original_product.is_count_price
          : product.price > 0;

        /**
         * Gets the unit of measure
         */
        const unitOfMeasure =
          product.original_product && product.original_product.um;

        return {
          ...product,
          is_service: isService,
          is_count_price: isCountPrice,
          um: unitOfMeasure,
        };
      });

      /**
       * Defines the products array
       */
      const products = items.filter(
        (item) =>
          item.is_service === false ||
          item.is_service === null ||
          !item.is_service
      );

      /**
       * Defines the services array
       */
      const services = items.filter(
        (item) => item.is_service === true || item.is_service
      );

      /**
       * Defines the requested services array
       */
      const reqServices = items
        .filter((item) => item.is_service)
        .map((item) => item.name)
        .join(" | ");

      setMaxReached(products.length + services.length >= 13);
      setProducts(products);
      setServicesReq(reqServices);
      setServicesExec(services);
    }
  }, [data]);

  return (
    <Grid
      item
      container
      xs={12}
      className={clsx({
        [classes.container]: !maxReached,
      })}
    >
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.blank}>
            <CardContent>
              <Grid container direction="column" justify="center">
                <WorkOrderHeader {...header} />
                <Typography variant="h1" className={classes.title}>
                  {t("workOrder")}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.box}
              >
                <Grid item container xs={6} sm={6} className={classes.padding}>
                  <Grid item container xs={12} direction="column">
                    <TableTitle title={t("orderDetails")} />
                    <DataTable {...dataTable} data={orderData} />
                  </Grid>
                </Grid>

                <Grid item container xs={6} sm={6} className={classes.padding}>
                  <Grid item container xs={12} direction="column">
                    <TableTitle title={t("clientData")} />
                    <DataTable {...dataTable} data={clientData} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className={classes.spacing}>
                    <TableTitle title={t("car")} />
                  </Grid>
                  <Grid item container xs={6} sm={6}>
                    <DataTable
                      {...dataTable}
                      className={classes.spacing}
                      data={sliceArray(carData, 1)}
                    />
                  </Grid>
                  <Grid item container xs={6} sm={6}>
                    <DataTable
                      {...dataTable}
                      className={classes.spacing}
                      data={sliceArray(carData, 2)}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <WorkOrderWorkers {...workOrderWorkers} workers={workers} />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.box}
              >
                <Grid container className={classes.boxItem}>
                  <WorkOrderTable
                    {...table}
                    title={t("products")}
                    data={{ items: products, discount }}
                    triggerHide
                  />
                </Grid>
                <Grid container className={classes.boxItem}>
                  <Grid item container xs={12} direction="column">
                    <Typography
                      variant="caption"
                      className={classes.tableTitle}
                    >
                      {t("servicesRequested")}
                    </Typography>
                    <Grid container direction="column">
                      {renderRequestedServices()}
                      <WorkOrderSignature
                        {...workOrderSignature}
                        justify="flex-start"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.boxItem}>
                  <WorkOrderTable
                    {...table}
                    title={t("servicesExecuted")}
                    data={{ items: servicesExec, discount }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={clsx({
                  [classes.positionBottom]: !maxReached,
                })}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <WorkOrderSignature
                      {...workOrderSignature}
                      label={t("signatureCompany")}
                      justify="flex-start"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <WorkOrderSignature
                      {...workOrderSignature}
                      label={t("signatureClient")}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <WorkOrderDisclaimer
                    {...disclaimer}
                    work_order_type={work_order_type}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

PrintWorkOrder.propTypes = propTypes;
PrintWorkOrder.defaultProps = defaultProps;

export default PrintWorkOrder;
export {
  propTypes as PrintWorkOrderPropTypes,
  defaultProps as PrintWorkOrderDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  prevButton: {
    display: "flex",
    color: theme.palette.primary.contrastText,
    padding: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.contrastText
    }
  },
  pagesDisplay: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.contrastText
    },
    color: theme.palette.primary.contrastText,
    minWidth: 85,
    marginTop: 2
  },
  canvass: {
    height: "100vh"
  },
  main: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main
  },
  toolsContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  toolsMain: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flex: 50
    }
  },
  iconContainer: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    width: 30,
    height: 30,
    minHeight: 30,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& svg": {
        color: "#fff"
      }
    }
  },
  icon: {
    width: 32,
    height: 32,
    color: "#fff"
  },
  iconSmall: {
    width: 24,
    height: 24,
    color: "#fff"
  },
  arrow: {
    color: theme.palette.primary.main
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1, 0)
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $downloadText": {
        color: theme.palette.primary.main
      },
      "& $downloadIcon": {
        color: theme.palette.primary.main
      }
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "1rem"
    }
  },
  downloadText: {
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    marginLeft: "0.3rem"
  },
  downloadIcon: {
    color: theme.palette.common.white
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Material UI Imports
 */
import TableCell from "@material-ui/core/TableCell";

/**
 * Styles Imports
 */
import { useStyles } from "./TableCellHeader.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  label: PropTypes.any,
  classes: PropTypes.shape({
    cell: PropTypes.string,
    header: PropTypes.string,
    iconContainer: PropTypes.string,
    upArrow: PropTypes.string,
    downArrow: PropTypes.string,
  }),
  sort: PropTypes.bool,
  onClick: PropTypes.func,
  sortDirection: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  label: "",
  classes: {},
  sort: true,
  onClick: () => {},
  sortDirection: "",
};

/**
 * Displays the component
 */
const TableCellHeader = (props) => {
  const { label, classes, onClick, field, sortDirection } = props;
  /**
   * Gets the component styles
   */
  const styles = useStyles({
    align: field.align,
  });

  /**
   * Defines the component classes
   */
  const cellClasses = clsx({
    [styles.cellHeader]: true,
    [classes.cell]: classes.cell ? true : false,
    [styles.small]: label.length < 4,
  });

  return (
    <TableCell
      sortDirection={sortDirection}
      onClick={onClick}
      className={cellClasses}
    >
      {label}
    </TableCell>
  );
};

TableCellHeader.propTypes = propTypes;
TableCellHeader.defaultProps = defaultProps;

export default TableCellHeader;
export {
  propTypes as TableCellHeaderPropTypes,
  defaultProps as TableCellHeaderDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: theme.mixins.submoduleContainer,
  marginsTitle: {
    marginBottom: "1rem",
  },
}));

export { useStyles };

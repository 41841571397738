import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Imports Material UI Components
 */
import Grid from "@material-ui/core/Grid";
import AddToPhotosOutlinedIcon from "@material-ui/icons/AddToPhotosOutlined";
import TodayIcon from "@material-ui/icons/Today";
import EventBusyIcon from "@material-ui/icons/EventBusy";

/**
 * Imports components
 */
import Button from "../Button";
import LoadingText from "../LoadingText";
import SubmoduleTitle from "../SubmoduleTitle";
import SubmoduleWrapper from "../SubmoduleWrapper";
import SubmoduleContainer from "../SubmoduleContainer";
import CreateButton from "../CreateButton";
import WorkOrderTimerCards from "../WorkOrderTimerCards";
import DeleteWorkOrderTimerModal from "../DeleteWorkOrderTimerModal";
import AppointmentsScheduler from "../AppointmentsScheduler";
import NCreateWorkOrderModal from "../NCreateWorkOrderModal";
import RecentlyCreatedWorkOrders from "../RecentlyCreatedWorkOrders";

/**
 * Imports hooks
 */
import { useTranslation } from "react-i18next";
import { useLocalStorage, useSidebar, useWorkOrder, useDebounce } from "../../hooks";

/**
 * Imports utils
 */
import {
  getLatestActiveWork,
  cleanGarbage,
  sortCardsByTimer,
  filterCardsByPlateNumber,
  filterRecentlyCreated,
} from "./TyreServiceCreateWorkOrders.utils";

/**
 *  Imports component styles
 */
import { useStyles } from "./TyreServiceCreateWorkOrders.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  path: "/create",
};

/**
 * Displays the component
 */
const TyreServiceCreateWorkOrders = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the sidebar state
   */
  const { open: isSidebarOpen } = useSidebar();

  /**
   * Gets the component styles
   */
  const classes = useStyles({ open: isSidebarOpen });

  /**
   * Initializes the draft data
   */
  const [draftData, setDraftData] = useState({});

  /**
   * Gets the debounce
   */
  const debounce = useDebounce();

  /**
   * Gets the useWorkOrder hook setter
   */
  const { setDrafts } = useWorkOrder();

  /**
   * Initializes the timer cards
   */
  const [timerCards, setTimerCards] = useState([]);

  /**
   * Initializes the current card in edit mode
   */
  const [cardInEdit, setCardInEdit] = useState("");

  /**
   * Initializes the delete data
   */
  const [deleteData, setDeleteData] = useState({});

  /**
   * Initializes the modal's open state
   */
  const [isOpenModal, setIsOpenModal] = useState(false);

  /**
   * Initializes the delete modal state
   */
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  /**
   * Initializes the calendar open flag
   */
  const [calendarOpen, setCalendarOpen] = useState(false);

  /**
   * Initializes the loading flag
   */
  const [loading, setLoading] = useState(false);

  /**
   * Gets the active work / drafts
   */
  const [activeWork, setActiveWork] = useLocalStorage("drafts", []);

  /**
   * Gets the recently created work orders
   */
  const [recentlyCreated, setRecentlyCreated] = useLocalStorage(
    "recentlyCreated",
    []
  );

  /**
   * Defines the submit button classes
   */
  const submitBtnClasses = clsx(classes.submitBtn, {
    [classes.calendarOpen]: calendarOpen && !loading,
  });

  /**
   * Handles togging the calendar on and off
   */
  const toggleCalendar = () => {
    if (!calendarOpen) {
      setLoading(true);
      setCalendarOpen(true);
    } else {
      setCalendarOpen(false);
    }
  };

  /**
   * Handles opening the modal
   */
  const handleOpenModal = () => setIsOpenModal(true);

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => setIsOpenModal(false);

  /**
   * Handles opening the delete modal
   */
  const openDeleteModal = () => setIsDeleteOpen(true);

  /**
   * Handles closing the delete modal
   */
  const closeDeleteModal = () => setIsDeleteOpen(false);

  /**
   * Handles deleting a timer card
   */
  const handleDelete = (plate_number) => {
    const timerCards = getLatestActiveWork();
    const work = filterCardsByPlateNumber(timerCards, plate_number);

    debounce(() => {
      localStorage.removeItem(`${plate_number}_newTimer`)
    }, 350)

    setActiveWork(work);
    setDrafts(work);
    setTimerCards(work.sort(sortCardsByTimer));
  };

  /**
   * Handles opening the create modal but with draft data already set
   */
  const handleEdit = (props) => {
    setDraftData(props);
    setCardInEdit(props.id);
  };
  
  /**
   * Handles the create workorder callback
   */
   const onCreate = (data) => {
    const { car_plate_number } = data;

    /**
     * Gets the drafts
     */
    const drafts = getLatestActiveWork();

    localStorage.setItem('drafts', JSON.stringify(drafts.filter(wo => wo.plateNumer !== car_plate_number )))
    setTimerCards(prevState => prevState.filter(wo => wo.plateNumber !== car_plate_number ))
  }
  
  /**
   * Updates the recently created wo list
   */
  useEffect(() => {
    if (recentlyCreated.length > 0) {
      const updatedList = filterRecentlyCreated(recentlyCreated);
      setRecentlyCreated(updatedList);
    }
    // eslint-disable-next-line
  }, [isOpenModal]);

  /**
   * Handles opening the modal
   */
  useEffect(() => {
    if (cardInEdit) handleOpenModal();
    // eslint-disable-next-line
  }, [cardInEdit]);

  /**
   * Handles opening the modal
   */
  useEffect(() => {
    if (Object.keys(deleteData).length > 0) openDeleteModal();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Resets the draft data when the modal closes
   */
  useEffect(() => {
    if (!isOpenModal && Object.keys(draftData).length > 0) {
      handleOpenModal();
    }
    // eslint-disable-next-line
  }, [isOpenModal, draftData]);

  /**
   * Updates the timer cards when the modal opens/closes
   */
  useEffect(() => {
    const latestActiveWork = getLatestActiveWork();
    setTimerCards(latestActiveWork);
    cleanGarbage();

    debounce(() => {
      const hasOldPlates = localStorage.getItem("oldPlates");
      if(hasOldPlates){
         const oldPlates = JSON.parse(localStorage.getItem('oldPlates'))
         oldPlates.forEach(plate => {
          localStorage.removeItem(`${plate}_newTimer`);
         })
   
         localStorage.removeItem("oldPlates");
      }
    }, 1000)
    
    // eslint-disable-next-line
  }, [isOpenModal]);

  return (
    <SubmoduleContainer>
      <SubmoduleTitle
        icon={<AddToPhotosOutlinedIcon />}
        title={t("titleCreateWorkOrders")}
      />
      <SubmoduleWrapper>
        <Grid container className={classes.action}>
          <CreateButton
            className={classes.addBtn}
            text={t("add")}
            onClick={handleOpenModal}
          /> 
        </Grid>
        <Grid container spacing={3}>
          <Grid item container xs={12} md={9} spacing={3}>
            <WorkOrderTimerCards
              timerCards={timerCards}
              isOpenModal={isOpenModal}
              draftData={draftData}
              handleEdit={handleEdit}
              cardInEdit={cardInEdit}
              setCardInEdit={setCardInEdit}
              setDeleteData={setDeleteData}
            /> 
          </Grid>
          <Grid item container xs={12} md={3}>
            <RecentlyCreatedWorkOrders
              recentlyCreated={recentlyCreated}
              setRecentlyCreated={setRecentlyCreated}
            />
          </Grid>
          <Button
            onClick={toggleCalendar}
            type="submit"
            variant="filled"
            className={submitBtnClasses}
          >
            <LoadingText
              size={22}
              loading={loading}
              text={t(calendarOpen ? "closeCalendar" : "openCalendar")}
              icon={calendarOpen ? <EventBusyIcon /> : <TodayIcon />}
            />
          </Button>
          {calendarOpen && (
            <AppointmentsScheduler
              viewOnly={true}
              defaultActiveView="Day"
              allowChangeView={false}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </Grid>
        <NCreateWorkOrderModal
          open={isOpenModal}
          closeModal={handleCloseModal}
          activeWork={activeWork}
          setActiveWork={setActiveWork}
          draftData={draftData}
          setDraftData={setDraftData}
          recentlyCreated={recentlyCreated}
          setRecentlyCreated={setRecentlyCreated}
          onCreate={onCreate}
        />
        <DeleteWorkOrderTimerModal
          open={isDeleteOpen}
          closeModal={closeDeleteModal}
          deleteData={deleteData}
          handleDelete={handleDelete}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

TyreServiceCreateWorkOrders.propTypes = propTypes;
TyreServiceCreateWorkOrders.defaultProps = defaultProps;

export default TyreServiceCreateWorkOrders;
export {
  propTypes as TyreServiceCreateWorkOrdersPropTypes,
  defaultProps as TyreServiceCreateWorkOrdersDefaultProps,
};

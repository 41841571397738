/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 780,
    padding: 0,
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
  content: {
    padding: 4,
  },

  appbar: {
    boxShadow: "none",
    border: "none",
    background: "transparent",
    maxWidth: "inherit",
    width: "inherit",
    display: "flex",
    justifyContent: "center",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      display: "flex",
    },
    "& .MuiTab-wrapper": {
      border: `1px solid #ded6d6`,
      padding: "0.5rem 1rem",
      background: theme.palette.sidebar.main,
      textTransform: "none",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: theme.palette.common.white,
      "& .MuiTab-wrapper": {
        background: theme.palette.primary.main,
        // border: `1px solid ${theme.palette.primary.accent}`,
      },
    },
  },
  indicator: {
    display: "none",
  },
  cardActions: {
    paddingTop: 0,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  saveBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    height: 30,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  nextIcon: {
    marginLeft: "0.25rem",
  },
  closeBtn: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    minWidth: 125,
    borderRadius: 0,
    boxShadow: "none",
    fontWeight: 400,
    height: 30,
    "&:hover": {
      background: theme.palette.error.dark,
      boxShadow: "none",
    },
  },
  stepCard: {
    boxShadow: "none",
  },
  finalStep: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

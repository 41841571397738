import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./TableResultsNotFound.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  resetSearch: PropTypes.func,
  resetFilters: PropTypes.func,
  handleAdd: PropTypes.func,
  withAdd: PropTypes.bool,
  render: PropTypes.bool,
  colSpan: PropTypes.number,
  search: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  resetSearch: () => {},
  resetFilters: () => {},
  handleAdd: () => {},
  withAdd: false,
  render: false,
  colSpan: 0,
  search: "",
};

/**
 * Displays the component
 */
const TableResultsNotFound = (props) => {
  const {
    helpers,
    options,
    resetSearch,
    resetFilters,
    search,
    render,
    colSpan,
    button,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the table options
   */
  const { withSearch, withAdd, addResult } = options;

  const noHelpers = Object.values(helpers).every((helper) => helper === false);
  const withResetSearch = helpers.resetSearch;
  const withResetFilters = helpers.resetFilters;
  const withAddResult = helpers.addResult;

  /**
   * Handles adding a new item (opens an add modal)
   */
  const handleAdd = () => {
    if (!withAdd) return;
    if (helpers.addResult) {
      addResult();
      resetSearch();
    }
  };

  /**
   * Gets the not found message
   */
  const getNotFoundMessage = () => {
    return noHelpers ||
      !withResetSearch ||
      !withSearch ||
      !withResetFilters ||
      !!resetFilters
      ? t("notFoundNoHelpers")
      : t("notFound");
  };

  /**
   * Displays the reset search helper button
   */
  const displayResetSearch = () => {
    return withResetSearch && withSearch ? (
      <Button
        {...button}
        type="button"
        variant="filled"
        onClick={resetSearch}
        disabled={search.length < 1}
        className={classes.button}
      >
        {t("resetSearch")}
      </Button>
    ) : null;
  };

  /**
   * Displays the reset filters helper button
   */
  const displayResetFilters = () => {
    return withResetFilters ? (
      <Button
        {...button}
        type="button"
        variant="filled"
        onClick={resetFilters}
        className={classes.button}
      >
        {t("resetFilters")}
      </Button>
    ) : null;
  };

  /**
   * Displays the add result helper button
   */
  const displayAddResult = () => {
    return withAddResult && withAdd ? (
      <Button
        {...button}
        type="button"
        variant="filled"
        onClick={handleAdd}
        className={classes.button}
      >
        {t("addNew")}
      </Button>
    ) : null;
  };

  /**
   * Displays the helpers
   */
  const displayHelpers = () => {
    return !noHelpers ? (
      <div className={classes.actions}>
        {displayResetSearch()}
        {displayResetFilters()}
        {displayAddResult()}
      </div>
    ) : null;
  };

  return render ? (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography variant="h2" className={classes.text}>
          {getNotFoundMessage()}
        </Typography>
        {displayHelpers()}
      </TableCell>
    </TableRow>
  ) : null;
};

TableResultsNotFound.propTypes = propTypes;
TableResultsNotFound.defaultProps = defaultProps;

export default TableResultsNotFound;
export {
  propTypes as TableResultsNotFoundPropTypes,
  defaultProps as TableResultsNotFoundDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import Routes from "./components/Routes";
import Providers, {
  ProvidersDefaultProps,
  ProvidersPropTypes,
} from "./components/Providers";
import { ThemeProvider } from "./hooks";

/**
 * Material UI Imports
 */
import { CssBaseline } from "@material-ui/core";

/**
 * Hooks
 */
import { LanguageProvider } from "./hooks";

/**
 * CSS Imports
 */
import "./App.css";

/**
 * Defines the prop types
 */
const propTypes = {
  providers: PropTypes.shape(ProvidersPropTypes),
};
/**
 * Defines the default props
 */
const defaultProps = {
  providers: ProvidersDefaultProps,
};

/**
 * Displays the component
 */
const App = (props) => {
  const { providers } = props;

  return (
    <LanguageProvider>
      <ThemeProvider>
        <Providers {...providers}>
          <CssBaseline>
            <Routes />
          </CssBaseline>
        </Providers>
      </ThemeProvider>
    </LanguageProvider>
  );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;

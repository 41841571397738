import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import CreateButton from "../CreateButton";
import EditButton from "../EditButton";
import DeleteButton from "../DeleteButton";
import Input from "../Input";
import ErrorMessages from "../ErrorMessages";
import AddWorkOrderProductDraftModal from "../AddWorkOrderProductDraftModal";
import AddWorkOrderServiceDraftModal from "../AddWorkOrderServiceDraftModal";
import EditWorkOrderProductDraftModal from "../EditWorkOrderProductDraftModal";
import EditWorkOrderServiceDraftModal from "../EditWorkOrderServiceDraftModal";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

/**
 * Hooks
 */
import { useTranslation } from "react-i18next";
import {
  useUser,
  useWorkOrder,
  useMessage,
  useApiClient,
  useProducts,
} from "../../hooks";
import { useHistory } from "react-router-dom";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreServiceCreateStep2.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  currency: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.strnig,
      align: PropTypes.string,
    })
  ),
};

/**
 * Defines the default props
 */
const defaultProps = {
  currency: "RON",
  headers: [
    { label: "#", align: "left" },
    { label: "itemName", align: "left" },
    { label: "um", align: "left" },
    { label: "quantity", align: "left" },
    { label: "price", align: "right" },
    { label: "totalLabel", align: "right" },
  ],
};

/**
 * Displays the component
 */
const TyreServiceCreateStep2 = (props) => {
  const { inputs, setInputs, handleInputChange, currency, headers } = props;
 
  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the work order hook
   */
  const { workOrder, workOrderType } = useWorkOrder();

  /**
   * Gets the products hook
   */
  const {
    filterServices,
    filterProducts,
    objectHasData,
    handleItemCostRendering,
    getBasePricing,
    getProductsRequestBody,
    getServicesRequestBody,
    calculateTotal,
    calculateDiscountSpecial,
    applyPriceConditions,
    getCarTypeName
  } = useProducts(); 

  /**
   * Initializes the products
   */
  const [products, setProducts] = useState([]);

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the product data for the modal
   */
  const [productData, setProductData] = useState({});

  /**
   * Initializes the service data for the modal
   */
  const [serviceData, setServiceData] = useState({});

  /**
   * Initializes the list of products
   */
  const [productsList, setProductsList] = useState([]);

  /**
   * Initializes the list of services
   */
  const [servicesList, setServicesList] = useState([]);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Initializes the special discount
   */
  const [specialDiscount, setSpecialDiscount] = useState(0);

  /**
   * Initializes the discount state
   */
  const [discount, setDiscount] = useState(0);

  /**
   * Initializes the tyre service state
   */
  const [tyreService, setTyreService] = useState({});

  /**
   * Initializes the payment types
   */
  const [paymentTypes, setPaymentTypes] = useState([]);

  /**
   * Initializes the payment type
   */
  const [paymentType, setPaymentType] = useState("");

  /**
   * Initializes the car types
   */
  const [carTypes, setCarTypes] = useState([]);

  /**
   * Initializes the subtotal state
   */
  const [subtotal, setSubtotal] = useState(0);

  /**
   * Initializes the tax state
   */
  const [tax, setTax] = useState(0);

  /**
   * Initializes the total state
   */
  const [total, setTotal] = useState(0);

  /**
   * Initializes the tyre service flag
   */
  const [isTyreService, setIsTyreService] = useState(false);

  /**
   * Initializes the check conditions flag
   */
  const [toCheckConditions, setToCheckConditions] = useState(true);

  /**
   * Initializes the other data state holder
   */
  const [otherData, setOtherData] = useState({});

  /**
   * Initializes the has hotel service flag
   */
  const [hasHotelService, setHasHotelService] = useState(false);

  /**
   * Initializes the modal states
   */
  const [modalState, setModalState] = useState({
    addProduct: false,
    editProduct: false,
    addService: false,
    editService: false,
  });

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Gets the current user
   */
  const { user, setUser } = useUser();

  /**
   * Handles searching for services
   */
  const searchServices = async (data) => {
    try {
      const foundServices = await apiClient.post("/products/search", data);
      if (foundServices) {
        const { data } = foundServices;

        const services = data.items;
        const servicesList = filterServices(services, inputs, user, workOrderType);

        setServicesList(servicesList);
        setUser((prevState) => ({ ...prevState, services: servicesList }));
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages error={error} />,
      });
    }
  };

  /**
   * Handles searching for products
   */
  const searchProducts = async (data) => {
    try {
      const foundProducts = await apiClient.post("/products/search", data);
      if (foundProducts) {
        const { data } = foundProducts;

        const products = data.items;
        const productsList = filterProducts(products, inputs);

        setProductsList(productsList);
        setUser((prevState) => ({ ...prevState, products: productsList }));
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages error={error} />,
      });
    }
  };

  /**
   * Handles rendering empty cells
   */
  const renderEmptyCells = (count) => {
    if (!count) return;
    let cells = Array(count)
      .fill(null)
      .map((_, idx) => idx);
    return cells && cells.map((cell) => <TableCell key={cell}></TableCell>);
  };

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.settings) {
      const paymentTypes = user.settings.account.payment_types;
      setPaymentTypes(paymentTypes);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles prefilling the product options list upon mounting
   */
  useEffect(() => {
    if ((user.products && user.products.length < 0) || !user.products) {
      const data = getProductsRequestBody();
      searchProducts(data);
    } else {
      setProductsList(user.products);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Handles prefilling the product options list upon mounting
   */
  useEffect(() => {
    const data = getServicesRequestBody();
    searchServices(data);
    // eslint-disable-next-line
  }, [inputs.car_type, inputs.tyre_rim]);

  /**
   * Handles updating the tax input
   */
  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      tax: parseFloat(tax),
    }));
    // eslint-disable-next-line
  }, [tax]);

  /**
   * Handles updating the total input
   */
  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      total: parseFloat(total),
    }));
    // eslint-disable-next-line
  }, [total]);

  /**
   * Handles updating the payment_type_id input
   */
  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      payment_type_id: paymentType,
    }));
    // eslint-disable-next-line
  }, [paymentType]);

  /**
   * Handles updating the special discount
   */
  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      specialDiscount: specialDiscount,
    }));
    // eslint-disable-next-line
  }, [specialDiscount]);

  /**
   * Handles updating subtotal
   */
  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      subtotal: parseFloat(subtotal),
    }));
    // eslint-disable-next-line
  }, [subtotal]);

  /**
   * Gets the inputs and errors
   */
  const {
    tyre_hotel_tyre_width,
    tyre_hotel_tyre_height,
    tyre_hotel_tyre_rim,
    tyre_hotel_tyre_name,
    tyre_hotel_tyre_quantity,
    tyre_hotel_description,
  } = inputs;

  /**
   * Updates the inputs, adds the hasHotelService flag
   */
  useEffect(() => {
    if (ready) {
      setInputs((prevState) => ({ ...prevState, hasHotelService }));
    }
    // eslint-disable-next-line
  }, [hasHotelService, ready]);

  /**
   * Handles updating the inputs state based on workOrder data from the provider
   */
  useEffect(() => {
    if (
      workOrder &&
      Object.keys(workOrder).length > 0 &&
      paymentTypes.length > 0
    ) {
      /**
       * Gets the work ordre data
       */
      const { products, work_order, tyre_hotel, tyre_service } = workOrder;
  
      /**
       * Updates the list of products
       */
      if (products && products.length > 0) {
        setProducts(products);
      }

      /**
       * Updates the discount state
       */
      if (work_order.discount) {
        setDiscount(work_order.discount);
      }

      /**
       * Updates the tyre service state
       */
      setTyreService(tyre_service);

      /**
       * Updates the payment type
       */
      if (work_order.payment_type_id) {
        setPaymentType(work_order.payment_type_id);
      } else if (!paymentType) {
        setPaymentType(paymentTypes[0].id);
      }

      /**
       * Sets the tyre hotel inputs if a tyre hotel was added
       */
      if (hasHotelService && tyre_hotel && Object.keys(tyre_hotel).length > 0) {
        setInputs((prevState) => ({
          ...prevState,
          tyre_hotel_tyre_width: tyre_hotel
            ? tyre_hotel.tyre_width
            : tyre_service.tyre_width,
          tyre_hotel_tyre_height: tyre_hotel
            ? tyre_hotel.tyre_height
            : tyre_service.tyre_height,
          tyre_hotel_tyre_rim: tyre_hotel
            ? tyre_hotel.tyre_rim
            : tyre_service.tyre_rim,
          tyre_hotel_tyre_name: tyre_hotel ? tyre_hotel.tyre_name : "",
          tyre_hotel_tyre_quantity: tyre_hotel ? tyre_hotel.tyre_quantity : 0,
          tyre_hotel_description: tyre_hotel ? tyre_hotel.description : "",
        }));
      } else {
        /**
         * Uses the tyre service inputs to update the matching fields in the tyre hotel
         * Only triggers if has hotel service is true and the fields of tyre hotel have not been filled in.
         */
        if (
          tyre_service &&
          Object.keys(tyre_service).length > 0 &&
          hasHotelService
        ) {
          setInputs((prevState) => ({
            ...prevState,
            tyre_hotel_tyre_width: tyre_service ? tyre_service.tyre_width : 0,
            tyre_hotel_tyre_height: tyre_service ? tyre_service.tyre_height : 0,
            tyre_hotel_tyre_rim: tyre_service ? tyre_service.tyre_rim : 0,
          }));
        }
      }
    }
    // eslint-disable-next-line
  }, [otherData, hasHotelService]);

  /**
   * Handles updating the has hotel service flag if a hotel service was added
   * Fills in the inputs with tyre service inputs if any exist.
   */
  useEffect(() => {
    if (products.length > 0) {
      /**
       * Handles finding products that are eligible for special discounts or behavior
       * Such as the tyre hotel service.
       */
      const found = products.find((product) => {
        if (product.is_service) {
          const cleanValue = product.name.toLowerCase().replace(/\s/g, "");
          const targetValue = "hotelanve";

          if (cleanValue.includes(targetValue)) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      });

      /**
       * Updates the inputs of the tyre hotel with the tyre_service data if any exists
       */
      if (found) {
        const { tyre_service } = workOrder;

        if (Object.keys(tyre_service).length > 0) {
          setInputs((prevState) => ({
            ...prevState,
            tyre_hotel_tyre_width: tyre_service ? tyre_service.tyre_width : 0,
            tyre_hotel_tyre_height: tyre_service ? tyre_service.tyre_height : 0,
            tyre_hotel_tyre_rim: tyre_service ? tyre_service.tyre_rim : 0,
            tyre_hotel_tyre_quantity: found.quantity,
          }));
        }

        /**
         * Updates the has hote service flag
         */
        setHasHotelService(true);
      }
      setReady(true);
    }
    // eslint-disable-next-line
  }, [products]);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the delete function
   */
  const handleDelete = (id) => {
    const newProducts = products.filter(
      (product) => product.organization_product_id !== id
    );
    setProducts(newProducts);
  };

  /**
   * Handles formatting the products and applying price conditions where eligible
   */
  const getProductData = (data) => {
    let dataIndex = -1;
    const exists = products.find((product, index) => {
      if (product.organization_product_id === data.organization_product_id) {
        dataIndex = index;
        return true;
      }
      return false;
    });

    if (exists) {
      const newProducts = [];
      products.forEach((product, idx) => {
        if (idx === dataIndex) {
          newProducts.push({
            ...product,
            quantity: product.quantity + data.quantity,
            total: (product.quantity + data.quantity) * product.price,
          });
        } else {
          newProducts.push(product);
        }
      });

      setProducts(applyPriceConditions(newProducts, tyreService));
    } else {
      setProducts((prevState) => [...prevState, { ...data }]);
    }
  };

  /**
   * Defines the product modal handlers
   */
  const openAddProduct = () => handleOpenModal("addProduct");
  const closeAddProduct = () => handleCloseModal("addProduct");

  /**
   * Handles opening the edit product modal
   */
  const openEditProduct = (props) => setProductData({ ...props });

  /**
   * Handles closing the edit product modal
   */
  const closeEditProduct = () => {
    setProductData({});
    handleCloseModal("editProduct");
  };

  /**
   * Handles opening the add service modal
   */
  const openAddService = () => handleOpenModal("addService");

  /**
   * Handles closing the add service modal
   */
  const closeAddService = () => handleCloseModal("addService");

  /**
   * Handles opening the edit service modal
   */
  const openEditService = (props) => setServiceData({ ...props });

  /**
   * Handles closing the edit service modal
   */
  const closeEditService = () => {
    setServiceData({});
    handleCloseModal("editService");
  };

  /**
   * Handles updating the product data by finding it in the list of products via index.
   */
  const updateProduct = (data, index) => {
    const newProducts = [];
    products.forEach((product, idx) => {
      if (idx === index) {
        newProducts.push(data);
      } else {
        newProducts.push(product);
      }
    });
    setProducts(newProducts);
  };

  /**
   * Handles rerouting to the products page
   */
  const goToProductsPage = () =>
    history.push("/modules/workstation-settings/products");

  /**
   * Handle rendering the products
   */
  const renderTableData = (items) => {
    return (
      items &&
      items.map((item, idx) => {
        const {
          name,
          um,
          organization_product_id,
          quantity,
          is_count_price,
          is_service,
        } = item;
        /**
         * Handles deleting the item
         */
        const deleteItem = () => {
          handleDelete(organization_product_id);
        };

        /**
         * Defines the table cell data
         */
        const count = idx + 1;
        const price = handleItemCostRendering(item, "price");
        const total = handleItemCostRendering(item, "total");
        const quant = Number(quantity).toFixed(2);
        const unitMeasure = um ? um : t("piece");

        /**
         * Defines the action button classes
         */
        const btnClasses = clsx(classes.smallBtn, classes.smallBtnMargins);
        const handleEdit = () =>
          is_service
            ? openEditService({ ...item, index: idx })
            : openEditProduct({ ...item, index: idx });

        return (
          <TableRow key={idx}>
            <TableCell
              component="th"
              scope="row"
              size="small"
              className={classes.itemCount}
            >
              {count}
            </TableCell>
            <TableCell className={classes.name}>
              <div className={classes.itemMain}>
                <span className={classes.nameSpan}>{name}</span>
                {!is_count_price && (
                  <Tooltip
                    title={t("notCountPriceExplanation")}
                    TransitionComponent={Zoom}
                    placement="top"
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.popper,
                    }}
                  >
                    <span
                      className={classes.notCountPrice}
                      onClick={goToProductsPage}
                    >
                      <WarningIcon />
                      {t("notActivated")}
                    </span>
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell>{unitMeasure}</TableCell>
            <TableCell>{quant}</TableCell>
            <TableCell align="right">{price}</TableCell>
            <TableCell align="right">{total}</TableCell>
            <TableCell>
              <div className={classes.actions}>
                <EditButton
                  title={t("editItem")}
                  className={btnClasses}
                  onClick={handleEdit}
                />
                <DeleteButton
                  title={t("deleteItem")}
                  className={btnClasses}
                  loading={false}
                  spinnerSize={24}
                  onClick={deleteItem}
                />
              </div>
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  /**
   * Handles rendering the table headers
   */
  const renderTableHeaders = (dataTable) => {
    return (
      dataTable &&
      dataTable.length > 0 &&
      headers.map((header, idx) => {
        const { align, label } = header;

        /**
         * Gets the table cell classes
         */
        const getClassName = () => {
          if (idx === 0) return classes.itemCount;
          if (idx === 1) return classes.name;
        };

        return (
          <TableCell key={idx} align={align} className={getClassName()}>
            {t(label)}
          </TableCell>
        );
      })
    );
  };

  /**
   * Handles opening the edit product modal if the data is set
   */
  useEffect(() => {
    if (objectHasData(productData)) handleOpenModal("editProduct");
  // eslint-disable-next-line
  }, [productData]);

  /**
   * Handles opening the edit product modal if the data is set
   */
  useEffect(() => {
    if (objectHasData(serviceData)) handleOpenModal("editService");
    // eslint-disable-next-line
  }, [serviceData]);

  /**
   * Handles updating the base pricing using the data from the tables
   */
  useEffect(() => {
    if (products.length > 0) {
      const { subtotal, tax } = getBasePricing(products, workOrder);

      setSubtotal(subtotal);
      setTotal(calculateTotal(subtotal, discount, specialDiscount));
      setTax(tax);
    }

    // eslint-disable-next-line
  }, [products, workOrder, discount, specialDiscount]);

  /**
   * Handles calculating the special discount
   */
  useEffect(() => {
    if (products.length > 0) {
      const specialDiscount = calculateDiscountSpecial(
        products,
          getCarTypeName(inputs.car_type, carTypes)
      );
      setSpecialDiscount(specialDiscount);
    }
    // eslint-disable-next-line
  }, [products]);

  /**
   * Handles setting the work order local state
   * Sets the has hotel service flag
   */
  useEffect(() => {
    if (workOrder) {
      setOtherData(workOrder);
      setHasHotelService(workOrder.hasHotelService || inputs.hasHotelService);
    }
    // eslint-disable-next-line
  }, [workOrder]);

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      setCarTypes(carTypes);
      setReady(true);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles edgecase where total could become negative due to discount
   * In this case it resets the total to 0, the discount becomes the subtotal
   */
  useEffect(() => {
    if (productsList.length > 0 && servicesList.length > 0) {
      if (Math.sign(total) === -1 || Number(subtotal) < Number(discount)) {
        setTotal(0);
        setDiscount(subtotal);
      }
    }
    // eslint-disable-next-line
  }, [total, subtotal, discount, productsList, servicesList]);

  /**
   * If there are no products, reset the total and subtotal.
   */
  useEffect(() => {
    if (products.length < 1 && subtotal > 0) {
      setSubtotal(0);
      setTotal(0);
    }
    // eslint-disable-next-line
  }, [products, subtotal]);

  /**
   * Handles applying price conditions
   */
  useEffect(() => {
    if (toCheckConditions && products.length > 0) {
      const newProducts = applyPriceConditions(products, tyreService);

      let subtotal = 0;
      /**
       * Handles incrementing the result;
       */
      const incrementResult = (array) => {
        if (!array) return;
        array.forEach((item) => {
          const { is_count_price, price, quantity } = item;
          if (is_count_price && price > 0) {
            subtotal += price * quantity;
          }
        });
      };

      incrementResult(newProducts);
      setSubtotal(subtotal);
      setProducts(newProducts);
      setToCheckConditions(false);
    }
    // eslint-disable-next-line
  }, [products, toCheckConditions, tyreService]);

  /**
   * Handles togging the tyre hotel service button
   * Resets the state when turned off
   */
  const handleToggleTyreHotel = () => {
    if (hasHotelService) {
      setInputs((prevState) => ({
        ...prevState,
        tyre_hotel_tyre_height: "",
        tyre_hotel_tyre_width: "",
        tyre_hotel_tyre_name: "",
        tyre_hotel_tyre_quantity: 0,
        tyre_hotel_tyre_rim: "",
        tyre_hotel_description: "",
      }));
      return setHasHotelService(false);
    }
    return setHasHotelService(true);
  };

  /**
   * For Tyre service work order types
   * Sets the isTyreService flag
   */
  useEffect(() => {
    if (workOrderType) {
      const _workOrderType = user.settings.account.work_order_types.find(
        (wo) => wo.id === workOrderType
      );

      if (_workOrderType && _workOrderType.name === "Vulcanizare") {
        setIsTyreService(true);
      }
    }
    // eslint-disable-next-line
  }, [workOrderType]);

  /**
   * Handles adding the products to the inputs state
   */
  useEffect(() => {
    if (products.length > 0) {
      setInputs((prevState) => ({ ...prevState, products }));
    }
  // eslint-disable-next-line
  }, [products]);

  return (
    <Card className={classes.stepCard}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <div className={classes.choice}>
            <CreateButton
              className={classes.addProductBtn}
              onClick={openAddProduct}
              text={t("addProduct")}
              title={t("addProduct")}
            />
            <CreateButton
              className={classes.addServiceBtn}
              onClick={openAddService}
              text={t("addService")}
              title={t("addService")}
            />
            {isTyreService && (
              <CreateButton
                className={clsx(classes.addTyreHotelBtn, {
                  [classes.deleteTyreHotelBtn]: hasHotelService,
                })}
                icon={
                  hasHotelService ? (
                    <DeleteIcon className={classes.delIcon} />
                  ) : null
                }
                onClick={handleToggleTyreHotel}
                text={t(
                  hasHotelService ? "deleteTyreHotelForm" : "addTyreHotel"
                )}
                title={t(
                  hasHotelService ? "deleteTyreHotelForm" : "addTyreHotel"
                )}
              />
            )}
          </div>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="caption" className={classes.sectionTitle}>
              {t("products")}
            </Typography>
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <Fragment>{renderTableHeaders(products)}</Fragment>
                    {renderEmptyCells(1)}
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableData(products)}</TableBody>
              </Table>
            </TableContainer>
            <AddWorkOrderProductDraftModal
              open={modalState.addProduct}
              closeModal={closeAddProduct}
              getProductData={getProductData}
              products={productsList}
            />
            <AddWorkOrderServiceDraftModal
              open={modalState.addService}
              closeModal={closeAddService}
              getProductData={getProductData}
              products={applyPriceConditions(servicesList, tyreService)}
              tyreService={tyreService}
            />
            <EditWorkOrderProductDraftModal
              data={productData}
              open={modalState.editProduct}
              closeModal={closeEditProduct}
              updateProduct={updateProduct}
              products={productsList}
            />
            <EditWorkOrderServiceDraftModal
              data={serviceData}
              open={modalState.editService}
              closeModal={closeEditService}
              updateProduct={updateProduct}
              products={applyPriceConditions(servicesList, tyreService)}
            />
          </Grid>
          <Grid container>
            {hasHotelService && (
              <Grid item xs={9}>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    className={classes.sectionTitle}
                  >
                    {t("tyreHotel")}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Input
                        type="numeric"
                        className={classes.field}
                        inputNumeric={{
                          id: "tyre_hotel_tyre_width",
                          name: "tyre_hotel_tyre_width",
                          value: tyre_hotel_tyre_width,
                          variant: "outlined",
                          onChange: handleInputChange,
                          label: t("tyre_widthLabel"),
                          showHelper: false,
                          InputLabelProps: {
                            className: classes.label,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        type="numeric"
                        className={classes.field}
                        inputNumeric={{
                          id: "tyre_hotel_tyre_height",
                          name: "tyre_hotel_tyre_height",
                          value: tyre_hotel_tyre_height,
                          variant: "outlined",
                          onChange: handleInputChange,
                          label: t("tyre_heightLabel"),
                          showHelper: false,
                          InputLabelProps: {
                            className: classes.label,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        type="numeric"
                        className={classes.field}
                        inputNumeric={{
                          id: "tyre_hotel_tyre_rim",
                          name: "tyre_hotel_tyre_rim",
                          value: tyre_hotel_tyre_rim,
                          variant: "outlined",
                          onChange: handleInputChange,
                          label: t("tyre_rimLabel"),
                          showHelper: false,
                          InputLabelProps: {
                            className: classes.label,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Input
                        type="text"
                        className={classes.field}
                        inputText={{
                          id: "tyre_hotel_tyre_name",
                          name: "tyre_hotel_tyre_name",
                          value: tyre_hotel_tyre_name,
                          variant: "outlined",
                          onChange: handleInputChange,
                          label: t("tyre_nameLabel"),
                          showHelper: false,
                          InputLabelProps: {
                            className: classes.label,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Input
                        type="numeric"
                        className={classes.field}
                        inputNumeric={{
                          id: "tyre_hotel_tyre_quantity",
                          name: "tyre_hotel_tyre_quantity",
                          value: tyre_hotel_tyre_quantity,
                          variant: "outlined",
                          onChange: handleInputChange,
                          label: t("tyre_quantityLabel"),
                          showHelper: false,
                          InputLabelProps: {
                            className: classes.label,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="textarea"
                        inputTextarea={{
                          id: "tyre_hotel_description",
                          name: "tyre_hotel_description",
                          value: tyre_hotel_description,
                          onChange: handleInputChange,
                          showCount: true,
                          rows: 3,
                          rowsMax: 3,
                          maxChars: 100,
                          size: "medium",
                          variant: "outlined",
                          label: t("descriptionLabel"),
                          ariaLabel: t("descriptionLabel"),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={hasHotelService ? 3 : 12}>
              <div className={classes.formContainer}>
                <div className={classes.form}>
                  <div className={classes.pricingContainer}>
                    <div className={classes.pricing}>
                      <Typography className={classes.pricingLabel}>
                        {t("subtotal")}
                      </Typography>
                      <Typography className={classes.pricingValue}>
                        {subtotal} {currency}
                      </Typography>
                    </div>
                    <div className={classes.pricing}>
                      <Typography className={classes.pricingLabel}>
                        {t("discountLabel")}
                      </Typography>
                      <Typography className={classes.pricingValue}>
                        {specialDiscount ? specialDiscount : 0} {currency}
                      </Typography>
                    </div>
                    <div className={classes.pricing}>
                      <Typography className={classes.pricingLabel}>
                        {t("total")}
                      </Typography>
                      <Typography className={classes.pricingValue}>
                        {total} {currency}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TyreServiceCreateStep2.propTypes = propTypes;
TyreServiceCreateStep2.defaultProps = defaultProps;

export default TyreServiceCreateStep2;
export {
  propTypes as TyreServiceCreateStep2PropTypes,
  defaultProps as TyreServiceCreateStep2DefaultProps,
};

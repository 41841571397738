import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Styles Imports
 */
import { useStyles } from "./Span.styles";

/**
 * Defines the prop types
 */
const propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
    children: "",
    className: "",
    onClick: () => {}
};

/**
 * Displays the component
 */
const Span = props => {
    const { className, children, onClick } = props;

    /**
     * Gets the component styles
     */
    const classes = useStyles();

    /**
     * Defines the span classes
     */
    const spanClasses = clsx(classes.span, { [className]: true });

    return (
        <span onClick={onClick} className={spanClasses}>
            {children}
        </span>
    );
};

Span.propTypes = propTypes;
Span.defaultProps = defaultProps;

export default Span;
export { propTypes as SpanPropTypes, defaultProps as SpanDefaultProps };

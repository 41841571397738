import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import { useHistory } from "react-router-dom";

/**
 * Assets Import
 */
import accountSettingsImg from "./account_settings.png";
import carServiceImg from "./car_service.png";
import carWashImg from "./car_wash.png";
import tyreServiceImg from "./tyre_service.png";
import workstationSettingsImg from "./workstation_settings.png";
import reportsImg from "./reports.png";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

/**
 * Styles Imports
 */
import { useStyles } from "./ModuleCardMini.styles";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  path: PropTypes.string,
  delay: PropTypes.number,
  name: PropTypes.string,
  active: PropTypes.bool,
  onModuleChange: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  path: "",
  delay: 0,
  name: "",
  active: false,
  onModuleChange: () => {},
};

/**
 * Displays the component
 */
const ModuleCardMini = (props) => {
  const { path, name, icon, active: activeProp } = props;

  /**
   * Initializes the active state
   */
  const [active, setActive] = useState(true);

  /**
   * Initializes the suspended acc state
   */
  const [isSuspended, setIsSuspended] = useState(false);

  /**
   * Initializes the deleted acc state
   */
  const [isDeleted, setIsDeleted] = useState(false);

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles getting the image src
   */
  const getImageSrc = () => {
    switch (icon) {
      case "car-wash":
        return carWashImg;
      case "car-service":
        return carServiceImg;
      case "tyre-service":
        return tyreServiceImg;
      case "account-settings":
        return accountSettingsImg;
      case "workstation-settings":
        return workstationSettingsImg;
      case "reports":
        return reportsImg;
      default:
        return null;
    }
  };

  /**
   * Gets the history object
   */
  const history = useHistory();

  const handleRouting = () => history.push(`/modules/${path}`);

  const getActiveStatus = () => {
    const inactiveOrSuspended = !active || isSuspended;
    const isAdmin = user.type === "admin";
    const accSettings = path === "account-settings";

    return (
      (isSuspended && !isAdmin) ||
      (!active && !isAdmin) ||
      (inactiveOrSuspended && isAdmin && !accSettings) ||
      isDeleted
    );
  };

  /**
   * Defines the module container classes
   */
  const moduleContainerClasses = clsx(classes.moduleContainer, {
    [classes.disabledModuleContainer]: getActiveStatus(),
  });

  /**
   * Defines the module card classes
   */
  const ModuleCardMiniClasses = clsx(classes.moduleCard, {
    [classes.disabledModule]: getActiveStatus(),
  });

  /**
   * Defines the module card backdrop classes
   */
  const backdropClasses = clsx(classes.backdrop, {
    [classes.disabledBackdrop]: getActiveStatus(),
  });

  /**
   * Defines the module card img container classes
   */
  const imgContainerClasses = clsx(classes.imgContainer, {
    [classes.disabledContainer]: getActiveStatus(),
  });

  /**
   * Handles rendering the disabled or inactive status banner
   */
  const renderDisabledBanner = () => {
    return (
      getActiveStatus() && (
        <div className={classes.inactiveBox}>
          <Typography variant="h3" className={classes.inactiveTitle}>
            {t("disabled")}
          </Typography>
        </div>
      )
    );
  };

  /**
   * Updates the active state
   */
  useEffect(() => {
    if (activeProp) setActive(true);
    if (!activeProp) setActive(false);
  }, [activeProp]);

  useEffect(() => {
    if (user.account) {
      const isSuspended = user.account.status === "suspended";
      const isDeleted = user.account.status === "deleted";

      setIsSuspended(isSuspended);
      setIsDeleted(isDeleted);
    }
  }, [user]);

  return (
    <div className={classes.wrapper}>
      <div className={moduleContainerClasses}>
        <Card
          className={ModuleCardMiniClasses}
          title={!active ? t("disabled") : t(name)}
          onClick={handleRouting}
        >
          <CardContent>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <div className={classes.container}>
                  <div className={backdropClasses}>
                    <div className={imgContainerClasses}>
                      <img
                        alt={name}
                        className={classes.img}
                        src={getImageSrc()}
                      />
                    </div>
                    {renderDisabledBanner()}
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Typography
        variant="h3"
        className={classes.moduleTitle}
        onClick={handleRouting}
      >
        {t(name)}
      </Typography>
    </div>
  );
};

ModuleCardMini.propTypes = propTypes;
ModuleCardMini.defaultProps = defaultProps;

export default ModuleCardMini;
export {
  propTypes as ModuleCardMiniPropTypes,
  defaultProps as ModuleCardMiniDefaultProps,
};

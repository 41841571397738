import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./AddOrganizationModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./AddOrganizationModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    company_name: PropTypes.string,
    vat_number: PropTypes.string,
    reg_number: PropTypes.string,
    city: PropTypes.string,
    number: PropTypes.string,
    street: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string,
    company_phone: PropTypes.string,
    company_email: PropTypes.string,
    company_webpage: PropTypes.string,
    company_time_sheet: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string
  }),
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    company_name: "",
    vat_number: "",
    reg_number: "",
    city: "",
    number: "",
    street: "",
    county: "",
    country: "",
    company_phone: "",
    company_email: "",
    company_webpage: "",
    company_time_sheet: "",
    first_name: "",
    last_name: "",
    phone: "",
    gender: ""
  },
  genders: [
    { label: "Masculin", value: "male" },
    { label: "Feminin", value: "female" },
    { label: "Altul", value: "other" }
  ],
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const AddOrganizationModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    loadingText,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    genders,
    errorMessages
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user setter
   */
  const { setUser, user } = useUser();

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [formInputs, setFormInputs] = useState({});

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    setFormInputs(inputs);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    company_name,
    vat_number,
    reg_number,
    city,
    number,
    street,
    county,
    country,
    company_phone,
    company_email,
    company_webpage,
    company_time_sheet,
    first_name,
    last_name,
    gender,
    phone
  } = inputs;

  const {
    company_name: company_nameError,
    vat_number: vat_numberError,
    reg_number: reg_numberError,
    city: cityError,
    number: numberError,
    street: streetError,
    county: countyError,
    country: countryError,
    company_phone: company_phoneError,
    company_email: company_emailError,
    company_webpage: company_webpageError,
    company_time_sheet: company_time_sheetError,
    first_name: first_nameError,
    last_name: last_nameError,
    gender: genderError,
    phone: phoneError
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    closeModal();
  };

  /**
   * Handles adding a new organization
   */
  const addUserOrganization = async (data) => {
    try {
      const newOrganization = await apiClient.post("/organizations", data);

      if (newOrganization) {
        const { data } = newOrganization;
        setLoading(false);

        const organizations = user.organizations;
        organizations.push(data);

        setUser((prevState) => ({ ...prevState, organizations }));
        resetInputs();

        /**
         * Dispatches the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage")
        });
        handleCloseModal();
      }
    } catch (error) {
      setLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
        origin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    }
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        company_data: {
          name: formInputs.company_name,
          vat_number: formInputs.vat_number,
          reg_number: formInputs.reg_number,
          city: formInputs.city,
          number: formInputs.number,
          street: formInputs.street,
          county: formInputs.county,
          country: formInputs.country,
          phone: formInputs.company_phone,
          email: formInputs.company_email
        },
        contact_data: {
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          gender: formInputs.gender,
          phone: formInputs.phone
        }
      };

      if (formInputs.company_webpage)
        data.company_data["webpage"] = formInputs.company_webpage;
      if (formInputs.company_time_sheet)
        data.company_data["time_sheet"] = formInputs.company_time_sheet;

      addUserOrganization(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAddOrganization")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <div className={classes.box}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography className={classes.label}>
                              {t("companyData")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "company_name",
                                required: true,
                                name: "company_name",
                                value: company_name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("company_nameLabel"),
                                placeholder: t("company_namePlaceholder"),
                                error: company_nameError,
                                autoFocus: getAutoFocus().company_name
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "vat_number",
                                required: true,
                                name: "vat_number",
                                value: vat_number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("vat_numberLabel"),
                                placeholder: t("vat_numberPlaceholder"),
                                error: vat_numberError,
                                autoFocus: getAutoFocus().vat_number
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "reg_number",
                                required: true,
                                name: "reg_number",
                                value: reg_number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("reg_numberLabel"),
                                placeholder: t("reg_numberPlaceholder"),
                                error: reg_numberError,
                                autoFocus: getAutoFocus().reg_number
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "country",
                                required: true,
                                name: "country",
                                value: country,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("countryLabel"),
                                placeholder: t("countryPlaceholder"),
                                error: countryError,
                                autoFocus: getAutoFocus().country
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "city",
                                required: true,
                                name: "city",
                                value: city,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("cityLabel"),
                                placeholder: t("cityPlaceholder"),
                                error: cityError,
                                autoFocus: getAutoFocus().city
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "county",
                                required: true,
                                name: "county",
                                value: county,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("countyLabel"),
                                placeholder: t("countyPlaceholder"),
                                error: countyError,
                                autoFocus: getAutoFocus().county
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "street",
                                required: true,
                                name: "street",
                                value: street,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("streetLabel"),
                                placeholder: t("streetPlaceholder"),
                                error: streetError,
                                autoFocus: getAutoFocus().street
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "number",
                                required: true,
                                name: "number",
                                value: number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("numberLabel"),
                                error: numberError,
                                autoFocus: getAutoFocus().number
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "company_email",
                                required: true,
                                name: "company_email",
                                value: company_email,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("company_emailLabel"),
                                placeholder: t("company_emailPlaceholder"),
                                error: company_emailError,
                                autoFocus: getAutoFocus().company_email
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="phone"
                              className={classes.field}
                              inputPhone={{
                                id: "company_phone",
                                required: true,
                                name: "company_phone",
                                value: company_phone,
                                onChange: handleInputChange,
                                variant: "standard",
                                label: t("company_phoneLabel"),
                                placeholder: t("company_phonePlaceholder"),
                                error: company_phoneError,
                                autoFocus: getAutoFocus().company_phone
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "company_webpage",
                                name: "company_webpage",
                                value: company_webpage,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("company_webpageLabel"),
                                placeholder: t("company_webpagePlaceholder"),
                                error: company_webpageError,
                                autoFocus: getAutoFocus().company_webpage
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "company_time_sheet",
                                name: "company_time_sheet",
                                value: company_time_sheet,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("company_time_sheetLabel"),
                                placeholder: t("company_time_sheetPlaceholder"),
                                error: company_time_sheetError,
                                autoFocus: getAutoFocus().company_time_sheet
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.box}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography className={classes.label}>
                              {t("contactData")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "first_name",
                                required: true,
                                name: "first_name",
                                value: first_name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("first_nameLabel"),
                                error: first_nameError,
                                autoFocus: getAutoFocus().first_name
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "last_name",
                                required: true,
                                name: "last_name",
                                value: last_name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("last_nameLabel"),
                                error: last_nameError,
                                autoFocus: getAutoFocus().last_name
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="phone"
                              className={classes.field}
                              inputPhone={{
                                id: "phone",
                                name: "phone",
                                value: phone,
                                onChange: handleInputChange,
                                placeholder: "",
                                variant: "standard",
                                label: t("phoneLabel"),
                                error: phoneError,
                                autoFocus: getAutoFocus().phone
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className={classes.inputContainer}>
                              <Input
                                className={classes.selectField}
                                type="select"
                                inputSelect={{
                                  required: true,
                                  name: "gender",
                                  value: gender,
                                  onChange: handleInputChange,
                                  label: t("genderLabel"),
                                  options: genders,
                                  optionLabel: "label",
                                  optionValue: "value",
                                  error: genderError,
                                  autoFocus: getAutoFocus().gender
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitButton}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitAdd")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

AddOrganizationModal.propTypes = propTypes;
AddOrganizationModal.defaultProps = defaultProps;

export default AddOrganizationModal;
export {
  propTypes as AddOrganizationModalPropTypes,
  defaultProps as AddOrganizationModalDefaultProps
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch } from "react-router-dom";

/**
 * Component Imports
 */
import AccountSettingsSidebar, {
  AccountSettingsSidebarDefaultProps,
  AccountSettingsSidebarPropTypes,
} from "../AccountSettingsSidebar";
import AccountSettingsMain, {
  AccountSettingsMainDefaultProps,
  AccountSettingsMainPropTypes,
} from "../AccountSettingsMain";
import AccountSettingsOrganizations, {
  AccountSettingsOrganizationsDefaultProps,
  AccountSettingsOrganizationsPropTypes,
} from "../AccountSettingsOrganizations";
import AccountSettingsUsers, {
  AccountSettingsUsersDefaultProps,
  AccountSettingsUsersPropTypes,
} from "../AccountSettingsUsers";
import AccountSettingsWorkers, {
  AccountSettingsWorkersDefaultProps,
  AccountSettingsWorkersPropTypes,
} from "../AccountSettingsWorkers";
import AccountSettingsPaymentMethods, {
  AccountSettingsPaymentMethodsDefaultProps,
  AccountSettingsPaymentMethodsPropTypes,
} from "../AccountSettingsPaymentMethods";
import AccountSettingsCarTypes, {
  AccountSettingsCarTypesDefaultProps,
  AccountSettingsCarTypesPropTypes,
} from "../AccountSettingsCarTypes";
import AccountSettingsOrders, {
  AccountSettingsOrdersDefaultProps,
  AccountSettingsOrdersPropTypes,
} from "../AccountSettingsOrders";
import AccountSettingsUpgrade, {
  AccountSettingsUpgradeDefaultProps,
  AccountSettingsUpgradePropTypes,
} from "../AccountSettingsUpgrade";
import AccountSettingsAppointmentGroups, {
  AccountSettingsAppointmentGroupsDefaultProps,
  AccountSettingsAppointmentGroupsPropTypes,
} from "../AccountSettingsAppointmentGroups";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes,
} from "../PageNotFound";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(AccountSettingsSidebarPropTypes),
  main: PropTypes.shape(AccountSettingsMainPropTypes),
  organizations: PropTypes.shape(AccountSettingsOrganizationsPropTypes),
  users: PropTypes.shape(AccountSettingsUsersPropTypes),
  workers: PropTypes.shape(AccountSettingsWorkersPropTypes),
  paymentMethods: PropTypes.shape(AccountSettingsPaymentMethodsPropTypes),
  carTypes: PropTypes.shape(AccountSettingsCarTypesPropTypes),
  orders: PropTypes.shape(AccountSettingsOrdersPropTypes),
  upgrade: PropTypes.shape(AccountSettingsUpgradePropTypes),
  appointmentGroups: PropTypes.shape(AccountSettingsAppointmentGroupsPropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  path: PropTypes.string,
  paths: PropTypes.shape({
    main: PropTypes.string,
    organizations: PropTypes.string,
    users: PropTypes.string,
    workers: PropTypes.string,
    paymentMethods: PropTypes.string,
    carTypes: PropTypes.string,
    orders: PropTypes.string,
    appointmentGroups: PropTypes.string,
    upgrade: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  sidebar: AccountSettingsSidebarDefaultProps,
  main: AccountSettingsMainDefaultProps,
  organizations: AccountSettingsOrganizationsDefaultProps,
  users: AccountSettingsUsersDefaultProps,
  workers: AccountSettingsWorkersDefaultProps,
  paymentMethods: AccountSettingsPaymentMethodsDefaultProps,
  carTypes: AccountSettingsCarTypesDefaultProps,
  orders: AccountSettingsOrdersDefaultProps,
  upgrade: AccountSettingsUpgradeDefaultProps,
  appointmentGroups: AccountSettingsAppointmentGroupsDefaultProps,
  notFound: PageNotFoundDefaultProps,
  path: `/modules/account-settings`,
  paths: {
    main: AccountSettingsMainDefaultProps.path,
    organizations: AccountSettingsOrganizationsDefaultProps.path,
    users: AccountSettingsUsersDefaultProps.path,
    workers: AccountSettingsWorkersDefaultProps.path,
    paymentMethods: AccountSettingsPaymentMethodsDefaultProps.path,
    carTypes: AccountSettingsCarTypesDefaultProps.path,
    orders: AccountSettingsOrdersDefaultProps.path,
    appointmentGroups: AccountSettingsAppointmentGroupsDefaultProps.path,
    upgrade: AccountSettingsUpgradeDefaultProps.path,
  },
};

/**
 * Displays the component
 */
const AccountSettingsPage = (props) => {
  const {
    sidebar,
    main,
    organizations,
    users,
    workers,
    paymentMethods,
    carTypes,
    orders,
    upgrade,
    appointmentGroups,
    notFound,
    path: basePath,
    paths,
  } = props;

  /**
   * Gets the paths from the components
   */
  const {
    main: mainPath,
    organizations: organizationsPath,
    users: usersPath,
    workers: workersPath,
    paymentMethods: paymentMethodsPath,
    carTypes: carTypesPath,
    orders: ordersPath,
    appointmentGroups: appointmentGroupsPath,
    upgrade: upgradePath,
  } = paths;

  /**
   * Defines the path building function
   * Attaches the base path of the module to each individual path
   */
  const buildPath = (path) => `${basePath}${path}`;

  /**
   * Defines and builds the paths
   */
  const buildedPaths = {
    main: buildPath(mainPath),
    organizations: buildPath(organizationsPath),
    users: buildPath(usersPath),
    workers: buildPath(workersPath),
    paymentMethods: buildPath(paymentMethodsPath),
    carTypes: buildPath(carTypesPath),
    orders: buildPath(ordersPath),
    appointmentGroups: buildPath(appointmentGroupsPath),
    upgrade: buildPath(upgradePath),
  };

  return (
    <Fragment>
      <AccountSettingsSidebar {...sidebar} paths={buildedPaths} />
      <Switch>
        <Route exact path={buildedPaths.main}>
          <AccountSettingsMain {...main} />
        </Route>
        <Route exact path={buildedPaths.organizations}>
          <AccountSettingsOrganizations {...organizations} />
        </Route>
        <Route exact path={buildedPaths.users}>
          <AccountSettingsUsers {...users} />
        </Route>
        <Route exact path={buildedPaths.workers}>
          <AccountSettingsWorkers {...workers} />
        </Route>
        <Route exact path={buildedPaths.paymentMethods}>
          <AccountSettingsPaymentMethods {...paymentMethods} />
        </Route>
        <Route exact path={buildedPaths.carTypes}>
          <AccountSettingsCarTypes {...carTypes} />
        </Route>
        <Route exact path={buildedPaths.appointmentGroups}>
          <AccountSettingsAppointmentGroups {...appointmentGroups} />
        </Route>
        <Route exact path={buildedPaths.orders}>
          <AccountSettingsOrders {...orders} />
        </Route>
        <Route exact path={buildedPaths.upgrade}>
          <AccountSettingsUpgrade {...upgrade} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
    </Fragment>
  );
};

AccountSettingsPage.propTypes = propTypes;
AccountSettingsPage.defaultProps = defaultProps;

export default AccountSettingsPage;
export {
  propTypes as AccountSettingsPagePropTypes,
  defaultProps as AccountSettingsPageDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Assets Imports
 */
import dotGrid from "./dot-grid.png";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modulePage: {
    background: "transparent",
    backgroundImage: `url(${dotGrid})`,
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  backdrop: {
    background: "rgba(0,0,0,0.3)",
    zIndex: 1200
  }
}));

export { useStyles };

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./AddClientModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./AddClientModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  defaultValues: PropTypes.shape({
    is_company: PropTypes.bool,
    fidelity: PropTypes.bool,
    name: PropTypes.string,
    delegate_name: PropTypes.string,
    description: PropTypes.string,
    vat_number: PropTypes.string,
    reg_number: PropTypes.string,
    organization_id: PropTypes.any,
    email: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    number: PropTypes.string,
    street: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string
  }),
  parent: PropTypes.string,
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultValues: {
    is_company: false,
    fidelity: false,
    name: "",
    delegate_name: "",
    description: "",
    vat_number: "",
    reg_number: "",
    organization_id: "",
    email: "",
    phone: "",
    city: "",
    number: "",
    street: "",
    county: "",
    country: "România"
  },
  parent: "workstation",
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const AddClientModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    handleDefaultSearch,
    form,
    loadingText,
    input,
    button,
    setTab,
    parent,
    setNewClient
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles creating a client
   */
  const createClient = async (data) => {
    try {
      const createdClient = await apiClient.post("/clients", data);

      if (createdClient) {
        const { data } = createdClient;

        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage")
        });

        if (parent === "workstation") {
          setTab(0);
          handleDefaultSearch();
          handleCloseModal();
        } else {
          setNewClient(data);
          handleCloseModal();
        }
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);
    }
  };

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the organizations state
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    is_company,
    fidelity,
    name,
    delegate_name,
    description,
    vat_number,
    reg_number,
    email,
    phone,
    city,
    number,
    street,
    county,
    country,
    organization_id
  } = inputs;

  const {
    name: nameError,
    delegate_name: delegate_nameError,
    vat_number: vat_numberError,
    reg_number: reg_numberError,
    email: emailError,
    number: numberError,
    phone: phoneError,
    city: cityError,
    street: streetError,
    county: countyError,
    country: countryError
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    closeModal();
  };

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name
        };
      });
      setOrganizations(orgs);
      setInputs((prevState) => {
        return { ...prevState, organization_id: orgs[0].value };
      });
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {};
      const client = {
        is_company,
        fidelity,
        name,
        organization_id
      };

      if (description) client["description"] = description;
      if (is_company) {
        client["delegate_name"] = delegate_name;

        const company = {
          vat_number,
          reg_number
        };
        const address = {
          city,
          number,
          street,
          county,
          country
        };

        if (phone) company["phone"] = phone;
        if (email) company["email"] = email;

        data["company"] = company;
        data["address"] = address;
      } else {
        if (phone) client["phone"] = phone;
        if (email) client["email"] = email;
      }
      data["client"] = client;
      createClient(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (parent !== "workstation") {
      if (user.organization) {
        setInputs((prevState) => ({
          ...prevState,
          organization_id: user.organization.id
        }));
      }
    }
    // eslint-disable-next-line
  }, [parent, user, open]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={false}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAddClient")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="add-client-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6} className={classes.checkboxContainer}>
                          <Input
                            {...input}
                            type="checkbox"
                            className={classes.checkbox}
                            inputCheckbox={{
                              id: "is_company",
                              required: true,
                              name: "is_company",
                              value: is_company,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("is_companyLabel")
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.checkboxContainer}>
                          <Input
                            {...input}
                            type="checkbox"
                            className={classes.checkbox}
                            inputCheckbox={{
                              id: "fidelity",
                              name: "fidelity",
                              value: fidelity,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("fidelityLabel")
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "name",
                              required: true,
                              name: "name",
                              value: name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("nameLabel"),
                              error: nameError,
                              autoFocus: getAutoFocus().name
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              required: true,
                              disabled: parent !== "workstation",
                              name: "organization_id",
                              value: organization_id,
                              onChange: handleInputChange,
                              label: t("organization_idLabel"),
                              options: organizations,
                              optionLabel: "label",
                              optionValue: "value"
                            }}
                          />
                        </Grid>
                        <Zoom in={is_company} mountOnEnter unmountOnExit>
                          <Grid item xs={12} md={3}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "delegate_name",
                                required: true,
                                name: "delegate_name",
                                value: delegate_name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("delegate_nameLabel"),
                                error: delegate_nameError,
                                autoFocus: getAutoFocus().delegate_name
                              }}
                            />
                          </Grid>
                        </Zoom>
                      </Grid>
                      {!is_company && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "email",
                                name: "email",
                                value: email,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("emailLabel"),
                                error: emailError,
                                autoFocus: getAutoFocus().email
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="phone"
                              className={classes.field}
                              inputPhone={{
                                id: "phone",
                                name: "phone",
                                value: phone,
                                required: true,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("phoneLabel"),
                                error: phoneError,
                                autoFocus: getAutoFocus().phone
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            className={classes.textarea}
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 2,
                              rowsMax: 2,
                              maxChars: 200,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel")
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Zoom in={is_company} mountOnEnter unmountOnExit>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "vat_number",
                                required: true,
                                name: "vat_number",
                                value: vat_number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("vat_numberLabel"),
                                error: vat_numberError,
                                autoFocus: getAutoFocus().vat_number
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "reg_number",
                                required: true,
                                name: "reg_number",
                                value: reg_number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("reg_numberLabel"),
                                error: reg_numberError,
                                autoFocus: getAutoFocus().reg_number
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "email",
                                name: "email",
                                value: email,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("emailLabel"),
                                error: emailError,
                                autoFocus: getAutoFocus().email
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="phone"
                              className={classes.field}
                              inputPhone={{
                                id: "phone",
                                name: "phone",
                                value: phone,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("phoneLabel"),
                                error: phoneError,
                                autoFocus: getAutoFocus().phone
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Zoom>

                      <Zoom in={is_company} mountOnEnter unmountOnExit>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "country",
                                required: true,
                                name: "country",
                                value: country,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("countryLabel"),
                                error: countryError,
                                autoFocus: getAutoFocus().country
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "county",
                                required: true,
                                name: "county",
                                value: county,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("countyLabel"),
                                error: countyError,
                                autoFocus: getAutoFocus().county
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "city",
                                required: true,
                                name: "city",
                                value: city,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("cityLabel"),
                                error: cityError,
                                autoFocus: getAutoFocus().city
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "street",
                                required: true,
                                name: "street",
                                value: street,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("streetLabel"),
                                error: streetError,
                                autoFocus: getAutoFocus().street
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "number",
                                required: true,
                                name: "number",
                                value: number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("numberLabel"),
                                error: numberError,
                                autoFocus: getAutoFocus().number
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Zoom>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            form="add-client-modal"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitAdd")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

AddClientModal.propTypes = propTypes;
AddClientModal.defaultProps = defaultProps;

export default AddClientModal;
export {
  propTypes as AddClientModalPropTypes,
  defaultProps as AddClientModalDefaultProps
};

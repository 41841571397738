import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Styles Imports
 */
import { useStyles } from "./CarWashMain.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  componentName: PropTypes.string,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  componentName: "CarWashMain",
  path: "/",
};

/**
 * Displays the component
 */
const CarWashMain = (props) => {
  const { componentName } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  return <div className={classes.CarWashMain}>{t(componentName)}</div>;
};

CarWashMain.propTypes = propTypes;
CarWashMain.defaultProps = defaultProps;

export default CarWashMain;
export {
  propTypes as CarWashMainPropTypes,
  defaultProps as CarWashMainDefaultProps,
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch, Redirect } from "react-router-dom";

/**
 * Component Imports
 */
import WorkstationSettingsSidebar, {
  WorkstationSettingsSidebarDefaultProps,
  WorkstationSettingsSidebarPropTypes
} from "../WorkstationSettingsSidebar";
import WorkstationSettingsWorkOrders, {
  WorkstationSettingsWorkOrdersDefaultProps,
  WorkstationSettingsWorkOrdersPropTypes
} from "../WorkstationSettingsWorkOrders";
import WorkstationSettingsClients, {
  WorkstationSettingsClientsDefaultProps,
  WorkstationSettingsClientsPropTypes
} from "../WorkstationSettingsClients";
import WorkstationSettingsProducts, {
  WorkstationSettingsProductsDefaultProps,
  WorkstationSettingsProductsPropTypes
} from "../WorkstationSettingsProducts";
import WorkstationSettingsLoyaltyCards, {
  WorkstationSettingsLoyaltyCardsDefaultProps,
  WorkstationSettingsLoyaltyCardsPropTypes
} from "../WorkstationSettingsLoyaltyCards";
import WorkstationSettingsContracts, {
  WorkstationSettingsContractsDefaultProps,
  WorkstationSettingsContractsPropTypes
} from "../WorkstationSettingsContracts";
import WorkstationSettingsTyreHotels, {
  WorkstationSettingsTyreHotelsDefaultProps,
  WorkstationSettingsTyreHotelsPropTypes
} from "../WorkstationSettingsTyreHotels";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes
} from "../PageNotFound";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(WorkstationSettingsSidebarPropTypes),
  workOrders: PropTypes.shape(WorkstationSettingsWorkOrdersPropTypes),
  clients: PropTypes.shape(WorkstationSettingsClientsPropTypes),
  hotels: PropTypes.shape(WorkstationSettingsTyreHotelsPropTypes),
  products: PropTypes.shape(WorkstationSettingsProductsPropTypes),
  loyaltyCards: PropTypes.shape(WorkstationSettingsLoyaltyCardsPropTypes),
  contracts: PropTypes.shape(WorkstationSettingsContractsPropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  path: PropTypes.string,
  paths: PropTypes.object
};

/**
 * Defines the default props
 */
const defaultProps = {
  sidebar: WorkstationSettingsSidebarDefaultProps,
  workOrders: WorkstationSettingsWorkOrdersDefaultProps,
  clients: WorkstationSettingsClientsDefaultProps,
  products: WorkstationSettingsProductsDefaultProps,
  loyaltyCards: WorkstationSettingsLoyaltyCardsDefaultProps,
  hotels: WorkstationSettingsTyreHotelsDefaultProps,
  contracts: WorkstationSettingsContractsDefaultProps,
  notFound: PageNotFoundDefaultProps,
  path: "/modules/workstation-settings",
  paths: {
    workOrders: WorkstationSettingsWorkOrdersDefaultProps.path,
    clients: WorkstationSettingsClientsDefaultProps.path,
    products: WorkstationSettingsProductsDefaultProps.path,
    loyaltyCards: WorkstationSettingsLoyaltyCardsDefaultProps.path,
    hotels: WorkstationSettingsTyreHotelsDefaultProps.path,
    contracts: WorkstationSettingsContractsDefaultProps.path
  }
};

/**
 * Displays the component
 */
const WorkstationSettingsPage = (props) => {
  const {
    sidebar,
    workOrders,
    clients,
    products,
    loyaltyCards,
    hotels,
    contracts,
    notFound,
    path: basePath,
    paths
  } = props;

  /**
   * Gets the paths from the components
   */
  const {
    workOrders: workOrdersPath,
    clients: clientsPath,
    products: productsPath,
    loyaltyCards: loyaltyCardsPath,
    hotels: hotelsPath,
    contracts: contractsPath
  } = paths;

  /**
   * Defines the path building function
   * Attaches the base path of the module to each individual path
   */
  const buildPath = (path) => `${basePath}${path}`;

  /**
   * Defines and builds the paths
   */
  const buildedPaths = {
    workOrders: buildPath(workOrdersPath),
    clients: buildPath(clientsPath),
    products: buildPath(productsPath),
    loyaltyCards: buildPath(loyaltyCardsPath),
    hotels: buildPath(hotelsPath),
    contracts: buildPath(contractsPath)
  };

  return (
    <Fragment>
      <WorkstationSettingsSidebar {...sidebar} paths={buildedPaths} />
      <Switch>
        <Route exact path={basePath}>
          <Redirect to={buildedPaths.workOrders} />
        </Route>
        <Route exact path={buildedPaths.workOrders}>
          <WorkstationSettingsWorkOrders {...workOrders} />
        </Route>
        <Route exact path={buildedPaths.clients}>
          <WorkstationSettingsClients {...clients} />
        </Route>
        <Route exact path={buildedPaths.products}>
          <WorkstationSettingsProducts {...products} />
        </Route>
        <Route exact path={buildedPaths.loyaltyCards}>
          <WorkstationSettingsLoyaltyCards {...loyaltyCards} />
        </Route>
        <Route exact path={buildedPaths.hotels}>
          <WorkstationSettingsTyreHotels {...hotels} />
        </Route>
        <Route exact path={buildedPaths.contracts}>
          <WorkstationSettingsContracts {...contracts} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
    </Fragment>
  );
};

WorkstationSettingsPage.propTypes = propTypes;
WorkstationSettingsPage.defaultProps = defaultProps;

export default WorkstationSettingsPage;
export {
  propTypes as WorkstationSettingsPagePropTypes,
  defaultProps as WorkstationSettingsPageDefaultProps
};

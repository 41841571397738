/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  listItem: {
    borderBottom: "1px solid #e0e0e0",
  },
  listIcon: {
    "& svg": {
      width: "2rem",
      height: "2rem",
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.accent}`,
      background: theme.palette.primary.accent,
      padding: "0.25rem",
      borderRadius: 3,
      cursor: "pointer",
      "&:hover": {
        border: `1px solid ${theme.palette.primary.dark}`,
        background: theme.palette.primary.dark,
      },
    },
  },
  flexContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    "& $listIcon": {
      display: "flex",
      minWidth: 0,
      "& svg": {
        width: "1.6rem",
        height: "1.6rem",
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.error.main}`,
        background: theme.palette.error.main,
        padding: "0.15rem",
        borderRadius: 3,
        cursor: "pointer",
        "&:hover": {
          border: `1px solid ${theme.palette.error.dark}`,
          background: theme.palette.error.dark,
        },
      },
    },
  },
  listText: {
    display: "flex",
    flexDirection: "column",
  },
  primary: {
    display: "flex",
    alignItems: "center",
  },
  plate_number: {
    color: "#5d5d5d",
    fontWeight: 800,
    fontFamily: theme.font.family,
    fontSize: "0.9rem",
    marginRight: "0.5rem",
  },
  uuid: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    fontSize: "0.75rem",
    color: theme.palette.primary.accent,
    opacity: 0.9,
  },
  secondary: {
    display: "flex",
    alignItems: "center",
  },
  created_at: {
    color: theme.palette.secondary.text,
    fontFamily: theme.font.family,
    fontSize: "0.8rem",
    opacity: 0.7,
    fontStyle: "italic",
  },
  notFound: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    color: theme.palette.error.main,
    fontWeight: 800,
  },
  recentlyCreated: {
    width: "100%",
    marginLeft: 0,
    paddingLeft: "1.5rem",
  },
  list: {
    border: `1px solid #cac2c2`,
    background: theme.palette.common.white,
    padding: 0,
    boxShadow: "2px 1px 5px 1px #c5c5c5",
    width: "100%",
  },
  listHeader: {
    borderBottom: "1px solid #e0e0e0",
    background: "#f3f3f3",
  },
  listTitleText: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    color: theme.palette.sidebar.text,
    fontWeight: 800,
  },
  box: {
    maxHeight: 500,
    overflow: "auto",
  },
  listFooter: {
    borderTop: "1px solid #e0e0e0",
    background: "#f3f3f3",
  },
  lowGutters: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  disclaimer: {
    fontSize: "0.75rem",
    fontFamily: theme.font.family,
    color: theme.palette.sidebar.text,
    display: "flex",
    alignItems: "center",
    fontWeight: 800,
    "& svg": {
      color: theme.palette.warning.main,
      marginRight: "0.25rem",
    },
  },
}));

export { useStyles };

import React, { useState} from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import DocumentModal, {DocumentModalDefaultProps, DocumentModalPropTypes} from "../DocumentModal";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./GuestFooter.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  documentModal: PropTypes.shape(DocumentModalPropTypes),
  currentYear: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  documentModal: DocumentModalDefaultProps,
  currentYear: new Date().getFullYear(),
};

/**
 * Displays the component
 */

const GuestFooter = (props) => {
  const { currentYear, documentModal } = props;

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the state of the modal
   */
  const [modalState, setModalState] = useState({
    terms_and_conditions: false,
    privacy_and_policy: false
  });

  /**
   * Initializes the documents state
   */
  const [documents, setDocuments] = useState({
    terms_and_conditions: "",
    privacy_and_policy: ""
  });

  /**
   * Handles updating the documents state
   */
  const updateDocument = props => {
    const { name, document } = props;

    setDocuments(prevState => {
      return { ...prevState, [name]: document };
    });
  };

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = type => {
    setModalState(prevState => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = type => {
    setModalState(prevState => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the Terms and conditions and privacy policy Modal Handlers
   */
  const openTermsModal = () => handleOpenModal("terms_and_conditions");
  const closeTermsModal = () => handleCloseModal("terms_and_conditions");

  const openPrivacyModal = () => handleOpenModal("privacy_and_policy");
  const closePrivacyModal = () => handleCloseModal("privacy_and_policy");


  /**
   * Handles rendering the GuestFooter
   */
  const renderGuestFooter = () => {
    return (
        <Grid container className={classes.footer}>
            <Grid item container xs={12} className={classes.footerItemsContainer}>
              <Typography className={classes.copyright}>{t("copyRightText",{year: currentYear})}</Typography>
              <div className={classes.links}>
                <Typography onClick={openPrivacyModal}>{t("privacy")}</Typography>
                <Typography onClick={openTermsModal}> {t("termsAndConditions")}</Typography>
              </div>
              <DocumentModal
                  {...documentModal}
                  title={t("termsAndConditions")}
                  documentName="terms_and_conditions"
                  document={documents.terms_and_conditions}
                  updateDocument={updateDocument}
                  open={modalState.terms_and_conditions}
                  closeModal={closeTermsModal}
              />
              <DocumentModal
                  {...documentModal}
                  title={t("privacyPolicy")}
                  documentName="privacy_and_policy"
                  document={documents.privacy_and_policy}
                  updateDocument={updateDocument}
                  open={modalState.privacy_and_policy}
                  closeModal={closePrivacyModal}
              />
            </Grid>
        </Grid>
    );
  };

  return renderGuestFooter();
};

GuestFooter.propTypes = propTypes;
GuestFooter.defaultProps = defaultProps;

export default GuestFooter;
export {
  propTypes as GuestFooterPropTypes,
  defaultProps as GuestFooterDefaultProps
};

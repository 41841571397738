import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import TableCellValue, {
  TableCellValueDefaultProps,
  TableCellValuePropTypes,
} from "../TableCellValue";
import TablePopover, {
  TablePopoverDefaultProps,
  TablePopoverPropTypes,
} from "../TablePopover";
import Input from "../Input";

/**
 *  Material UI Imports
 */
import TableRow from "@material-ui/core/TableRow";

/**
 * Styles Imports
 */
import { useStyles } from "./TableRows.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  cellValue: PropTypes.shape(TableCellValuePropTypes),
  popover: PropTypes.shape(TablePopoverPropTypes),
  collection: PropTypes.array,
  options: PropTypes.object,
  headers: PropTypes.array,
  getValue: PropTypes.func,
  tableData: PropTypes.array,
  specialStyles: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  cellValue: TableCellValueDefaultProps,
  popover: TablePopoverDefaultProps,
  collection: [],
  options: {},
  headers: [],
  getValue: () => {},
  tableData: [],
  specialStyles: [],
};

/**
 * Displays the component
 */
const TableRows = (props) => {
  const {
    cellValue,
    collection,
    options,
    headers,
    getValue,
    popover,
    tableData,
    specialStyles,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles({
    wrapped: options.actionsWrapped,
  });

 
  /**
   * Defines the table action buttons
   */
  const TableActionButtons = (props) => {
    const { row } = props;

    const actions = options.fields.find((field) => field.type === "actions");

    if (!actions) return null;
    if (actions.hidden) return null;

    const buttons = actions.buttons;

    /**
     * Handles rendering the actions
     */
    const renderActions = () =>
      buttons &&
      buttons.map((button, idx) => {
        let handleAction = () => button.action(row);

        if (button.type === "delete") {
          handleAction = () => button.action(row, collection);
        }

        if (button.type === "popover") {
          const id = `${idx}-${button.name}`;
          return (
            <TablePopover key={id} {...popover} {...button} id={id} row={row} />
          );
        }

        return (
          <span
            key={`${idx}-${button.name}`}
            className={classes.btnContainer}
            onClick={handleAction}
          >
            {button.component}
          </span>
        );
      });

    let specialClassName = "";

    specialStyles.forEach((style) => {
      if (row[style.key] === style.match) {
        specialClassName += " " + style.className;
      }
    });

    return (
      <TableCellValue
        {...cellValue}
        field={actions}
        key={`${actions.name}`}
        specialClassName={specialClassName}
      >
        <div className={classes.actions}>{renderActions()}</div>
      </TableCellValue>
    );
  };

  const getTableCellValues = (id, row, idx) => {
    return (
      headers &&
      headers.map((field) => {
        if (field.hidden) return null;
        if (field.type === "actions") return null;
        if (field.type === "action") {
          const actions = options.fields.find(
            (field) => field.type === "action"
          );

          if (!actions) return null;
          const key = field.name;

          if ((Array.isArray(row[key]) && row[key].length < 1) || !row[key]) {
            return <TableCellValue {...cellValue} field={field} value="-" />;
          }

          const buttons = actions.buttons;
          /**
           * Handles rendering the actions
           */
          const renderActions = () =>
            buttons &&
            buttons.map((button, idx) => {
              let handleAction = () => button.action(row);

              return (
                <span
                  key={`${idx}-${button.name}`}
                  className={classes.btnContainer}
                  onClick={handleAction}
                >
                  {field.showCount && (
                    <span className={classes.counter}>{row[key].length}</span>
                  )}
                  {button.component}
                </span>
              );
            });

          let specialClassName = "";

          specialStyles.forEach((style) => {
            if (row[style.key] === style.match) {
              specialClassName += " " + style.className;
            }
          });

          return (
            <TableCellValue
              {...cellValue}
              field={actions}
              specialClassName={specialClassName}
              key={`${actions.name}`}
            >
              <div className={classes.actions}>{renderActions()}</div>
            </TableCellValue>
          );
        } else if(field.type === "checkbox") {
 
          const checkbox = options.fields.find((field) => field.type === "checkbox");
 
          if (!checkbox) return null;
      
          let specialClassName = "";
      
          specialStyles.forEach((style) => {
            if (row[style.key] === style.match) {
              specialClassName += " " + style.className;
            }
          });
      
          return (
            <TableCellValue
              {...cellValue}
              field={checkbox}
              key={`${checkbox.name}`}
              specialClassName={specialClassName}
            >
              <div className={classes.actions}>
                <Input
                  type="checkbox"
                  inputCheckbox={{
                    id: checkbox.id,
                    name: checkbox.name,
                    value: checkbox.getValue(row),
                    onChange: e => checkbox.onChange(e, row),
                    maxSize: 70,
                    variant: "standard",
                    label: checkbox.getLabel(row),
                    classes: {
                      colorSecondary: classes.checkbox
                    }
                  }}
                />
              </div>
            </TableCellValue>
          );
        } else {
          const type = field.type ? field.type : "text";
          const hasFilter = !!field.displayFilter;
          const isCounter = !!field.displayCount;
          const options = field.options ? field.options : [];

          const value = getValue(
            hasFilter,
            isCounter,
            field,
            row,
            options,
            idx
          );

          let specialClassName = "";

          specialStyles.forEach((style) => {
            if (row[style.key] === style.match) {
              specialClassName += " " + style.className;
            }
          });

          return (
            <TableCellValue
              {...cellValue}
              field={field}
              specialClassName={specialClassName}
              key={`${id}-${field.name}`}
              value={value}
              type={type}
              row={row}
            />
          );
        }
      })
    );
  };

  /**
   * Handles rendering the table rows
   */
  const getTableRows = () => {
    if (headers && headers.length > 0 && tableData) {
      return (
        tableData &&
        tableData.length > 0 &&
        tableData.map((row, idx) => {
          /**
           *  Gets the row values
           */
          const { id } = row;
          return (
            <TableRow key={id}>
              <Fragment>{getTableCellValues(id, row, idx)}</Fragment>
              <TableActionButtons row={row} headers={headers} />
            </TableRow>
          );
        })
      );
    }
    return null;
  };

  return getTableRows();
};

TableRows.propTypes = propTypes;
TableRows.defaultProps = defaultProps;

export default TableRows;
export {
  propTypes as TableRowsPropTypes,
  defaultProps as TableRowsDefaultProps,
};

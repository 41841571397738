import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { useLocation, useRouteMatch } from "react-router-dom";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import ModuleCard, {
  ModuleCardDefaultProps,
  ModuleCardPropTypes,
} from "../ModuleCard";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ModulesModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  moduleCard: PropTypes.shape(ModuleCardPropTypes),
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  moduleCard: ModuleCardDefaultProps,
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const ModulesModal = (props) => {
  const {
    moduleCard,
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Gets the location path
   */
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const { path } = match;

  /**
   * Handles getting the modal size
   */
  const getModalSize = () => {
    const { modules } = user;
    if (!modules) return;
    if (modules && !Array.isArray(modules)) return;
    switch (modules.length) {
      case 0:
      case 1:
        return "xs";
      case 2:
      case 3:
        return "sm";
      case 4:
        return "md";
      case 5:
        return "lg";
      default:
        return "lg";
    }
  };

  /**
   * Handles getting the modal size
   */
  const getModuleCardSize = () => {
    const { modules } = user;
    if (!modules) return;
    if (modules && !Array.isArray(modules)) return;
    switch (modules.length) {
      case 0:
      case 1:
        return { xs: 12, md: 12, lg: 6, xl: 6 };
      case 2:
        return { xs: 12, md: 12, lg: 6, xl: 6 };
      case 3:
        return { xs: 12, md: 12, lg: 6, xl: 6 };
      case 4:
        return { xs: 12, md: 4, lg: 4, xl: 4 };
      case 5:
        return { xs: 12, md: 4, lg: 4, xl: 3 };
      default:
        return { xs: 12, md: 4, lg: 4, xl: 3 };
    }
  };

  /**
   * Renders the modules
   */
  const renderModules = () => {
    const { modules } = user;

    /**
     * Filter out the currently active module
     */
    const filteredModules =
      modules &&
      Array.isArray(modules) &&
      modules.filter((module) => !path.includes(module.path));

    return (
      filteredModules &&
      filteredModules.map((module) => {
        const { xs, md, lg, xl } = getModuleCardSize();

        return (
          <Grid key={module.path} item xs={xs} md={md} lg={lg} xl={xl}>
            <ModuleCard
              {...moduleCard}
              {...module}
              onModuleChange={closeModal}
              delay={0}
            />
          </Grid>
        );
      })
    );
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth={getModalSize()}
        open={open}
        onClose={closeModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAvailableModules")}
          onClick={closeModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            {renderModules()}
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={closeModal} />
      </Modal>
    </Fragment>
  );
};

ModulesModal.propTypes = propTypes;
ModulesModal.defaultProps = defaultProps;

export default ModulesModal;
export {
  propTypes as ModulesModalPropTypes,
  defaultProps as ModulesModalDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import DialogActions from "@material-ui/core/DialogActions";

/**
 * Styles Imports
 */
import { useStyles } from "./ModalActions.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    title: PropTypes.string,
    iconContainer: PropTypes.string,
    icon: PropTypes.string,
  }),
  text: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  classes: {
    root: "",
    container: "",
    title: "",
    iconContainer: "",
    icon: "",
  },
  text: "",
  children: null,
  onClick: null,
};

/**
 * Displays the component
 */
const ModalActions = (props) => {
  const { button, classes, text, onClick, children } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Defines the classes
   */
  const rootClasses = clsx(styles.actions, {
    [classes.root]: classes.root ? true : false,
  });
  const btnClasses = clsx(styles.button, {
    [classes.button]: classes.button ? true : false,
  });

  return (
    <DialogActions className={rootClasses}>
      {onClick && (
        <Button
          {...button}
          type="button"
          variant="filled"
          onClick={onClick}
          className={btnClasses}
        >
          {text ? text : t("close")}
        </Button>
      )}
      {children}
    </DialogActions>
  );
};

ModalActions.propTypes = propTypes;
ModalActions.defaultProps = defaultProps;

export default ModalActions;
export {
  propTypes as ModalActionsPropTypes,
  defaultProps as ModalActionsDefaultProps,
};

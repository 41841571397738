/**
* External Imports
*/
import { differenceInSeconds } from "date-fns";

/**
 * Gets the latest active work
 */
export const getLatestActiveWork = () => {
  const latestWork = JSON.parse(localStorage.getItem("drafts"));
  return latestWork || [];
}

/**
 * Handles getting the local storage timer related keys
 */
export const getLocalStorageTimeKeys = () => {
  const keys = [];
  let x;
  for (x in localStorage) {
    if (!localStorage.hasOwnProperty(x)) {
      continue;
    }
    if (x.slice(-8) === "newTimer") keys.push(x);
  }
  return { keys };
};

/**
* Handles cleaning the garbage
*/
export const cleanGarbage = () => {
  const latestActiveWork = getLatestActiveWork();

  if (latestActiveWork.length > 0) {
    const toBeRemoved = [];

    const { keys } = getLocalStorageTimeKeys();
    const timers = latestActiveWork.map((work) => {
      const { plateNumber } = work;
      return `${plateNumber}_newTimer`;
    });

    keys.forEach((key) => {
      if (!timers.includes(key)) {
        toBeRemoved.push(key);
      }
    });

    localStorage.removeItem(`undefined_newTimer`);
    toBeRemoved.forEach((key) => localStorage.removeItem(key));
  }
};


/**
* Sorts cards by timer
*/
export const sortCardsByTimer = (a, b) => {
  if (!a.plate_number || !b.plate_number) return;
  const itemA = JSON.parse(localStorage.getItem(`${a.plate_number}_newTimer`));
  const itemB = JSON.parse(localStorage.getItem(`${b.plate_number}_newTimer`));

  const A = new Date(itemA.endTime) - new Date(itemA.startTime);
  const B = new Date(itemB.endTime) - new Date(itemB.startTime);

  return B - A;
};

/**
* Handles filtering out the cards that will not be deleted
*/
export const filterCardsByPlateNumber = (timerCards, plate_number) =>
  timerCards.filter((card) => {
    return card.plateNumber !== plate_number;
  });

/**
 * Handles removing a card timer
 */
export const removeCardTimer = (plateNumber) => {
  localStorage.removeItem(`${plateNumber}_newTimer`);
}

export const filterRecentlyCreated = (recentlyCreated) => {
  return recentlyCreated.filter((work) => {
    let diffInSeconds = differenceInSeconds(
      new Date(),
      new Date(work.created_at)
    );

    const hours = Math.floor(diffInSeconds / 3600).toString();

    if (hours >= 12) return false;
    return true;
  });
}
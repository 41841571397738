/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;
  const { isEmpty, isEmail, isUrl } = useValidator();

  const validatorFn = (props) => {
    const { name, value } = props;
    let error = null;

    switch (name) {
      case "first_name":
      case "last_name":
      case "phone":
      case "gender":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "company_name":
      case "vat_number":
      case "reg_number":
      case "city":
      case "number":
      case "street":
      case "county":
      case "country":
      case "company_phone":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "company_email":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        if (!isEmail(value))
          return (error = t("LanguageProvider:error_emailInvalid"));
        return error;
      case "company_webpage":
        if (value && !isUrl(value))
          return (error = t("LanguageProvider:error_urlInvalid"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./AddTyreHotelModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./AddTyreHotelModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    work_order_uuid: PropTypes.string,
    client_name: PropTypes.string,
    organization_client_id: PropTypes.any,
    car_number: PropTypes.string,
    tyre_width: PropTypes.string,
    tyre_height: PropTypes.string,
    tyre_rim: PropTypes.string,
    tyre_name: PropTypes.string,
    tyre_quantity: PropTypes.string,
    description: PropTypes.string
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  workOrder: PropTypes.object,
  onSuccess: PropTypes.func,
  resetOnClose: PropTypes.bool
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    work_order_uuid: "",
    client_name: "",
    organization_client_id: "",
    car_number: "",
    tyre_width: "",
    tyre_height: "",
    tyre_rim: "",
    tyre_name: "",
    tyre_quantity: "",
    description: ""
  },
  open: false,
  closeModal: () => {},
  workOrder: {},
  onSuccess: null,
  resetOnClose: true
};

/**
 * Displays the component
 */
const AddTyreHotelModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    setUpdated,
    clients,
    workOrder,
    onSuccess,
    resetOnClose
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    setInputs,
    setErrors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    handleValidation,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    work_order_uuid,
    client_name,
    organization_client_id,
    car_number,
    tyre_width,
    tyre_height,
    tyre_rim,
    tyre_name,
    tyre_quantity,
    description
  } = inputs;

  const {
    work_order_uuid: work_order_uuidError,
    client_name: client_nameError,
    car_number: car_numberError,
    tyre_width: tyre_widthError,
    tyre_height: tyre_heightError,
    tyre_rim: tyre_rimError,
    tyre_name: tyre_nameError,
    tyre_quantity: tyre_quantityError
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    if (resetOnClose) resetInputs();

    setErrors({});
    setReady(false);
    closeModal();
  };

  /**
   * Handles creating a tyre hotel
   */
  const createTyreHotel = async (data) => {
    try {
      const createdTyreHotel = await apiClient.post("/tyre-hotels", data);
      if (createdTyreHotel) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage")
        });

        if (onSuccess) onSuccess(createdTyreHotel);
        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
        origin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    }
  };

  /**
   * Handles changing the client name
   */
  const handleClientChange = (event, newValue) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        organization_client_id: newValue,
        client_name: newValue
          ? clients.find((client) => client.name === newValue)
            ? newValue
            : prevState.client_name
          : prevState.client_name
      };
    });
  };

  const getClientID = (name) => {
    const found = clients.find((client) => client.name === name);
    return found ? found.id : "";
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        work_order_uuid
      };

      if (organization_client_id) {
        data["organization_client_id"] = getClientID(organization_client_id);
      }
      if (client_name) {
        data["client_name"] = client_name;
      }
      if (car_number) data["car_number"] = car_number;
      if (tyre_width) data["tyre_width"] = tyre_width;
      if (tyre_height) data["tyre_height"] = tyre_height;
      if (tyre_rim) data["tyre_rim"] = tyre_rim;
      if (tyre_name) data["tyre_name"] = tyre_name;
      if (tyre_quantity) data["tyre_quantity"] = tyre_quantity;
      if (description) data["description"] = description;

      createTyreHotel(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (workOrder && Object.keys(workOrder).length > 0 && open) {
      const { uuid, car_plate_number, tyre_service, client_name } = workOrder;

      setInputs((prevState) => ({
        ...prevState,
        organization_client_id: client_name,
        client_name: client_name,
        work_order_uuid: uuid,
        car_number: car_plate_number,
        tyre_width: tyre_service ? tyre_service.tyre_width : "",
        tyre_height: tyre_service ? tyre_service.tyre_height : "",
        tyre_rim: tyre_service ? tyre_service.tyre_rim : ""
      }));
    }
    // eslint-disable-next-line
  }, [workOrder, open]);

  useEffect(() => {
    if (client_nameError) {
      handleValidation("client_name", client_name);
    }
    // eslint-disable-next-line
  }, [client_nameError, client_name]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAddTyreHotel")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="add-tyre-hotel-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "work_order_uuid",
                              name: "work_order_uuid",
                              value: work_order_uuid,
                              required: true,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("work_order_uuidLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: work_order_uuidError,
                              autoFocus: getAutoFocus().work_order_uuid
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "car_number",
                              name: "car_number",
                              value: car_number,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("car_numberLabel"),
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: car_numberError,
                              autoFocus: getAutoFocus().car_number
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "tyre_name",
                              name: "tyre_name",
                              value: tyre_name,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("tyre_nameLabel"),
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: tyre_nameError,
                              autoFocus: getAutoFocus().tyre_name
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              id: "tyre_quantity",
                              name: "tyre_quantity",
                              value: tyre_quantity,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("tyre_quantityLabel"),
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: tyre_quantityError,
                              autoFocus: getAutoFocus().tyre_quantity
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              id: "tyre_width",
                              name: "tyre_width",
                              value: tyre_width,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("tyre_widthLabel"),
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: tyre_widthError,
                              autoFocus: getAutoFocus().tyre_width
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              id: "tyre_height",
                              name: "tyre_height",
                              value: tyre_height,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("tyre_heightLabel"),
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: tyre_heightError,
                              autoFocus: getAutoFocus().tyre_height
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              id: "tyre_rim",
                              name: "tyre_rim",
                              value: tyre_rim,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("tyre_rimLabel"),
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: tyre_rimError,
                              autoFocus: getAutoFocus().tyre_rim
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Input
                            {...input}
                            type="autocomplete"
                            className={classes.field}
                            inputAutocomplete={{
                              id: "organization_client_id",
                              name: "organization_client_id",
                              inputValue: organization_client_id,
                              onChange: handleClientChange,
                              variant: "outlined",
                              freeSolo: false,
                              autoComplete: false,
                              labelClass: classes.label,
                              InputLabelProps: {
                                className: classes.label
                              },
                              label: t("organization_client_idLabel"),
                              options: clients,
                              getOptionSelected: (option, value) => {
                                return option.id === value.id;
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              required: true,
                              id: "client_name",
                              name: "client_name",
                              value: client_name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              InputLabelProps: {
                                className: classes.label
                              },
                              variant: "outlined",
                              label: t("client_nameLabel"),
                              error: client_nameError,
                              autoFocus: getAutoFocus().client_name
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 6,
                              rowsMax: 14,
                              maxChars: 100,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel")
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitAdd")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

AddTyreHotelModal.propTypes = propTypes;
AddTyreHotelModal.defaultProps = defaultProps;

export default AddTyreHotelModal;
export {
  propTypes as AddTyreHotelModalPropTypes,
  defaultProps as AddTyreHotelModalDefaultProps
};

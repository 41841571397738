import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import { isSaturday, isSunday, isDate } from "date-fns";

/**
 * Material UI Imports
 */
import TableCell from "@material-ui/core/TableCell";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
/**
 * Styles Imports
 */
import { useStyles } from "./TableCellValue.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.oneOf([
    "text",
    "boolean",
    "email",
    "multiSelect",
    "attendance",
    "actions",
      "date",
      "checkbox"
  ]),
  value: PropTypes.any,
  classes: PropTypes.shape({
    cell: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
  }),
  row: PropTypes.object,
  specialClassName: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: "text",
  value: "",
  classes: {},
  row: {},
  specialClassName: "",
};

/**
 * Displays the component
 */
const TableCellValue = (props) => {
  const {
    type,
    value,
    classes,
    large,
    field,
    children,
    row,
    specialClassName,
  } = props;

  const { is_header } = row;

  /**
   * Gets the component styles
   */
  const styles = useStyles({
    align: field.align,
    minSize: field.minSize,
    maxSize: field.maxSize,
  });

  const isWeekend =
    field.value &&
    isDate(new Date(field.value)) &&
    (isSaturday(new Date(field.value)) || isSunday(new Date(field.value)));

  const tableCellHeaderClasses =
    "MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader";

  /**
   * Defines the component classes
   */
  const cellClasses = clsx(styles.cellValue, {
    [classes.cell]: classes.cell ? true : false,
    [styles.attended]: type === "attendance" && value > 0 && !isWeekend,
    [styles.notAttended]: type === "attendance" && value <= 0 && !isWeekend,
    [styles.large]: large,
    [tableCellHeaderClasses]: is_header,
    [specialClassName]: !!specialClassName,
    [field.styles && field.styles.cell]: field.styles && field.styles.cell,
  });

  /**
   * Handles rendering the value
   */
  const renderValue = () => {
    if (children) return children;
    if (typeof value === "object") return "";
    switch (type) {
      case "text":
        return value;
      case "attendance":
        return value ? value : "";
      case "boolean":
        return value ? (
          <div className={styles.status}>
            <DoneIcon className={styles.done} />
          </div>
        ) : (
          <div className={styles.status}>
            <ClearIcon className={styles.cancel} />
          </div>
        );
      default:
        return value;
    }
  };

  return <TableCell className={cellClasses}>{renderValue()}</TableCell>;
};

TableCellValue.propTypes = propTypes;
TableCellValue.defaultProps = defaultProps;

export default TableCellValue;
export {
  propTypes as TableCellValuePropTypes,
  defaultProps as TableCellValueDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { useHistory } from "react-router-dom";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 * Material UI Imports
 */
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./PageNotFound.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  errCode: PropTypes.string,
  errMessage: PropTypes.string,
  errMessageExtended: PropTypes.string,
  button: PropTypes.shape(ButtonPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  errCode: "404",
  errMessage: "notFound",
  errMessageExtended: "notFoundExtended",
  button: ButtonDefaultProps,
};

/**
 * Displays the component
 */
const PageNotFound = (props) => {
  const { errCode, errMessage, errMessageExtended, button } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Handles redirecting to the main page
   */
  const handleClick = () => history.push("/modules");

  return (
    <div className={classes.container}>
      <Zoom in={true} timeout={200}>
        <Typography variant="h1" className={classes.errCode}>
          {errCode}
        </Typography>
      </Zoom>
      <Zoom in={true} timeout={200}>
        <Typography variant="h2" className={classes.errMessage}>
          {t(errMessage)}
        </Typography>
      </Zoom>
      <Zoom in={true} timeout={200}>
        <Typography className={classes.errMessageExtended}>
          {t(errMessageExtended)}
        </Typography>
      </Zoom>
      <Zoom in={true} timeout={200}>
        <div className={classes.actions}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleClick}
            className={classes.button}
          >
            {t("backToMenu")}
          </Button>
        </div>
      </Zoom>
    </div>
  );
};

PageNotFound.propTypes = propTypes;
PageNotFound.defaultProps = defaultProps;

export default PageNotFound;
export {
  propTypes as PageNotFoundPropTypes,
  defaultProps as PageNotFoundDefaultProps,
};

/**
 * Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
  },
  input: {
    padding: 10,
    paddingLeft: 0,
    fontSize: "0.85rem",
    background: theme.palette.common.white,
  },
  borderLeft: {
    borderLeft: `1px solid #afafaf`,
    "&$error": {
      borderColor: theme.palette.error.main,
    },
  },
  borderRight: {
    borderRight: `1px solid #afafaf`,
    "&$error": {
      borderColor: theme.palette.error.main,
    },
  },
  inputRoot: {
    "&$inputFocused $inputOutlined": {
      borderWidth: 1,
    },
    "&:hover $inputOutlined": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover $error": {
      borderColor: theme.palette.error.main,
    },
  },
  inputFocused: {
    "& $inputOutlined": {
      borderWidth: 1,
    },
  },
  inputOutlined: {
    borderWidth: 1,
  },
  labelOutlined: {
    fontSize: "0.85rem",
    "&$marginDense": {
      transform: "translate(12px,12px) scale(1)",
    },
    "&$shrink": {
      transform: "translate(5px, -15px) scale(0.8)",
    },
    "&$marginDenseAdornment": {
      transform: "translate(40px,11px) scale(1)",
    },
    "&$shrinkAdornment": {
      transform: "translate(5px, -15px) scale(0.8)",
    },
  },
  marginDense: {
    transform: "translate(12px,12px) scale(1)",
  },
  shrink: {
    transform: "translate(5px, -15px) scale(0.8)",
  },
  marginDenseAdornment: {
    transform: "translate(40px,11px) scale(1)",
  },
  shrinkAdornment: {
    transform: "translate(5px, -15px) scale(0.8)",
  },
  helperMargins: {
    marginLeft: 4,
    marginRight: 4,
  },
  inputBaseDefault: {
    borderColor: "#afafaf",
  },
  inputBasePrimary: {
    borderColor: theme.palette.primary.main,
  },
  inputBaseSecondary: {
    borderColor: theme.palette.secondary.main,
  },
  inputBaseError: {
    borderColor: theme.palette.error.main,
  },
  inputBaseWarning: {
    borderColor: theme.palette.warning.main,
  },
  inputBaseSuccess: {
    borderColor: theme.palette.success.main,
  },
  adornmentDefault: {
    background: "#e4e4e4",
  },
  adornmentPrimary: {
    background: theme.palette.primary.main,
    "& $adornmentText": {
      color: theme.palette.common.white,
    },
  },
  adornmentSecondary: {
    background: theme.palette.secondary.main,
    "& $adornmentText": {
      color: theme.palette.common.white,
    },
  },
  adornmentWarning: {
    background: theme.palette.warning.main,
    "& $adornmentText": {
      color: theme.palette.common.white,
    },
  },
  adornmentSuccess: {
    background: theme.palette.success.main,
    "& $adornmentText": {
      color: theme.palette.common.white,
    },
  },
  adornmentErr: {
    background: theme.palette.error.main,
    "& svg": {
      color: theme.palette.common.white,
    },
    "& $adornmentText": {
      color: theme.palette.common.white,
    },
  },
  adornmentError: {
    background: theme.palette.error.main,
    "& svg": {
      color: theme.palette.common.white,
    },
    "& $adornmentText": {
      color: theme.palette.common.white,
    },
  },
  adornedStart: {
    paddingLeft: 10,
  },
  adornedEnd: {
    paddingRight: 5,
  },
  error: {
    borderColor: theme.palette.error.main,
    "&$adornmentError": {
      background: theme.palette.error.main,
      "& svg": {
        color: theme.palette.common.white,
      },
    },
  },
  adornmentText: {
    fontSize: "0.85rem",
    color: "#585858",
    "&$error": {
      color: theme.palette.common.white,
    },
  },
}));

export { useStyles };

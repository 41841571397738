import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Imports i18n
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 * Material UI Imports
 */
import { Button as MaterialUIButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";

/**
 * Imports the component styles
 */
import { useStyles } from "./UpgradeExplanation.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  modulePrice: PropTypes.number,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baseDiscount: PropTypes.number,
  finalPrice: PropTypes.number,
  calculatedDiscount: PropTypes.number,
  orgCount: PropTypes.number,
  organizations: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  modulePrice: 0,
  quantity: 0,
  baseDiscount: 0.33,
  finalPrice: 0,
  calculatedDiscount: 0,
  orgCount: 0,
  organizations: [],
};

/**
 * Displays the component
 */
const UpgradeExplanation = (props) => {
  const {
    modulePrice,
    quantity,
    finalPrice,
    calculatedDiscount,
    orgCount,
    organizations,
    button,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the open state
   */
  const [open, setOpen] = useState(false);

  /**
   * Handles closing the tooltip
   */
  const handleTooltipClose = () => {
    setOpen(false);
  };

  /**
   * Handles opening the tooltip
   */
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const TooltipContent = () => {
    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.header}>
          <CloseIcon
            onClick={handleTooltipClose}
            title={t("close")}
            titleAccess={t("close")}
          />
        </div>
        <div className={classes.section}>
          <span className={classes.subtitle}>{t("priceCalcFormula")}</span>
          <span className={classes.formula}>
            {`(`} {t("modulePrice")}
            {` * `}
            {t("quantity")}
            {` * `}
            {t("organizationsCount")}
            {`) - `}
            {t("discountTotal")}
          </span>
          <span className={classes.formula}>
            {`(${modulePrice} * ${quantity} * ${orgCount}) - ${calculatedDiscount} = ${
              modulePrice * quantity * orgCount
            } - ${calculatedDiscount} = ${finalPrice}`}{" "}
            {` EUR `}
          </span>
        </div>
        <div className={classes.section}>
          <div className={classes.variableLegend}>
            <span className={classes.label}>{`${t("modulePrice")}:`}</span>
            <span className={classes.value}>{modulePrice}</span>
            <span className={classes.label}> EUR </span>
          </div>
          <div className={classes.variableLegend}>
            <span className={classes.label}>{`${t("quantity")}:`}</span>
            <span className={classes.value}>
              {`${quantity} ${t(quantity > 1 ? "months" : "month")}`}
            </span>
          </div>
          <div className={classes.variableLegend}>
            <span className={classes.label}>
              {`${t("organizationsCount")}:`}
            </span>
            <span className={classes.valueFlex}>
              {orgCount}
              <ul>
                {organizations &&
                  organizations.map((org) => {
                    return <li key={org.name}>{org.name}</li>;
                  })}
              </ul>
            </span>
          </div>
          <div className={classes.variableLegend}>
            <span className={classes.label}> {`${t("discountTotal")}:`}</span>
            <span className={classes.value}>{calculatedDiscount}</span>
            <span className={classes.label}> EUR </span>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.variableLegend}>
            <span className={classes.label}>{t("discountCalcFormula")}</span>
            <span className={classes.valueFlex}>
              <ul>
                {/*<li>{t("baseReductionExplanation")}</li>*/}
                {/*<li>{t("cumulatedReductionExplanation")}</li>*/}
                <li>{t("cumulatedReductionExplanationExample")}</li>
              </ul>
            </span>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            {...button}
            type="button"
            variant="filled"
            className={classes.closeBtn}
            onClick={handleTooltipClose}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive
            title={<TooltipContent />}
            placement="left"
            classes={{
              tooltip: classes.tooltipBase,
            }}
          >
            <MaterialUIButton
              className={classes.button}
              onClick={handleTooltipOpen}
            >
              {t("explanation")}
            </MaterialUIButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

UpgradeExplanation.propTypes = propTypes;
UpgradeExplanation.defaultProps = defaultProps;

export default UpgradeExplanation;
export {
  propTypes as UpgradeExplanationPropTypes,
  defaultProps as UpgradeExplanationDefaultProps,
};

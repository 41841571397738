import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import ReportsTyreWasteSearch, {
  ReportsTyreWasteSearchDefaultProps,
  ReportsTyreWasteSearchPropTypes,
} from "../ReportsTyreWasteSearch";
import ReportsTyreWasteTable, {
  ReportsTyreWasteTableDefaultProps,
  ReportsTyreWasteTablePropTypes,
} from "../ReportsTyreWasteTable";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ReportsTyreWaste.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  search: PropTypes.shape(ReportsTyreWasteSearchPropTypes),
  table: PropTypes.shape(ReportsTyreWasteTablePropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
  dateFormat: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  search: ReportsTyreWasteSearchDefaultProps,
  table: ReportsTyreWasteTableDefaultProps,
  button: ButtonDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: "/tyre-waste",
  dateFormat: "yyyy-MM-dd HH:mm:ss",
};

/**
 * Displays the component
 */
const ReportsTyreWaste = (props) => {
  const {
    title,
    wrapper,
    container,
    search,
    table,
    button,
    loadingBackdrop,
    dateFormat,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  const { user } = useUser();

  /**
   * Initializes the print loading state
   */
  const [printLoading, setPrintLoading] = useState(false);

  /**
   * Initializes the data
   */
  const [data, setData] = useState({});

  /**
   * Initializes the init api call flag
   */
  const [apiCallMade, setApiCallMade] = useState(false);

  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  /**
   * Initializes the time interval
   */
  const [timeInterval, setTimeInterval] = useState({
    start: new Date(),
    end: new Date(),
  });

  /**
   * Initializes the component ref, used for printing the component
   */
  const componentRef = useRef();

  const [notFound, setNotFound] = useState(false);

  /**
   * Initializes the flag that will mount the print component
   */
  const [mountPrint, setMountPrint] = useState(false);

  /**
   * Defines the handle print function
   */
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const updateInputs = (inputs) => {
    if (typeof inputs.created_at === "string") {
      const interval = inputs.created_at.split(" - ");
      setTimeInterval({
        start: new Date(interval[0]),
        end: new Date(interval[1]),
      });
    } else {
      setTimeInterval({
        start: new Date(inputs.created_at[0]),
        end: new Date(inputs.created_at[1]),
      });
    }
  };

  /**
   * Handles formatting the number
   * @param {String} value
   */
  const formatNumber = (value) => (value ? `${value.toFixed(0)} BUC` : "");

  /**
   * Handles getting the start date
   */
  const getStartDate = (startDate) => {
    const date = new Date(startDate).setHours(0, 0, 0);

    const newDate = new Date(date);

    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    const d = newDate.getDate();

    const start = new Date(y, m, d).setHours(0, 0, 0);

    const a = formatDate(new Date(start), dateFormat);
    return formatDate(new Date(a), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles getting the end date
   */
  const getEndDate = (endDate) => {
    const date = new Date(endDate).setHours(0, 0, 0);
    const newDate = new Date(date);
    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    const d = newDate.getDate();
    const end = new Date(y, m, d).setHours(23, 59, 59);

    const b = formatDate(new Date(end), dateFormat);
    return formatDate(new Date(b), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles initiating the printing
   */
  const printComponent = () => {
    setMountPrint(true);
    setPrintLoading(true);
  };

  /**
   * Handles getting the print button content
   */
  const getPrintButtonContent = () =>
    printLoading ? (
      <CircularProgress size="1.45rem" className={classes.loader} />
    ) : (
      <PrintIcon className={classes.icon} />
    );

  /**
   * Defines the Print Button component
   */
  const PrintButton = () => {
    return (
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.printButton}
        title={t("printReport")}
        onClick={printComponent}
      >
        {getPrintButtonContent()}
      </Button>
    );
  };

  /**
   * Defines the Flex Center Component
   */
  const FlexCenter = ({ children }) => (
    <div className={classes.flexCenter}>{children}</div>
  );

  /**
   * Defines the Flex End Component
   */
  const FlexEnd = ({ children }) => (
    <div className={classes.flexEnd}>{children}</div>
  );

  /**
   * Defines the Flex Column Component
   */
  const FlexColumn = ({ children }) => (
    <div className={classes.flexColumn}>{children}</div>
  );

  /**
   * Defines the Container Component
   */
  const Container = ({ children }) => (
    <div className={classes.container}>{children}</div>
  );

  /**
   * Defines the Label Component
   */
  const Label = (props) => {
    const { value } = props;
    return <span className={classes.label}>{value}</span>;
  };

  /**
   * Defines the Value Component
   */
  const Value = (props) => {
    const { value } = props;

    return <span className={classes.value}>{value}</span>;
  };

  /**
   * Defines the Report Title Component
   */
  const ReportTitle = () => {
    return (
      <FlexColumn>
        <Typography variant="caption" className={classes.title}>
          {getReportTitle()}
        </Typography>
        <FlexCenter>
          <Label value={t("from")} />
          <Value value={getStartDate(timeInterval.start)} />
          <Label value="/" />
          <Label value={t("to")} />
          <Value value={getEndDate(timeInterval.end)} />
        </FlexCenter>
      </FlexColumn>
    );
  };

  /**
   * Defines the Report Payment Types Data Component
   */
  const ReportPaymentTypesData = () => {
    if (data.report_data && data.report_data.car_type_sum) {
      const carTypes = Object.entries(data.report_data.car_type_sum);

      return (
        <Container>
          {carTypes.map((entry) => {
            const label = entry[0];
            const value = entry[1];

            return (
              <FlexEnd>
                <Label value={`${label}:`} />
                <Value value={formatNumber(value)} />
              </FlexEnd>
            );
          })}
        </Container>
      );
    }
    return null;
  };

  const DataNotFound = () => {
    return notFound ? (
      <Grid container>
        <Typography variant="h2" className={classes.notFoundText}>
          {t("notFound")}
        </Typography>
      </Grid>
    ) : null;
  };

  const getUserOrganizationByID = (id) => {
    if (user.organizations) {
      const found = user.organizations.find((org) => org.id === id);
      const name = found ? found.name : false;
      const companyBase = user.account ? user.account.name : "";
      if (!name) return "";
      return name.replace(`${companyBase} - `, "");
    }
  };

  const getReportTitle = () => {
    if (activeOrg === "all") return t("totalTitle");
    const activeOrgName = getUserOrganizationByID(activeOrg);
    return `${t("titleReportTyreWaste")} - ${activeOrgName}`;
  };

  useEffect(() => {
    if (data.items && data.items.length < 1 && apiCallMade) {
      setNotFound(true);
    } else if (data.items && data.items.length > 0 && apiCallMade) {
      setNotFound(false);
    }
  }, [data, apiCallMade]);

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setPrintLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Resets the loading state
   */
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoading(false);
    }
  }, [data]);

  /**
   * Handles triggering the printing
   */
  useEffect(() => {
    if (printLoading) {
      setTimeout(() => {
        setReadyToPrint(true);
      }, [1000]);
    }
  }, [printLoading]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<WidgetsOutlinedIcon />}
        title={t("titleReportTyreWaste")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12}>
            <ReportsTyreWasteSearch
              {...search}
              setData={setData}
              apiCallMade={apiCallMade}
              setApiCallMade={setApiCallMade}
              updateInputs={updateInputs}
              setBackdropLoading={setLoading}
              setActiveOrg={setActiveOrg}
            />
          </Grid>
          {mountPrint && (
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                <Grid container className={classes.printContainer}>
                  <Grid item container xs={12}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                      <ReportTitle />
                    </Grid>
                    <Grid item xs={3}>
                      <ReportPaymentTypesData />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <ReportsTyreWasteTable
                      {...table}
                      isPrinting={true}
                      data={data.items}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          {data.items && data.items.length > 0 && (
            <Grid container className={classes.mainContainer}>
              <Grid item container xs={12}>
                <Grid item xs={3}>
                  <PrintButton />
                </Grid>
                <Grid item xs={6}>
                  <ReportTitle />
                </Grid>
                <Grid item xs={3}>
                  <ReportPaymentTypesData />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <ReportsTyreWasteTable {...table} data={data.items} />
              </Grid>
            </Grid>
          )}
          <DataNotFound />
          <LoadingBackdrop {...loadingBackdrop} open={loading} />
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

ReportsTyreWaste.propTypes = propTypes;
ReportsTyreWaste.defaultProps = defaultProps;

export default ReportsTyreWaste;
export {
  propTypes as ReportsTyreWastePropTypes,
  defaultProps as ReportsTyreWasteDefaultProps,
};

/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;
  const { isLength } = useValidator();

  /**
   * Checks if the input has a certain length
   */
  const isLengthTextBuilder = (value, options) => {
    let { min, max } = options;

    if (!min) min = 1;
    if (!max) max = 1;

    const minLength = t("LanguageProvider:error_minLength");
    const maxLength = t("LanguageProvider:error_maxLength");
    const chars = t("Form:characters");

    if (value.length < min) return `${minLength} ${min} ${chars}`;
    if (value.length > max) return `${maxLength} ${max} ${chars}`;

    return true;
  };

  const validatorFn = (props) => {
    const { name, value } = props;
    const options = { min: 6, max: 65 };

    let error = null;

    switch (name) {
      case "password":
        if (value && !isLength(value, options))
          return (error = isLengthTextBuilder(value, options));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { addMinutes, differenceInMinutes } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import AddWorkOrderServiceDraftModal, {
  AddWorkOrderServiceDraftModalDefaultProps,
  AddWorkOrderServiceDraftModalPropTypes,
} from "../AddWorkOrderServiceDraftModal";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

/**
 * Hooks
 */
import { useForm, useUser, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditAppointmentModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditAppointmentModal.styles";
import shortid from "shortid";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  createBtn: PropTypes.shape(CreateButtonPropTypes),
  deleteBtn: PropTypes.shape(DeleteButtonPropTypes),
  addServiceModal: PropTypes.shape(AddWorkOrderServiceDraftModalPropTypes),
  defaultValues: PropTypes.shape({
    organization_id: PropTypes.any,
    appointment_group_id: PropTypes.any,
    organization_client_id: PropTypes.any,
    from: PropTypes.any,
    to: PropTypes.any,
    duration: PropTypes.any,
    car_plate_number: PropTypes.string,
    client_name: PropTypes.string,
    car_model: PropTypes.string,
    phone: PropTypes.string,
    description: PropTypes.string,
    requested_services: PropTypes.array,
  }),
  editData: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  createBtn: CreateButtonDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  deleteBtn: DeleteButtonDefaultProps,
  addServiceModal: AddWorkOrderServiceDraftModalDefaultProps,
  defaultValues: {
    organization_id: "",
    appointment_group_id: "",
    organization_client_id: "",
    from: new Date(),
    to: new Date(),
    duration: "",
    car_plate_number: "",
    client_name: "",
    car_model: "",
    phone: "",
    description: "",
    requested_services: [],
  },
  editData: {},
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditAppointmentModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    errorMessages,
    setUpdated,
    loadingText,
    editData,
    setEditData,
    onAddedAppointmentChange,
    createBtn,
    deleteBtn,
    addServiceModal,
    appointmentGroups,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Initializes the clients list
   */
  const [clients, setClients] = useState([]);

  /**
   * Initializes the organizations state
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);
  const [inputsUpdated, setInputsUpdated] = useState(false);
  const [servicesList, setServicesList] = useState([]);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  const [addServiceOpen, setAddServiceOpen] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setErrors,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    handleDateChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    organization_id,
    appointment_group_id,
    organization_client_id,
    from,
    to,
    car_plate_number,
    client_name,
    car_model,
    phone,
    description,
    requested_services,
  } = inputs;

  const {
    car_plate_number: car_plate_numberError,
    client_name: client_nameError,
    car_model: car_modelError,
    phone: phoneError,
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    setErrors({});
    setEditData({});
    closeModal();
    setLoading(false);
    setInputsUpdated(false);
  };

  /**
   * Handles updating the appointment
   */
  const updateAppointment = async (data, id) => {
    try {
      const updatedAppointment = await apiClient.put(
        `/appointments/${id}`,
        data
      );
      if (updatedAppointment) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });

        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles searching for products / services
   */
  const searchProducts = async (data) => {
    try {
      const products = await apiClient.post("/products/search", data);

      if (products) {
        const { data } = products;

        const items = data.items;

        const filteredServices = items.filter((product) => {
          // if (product.organization_id === user.organization.id) return true;
          return true;
        });

        setServicesList(
          filteredServices.sort((a, b) => {
            return a.organization_id - b.organization_id;
          })
        );
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  const getProductData = (data) => {
    const requestedServices = inputs.requested_services;

    let dataIndex = -1;
    const exists = requestedServices.find((product, index) => {
      if (product.organization_product_id === data.organization_product_id) {
        dataIndex = index;
        return true;
      }
      return false;
    });
    if (exists) {
      const newProducts = [];
      requestedServices.forEach((product, idx) => {
        if (idx === dataIndex) {
          newProducts.push({
            ...product,
            quantity: product.quantity + data.quantity,
            total: (product.quantity + data.quantity) * product.price,
          });
        } else {
          newProducts.push(product);
        }
      });

      setInputs((prevState) => ({
        ...prevState,
        requested_services: newProducts,
      }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        requested_services: [...prevState.requested_services, { ...data }],
      }));
    }
  };

  /**
   * Handles changing the client name
   */
  const handleClientChange = (event, newValue) => {
    if (newValue === null) return;
    if (!inputsUpdated && organization_client_id && client_name) {
      setInputsUpdated(true);
      return;
    }

    setInputs((prevState) => {
      return {
        ...prevState,
        organization_client_id: newValue,
        client_name: newValue
          ? clients.find((client) => client.name === newValue)
            ? newValue
            : prevState.client_name
          : prevState.client_name,
      };
    });
  };

  /**
   * Defines the product modal handlers
   */
  const openAddService = () => setAddServiceOpen(true);
  const closeAddService = () => setAddServiceOpen(false);

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      });
      setOrganizations(orgs);
      setInputs((prevState) => {
        return { ...prevState, organization_id: orgs[0].value };
      });
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Gets the clients
   */
  useEffect(() => {
    if (open && user.clients) {
      setClients(user.clients);
    }
    // eslint-disable-next-line
  }, [open, user]);

  /**
   * Handles prefilling the product options list upon mounting
   */
  useEffect(() => {
    if (open) {
      const data = {
        models: [
          {
            label: "Is Service",
            field: "is_service",
            type: "boolean",
            order: 5,
            options: [],
            selected: true,
          },
        ],
        order_by: "name",
        order_dir: "asc",
        page_size: 500000, // needed in order to get all products
        page_count: 1,
      };
      searchProducts(data);
    }

    // eslint-disable-next-line
  }, [open]);

  /**
   * Defines the delete function
   */
  const handleDelete = (id) => {
    const newServices = requested_services.filter(
      (product) => product.organization_product_id !== id
    );
    setInputs((prevState) => ({
      ...prevState,
      requested_services: newServices,
    }));
  };

  /**
   * Gets the client id
   * @param {String} client_name
   */
  const getClientId = (client_name) => {
    if (!client_name) return "";
    const found = clients.find((client) => client.name === client_name);
    return found ? found.id : "";
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const timezoneOffsetDate = new Date();
      const offset = Math.abs(timezoneOffsetDate.getTimezoneOffset());

      const data = {
        from: addMinutes(new Date(inputs.from), offset),
        to: addMinutes(new Date(inputs.to), offset),
        duration: differenceInMinutes(
          new Date(inputs.to),
          new Date(inputs.from)
        ),
        car_plate_number: inputs.car_plate_number,
        client_name: inputs.client_name,
        id: shortid.generate(),
      };

      if (appointment_group_id)
        data["appointment_group_id"] = inputs.appointment_group_id;
      if (appointment_group_id === "") {
        data["appointment_group_id"] = null;
      }

      if (organization_id) data["organization_id"] = inputs.organization_id;
      if (organization_client_id) {
        const client_id = getClientId(inputs.organization_client_id);
        if (client_id) data["organization_client_id"] = client_id;
      }

      if (car_model) data["car_model"] = inputs.car_model;

      if (phone) data["phone"] = inputs.phone;
      if (description) data["description"] = inputs.description;

      if (inputs.requested_services.length > 0)
        data["requested_services"] = inputs.requested_services;
      if (inputs.requested_services.length === 0)
        data["requested_services"] = [];

      updateAppointment(data, editData.appointmentData.id);
      onAddedAppointmentChange(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (
      Object.keys(editData).length > 0 &&
      clients.length > 0 &&
      !inputsUpdated &&
      open
    ) {
      const { appointmentData } = editData;

      setInputs((prevState) => ({
        ...prevState,
        organization_id: appointmentData.organization_id,
        appointment_group_id: appointmentData.appointment_group_id
          ? appointmentData.appointment_group_id
          : "",
        organization_client_id: appointmentData.client
          ? appointmentData.client.name
          : appointmentData.client_name,
        client_name: appointmentData.client_name,
        phone: appointmentData.phone ? appointmentData.phone : "",
        car_plate_number: appointmentData.car_plate_number,
        car_model: appointmentData.car_model ? appointmentData.car_model : "",
        description: appointmentData.description
          ? appointmentData.description
          : "",
        duration: appointmentData.duration,
        requested_services:
          appointmentData.requested_services &&
          appointmentData.requested_services.length > 0
            ? appointmentData.requested_services.map((service) => {
                return {
                  ...service,
                  id: service.id ? service.id : shortid.generate(),
                };
              })
            : [],
        from: new Date(appointmentData.startDate),
        to: new Date(appointmentData.endDate),
      }));
    }
    // eslint-disable-next-line
  }, [open, editData, clients, inputsUpdated]);

  useEffect(() => {
    if (appointment_group_id === "none") {
      setInputs((prevState) => ({ ...prevState, appointment_group_id: "" }));
    }
    // eslint-disable-next-line
  }, [appointment_group_id]);

  const formatAppointmentGroups = (appointmentGroups) => {
    if (appointmentGroups.length < 1) return [];
    if (user.type === "admin") return appointmentGroups;

    const result = appointmentGroups.filter((group) => {
      return (
        group.organization_id && group.organization_id === user.organization.id
      );
    });

    const baseGroup = appointmentGroups.find((group) => group.id === "none");

    if (result.length < 1) {
      return baseGroup ? [baseGroup] : [];
    }

    return [{ ...baseGroup }, ...result];
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={false}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleEditAppointment")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-worker-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        {user.type === "admin" && (
                          <Grid item xs={12}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "organization_id",
                                name: "organization_id",
                                value: organization_id,
                                onChange: handleInputChange,
                                label: t("organizationLabel"),
                                variant: "outlined",
                                options: organizations,
                                optionLabel: "label",
                                optionValue: "value",
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "appointment_group_id",
                              name: "appointment_group_id",
                              value: appointment_group_id,
                              onChange: handleInputChange,
                              label: t("appointment_group_id"),
                              variant: "outlined",
                              options:
                                formatAppointmentGroups(appointmentGroups),
                              optionLabel: "name",
                              optionValue: "id",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Input
                            {...input}
                            type="autocomplete"
                            className={classes.field}
                            inputAutocomplete={{
                              id: "organization_client_id",
                              name: "organization_client_id",
                              inputValue: organization_client_id,
                              onChange: handleClientChange,
                              variant: "outlined",
                              freeSolo: false,
                              autoComplete: false,
                              label: t("organization_client_idLabel"),
                              options: clients,
                              getOptionSelected: (option, value) => {
                                return option.id === value.id;
                              },
                              showHelper: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              required: true,
                              id: "client_name",
                              name: "client_name",
                              value: client_name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("client_nameLabel"),
                              error: client_nameError,
                              showHelper: !!client_nameError,
                              autoFocus: getAutoFocus().client_name,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="phone"
                            className={classes.field}
                            inputPhone={{
                              id: "phone",
                              name: "phone",
                              value: phone,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("phoneLabel"),
                              error: phoneError,
                              autoFocus: getAutoFocus().phone,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            className={classes.dateField}
                            type="dateTime"
                            inputDateTime={{
                              required: true,
                              name: "from",
                              value: from,
                              onChange: handleDateChange,
                              margin: "normal",
                              label: t("fromLabel"),
                              variant: "standard",
                              format: "yyyy-MM-dd HH:mm",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            className={classes.dateField}
                            type="dateTime"
                            inputDateTime={{
                              required: true,
                              name: "to",
                              value: to,
                              onChange: handleDateChange,
                              margin: "normal",
                              label: t("toLabel"),
                              variant: "standard",
                              format: "yyyy-MM-dd HH:mm",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              required: true,
                              id: "car_plate_number",
                              name: "car_plate_number",
                              value: car_plate_number,
                              onChange: handleInputChange,
                              maxSize: 9,
                              variant: "outlined",
                              label: t("car_plate_numberLabel"),
                              error: car_plate_numberError,
                              showHelper: !!car_plate_numberError,
                              autoFocus: getAutoFocus().car_plate_number,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "car_model",
                              name: "car_model",
                              value: car_model,
                              onChange: handleInputChange,
                              variant: "outlined",
                              label: t("car_modelLabel"),
                              error: car_modelError,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 2,
                              rowsMax: 2,
                              maxChars: 200,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel"),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.choice}>
                            <Typography
                              variant="caption"
                              className={classes.sectionTitle}
                            >
                              {t("requested_services")}
                            </Typography>
                            <CreateButton
                              {...createBtn}
                              className={classes.addServiceBtn}
                              onClick={openAddService}
                              text={t("addService")}
                              title={t("addService")}
                            />
                          </div>
                          {requested_services.length > 0 && (
                            <List dense={true} className={classes.list}>
                              {requested_services.map((service) => {
                                const deleteItem = () =>
                                  handleDelete(service.id);
                                return (
                                  <ListItem key={service.id}>
                                    <ListItemText
                                      primary={`${service.name} x ${service.quantity}`}
                                    />
                                    <ListItemSecondaryAction>
                                      <DeleteButton
                                        {...deleteBtn}
                                        title={t("deleteItem")}
                                        className={classes.smallBtn}
                                        onClick={deleteItem}
                                      />
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                            </List>
                          )}
                        </Grid>

                        <AddWorkOrderServiceDraftModal
                          {...addServiceModal}
                          open={addServiceOpen}
                          closeModal={closeAddService}
                          getProductData={getProductData}
                          products={servicesList}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-worker-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditAppointmentModal.propTypes = propTypes;
EditAppointmentModal.defaultProps = defaultProps;

export default EditAppointmentModal;
export {
  propTypes as EditAppointmentModalPropTypes,
  defaultProps as EditAppointmentModalDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modalContent: {
    maxHeight: 660,
    padding: "0.5rem"
  },
  wrapper: {
    background: theme.palette.common.white,
    boxShadow: "none"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  cardContent: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  whiteTagFlex: {
    display: "flex",
    flex: 1,
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
      display: "flex",
      fontWeight: 800,
      color: theme.palette.secondary.main,
      alignItems: "center",
      justifyContent: "center",
      padding: "0.25rem 1rem",
      background: theme.palette.sidebar.main,
      border: `1px solid #dadada`,
      borderRadius: 5
    },
    "& svg": {
      color: theme.palette.primary.main,
      marginRight: "0.5rem"
    }
  }
}));

export { useStyles };

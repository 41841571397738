import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import TimerIcon from "@material-ui/icons/Timer";

/**
 * Styles Imports
 */
import { useStyles } from "./ModulePackageCard.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  currency: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.number,
  price: PropTypes.number,
  button: PropTypes.shape(ButtonPropTypes),
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activePackage: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  currency: "RON",
  description: "",
  duration: "30 Days",
  name: "Tyre Service",
  order: 0,
  price: 0,
  button: ButtonDefaultProps,
  uuid: 0,
  activePackage: {},
};

/**
 * Displays the component
 */
const ModulePackageCard = (props) => {
  const {
    currency,
    description,
    duration,
    name,
    activePackage,
    price,
    button,
    updateModalData,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const handleOpenModal = () => updateModalData(props);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="caption" className={classes.title}>
          {name}
        </Typography>
        <Typography variant="caption" className={classes.description}>
          {t(description)}
        </Typography>
        {activePackage.name === name && (
          <Typography variant="caption" className={classes.activeHeader}>
            {t("activated")}
          </Typography>
        )}
      </div>
      <div className={classes.body}>
        <Typography variant="caption" className={classes.priceContainer}>
          <Typography variant="caption" className={classes.priceValue}>
            <Typography variant="caption" className={classes.priceCurrency}>
              {currency}
            </Typography>
            {price}
            <Typography variant="caption" className={classes.priceMonthly}>
              {t("monthly")}
            </Typography>
          </Typography>
        </Typography>
        <Typography variant="caption" className={classes.duration}>
          <TimerIcon />
          <Typography variant="caption" className={classes.durationLabel}>
            {t("durationLabel")}
          </Typography>
          {t(duration)}
        </Typography>
        <Button
          {...button}
          type="button"
          variant="standard"
          className={classes.button}
          title={t(activePackage.name === name ? "extend" : "activate")}
          onClick={handleOpenModal}
        >
          {t(activePackage.name === name ? "extend" : "activate")}
        </Button>
      </div>
    </div>
  );
};

ModulePackageCard.propTypes = propTypes;
ModulePackageCard.defaultProps = defaultProps;

export default ModulePackageCard;
export {
  propTypes as ModulePackageCardPropTypes,
  defaultProps as ModulePackageCardDefaultProps,
};

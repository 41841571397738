/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.4rem",
    fontFamily: theme.font.family,
    textAlign: "center",
    padding: "0.25rem 1rem",
    margin: "0.75rem 0",
    fontWeight: 800,
  },
  tableFontSize: {
    fontSize: "1rem",
  },
  disclaimer: {
    position: "absolute",
    bottom: 25,
  },
  table: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  blank: {
    boxShadow: "none",
    marginTop: "1rem",
  },
  signatureContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  signatureCont: {
    padding: "0.25rem",
    paddingRight: 0,
    display: "flex",
    alignItems: "flex-end",
    marginTop: 0,
    marginBottom: "0.25rem",
    fontFamily: theme.font.family,
    fontSize: "1rem",
    fontWeight: 800,
  },
  signatureLine: {
    width: 200,
    maxWidth: 200,
    height: 1,
    background: "#717171",
    left: 90,
    bottom: 10,
  },
}));

export { useStyles };

/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;
  const { isEmpty } = useValidator();

  const validatorFn = (props) => {
    const { name, value } = props;

    let error = null;

    switch (name) {
      case "name":
      case "short_name":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

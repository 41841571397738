/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    marginBottom: "2rem",
    marginTop: "2rem"
  },
  appbar: {
    boxShadow: "none",
    border: "none",
    background: "transparent",
    maxWidth: "inherit",
    width: "inherit"
  },
  tabs: {
    width: "inherit",
    maxWidth: "inherit",
    justifyContent: "center",
    "& .MuiTab-root": {
      maxWidth: "none"
    },
    "& .MuiTab-wrapper": {
      border: `1px solid #ded6d6`,
      padding: "0.5rem 1rem",
      background: theme.palette.sidebar.main,
      textTransform: "none"
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      opacity: 0.2
    },
    "& .MuiTabs-scroller": {
      maxWidth: 1300,
      flex: "initial",
      [theme.breakpoints.only("sm")]: {
        maxWidth: 620
      },
      [theme.breakpoints.only("md")]: {
        maxWidth: 870
      },
      [theme.breakpoints.only("lg")]: {
        maxWidth: 940
      }
    }
  },
  indicator: {
    width: "50%"
  }
}));

export { useStyles };

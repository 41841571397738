/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    color: "#fff",
    fontSize: "1.4rem",
    fontWeight: 800,
    fontFamily: theme.font.display,

    [theme.breakpoints.down("lg")]: {
      marginTop: "1rem"
    }
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: 800,
    fontFamily: theme.font.display,
    background: theme.palette.common.white,
    padding: "0.5rem 1rem",
    borderRadius: 10
  },
  pos: {
    marginBottom: 12
  },
  logoContainer: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
    "& img": {
      [theme.breakpoints.down("xl")]: {
        maxWidth: 100
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: 75
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 45
      }
    }
  },
  lgImgContainer: {
    "& img": {
      [theme.breakpoints.down("xl")]: {
        maxWidth: 450
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: 350
      }
    }
  },
  appTitle: {
    color: theme.palette.primary.main,
    fontSize: "2.6rem",
    fontWeight: 800,
    fontFamily: theme.font.display,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem"
    }
  },
  paragraph: {
    color: theme.palette.common.black,
    padding: "0.5rem 0",
    fontSize: "1.4rem",
    fontFamily: theme.font.family
  },
  greetingContainer: {
    background: theme.palette.common.white,
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    // minHeight: "100vh",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minHeight: "20vh"
    }
  },
  messageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  greeting: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
    padding: "1.5rem"
  },
  formContainer: {
    background: theme.palette.primary.main,
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    // minHeight: "100vh",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem"
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem"
    }
  },
  form: {
    padding: "1.5rem",
    background: theme.palette.common.white,
    borderRadius: 15,
    minWidth: 600,
    maxWidth: 850,
    maxHeight: 430,
    overflowY: "scroll",
    boxShadow: "none",
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 0"
    }
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "1rem"
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  footerDocumentLinks: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    "& .MuiTypography-caption": {
      margin: "0 0.5rem",
      fontWeight: 800,
      fontSize: "0.8rem"
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flex: 1,
      flexDirection: "column"
    }
  },
  footerCopyright: {
    "& .MuiTypography-caption": {
      fontWeight: 800,
      fontSize: "0.8rem"
    }
  },
  checkoutPage: {
    ...theme.mixins.flex.column,
    flex: 1
  },
  container: {
    ...theme.mixins.flex.column,
    flex: 1
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  message: {
    // padding: "1rem",
    textAlign: "center",
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    background: "transparent",
    boxShadow: "none",
    marginBottom: "1rem"
  },
  submitButton: {
    padding: "0.5rem 2rem",
    margin: "0 0.5rem",
    borderRadius: 5,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main
    }
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between"
  },
  loginLink: {
    margin: "0 0.5rem",
    fontWeight: 800,
    fontSize: "0.8rem",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.accent
    }
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  reCaptcha: {
    marginTop: "1rem"
  },
  checkbox: {
    padding: 0,
    paddingRight: 2
  },
  checkboxContainer: {
    ...theme.mixins.flex.rowCenter,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 1rem",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start"
    },
    "& .MuiFormControlLabel-root": {
      marginRight: 3,
      padding: 0
    }
  },
  disclaimer: {
    fontSize: "0.9rem",
    fontWeight: 800,
    fontFamily: theme.font.family,
    [theme.breakpoints.down("sm")]: {
      marginTop: -2
    },
    paddingBottom: 2
  },
  termsTitle: {
    fontSize: "0.9rem",
    opacity: 0.7,
    padding: "0 0.15rem",
    marginTop: "0.5rem"
  },
  terms: {
    fontSize: "0.8rem",
    opacity: 0.7,
    padding: "0.5rem 0.15rem",
    borderTop: "1px solid #cacaca",
    borderBottom: "1px solid #cacaca",
    marginBottom: "0.5rem"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "1rem 0",
  },
  mainContainer: {
    margin: "1rem 0",
    padding: "1rem",
    background: theme.palette.common.white,
    border: "1px solid #eaeaea",
  },
  printContainer: {
    padding: "1rem",
  },
  container: {
    padding: "1rem 0",
    background: theme.palette.common.white,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    justifyContent: "flex-end",
  },
  label: {
    fontSize: "0.95rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    padding: "0 0.25rem",
  },
  value: {
    fontSize: "0.95rem",
    color: theme.palette.sidebar.text,
    fontFamily: theme.font.family,
  },
  title: {
    fontSize: "1.5rem",
    color: theme.palette.sidebar.text,
    fontFamily: theme.font.family,
    marginBottom: "1rem",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
    marginBottom: "0rem",
  },
  printButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.accent,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
  loader: {
    color: theme.palette.common.white,
    width: "1.5rem",
    height: "1.5rem",
  },
  icon: {
    width: "1em",
    height: "1em",
  },
  test: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    background: "#f3f3f3",
    border: "1px solid #eaeaea",
    borderRight: "1px solid #eaeaea",
    width: "100%",
    padding: "6px 12px",
    fontSize: "0.8rem",
    color: "#4e4e4e",
    lineHeight: "1.5rem",
  },
}));

export { useStyles };

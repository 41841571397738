import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SaveIcon from "@material-ui/icons/Save";
import Fab from "@material-ui/core/Fab";

/**
 * Styles Imports
 */
import { useStyles } from "./PDFNavigation.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
};

/**
 * Defines the default props
 */
const defaultProps = {
  page: 1,
  pages: 1,
  handlePrevClick: () => {},
};

/**
 * Displays the component
 */
const PDFNavigation = (props) => {
  const { page, pages, documentName, handlePrevClick, handleNextClick } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the state that control the rate of state changes
   */
  const [stateTransitioning, setStateTransitioning] = useState(false);

  /**
   * Handles going to the previous page
   */
  const getPreviousPage = () => {
    if (page === 1) return;
    if (!stateTransitioning) {
      setStateTransitioning(true);
      handlePrevClick();
    }
  };

  /**
   * Handles going to the next page
   */
  const getNextPage = () => {
    if (page === pages) return;
    if (!stateTransitioning) {
      setStateTransitioning(true);
      handleNextClick();
    }
  };

  /**
   * Handles opening the document in a new window.
   */
  const openDocument = () => {
    window.open(`/documents/${documentName}`, "_blank");
  };

  /**
   * Renders the previous button
   */
  const PreviousButton = () => {
    return (
      <Fab
        aria-label={t("previous")}
        className={classes.iconContainer}
        onClick={getPreviousPage}
      >
        <KeyboardArrowLeftIcon className={classes.icon} />
      </Fab>
    );
  };

  /**
   * Renders the next button
   */
  const NextButton = () => {
    return (
      <Fab
        aria-label={t("next")}
        className={classes.iconContainer}
        onClick={getNextPage}
      >
        <KeyboardArrowRightIcon className={classes.icon} />
      </Fab>
    );
  };

  /**
   * Renders the pages display
   */
  const PagesDisplay = () => {
    return (
      <Typography variant="caption" className={classes.pagesDisplay}>
        {t("page")} {page} {t("outOf")} {pages}
      </Typography>
    );
  };

  /**
   * Renders the Extra Actions Component
   */
  const ExtraActions = () => {
    return (
      <Box className={classes.downloadContainer} onClick={openDocument}>
        <SaveIcon className={classes.downloadIcon} />
        <Typography className={classes.downloadText}>
          {t("download")}
        </Typography>
      </Box>
    );
  };

  /**
   * Handles limiting the amount of state changes for the next page state changer
   * This is a required fix as the library comes with a few bugs.
   */
  useEffect(() => {
    if (stateTransitioning) {
      const timeout = setTimeout(() => {
        setStateTransitioning(false);
      }, [50]);
      return () => clearTimeout(timeout);
    }
  }, [handleNextClick, stateTransitioning]);

  /**
   * Handles limiting the amount of state changes for the previous page state changer
   * This is a required fix as the library comes with a few bugs.
   */
  useEffect(() => {
    if (stateTransitioning) {
      const timeout = setTimeout(() => {
        setStateTransitioning(false);
      }, [50]);
      return () => clearTimeout(timeout);
    }
  }, [handlePrevClick, stateTransitioning]);

  return (
    <Box className={classes.main}>
      <Box className={classes.toolsContainer} />
      <Box className={classes.toolsMain}>
        <PreviousButton />
        <PagesDisplay />
        <NextButton />
      </Box>
      <Box className={classes.toolsContainer}>
        <ExtraActions />
      </Box>
    </Box>
  );
};

PDFNavigation.propTypes = propTypes;
PDFNavigation.defaultProps = defaultProps;

export default PDFNavigation;
export {
  propTypes as PDFNavigationPropTypes,
  defaultProps as PDFNavigationDefaultProps,
};

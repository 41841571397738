/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  stepCard: {
    boxShadow: "none",
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardActions: {
    paddingTop: 0,
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    height: 30,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  finalStep: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  beforeIcon: {
    marginRight: "0.25rem",
  },
  nextIcon: {
    marginLeft: "0.25rem",
  },
  services: {
    display: "inline-flex",
    flex: 1,
    alignItems: "center",
    flexWrap: "wrap",
  },
  serviceBox: {
    fontFamily: theme.font.family,
    fontSize: "0.75rem",
    padding: "0.25rem 1rem",
    color: theme.palette.secondary.light,
    border: `1px solid #c2c5c7`,
    borderRadius: 3,
    background: theme.palette.sidebar.main,
    margin: "0.15rem",
    fontWeight: 800,
  },
  tableTitle: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 0,
    marginBottom: "0.5rem",
    borderBottom: `1px solid #e0e0e0`,
    display: "flex",
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  title: {
    fontSize: "1.5rem",
    fontFamily: theme.font.family,
    textAlign: "center",
    padding: "1rem",
    marginTop: "1rem",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "1rem",
  },
  duration: {
    display: "flex",
    padding: "0.25rem 1rem",
    border: "1px solid #c2b9b3",
    fontSize: "2rem",
    borderRadius: 5,
    background: "#f0edec",
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontFamily: theme.font.family,
    marginBottom: "0.5rem",
  },
  box: {
    border: `1px solid ${theme.palette.border.main}`,
    background: "#fbfbfb",
    padding: 10,
    paddingTop: 0,
    borderRadius: 3,
    marginBottom: "2rem",
  },

  spacing: {
    margin: "0 0.5rem",
  },
  boxItem: {
    margin: "0.5rem 0.5rem 0",
  },
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch } from "react-router-dom";

/**
 * Asset Imports
 */
import {ReactComponent as Logo} from "./logo.svg";
import {ReactComponent as LogoDark} from "./logo_dark.svg";

/**
 * Hooks Imports
 */
import {useTheme} from "../../hooks";

/**
 * Component Imports
 */
import RegisterPage, {
  RegisterPagePropTypes,
  RegisterPageDefaultProps,
} from "../RegisterPage";
import LoginPage, {
  LoginPagePropTypes,
  LoginPageDefaultProps,
} from "../LoginPage";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes,
} from "../PageNotFound";
import DocumentPage, {
  DocumentPagePropTypes,
  DocumentPageDefaultProps,
} from "../DocumentPage";
import GuestFooter from "../GuestFooter";
import LanguageChanger, {
  LanguageChangerPropTypes,
  LanguageChangerDefaultProps,
} from "../LanguageChanger";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";

/**
 * i18n Imports
 */
import {useTranslation} from "react-i18next";

/**
 * Styles Imports
 */
import {useStyles} from "./AuthPage.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  loginPage: PropTypes.shape(LoginPagePropTypes),
  registerPage: PropTypes.shape(RegisterPagePropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  documentPage: PropTypes.shape(DocumentPagePropTypes),
  langChanger: PropTypes.shape(LanguageChangerPropTypes),
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  loginPage: LoginPageDefaultProps,
  registerPage: RegisterPageDefaultProps,
  notFound: PageNotFoundDefaultProps,
  documentPage: DocumentPageDefaultProps,
  langChanger: LanguageChangerDefaultProps,
  path: "/",
};

/**
 * Displays the component
 */
const AuthPage = (props) => {
  const {
    loginPage,
    registerPage,
    documentPage,
    langChanger,
    notFound,
  } = props;

  // eslint-disable-next-line
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the theme handlers
   */
  const { activeTheme } = useTheme();

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Checks the active theme is dark or not
   *
   * @returns {boolean}
   */
  const isDarkThemeActive = () => {
    return activeTheme === "dark-theme";
  }

  return (
    <Grid container justify="center" className={classes.route}>
      <div className={classes.container}>
        <LanguageChanger {...langChanger} />
      </div>
      <div className={classes.promo}>
        {isDarkThemeActive() ? <Logo/> : <LogoDark />}
      </div>
      <Switch>
        <Route exact path={loginPage.path}>
          <LoginPage {...loginPage} />
        </Route>
        <Route exact path={registerPage.path}>
          <RegisterPage {...registerPage} />
        </Route>
        <Route exact path={documentPage.path}>
          <DocumentPage {...documentPage} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
      <GuestFooter />
    </Grid>
  );
};

AuthPage.propTypes = propTypes;
AuthPage.defaultProps = defaultProps;

export default AuthPage;
export { propTypes as AuthPagePropTypes, defaultProps as AuthPageDefaultProps };

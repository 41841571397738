import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 *  Material UI Imports
 */
import DialogContent from "@material-ui/core/DialogContent";

/**
 * Styles Imports
 */
import { useStyles } from "./ModalContent.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  className: "",
  children: ""
};

/**
 * Displays the component
 */
const ModalContent = props => {
  const { children, className } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the content container class
   */
  const containerClasses = clsx(classes.content, {
    [className]: className ? true : false
  });

  return <DialogContent className={containerClasses}>{children}</DialogContent>;
};

ModalContent.propTypes = propTypes;
ModalContent.defaultProps = defaultProps;

export default ModalContent;
export {
  propTypes as ModalContentPropTypes,
  defaultProps as ModalContentDefaultProps
};

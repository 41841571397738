import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditClientModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditClientModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    is_company: PropTypes.bool,
    organization_id: PropTypes.any,
    fidelity: PropTypes.bool,
    name: PropTypes.string,
    delegate_name: PropTypes.string,
    description: PropTypes.string,
    vat_number: PropTypes.string,
    reg_number: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    number: PropTypes.string,
    street: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string,
  }),
  editData: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    is_company: false,
    organization_id: "",
    fidelity: false,
    name: "",
    delegate_name: "",
    description: "",
    vat_number: "",
    reg_number: "",
    email: "",
    phone: "",
    city: "",
    number: "",
    street: "",
    county: "",
    country: "România",
  },
  editData: {},
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditClientModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    errorMessages,
    loadingText,
    editData,
    setUpdated,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);
  const [clientReady, setClientReady] = useState(false);
  const [orgReady, setOrgReady] = useState(false);
  const [skip, setSkip] = useState(false);
  const [addressEditted, setAddressEdited] = useState(false);
  const [addressReady, setAddressReady] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [companyEmpty, setCompanyEmpty] = useState(false);

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    is_company,
    organization_id,
    fidelity,
    name,
    delegate_name,
    description,
    vat_number,
    reg_number,
    email,
    phone,
    city,
    number,
    street,
    county,
    country,
  } = inputs;

  const {
    name: nameError,
    delegate_name: delegate_nameError,
    vat_number: vat_numberError,
    reg_number: reg_numberError,
    email: emailError,
    number: numberError,
    phone: phoneError,
    city: cityError,
    street: streetError,
    county: countyError,
    country: countryError,
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setClientReady(false);
    setOrgReady(false);
    setAddressEdited(false);
    setAddressReady(false);
    setReady(false);
    closeModal();
  };

  /**
   * Handles getting a client by id
   */
  const getClientById = async (id) => {
    try {
      const foundClient = await apiClient.get(`/clients/${id}`);
      if (foundClient) {
        const { data } = foundClient;
        setInputs((prevState) => {
          return {
            ...prevState,
            name: data.name,
            is_company: data.is_company,
            fidelity: data.fidelity,
            organization_id: data.organization_id,
            description: data.description ? data.description : "",
            delegate_name: data.delegate_name ? data.delegate_name : "",
            vat_number:
              data.company && data.company.vat_number
                ? data.company.vat_number
                : "",
            reg_number:
              data.company && data.company.reg_number
                ? data.company.reg_number
                : "",
            email:
              (data.company && data.company.email ? data.company.email : "") ||
              data.email,
            phone:
              (data.company && data.company.phone ? data.company.phone : "") ||
              data.phone,
            country:
              data.address && data.address.country ? data.address.country : "",
            county:
              data.address && data.address.county ? data.address.county : "",
            city: data.address && data.address.city ? data.address.city : "",
            street:
              data.address && data.address.street ? data.address.street : "",
            number:
              data.address && data.address.number ? data.address.number : "",
          };
        });
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles updating a client
   */
  const updateClient = async (data, id) => {
    try {
      const updatedClient = await apiClient.put(`/clients/${id}`, data);
      if (updatedClient) {
        setReady(false);
        setClientReady(true);
        if (skip) {
          /**
           * Triggers a new search using the current search model
           */
          setUpdated(true);
          setLoading(false);
          handleCloseModal();
          dispatchMessage({
            message: t("successMessage"),
          });
        }
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles updating the user organization data
   */
  const updateUserOrganizationData = async (data, id) => {
    try {
      const updatedOrganization = await apiClient.put(`/companies/${id}`, data);
      if (updatedOrganization) {
        if (!companyEmpty) {
          setReady(false);
          setOrgReady(true);
        }
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles updating the user organization address
   */
  const updateUserOrganizationAddress = async (data, id) => {
    try {
      const updatedAddress = await apiClient.put(`/addresses/${id}`, data);
      if (updatedAddress) {
        setReady(false);
        setAddressReady(true);
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles creating user org data
   */
  const createUserOrganizationData = async (data, model, id) => {
    try {
      const createdData = await apiClient.post(
        `/companies/${model}/${id}`,
        data
      );
      if (createdData) {
        const { data } = createdData;
        setReady(false);
        setOrgReady(true);
        const id = data.id;
        const addressData = {
          country,
          county,
          city,
          street,
          number,
          name: `adresa-${id}`,
        };
        setAddressEdited(true);
        createUserOrganizationAddress(addressData, "company", id);
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles creating user org address
   */
  const createUserOrganizationAddress = async (data, model, id) => {
    try {
      const createdData = await apiClient.post(
        `/addresses/${model}/${id}`,
        data
      );
      if (createdData) {
        setReady(false);
        setAddressReady(true);
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const { id, address, company } = editData;
      const clientData = { name, fidelity, organization_id };

      const addressID =
        address && Object.keys(address).length > 0 ? address.id : null;
      const companyID =
        company && Object.keys(company).length > 0 ? company.id : null;

      if (description) clientData["description"] = description;

      const orgData = {};

      if (is_company) {
        orgData["reg_number"] = reg_number;
        orgData["vat_number"] = vat_number;
        orgData["name"] = name;
      } else {
        if (phone) clientData["phone"] = phone;
        if (email) clientData["email"] = email;
      }

      if (delegate_name) clientData["delegate_name"] = delegate_name;

      if (email) orgData["email"] = email;
      if (phone) orgData["phone"] = phone;

      const addressData = {
        country,
        county,
        city,
        street,
        number,
      };

      if (companyID) {
        if (Object.keys(orgData).length > 0)
          updateUserOrganizationData(orgData, companyID);
      } else if (is_company && !companyID) {
        setCompanyEmpty(true);
        createUserOrganizationData(
          orgData,
          "organizationClient",
          organization_id
        );
      }

      if (!addressID && !companyID && !is_company) setSkip(true);

      if (addressID) {
        setAddressEdited(true);
        updateUserOrganizationAddress(addressData, addressID);
      } else if (companyID) {
        setAddressEdited(true);

        const data = {
          ...addressData,
          name: `adresa-${id}`,
        };
        createUserOrganizationAddress(data, "company", companyID);
      }

      updateClient(clientData, id);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (editData.id && open) getClientById(editData.id);
    // eslint-disable-next-line
  }, [editData.id, open]);

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      });
      setOrganizations(orgs);
    }
  }, [user]);

  useEffect(() => {
    if (clientReady && orgReady) {
      if (addressEditted && addressReady) {
        setLoading(false);
        /**
         * Triggers a new search using the current search model
         */
        setUpdated(true);
        handleCloseModal();
        dispatchMessage({
          message: t("successMessage"),
        });
      } else {
        /**
         * Triggers a new search using the current search model
         */
        setUpdated(true);
        setLoading(false);
        handleCloseModal();
        dispatchMessage({
          message: t("successMessage"),
        });
      }
    }
    // eslint-disable-next-line
  }, [addressReady, clientReady, orgReady, addressEditted]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        unmountOnExit
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleEditClient")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-client-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          className={classes.checkboxContainer}
                        >
                          <Input
                            {...input}
                            type="checkbox"
                            className={classes.checkbox}
                            inputCheckbox={{
                              id: "fidelity",
                              name: "fidelity",
                              value: fidelity,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("fidelityLabel"),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "name",
                              required: true,
                              name: "name",
                              value: name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("nameLabel"),
                              error: nameError,
                              autoFocus: getAutoFocus().name,
                            }}
                          />
                        </Grid>
                        <Zoom in={is_company} mountOnEnter unmountOnExit>
                          <Grid item xs={12} md={4}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "delegate_name",
                                required: true,
                                name: "delegate_name",
                                value: delegate_name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("delegate_nameLabel"),
                                error: delegate_nameError,
                                autoFocus: getAutoFocus().delegate_name,
                              }}
                            />
                          </Grid>
                        </Zoom>
                        <Grid item xs={12} md={4}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              required: true,
                              name: "organization_id",
                              value: organization_id,
                              onChange: handleInputChange,
                              label: t("organization_idLabel"),
                              options: organizations,
                              optionLabel: "label",
                              optionValue: "value",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Zoom in={is_company} mountOnEnter unmountOnExit>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "vat_number",
                                required: true,
                                name: "vat_number",
                                value: vat_number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("vat_numberLabel"),
                                error: vat_numberError,
                                autoFocus: getAutoFocus().vat_number,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "reg_number",
                                required: true,
                                name: "reg_number",
                                value: reg_number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("reg_numberLabel"),
                                error: reg_numberError,
                                autoFocus: getAutoFocus().reg_number,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "email",
                                name: "email",
                                value: email,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("emailLabel"),
                                error: emailError,
                                autoFocus: getAutoFocus().email,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="phone"
                              className={classes.field}
                              inputPhone={{
                                id: "phone",
                                name: "phone",
                                value: phone,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("phoneLabel"),
                                error: phoneError,
                                autoFocus: getAutoFocus().phone,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Zoom>

                      <Zoom in={is_company} mountOnEnter unmountOnExit>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "country",
                                required: true,
                                name: "country",
                                value: country,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("countryLabel"),
                                error: countryError,
                                autoFocus: getAutoFocus().country,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "county",
                                required: true,
                                name: "county",
                                value: county,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("countyLabel"),
                                error: countyError,
                                autoFocus: getAutoFocus().county,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "city",
                                required: true,
                                name: "city",
                                value: city,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("cityLabel"),
                                error: cityError,
                                autoFocus: getAutoFocus().city,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "street",
                                required: true,
                                name: "street",
                                value: street,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("streetLabel"),
                                error: streetError,
                                autoFocus: getAutoFocus().street,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "number",
                                required: true,
                                name: "number",
                                value: number,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("numberLabel"),
                                error: numberError,
                                autoFocus: getAutoFocus().number,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Zoom>
                      {!is_company && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "email",
                                name: "email",
                                value: email,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("emailLabel"),
                                error: emailError,
                                autoFocus: getAutoFocus().email,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="phone"
                              className={classes.field}
                              inputPhone={{
                                id: "phone",
                                name: "phone",
                                value: phone,
                                required: true,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "standard",
                                label: t("phoneLabel"),
                                error: phoneError,
                                autoFocus: getAutoFocus().phone,
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid container>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            className={classes.textarea}
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 3,
                              rowsMax: 6,
                              maxChars: 200,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel"),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-client-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditClientModal.propTypes = propTypes;
EditClientModal.defaultProps = defaultProps;

export default EditClientModal;
export {
  propTypes as EditClientModalPropTypes,
  defaultProps as EditClientModalDefaultProps,
};

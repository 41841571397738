/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    border: "1px solid #dadada",
    padding: "0.5rem",
    margin: "0.25rem",
    borderRadius: 5,
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.sidebar.main
  },
  contactContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& $contactName": {
        color: theme.palette.primary.main
      },
      "& $contactInfo": {
        color: theme.palette.primary.main
      }
    }
  },
  contactName: {
    fontSize: "0.9rem",
    color: theme.palette.secondary.light,
    fontWeight: 800,
    marginRight: "0.25rem",
    display: "flex",
    alignItems: "center"
  },
  editSmall: {
    padding: "0.15rem",
    margin: "0 0.25rem",
    minWidth: 0,
    "& svg": {
      margin: 0
    }
  },
  deleteButton: {
    padding: "0.15rem",
    marginLeft: "0.3rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff"
    }
  },
  contactInfo: {
    fontSize: "0.85rem",
    display: "flex",
    alignItems: "center",
    fontWeight: 800,
    color: theme.palette.secondary.light
  },
  contactIcon: {
    color: theme.palette.primary.main,
    marginRight: "0.25rem",
    marginLeft: "1rem",
    fontSize: "1.2rem"
  },
  contactIconLg: {
    color: theme.palette.primary.main,
    marginLeft: "0.25rem",
    marginRight: "0.25rem",
    fontSize: "1.4rem"
  },
  actions: {
    display: "flex",
    alignItems: "center"
  }
}));

export { useStyles };

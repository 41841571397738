const en_hu = {
  "30 Days": "30 nap",
  "<": "kisebb mint",
  "<=": "kisebb vagy egyenlő mint",
  "<=_label": "kisebb vagy egyenlő mint",
  "<_label": "kisebb mint",
  "=": "egyenlő",
  "=_label": "egyenlő",
  ">": "nagyobb mint",
  ">=": "nagyobb vagy egyenlő mint",
  ">=_label": "nagyobb vagy egyenlő mint",
  ">_label": "nagyobb mint",
  "Account settings": "Fiók beállítások",
  AccountSettingsAppointmentGroups: "AccountSettingsAppointmentGroups",
  AccountSettingsCarTypes: "AccountSettingsCarTypes",
  AccountSettingsMain: "AccountSettingsMain",
  AccountSettingsOrders: "AccountSettingsOrders",
  AccountSettingsOrganizations: "AccountSettingsOrganizations",
  AccountSettingsPage: "AccountSettingsPage",
  AccountSettingsPaymentMethods: "AccountSettingsPaymentMethods",
  AccountSettingsSidebar: "AccountSettingsSidebar",
  AccountSettingsUpgrade: "AccountSettingsUpgrade",
  AccountSettingsUsers: "AccountSettingsUsers",
  AccountSettingsWorkers: "AccountSettingsWorkers",
  AddAppointmentGroupModal: "AddAppointmentGroupModal",
  AddAppointmentModal: "AddAppointmentModal",
  AddCarTypeModal: "AddCarTypeModal",
  AddClientModal: "AddClientModal",
  AddContractModal: "AddContractModal",
  AddLoyaltyCardModal: "AddLoyaltyCardModal",
  AddOrgContactModal: "AddOrgContactModal",
  AddOrganizationModal: "AddOrganizationModal",
  AddPaymentMethodModal: "AddPaymentMethodModal",
  AddProductConditionModal: "AddProductConditionModal",
  AddProductModal: "AddProductModal",
  AddTyreHotelModal: "AddTyreHotelModal",
  AddUserModal: "AddUserModal",
  AddWorkOrderProductDraftModal: "AddWorkOrderProductDraftModal",
  AddWorkOrderProductModal: "AddWorkOrderProductModal",
  AddWorkOrderServiceDraftModal: "AddWorkOrderServiceDraftModal",
  AddWorkOrderServiceModal: "AddWorkOrderServiceModal",
  AddWorkOrderWorkerModal: "AddWorkOrderWorkerModal",
  AddWorkerModal: "AddWorkerModal",
  AppointmentGroupsTable: "AppointmentGroupsTable",
  AppointmentsScheduler: "AppointmentsScheduler",
  AttendanceTable: "AttendanceTable",
  AuthPage: "AuthPage",
  BarcodeReader: "BarcodeReader",
  CARD: "BANKKÁRTYA",
  CASH: "KÉSZPÉNZ",
  "Car Service": "Autószerviz",
  "Car Wash": "Autómosó",
  CarServiceMain: "CarServiceMain",
  CarServicePage: "CarServicePage",
  CarServiceSidebar: "CarServiceSidebar",
  CarTypesTable: "CarTypesTable",
  CarWashMain: "CarWashMain",
  CarWashPage: "CarWashPage",
  CarWashSidebar: "CarWashSidebar",
  ClientsSearchForm: "ClientsSearchForm",
  ClientsTable: "ClientsTable",
  CompanyContactDataBox: "CompanyContactDataBox",
  CompanyDataForm: "CompanyDataForm",
  ConfirmUpgradeModal: "ConfirmUpgradeModal",
  ContactDataForm: "ContactDataForm",
  ContactItem: "ContactItem",
  ContractCard: "ContractCard",
  ContractsSearchForm: "ContractsSearchForm",
  ContractsTable: "ContractsTable",
  CreateButton: "CreateButton",
  CreateWorkOrderModal: "CreateWorkOrderModal",
  CreateWorkOrderStage1: "CreateWorkOrderStage1",
  CreateWorkOrderStage2: "CreateWorkOrderStage2",
  CreateWorkOrderStage3: "CreateWorkOrderStage3",
  CreateWorkOrderStage4: "CreateWorkOrderStage4",
  DataTable: "DataTable",
  DeleteAccountModal: "DeleteAccountModal",
  DeleteAppointmentModal: "DeleteAppointmentModal",
  DeleteButton: "DeleteButton",
  DeleteClientModal: "DeleteClientModal",
  DeleteContractModal: "DeleteContractModal",
  DeleteLoyaltyCardModal: "DeleteLoyaltyCardModal",
  DeleteModal: "DeleteModal",
  DeleteProductModal: "DeleteProductModal",
  DeleteTyreHotelModal: "DeleteTyreHotelModal",
  DeleteUserModal: "DeleteUserModal",
  DeleteWorkOrderModal: "DeleteWorkOrderModal",
  DeleteWorkOrderTimerModal: "DeleteWorkOrderTimerModal",
  DeleteWorkerModal: "DeleteWorkerModal",
  DetailedAttendanceModal: "DetailedAttendanceModal",
  DocumentModal: "DocumentModal",
  DocumentPage: "DocumentPage",
  DocumentViewer: "DocumentViewer",
  DynamicSearchForm: "DynamicSearchForm",
  DynamicTable: "DynamicTable",
  EditAppointmentGroupModal: "EditAppointmentGroupModal",
  EditAppointmentModal: "EditAppointmentModal",
  EditButton: "EditButton",
  EditCarTypeModal: "EditCarTypeModal",
  EditClientModal: "EditClientModal",
  EditContractModal: "EditContractModal",
  EditLoyaltyCardModal: "EditLoyaltyCardModal",
  EditOrgAddressModal: "EditOrgAddressModal",
  EditOrgContactModal: "EditOrgContactModal",
  EditOrganizationModal: "EditOrganizationModal",
  EditPaymentMethodModal: "EditPaymentMethodModal",
  EditProductConditionModal: "EditProductConditionModal",
  EditProductModal: "EditProductModal",
  EditTyreHotelModal: "EditTyreHotelModal",
  EditUserModal: "EditUserModal",
  EditWorkOrderModal: "EditWorkOrderModal",
  EditWorkOrderProductDraftModal: "EditWorkOrderProductDraftModal",
  EditWorkOrderProductModal: "EditWorkOrderProductModal",
  EditWorkOrderServiceDraftModal: "EditWorkOrderServiceDraftModal",
  EditWorkOrderServiceModal: "EditWorkOrderServiceModal",
  EditWorkOrderSettingsModal: "EditWorkOrderSettingsModal",
  EditWorkOrderStage1: "EditWorkOrderStage1",
  EditWorkOrderStage2: "EditWorkOrderStage2",
  EditWorkOrderStage3: "EditWorkOrderStage3",
  EditWorkerModal: "EditWorkerModal",
  ErrorMessages: "ErrorMessages",
  "FACT TERMENI": "HATÁRIDŐS SZÁMLA",
  "FISA TERMENI": "HATÁRIDŐS FIZETÉS",
  Form: "Form",
  GDPR: "GDPR",
  GridViewMode: "GridViewMode",
  GuestSidebar: "GuestSidebar",
  Importer: "Beszállító",
  InfoButton: "InfoButton",
  InputAutocomplete: "InputAutocomplete",
  InputDate: "InputDate",
  InputDateRange: "InputDateRange",
  InputDateTime: "InputDateTime",
  InputNumeric: "InputNumeric",
  InputPassword: "InputPassword",
  InputPostCode: "InputPassword",
  InputSearch: "InputSearch",
  ListBox: "ListBox",
  LoadingBackdrop: "LoadingBackdrop",
  LoadingText: "LoadingText",
  LoginForm: "LoginForm",
  LoginPage: "Bejelentkezési oldal",
  LoyaltyCard: "LoyaltyCard",
  LoyaltyCards: "LoyaltyCards",
  LoyaltyCardsSearchForm: "LoyaltyCardsSearchForm",
  MessagePopup: "MessagePopup",
  Modal: "Modal",
  ModalActions: "ModalActions",
  ModalContent: "ModalContent",
  ModalTitle: "ModalTitle",
  ModuleCard: "ModuleCard",
  ModuleCardMini: "ModuleCardMini",
  ModulePackageCard: "ModulePackageCard",
  ModulePackages: "ModulePackages",
  ModuleSelectionPage: "ModuleSelectionPage",
  Modules: "Modules",
  ModulesFooter: "ModulesFooter",
  ModulesHeader: "ModulesHeader",
  ModulesModal: "ModulesModal",
  Nou: "új",
  OrderCard: "OrderCard",
  OrderItem: "OrderItem",
  Orders: "Orders",
  OrganizationItem: "OrganizationItem",
  OrganizationsTabs: "OrganizationsTabs",
  PDFNavigation: "PDFNavigation",
  PROTOCOL: "PROTOKOLL",
  PageNotFound: "PageNotFound",
  PaymentMethodCard: "PaymentMethodCard",
  PaymentMethods: "PaymentMethods",
  PaymentMethodsTable: "PaymentMethodsTable",
  "Pentru fiecare punct de lucru consecutiv se adaugă o reducere adițională de 33%":
    "Minden egymást követő munkapontra további 33% -os kedvezmény jár ",
  Popper: "Popper",
  PrintTimesheetReport: "PrintTimesheetReport",
  PrintTyreHotels: "PrintTyreHotels",
  PrintTyreHotelsClient: "PrintTyreHotelsClient",
  ProductCard: "ProductCard",
  ProductsSearchForm: "ProductsSearchForm",
  ProductsTable: "ProductsTable",
  PromotionCodeForm: "PromotionCodeForm",
  Providers: "Providers",
  REG: "Cégjegyzékszám",
  ReCaptcha: "ReCaptcha",
  ReactVrSelect: "ReactVrSelect",
  RegisterPage: "RegisterPage",
  ReportsAdvanced: "ReportsAdvanced",
  ReportsAdvancedSearch: "ReportsAdvancedSearch",
  ReportsAdvancedTable: "ReportsAdvancedTable",
  ReportsCompanies: "ReportsCompanies",
  ReportsCompaniesObservationsTable: "ReportsCompaniesObservationsTable",
  ReportsCompaniesSearch: "ReportsCompaniesSearch",
  ReportsCompaniesTable: "ReportsCompaniesTable",
  ReportsCompaniesTableTotalization: "ReportsCompaniesTableTotalization",
  ReportsDaily: "ReportsDaily",
  ReportsDailySearch: "ReportsDailySearch",
  ReportsDailyTable: "ReportsDailyTable",
  ReportsMonthly: "ReportsMonthly",
  ReportsMonthlySearch: "ReportsMonthlySearch",
  ReportsMonthlyTable: "ReportsMonthlyTable",
  ReportsPage: "ReportsPage",
  ReportsSidebar: "ReportsSidebar",
  ReportsTyreWaste: "ReportsTyreWaste",
  ReportsTyreWasteSearch: "ReportsTyreWasteSearch",
  ReportsTyreWasteTable: "ReportsTyreWasteTable",
  ReportsWorkers: "ReportsWorkers",
  ReportsWorkersSearch: "ReportsWorkersSearch",
  ReportsWorkersTable: "ReportsWorkersTable",
  Routes: "Routes",
  SchedulerStatusChanger: "SchedulerStatusChanger",
  ScrollToTop: "ScrollToTop",
  SelectWorkOrderPaymentType: "SelectWorkOrderPaymentType",
  "Serviciu de anvelope": "Gumiabroncs szolgáltatások",
  "Setările contului": "Fiók beállítások",
  "Setările stației de lucru": "Munkapont beállítások",
  Sidebar: "Sidebar",
  SmallModuleCards: "SmallModuleCards",
  Span: "Span",
  SubmoduleContainer: "SubmoduleContainer",
  SubmoduleTitle: "SubmoduleTitle",
  SubmoduleWrapper: "SubmoduleWrapper",
  SuperAdminMain: "SuperAdminMain",
  SuperAdminPage: "SuperAdminPage",
  SuperAdminSidebar: "SuperAdminSidebar",
  TabPanel: "TabPanel",
  TableActions: "TableActions",
  TableCellHeader: "TableCellHeader",
  TableCellValue: "TableCellValue",
  TableHeader: "TableHeader",
  TablePagination: "TablePagination",
  TablePaginationActions: "TablePaginationActions",
  TablePopover: "TablePopover",
  TableResultsNotFound: "TableResultsNotFound",
  TableRows: "TableRows",
  TableSearchInput: "TableSearchInput",
  Terminat: "kész",
  TextCount: "TextCount",
  TextMask: "TextMask",
  "This contains all modules (Tyre Service, Car Wash, Car Service) with all functionalities":
    "Ez tartalmazza az összes modult (gumiabroncs szerviz, autómosó, autószerviz) minden funkcióval",
  "This is the car service account with all functionalities":
    "Ez az autószerviz fiók minden funkcióval ",
  "This is the car wash account with all functionalities":
    "Ez az autómosó fiók minden funkcióval ",
  "This is the tyre service account with all functionalities":
    "Ez a gumiabroncs szerviz fiók minden funkcióval ",
  TimesheetAdminView: "TimesheetAdminView",
  TimesheetLogEntry: "TimesheetLogEntry",
  TimesheetModal: "TimesheetModal",
  TimesheetReport: "TimesheetReport",
  Tooltip: "Tooltip",
  "Tyre Service": "Gumiabroncs szolgáltatások",
  TyreHotelCard: "TyreHotelCard",
  TyreHotelsChoiceModal: "TyreHotelsChoiceModal",
  TyreHotelsSearchForm: "TyreHotelsSearchForm",
  TyreHotelsTable: "TyreHotelsTable",
  TyreServiceControlPanel: "TyreServiceControlPanel",
  TyreServiceCreateWorkOrders: "TyreServiceCreateWorkOrders",
  TyreServiceMain: "TyreServiceMain",
  TyreServicePage: "TyreServicePage",
  TyreServiceScheduler: "TyreServiceScheduler",
  TyreServiceSidebar: "TyreServiceSidebar",
  TyreServiceTyreHotels: "TyreServiceTyreHotels",
  TyreServiceWorkOrderTable: "TyreServiceWorkOrderTable",
  TyreServiceWorkOrdersTable: "TyreServiceWorkOrdersTable",
  UpgradeExplanation: "UpgradeExplanation",
  "User with this username does not exist!":
    "Felhasználó ezzel a felhasználónévvel nem létezik! ",
  UserCard: "UserCard",
  UserDataForm: "UserDataForm",
  UserOrganizations: "UserOrganizations",
  Users: "Users",
  UsersTable: "UsersTable",
  VAT: "Adószám",
  ViewMode: "ViewMode",
  ViewOrgContactModal: "ViewOrgContactModal",
  ViewPriceConditionsModal: "ViewPriceConditionsModal",
  ViewWorkOrderModal: "ViewWorkOrderModal",
  Widget: "Widget",
  Widgets: "Widgets",
  WorkOrderCard: "WorkOrderCard",
  WorkOrderDisclaimer: "WorkOrderDisclaimer",
  WorkOrderHeader: "WorkOrderHeader",
  WorkOrderSearchForm: "WorkOrderSearchForm",
  WorkOrderServices: "WorkOrderServices",
  WorkOrderSignature: "WorkOrderSignature",
  WorkOrderTable: "WorkOrderTable",
  WorkOrderTimerCard: "WorkOrderTimerCard",
  WorkOrderView: "WorkOrderView",
  WorkOrderWorkers: "WorkOrderWorkers",
  WorkOrdersTable: "WorkOrdersTable",
  WorkerAttendanceCard: "WorkerAttendanceCard",
  WorkerAttendanceModal: "WorkerAttendanceModal",
  WorkerCard: "WorkerCard",
  WorkersTable: "WorkersTable",
  "Workstation settings": "Munkapont beállítások",
  WorkstationSettingsContracts: "WorkstationSettingsContracts",
  WorkstationSettingsLoyaltyCards: "WorkstationSettingsLoyaltyCards",
  WorkstationSettingsPage: "WorkstationSettingsPage",
  WorkstationSettingsProducts: "WorkstationSettingsProducts",
  WorkstationSettingsSidebar: "WorkstationSettingsSidebar",
  WorkstationSettingsTyreHotels: "WorkstationSettingsTyreHotels",
  WorkstationSettingsWorkOrders: "WorkstationSettingsWorkOrders",
  "Wrong password!": "Helytelen jelszó, próbálkozzon újra.",
  aboutGDPR: "GDPR rendeletről",
  acceptTermsAndConditions: "Elfogadom a felhasználási feltételeket",
  accountChanged: "Fiók sikeresen módosítva!",
  accountCreated: "Cont creat cu success!",
  accountData: "Fiók adatok",
  accountDeletedPermanently: "Ez a fiók véglegesen le van tiltva.",
  accountDeletedSuccessfuly: "A fiók sikeresen törölve!",
  accountRecovered: "Fiók sikeresen visszaállítva !",
  accountSettings: "Account Settings",
  accountStatus: "Fiók státusza",
  accountType: "Tip Cont",
  actions: "Eljárások",
  activate: "Aktiválja",
  activated: "A szolgáltatás aktiválva",
  active: "Aktív",
  activeService: "Aktív",
  activeUntil: "Aktív",
  add: "Hozzáadás",
  addContact: "Kontakt hozzáadása",
  addNew: "Eredmény hozzáadása",
  addNewCard: "Új kártya hozzáadása",
  addNewClient: "Ügyfél hozzáadása",
  addNewContract: "Szerződés hozzáadása",
  addNewHotel: "Hotel hpzzáadása",
  addNewOrganization: "Telephely Hozzáadása",
  addNewProduct: "Termék hozzáadása",
  addNewUser: "Új felhasználó hozzáadása",
  addNewWorker: "Munkás hozzáadása",
  addProduct: "Termék hozzáadása",
  addService: "Szolgáltatás módosítása",
  addWorkerBtn: "Hozzáadás",
  address: "Cím",
  admin: "Adminisztrátor",
  adrLabel: "Cím",
  advSearchActive: "Részletes keresés (bekapcsolva)",
  advSearchInactive: "Részletes keresés (kikapcsolva)",
  advanced: "Részletes jelentés",
  advancedReports: "Részletes jelentés",
  all: "Összes",
  allOrgs: "Összes",
  appointmentDeleted: "A programálás sikeresen törölve!",
  appointmentGroups: "Programálások csoportja",
  appointment_group_id: "Csoport kiválasztása",
  areYouSureYouWantToDelete: "Biztosan törli ",
  areYouSureYouWantToDeleteTheAppointment: "Biztosan törli a programáslást?",
  areYouSureYouWantToDeleteTheCard: "Biztosan törli a (z) számú kártyát? ",
  areYouSureYouWantToDeleteTheClient: "Biztosan törli az ügyfelet?",
  areYouSureYouWantToDeleteTheContract: "Biztosan törli a szerződést? ",
  areYouSureYouWantToDeleteTheProduct: "Biztosan törli a terméket?",
  areYouSureYouWantToDeleteTheWorkOrder: "Biztosan törli a rendelést? ",
  areYouSureYouWantToDeleteTheWorker: "Biztosan törli a munkást? ",
  areYouSureYouWantToDeleteTheAccount:
    "Are you sure you want to delete the account associated with ",
  asc: "Növekvő",
  attendanceReport: "Jelenléti jelentés",
  backToMenu: "Vissza a menübe",
  baseHelper: "Csak számok / betűk és a space megengedettek.",
  baseReductionExplanation: " ",
  billingInfo: "Számlázási adatok",
  birth_date: "Születési dátum",
  birth_dateLabel: "Születési dátum",
  boltTorque: "Csavarnyomaték",
  bolt_torqueLabel: "Csavarnyomaték",
  cancelLabel: "Törli",
  car: "Autó",
  carDescription: "Leírás",
  carKm: "Autó km",
  carMake: "Gyártó",
  carModel: "Típus",
  carPlate: "Rendszám",
  carService: "Autó szervíz",
  carType: "Autó típus",
  carTypeCreated: "Az autó típusa sikeresen létrehozva!",
  carTypes: "Autótípusok",
  carWash: "Autómosó",
  car_description: "DOT / Megjegyzések",
  car_km: "Km a műszerfalon",
  car_kmLabel: "Km a műszerfalon ",
  car_modelLabel: "Autómodell",
  car_number: "Regisztrációs szám",
  car_numberLabel: "Regisztrációs szám",
  car_plate_number: "Regisztrációs szám",
  car_plate_numberLabel: "Regisztrációs szám",
  car_type: "Típus",
  car_typeLabel: "Autó típus",
  car_type_id: "Autótípus",
  car_types: "Autótípusok",
  cardAdded: "A kártya sikeresen hozzáadva!",
  cardDeleted: "A kártya sikeresen törölve!",
  cardFound: "Hűségkártya azonosítva",
  cardModified: "Kártya sikeresen módosítva!",
  cardNotFound: "Sajnáljuk. Ezzel a kóddal a kártya nem található",
  card_numberLabel: "Kártyaszám",
  card_typeLabel: "A kártya típusa",
  change_status: "Státuszváltozás",
  changesUpdatedSuccessfuly: "A módosításokat sikeresen mentettük.",
  charText: "karakterek",
  characters: "karakterek.",
  cityLabel: "Város",
  cityPlaceholder: "Pl: Cluj-Napoca",
  clearStatus: "Státusz törlése",
  client: "Ügyfél adatlap",
  clientCreated: "Sikeresen létrehozott ügyfél!",
  clientData: "Ügyféladatok",
  clientDeleted: "Az ügyfél sikeresen törölve!",
  clientLabel: "Ügyfél neve",
  clientModified: "Ügyfél sikeresen módosítva!",
  clientName: "Név",
  clientType: "Ügyfél típusa",
  client_name: "Ügyfél",
  client_nameLabel: "Ügyfél neve",
  clients: "Ügyfelek",
  clientsLabel: "Ügyfél",
  close: "Bezár",
  closeList: "Bezár",
  companies: "Cégjelentés",
  companiesReports: "Cégjelentés",
  company: "Jogi személy",
  companyData: "A cég adatai",
  companyName: "Cég",
  company_emailLabel: "Email",
  company_emailPlaceholder: "Pl: office@pangus.ro",
  company_nameLabel: "Megnevezés",
  company_namePlaceholder: "Pl: SC Atelierulmeu SRL",
  company_phoneLabel: "Telefonszám",
  company_phonePlaceholder: "Pl: 0123 123 123",
  company_regLabel: "Nr. Înreg.",
  company_time_sheetLabel: "Munkaprogram",
  company_time_sheetPlaceholder:
    "Pl: H-P 08:00 - 18:00 | S 08:00 - 13:00 | V Zárva",
  company_vatLabel: "C.U.I / C.I.F",
  company_webpageLabel: "Weboldal",
  company_webpagePlaceholder: "Pl: www.pangus.ro",
  conditionAdded: "Feltétel sikeresen hozzáadva!",
  conditionDeleted: "A feltétel sikeresen törölve.",
  conditionLabel: "Feltétel",
  conditionModified: "A feltétel sikeresen módosítva!",
  config: "Konfiguráció",
  confirmAttendance: "Küld",
  confirmYourPassword: "Jelszó megerősítése",
  confirm_textLabel: "Erősítse meg a cég nevét",
  contactData: "Kapcsolati adatok",
  contactDeleted: "A kontakt sikeresen törölve",
  contactMe: "Vedd fel velünk a kapcsolatot!",
  contactUs: "Lépjen kapcsolatba velünk",
  contacts: "Kontaktok",
  contractAdded: "A szerződés sikeresen hozzáadva!",
  contractCreated: "Sikeresen létrehozott kapcsolat!",
  contractDeleted: "A szerződés sikeresen törölve!",
  contractModified: "Szerződés sikeresen módosítva!",
  contract_number: "Szerződés szám",
  contract_numberLabel: "Szerződés szám",
  contracts: "Szerződések",
  controlPanel: "Vezérlőpult",
  cookiePolicy: "Cookie",
  cookieSettings: "Süti-beállítások",
  copyRightText: "© {{year}} Atelierulmeu. Minden jog fenntartva.",
  count: "#",
  countSymbol: "#",
  countryLabel: "Ország",
  countryPlaceholder: "Pl: Romania",
  countyLabel: "Megye",
  countyPlaceholder: "Pl: Cluj",
  create: "Munkalapok hozzáadása",
  createWorkOrder: "Munkalapok hozzáadása",
  createdBy: "Készítette",
  createdSuccessfully: " sikeresen létrehozva.",
  created_at: "Létrehozás dátuma",
  created_atLabel: "Létrehozás dátuma",
  cuiLabel: "Adószám",
  cumulatedReductionExplanation: " ",
  cumulatedReductionExplanationExample:
    "Például : Az első munkapont 33%-os kedvezménnyt kap, a 2. munkapont már 66%-os kedvezményt kap. A 3. munkapont ingyenes lesz, a 4. munkapont újra 33% kedvezménnyel indul.",
  daily: "Napi jelentés",
  dailyReports: "Napi jelentés",
  dashboard: "Adminisztráció",
  dataUpdated: "Az adatok frissítésre kerültek",
  date: "Dátum",
  day: "Nap",
  day_1: "Hétfő",
  day_2: "kedd",
  day_3: "Szerda",
  day_4: "Csütörtök",
  day_5: "Péntek",
  day_6: "Szombat",
  day_7: "Vasárnap",
  days: "napok",
  delegate: "Küldött neve",
  delegate_name: "Családnév / Keersztnév",
  delegate_nameLabel: "Küldött neve",
  deletHotel: "Hotel törlése",
  delete: "Törlés",
  deleteAcc: "Fiók törlése",
  deleteAppointment: "Töröl",
  deleteCard: "Kártya törlése",
  deleteCondition: "Feltétel törlése",
  deleteConfirm: "Törlés",
  deleteConfirmation: "Biztosan törli a (z) fiókot ",
  deleteConfirmationText: "Biztosan törli ",
  deleteContact: "Törlés",
  deleteItem: "Törlés",
  deleteNoCardNum: "Biztosan törölni kívánja ezt a kártyát ?",
  deleteOrganization: "Törlés",
  deleteSuccessMessage: "Sikeresen törölve",
  deleteUser: "Törlés",
  deleteWorkOrder: "Rendelés törlése",
  deleted: "A fiók véglegesen felfüggesztve",
  deletedMessage: "A fizetési mód sikeresen törölve",
  deleted_success: "A fiók sikeresen inaktiválva.",
  deletegateName: "Küldött neve",
  deletingAccount: " ",
  desc: "Csökkenő",
  description: "Leírás",
  descriptionLabel: "Leírás",
  descriptionLabel_1: "Megjegyzések",
  descriptionLabel_10: "Leírás",
  descriptionLabel_11: "Leírás",
  descriptionLabel_12: "Leírás",
  descriptionLabel_13: "Megjegyzések",
  descriptionLabel_14: "Leírás",
  descriptionLabel_15: "Ügyfél leÍrása",
  descriptionLabel_16: "Leírás",
  descriptionLabel_17: "Leírás",
  descriptionLabel_18: "Leírás",
  descriptionLabel_19: "Leírás",
  descriptionLabel_2: "Leírás",
  descriptionLabel_20: "Leírás",
  descriptionLabel_21: "Leírás",
  descriptionLabel_22: "Leírás",
  descriptionLabel_23: "Leírás",
  descriptionLabel_3: "Az ügyfél leírása",
  descriptionLabel_4: "Leírás",
  descriptionLabel_5: "Leírás",
  descriptionLabel_6: "Leírás",
  descriptionLabel_7: "Leírás",
  descriptionLabel_8: "Leírás",
  descriptionLabel_9: "Leírás",
  detailed: "Részletes",
  disabled: "Inaktív modul",
  disclaimer: "Csak az elmúlt 12 óra adatai jelennek meg.",
  tyreServiceDisclaimerText1:
    "A szervizegység garantálja az elvégzett munkát *) és annak megfelelőségét **) az OUG140/2021 szerint * 3 hónapig a jármű átvételének dátumától számítva, ha a munkához nem volt szükség alkatrész cserére vagy az ügyfél által hozott alkatrésszel hajtották végre",
  tyreServiceDisclaimerText2:
    "** Az alkatrészek garanciája az OUG140/2021 alapján - a gyártó nyilatkozata szerint / a felek közötti megállapodás alapján, feltételesen, ill. a jármű gyártója által biztosított kezelési utasítás alapján ___ hónap",
  tyreServiceDisclaimerText3:
    "*** A ÜGYFÉL kérésére a cég biztosítja az elvégzett tevékenységek megtekintését és a végső ellenőrzések elvégzését igazoló nyilvántartásokat.",
  tyreServiceDisclaimerText4: "**** Nem vállalunk garanciát az ügyfél által hozott alkatrészekre.",
  tyreServiceDisclaimerTitle: "Minőségi tanúsítvány és garancia",
  discount: "Kedvezmény",
  discountCalcFormula: "Kedvezményszámítási képlet",
  discountLabel: "Kedvezmény ",
  discountTotal: "Számított kedvezmény",
  discount_typeLabel: "Kedvezmény típusa",
  discount_valueLabel: "Kedvezmény",
  document_prefixLabel: "Dokumentum előtagja",
  document_prefixPlaceholder: " ",
  document_suffixLabel: "Dokumentum utótagja",
  document_suffixPlaceholder: " ",
  documents: "Dokumentumok",
  done: "Befejez",
  doneWorkOrders: "Befejezve",
  downTrend: "Az új ár alacsonyabb, mint a standard ár",
  download: "Letöltés",
  downloadLabel: "Számla letöltése",
  duration: "Időtartam",
  durationLabel: "Szolgáltatás időtartama",
  edit: "Módosít",
  editAddress: "Cím módosítása",
  editAppointment: "Módosít",
  editCard: "Kártya módosítása",
  editCondition: "Feltétel módosítása",
  editContact: "Kontakt adatainak módosÍtása",
  editHotel: "Hotel módosítása",
  editInfo: "Adatok módosítása",
  editItem: "Módosít",
  editOrganization: "Telephely adatainak módosítása",
  editUser: "Munkás adatainak módosítása",
  editWorkOrder: "Rendelés módosítása",
  email: "A munkapont email címe",
  emailLabel: "Email",
  end: "Befejezés dátuma",
  endAttendance: "Kilépés",
  endDate: "Kilépés dátuma",
  endDateLabel: "Befejezés dátuma",
  error_emailInvalid: "E-mail cím érvénytelen",
  error_invalidNumber: "Érvénytelen szám",
  error_invalidPostalCode: "Csak számok / betűk és a space megengedettek.",
  error_isRequired: "Kötelező mező.",
  error_maxLength: "Legalább egy  ",
  error_minLength: "Kell tartalmazzon legalább egy ",
  error_noPasswordConfirm: "Kérjük, erősítse meg a jelszót",
  error_passwordsNotMatch: "A jelszavaknak egyezniük kell",
  error_telNumInvalid: "A telefonszám érvénytelen",
  error_telNumNoDigits: "Legalább 4 számjegyet kell tartalmaznia",
  error_urlInvalid: "Kérjük, írjon be egy érvényes url-t.",
  expendable: "Fogyóanyag",
  expendable_products: "Fogyóanyagok",
  explanation: "Hogyan számítják ki az árat??",
  extend: "Hosszabít",
  facebook: "Facebook",
  false: "Nem",
  female: "Nő",
  fidelity: "Hűséges ügyfél",
  fidelityLabel: "Hűséges ügyfél",
  fieldLabel: "Mező",
  finalizeRegister: "Véglegesítse",
  finished: "Befejezve",
  finishedLabel: "Befejezés dátuma",
  firstPage: "Első oldal",
  first_nameLabel: "Keresztnév",
  flatTrend: "Az új ár megegyezik a standard árral",
  forgotPassword: "Elfelejtette a jelszót?",
  free30: "Próbaidő 30 nap",
  from: "",
  fromLabel: "tól",
  function: "Funkció",
  functionLabel: "Funkció",
  functionPlaceholder: "Ex",
  gender: "Nem",
  genderLabel: "Nem",
  genderMissing: " - ",
  generalAndWorkers: "Általános adatok / Munkások",
  generalInfo: "Általános Információk",
  groupCreated: "Sikeresen létrehozott csoport!",
  groupless: "Csoport nélküli",
  groups: "Szűrés csoportok szerint",
  groupsPlaceholder: "Csoport keresése",
  guaranteeForms: "Garancia Űrlap",
  haveAnAccount: "Már van fiókom.",
  helperText: " ",
  home: "Főoldal",
  hotelChanged: "A gumiabroncs hotel sikeresen módosítva!",
  hotelData: "Gumiabroncsok tárolása",
  hotels: "Gumiabroncs Hotel",
  hoursWorked: "Ledolfozott órák",
  id: "Ügyiratszám",
  if: "ha",
  import: "Excel fájl feltöltése",
  "in-progress": "folyamatban",
  invalid_promo: "A promóciós kód érvénytelen.",
  ireversible: "Ez a művelet visszafordíthatatlan!",
  is: "van",
  isCountPrice: "Aktivált ár",
  is_active: "Aktív",
  is_activeLabel: "Kártya aktiválása",
  is_company: "Jogi személy?",
  is_companyLabel: "Jogi személy",
  is_count_price: "Aktivált ár",
  is_count_priceLabel: "Ár aktiválva",
  is_expendable: "Fogyóanyag",
  is_expendableLabel: "Fogyó anyagok",
  is_inactive: "Inaktív",
  is_service: "Szolgáltatás",
  is_serviceLabel: "Szolgáltatás",
  itemName: "Megnevezés",
  lastAttendance: "Utolsó frissítés",
  lastPage: "Utolsó oldal",
  lastWeek: "Az előző hét ",
  last_nameLabel: "Családnév",
  left: "maradt",
  linkedin: "LinkedIn",
  loginBtnText: "Belépés",
  logout: "Kijelentkezés",
  loyaltyCard: "Hűségkártya",
  loyaltyCards: "Hűségkártyák",
  loyaltyClients: "Hűséges vásárlók",
  loyaltyTitle: "Hűségkártya",
  loyalty_card: "Hűségkártya",
  main: "Adminisztráció",
  mainLogoTitle: "Műhelyem",
  makeLabel: "Autó márka",
  male: "Férfi",
  messages: "Üzenetek",
  modelLabel: "Típus",
  modifiedSuccessfuly: "Sikeresen módosítva!",
  "module.Reports": "Jelentések",
  modulePrice: "A modul ára",
  modules: "modulok",
  modulesMenu: "Vissza a menübe",
  month: "(Hónap)",
  monthly: "Havi jelentés",
  monthlyReports: "Havi jelentés",
  months: "(hónapok)",
  more: "több",
  moreActions: "További műveletek",
  mountingStation: "Szerelőállomások",
  mountingStations: "Szerelőállomások",
  myAccount: "Fiókom",
  name: "Név",
  nameLabel: "Név",
  productNameLabel: "Termék megnevezése",
  searchProductNameLabel: "Termék szürése",
  serviceNameLabel: "Szolgáltatás megnevezése",
  searchServiceNameLabel: "Szolgáltatás szürése",
  new: "Új",
  newTyre: "Új gumiabroncsok",
  new_priceBecomes: "az új ár lesz",
  new_priceLabel: "Új ár",
  new_tyreLabel: "Új gumiabroncsok",
  new_tyres: "Új gumiabroncsok",
  next: "Következő",
  nextWeek: "Következő hét ",
  noBarcode: "Sajnáljuk. Nemi smerte fel a vonalkódot",
  noGDPR: "A folytatáshoz el kell fogadnia a sütiket.",
  noRecentlyAddedOrdersFound: "Nem található nemrégiben hozzáadott rendelés.",
  noResultsFound: "Nincs találat. Próbálja ki az alábbi lehetőségeket.",
  none: "Egyik sem",
  normalClients: "Ügyfelek",
  notActivated: " Inaktív ár",
  notCountPriceExplanation:
    "A termék ára inaktív, a „Termékek” részben módosítani kell.",
  notFound: "Nincs megjelenítendő adat",
  notFoundExtended: " ",
  notFoundNoHelpers:
    "Nincs találat. Próbálja visszaállítani a keresési szűrőket",
  now: "most ",
  numberLabel: "Szám",
  observation: "Észrevételek",
  observations: "Észrevételek",
  ok: "OK",
  "old-data": "Régi adatok",
  "on-hold": "Várakozás",
  operations: "Műveletek",
  operator: "Operátor",
  operatorName: "Operátor neve",
  orderByLabel: "Rendezés",
  orderData: "Rendelés részletei",
  orderDetails: "Rendelés részletei",
  orderDirLabel: "Sorrend",
  orderID: "Rendelésszám",
  orderNumber: "Rendelésszám",
  order_by: "Rendezés",
  order_dir: "Sorrend",
  order_idLabel: "Rendelésszám",
  orders: "Rendelések",
  org: "Telephely",
  orgLabel: "Cég",
  organization: "Telephely",
  organizationCreated: "Sikeresen létrehozott telephely!",
  organizationLabel: "Telephely",
  organization_client_id: "Ügyfél",
  organization_client_idLabel: "Válassza ki az ügyfelet",
  organization_id: "Telephely",
  organization_idLabel: "Munkapont",
  organizations: "Telephelyek",
  organizationsCount: "Munkapontok száma",
  outOf: " ból ",
  page: "Oldal",
  pageNotAvailable: "Az oldal érvénytelen",
  pageNotFound: "Az oldal nem található",
  pageSizeLabel: "Oldalankénti tételek",
  paid: "Fizetett",
  password: "Jelszó",
  passwordHelperText: "A jelszónak legalább 6 karakterből kell állnia.",
  passwordLabel: "Jelszó",
  pause: "Szünet",
  payment: "Fizetés",
  paymentDetails: "Fizetési részletek",
  paymentMethodChanged: "A fizetési mód sikeresen módosítva!",
  paymentMethodCreated: "Sikeresen létrehozott fizetési mód!",
  paymentMethods: "Fizetési módok",
  paymentType: "A fizetés típusa",
  paymentTypes: "Fizetési módok",
  payment_type: "Fizetési mód",
  payment_typeLabel: "Fizetési módok",
  payment_type_id: "A fizetés típusa",
  payment_type_idLabel: "A fizetés típusa",
  payment_types: "Fizetési módok",
  percent: "Százalék",
  percent_1: "Százalék",
  percent_2: "Százalék",
  person: "Fizikai személy",
  piece: "DB",
  phone: "Telefonszám",
  phoneAllowedChars: "Csak számok, -, +, () és szóköz engedélyezett.",
  phoneLabel: "Telefonszám",
  phone_fixLabel: "Vezetékes telefon",
  phone_mobileLabel: "Mobiltelefon",
  physical: "Fizikai",
  physical_products: "Fizikai termékek",
  plate: "Regisztrációs szám",
  plate_number: "Regisztrációs szám.",
  plate_numberLabel: "Regisztrációs szám",
  popoverTitle: "Válasszon egy státuszt",
  post_codeLabel: "Irányítószám",
  previous: "Vissza",
  price: "Ár",
  priceCalcFormula: "Árszámítási képlet",
  priceLabel: "Ár",
  price_conditions: "Árfeltételek",
  printReport: "Jelentés nyomtatása",
  printWorkOrder: "Rendelés nyomtatása",
  prinț: "Nyomtatás",
  privacy: "Adatvédelmi politika",
  privacyPolicy: "Adatvédelem",
  product: "Termék neve",
  productAdded: "Termék sikeresen hozzáadva!",
  productChanged: "A termék sikeresen módosítva.",
  productDeleted: "A termék sikeresen törölve.",
  products: "Termékek",
  productsEmpty:
    "Kérjük, adjon hozzá legalább egy terméket vagy szolgáltatást.",
  products_name: "Új termékek",
  programmingAdded: "A programáslás sikeresen hozzáadva.",
  progress: "Folyamatban",
  progress_saved: "Az adatok frissítésre kerültek.",
  promoText: "Velünk mindent kézben tarthat!",
  promotionLabel: "Promóciós kód",
  promotion_codeLabel: "Promóciós kód",
  qty: "Mennyiség",
  quantity: "Mennyiség",
  quantityLabel: "Mennyiség",
  quick_text: "Gyors keresés",
  quick_textLabel: "Gyors keresés",
  rangeLabel: "Időintervallum",
  recentlyCreated: "Nemrég hozzáadott",
  recoverAcc: "Fiók visszaállítása ",
  regLabel: "Nyilvántartási szám",
  reg_number: "Regisztrációs szám",
  reg_numberLabel: "Sorszáma a cégnyilvántartásba",
  reg_numberPlaceholder: "Pl: J12/345/2000",
  registerLabel: "Még nincs fiókja? Csatlakozzon!",
  requested_services: "Igényelt szolgáltatások",
  reset: "Újrakezdi",
  resetFilters: "A keresési szűrők visszaállítása",
  resetSearch: "A keresés visszaállítása",
  resume: "Folytasd",
  returnForms: "Visszaküldési űrlapok",
  reversible: "Ez a művelet visszafordítható! ",
  role: "Funkció",
  roleMissing: " - ",
  rotateLeft: "Forgatás balra",
  rotateRight: "Forgatás jobbra",
  rowsPerPage: "Sorok oldalanként",
  save: "Mentés",
  scanCard: "Olvassa be a kártyát",
  scanningPossible: "Hűségkártya beolvasása lehetséges.",
  scheduler: "Programálások",
  selectModule: "Modul kiválasztása",
  selectProduct: "Válasszon egy szolgáltatást",
  send: "Küld",
  serverDown:
    "Hoppá! Úgy tűnik, hogy a műhely jelenleg nem érhető el. Minden tőlünk telhetőt megteszünk, hogy a platform újra online legyen. Kérjük, próbálkozzon újra később.",
  service: "Szolgáltatás",
  serviceAdded: "A szolgáltatás sikeresen hozzáadva.",
  serviceBought: "Vásárolt szolgáltatás",
  serviceChanged: "A szolgáltatás sikeresen módosítva.",
  serviceDeleted: "A szolgáltatás sikeresen törölve.",
  serviceInterval: "Az aktív szolgáltatás időintervalluma",
  service_products: "Szolgáltatások",
  services: "Szolgáltatások",
  servicesExecuted: "Végrehajtott szolgáltatások",
  servicesRequested: "Kért szolgáltatások",
  short_name: "Rövidítés",
  short_nameLabel: "Rövidítés",
  shownResults: "Megjelenített eredmények.",
  signature: "Aláírás",
  signatureClient: "Ügyfél aláírása",
  signatureCompany: "Cég aláírása",
  skipped: "Törölve",
  socialMedia: "Közösségi hálózatok",
  stage1: "Általános adatok",
  stage2: "Kliens adatok",
  stage3: "Termékek / Szolgáltatások",
  stage4: "Összegzés",
  editStage1: "Client Data",
  editStage2: "Products / Service",
  editStage3: "Summary",

  standardPrice: "Standard ár",
  start: "Kezdeti dátum",
  startAttendance: "Belépés",
  startDate: "Belépés dátuma ",
  startDateLabel: "Kezdés dátuma",
  startTimer: "Kezdet",
  status: "Státusz",
  statusLabel: "Rendelés státusza",
  step0Message:
    "Az alkalmazás használatához kérjük, töltsd ki az alábbi űrlapot.",
  step1Message: "Kapcsolattartási adatok",
  step2Message: "Válaszd ki a felhasználónevet és a jelszót.",
  step3Message:
    "Ha van kedvezménykódod, akkor beírhatod alább vagy befejezheted a folyamatot.",
  stop: "Megállít",
  stopTimer: "Állítsd meg az időzítőt.",
  streetLabel: "Utca",
  streetPlaceholder: "A cég székhelye",
  submitAdd: "Hozzáadás",
  submitAddContact: "Kapcsolat hozzáadása",
  submitConfirmOrder: "Erősítse meg a megrendelést",
  submitEdit: "Módosít",
  submitFinalize: "Befejezés",
  submitPlaceOrder: "Adja le a rendelést",
  submitSave: "Mentés",
  submitSend: "Küldés",
  submitSearch: "Keres",
  submitSearchClient: "Ügyfél keresése",
  submitSearchContract: "Kontakt keresése",
  submitSearchWorker: "Munkás keresése",
  submitSearchCard: "Kártya keresése",
  submitSearchProduct: "Termék keresése",
  submitSearchHotel: "Hotel keresése",
  submitSearchOrder: "Rendelés keresése",
  subtotal: "Összesen",
  success: "Sikeresen módosítva",
  successMessage: "A művelet sikeresen végrehajtva",
  successMessageEntry: "A belépés sikeresen hozzáadva!",
  successMessageExit: "A kilépés sikeresen hozzáadva!",
  successMessage_54: "Adatok frissítve",
  successMessage_55: "Adatok frissítve",
  successMessage_56: "Sikeresen módosítva",
  successMessage_57: "Frissített státusz.",
  summary: "Összegzés",
  "super-admin": "Adminisztrátor",
  superAdmin: "Szuper admin",
  suspendAcc: "Fiók felfüggesztése",
  suspendConfirm: "Felfüggesztés",
  suspendConfirmation: " ",
  suspended: "Folyamatban",
  suspended_success: "A fiók sikeresen felfüggesztve.",
  suspendingAccount: " ",
  tax: "Díj",
  terms: " ",
  termsAndConditions: "Felhasználási feltételek",
  termsLabel: "Felhasználási feltételek",
  then: " akkor ",
  time: "Óra / Dátum",
  timeInterval: "Időintervallum",
  timeLeft: "-ig aktív a szolgáltatás",
  time_interval: "Időintervallum",
  time_sheetLabel: "Munkaprogram",
  time_sheetPlaceholder: "Pl",
  timesheet: "Munkaidő-kimutatás",
  timesheetReport: "Munkaidó-kimutatás jelentése - ",
  title: "Autótípusok",
  titleAddAppointment: "Programásás hozzáadása",
  titleAddCarType: "Adja meg az autó típusát",
  titleAddClient: "Ügyfél hozzáadása",
  titleAddCondition: "Feltétel hozzáadása",
  titleAddContact: "Kapcsolat hozzáadása",
  titleAddContract: "Szerződés hozzáadása",
  titleAddGroup: "Csoport hozzáadása",
  titleAddLoyaltyCard: "Hűségkártya hozzáadása",
  titleAddNewProduct: "Új termék hozzáadása",
  titleAddNewService: "Új szolgáltatás hozzáadása",
  titleAddNewWorker: "Munkás hozzáadása",
  titleAddOrganization: "Telephely hozzáadása",
  titleAddPaymentMethod: "Adja meg a fizetési módot",
  titleAddProduct: "Termék hozzáadása",
  titleAddTyreHotel: "Gumiabroncs Hotel hozzáadása",
  titleAddUser: "Adaugă Utilizator",
  titleAddWorker: "Munkás hozzáadása",
  titleApp: "Műhelyem",
  titleAppointmentGroups: "Programálások csoportja",
  titleAppointments: "Programálások",
  titleAttendanceRule: "Alkalmazottak jelenlétének kimutatása",
  titleAvailableModules: "Érvényes modulok",
  titleCarTypes: "Autó típusok",
  titleChooseDate: "Dátum kiválasztása",
  titleChoosePrintingMethod: "Válasszon egyet a nyomtatási módszerek közül",
  titleClients: "Ügyfelek",
  titleCompanyReport: "Cégjelentés",
  titleConfirmOrder: "Megrendelés visszaigazolása",
  titleContracts: "Szerződések",
  titleControlPanel: "Vezérlőpult",
  titleCreateNewWorkOrder: "Hozzon létre egy új megrendelést",
  titleCreateWorkOrders: "Munkalapok létrehozása",
  titleDashboard: "Áttekintés",
  titleDeleteOrder: "Törölje a rendelést",
  titleDetailedReport: " Részletes jelentés",
  titleDetailedTimesheet: "Részletes nyílvántartás",
  titleEditAddress: " Cím módosítása",
  titleEditAppointment: "Edit Appointment",
  titleEditCarType: "Változtassa meg az autó típusát - ",
  titleEditClient: "Ügyfél módosítása",
  titleEditCondition: "Feltétel módosítása",
  titleEditContact: "Szerződés módosítása - ",
  titleEditContract: "Szerződés módosítása",
  titleEditGeneralInfo: " Általános információk módosítása",
  titleEditLoyaltyCard: "Hűségkártya módosítása",
  titleEditOrganization: "Telephely módosítása - ",
  titleEditPaymentMethod: "Fizetési mód megváltoztatása- ",
  titleEditProduct: "Termék módosítása - ",
  titleEditService: "Szolgáltatás módosítása - ",
  titleEditTyreHotel: "A guumiabroncs hotel módosítása.",
  titleEditUser: "Felhasználó módosítása - ",
  titleEditWorkOrder: "Rendelés módosítása ",
  titleEditWorker: "Munkás módosítása - ",
  titleLogin: "Bejelentkezés",
  titleLoyaltyCards: "Hűségkártyák",
  titleModifyGroup: "Csoport módosítása ",
  titleOrders: "Rendelések",
  titleOrganizations: "Telephelyek",
  titlePaymentMethods: "Fizetési módok",
  titlePriceConditionsFor: "Árfeltételek a ",
  titleProducts: "Termékek",
  titleRegister: "Regisztráció",
  titleReportDaily: "Napi jelentés",
  titleReportMonthly: "Havi jelentés",
  titleReportTyreWaste: "Hulladékjelentés",
  titleReportWorkers: "Alkalmazottak  jelentés ",
  titleSelectModule: "Válassza ki az érvényes modulok egyikét",
  titleTimesheet: "Munkaidő-kimutatás ",
  titleTyreHotels: "Gumiabroncs Hotel",
  titleTyreHotelsDeposit: "Tárolás / Gumiabroncs Hotel",
  titleUpdateAccount: "Aktualizálás",
  titleUpdateActiveService: "Aktív szolgáltatás frissítése",
  titleUsers: "Felhasználók",
  titleWorkOrder: "Rendelés ",
  titleWorkOrders: "Munkarendelések",
  titleWorkers: "Munkások",
  to: "",
  toLabel: "ig",
  today: "Ma",
  total: "Fizetett összeg",
  totalHours: "Az összes ledolgozott órák",
  totalHoursWorked: "Összes ledolgozott órák",
  totalLabel: "Összesen",
  totalTitle: "Teljes jelentés",
  totalization: "Összesítés",
  totalizationTitle: "Havi összesítő jelentés",
  totalized: "Havi összesítő cégjelentés",
  true: "Igen",
  type: "Fióktípus",
  typeLabel: "Fiók típus",
  tyreDim: "Gumiabroncs méret",
  tyreDimensions: "A gumiabroncs méretei",
  tyreHotel: "Hotel / Abroncs tárolása",
  tyreHotelDeleted: "A hotel sikeresen törölve!",
  tyreHotelDescription: "Leírás",
  tyreHotelsAdded: "A hotel sikeresen hozzáadva!",
  tyrePressure: "Gumiabroncsnyomás",
  tyreService: "Gumiabroncs szerviz",
  tyre_dim: "Gumiabroncs méret",
  tyre_dimension: "Gumiabroncs méret",
  tyre_dimensions: "Gumiabroncs méret",
  tyre_height: "abroncsmagasság",
  tyre_heightLabel: "Abroncs magassága",
  tyre_name: "Név",
  tyre_nameLabel: "Abroncs neve",
  tyre_pressure: "abroncsnyomás",
  tyre_pressureLabel: "Abroncsnyomás",
  tyre_quantity: "Mennyiség",
  tyre_quantityLabel: "Mennyiség",
  tyre_rim: "abroncsátmérő",
  tyre_rimLabel: "Abroncsátmérő",
  tyre_waste: "gumiabroncs hulladék",
  tyre_wasteLabel: " Abroncs hulladék",
  tyre_width: "abroncsszélesség",
  tyre_widthLabel: "Abroncs szélessége",
  tyres: "Gumiabroncsok",
  um: "UM",
  umLabel: "Mértékegység",
  undo: "A keresési beállítások visszaállítása",
  undoRotation: "Forgatás visszavonása",
  undoZoom: "Kicsinyítés/nagyítás visszavonása",
  upTrend: "Az új ár magasabb, mint a standard ár",
  updateStatus: "Státusz módosítása",
  updatedAt: "Dátum",
  upgrade: "Frissítés",
  user_idLabel: "Társítva a fiókkal",
  username: "Név",
  usernameLabel: "Felhasználó",
  users: "Felhasználók",
  uuid: "Rendelésszám",
  value: "Érték (RON)",
  valueLabel: "Érték",
  value_1: "Érték (RON)",
  value_2: "Érték",
  vat_number: "Adószám",
  vat_numberLabel: "Adószám",
  vat_numberPlaceholder: "Pl: CIF: RO123456",
  view: "Megtekintés",
  viewAdmin: "Adminisztrátor megtekintése",
  viewContact: "Kontakt megtekintése",
  viewMode: "Nézet mód",
  viewNormal: "Műhely megtekintése",
  viewPriceConditions: "Nyisd ki",
  viewWorkOrder: "Rendelés megtekintése",
  views: "Időintervallum",
  vin_numberLabel: "Alvázszám",
  waitingWorkOrders: "Folyamatban",
  warehouse: "Címkék - Raktár",
  waste: "Hulladékjelentés ",
  wasteReports: "Hulladékjelentés",
  webpageLabel: "Weboldal",
  week: "Hét",
  welcomeBack: "Üdvözöljük!",
  wheelTypes: "Autó típusok",
  wheel_type: "Kerék típusa",
  wheel_typeLabel: "Kerék típusa",
  wheel_types: "Gumiabroncsok",
  wheels: "Abroncsok",
  withGroupingLabel: "Csoport nézet",
  "work-orders-daily": "Munkarendelések - ma",
  "work-orders-monthly": "Munkarendelések - havonta",
  "work-orders-weekly": "Munkarendelések - hetente",
  workInterval: "Munkaintervallum",
  workOrder: "Munkalap",
  workOrderCreated: "A megrendelés létrejött",
  workOrderDeleted: "A rendelés sikeresen törölve!",
  workOrderLabel: "Rendelés",
  workOrderSettings: "Munkalap beállítások",
  workOrderType: "Munkarendelés típusa",
  workOrders: "Munkalapok",
  workPlace: "Munkapont",
  work_order_id: "Munkalap száma",
  work_order_type: "Szolgáltatás",
  work_order_typeLabel: "Rendelés típusa",
  work_order_type_id: "Rendelés típusa",
  work_order_types: "Munkalapok",
  work_order_uuid: "Munkalap száma",
  work_order_uuidLabel: "Munkalap",
  work_orders_count: "Mennyiségileg összesített munkalapok",
  work_week: "Hét",
  worked_hours: "Összes óra",
  worker: "Munkás",
  workerChanged: "Munkás sikeresen módosítva!",
  workerCreated: "Sikeresen létrehozott munkás!",
  workerDeleted: "A munkás sikeresen törölve!",
  workerLabel: "Felhasználó",
  workers: "Munkások",
  workersLabel: "Keresés név szerint",
  workersReports: "Alkalmazottak  jelentés",
  workersSearchLabel: "Munkás keresése",
  workstationSettings: "Munkaállomás beállításai",
  zoomIn: "Nagyít",
  zoomOut: "Kicsinyít",
  addTyreHotel: "Gumiabroncs Hotel hozzáadása",
  deleteTyreHotelForm: "Abroncs hotel törlés",
  carServiceDisclaimerTitle: "Certificate of quality and guarantee",
  carServiceDisclaimerText1:
    "The terms of execution of the services ordered is  ……………………… . These terms are subject to change in teh following scenarios: a) For the execution of work at the moment of disassembly in case new defects are discovered, which require orders / parts and/or additional work. b) The parts ordered are not delivered according to the established terms. c) In case of force majeure or fortuitous events.",
  carServiceDisclaimerText2:
    "The unit can perform additional work and replacement parts up to the maximum amount of ……………… .. RON, these being accepted by the presence.",
  carServiceDisclaimerText3:
    "The customer undertakes to ensure the correct operation of the repaired vehicle in accordance with the requirements imposed by the manufacturer. The parts and materials brought by the customer do not benefit from the warranty.",
  carServiceDisclaimerText4:
    "The Beneficiary / Customer declares that I agree that the provider has the right of retention on the repaired vehicle until the full payment of the value of their service / repair invoice (s) accepted by me / our company.",
  guaranteeConditions: "WARRANTY CONDITIONS",
  ourUnitGrantsGuarantee: "Our unit provides warranty",
  forProducts: "for products",
  toIndividuals24Months:
    "for individuals: 24 months according to Law no. 449/2003",
  toCompanies12Months: "for legal entities: 12 months",
  toCompaniesExceptions6Months:
    "for legal entities (driving schools, taxi): 6 months",
  workGuarantee3Months:
    "for labor the warranty is 3 months from the date of receipt",
  manufacturing_yearLabel: "Manufacturing Year",
  accounts: "Fiókok",
  titleAccounts: "Fiókok",
  GroupByDay: "Csoportositás",
  PlateNumberAlreadyInUse: "A rendszám már létezik",
  accountNameLabel: "Fiók név",
  accountUsers: "Felhasználók sz.",
  inactive: "Inaktiv",
  activeFromLabel: "Aktiv től",
  activeToLabel: "Aktiv ig",
  titleEditAccount: "Fiók szerkesztése",
  accountOrganizations: "Munkapontok sz",
  workOrderTypes: "Munkalap tipus",
  accountUUID: "Fiók uuid",
  openCalendar: "Naptár kinyit",
  closeCalendar: "Naptár bezár",
};

export { en_hu };

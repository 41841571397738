import React, { forwardRef } from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import ModalTitle, {
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
} from "../ModalTitle";
import ModalContent, {
  ModalContentDefaultProps,
  ModalContentPropTypes,
} from "../ModalContent";
import ModalActions, {
  ModalActionsDefaultProps,
  ModalActionsPropTypes,
} from "../ModalActions";

/**
 *  Material UI Imports
 */
import { useTheme, useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";

/**
 * Defines the prop types
 */
const propTypes = {
  open: PropTypes.bool,
  scroll: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  keepMounted: PropTypes.bool,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  ariaLabel: PropTypes.string,
  animation: PropTypes.oneOf(["fade", "grow", "slide", "zoom"]),
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      enter: PropTypes.number,
      appear: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
  direction: PropTypes.string,
  children: PropTypes.any,
  hideBackdrop: PropTypes.bool,
  className: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  open: false,
  scroll: "paper",
  maxWidth: "md",
  fullWidth: true,
  keepMounted: true,
  fullScreen: false,
  onClose: () => {},
  ariaLabel: "",
  animation: "fade",
  timeout: {
    enter: 400,
    appear: 400,
    exit: 400,
  },
  direction: "up",
  children: "",
  hideBackdrop: false,
  className: "",
};

/**
 * Defines the available transitions
 */
const ZoomTransition = forwardRef(function Transition(props, ref) {
  return <Zoom in={true} ref={ref} {...props} />;
});
const FadeTransition = forwardRef(function Transition(props, ref) {
  return <Fade in={true} ref={ref} {...props} />;
});
const GrowTransition = forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});
const SlideTransition = forwardRef(function Transition(props, ref) {
  return <Slide in={true} ref={ref} {...props} />;
});

/**
 * Displays the component
 */
const Modal = (props) => {
  const {
    open,
    scroll,
    maxWidth,
    fullWidth,
    keepMounted,
    ariaLabel,
    onClose,
    animation,
    timeout,
    direction,
    fullScreen,
    children,
    classes,
    hideBackdrop,
    className,
  } = props;

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  /**
   * Gets the animation
   */
  const getAnimation = () => {
    switch (animation) {
      case "zoom":
        return ZoomTransition;
      case "fade":
        return FadeTransition;
      case "grow":
        return GrowTransition;
      case "slide":
        return SlideTransition;
      default:
        return ZoomTransition;
    }
  };

  return (
    <Dialog
      scroll={scroll}
      maxWidth={maxWidth}
      classes={classes}
      className={className}
      fullScreen={fullScreen ? fullScreen : triggerFullScreen}
      fullWidth={fullWidth}
      open={open}
      keepMounted={keepMounted}
      TransitionComponent={getAnimation()}
      TransitionProps={{ timeout, direction }}
      onClose={onClose}
      aria-labelledby={ariaLabel}
      hideBackdrop={hideBackdrop}
      disableBackdropClick={true}
    >
      {children}
    </Dialog>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
export {
  ModalTitle,
  ModalTitlePropTypes,
  ModalTitleDefaultProps,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
  propTypes as ModalPropTypes,
  defaultProps as ModalDefaultProps,
};

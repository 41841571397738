import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { en_hu } from "./EditTyreHotels.lang.en-hu";
import { en_en } from "./EditTyreHotels.lang.en-en";
import { en_ro } from "./EditTyreHotels.lang.en-ro";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import EditTyreHotelForm, {
  EditTyreHotelFormPropTypes,
  EditTyreHotelFormDefaultProps
} from "../EditTyreHotelForm";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import Badge from '@material-ui/core/Badge';
import SaveIcon from "@material-ui/icons/Save";

/**
 * Styles Imports
 */
import { useStyles } from "./EditTyreHotels.styles";


/**
 * Defines the prop types
 */
const propTypes = {
  tyre_hotels: PropTypes.array,
  workOrder: PropTypes.object,
  button: PropTypes.shape(ButtonPropTypes),
  editForm: PropTypes.shape(EditTyreHotelFormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  tyre_hotels: [],
  workOrder: {},
  button: ButtonDefaultProps,
  editForm: EditTyreHotelFormDefaultProps,
  loadingText: LoadingTextDefaultProps,
};

/**
 * Displays the component
 */
const EditTyreHotels = props => {
  const { tyre_hotels, workOrder, button, editForm, loadingText } = props;


  /**
   * Handles the translations
   */
  i18n.addResourceBundle("en_ro", "EditTyreHotels", en_ro);
  i18n.addResourceBundle("en_en", "EditTyreHotels", en_en);
  i18n.addResourceBundle("en_hu", "EditTyreHotels", en_hu);
  const { t } = useTranslation("EditTyreHotels");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Init the trigger flag
   */
  const [triggerApiCalls, setTriggerApiCalls] = useState(false)

  /**
   * Init the loading state
   */
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setTriggerApiCalls(true);
  }


  const onStart = () => {
    setLoading(true);
  }

  const onFinish = () => {
    setTriggerApiCalls(false);
    setLoading(false);
  }

  const onReset = () => {
    setTriggerApiCalls(false);
  }

  /**
   * Handles rendering the forms
   */
  const renderForms = () => {
    if (tyre_hotels.length < 1) return null;

    return tyre_hotels.map(hotel => <EditTyreHotelForm {...editForm} key={hotel.id}
      data={hotel} workOrder={workOrder} onFinish={onFinish} onStart={onStart} onReset={onReset} triggerApiCalls={triggerApiCalls}
    />)
  }

  /**
   * Handles rendering the title
   */
  const renderTitle = () => {
    if (tyre_hotels.length > 1) {
      return <Badge badgeContent={tyre_hotels.length} classes={{
        anchorOriginTopRightRectangle: classes.badge
      }}>
        {t("tyreHotel")}
      </Badge>
    }

    return t("tyreHotel")
  }




  return <React.Fragment>
    <Typography
      variant="caption"
      className={classes.sectionTitle}
    >
      {renderTitle()}
      <Button
        {...button}
        type="button"
        variant="filled"
        onClick={handleClick}
        className={classes.saveBtn}
      >
        <LoadingText
          {...loadingText}
          loading={loading}
          text={t("save")}
          icon={<SaveIcon />}
        />
      </Button>
    </Typography>
    <div className={clsx(classes.container, {
      [classes.onlyOne]: tyre_hotels.length === 1
    })}>
      {renderForms()}
    </div>
  </React.Fragment>


};

EditTyreHotels.propTypes = propTypes;
EditTyreHotels.defaultProps = defaultProps;

export default EditTyreHotels;
export {
  propTypes as EditTyreHotelsPropTypes,
  defaultProps as EditTyreHotelsDefaultProps
};

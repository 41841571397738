/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Imports Hooks
 */
import { useAuth, useMessage } from "../index";
import PropTypes from "prop-types";

/**
 * Imports the client
 */
import { getApiClient } from "./getApiClient";

/**
 * Defines the hook prop types
 */
const propTypes = {
  withCredentials: PropTypes.bool
};

/**
 * Defines the default props
 */
const defaultProps = {
  withCredentials: false
};

const useApiClient = (props = {}) => {
  const { withCredentials } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the auth hook data
   */
  const { token, logout } = useAuth();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  const { apiClient } = getApiClient({
    withCredentials,
    logout,
    dispatchMessage,
    token,
    t
  });

  return { apiClient };
};

useApiClient.prototypes = propTypes;
useApiClient.defaultProps = defaultProps;

export { useApiClient };

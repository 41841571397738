import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports Components
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Imports Styles
 */
import { useStyles } from "./ReportsCompaniesTableTotalization.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  data: PropTypes.any,
  isPrinting: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  data: [],
  isPrinting: false,
};

/**
 * Displays the component
 */
const ReportsCompaniesTableTotalization = (props) => {
  const { data, isPrinting } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the collection
   */
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    const formattedData = Object.entries(data).map((entry) => ({
      id: entry[0],
      ...entry[1],
    }));

    const list = isPrinting
      ? [
          {
            name: t("companyName"),
            total: t("total"),
            discount: t("discount"),
            subtotal: t("subtotal"),
            payment_type_name: t("payment"),
            is_header: true,
          },
          ...formattedData,
        ]
      : formattedData;

    setCollection(list);
    // eslint-disable-next-line
  }, [isPrinting, data]);

  return (
    <TableContainer>
      <Table stickyHeader className={clsx(classes.table, "page-break")}>
        <TableHead
          className={clsx({
            [classes.hiddenHeader]: isPrinting,
          })}
        >
          <TableRow>
            <TableCell> #</TableCell>
            <TableCell align="right"> {t("companyName")} </TableCell>
            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("subtotal")}
            </TableCell>
            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("discount")}
            </TableCell>

            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("total")}
            </TableCell>
            <TableCell align="right">{t("payment")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection.map((item, index) => {
            const {
              name,
              total,
              discount,
              subtotal,
              payment_type_name,
              is_header,
            } = item;

            const headerClasses =
              "MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader";

            const cellClass = clsx({
              [headerClasses]: is_header,
            });

            const alignRight = { textAlign: "right" };

            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row" className={cellClass}>
                  {isPrinting ? (index === 0 ? "#" : index) : index + 1}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {name}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {isPrinting ? subtotal : `${subtotal} RON`}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {isPrinting ? discount : `${discount} RON`}
                </TableCell>

                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {isPrinting ? total : `${total} RON`}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {payment_type_name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportsCompaniesTableTotalization.propTypes = propTypes;
ReportsCompaniesTableTotalization.defaultProps = defaultProps;

export default ReportsCompaniesTableTotalization;
export {
  propTypes as ReportsCompaniesTableTotalizationPropTypes,
  defaultProps as ReportsCompaniesTableTotalizationDefaultProps,
};

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import shortid from "shortid";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";
/**
 * Material UI Imports
 */
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import TextField from "@material-ui/core/TextField";

/**
 * Styles Imports
 */
import { useStyles } from "./InputPassword.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.any,
  required: PropTypes.bool,
  preventPaste: PropTypes.bool,
  maxSize: PropTypes.number,
  showHelper: PropTypes.bool,
  handlePasteDefault: PropTypes.func,
  InputLabelProps: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  autoFocus: false,
  label: "",
  value: "",
  onChange: () => {},
  id: "passwordInput",
  name: "passwordInput",
  error: "",
  required: false,
  preventPaste: false,
  maxSize: 100,
  showHelper: true,
  handlePasteDefault: () => {},
  InputLabelProps: {},
};

/**
 * Displays the component
 */

const InputPassword = (props) => {
  const {
    autoFocus,
    label,
    value,
    onChange,
    id,
    name,
    error,
    required,
    preventPaste,
    maxSize,
    variant,
    className,
    showHelper,
    handlePasteDefault,
    InputLabelProps,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Initializes the state that hides/shows the password
   */
  const [showPassword, setShowPassword] = useState(false);

  const [readOnly, setReadOnly] = useState(true);

  const handleFocus = () => {
    setReadOnly(false);
  };

  const onBlur = () => {
    setReadOnly(true);
  };

  /**
   * Renders the visibility ON icon
   */
  const VisibilityOnIcon = () => {
    /**
     * Defines the icon classes
     */
    const iconClasses = clsx(classes.icon, {
      [classes.errorColor]: error ? true : false,
    });

    return <VisibilityOutlinedIcon className={iconClasses} />;
  };

  /**
   * Renders the visibility OFF icon
   */
  const VisibilityOffIcon = () => {
    /**
     * Defines the icon classes
     */
    const iconClasses = clsx(classes.icon, {
      [classes.errorColor]: error ? true : false,
    });

    return <VisibilityOffOutlinedIcon className={iconClasses} />;
  };

  /**
   * Shows / Hides the password field
   */
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  /**
   * Builds the icon that displays if the password is hidden or shown
   */
  const endAdornmentShowPassword = (
    <InputAdornment position="end" className={classes.adornment}>
      <IconButton
        button="true"
        className={classes.passwordIconBtn}
        onClick={toggleShowPassword}
      >
        {showPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment>
  );

  /**
   * Defines the form control classes
   */
  const formControlClasses = clsx(classes.fieldDefault, {
    [className]: true,
  });

  /**
   * Configures the Input Component props
   */
  const InputProps = {
    endAdornment: endAdornmentShowPassword,
  };

  /**
   * Configures the base input props
   */
  const inputProps = { autoFocus: autoFocus, maxLength: maxSize };

  /**
   * Renders the hidden text if there's no erros or helper messages
   */
  const hiddenText = () => <span className={classes.hidden}>error_msg</span>;

  /**
   * Gets the helper text/or error
   */
  const getHelperText = () => {
    if (error) return error;
    if (!showHelper) return null;

    return error ? error : showHelper ? t("passwordHelperText") : hiddenText();
  };
  const helperText = getHelperText();

  /**
   * Prevents pasting into the input field
   */
  const handlePaste = (e) => {
    e.preventDefault();
    return false;
  };

  /**
   * Applies focus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) inputRef.current.focus();
  }, [autoFocus, inputRef]);

  const textfieldProps = {
    id: `${id}-${shortid.generate()}`,
    error: !!error,
    label,
    name,
    autoComplete: "new-password",
    inputRef,
    value,
    onChange,
    readOnly: readOnly,
    type: showPassword ? "text" : "password",
    variant,
    required,
    autoFocus,
    onPaste: preventPaste ? handlePaste : handlePasteDefault,
    InputLabelProps,
    InputProps,
    inputProps,
  };

  if (helperText) textfieldProps["helperText"] = helperText;

  return (
    <FormControl className={formControlClasses}>
      <TextField {...textfieldProps} onFocus={handleFocus} onBlur={onBlur} />
    </FormControl>
  );
};

InputPassword.propTypes = propTypes;
InputPassword.defaultProps = defaultProps;

export default InputPassword;
export {
  propTypes as InputPasswordPropTypes,
  defaultProps as InputPasswordDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import ClearIcon from "@material-ui/icons/Clear";

/**
 * Styles Imports
 */
import { useStyles } from "./LoyaltyCard.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.any,
  is_active: PropTypes.bool,
  organization_client_id: PropTypes.any,
  card_number: PropTypes.string,
  discount_type: PropTypes.string,
  discount_value: PropTypes.number,
  percentageLabel: PropTypes.string,
  currency: PropTypes.string,
  clients: PropTypes.array,
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: 0,
  is_active: false,
  organization_client_id: 0,
  card_number: "",
  discount_type: "",
  discount_value: 0,
  percentageLabel: "%",
  currency: "RON",
  clients: [],
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
};

/**
 * Displays the component
 */
const LoyaltyCard = (props) => {
  const {
    is_active,
    organization_client_id,
    card_number,
    discount_type,
    discount_value,
    percentageLabel,
    currency,
    editButton,
    clients,
    deleteButton,
    handleDelete,
    handleEdit,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Handles opening the delete product modal
   */
  const openDelete = () => handleDelete(props);

  /**
   * Handles opening the edit product modal
   */
  const openEdit = () => handleEdit(props);

  /**
   * Handles getting the organization name
   */
  const getClientName = (id) => {
    if (clients && clients.length > 0) {
      const found = clients.find((client) => client.id === id);
      return found ? found.name : "";
    }
  };

  /**
   * Handles getting the card icon and the dynamic classes
   */
  const getCardStatusIcon = () => {
    const iconClasses = clsx(classes.iconContainer, {
      [classes.activatedCard]: is_active,
    });

    const cardIcon = is_active ? <DoneOutlineIcon /> : <ClearIcon />;
    return { classes: iconClasses, icon: cardIcon };
  };

  /**
   * Defines the card icon and icon classes
   */
  const iconClasses = getCardStatusIcon().classes;
  const icon = getCardStatusIcon().icon;

  /**
   * Gets the card status text
   */
  const getCardStatusText = () => t(is_active ? "is_active" : "is_inactive");

  /**
   * Gets the discount type label
   */
  const getDiscountTypeLabel = () =>
    discount_type === "PERCENT" ? (
      <span className={classes.percent}> {percentageLabel} </span>
    ) : (
      <span className={classes.currency}> {currency} </span>
    );

  return (
    <div className={classes.cardContainer}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.wrapper}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>
                <div className={classes.name}>{t("loyaltyCard")}</div>
                <div className={classes.activated}>
                  <div className={iconClasses}>{icon}</div>
                  <Typography
                    variant="caption"
                    className={classes.activatedStatus}
                  >
                    {getCardStatusText()}
                  </Typography>
                </div>
              </div>
              <div className={classes.cardBody}>
                <div className={classes.discount}>
                  {t("discountLabel")}
                  <span className={classes.value}>{discount_value}</span>

                  {getDiscountTypeLabel()}
                </div>
                <div className={classes.cardNumber}>{card_number}</div>
                <div className={classes.cardCompany}>
                  {getClientName(organization_client_id)}
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <EditButton
                {...editButton}
                className={classes.button}
                title={t("editCard")}
                onClick={openEdit}
              />
              <DeleteButton
                {...deleteButton}
                className={classes.deleteButton}
                title={t("deleteCard")}
                onClick={openDelete}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

LoyaltyCard.propTypes = propTypes;
LoyaltyCard.defaultProps = defaultProps;

export default LoyaltyCard;
export {
  propTypes as LoyaltyCardPropTypes,
  defaultProps as LoyaltyCardDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * Imports Material UI Components
 */
import Grid from "@material-ui/core/Grid";

/**
 * Imports components
 */
import WorkOrderTimerCard from "../WorkOrderTimerCard";

/**
 * Imports hooks
 */
import { useUser, useAuth } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  timerCards: PropTypes.array,
  isOpenModal: PropTypes.bool,
  draftData: PropTypes.object,
  handleEdit: PropTypes.func,
  cardInEdit: PropTypes.object,
  setCardInEdit: PropTypes.func,
  setDeleteData: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  timerCards: [],
  isOpenModal: false,
  draftData: {},
  handleEdit: () => {},
  cardInEdit: {},
  setCardInEdit: () => {},
  setDeleteData: () => {},
};

/**
 * Displays the component
 */
const WorkOrderTimerCards = (props) => {
  const {
    timerCards,
    isOpenModal,
    draftData,
    handleEdit,
    cardInEdit,
    setCardInEdit,
    setDeleteData,
  } = props;

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Gets the global auth state
   */
  const { auth } = useAuth();

  /**
   * Handles edgecase
   */
  if (!user.settings) return null;
 
  return (
    timerCards &&
    timerCards.map((card) => {
      /**
       * Handles edge case where the cards crash when logging out
       */
      if (!auth.user) return null;

      /**
       * Checks if the same user who created the card is also the one that's logged in
       * Only renders the card if they are the same.
       */
      if (card.user !== auth.user.uuid) return null;

      return (
        <Grid key={card.plateNumber} item sm={6} lg={4} xl={2}>
          <WorkOrderTimerCard
            {...card}
            user={user}
            isOpenModal={isOpenModal}
            draftData={draftData}
            handleEdit={handleEdit}
            cardInEdit={cardInEdit}
            setCardInEdit={setCardInEdit}
            setDeleteData={setDeleteData}
          />
        </Grid>
      );
    })
  );
};

WorkOrderTimerCards.propTypes = propTypes;
WorkOrderTimerCards.defaultProps = defaultProps;

export default WorkOrderTimerCards;
export {
  propTypes as WorkOrderTimerCardsPropTypes,
  defaultProps as WorkOrderTimerCardsDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  pdfContainer: {
    maxHeight: "none"
  },
  navbarWrapper: {
    backgroundColor: "#0a0a0a",
    padding: "0.5rem",
    color: "#fff",
    width: "100%"
  },
  canvasCss: {
    maxHeight: "100%",
    overflow: "scroll",
    overflowY: "hidden"
  },
  blank: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  content: {
    padding: 0
  }
}));

export { useStyles };

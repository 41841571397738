import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import { format as formatDate } from "date-fns";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import ReportsCompaniesSearch, {
  ReportsCompaniesSearchPropTypes,
  ReportsCompaniesSearchDefaultProps,
} from "../ReportsCompaniesSearch";
import ReportsCompaniesTable, {
  ReportsCompaniesTableDefaultProps,
  ReportsCompaniesTablePropTypes,
} from "../ReportsCompaniesTable";
import ReportsCompaniesTableTotalization, {
  ReportsCompaniesTableTotalizationDefaultProps,
  ReportsCompaniesTableTotalizationPropTypes,
} from "../ReportsCompaniesTableTotalization";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";

/**
 * Hooks
 */
import { useUser, useLocalStorage } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ReportsCompanies.styles";
import ReportsCompaniesObservationsTable from "../ReportsCompaniesObservationsTable/ReportsCompaniesObservationsTable";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  search: PropTypes.shape(ReportsCompaniesSearchPropTypes),
  input: PropTypes.shape(InputPropTypes),
  table: PropTypes.shape(ReportsCompaniesTablePropTypes),
  tableTotal: PropTypes.shape(ReportsCompaniesTableTotalizationPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
  dateFormat: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  search: ReportsCompaniesSearchDefaultProps,
  input: InputDefaultProps,
  table: ReportsCompaniesTableDefaultProps,
  tableTotal: ReportsCompaniesTableTotalizationDefaultProps,
  button: ButtonDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: "/companies",
  dateFormat: "yyyy-MM-dd HH:mm:ss",
};

/**
 * Displays the component
 */
const ReportsCompanies = (props) => {
  const {
    title,
    wrapper,
    input,
    search,
    container,
    dateFormat,
    table,
    tableTotal,
    button,
    loadingBackdrop,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the totalization flag
   */
  const [totalization, setTotalization] = useLocalStorage(
    "totalization",
    false
  );

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the print loading state
   */
  const [printLoading, setPrintLoading] = useState(false);

  /**
   * Initializes the data
   */
  const [data, setData] = useState({
    items: [],
    items_observations: [],
  });

  /**
   * Initializes the init api call flag
   */
  const [apiCallMade, setApiCallMade] = useState(false);

  const [notFound, setNotFound] = useState(false);

  const { user } = useUser();

  /**
   * Initializes the clients list
   */
  const [clientsList, setClientsList] = useState([]);

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  /**
   * Initializes the component ref, used for printing the component
   */
  const componentRef = useRef();

  /**
   * Initializes the time interval
   */
  const [timeInterval, setTimeInterval] = useState({
    start: new Date(),
    end: new Date(),
  });

  /**
   * Handles toggling the totalization
   */
  const toggleTotalization = () => {
    setLoading(true);
    setTotalization((prevState) => !prevState);
  };

  /**
   * Defines the handle print function
   */
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**
   * Handles formatting the number
   * @param {String} value
   */
  const formatNumber = (value) => `${value.toFixed(2)} RON`;

  /**
   * Handles getting the start date
   */
  const getStartDate = (startDate) => {
    const date = new Date(startDate).setHours(0, 0, 0);

    const newDate = new Date(date);

    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    const d = newDate.getDate();

    const start = new Date(y, m, d).setHours(0, 0, 0);

    const a = formatDate(new Date(start), dateFormat);
    return formatDate(new Date(a), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles getting the end date
   */
  const getEndDate = (endDate) => {
    const date = new Date(endDate).setHours(0, 0, 0);
    const newDate = new Date(date);
    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    const d = newDate.getDate();
    const end = new Date(y, m, d).setHours(23, 59, 59);

    const b = formatDate(new Date(end), dateFormat);
    return formatDate(new Date(b), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles initiating the printing
   */
  const printComponent = () => {
    setPrintLoading(true);
  };

  /**
   * Handles getting the print button content
   */
  const getPrintButtonContent = () =>
    printLoading ? (
      <CircularProgress size="1.45rem" className={classes.loader} />
    ) : (
      <PrintIcon className={classes.icon} />
    );

  const getUserOrganizationByID = (id) => {
    if (user.organizations) {
      const found = user.organizations.find((org) => org.id === id);
      const name = found ? found.name : false;
      const companyBase = user.account ? user.account.name : "";
      if (!name) return "";
      return name.replace(`${companyBase} - `, "");
    }
  };

  const getReportTitle = (totalization) => {
    if (totalization && activeOrg === "all") return t("totalized");
    if (activeOrg === "all") return t("totalTitle");
    const activeOrgName = getUserOrganizationByID(activeOrg);
    return `${
      totalization ? t("totalizationTitle") : t("titleCompanyReport")
    } - ${activeOrgName}`;
  };

  /**
   * Defines the Print Button component
   */
  const PrintButton = () => {
    return (
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.printButton}
        title={t("printReport")}
        onClick={printComponent}
      >
        {getPrintButtonContent()}
      </Button>
    );
  };

  /**
   * Defines the Flex Center Component
   */
  const FlexCenter = ({ children }) => (
    <div className={classes.flexCenter}>{children}</div>
  );

  /**
   * Defines the Flex End Component
   */
  const FlexEnd = ({ children }) => (
    <div className={classes.flexEnd}>{children}</div>
  );

  /**
   * Defines the Flex Column Component
   */
  const FlexColumn = ({ children }) => (
    <div className={classes.flexColumn}>{children}</div>
  );

  /**
   * Defines the Container Component
   */
  const Container = ({ children }) => (
    <div className={classes.container}>{children}</div>
  );

  /**
   * Defines the Label Component
   */
  const Label = (props) => {
    const { value } = props;
    return <span className={classes.label}>{value}</span>;
  };

  /**
   * Defines the Value Component
   */
  const Value = (props) => {
    const { value } = props;

    return <span className={classes.value}>{value}</span>;
  };

  /**
   * Defines the Report Data Component
   */
  const ReportData = () => {
    if (data.report_data) {
      if (Array.isArray(data.report_data) && data.report_data.length < 1)
        return null;
      if (
        !Array.isArray(data.report_data) &&
        Object.keys(data.report_data).length < 1
      )
        return null;
      const { report_data } = data;
      const { total, discount, subtotal, new_tyre_quantity } = report_data;

      return (
        <Container>
          <FlexCenter>
            <Label value={t("new_tyres")} />
            <Value value={new_tyre_quantity} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("subtotal")} />
            <Value value={formatNumber(subtotal)} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("discount")} />
            <Value value={formatNumber(discount)} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("total")} />
            <Value value={formatNumber(total)} />
          </FlexCenter>
        </Container>
      );
    }
    return null;
  };

  /**
   * Defines the Report Title Component
   */
  const ReportTitle = () => {
    return (
      <FlexColumn>
        <Typography
          variant="caption"
          className={clsx(classes.title, {
            [classes.titleMargins]: totalization,
          })}
        >
          {getReportTitle(totalization)}
        </Typography>
        {Object.keys(data).length > 0 && (
          <FlexCenter>
            <Label value={t("from")} />
            <Value value={getStartDate(timeInterval.start)} />
            <Label value="/" />
            <Label value={t("to")} />
            <Value value={getEndDate(timeInterval.end)} />
          </FlexCenter>
        )}
      </FlexColumn>
    );
  };

  /**
   * Defines the Report Payment Types Data Component
   */
  const ReportPaymentTypesData = () => {
    if (data.report_data) {
      if (Array.isArray(data.report_data) && data.report_data.length < 1)
        return null;
      if (
        !Array.isArray(data.report_data) &&
        Object.keys(data.report_data).length < 1
      )
        return null;
      const paymentTypes = Object.entries(data.report_data.payment_type_sum);

      return (
        <Container>
          {paymentTypes.map((entry) => {
            const label = entry[0];
            const values = entry[1];

            const { total } = values;

            return (
              <FlexEnd>
                <Label value={`${label}:`} />
                <Value value={formatNumber(total)} />
              </FlexEnd>
            );
          })}
        </Container>
      );
    }
    return null;
  };

  const updateInputs = (inputs) => {
    if (typeof inputs.finished === "string") {
      const interval = inputs.finished.split(" - ");
      setTimeInterval({
        start: new Date(interval[0]),
        end: new Date(interval[1]),
      });
    } else {
      setTimeInterval({
        start: new Date(inputs.finished[0]),
        end: new Date(inputs.finished[1]),
      });
    }
  };

  const DataNotFound = () => {
    return notFound ? (
      <Grid container>
        <Typography variant="h2" className={classes.notFoundText}>
          {t("notFound")}
        </Typography>
      </Grid>
    ) : null;
  };

  useEffect(() => {
    if (
      ((Array.isArray(data.items) && data.items.length < 1) ||
        (!Array.isArray(data.items) && Object.keys(data.items).length < 1)) &&
      apiCallMade
    ) {
      setNotFound(true);
    } else if (
      ((Array.isArray(data.items) && data.items.length > 0) ||
        (!Array.isArray(data.items) && Object.keys(data.items).length > 0)) &&
      apiCallMade
    ) {
      setNotFound(false);
    }
  }, [data, apiCallMade]);

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setPrintLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Resets the loading state
   */
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoading(false);
    }
  }, [data]);

  /**
   * Handles triggering the printing
   */
  useEffect(() => {
    if (printLoading) {
      setTimeout(() => {
        setReadyToPrint(true);
      }, 1000);
    }
  }, [printLoading]);

  /**
   * Gets the clients upon mount
   */
  useEffect(() => {
    if (user.clients) {
      setClientsList(user.clients);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<TimelineOutlinedIcon />}
        title={t("titleCompanyReport")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container>
          <Zoom in={true} timeout={300}>
            <Grid container item xs={12} md={12} className={classes.container}>
              <Grid item xs={12}>
                <Input
                  {...input}
                  type="checkbox"
                  className={classes.checkbox}
                  inputCheckbox={{
                    name: "totalization",
                    value: totalization,
                    onChange: toggleTotalization,
                    variant: "standard",
                    label: t("totalization"),
                  }}
                />
              </Grid>
            </Grid>
          </Zoom>
          <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
              {clientsList.length > 0 && (
                <ReportsCompaniesSearch
                  {...search}
                  setData={setData}
                  clientsList={clientsList}
                  totalization={totalization}
                  updateInputs={updateInputs}
                  setApiCallMade={setApiCallMade}
                  setBackdropLoading={setLoading}
                  setActiveOrg={setActiveOrg}
                />
              )}
            </Grid>
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                <Grid container className={classes.printContainer}>
                  <Grid item container xs={12}>
                    <Grid item xs={3}>
                      <ReportData />
                    </Grid>
                    <Grid item xs={6}>
                      <ReportTitle />
                    </Grid>
                    <Grid item xs={3}>
                      <ReportPaymentTypesData />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    {totalization ? (
                      <ReportsCompaniesTableTotalization
                        {...tableTotal}
                        isPrinting={true}
                        data={data.items}
                      />
                    ) : (
                      <ReportsCompaniesTable
                        {...table}
                        isPrinting={true}
                        data={data.items}
                      />
                    )}
                  </Grid>
                  {data.items_observations &&
                    data.items_observations.length > 0 && (
                      <Grid item container xs={12}>
                        <Typography
                          variant="caption"
                          className={classes.tableSection}
                        >
                          {t("observations")}
                        </Typography>
                        <ReportsCompaniesObservationsTable
                          isPrinting={true}
                          data={data.items_observations}
                        />
                      </Grid>
                    )}
                </Grid>
              </div>
            </div>
            {(Array.isArray(data.items) && data.items.length > 0) ||
            (!Array.isArray(data.items) &&
              Object.keys(data.items).length > 0) ? (
              <Grid container className={classes.mainContainer}>
                <Grid item container xs={12}>
                  <Grid item xs={3}>
                    <PrintButton />
                    <ReportData />
                  </Grid>
                  <Grid item xs={6}>
                    <ReportTitle />
                  </Grid>
                  <Grid item xs={3}>
                    <ReportPaymentTypesData />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  {totalization ? (
                    <ReportsCompaniesTableTotalization
                      {...tableTotal}
                      data={data.items}
                    />
                  ) : (
                    <ReportsCompaniesTable {...table} data={data.items} />
                  )}
                </Grid>
                {data.items_observations && data.items_observations.length > 0 && (
                  <Grid item container xs={12}>
                    <Typography
                      variant="caption"
                      className={classes.tableSection}
                    >
                      {t("observations")}
                    </Typography>
                    <ReportsCompaniesObservationsTable
                      data={data.items_observations}
                    />
                  </Grid>
                )}
              </Grid>
            ) : null}
            <DataNotFound />
            <LoadingBackdrop {...loadingBackdrop} open={loading} />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

ReportsCompanies.propTypes = propTypes;
ReportsCompanies.defaultProps = defaultProps;

export default ReportsCompanies;
export {
  propTypes as ReportsCompaniesPropTypes,
  defaultProps as ReportsCompaniesDefaultProps,
};

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import AddProductConditionModal, {
  AddProductConditionModalDefaultProps,
  AddProductConditionModalPropTypes,
} from "../AddProductConditionModal";
import EditProductConditionModal, {
  EditProductConditionModalDefaultProps,
  EditProductConditionModalPropTypes,
} from "../EditProductConditionModal";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditProductModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditProductModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  createBtn: PropTypes.shape(CreateButtonPropTypes),
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  addCondition: PropTypes.shape(AddProductConditionModalPropTypes),
  editCondition: PropTypes.shape(EditProductConditionModalPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
    um: PropTypes.string,
    organization_id: PropTypes.string,
    work_order_type_id: PropTypes.string,
    car_type_id: PropTypes.string,
    is_service: PropTypes.bool,
    is_expendable: PropTypes.bool,
    is_count_price: PropTypes.bool,
    description: PropTypes.string,
  }),
  editData: PropTypes.object,
  currency: PropTypes.string,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  createBtn: CreateButtonDefaultProps,
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  addCondition: AddProductConditionModalDefaultProps,
  editCondition: EditProductConditionModalDefaultProps,
  defaultValues: {
    name: "",
    price: "",
    um: "",
    organization_id: "",
    work_order_type_id: "",
    car_type_id: "",
    is_service: false,
    is_expendable: false,
    is_count_price: false,
    description: "",
  },
  currency: "RON",
  editData: {},
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditProductModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    editData,
    setEditData,
    setUpdated,
    currency,
    createBtn,
    editButton,
    deleteButton,
    addCondition,
    editCondition,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Initializes the product id
   */
  const [productID, setProductID] = useState(0);

  /**
   * Initializes the product condition edit data
   */
  const [conditionEditData, setConditionEditData] = useState({});

  /**
   * Initializes a cache for the deleted item data
   */
  const [deleteTarget, setDeleteTarget] = useState({});

  /**
   * Initializes the conditions array
   * Holds all the conditions
   */
  const [conditions, setConditions] = useState([]);

  /**
   * Initializes the show conditions flag
   */
  const [showConditions, setShowConditions] = useState(false);

  /**
   * Initializes the down trend conditions
   * New price < Standard price
   */
  const [downTrend, setDownTrend] = useState([]);

  /**
   * Initializes the flat trend conditions
   * New price === Standard price
   */
  const [flatTrend, setFlatTrend] = useState([]);

  /**
   * Initializes the up trend conditions
   * New price > Standard price
   */
  const [upTrend, setUpTrend] = useState([]);

  /**
   * Initializes the organizations state
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the car types list
   */
  const [carTypes, setCarTypes] = useState([]);

  /**
   * Initializes the work order types list
   */
  const [workOrderTypes, setWorkOrderTypes] = useState([]);

  /**
   * Initializes the fields state
   */
  const [fields, setFields] = useState([]);

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    add: false,
    edit: false,
  });

  /**
   * Handles the api error
   */
  const handleAPIErrorResponse = (error) => {
    /**
     * Resets the state
     */
    setLoading(false);
    setReady(false);

    /**
     * Handles dispatching the error message
     */
    dispatchMessage({
      icon: false,
      severity: "error",
      component: <ErrorMessages {...errorMessages} error={error} />,
    });
  };

  /**
   * Handles updating a product by id
   */
  const updateProduct = async (data, id) => {
    try {
      const updatedProduct = await apiClient.put(`/products/${id}`, data);
      if (updatedProduct) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });

        handleCloseModal("update");
      }
    } catch (error) {
      handleAPIErrorResponse(error);
    }
  };

  /**
   * Handles getting a product by id
   */
  const getProductById = async (id) => {
    try {
      const product = await apiClient.get(`/products/${id}`);
      if (product) {
        const { data } = product;
        const {
          id,
          organization_id,
          work_order_type_id,
          car_type_id,
          is_service,
          is_expendable,
          is_count_price,
          name,
          um,
          price,
          description,
          price_conditions,
        } = data;

        const workOrderIDs = workOrderTypes.map((wo) => wo.id);

        let woExists = workOrderIDs.includes(work_order_type_id);

        setProductID(id);
        setFields([
          { label: t("tyre_widthLabel"), value: "tyre_width" },
          { label: t("tyre_heightLabel"), value: "tyre_height" },
          { label: t("tyre_rimLabel"), value: "tyre_rim" },
        ]);
        setConditions(price_conditions);
        setInputs((prevState) => {
          return {
            ...prevState,
            organization_id: organization_id ? organization_id : "",
            work_order_type_id: woExists ? work_order_type_id : "",
            car_type_id: car_type_id ? car_type_id : "",
            is_service: is_service ? is_service : false,
            is_expendable: is_expendable ? is_expendable : false,
            is_count_price: is_count_price ? is_count_price : false,
            name: name ? name : "",
            um: um ? um : "",
            price: price ? price.toString() : "0",
            description: description ? description : "",
          };
        });
      }
    } catch (error) {
      handleAPIErrorResponse(error);
    }
  };

  /**
   * Handles deleting a product condition by id
   */
  const deleteProductCondition = async (id) => {
    try {
      const deletedCondition = await apiClient.delete(
        `/product-conditions/${id}`
      );
      if (deletedCondition) {
        setLoading(false);
        const newConditions = conditions.filter(
          (condition) => condition.id !== deleteTarget.id
        );
        setDeleteTarget({});
        setConditions(newConditions);
        /**
         * Handles dispatching the success message
         */
        dispatchMessage({ icon: false, message: t("conditionDeleted") });
      }
    } catch (error) {
      handleAPIErrorResponse(error);
    }
  };

  /**
   * Handles Opening the modal
   */
  const handleOpen = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleClose = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAdd = () => handleOpen("add");
  const closeAdd = () => handleClose("add");

  /**
   * Defines the edit User modal handlers
   */
  const openEdit = () => handleOpen("edit");
  const closeEdit = () => handleClose("edit");

  /**
   * Handles deleting the product condition
   */
  const handleDelete = (id) => {
    setLoading(true);
    deleteProductCondition(id);
  };

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    resetInputs,
    setInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    organization_id,
    work_order_type_id,
    car_type_id,
    is_service,
    is_expendable,
    is_count_price,
    name,
    um,
    price,
    description,
  } = inputs;

  const {
    name: nameError,
    price: priceError,
    um: umError,
    organization_id: organization_idError,
    work_order_type_id: work_order_type_idError,
    car_type_id: car_type_idError,
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (triggerUpdate) => {
    if (triggerUpdate) setUpdated(true);
    resetInputs();
    setEditData({});
    setReady(false);
    setShowConditions(false);
    closeModal();
  };

  /**
   * Defines the Condition Component
   */
  const Condition = (props) => {
    const { new_price, field, condition, value, flat, up, id } = props;

    /**
     * Defines the condition price classes
     */
    const conditionPriceClasses = clsx(classes.conditonPrice, {
      [classes.flat]: flat,
      [classes.up]: up,
    });

    /**
     * Builds the condition string
     */
    const getConditionText = () =>
      `${t("if")} ${t(field)} ${condition} ${value}`;

    /**
     * Handles opening the edit modal
     */
    const editItem = () => setConditionEditData(props);

    /**
     * Handles deleting the item
     */
    const deleteItem = () => {
      handleDelete(id);
      setDeleteTarget({ id, is_service });
    };

    return (
      <li className={classes.priceCondition}>
        <span className={conditionPriceClasses}>
          {new_price} {currency}
        </span>
        <span className={classes.conditionText}>{getConditionText()}</span>
        <div className={classes.conditionActions}>
          <EditButton
            {...editButton}
            className={classes.button}
            title={t("editCondition")}
            onClick={editItem}
          />
          <DeleteButton
            {...deleteButton}
            className={classes.deleteButton}
            title={t("deleteCondition")}
            loading={loading && deleteTarget.id === id}
            spinnerSize={24}
            onClick={deleteItem}
          />
        </div>
      </li>
    );
  };

  /**
   * Handles getting the trend by type
   */
  const getTrendArray = (type) => {
    switch (type) {
      case "flat":
        return flatTrend;
      case "down":
        return downTrend;
      case "up":
        return upTrend;
      default:
        return [];
    }
  };

  /**
   * Handles getting the condition props unique for each trend
   */
  const getConditionSpecialProps = (type) => {
    switch (type) {
      case "flat":
        return { flat: true };
      case "down":
        return { down: true };
      case "up":
        return { up: true };
      default:
        return [];
    }
  };

  /**
   * Handles rendering the conditions
   */
  const renderConditions = (type) => {
    const trend = getTrendArray(type);
    const conditionProps = getConditionSpecialProps(type);
    return (
      trend &&
      trend.map((condition, idx) => {
        return <Condition key={idx} {...condition} {...conditionProps} />;
      })
    );
  };

  /**
   * Handles rendering the price conditions actions
   */
  const renderConditionsActions = () => {
    return (
      <Zoom mountOnEnter unmountOnExit in={showConditions}>
        <Grid container>
          <Grid item xs={12} container justify="center">
            <div className={classes.actions}>
              <Typography
                variant="caption"
                className={classes.priceConditionsTitle}
              >
                {t("price_conditions")}
                <CreateButton
                  {...createBtn}
                  className={classes.smallBtn}
                  onClick={openAdd}
                />
              </Typography>
              <Typography variant="caption" className={classes.priceLabel}>
                {t("standardPrice")}
                <Typography variant="caption" className={classes.priceValue}>
                  {editData.price} {currency}
                </Typography>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Zoom>
    );
  };

  /**
   * Handles rendering the price conditions
   */
  const renderPriceConditions = () => {
    /**
     * Defines the trends
     */
    const downTrends = renderConditions("down");
    const flatTrends = renderConditions("flat");
    const upTrends = renderConditions("up");

    /**
     * Defines the render condition
     */
    const renderCondition =
      showConditions && conditions && conditions.length > 0;

    return (
      <Zoom mountOnEnter unmountOnExit in={renderCondition}>
        <Grid container className={classes.priceConditions}>
          <Grid item container xs={12} md={4} direction="column">
            <Typography variant="caption" className={classes.title}>
              <TrendingDownIcon className={classes.downIcon} /> {t("downTrend")}
            </Typography>
            <div className={classes.conditionsContainer}>{downTrends}</div>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={4}
            direction="column"
            className={classes.section}
          >
            <Typography variant="caption" className={classes.title}>
              <TrendingFlatIcon className={classes.flatIcon} /> {t("flatTrend")}
            </Typography>
            <div className={classes.conditionsContainer}>{flatTrends}</div>
          </Grid>
          <Grid item container xs={12} md={4} direction="column">
            <Typography variant="caption" className={classes.title}>
              <TrendingUpIcon className={classes.upIcon} /> {t("upTrend")}
            </Typography>
            <div className={classes.conditionsContainer}>{upTrends}</div>
          </Grid>
        </Grid>
      </Zoom>
    );
  };

  /**
   * Handles distributing the conditions in their respective trend categories
   */
  useEffect(() => {
    const { price, is_count_price } = editData;

    if (conditions && conditions.length > 0) {
      setShowConditions(true);
      let downTrends = [];
      let flatTrends = [];
      let upTrends = [];

      conditions.forEach((condition) => {
        if (condition.new_price > price) upTrends.push(condition);
        if (condition.new_price < price) downTrends.push(condition);
        if (condition.new_price === price) flatTrends.push(condition);
      });

      setDownTrend(downTrends);
      setFlatTrend(flatTrends);
      setUpTrend(upTrends);
    }

    if (price > 0 && is_count_price) {
      setShowConditions(true);
    }
  }, [conditions, price, editData]);

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      });
      setOrganizations(orgs);
    }
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const workorderTypes = user.settings.account.work_order_types;

      setCarTypes([{ id: "none", name: t("none") }, ...carTypes]);
      setWorkOrderTypes([{ id: "none", name: t("none") }, ...workorderTypes]);
    }

    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles opening the edit modal once the edit data is set
   */
  useEffect(() => {
    if (Object.keys(conditionEditData).length > 0) {
      openEdit();
    }
    // eslint-disable-next-line
  }, [conditionEditData]);

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      /**
       * Gets the product by ID
       */
      getProductById(editData.id);
    }

    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        is_service,
        is_expendable,
        is_count_price,
      };

      if (description) data["description"] = description;
      if (organization_id) data["organization_id"] = organization_id;

      car_type_id
        ? (data["car_type_id"] = car_type_id)
        : (data["car_type_id"] = null);
      work_order_type_id
        ? (data["work_order_type_id"] = work_order_type_id)
        : (data["work_order_type_id"] = null);

      if (name) data["name"] = name;
      if (um) data["um"] = um;
      if (price) data["price"] = price;

      updateProduct(data, editData.id);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the is_expendable flag depending on the is_service flag
   */
  useEffect(() => {
    if (is_service && is_expendable) {
      setInputs((prevState) => {
        return { ...prevState, is_expendable: false };
      });
    }
    // eslint-disable-next-line
  }, [is_service]);

  /**
   * Handles updating the is_service flag depending on the is_expendable flag
   */
  useEffect(() => {
    if (is_expendable && is_service) {
      setInputs((prevState) => {
        return { ...prevState, is_service: false };
      });
    }
    // eslint-disable-next-line
  }, [is_expendable]);

  /**
   * Defines the input label props
   */
  const inputLabelProps = {
    className: classes.label,
  };

  useEffect(() => {
    if (car_type_id === "none")
      setInputs((prevState) => ({ ...prevState, car_type_id: "" }));
    // eslint-disable-next-line
  }, [car_type_id]);

  useEffect(() => {
    if (work_order_type_id === "none")
      setInputs((prevState) => ({ ...prevState, work_order_type_id: "" }));
    // eslint-disable-next-line
  }, [work_order_type_id]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={`${t("titleEditProduct")} ${editData.name}`}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container>
                        <Grid container item xs={12} md={9} spacing={1}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "name",
                                name: "name",
                                value: name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "outlined",
                                InputLabelProps: inputLabelProps,
                                label: t("nameLabel"),
                                error: nameError,
                                autoFocus: getAutoFocus().name,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Input
                              {...input}
                              type="numeric"
                              className={classes.field}
                              inputNumeric={{
                                id: "price",
                                name: "price",
                                value: price,
                                onChange: handleInputChange,
                                variant: "outlined",
                                InputLabelProps: inputLabelProps,
                                label: t("priceLabel"),
                                error: priceError,
                                autoFocus: getAutoFocus().price,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "um",
                                name: "um",
                                value: um,
                                onChange: handleInputChange,
                                maxSize: 5,
                                variant: "outlined",
                                placeholder: t("piece"),
                                InputLabelProps: inputLabelProps,
                                label: t("umLabel"),
                                error: umError,
                                autoFocus: getAutoFocus().um,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "organization_id",
                                name: "organization_id",
                                value: organization_id,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("organizationLabel"),
                                options: organizations,
                                optionLabel: "label",
                                optionValue: "value",
                                error: organization_idError,
                                autoFocus: getAutoFocus().organization_id,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "work_order_type_id",
                                name: "work_order_type_id",
                                value: work_order_type_id,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("work_order_typeLabel"),
                                options: workOrderTypes,
                                optionLabel: "name",
                                optionValue: "id",
                                error: work_order_type_idError,
                                autoFocus: getAutoFocus().work_order_type_id,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "car_type_id",
                                name: "car_type_id",
                                value: car_type_id,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("car_typeLabel"),
                                options: carTypes,
                                optionLabel: "name",
                                optionValue: "id",
                                error: car_type_idError,
                                autoFocus: getAutoFocus().car_type_id,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} md={3} justify="center">
                          <Grid
                            container
                            item
                            xs={12}
                            md={10}
                            justify="flex-end"
                          >
                            <Grid item xs={12} container direction="column">
                              <Typography
                                variant="caption"
                                className={classes.formSection}
                              >
                                {t("config")}
                              </Typography>
                              <Input
                                {...input}
                                type="checkbox"
                                className={classes.field}
                                inputCheckbox={{
                                  name: "is_service",
                                  value: is_service,
                                  onChange: handleInputChange,
                                  variant: "outlined",
                                  labelClass: classes.label,
                                  InputLabelProps: inputLabelProps,
                                  showHelper: false,
                                  label: t("is_serviceLabel"),
                                }}
                              />
                              <Input
                                {...input}
                                type="checkbox"
                                className={classes.field}
                                inputCheckbox={{
                                  name: "is_expendable",
                                  value: is_expendable,
                                  onChange: handleInputChange,
                                  variant: "outlined",
                                  labelClass: classes.label,
                                  InputLabelProps: inputLabelProps,
                                  showHelper: false,
                                  label: t("is_expendableLabel"),
                                }}
                              />
                              <Input
                                {...input}
                                type="checkbox"
                                className={classes.field}
                                inputCheckbox={{
                                  name: "is_count_price",
                                  value: is_count_price,
                                  onChange: handleInputChange,
                                  variant: "outlined",
                                  labelClass: classes.label,
                                  InputLabelProps: inputLabelProps,
                                  showHelper: false,
                                  label: t("is_count_priceLabel"),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            className={classes.textarea}
                            inputTextarea={{
                              id: "edit_description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 6,
                              rowsMax: 14,
                              maxChars: 200,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel"),
                            }}
                          />
                        </Grid>
                        {renderConditionsActions()}
                        {renderPriceConditions()}
                      </Grid>
                    </CardContent>
                    <AddProductConditionModal
                      {...addCondition}
                      conditions={conditions}
                      setConditions={setConditions}
                      open={modalState.add}
                      closeModal={closeAdd}
                      fields={fields}
                      id={productID}
                    />
                    <EditProductConditionModal
                      {...editCondition}
                      conditions={conditions}
                      setConditions={setConditions}
                      open={modalState.edit}
                      closeModal={closeEdit}
                      setEditData={setConditionEditData}
                      editData={conditionEditData}
                      fields={fields}
                      id={conditionEditData.id}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading && Object.keys(deleteTarget).length < 1}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditProductModal.propTypes = propTypes;
EditProductModal.defaultProps = defaultProps;

export default EditProductModal;
export {
  propTypes as EditProductModalPropTypes,
  defaultProps as EditProductModalDefaultProps,
};

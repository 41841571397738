/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t } = props;
  const { isEmpty, isPhoneNum } = useValidator();

  const validatorFn = (props) => {
    const { name, value } = props;

    let error = null;

    switch (name) {
      case "client_name":
      case "name":
      case "role":
      case "from":
      case "to":
      case "car_plate_number":
        if (isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "phone":
        if (value && !isPhoneNum(value))
          return (error = t("LanguageProvider:error_telNumInvalid"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1.2rem",
    fontFamily: theme.font.display,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "0.5rem",
    color: theme.palette.error.main,
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  button: {
    padding: "0.25rem 1rem",
    margin: "0 0.35rem",
    minWidth: 155,
    borderRadius: 0,
    fontWeight: 400,
    height: 30,
    background: theme.palette.button.light,
    color: theme.palette.secondary.contrastText,
    boxShadow: "none",
    marginBottom: "1rem",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

import { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { useParams } from "react-router-dom";

/**
 * Hooks
 */
import { useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  path: PropTypes.string,
  supportedType: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  path: "/documents/:document_name",
  supportedType: "application/pdf",
};

/**
 * Displays the component
 */
const DocumentPage = (props) => {
  const { supportedType } = props;

  /**
   * Gets the query params
   */
  const { document_name } = useParams();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Handles getting a document by name
   */
  const getDocument = async (name) => {
    const document = await apiClient.get(`/documents/official/${name}`, {
      responseType: "blob",
    });
    if (document) {
      const fileURL = generateUrl(document);
      window.open(fileURL, "_self", "", false);
    }
  };

  /**
   * Generates a url for the pdf returned from the request
   */
  const generateUrl = (data) => {
    const blob = data;
    const file = new Blob([blob], {
      type: supportedType,
    });

    const url = URL.createObjectURL(file);

    return url;
  };

  /**
   * Gets the document if there's no document
   */
  useEffect(() => {
    getDocument(document_name);

    // eslint-disable-next-line
  }, [document_name]);

  return null;
};

DocumentPage.propTypes = propTypes;
DocumentPage.defaultProps = defaultProps;

export default DocumentPage;
export {
  propTypes as DocumentPagePropTypes,
  defaultProps as DocumentPageDefaultProps,
};

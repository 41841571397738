/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  viewButton: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  printButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  settingsButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  loader: {
    color: theme.palette.common.white,
    width: "1.5rem",
    height: "1.5rem",
  },
  tableTitle: {
    fontSize: "1.2rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    marginTop: 0,
    marginBottom: "0.35rem",
  },
  actions: {
    padding: "1rem",
    display: "flex",
    background: theme.palette.common.white,
    flexDirection: "column",
    justifyContent: "center",
  },
  changeStatus: {
    padding: "0.25rem 1rem",
    margin: "0.5rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.accent,
      boxShadow: "none",
    },
    display: "flex",
    flex: 1,
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem",
    },
  },
  popoverTitle: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "0.8rem",
    marginTop: "0.25rem",
  },
  doneStatus: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  progressStatus: {
    background: theme.palette.warning.main,
    "&:hover": {
      background: theme.palette.warning.dark,
      boxShadow: "none",
    },
  },
  holdStatus: {
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
  newWorkOrder: {
    background: "#fff2de !important",
    padding: "0 12px !important",
  },
  doneWorkOrder: {
    background: "#deffd6 !important",
    padding: "0 12px !important",
  },
  holdWorkOrder: {
    background: "#f2f8ff !important",
    padding: "0 12px !important",
  },
  progressWorkOrder: {
    padding: "0 12px !important",
  },
  isWorker: {
    padding: "6px 12px !important",
  },
  bold: {
    fontWeight: 800,
  },
}));

export { useStyles };

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch, Redirect } from "react-router-dom";

/**
 * Component Imports
 */
import TyreServiceSidebar, {
  TyreServiceSidebarDefaultProps,
  TyreServiceSidebarPropTypes,
} from "../TyreServiceSidebar";
import TyreServiceControlPanel, {
  TyreServiceControlPanelDefaultProps,
  TyreServiceControlPanelPropTypes,
} from "../TyreServiceControlPanel";
import TyreServiceTyreHotels, {
  TyreServiceTyreHotelsDefaultProps,
  TyreServiceTyreHotelsPropTypes,
} from "../TyreServiceTyreHotels";
import TyreServiceCreateWorkOrders, {
  TyreServiceCreateWorkOrdersDefaultProps,
  TyreServiceCreateWorkOrdersPropTypes,
} from "../TyreServiceCreateWorkOrders";
import TyreServiceScheduler, {
  TyreServiceSchedulerDefaultProps,
  TyreServiceSchedulerPropTypes,
} from "../TyreServiceScheduler";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes,
} from "../PageNotFound";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(TyreServiceSidebarPropTypes),
  controlPanel: PropTypes.shape(TyreServiceControlPanelPropTypes),
  hotels: PropTypes.shape(TyreServiceTyreHotelsPropTypes),
  create: PropTypes.shape(TyreServiceCreateWorkOrdersPropTypes),
  scheduler: PropTypes.shape(TyreServiceSchedulerPropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  path: PropTypes.string,
  paths: PropTypes.shape({
    controlPanel: PropTypes.string,
    hotels: PropTypes.string,
    create: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */

const defaultProps = {
  sidebar: TyreServiceSidebarDefaultProps,
  controlPanel: TyreServiceControlPanelDefaultProps,
  hotels: TyreServiceTyreHotelsDefaultProps,
  create: TyreServiceCreateWorkOrdersDefaultProps,
  scheduler: TyreServiceSchedulerDefaultProps,
  notFound: PageNotFoundDefaultProps,
  path: "/modules/tyre-service",
  paths: {
    controlPanel: TyreServiceControlPanelDefaultProps.path,
    hotels: TyreServiceTyreHotelsDefaultProps.path,
    create: TyreServiceCreateWorkOrdersDefaultProps.path,
    scheduler: TyreServiceSchedulerDefaultProps.path,
  },
};

/**
 * Displays the component
 */
const TyreServicePage = (props) => {
  const {
    sidebar,
    controlPanel,
    hotels,
    create,
    scheduler,
    notFound,
    path: basePath,
    paths,
  } = props;

  /**
   * Gets the paths from the components
   */
  const {
    controlPanel: controlPanelPath,
    hotels: hotelsPath,
    create: createPath,
    scheduler: schedulerPath,
  } = paths;

  /**
   * Defines the path building function
   * Attaches the base path of the module to each individual path
   */
  const buildPath = (path) => `${basePath}${path}`;

  /**
   * Defines and builds the paths
   */
  const buildedPaths = {
    controlPanel: buildPath(controlPanelPath),
    hotels: buildPath(hotelsPath),
    create: buildPath(createPath),
    scheduler: buildPath(schedulerPath),
  };

  return (
    <Fragment>
      <TyreServiceSidebar {...sidebar} paths={buildedPaths} />
      <Switch>
        <Route exact path={basePath}>
          <Redirect to={buildedPaths.controlPanel} />
        </Route>
        <Route exact path={buildedPaths.controlPanel}>
          <TyreServiceControlPanel {...controlPanel} />
        </Route>
        <Route exact path={buildedPaths.hotels}>
          <TyreServiceTyreHotels {...hotels} />
        </Route>
        <Route exact path={buildedPaths.create}>
          <TyreServiceCreateWorkOrders {...create} />
        </Route>
        <Route exact path={buildedPaths.scheduler}>
          <TyreServiceScheduler {...scheduler} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
    </Fragment>
  );
};

TyreServicePage.propTypes = propTypes;
TyreServicePage.defaultProps = defaultProps;

export default TyreServicePage;
export {
  propTypes as TyreServicePagePropTypes,
  defaultProps as TyreServicePageDefaultProps,
};

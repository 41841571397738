import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import shortid from "shortid";
import clsx from "clsx";
import { format as formatDate } from "date-fns";
import { differenceInSeconds } from "date-fns";

/**
 *  Imports Material UI Components
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import CardActions from "@material-ui/core/CardActions";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

/**
 * Imports Component
 */
import Modal, { ModalTitle, ModalContent, ModalActions } from "../Modal";
import Button from "../Button";
import LoadingText from "../LoadingText";
import TyreServiceCreateStep1 from "../TyreServiceCreateStep1";
import TyreServiceCreateStep2 from "../TyreServiceCreateStep2";
import TyreServiceCreateStep3 from "../TyreServiceCreateStep3";
import TabPanel from "../TabPanel";
import ErrorMessages from "../ErrorMessages";

/**
 * Imports the validator
 */
import Validator from "./TyreServiceCreateModal.validations";

/**
 * Imports hooks
 */
import { useTranslation } from "react-i18next";
import {
  useWorkOrder,
  useUser,
  useMessage,
  useForm,
  useTimer,
  useAuth,
  useApiClient,
  useDebounce,
} from "../../hooks";

/**
 * Imports the component styles
 */
import { useStyles } from "./TyreServiceCreateModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  open: false,
  closeModal: () => {},
  defaultValues: {
    organization_client_id: "",
    car_type_id: "",
    client_name: "",
    delegate_name: "",
    duration: "",
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    start_date: "",
    end_date: "",
    description: "",
    workers: [],
    plate_number: "",
    car_type: "",
    make: "",
    model: "",
    vin_number: "",
    workers_description: "",
    wheel_type: "",
    car_km: "",
    new_tyre: false,
    tyre_width: "",
    tyre_height: "",
    tyre_rim: "",
    tyre_waste: "",
    tyre_pressure: "",
    bolt_torque: "",
    tyre_hotel_tyre_width: "",
    tyre_hotel_tyre_height: "",
    tyre_hotel_tyre_rim: "",
    tyre_hotel_tyre_name: "",
    tyre_hotel_tyre_quantity: "",
    tyre_hotel_description: "",
    products: [],
  },
};

/**
 * Defines the date format
 */
const dateFormat = "yyyy-MM-dd HH:mm:ss";

/**
 * Displays the component
 */
const TyreServiceCreateModal = (props) => {
  const {
    open,
    closeModal,
    defaultValues,
    draftData,
    setActiveWork,
    recentlyCreated,
    setRecentlyCreated,
    onCreate
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher 
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global auth
   */
  const { auth } = useAuth();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the disabled state
   */
  const [disabled, setDisabled] = useState(false);

  /**
   * Initializes the special discount
   */
  const [specialDiscount, setSpecialDiscount] = useState(0);

  /**
   * Gets the work order hook
   */
  const {
    drafts,
    setDrafts,
    workOrderType,
    workOrder,
    setWorkOrder,
    setWorkOrderType,
  } = useWorkOrder();

  /**
   * Defines the plate number
   */
  const plate = workOrder.car
    ? workOrder.car.plate_number
    : draftData.plateNumber;

  /**
   * Gets the debounce
   */
  const debounce = useDebounce();

  /**
   * Handles getting the timestamp offset
   */
  const getTimestampOffset = () => {
    /**
     * Gets the timer data from local storage
     */
    const timer = JSON.parse(localStorage.getItem(`${plate}_newTimer`)) || null;

    const offset = new Date();
    offset.setSeconds(
      offset.getSeconds() +
        differenceInSeconds(
          new Date(),
          new Date(timer ? timer.startTime : new Date())
        )
    );
    return offset;
  };

  /**
   * Sets up the reducer
   */
  const reducer = (state, action) => {
    const { type, step } = action;
    const { activeStep } = state;

    switch (type) {
      case "STEP_NEXT":
        return { activeStep: activeStep + 1 };
      case "STEP_BACK":
        return { activeStep: activeStep - 1 };
      case "STEP_RESET":
        return { activeStep: 0 };
      case "STEP_UPDATE":
        return { activeStep: step };
      default:
        return state;
    }
  };

  /**
   * Initializes the active step state
   */
  const [state, dispatch] = useReducer(reducer, { activeStep: 0 });
  const { activeStep } = state;

  /**
   * Initializes the timer
   */
  const {
    getCurrentTime,
    getDurationInSeconds,
    saveToLocalStorage,
    startTime,
  } = useTimer(`${plate}_newTimer`, {
    open: activeStep === 2,
    offsetTimestamp: draftData.duration ? getTimestampOffset() : 0,
  });

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    handleSave();
    saveToLocalStorage();
    closeModal();
  };

  /**
   * Gets the wheel type name using the id
   * @param {Number} id
   */
  const getWheelTypeName = (id) => {
    if (user.settings) {
      const wheelTypes = user.settings.account.wheel_types;

      const found = wheelTypes.find((wheelType) => wheelType.id === id);
      return found ? found.name : "";
    }
  };

  /**
   * Gets the car type name using the id
   * @param {Number} id
   */
  const getCarTypeName = (id) => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;

      const found = carTypes.find((carType) => carType.id === id);
      return found ? found.name : "";
    }
  };

  /**
   * Gets the client id
   * @param {String} client_name
   */
  const getClientId = (client_name) => {
    if (!client_name) return "";
    const found = user.clients.find((client) => client.name === client_name);
    return found ? found.id : "";
  };

  /**
   * Handles returning the props for each tab
   */
  const a11yProps = (index) => {
    return {
      id: `stage-tab-${index}`,
      "aria-controls": `stage-tabpanel-${index}`,
    };
  };

  /**
   * Defines the base dispatch actions
   */
  const nextStep = () => dispatch({ type: "STEP_NEXT" });
  const prevStep = () => dispatch({ type: "STEP_BACK" });
  const updateStep = (step) => dispatch({ type: "STEP_UPDATE", step });

  /**
   * Handles the tab change
   */
  const handleChange = (event, newValue) => {
    const errors = validateInputs(inputs);
    if (!hasErrors(errors)) {
      handleSave();
      updateStep(newValue);
    }
  };

  /**
   * Handles creating a work order
   */
  const createWorkOrder = async () => {
    if (disabled) return;
    setDisabled(true);

    try {
      /**
       * Gets the wheel type name
       */
      const wheelType = getWheelTypeName(inputs.wheel_type);

      /**
       * Gets the car type name
       */
      const carTypeName = getCarTypeName(inputs.car_type);

      /**
       * Gets the inputs
       */
      const {
        organization_client_id,
        delegate_name,
        description,
        make,
        model,
        vin_number,
        car_description,
        car_km, 
        new_tyre,
        tyre_height,
        tyre_width,
        tyre_rim,
        tyre_waste,
        tyre_pressure,
        bolt_torque,
        tyre_hotel_tyre_width,
        tyre_hotel_tyre_height,
        tyre_hotel_tyre_rim,
        tyre_hotel_tyre_name,
        tyre_hotel_tyre_quantity,
        tyre_hotel_description,
      } = inputs;

      /**
       * Defines the work_order data
       */
      const work_order = {
        car_type_id: inputs.car_type,
        work_order_type_id: workOrderType,
        client_name: inputs.client_name,
        duration: getCurrentTime(),
        subtotal: parseFloat(inputs.subtotal),
        discount: inputs.specialDiscount || 0,
        tax: 0,
        total: parseFloat(inputs.total),
        start_date: formatDate(new Date(startTime), dateFormat),
        end_date: formatDate(new Date(), dateFormat),
      };

      /**
       * Adds the organization_client_id (optional field)
       */
      if (organization_client_id) {
        work_order["organization_client_id"] = getClientId(
          organization_client_id
        );
      }

      /**
       * Adds the delegate_name (optional field)
       */
      if (delegate_name) {
        work_order["delegate_name"] = delegate_name;
      }

      /**
       * Adds the description (optional field)
       */
      if (description) {
        work_order["description"] = description;
      }

      /**
       * Defines the workers
       */
      const workers = inputs.workers.map((worker) => worker.id);

      /**
       * Defines the car data
       */
      const car = {
        plate_number: inputs.plate_number,
        type: carTypeName,
      };

      /**
       * Adds the car make (optional field)
       */
      if (make) {
        car["make"] = make;
      }

      /**
       * Adds the car model (optional field)
       */
      if (model) {
        car["model"] = model;
      }

      /**
       * Adds the vin-number (optional field)
       */
      if (vin_number) {
        car["vin-number"] = vin_number;
      }

      /**
       * Adds the car description (optional field)
       */
      if (car_description) {
        car["description"] = car_description;
      }

      /**
       * Defines the tyre service data
       */
      const tyre_service = {
        wheel_type: wheelType,
      };

      /**
       * Adds tyre service related fields, all of which are optional.
       */
      if (bolt_torque) tyre_service["bolt_torque"] = bolt_torque;
      if (car_km) tyre_service["car_km"] = car_km;
      if (new_tyre) tyre_service["new_tyre"] = new_tyre;
      if (tyre_height) tyre_service["tyre_height"] = tyre_height;
      if (tyre_waste) tyre_service["tyre_waste"] = tyre_waste;
      if (tyre_width) tyre_service["tyre_width"] = tyre_width;
      if (tyre_rim) tyre_service["tyre_rim"] = tyre_rim;
      if (tyre_pressure) tyre_service["tyre_pressure"] = tyre_pressure;

      /**
       * Defines the products
       */
      const products = inputs.products;

      /**
       * Initializes the tyre_hotel data
       */
      const tyre_hotel = {};

      /**
       * Adds tyre hotel related fields, all of which are optional.
       */
      if (tyre_hotel_tyre_height) {
        tyre_hotel["tyre_height"] = tyre_hotel_tyre_height;
      }

      if (tyre_hotel_tyre_width) {
        tyre_hotel["tyre_width"] = tyre_hotel_tyre_width;
      }

      if (tyre_hotel_tyre_rim) {
        tyre_hotel["tyre_rim"] = tyre_hotel_tyre_rim;
      }

      if (tyre_hotel_tyre_name) {
        tyre_hotel["tyre_name"] = tyre_hotel_tyre_name;
      }

      if (tyre_hotel_tyre_quantity) {
        tyre_hotel["tyre_quantity"] = tyre_hotel_tyre_quantity.toString();
      }

      if (tyre_hotel_description) {
        tyre_hotel["description"] = tyre_hotel_description;
      }

      /**
       * Initializes the request body
       */
      const requestBody = {
        work_order,
        workers,
        car,
        tyre_service,
      };

      /**
       * Checks if any products exist, and adds them to the request
       */
      if (products.length > 0) {
        requestBody["products"] = products;
      }

      /**
       * Checks if the tyre_hotel is empty, and adds it to the request if it isn't
       */
      if (Object.keys(tyre_hotel).length > 0) {
        requestBody["tyre_hotel"] = tyre_hotel;
      }

      /**
       * Handles making the api request to create the work order
       */
      const createdWorkOrder = await apiClient.post(
        "/work-orders",
        requestBody
      );

      /**
       * Handles the response from the api
       */
      if (createdWorkOrder) {
        const { data } = createdWorkOrder;

        /**
         * Cleans up the states
         */
        handleCleanup(data);
        onCreate(data);
      }
    } catch (error) {
      setDisabled(false);
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages error={error} />,
      });
    }
  };

  /**
   * Handles cleaning up the create wo states
   */
  const handleCleanup = (data) => {
    debounce(() => {
      const { car_plate_number } = data;
      /**
       * Filters out the work order from the drafts
       */
      const latestWork = drafts.filter(
        (work) => work.plateNumber !== car_plate_number
      );

      /**
       * Updates the state
       */
      setActiveWork(latestWork);

      /**
       * Removes the now created work order from the local storage
       */
      localStorage.removeItem(`${car_plate_number}_newTimer`);
      localStorage.setItem("drafts", JSON.stringify(latestWork));

      /**
       * Adds the created work order to the list of recently created work orders
       */
      const latestCreated = [
        ...recentlyCreated,
        {
          user: auth.user.uuid,
          uuid: data.uuid,
          created_at: data.created_at,
          plate_number: car_plate_number,
        },
      ];

      /**
       * Updates the state
       */
      setRecentlyCreated(
        latestCreated.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      );

      /**
       * Resets states and closes modal
       */
      setDisabled(false);
      setDrafts(latestWork);
      setWorkOrderType("");
      setWorkOrder({});
    }, 2000);

    /**
     * Defines the success message
     */
    const message = `${t("orderNumber")} ${data.uuid} ${t(
      "createdSuccessfully"
    )}`;

    /**
     * Handles dispatching the message
     */
    dispatchMessage({
      delay: 450,
      timer: 15000,
      message: message,
    });

    closeModal();
  };

  /**
   * Validates the inputs and handles going to the next step if validations pass
   */
  const handleNext = () => {
    const errors = validateInputs(inputs);
    if (!hasErrors(errors)) {
      handleSave();
      nextStep();
    }
  };

  /**
   * Validates the inputs and handles going to the previous step if validations pass
   */
  const handlePrev = () => {
    const errors = validateInputs(inputs);
    if (!hasErrors(errors)) {
      handleSave();
      prevStep();
    }
  };

  /**
   * Handles saving the work order inputs as a draft
   */
  const saveAsDraft = (inputs) => {
    /**
     * Gets the existing drafts from local storage
     */
    const drafts = JSON.parse(localStorage.getItem("drafts"));

    /**
     * Finds the draft associated with the current work order
     * Updates the data or adds the work order to the list of drafts
     */

    if (!drafts) {
      localStorage.setItem("drafts");
      const drafts = [
        {
          user: auth.user.uuid,
          workOrder: { ...inputs },
          plateNumber: inputs.plate_number,
          workOrderType,
          duration: getDurationInSeconds(),
        },
      ];
      return setDrafts(drafts);
    }

    const _drafts = drafts.map((draft) => {
      /**
       * Defines the plate number
       */
      const plate =
        workOrder && workOrder.car
          ? workOrder.car.plate_number
          : draftData.plateNumber;

      if (draft.plateNumber === plate) {
        const result = {
          user: auth.user.uuid,
          workOrder: { ...inputs },
          plateNumber: inputs.plate_number,
          workOrderType,
          duration: getDurationInSeconds(),
        };
        return result;
      } else {
        return { ...draft };
      }
    });

    /**
     * Updates the state
     */
    setDrafts(_drafts);
  };

  /**
   * Handles initializing the work order
   */
  const initializeWorkOrder = () => {
    /**
     * Defines the plate number
     */
    const plateNumber =
      workOrder && workOrder.car
        ? workOrder.car.plate_number
        : draftData.plateNumber;

    if (!plateNumber) return false;

    /**
     * If there are any drafts, check if the current work order exists in this list
     * Update the inputs with the draft data or initialize an empty draft with just the plate number of the WO
     */
    if (drafts.length > 0) {
      const foundWO = drafts.find((draft) => draft.plateNumber === plateNumber);
      if (foundWO) {
        setWorkOrder(foundWO.workOrder);
        setInputs((prevState) => {
          return {
            ...prevState,
            ...foundWO.workOrder,
          };
        });
      } else {
        const prevDrafts = [...drafts];
        const newDraft = {
          id: shortid.generate(),
          user: auth.user.uuid,
          workOrder: {},
          workOrderType,
          duration: getDurationInSeconds(),
          plateNumber,
        };
        prevDrafts.push(newDraft);
        setDrafts(prevDrafts);
      }
    } else {
      setDrafts([
        {
          id: shortid.generate(),
          user: auth.user.uuid,
          workOrder: {},
          workOrderType,
          duration: getDurationInSeconds(),
          plateNumber,
        },
      ]);
    }
  };

  /**
   * Handles the submit
   */
  const onSubmit = () => handleSave();

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    handleValidation,
    validateInputs,
    hasErrors,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Handles saving the work order
   */
  const handleSave = () => {
    /**
     * Validates before saving
     */
    const errors = validateInputs(inputs);

    /**
     * Updates the special discount state
     */
    setSpecialDiscount(inputs.specialDiscount);

    /**
     * Prepares the data if the validations pass
     */
    if (!hasErrors(errors)) {
      setLoading(true);

      /**
       * Defines the work order data
       */
      const work_order = {
        work_order_type: workOrderType,
        organization_client_id: getClientId(inputs.organization_client_id),
        car_type_id: inputs.car_type,
        client_name: inputs.client_name,
        delegate_name: inputs.delegate_name,
        duration: getCurrentTime(),
        subtotal: inputs.subtotal,
        discount: inputs.discount,
        tax: inputs.tax,
        total: inputs.total,
        start_date: formatDate(new Date(startTime), dateFormat),
        end_date: formatDate(new Date(), dateFormat),
        description: inputs.description,
      };

      /**
       * Defines the workers
       */
      const workers = inputs.workers;

      /**
       * Defines the car data
       */
      const car = {
        plate_number: inputs.plate_number,
        type: inputs.car_type,
        make: inputs.make,
        model: inputs.model,
        vin_number: inputs.vin_number,
      };

      /**
       * Defines the tyre service data
       */
      const tyre_service = {
        wheel_type: inputs.wheel_type,
        car_km: inputs.car_km,
        new_tyre: inputs.new_tyre,
        tyre_width: inputs.tyre_width,
        tyre_height: inputs.tyre_height,
        tyre_rim: inputs.tyre_rim,
        tyre_waste: inputs.tyre_waste,
        tyre_pressure: inputs.tyre_pressure,
        bolt_torque: inputs.bolt_torque,
      };

      /**
       * Defines the products data
       */
      const products = inputs.products;

      /**
       * Defines the tyre hotel data
       */
      const tyre_hotel = {
        tyre_width: inputs.tyre_hotel_tyre_width,
        tyre_height: inputs.tyre_hotel_tyre_height,
        tyre_rim: inputs.tyre_hotel_tyre_rim,
        tyre_name: inputs.tyre_hotel_tyre_name,
        tyre_quantity: inputs.tyre_hotel_tyre_quantity,
        description: inputs.tyre_hotel_description,
      };

      /**
       * Triggers the timeout to stop the loading spinner
       */
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      /**
       * Updates the work order state
       */
      setWorkOrder((prevState) => {
        return {
          ...prevState,
          work_order,
          workers,
          car,
          tyre_service,
          tyre_hotel,
          products,
          hasHotelService: inputs.hasHotelService,
        };
      });

      /**
       * Saves the inputs to a draft
       */
      saveAsDraft(inputs);

      /**
       *  Syncs the local timer with local storage
       *  Creates the entry if it doesn't exist.
       */
      saveToLocalStorage();
    }
  };

  /**
   * Sets the initial state of the inputs
   * If draft data was provided as props it will use that data to set the inputs
   * Otherwise initializes with just the car plate number from the work order (initialized in the parent)
   */
  useEffect(() => {
    if (workOrder) {
      if (Object.keys(draftData).length > 0) {
        const { workOrder } = draftData;
        setInputs((prevState) => ({
          ...prevState,
          ...workOrder,
          plate_number:
            plate || draftData.plateNumber || workOrder.plate_number,
        }));
      } else {
        const { car } = workOrder;
        setInputs((prevState) => ({
          ...prevState,
          plate_number: car.plate_number,
        }));
      }
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Handles saving to local storage and initializing the state of the work order
   */
  useEffect(() => {
    initializeWorkOrder();
    // eslint-disable-next-line
  }, [open]);

  /**
   * Updates the work order state if the has hotel service flag has changed
   * By default this flag is undefined.
   */
  useEffect(() => {
    if (inputs.hasHotelService === false) {
      setWorkOrder((prevState) => ({
        ...prevState,
        hasHotelService: false,
        tyre_hotel: {
          description: "",
          tyre_height: "",
          tyre_name: "",
          tyre_quantity: "",
          tyre_rim: "",
          tyre_width: "",
        },
      }));
    }
    // eslint-disable-next-line
  }, [inputs]);

  console.log("INPUTS:", inputs)

  return (
    <Modal
      fullScreen={triggerFullScreen}
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      keepMounted={false}
      scroll="paper"
      key={`TyreServiceCreateModal_${open}`}
    >
      <ModalTitle
        title={t("titleCreateNewWorkOrder")}
        onClick={handleCloseModal}
      />
      <ModalContent className={classes.modalContent}>
        <Grid item container xs={12}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank} style={{ boxShadow: "none" }}>
                <CardContent className={classes.content}>
                  <Grid container justify="center">
                    <Grid item xs={12} md={12}>
                      <Box className={classes.root}>
                        <AppBar
                          position="static"
                          color="default"
                          className={classes.appbar}
                        >
                          <Tabs
                            className={classes.tabs}
                            value={activeStep}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="standard"
                            classes={{
                              indicator: classes.indicator,
                            }}
                          >
                            <Tab label={t("stage2")} {...a11yProps(0)} />
                            <Tab label={t("stage3")} {...a11yProps(1)} />
                            <Tab label={t("stage4")} {...a11yProps(2)} />
                          </Tabs>
                        </AppBar>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <TabPanel value={activeStep} index={0}>
                        <TyreServiceCreateStep1
                          inputs={inputs}
                          setInputs={setInputs}
                          errors={errors}
                          handleInputChange={handleInputChange}
                          getAutoFocus={getAutoFocus}
                          handleValidation={handleValidation}
                          originalPlate={plate}
                        />
                      </TabPanel>
                      <TabPanel value={activeStep} index={1}>
                        <TyreServiceCreateStep2
                          handleSave={handleSave}
                          inputs={inputs}
                          setInputs={setInputs}
                          errors={errors}
                          handleInputChange={handleInputChange}
                          getAutoFocus={getAutoFocus}
                          handleValidation={handleValidation}
                        />
                      </TabPanel>
                      <TabPanel value={activeStep} index={2}>
                        <TyreServiceCreateStep3
                          offset={draftData.duration ? getTimestampOffset() : 0}
                          plate={plate}
                          specialDiscount={specialDiscount}
                        />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <CardActions className={classes.cardActions}>
          <Button
            type="button"
            variant="filled"
            onClick={handleCloseModal}
            className={classes.closeBtn}
          >
            {t("close")}
          </Button>
          <Box className={classes.buttonsContainer}>
            <Button
              onClick={handleSave}
              variant="filled"
              className={classes.saveBtn}
            >
              <LoadingText
                loading={loading}
                text={t("save")}
                icon={<SaveIcon />}
              />
            </Button>
            {activeStep > 0 && (
              <Button
                type="button"
                variant="filled"
                className={classes.submitButton}
                onClick={handlePrev}
              >
                <NavigateBeforeIcon className={classes.beforeIcon} />
                {t("previous")}
              </Button>
            )}

            {activeStep === 2 ? (
              <Button
                type="button"
                variant="filled"
                onClick={createWorkOrder}
                disabled={disabled}
                className={clsx(classes.submitButton, classes.finalStep)}
              >
                <LoadingText
                  loading={disabled}
                  text={t("done")}
                  icon={<DoneIcon className={classes.nextIcon} />}
                />
              </Button>
            ) : (
              <Button
                type="button"
                variant="filled"
                className={classes.submitButton}
                onClick={handleNext}
              >
                {t("next")}
                <NavigateNextIcon className={classes.nextIcon} />
              </Button>
            )}
          </Box>
        </CardActions>
      </ModalActions>
    </Modal>
  );
};

TyreServiceCreateModal.propTypes = propTypes;
TyreServiceCreateModal.defaultProps = defaultProps;

export default TyreServiceCreateModal;
export {
  propTypes as TyreServiceCreateModalPropTypes,
  defaultProps as TyreServiceCreateModalDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: theme.mixins.submoduleContainer,
  box: {
    margin: "1rem 0",
    padding: "0.5rem 2rem",
    paddingBottom: "1rem"
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * Theme Import
 */
import { theme as defaultTheme } from "../../themes/default";
import { darkTheme } from "../../themes/dark-theme";
import { roRO, enUS, huHU } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

/**
 * Hooks
 */
import {
  AuthProvider,
  UserProvider,
  MessageProvider,
  RegisterProvider,
  SidebarProvider,
  LanguageProvider,
  WorkOrderProvider,
  useTheme,
  useLanguage,
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  children: "Content",
};

/**
 * Displays the component
 */
const Providers = (props) => {
  const { children } = props;

  /**
   * Gets the active theme
   */
  const { activeTheme } = useTheme();

  /**
   * Gets the active language
   */
  const { activeLanguage } = useLanguage();

  /**
   * Handles getting the theme data
   */
  const getTheme = () => {
    let language = roRO;
    let theme = defaultTheme;
    if (activeLanguage === "en_en") language = enUS;
    if (activeLanguage === "en_hu") language = huHU;

    switch (true) {
      case activeTheme === "default":
        theme = defaultTheme;
        break;
      case activeTheme === "dark-theme":
        theme = darkTheme;
        break;
      default:
        theme = defaultTheme;
    }

    return createMuiTheme(theme, language);
  };

  return (
    <ThemeProvider
      key={`${activeLanguage}_top_level_${activeTheme}`}
      theme={getTheme()}
    >
      <LanguageProvider>
        <RegisterProvider>
          <AuthProvider>
            <UserProvider>
              <SidebarProvider>
                <MessageProvider>
                  <WorkOrderProvider>{children}</WorkOrderProvider>
                </MessageProvider>
              </SidebarProvider>
            </UserProvider>
          </AuthProvider>
        </RegisterProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};

Providers.propTypes = propTypes;
Providers.defaultProps = defaultProps;

export default Providers;
export {
  defaultTheme as theme,
  propTypes as ProvidersPropTypes,
  defaultProps as ProvidersDefaultProps,
};

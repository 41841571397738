import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ProductsSearchForm, {
  ProductsSearchFormDefaultProps,
  ProductsSearchFormPropTypes,
} from "../ProductsSearchForm";
import AddProductModal, {
  AddProductModalDefaultProps,
  AddProductModalPropTypes,
} from "../AddProductModal";
import ProductsTable, {
  ProductsTableDefaultProps,
  ProductsTablePropTypes,
} from "../ProductsTable";
import DeleteProductModal, {
  DeleteProductModalDefaultProps,
  DeleteProductModalPropTypes,
} from "../DeleteProductModal";
import EditProductModal, {
  EditProductModalDefaultProps,
  EditProductModalPropTypes,
} from "../EditProductModal";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import Importer, { ImporterDefaultProps, ImporterPropTypes } from "../Importer";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeveloperBoardOutlinedIcon from "@material-ui/icons/DeveloperBoardOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkstationSettingsProducts.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./WorkstationSettingsProducts.defaults";

/**
 * Hooks
 */
import {
  useLocalStorage,
  useUser,
  useMessage,
  useApiClient,
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  importer: PropTypes.shape(ImporterPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  productsTable: PropTypes.shape(ProductsTablePropTypes),
  searchForm: PropTypes.shape(ProductsSearchFormPropTypes),
  addProductModal: PropTypes.shape(AddProductModalPropTypes),
  editProductModal: PropTypes.shape(EditProductModalPropTypes),
  deleteProductModal: PropTypes.shape(DeleteProductModalPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  importer: ImporterDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  productsTable: ProductsTableDefaultProps,
  searchForm: ProductsSearchFormDefaultProps,
  addProductModal: AddProductModalDefaultProps,
  editProductModal: EditProductModalDefaultProps,
  deleteProductModal: DeleteProductModalDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  path: "/products",
};

/**
 * Handles returning the props for each tab
 */
const a11yProps = (index) => {
  return {
    id: `products-tab-${index}`,
    "aria-controls": `products-tabpanel-${index}`,
  };
};

/**
 * Displays the component
 */
const WorkstationSettingsProducts = (props) => {
  const {
    importer,
    title,
    wrapper,
    container,
    productsTable,
    defaultSearch,
    searchForm,
    addProductModal,
    deleteProductModal,
    editProductModal,
    loadingBackdrop,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the tab panel
   */
  const [value, setValue] = useState(0);

  /**
   * Initializes the is service flag
   */
  const [isService, setIsService] = useState(false);

  /**
   * Initializes the is expendable flag
   */
  const [isExpendable, setIsExpendable] = useState(false);

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the updated flag that will trigger a search
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the tab changed flag
   */
  const [tabChanged, setTabChanged] = useState(false);

  /**
   * Initializes the inputs state
   */
  const [inputs, setInputs] = useState({});

  /**
   * Initializes the changed value state of the tabs
   */
  const [valueChanged, setValueChanged] = useState(false);

  /**
   * Initializes the reset filters flag
   */
  const [resetFilters, setResetFilters] = useState(false);

  /**
   * Initializes the state that hold the data for the modals
   */
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});

  const [defaultSearchDone, setDefaultSearchDone] = useState(false);

  /**
   * Initializes the state related to the Pagination
   */
  const [pageSize, setPageSize] = useLocalStorage("productsViewPageSize");
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    addProduct: false,
    editProduct: false,
    deleteProduct: false,
  });

  /**
   * Handles searching for products / services
   */
  const searchProducts = async (data) => {
    try {
      const products = await apiClient.post("/products/search", data);

      if (products) {
        const { data } = products;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setUpdated(false);
        setTabChanged(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, products: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
        setDefaultSearchDone(true);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setUpdated(false);
      setDefaultSearchDone(true);
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles opening the edit modal
   */
  const openEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const openDelete = (props) => setDeleteData(props);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAddProduct = () => handleOpenModal("addProduct");
  const closeAddProduct = () => handleCloseModal("addProduct");

  /**
   * Defines the edit User modal handlers
   */
  const openEditProduct = () => handleOpenModal("editProduct");
  const closeEditProduct = () => handleCloseModal("editProduct");

  /**
   * Defines the delete User modal handlers
   */
  const openDeleteProduct = () => handleOpenModal("deleteProduct");
  const closeDeleteProduct = () => handleCloseModal("deleteProduct");

  /**
   * Gets the inputs
   */
  const { orderBy, orderDir } = inputs;

  /**
   * Handles rendering the tabs
   */
  const renderTabs = () => {
    const tabs = [
      { uuid: "physical_products", name: t("physical_products") },
      { uuid: "service_products", name: t("service_products") },
      { uuid: "expendable_products", name: t("expendable_products") },
    ];

    return (
      tabs &&
      tabs.map((tab, idx) => {
        return <Tab key={tab.uuid} label={tab.name} {...a11yProps(idx)} />;
      })
    );
  };

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles changing the tab value flag
   */
  const handleTabValueChange = () => {
    setValueChanged(true);
    setPageCount(1);
  };

  /**
   * Handles changing the tab value flag
   */
  const handleTabChange = () => {
    setTabChanged(true);
  };

  /**
   * Handles updating the models on tab change
   */
  const handleTabModelUpdate = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setIsService(false);
        setIsExpendable(false);
        break;
      case 1:
        setIsService(true);
        setIsExpendable(false);
        break;
      case 2:
        setIsExpendable(true);
        setIsService(false);
        break;
      default:
        return null;
    }
    handleTabValueChange();
  };

  /**
   * Handles the tab change
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabModelUpdate(newValue);
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (user.products && user.products.length < 1 && pageCount === 0) return;
    setBackdropLoading(true);

    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page,
    };

    searchProducts(data);
    window.scrollTo(0, 0);
  };

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setResetFilters(true);

  /**
   * Handles getting the default model
   */
  const getDefaultModel = (model) => ({ ...defaults.models[model] });

  const getUserOrganizationModelValue = (mode, inputs) => {
    if (mode === "default") return user.organization && user.organization.id;
    if (inputs.organization) {
      const value = inputs.organization !== "all" ? inputs.organization : null;
      return value;
    }
  };

  /**
   * Handles building the search models
   */
  const buildSearchModels = (mode) => {
    /**
     * Gets the dynamic models (tabs affected by which tab is open)
     */
    const serviceModel = getDefaultModel("serviceModel");
    const expendableModel = getDefaultModel("expendableModel");
    const organizationIdModel = getDefaultModel("organizationIdModel");

    /**
     * Updates the model values based on the tabs open
     */
    serviceModel["selected"] = isService;
    expendableModel["selected"] = isExpendable;
    organizationIdModel["selected"] = getUserOrganizationModelValue(
      mode,
      inputs
    );

    let defaultModels = [
      { ...organizationIdModel },
      { ...serviceModel },
      { ...expendableModel },
    ];

    if (mode === "default") return defaultModels;

    const filteredModels = defaultSearch.models.filter(
      (model) =>
        model.field !== "is_service" &&
        model.field !== "is_expendable" &&
        model.field !== "organization_id"
    );

    let inputModels = Array.isArray(models) ? models : [];

    if (inputModels.length > 0)
      inputModels = inputModels.filter(
        (model) =>
          model.field !== "is_service" &&
          model.field !== "is_expendable" &&
          model.field !== "organization_id"
      );

    return [...filteredModels, ...defaultModels, ...inputModels];
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    /**
     * Constructs the final data object
     */
    const data = {
      ...defaultSearch,
      models: buildSearchModels("default"),
      page_size: pageSize || defaultSearch.page_size,
      page_count: 1,
    };

    return data;
  };

  const handleDownloadError = (error, data) => {
    console.log("error:", error);
    console.log("data:", data);
  };

  /**
   * Handles constructing the data object for the search
   */
  const getSearchParams = (pgCount) => {
    const data = {
      models: buildSearchModels(),
      order_by: inputs.orderBy || defaultSearch.order_by,
      order_dir: inputs.orderDir || defaultSearch.order_dir,
      page_size: pageSize || defaultSearch.page_size,
      page_count: pgCount || 1,
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchProducts(getDefaultSearch());
  };

  /**
   * Handles searching for work orders
   */
  const handleSearch = (pgCount) => {
    const data = getSearchParams(pgCount);
    searchProducts(data);
  };

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    setPageSize(e.target.value);
  };

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEditProduct();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDeleteProduct();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Handles triggering a search if updated
   */
  useEffect(() => {
    if (updated) handleSearch();
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles searching on page change
   */
  useEffect(() => {
    if (defaultSearchDone) {
      window.scrollTo(0, 0);
      handleSearch();
    }
    // eslint-disable-next-line
  }, [pageSize]);

  /**
   * Handles performing a search on tab change
   */
  useEffect(() => {
    if (valueChanged) {
      setBackdropLoading(true);
      handleTabChange();
      setValueChanged(false);
    }
  }, [valueChanged]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    if (user.organization) {
      setModels(defaultSearch.models);
      handleDefaultSearch();
    }

    // eslint-disable-next-line
  }, [user.organization]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<DeveloperBoardOutlinedIcon />}
        title={t("titleProducts")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            <div className={classes.root}>
              <AppBar
                position="static"
                color="default"
                className={classes.appbar}
              >
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="standard"
                >
                  {renderTabs()}
                </Tabs>
              </AppBar>
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <ProductsSearchForm
                  {...searchForm}
                  activeTab={value}
                  inputs={inputs}
                  setInputs={setInputs}
                  setModels={setModels}
                  setTotal={setTotal}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  setPageCount={setPageCount}
                  setBackdropLoading={setBackdropLoading}
                  isService={isService}
                  isExpendable={isExpendable}
                  tabChanged={tabChanged}
                  setTabChanged={setTabChanged}
                  setUpdated={setUpdated}
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                  handleDefaultSearch={handleDefaultSearch}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.products}>
              <Importer {...importer} onDownloadError={handleDownloadError} />
              <ProductsTable
                {...productsTable}
                isService={isService}
                isExpendable={isExpendable}
                handleAdd={openAddProduct}
                handleEdit={openEdit}
                handleDelete={openDelete}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleFiltersReset={handleFiltersReset}
                rowsPerPage={Number(pageSize)}
                page={pageCount}
                count={total}
              />
            </Grid>
            <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
            <AddProductModal
              {...addProductModal}
              open={modalState.addProduct}
              closeModal={closeAddProduct}
              organizations={user.organizations}
              setUpdated={setUpdated}
            />
            <EditProductModal
              {...editProductModal}
              open={modalState.editProduct}
              closeModal={closeEditProduct}
              editData={editData}
              setEditData={setEditData}
              setUpdated={setUpdated}
            />
            <DeleteProductModal
              {...deleteProductModal}
              open={modalState.deleteProduct}
              closeModal={closeDeleteProduct}
              deleteData={deleteData}
              setUpdated={setUpdated}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

WorkstationSettingsProducts.propTypes = propTypes;
WorkstationSettingsProducts.defaultProps = defaultProps;

export default WorkstationSettingsProducts;
export {
  propTypes as WorkstationSettingsProductsPropTypes,
  defaultProps as WorkstationSettingsProductsDefaultProps,
};

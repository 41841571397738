import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./AddProductModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./AddProductModal.styles";
import { Typography } from "@material-ui/core";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
    um: PropTypes.string,
    organization_id: PropTypes.string,
    work_order_type_id: PropTypes.string,
    car_type_id: PropTypes.string,
    is_service: PropTypes.bool,
    is_expendable: PropTypes.bool,
    is_count_price: PropTypes.bool,
    description: PropTypes.string
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    name: "",
    price: "",
    um: "",
    organization_id: "",
    work_order_type_id: "",
    car_type_id: "",
    is_service: false,
    is_expendable: false,
    is_count_price: false,
    description: ""
  },
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const AddProductModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    setUpdated
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Initializes the organizations state
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the car types list
   */
  const [carTypes, setCarTypes] = useState([]);

  /**
   * Initializes the work order types list
   */
  const [workOrderTypes, setWorkOrderTypes] = useState([]);

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    resetInputs,
    setInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    organization_id,
    work_order_type_id,
    car_type_id,
    is_service,
    is_expendable,
    is_count_price,
    name,
    um,
    price,
    description
  } = inputs;

  const {
    name: nameError,
    price: priceError,
    um: umError,
    organization_id: organization_idError,
    work_order_type_id: work_order_type_idError,
    car_type_id: car_type_idError
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    closeModal();
  };

  /**
   * Handles creating a new product
   */
  const createProduct = async (data) => {
    try {
      const createdProduct = await apiClient.post("/products", data);
      if (createdProduct) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 600,
          message: t("successMessage")
        });

        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
        origin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    }
  };

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    /**
     * Defines the default values for the select fields
     */
    let defaultOrg, defaultCarType, defaultWorkOrderType;

    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name
        };
      });
      setOrganizations(orgs);
      defaultOrg = orgs[0].value;
    }
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const workorderTypes = user.settings.account.work_order_types;

      setCarTypes([{ id: "none", name: t("none") }, ...carTypes]);
      setWorkOrderTypes([{ id: "none", name: t("none") }, ...workorderTypes]);
      defaultCarType = "";
      defaultWorkOrderType = workorderTypes[0].id;
    }

    if (defaultOrg && defaultCarType && defaultWorkOrderType) {
      setInputs((prevState) => {
        return {
          ...prevState,
          organization_id: defaultOrg,
          car_type_id: defaultCarType,
          work_order_type_id: defaultWorkOrderType
        };
      });
    }

    // eslint-disable-next-line
  }, [user, open]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        organization_id,
        work_order_type_id,
        car_type_id,
        is_service,
        is_expendable,
        is_count_price,
        name,
        um,
        price
      };

      if (description) data["description"] = description;

      createProduct(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the is_expendable flag depending on the is_service flag
   */
  useEffect(() => {
    if (is_service && is_expendable) {
      setInputs((prevState) => {
        return { ...prevState, is_expendable: false };
      });
    }
    // eslint-disable-next-line
  }, [is_service]);

  /**
   * Handles updating the is_service flag depending on the is_expendable flag
   */
  useEffect(() => {
    if (is_expendable && is_service) {
      setInputs((prevState) => {
        return { ...prevState, is_service: false };
      });
    }
    // eslint-disable-next-line
  }, [is_expendable]);

  useEffect(() => {
    if (car_type_id === "none")
      setInputs((prevState) => ({ ...prevState, car_type_id: "" }));
    // eslint-disable-next-line
  }, [car_type_id]);

  useEffect(() => {
    if (work_order_type_id === "none")
      setInputs((prevState) => ({ ...prevState, work_order_type_id: "" }));
    // eslint-disable-next-line
  }, [work_order_type_id]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAddProduct")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container>
                        <Grid container item xs={12} md={9} spacing={1}>
                          <Grid item xs={12} md={6}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                required: true,
                                id: "name",
                                name: "name",
                                value: name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "outlined",
                                InputLabelProps: {
                                  className: classes.label
                                },
                                label: t("nameLabel"),
                                error: nameError,
                                autoFocus: getAutoFocus().name
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Input
                              {...input}
                              type="numeric"
                              className={classes.field}
                              inputNumeric={{
                                required: true,
                                id: "price",
                                name: "price",
                                value: price,
                                onChange: handleInputChange,
                                variant: "outlined",
                                InputLabelProps: {
                                  className: classes.label
                                },
                                label: t("priceLabel"),
                                error: priceError,
                                autoFocus: getAutoFocus().price
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "um",
                                name: "um",
                                value: um,
                                required: true,
                                onChange: handleInputChange,
                                maxSize: 5,
                                variant: "outlined",
                                placeholder: t("piece"),
                                InputLabelProps: {
                                  className: classes.label
                                },
                                label: t("umLabel"),
                                error: umError,
                                autoFocus: getAutoFocus().um
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "organization_id",
                                required: true,
                                name: "organization_id",
                                value: organization_id,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("organizationLabel"),
                                options: organizations,
                                optionLabel: "label",
                                optionValue: "value",
                                error: organization_idError,
                                autoFocus: getAutoFocus().organization_id
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "work_order_type_id",
                                required: true,
                                name: "work_order_type_id",
                                value: work_order_type_id,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("work_order_typeLabel"),
                                options: workOrderTypes,
                                optionLabel: "name",
                                optionValue: "id",
                                error: work_order_type_idError,
                                autoFocus: getAutoFocus().work_order_type_id
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                required: true,
                                id: "car_type_id",
                                name: "car_type_id",
                                value: car_type_id,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("car_typeLabel"),
                                options: carTypes,
                                optionLabel: "name",
                                optionValue: "id",
                                error: car_type_idError,
                                autoFocus: getAutoFocus().car_type_id
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              {...input}
                              type="textarea"
                              className={classes.textarea}
                              inputTextarea={{
                                id: "add_description",
                                name: "description",
                                value: description,
                                onChange: handleInputChange,
                                showCount: true,
                                rows: 6,
                                rowsMax: 14,
                                maxChars: 200,
                                size: "medium",
                                variant: "outlined",
                                label: t("descriptionLabel"),
                                ariaLabel: t("descriptionLabel")
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} md={3} justify="center">
                          <Grid
                            container
                            item
                            xs={12}
                            md={10}
                            justify="flex-end"
                          >
                            <Grid item xs={12} container direction="column">
                              <Typography
                                variant="caption"
                                className={classes.formSection}
                              >
                                {t("config")}
                              </Typography>
                              <Input
                                {...input}
                                type="checkbox"
                                className={classes.field}
                                inputCheckbox={{
                                  name: "is_service",
                                  value: is_service,
                                  onChange: handleInputChange,
                                  variant: "outlined",
                                  labelClass: classes.label,
                                  InputLabelProps: {
                                    className: classes.label
                                  },
                                  showHelper: false,
                                  label: t("is_serviceLabel")
                                }}
                              />
                              <Input
                                {...input}
                                type="checkbox"
                                className={classes.field}
                                inputCheckbox={{
                                  name: "is_expendable",
                                  value: is_expendable,
                                  onChange: handleInputChange,
                                  variant: "outlined",
                                  labelClass: classes.label,
                                  InputLabelProps: {
                                    className: classes.label
                                  },
                                  showHelper: false,
                                  label: t("is_expendableLabel")
                                }}
                              />
                              <Input
                                {...input}
                                type="checkbox"
                                className={classes.field}
                                inputCheckbox={{
                                  name: "is_count_price",
                                  value: is_count_price,
                                  onChange: handleInputChange,
                                  variant: "outlined",
                                  labelClass: classes.label,
                                  InputLabelProps: {
                                    className: classes.label
                                  },
                                  showHelper: false,
                                  label: t("is_count_priceLabel")
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitAdd")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

AddProductModal.propTypes = propTypes;
AddProductModal.defaultProps = defaultProps;

export default AddProductModal;
export {
  propTypes as AddProductModalPropTypes,
  defaultProps as AddProductModalDefaultProps
};

/**
 * External Imports
 */
import "date-fns";
import { sub } from "date-fns";

/**
 * Organization ID Search Model
 */
const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

/**
 * Start Date Search Model
 */
const startDateModel = {
  label: "Start date",
  field: "start_date",
  type: "gte",
  order: 3,
  options: [],
  selected: sub(new Date(), { days: 7 }),
};

/**
 * End Date Search Model
 */
const endDateModel = {
  label: "End date",
  field: "end_date",
  type: "lte",
  order: 4,
  options: [],
  selected: new Date(),
};

/**
 * Defines the default search request body
 */
const defaults = {
  models: {
    organizationIdModel,
    startDateModel,
    endDateModel,
  },
  searchParams: {
    models: [organizationIdModel, startDateModel, endDateModel],
    order_by: "start_date",
    order_dir: "desc",
    page_size: 15,
    page_count: 1,
  },
};

export { defaults };

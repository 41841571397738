import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import TodayIcon from "@material-ui/icons/Today";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import CreateButton from "../CreateButton";
import DeleteButton from "../DeleteButton";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

/**
 * Styles Imports
 */
import { useStyles } from "./OrderItem.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  billing_address: PropTypes.string,
  billing_bank_account_name: PropTypes.string,
  billing_bank_account_number: PropTypes.string,
  billing_name: PropTypes.string,
  billing_reg_number: PropTypes.string,
  billing_vat_number: PropTypes.string,
  created_at: PropTypes.string,
  description: PropTypes.string,
  discount: PropTypes.number,
  id: PropTypes.number,
  module_package: PropTypes.shape({
    currency: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.string,
    name: PropTypes.string,
    order: PropTypes.number,
    price: PropTypes.number,
    uuid: PropTypes.number,
  }),
  promotion_code: PropTypes.string,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  total: PropTypes.number,
  transaction_id: PropTypes.any,
  uuid: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  billing_address: "",
  billing_bank_account_name: "",
  billing_bank_account_number: "",
  billing_name: "",
  billing_reg_number: "",
  billing_vat_number: "",
  created_at: "",
  description: "",
  discount: 0,
  id: 0,
  module_package: {},
  promotion_code: "",
  subtotal: 0,
  tax: 0,
  total: 0,
  transaction_id: "",
  uuid: "",
};

/**
 * Displays the component
 */
const OrderItem = (props) => {
  const {
    onChange,
    expanded,
    billing_address,
    billing_name,
    billing_reg_number,
    billing_vat_number,
    created_at,
    module_package,
    subtotal,
    total,
    tax,
    transaction_id,
    uuid,
    promotion_code,
    discount,
    quantity,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the expansion panel classes
   */

  const expansionPanel = {
    root: {
      root: classes.expansionPanelRoot,
    },
    summary: {
      root: classes.panelSummaryRoot,
      content: classes.panelSummaryContent,
    },
    details: {
      root: classes.panelDetailsRoot,
    },
  };

  /**
   * Handles opening the details panel
   */
  const openDetails = (e) => {
    e.preventDefault();
    onChange(uuid);
  };

  /**
   * Gets the module package props
   */
  const { name, price, currency, duration, description } = module_package;

  /**
   * Defines the label component
   */
  const TableLabel = (props) => {
    const { value } = props;

    return (
      <Grid item xs={6} md={4} className={classes.labelContainer}>
        <Typography variant="caption" className={classes.label}>
          {value}
        </Typography>
      </Grid>
    );
  };

  /**
   * Defines the value component
   */
  const TableValue = (props) => {
    const { value } = props;

    return (
      <Grid item xs={6} md={8} className={classes.valueContainer}>
        <Typography variant="caption" className={classes.value}>
          {value}
        </Typography>
      </Grid>
    );
  };

  /**
   * Defines the package name component
   */
  const PackageName = (props) => {
    const { packageName, description } = props;

    return (
      <Grid item xs={6} md={8}>
        <div className={classes.packageContainer}>
          <Typography variant="caption" className={classes.packageName}>
            {packageName}
          </Typography>
          <Tooltip
            title={description}
            TransitionComponent={Zoom}
            placement="top"
            classes={{
              tooltip: classes.tooltip,
              popper: classes.popper,
            }}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      </Grid>
    );
  };

  /**
   * Handles rendering the promotion code
   */
  const handlePromotionCode = () =>
    promotion_code && (
      <CreateButton
        icon={false}
        text={`${t("promotionLabel")}${promotion_code}`}
      />
    );

  return (
    <ExpansionPanel
      classes={expansionPanel.root}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded === uuid}
    >
      <ExpansionPanelSummary
        classes={expansionPanel.summary}
        aria-controls={`${uuid}-content`}
        id={`${uuid}-header`}
        onClick={openDetails}
      >
        <div className={classes.summary}>
          <Typography className={classes.transactionID}>
            <LabelImportantIcon />
            {t("orderNumber")} {transaction_id ? transaction_id : uuid}
          </Typography>
          <Typography className={classes.createdAt}>
            <TodayIcon />
            {created_at}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={expansionPanel.details}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12} container className={classes.wrapper}>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    className={classes.billingTitle}
                  >
                    {t("orderDetails")}
                  </Typography>
                </Grid>
                <TableLabel value={t("serviceBought")} />
                <PackageName packageName={name} description={description} />
                <TableLabel value={t("priceLabel")} />
                <TableValue value={`${price} ${currency} / luna`} />
                <TableLabel value={t("duration")} />
                <TableValue value={t(duration)} />
                <TableLabel value={t("quantity")} />
                <TableValue value={quantity} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.actions}>
              {handlePromotionCode()}
              <DeleteButton icon={false} text={t("downloadLabel")} />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography className={classes.billingTitle}>
              {t("billingInfo")}
            </Typography>
            <Grid container>
              <Grid item xs={12} container className={classes.wrapper}>
                <TableLabel value={t("companyName")} />
                <TableValue value={billing_name} />
                <TableLabel value={t("VAT")} />
                <TableValue value={billing_vat_number} />
                <TableLabel value={t("REG")} />
                <TableValue value={billing_reg_number} />
                <TableLabel value={t("address")} />
                <TableValue value={billing_address} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.billingTitle}>
              {t("paymentDetails")}
            </Typography>
            <Grid container>
              <Grid item xs={12} container className={classes.wrapper}>
                <TableLabel value={t("subtotal")} />
                <TableValue value={`${subtotal} ${currency}`} />
                <TableLabel value={t("discount")} />
                <TableValue value={`${discount} ${currency}`} />
                <TableLabel value={t("tax")} />
                <TableValue value={`${tax} ${currency}`} />
                <TableLabel value={t("total")} />
                <TableValue value={`${total} ${currency}`} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

OrderItem.propTypes = propTypes;
OrderItem.defaultProps = defaultProps;

export default OrderItem;
export {
  propTypes as OrderItemPropTypes,
  defaultProps as OrderItemDefaultProps,
};

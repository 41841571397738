import React, { useState, useEffect } from "react";

/**
 * Component Imports
 */
import DataTable from "../DataTable";
import WorkOrderTable from "../WorkOrderTable";
import WorkOrderWorkers from "../WorkOrderWorkers";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

/**
 * Hooks
 */
import { useTranslation } from "react-i18next";
import { useUser, useWorkOrder, useTimer } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreServiceCreateStep3.styles";

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Displays the component
 */
const TyreServiceCreateStep3 = (props) => {
  const { plate, offset } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /** 
   * Gets the component styles
   */
  const classes = useStyles();
 
  /**
   * Gets the work order hook
   */
  const { workOrder, workOrderType } = useWorkOrder();

  /**
   * Initializes the timer
   */
   const {
    getCurrentTime
  } = useTimer(`${plate}_newTimer`, {
    open: true,
    offsetTimestamp: offset,
  });


  /**
   * Initializes the data state
   */
  const [data, setData] = useState({});

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles initializing the tables data
   */
  const initTableData = (size) => {
    const result = Array(size).fill({ label: "", value: "", render: true });
    return result;
  };

  /**
   * Intializes the tables data
   */
  const [orderData, setOrderData] = useState(initTableData(3));
  const [hotelData, setHotelData] = useState(initTableData(3));
  const [clientData, setClientData] = useState(initTableData(3));
  const [carData, setCarData] = useState(initTableData(10));
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [workers, setWorkers] = useState([]);
  const [products, setProducts] = useState([]);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Handles building the tyre dimensions string
   * width / height R rim
   */
  const buildTyreDimString = (tyre_dimensions) => {
    const { tyre_width, tyre_height, tyre_rim } = tyre_dimensions;

    if (!tyre_width || !tyre_height || !tyre_rim) return " - ";

    return `${tyre_width} / ${tyre_height} R${tyre_rim}`;
  };

  const getWorkOrderTypeName = (id) => {
    if (user.settings) {
      const workOrderTypes = user.settings.account.work_order_types;
      const found = workOrderTypes.find((type) => type.id === id);
      return found ? found.name : "";
    }
  };

  const getCarTypeName = (id) => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const found = carTypes.find((type) => type.id === id);
      return found ? found.name : "";
    }
  };

  const getWheelTypeName = (id) => {
    if (user.settings) {
      const wheelTypes = user.settings.account.wheel_types;
      const found = wheelTypes.find((type) => type.id === id);
      return found ? found.name : "";
    }
  };

  /**
   * Halves an array in 2
   */
  const sliceArray = (array, parts, returnedPart) => {
    const sliceCount = Math.ceil(array.length / parts);
    if (returnedPart === 1) {
      return array.slice(0, sliceCount);
    } else {
      return array.slice(-sliceCount);
    }
  };

  /**
   * Defines the table title component
   */
  const TableTitle = (props) => {
    const { title } = props;

    return (
      <Typography variant="caption" className={classes.tableTitle}>
        {title}
      </Typography>
    );
  };

  /**
   * Builds the data tables if the api call is resolved
   */
  useEffect(() => {
    if (ready && Object.keys(data).length > 0) {
      /**
       * Defines the base render condition
       * Some data is rendered conditionally
       */
      const render = true;

      /**
       * Gets the data
       */
      const {
        car,
        products,
        tyre_service,
        work_order,
        workers,
        tyre_hotel,
      } = data;

      const {
        work_order_type,
        client_name,
        delegate_name,
        description,
        // eslint-disable-next-line
        specialDiscount,
      } = work_order;

  
      const { bolt_torque, car_km, new_tyre, tyre_pressure, wheel_type } =
        tyre_service;

      const {
        car_description,
        make,
        model,
        plate_number,
        car_type,
        vin_number,
        manufacturing_year,
      } = car;

      /**
       * Builds the tyre dimensions
       */
      const tyreDimensions = buildTyreDimString(tyre_service);

      const orderData = [
        {
          label: t("workOrderType"),
          value: getWorkOrderTypeName(workOrderType),
          render,
        },
        { label: t("description"), value: description, render },
      ];

      /**
       * Defines the order meta data
       */
      let hotelData = [];

      if (tyre_hotel) {
        hotelData = [
          {
            label: t("tyreDimensions"),
            value: buildTyreDimString(tyre_hotel),
            render,
          },
          { label: t("tyre_name"), value: tyre_hotel.tyre_name, render },
          {
            label: t("tyre_quantity"),
            value: tyre_hotel.tyre_quantity,
            render,
          },
          {
            label: t("tyreHotelDescription"),
            value: tyre_hotel.description,
            render,
          },
        ];
      }

      /**
       * Defines the client data
       */
      const clientData = [
        { label: t("clientName"), value: client_name, render },
        { label: t("delegate"), value: delegate_name, render },
      ];

      /**
       * Defines the car data
       */
      const carData = [
        { label: t("carPlate"), value: plate_number, render },
        { label: t("carMake"), value: make, render },
        { label: t("carModel"), value: model, render },
        { label: t("carType"), value: getCarTypeName(car_type), render },
        { label: t("wheel_type"), value: getWheelTypeName(wheel_type), render },
        { label: t("carDescription"), value: car_description, render },
      ];

      if (getWorkOrderTypeName(work_order_type) === "Service Auto") {
        carData.push({
          label: t("vin_number"),
          value: vin_number,
          render: !!vin_number,
        });
        carData.push({ label: t("carKm"), value: car_km, render });
        carData.push({
          label: t("manufacturing_yearLabel"),
          value: manufacturing_year,
          render,
        });
      }

      if (getWorkOrderTypeName(work_order_type) === "Vulcanizare") {
        carData.push({ label: t("newTyre"), value: t(new_tyre), render });
        carData.push({
          label: t("vin_number"),
          value: vin_number,
          render: !!vin_number,
        });
        carData.push({ label: t("carKm"), value: car_km, render });
        carData.push({
          label: t("tyreDimensions"),
          value: tyreDimensions,
          render,
        });
        carData.push({
          label: t("tyrePressure"),
          value: tyre_pressure,
          render,
        });
        carData.push({ label: t("boltTorque"), value: bolt_torque, render });
      }

      /**
       * Updates the state
       */
      setSpecialDiscount(props.specialDiscount);
      setWorkers(workers);
      setHotelData(hotelData);
      setOrderData(orderData);
      setClientData(clientData);
      setCarData(carData);
      setReady(false);
      setProducts(products);
    }
    // eslint-disable-next-line
  }, [ready, data]);

  useEffect(() => {
    if (Object.keys(workOrder).length > 0) {
      setData(workOrder);
      setReady(true);
    }
  }, [workOrder]);

  return (
    <div>
      <Card className={classes.stepCard}>
        <CardContent className={classes.cardContent}>
          <Grid container direction="column" justify="center">
            <div className={classes.titleContainer}>
              <Typography variant="h1" className={classes.title}>
                {t("workOrder")}
              </Typography>
              <div className={classes.duration}>{getCurrentTime()}</div>
            </div>
          </Grid>
          <Grid container spacing={2} justify="center" className={classes.box}>
            <Grid item container xs={12} sm={6}>
              <Grid item container xs={12} direction="column">
                <TableTitle title={t("orderData")} />
                <DataTable data={orderData} />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item container xs={12} direction="column">
                <TableTitle title={t("clientData")} />
                <DataTable data={clientData} />
              </Grid>
            </Grid>
            {hotelData.length > 0 && (
              <Grid item container xs={12} sm={12}>
                <Grid item container xs={12} direction="column">
                  <TableTitle title={t("hotelData")} />
                  <DataTable data={hotelData} />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} className={classes.spacing}>
                <TableTitle title={t("car")} />
              </Grid>
              <Grid item container xs={6} sm={6}>
                <DataTable
                  className={classes.spacing}
                  data={sliceArray(carData, 2, 1)}
                />
              </Grid>
              <Grid item container xs={6} sm={6}>
                <DataTable
                  className={classes.spacing}
                  data={sliceArray(carData, 2, 2)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <WorkOrderWorkers workers={workers} />
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center" className={classes.box}>
            <Grid container className={classes.boxItem}>
              <WorkOrderTable
                title={t("products")}
                data={{
                  items: products
                    ? products.map((product) => {
                        return {
                          ...product,
                          original_product: {
                            is_count_price: product ? product.price > 0 : false,
                          },
                        };
                      })
                    : [], 
                  specialDiscount,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

TyreServiceCreateStep3.propTypes = propTypes;
TyreServiceCreateStep3.defaultProps = defaultProps;

export default TyreServiceCreateStep3;
export {
  propTypes as TyreServiceCreateStep3PropTypes,
  defaultProps as TyreServiceCreateStep3DefaultProps,
};

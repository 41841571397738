/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 660,
  },
  modulesContainer: {
    margin: "0.5rem 0",
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem",
    },
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  label: {
    fontFamily: theme.font.display,
    color: theme.palette.secondary.main,
    fontWeight: 800,
    fontSize: "0.95rem",
    marginBottom: "0.25rem",
    display: "inline",
    wordBreak: "break-all",
    width: "100%",
    marginLeft: "0.5rem",
  },
  value: {
    fontFamily: theme.font.display,
    color: theme.palette.error.main,
    fontWeight: 800,
    fontSize: "0.95rem",
    marginBottom: 0,
    paddingLeft: "0.35rem",
  },
  field: {
    ...theme.mixins.searchField,
    marginTop: "1rem",
  },
  inputLabel: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  submitBtn: theme.mixins.submitBtn,
}));

export { useStyles };

import React, {Fragment} from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderDisclaimer.styles";
 
/**
 * Defines the prop types
 */
const propTypes = {
  className: PropTypes.string,
  work_order_type: PropTypes.object
};

/**
 * Defines the default props
 */
const defaultProps = {
  className: "",
  work_order_type: {}
};

/**
 * Displays the component
 */
const WorkOrderDisclaimer = (props) => {
  const { className, work_order_type } = props;
  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const getDisclaimerContent = () => {
    switch(true){
      case work_order_type.name === "Vulcanizare":
         return (
          <Fragment>
            <Typography variant="caption" className={classes.title}>
              {t("tyreServiceDisclaimerTitle")}
            </Typography>
            <Typography variant="caption" className={classes.text}>
              {t("tyreServiceDisclaimerText1")}
            </Typography>
            <Typography variant="caption" className={classes.text}>
              {t("tyreServiceDisclaimerText2")}
            </Typography>
            <Typography variant="caption" className={classes.textBold}>
              {t("tyreServiceDisclaimerText3")}
            </Typography>
              <Typography variant="caption" className={classes.textBold}>
                  {t("tyreServiceDisclaimerText4")}
              </Typography>
          </Fragment>
         )
        case work_order_type.name === "Service Auto":
          return (
            <Fragment>
              <Typography variant="caption" className={classes.title}>
                {t("carServiceDisclaimerTitle")}
              </Typography>
              <Typography variant="caption" className={classes.text}>
                {t("carServiceDisclaimerText1")}
              </Typography>
              <Typography variant="caption" className={classes.text}>
                {t("carServiceDisclaimerText2")}
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                {t("guaranteeConditions")}
              </Typography>
              <div className={classes.sectionContainer}>
                 <Typography variant="caption" className={classes.textContainer}>
                   {t("ourUnitGrantsGuarantee")}
                   <ul>
                     <li> 
                       {t("forProducts")}
                       <ul>
                         <li>{t("toIndividuals24Months")}</li>
                         <li>{t("toCompanies12Months")}</li>
                         <li>{t("toCompaniesExceptions6Months")}</li>
                       </ul>
                     </li>
                     <li>{t("workGuarantee3Months")}</li>
                   </ul>
                 </Typography>
              </div>
              <Typography variant="caption" className={classes.text}>
                {t("carServiceDisclaimerText3")}
              </Typography>
              <Typography variant="caption" className={classes.text}>
                {t("carServiceDisclaimerText4")}
              </Typography>
            </Fragment>
          )
        default:
          return null;
    }
  }

  return (
    <div
      className={clsx(classes.disclaimer, {
        [className]: !!className,
      })}
    >
     {getDisclaimerContent()}
    </div>
  );
};

WorkOrderDisclaimer.propTypes = propTypes;
WorkOrderDisclaimer.defaultProps = defaultProps;

export default WorkOrderDisclaimer;
export {
  propTypes as WorkOrderDisclaimerPropTypes,
  defaultProps as WorkOrderDisclaimerDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import {
  format as formatDate,
  formatDistanceStrict,
  differenceInDays
} from "date-fns";
import { ro } from "date-fns/locale";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Tooltip, { TooltipDefaultProps, TooltipPropTypes } from "../Tooltip";
import SmallModuleCards, {
  SmallModuleCardsDefaultProps,
  SmallModuleCardsPropTypes,
} from "../SmallModuleCards";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import CompanyContactDataBox, {
  CompanyContactDataBoxDefaultProps,
  CompanyContactDataBoxPropTypes,
} from "../CompanyContactDataBox";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DonutSmallOutlinedIcon from "@material-ui/icons/DonutSmallOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import TodayIcon from "@material-ui/icons/Today";
import BusinessIcon from "@material-ui/icons/Business";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsMain.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  tooltip: PropTypes.shape(TooltipPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  cards: PropTypes.shape(SmallModuleCardsPropTypes),
  companyData: PropTypes.shape(CompanyContactDataBoxPropTypes),
  dateFormat: PropTypes.string,
  dateFormatOptions: PropTypes.object,
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  tooltip: TooltipDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  cards: SmallModuleCardsDefaultProps,
  companyData: CompanyContactDataBoxDefaultProps,
  dateFormat: "dd MMMM yyyy",
  dateFormatOptions: { locale: ro },
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: "/",
};

/**
 * Displays the component
 */
const AccountSettingsMain = (props) => {
  const {
    tooltip,
    title,
    wrapper,
    container,
    cards,
    companyData,
    dateFormat,
    dateFormatOptions,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the time left state
   */
  const [timeLeft, setTimeLeft] = useState(0);

  /**
   * Initializes the module cards
   */
  const [modules, setModules] = useState([]);

  /**
   * Initializes the suspended acc state
   */
  const [isSuspended, setIsSuspended] = useState(false);

  /**
   * Gets the user
   */
  const { user } = useUser();

  /**
   * Builds the date interval string
   */
  const buildDateInterval = () => {
    if (user.settings) {
      /**
       * Converts the string to date objects
       */
      const activeFromDate = new Date(user.settings.account.active_from);
      const activeToDate = new Date(user.settings.account.active_to);

      /**
       * Formats the dates
       */
      const active_from = formatDate(
        activeFromDate,
        dateFormat,
        dateFormatOptions
      );
      const active_to = formatDate(activeToDate, dateFormat, dateFormatOptions);

      /**
       * Builds the string
       */
      return `${active_from} - ${active_to}`;
    }
  };

  /**
   * Calculates the time left for the service
   */
  const buildDateTimeLeft = () => {
    if (user.settings) {
      /**
       * Converts the string to date objects
       */
      const activeToDate = new Date(user.settings.account.active_to);
      const today = new Date();

      /**
       * Calculates the time left
       */
      const timeLeftResult = formatDistanceStrict(
        today,
        activeToDate,
        dateFormatOptions
      );
 
      return `${timeLeftResult} ${t("left")}`
 
    }
  };

  /**
   * Gets the user modules
   */
  const getUserModules = () => (user.settings ? user.settings.modules : []);

  /**
   * Handles getting the active package name
   */
  const getActivePackageData = () => {
    if (user.settings) {
      const { settings } = user;
      const { active_package } = settings;
      const { name, description } = active_package;

      return {
        name: name ? name : "",
        description: description ? description : "",
      };
    }
  };

  /**
   * Gets the company name
   */
  const getCompanyName = () => (user.account ? user.account.name : "");

  /**
   * Gets the user's organization data based on field
   */
  const getUserData = (field) =>
    user && user.organization && user.organization[field]
      ? user.organization[field]
      : "";

  /**
   * Checks if the user is suspended
   */
  const isUserSuspended = () =>
    user.account ? user.account.status === "suspended" : false;

  /**
   * Handles getting the account plan value
   */
  const getAccountPlan = () => {
    /**
     * Gets the active package
     */
    const activePackage = getActivePackageData();

    if (activePackage) {
      const { name, description } = activePackage;

      return (
        <Typography variant="caption" className={classes.accountPlan}>
          {name}
          <Tooltip
            {...tooltip}
            title={description}
            icon={<HelpIcon className={classes.packageIcon} />}
          />
        </Typography>
      );
    }
  };

  /**
   * Handles getting the account status value
   */
  const getAccountStatus = () => {
    /**
     * Defines the account status Icon component
     */
    const icon = isSuspended ? (
      <WarningIcon className={classes.warning} />
    ) : (
      <CheckCircleIcon className={classes.checked} />
    );

    const status = user && user.account && user.account.status;

    return (
      <Typography variant="caption" className={classes.accountStatus}>
        {t(status)}
        {icon}
      </Typography>
    );
  };

  /**
   * Defines a component that acts as a container with display flex/column
   * Created for readability
   */
  const FlexColumn = ({ children }) => (
    <div className={classes.flexColumn}>{children}</div>
  );

  /**
   * Defines a component that acts as the section title
   * Created for readability
   */
  const SectionTitle = ({ title }) => (
    <div className={classes.itemContainer}>
      <Typography variant="caption" className={classes.label}>
        {title}
      </Typography>
    </div>
  );

  /**
   * Defines the time left classes
   */
  const timeLeftClasses = clsx({
    [classes.timeLeft]: true,
    [classes.redTag]: timeLeft < 5,
    [classes.warningTag]: timeLeft > 5 && timeLeft <= 15,
    [classes.greenTag]: timeLeft > 15,
  });

  /**
   * Updates the state based on changes occuring to the global user
   */
  useEffect(() => {
    const { account, settings } = user;

    if (account) {
      const isSuspended = isUserSuspended();

      setIsSuspended(isSuspended);
    }

    if (settings) {
      const modules = getUserModules();

      setModules(modules);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user.settings) {
      /**
       * Converts the string to date objects
       */
      const activeToDate = new Date(user.settings.account.active_to);
      const today = new Date();
 
      /**
       * Gets the difference in days
       */
      const timeLeftNumber = differenceInDays(activeToDate, today);

  
      setTimeLeft(timeLeftNumber)
    }
  },[user.settings])

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<DonutSmallOutlinedIcon />}
        title={t("titleDashboard")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container>
          <Grid container item xs={12} md={12} className={classes.box}>
            <Grid item xs={12}>
              <FlexColumn>
                <Typography variant="caption" className={classes.title}>
                  {t("generalInfo")}
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <FlexColumn>
                      <SectionTitle title={t("accountData")} />
                      <CompanyContactDataBox
                        {...companyData}
                        value={getCompanyName()}
                        icon={<BusinessIcon />}
                        title={t("company")}
                      />
                      <CompanyContactDataBox
                        {...companyData}
                        value={getAccountPlan()}
                        icon={<DonutLargeIcon />}
                        title={t("activeService")}
                      />
                      <CompanyContactDataBox
                        {...companyData}
                        value={getAccountStatus()}
                        icon={<EqualizerIcon />}
                        title={t("accountStatus")}
                      />
                       <CompanyContactDataBox
                        {...companyData}
                        value={buildDateInterval()}
                        icon={<TodayIcon />}
                        title={t("serviceInterval")}
                      />
                       <CompanyContactDataBox
                        {...companyData}
                        className={timeLeftClasses}
                        value={buildDateTimeLeft()}
                        icon={<AccessAlarmIcon />}
                        title={t("timeLeft")}
                      />
                    </FlexColumn>
                  </Grid>
                  <Grid item xs={6}>
                    <FlexColumn>
                      <SectionTitle title={t("contactData")} />
                      <CompanyContactDataBox
                        {...companyData}
                        value={getUserData("email")}
                        icon={<EmailIcon />}
                        title={t("email")}
                      />
                      <CompanyContactDataBox
                        {...companyData}
                        value={getUserData("phone")}
                        icon={<PhoneIcon />}
                        title={t("phone")}
                      />
                      <CompanyContactDataBox
                        {...companyData}
                        value={getUserData("address")}
                        icon={<LocationOnIcon />}
                        title={t("address")}
                      />
                    </FlexColumn>
                  </Grid>
                </Grid>
              </FlexColumn>
           
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} className={classes.box}>
            <Grid item xs={12}>
              <SmallModuleCards {...cards} modules={modules} />
            </Grid>
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

AccountSettingsMain.propTypes = propTypes;
AccountSettingsMain.defaultProps = defaultProps;

export default AccountSettingsMain;
export {
  propTypes as AccountSettingsMainPropTypes,
  defaultProps as AccountSettingsMainDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modalContent: {
    maxHeight: 660
  },
  modulesContainer: {
    margin: "0.5rem 0"
  },
  modules: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "100%",
    marginTop: "5rem",
    backgroundColor: theme.palette.common.white
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
      paddingBottom: "5rem"
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "1rem",
      paddingBottom: "5rem"
    }
  },
  form: {
    background: "transparent",
    boxShadow: "none"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "0.5rem 0",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  submitBtn: theme.mixins.submitBtn
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  spinner: {
    color: theme.palette.common.white
  },
  text: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem"
    },
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit"
  }
}));

export { useStyles };

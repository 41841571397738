/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: 800,
    fontFamily: theme.font.display,
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem"
    }
  },
  pos: {
    marginBottom: 12
  },
  logoContainer: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
    "& img": {
      [theme.breakpoints.down("xl")]: {
        maxWidth: 100
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: 75
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 45
      }
    }
  },
  lgImgContainer: {
    "& img": {
      [theme.breakpoints.down("xl")]: {
        maxWidth: 450
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: 350
      }
    }
  },
  appTitle: {
    color: theme.palette.primary.main,
    fontSize: "2.6rem",
    fontWeight: 800,
    fontFamily: theme.font.display,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem"
    }
  },
  paragraph: {
    color: theme.palette.common.black,
    padding: "0.5rem 0",
    fontSize: "1.4rem",
    fontFamily: theme.font.family
  },
  greetingContainer: {
    background: theme.palette.common.white,
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    // minHeight: "100vh",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minHeight: "20vh"
    }
  },
  messageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  greeting: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
    padding: "1.5rem"
  },
  formContainer: {
    background: theme.palette.primary.main,
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    // minHeight: "100vh",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0 2rem"
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 1rem"
    }
  },
  form: {
    padding: "1.5rem",
    background: theme.palette.common.white,
    borderRadius: 15,
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 0"
    }
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "1rem"
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  footerDocumentLinks: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    "& .MuiTypography-caption": {
      margin: "0 0.5rem",
      fontWeight: 800,
      fontSize: "0.8rem"
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flex: 1,
      flexDirection: "column"
    }
  },
  footerCopyright: {
    "& .MuiTypography-caption": {
      fontWeight: 800,
      fontSize: "0.8rem"
    }
  }
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FixedSizeList } from "react-window";

/**
 * Hooks
 */
import { useForm, useApiClient, useMessage } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkOrderServiceModal.styles";

/**
 * Validations Import
 */
import Validator from "./EditWorkOrderServiceModal.validations";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  form: PropTypes.shape(FormPropTypes),
  input: PropTypes.shape(InputPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  defaultValues: PropTypes.shape({
    organization_product_id: PropTypes.string,
    work_order_id: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    total: PropTypes.number,
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  data: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  loadingText: LoadingTextDefaultProps,
  form: FormDefaultProps,
  input: InputDefaultProps,
  button: ButtonDefaultProps,
  defaultValues: {
    organization_product_id: "",
    work_order_id: "",
    is_count_price: false,
    name: "",
    quantity: 1,
    price: 0,
    total: 0,
  },
  open: false,
  closeModal: () => {},
  data: {},
};

/**
 * Displays the component
 */
const EditWorkOrderServiceModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    button,
    loadingText,
    form,
    input,
    editData,
    defaultValues,
    products,
    errorMessages,
    woProduct,
    workOrder,
    setServices,
    mainServices
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    closeModal();
  };

  /**
   * Initializes the loading state
   */
  const [activeProduct, setActiveProduct] = useState("");
  const [initMount, setInitMount] = useState(true);

  /**
   *  Initializes the open form state (qty / price / total)
   */
  const [search, setSearch] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [localProducts, setLocalProducts] = useState([]);

  const { name: productName } = editData;

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    /**
     * Formats the inputs
     */
    const quantity = parseInt(inputs.quantity);
    const total = parseFloat(inputs.total).toFixed(2);
    const price = total / quantity;

    /**
     * Defines the data object
     */

    const data = {
      name: inputs.name,
      quantity,
      price,
      total: parseFloat(total),
    };
    updateWorkOrderProduct(data, woProduct.id);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    hasErrors,
    setInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { name, quantity, price, total } = inputs;
  const { price: priceError } = errors;

  /**
   * Handles updating a work order product by id
   */
  const updateWorkOrderProduct = async (data, id) => {
    try {
      const updatedProduct = await apiClient.put(
        `/work-order-products/${id}`,
        data
      );
      if (updatedProduct) {
        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          message: t("successMessage"),
        });

        const newServices = [];
        
        mainServices.forEach(product => {
          if(product.id === id){
            newServices.push({
               ...product,
               ...data
             })
          } else {
            newServices.push(product);
          }
        })

        setServices(newServices);

        handleCloseModal();
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles incrementing the quantity
   */
  const handleAddition = () => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        quantity: (Number(prevInputs.quantity) + 1).toString(),
      };
    });
  };

  /**
   * Handles decrementing the quantity
   */
  const handleSubstraction = () => {
    if (quantity <= 1) return;
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        quantity: (Number(prevInputs.quantity) - 1).toString(),
      };
    });
  };

  const handleProductSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length < 1) {
      setLocalProducts(allProducts);
    } else {
      setLocalProducts(
        allProducts.filter((product) =>
          product.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    setLocalProducts(products);
    setAllProducts(products);
    // eslint-disable-next-line
  }, [products]);

  /**
   * Handles formatting and updating the total value
   */
  const updateTotal = (quantity, price) => {
    const q = parseFloat(quantity).toFixed(2);
    const p = parseFloat(price).toFixed(2);

    if (Number.isNaN(q)) return;
    if (Number.isNaN(p)) return;
    const result = (q * p).toFixed(2);
    return result === "NaN" ? -1 : result;
  };

  /**
   * Handles updating the total when quantity or price change
   */
  useEffect(() => {
    const totalString = updateTotal(quantity, price);
    const total = parseFloat(totalString).toFixed(2);

    if (total >= 0) {
      setInputs((prevState) => {
        return {
          ...prevState,
          total,
        };
      });
    }
    // eslint-disable-next-line
  }, [quantity, price]);

  /**
   * Resets the form if the name of the product is blank
   */
  useEffect(() => {
    if (name.length < 1) {
      setInputs((prevState) => {
        return {
          ...prevState,
          quantity: 1,
          price: 0,
          total: 0,
        };
      });
    }
    // eslint-disable-next-line
  }, [name]);

  /**
   * Handles deciding if the submit button is disabled
   */
  const getDisabledState = () => {
    return hasErrors(errors) || name.length < 1 || price < 0;
  };

  const activateProduct = (product) => {
    setSearch(`${product.name} (${product.price} RON)`);
    setActiveProduct(product);
  };

  const renderProducts = (props) => {
    const { index, style, data } = props;

    const item = data[index];

    const isActive = search === `${item.name} (${item.price} RON)`;

    const last = item.lastFromUser;

    const handleActivation = () => activateProduct(item);

    return (
      <ListItem
        button
        style={style}
        key={index}
        onClick={handleActivation}
        className={clsx(isActive ? classes.active : "", {
          [classes.fromUser]: last,
        })}
      >
        <ListItemText primary={`${item.name} (${item.price} RON)`} />
      </ListItem>
    );
  };

  /**
   * Initializes the input timer
   */
  let inputTimer = React.useRef(null);
  useEffect(() => {
    if (open && initMount) {
      clearTimeout(inputTimer.current);
      inputTimer.current = setTimeout(() => {
        const product = products.find(
          (product) =>
            product.id === activeProduct.organization_product_id ||
            product.id === activeProduct.id
        );

        setInputs((prevState) => {
          return {
            ...prevState,
            is_service: product ? product.is_service : false,
            total: product ? (product.price * quantity).toString() : 0,
            price: product ? product.price.toString() : 0,
            is_count_price: product ? product.price > 0 : false,
            organization_product_id: product ? product.id : "",
          };
        });
        setInitMount(false);
      }, [300]);
    }
    // eslint-disable-next-line
  }, [activeProduct, open]);

  useEffect(() => {
    if (open && !initMount) {
      clearTimeout(inputTimer.current);
      inputTimer.current = setTimeout(() => {
        const product = products.find(
          (product) =>
            product.id === activeProduct.organization_product_id ||
            product.id === activeProduct.id
        );

        setInputs((prevState) => {
          return {
            ...prevState,
            is_service: product ? product.is_service : false,
            total: product ? (product.price * quantity).toString() : 0,
            price: product ? product.price.toString() : 0,
            name: product ? product.name : "",
            is_count_price: product ? product.price > 0 : false,
            organization_product_id: product ? product.id : "",
          };
        });
      }, [300]);
    }
    // eslint-disable-next-line
  }, [activeProduct, open]);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      setInputs((prevState) => {
        return {
          ...prevState,
          name: editData.name,
          price: editData.price ? editData.price : "0",
          total: editData.total ? editData.total : "0",
          quantity: editData.quantity,
          organization_product_id: editData.id,
          work_order_id: workOrder.id,
        };
      });
      setTimeout(() => {
        activateProduct(editData);
      }, 300);
    }
    // eslint-disable-next-line
  }, [editData]);

  return (
    <Modal
      {...modal}
      fullScreen={triggerFullScreen}
      maxWidth="sm"
      open={open}
      onClose={handleCloseModal}
      keepMounted={false}
      scroll="paper"
    >
      <ModalTitle
        {...modalTitle}
        title={`${t("titleEditService")} ${productName}`}
        onClick={handleCloseModal}
      />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid item container xs={12}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank}>
                <CardContent className={classes.content}>
                  <Form
                    {...form}
                    onSubmit={handleSubmit}
                    id="add-work-order-product-modal"
                  >
                    <Zoom in={true}>
                      <Grid container justify="center">
                        <Grid item xs={12}>
                          <div className={classes.formContainer}>
                            <Button
                              {...button}
                              type="button"
                              variant="filled"
                              className={classes.substraction}
                              onClick={handleSubstraction}
                            >
                              <RemoveIcon className={classes.icon} />
                            </Button>

                            <Typography
                              variant="caption"
                              className={classes.qtyLabel}
                            >
                              {t("quantityLabel")}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.qtyValue}
                            >
                              {quantity}
                            </Typography>
                            <Button
                              {...button}
                              type="button"
                              variant="filled"
                              className={classes.addition}
                              onClick={handleAddition}
                            >
                              <AddIcon className={classes.icon} />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Zoom>
                    <Zoom in={true}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              required: true,
                              id: "price",
                              name: "price",
                              value: price,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("priceLabel"),
                              error: priceError,
                              autoFocus: getAutoFocus().price,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              required: true,
                              id: "total",
                              name: "total",
                              value: total,
                              disabled: true,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("totalLabel"),
                              showHelper: false,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Zoom>
                    <Grid item xs={12} container direction="column">
                      <Input
                        {...input}
                        type="text"
                        className={classes.nameField}
                        inputText={{
                          id: "name",
                          required: true,
                          name: "name",
                          value: name,
                          onChange: handleInputChange,
                          variant: "outlined",
                          label: t("serviceNameLabel"),
                          showHelper: false,
                        }}
                      />
                      <Input
                        {...input}
                        type="text"
                        className={classes.field}
                        inputText={{
                          id: "search",
                          required: true,
                          name: "search",
                          value: search,
                          onChange: handleProductSearch,
                          variant: "outlined",
                          label: t("searchServiceNameLabel"),
                          showHelper: false,
                        }}
                      />
                      <div className={classes.list}>
                        <FixedSizeList
                          height={400}
                          width="100%"
                          itemSize={46}
                          itemCount={localProducts.length}
                          itemData={localProducts}
                        >
                          {renderProducts}
                        </FixedSizeList>
                      </div>
                    </Grid>
                  </Form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions {...modalActions} onClick={handleCloseModal}>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.submitBtn}
          disabled={getDisabledState()}
          onClick={handleSubmit}
        >
          <LoadingText
            {...loadingText}
            loading={false}
            text={t("submitSave")}
          />
        </Button>
      </ModalActions>
    </Modal>
  );
};

EditWorkOrderServiceModal.propTypes = propTypes;
EditWorkOrderServiceModal.defaultProps = defaultProps;

export default EditWorkOrderServiceModal;
export {
  propTypes as EditWorkOrderServiceModalPropTypes,
  defaultProps as EditWorkOrderServiceModalDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column"
  },
  moduleContainer: {
    padding: "0.5rem",
    margin: "0.5rem",
    marginBottom: 0
  },
  disabledModuleContainer: {
    "&:hover": {
      transform: "scale(1)",
      cursor: "pointer"
    }
  },
  moduleCard: {
    minHeight: 150,
    background: theme.palette.primary.main,
    borderRadius: 3,
    transition: "background .2s ease-in-out",
    "&:hover": {
      background: theme.palette.hover.main,
      cursor: "pointer"
    }
  },
  disabledModule: {
    background: "#8e8e8e",
    "&:hover": {
      background: "#8e8e8e",
      cursor: "not-allowed"
    }
  },
  moduleTitle: {
    fontSize: "0.85rem",
    fontWeight: 800,
    padding: "0.5rem",
    paddingTop: 0,
    fontFamily: theme.font.display,
    textAlign: "center",
    color: theme.palette.sidebar.text
  },
  imageContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center"
  },
  backdrop: {
    background: "transparent",
    borderRadius: "50%",
    padding: "0.3rem",
    width: 110,
    height: 110
  },
  disabledBackdrop: {
    background: "#6b6b6b",
    position: "relative"
  },
  imgContainer: {
    background: theme.palette.hover.main,
    borderRadius: "50%",
    padding: "1.2rem",
    width: 100,
    height: 100
  },
  disabledContainer: {
    background: "#353535"
  },
  inactiveBox: {
    background: "#c71010e0",
    position: "absolute",
    top: "50%",
    left: 0,
    width: "100%",
    textAlign: "center",
    padding: "0.25rem",
    borderRadius: 5
  },
  inactiveTitle: {
    fontSize: "1.1rem",
    padding: "0.5rem",
    fontFamily: theme.font.display,
    textAlign: "center",
    textShadow: `1px 1px 1px ${theme.palette.common.black}`,
    color: theme.palette.common.white
  },
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderWorkers.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  workers: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  workers: [],
};

/**
 * Displays the component
 */
const WorkOrderWorkers = (props) => {
  const { workers } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the workers state
   */
  const [workersList, setWorkersList] = useState(workers);

  /**
   * Handles rendering the workers
   */
  const renderWorkers = () => (
    <div className={classes.workers}>
      {workersList &&
        workersList.map((worker, idx) => {
          return idx === workers.length - 1
            ? `${worker.name}`
            : `${worker.name},`;
        })}
    </div>
  );

  /**
   * Handles updating the workers list
   */
  useEffect(() => {
    if (workers && workers.length > 0) {
      setWorkersList(workers);
    }
  }, [workers]);

  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="caption" className={classes.title}>
          {t("workers")}
          {renderWorkers()}
        </Typography>
      </Grid>
    </div>
  );
};

WorkOrderWorkers.propTypes = propTypes;
WorkOrderWorkers.defaultProps = defaultProps;

export default WorkOrderWorkers;
export {
  propTypes as WorkOrderWorkersPropTypes,
  defaultProps as WorkOrderWorkersDefaultProps,
};

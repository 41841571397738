/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    minHeight: 350,
    maxHeight: 350,
    marginTop: "1rem",
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  menuIconOpen: {
    position: "fixed",
    left: 15,
    top: "4rem",
    background: theme.palette.primary.main,
    fontSize: "2rem",
    color: "#fff",
    padding: "0.15rem",
    cursor: "pointer",
    transition: "transform .2s ease-in-out",
    [theme.breakpoints.down('sm')]: {
      top: "6rem",
    }
  },
  menuIconClosed: {
    position: "fixed",
    left: 15,
    top: "4rem",
    background: theme.palette.primary.main,
    fontSize: "2rem",
    color: "#fff",
    padding: "0.15rem",
    cursor: "pointer",
    zIndex: 1000,
    transition: "transform .2s ease-in-out",
    [theme.breakpoints.down('sm')]: {
      top: "6rem",
    },
    "&:hover": {
      background: theme.palette.hover.main,
    }
  },
  isOpen: {
    transform: `translateX(${theme.drawerWidth}px)`
  },
  drawer: {
    // width: theme.drawerWidth,
    flexShrink: 0,
    transition: "all .2s ease-in-out"
  },
  drawerPaper: {
    width: theme.drawerWidth,
    height: `calc(100% - ${theme.navHeight + 35}px)`,
    marginTop: theme.navHeight,
    background: theme.palette.sidebar.main,
    "& .MuiToolbar-regular": {
      minHeight: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "87px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%"
    // },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  drawerContent: {
    overflow: "auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  greetingContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1rem"
  },
  drawerSection: {
    "& .MuiListItemIcon-root": {
      minWidth: 35
    },
    "& .MuiListItemText-root": {
      marginTop: 1,
      marginBottom: 0
    },
    "& .MuiList-root.MuiList-padding": {
      padding: 0,
      transition: "all 0.2s ease"
    }
  },
  menuItem: {
    fontSize: "0.9rem",
    color: "inherit",
    fontFamily: theme.font.family,
    fontWeight: 800
  },
  greeting: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    fontSize: "1.2rem",
    color: theme.palette.sidebar.text
  },
  name: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    fontSize: "0.8rem",
    color: theme.palette.common.white,
    marginBottom: "0.5rem",
    padding: "0.25rem 2rem",
    marginTop: "0.5rem",
    background: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.secondary.main
    }
  },
  icon: {
    color: theme.palette.primary.main,
    height: 25,
    width: 25
  },
  footerContainer: {
    display: "flex",
    flexWrap: "no-wrap"
  },
  footerItem: {
    opacity: 0.6,
    fontSize: "0.7rem"
  },
  nested: {
    paddingLeft: "2rem",
    "& .MuiListItemIcon-root": {
      minWidth: 25
    }
  },
  expandIcon: {
    color: theme.palette.primary.main,
    width: "1rem",
    height: "1rem",
    transition: "all .2s ease",
    transform: "rotate(180deg)"
  },
  expanded: {
    transform: "rotate(0deg)"
  },
  nestedIcon: {
    color: theme.palette.primary.main,
    marginRight: "0.15rem"
  },
  nestedMenuItem: {
    opacity: 0.7,
    color: theme.palette.secondary.main,
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    paddingLeft: "0.15rem"
  },
  logoutList: {
    marginBottom: "3rem"
  },
  activeMenu: {
    backgroundColor: theme.palette.hover.main,
    color: theme.palette.common.white,
    "& svg": {
      color: theme.palette.common.white
    },
    "& $menuItem": {
      color: theme.palette.common.white
    },
    "&:hover": {
      backgroundColor: theme.palette.hover.main,
      // "& svg": {
      //   color: theme.palette.primary.main
      // },
      // "& $menuItem": {
      //   color: theme.palette.hover.main
      // }
    }
  },
  fontAwesome: {
    "& .svg-inline--fa": {
      marginLeft: "0.1rem",
      color: theme.palette.primary.main,
      height: 22,
      width: 20
    }
  },
  img: {
    maxWidth: 20
  },
  deleteIcon: {
    color: theme.palette.error.main
  },
  recoveryIcon: {
    color: theme.palette.success.main
  },
  deleteText: {
    color: theme.palette.error.main
  },
  recoveryText: {
    color: theme.palette.success.main
  },
  goBackContainer: {
    position: "fixed",
    left: 60,
    top: "4rem",
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 32,
    boxShadow: "none",
    minWidth: 125,
    zIndex: 1000,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: "pointer",
    transition: "transform .2s ease-in-out",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
    [theme.breakpoints.down('sm')]: {
      top: "6rem",
    },
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { format as formatDate } from "date-fns";
import clsx from "clsx";
import shortid from "shortid";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Material UI Imports Components
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

/**
 * Imports Styles
 */
import { useStyles } from "./ReportsAdvancedTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  data: PropTypes.array,
  isPrinting: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  data: [],
  isPrinting: false,
};

/**
 * Displays the component
 */
const ReportsAdvancedTable = (props) => {
  const { data, isPrinting } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const list = isPrinting
    ? [
        {
          uuid: t("uuid"),
          car_plate_number: t("car_plate_number"),
          client_name: t("companyName"),
          delegate_name: t("name"),
          tyre_dimension: t("tyre_dimension"),
          products_name: t("products_name"),
          subtotal: t("subtotal"),
          discount: t("discount"),
          total: t("total"),
          payment_type_name: t("payment"),
          is_header: true,
          is_company: true,
        },
        ...data,
      ]
    : data;

  return (
    <TableContainer>
      <Table stickyHeader className={clsx(classes.table, "page-break")}>
        <TableHead
          className={clsx({
            [classes.hiddenHeader]: isPrinting,
          })}
        >
          <TableRow>
            <TableCell> #</TableCell>
            <TableCell align="right"> {t("uuid")}</TableCell>
            <TableCell align="right"> {t("car_plate_number")}</TableCell>
            <TableCell align="right"> {t("companyName")} </TableCell>
            <TableCell align="right">{t("name")}</TableCell>
            <TableCell align="right">{t("tyre_dimension")}</TableCell>
            <TableCell align="right">{t("products_name")}</TableCell>
            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("subtotal")}
            </TableCell>
            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("discount")}
            </TableCell>
            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("total")}
            </TableCell>
            <TableCell align="right">{t("payment")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            const {
              uuid,
              car_plate_number,
              client_name,
              delegate_name,
              tyre_dimension,
              total,
              payment_type_name,
              is_company,
              is_header,
              products_name,
              subtotal,
              discount,
              isDivider,
              count,
              nextFinished,
            } = item;

            const headerClasses =
              "MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader";

            const cellClass = clsx({
              [headerClasses]: is_header,
            });

            const rowClasses = clsx({
              [classes.divider]: isDivider,
            });

            const alignRight = { textAlign: "right" };
            let rowCount = count ? count : index + 1;

            return (
              <TableRow className={rowClasses} key={shortid.generate()}>
                <TableCell component="th" scope="row" className={cellClass}>
                  {isPrinting && (index === 0 ? "#" : index)}
                  {!isDivider && !isPrinting ? rowCount : null}
                  {isDivider && (
                    <Typography className={classes.dividerTitle}>
                      {formatDate(new Date(nextFinished), "yyyy-MM-dd")}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={{ minWidth: 75 }}
                >
                  {!isDivider && uuid}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {!isDivider && car_plate_number}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {!isDivider ? (is_company ? client_name : "-") : null}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {!isDivider
                    ? is_company
                      ? delegate_name
                        ? delegate_name
                        : "-"
                      : client_name
                    : null}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {!isDivider && tyre_dimension}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {!isDivider && products_name}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {!isDivider
                    ? isPrinting
                      ? subtotal
                      : parseFloat(subtotal).toFixed(2)
                    : null}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {!isDivider
                    ? isPrinting
                      ? discount
                      : parseFloat(discount).toFixed(2)
                    : null}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {!isDivider
                    ? isPrinting
                      ? total
                      : parseFloat(total).toFixed(2)
                    : null}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {!isDivider && payment_type_name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportsAdvancedTable.propTypes = propTypes;
ReportsAdvancedTable.defaultProps = defaultProps;

export default ReportsAdvancedTable;
export {
  propTypes as ReportsAdvancedTablePropTypes,
  defaultProps as ReportsAdvancedTableDefaultProps,
};

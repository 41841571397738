import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";

/**
 * Imports styles
 */
import { useStyles } from "./AttendanceTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  tableData: PropTypes.array,
  timeInterval: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  dynamicTable: DynamicTableDefaultProps,
  tableData: [],
  timeInterval: [],
};

/**
 * Displays the component
 */
const AttendanceTable = (props) => {
  const { dynamicTable, tableData, timeInterval } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the collection
   */
  const [collection, setCollection] = useState([]);

  const [initMount, setInitMount] = useState(true);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () => {
    if (initMount) return false;
    return !initMount && collection && collection.length < 1;
  };

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const { timeInterval } = props;
    let tableOptions = {
      withCount: true,
      withAdd: false,
      withSearch: true,
      withPagination: false,
      withStats: true,
      withSort: true,
      containerClassName: classes.tableContainer,
      helpers: {
        resetSearch: true,
        resetFilters: false,
        addResult: false,
      },
      defaultOrderBy: "name",
      fields: [
        {
          name: "name",
          label: t("name"),
          align: "center",
          minSize: 150,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: true,
        },
      ],
    };

    const datesInterval =
      timeInterval &&
      timeInterval.map((date, idx) => {
        return {
          name: `day_${idx + 1}`,
          label: t(`day_${idx + 1}`),
          value: date,
          type: "attendance",
          align: "center",
          minSize: 100,
          key: true,
          searchField: false,
          sort: true,
        };
      });

    /**
     *  Push the action buttons to the fields
     */
    tableOptions.fields.push(...datesInterval, {
      name: "worked_hours",
      label: t("worked_hours"),
      align: "center",
      minSize: 150,
      maxSize: 200,
      key: true,
      searchField: true,
      sort: true,
    });

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();
    if (initMount) setInitMount(false);

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({ timeInterval });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [timeInterval]);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (tableData.length > 0) {
      const data = tableData.map((data) => {
        const { totalHours, timesheet } = data;

        return {
          ...data,
          day_1: timesheet[0].hours,
          day_2: timesheet[1].hours,
          day_3: timesheet[2].hours,
          day_4: timesheet[3].hours,
          day_5: timesheet[4].hours,
          day_6: timesheet[5].hours,
          day_7: timesheet[6].hours,
          worked_hours: totalHours,
        };
      });
      setCollection(data);
    }
    if (tableData.length < 1) {
      setCollection([]);
    }
  }, [tableData]);

  return renderDynamicTable();
};

AttendanceTable.propTypes = propTypes;
AttendanceTable.defaultProps = defaultProps;

export default AttendanceTable;
export {
  propTypes as AttendanceTablePropTypes,
  defaultProps as AttendanceTableDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 750,
    padding: 0,
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
  content: {
    padding: "16px",
    marginBottom: "1rem",
  },
  selectProductTitle: {
    fontWeight: 800,
    marginBottom: "0.75rem",
    display: "block",
    fontFamily: theme.font.family,
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "4px 0",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.85rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
      fontSize: "0.75rem",
    },
    "& .MuiAutocomplete-tagSizeSmall": {
      background: theme.palette.primary.accent,
      color: theme.palette.common.white,
      margin: 4,
      marginTop: 6,
      borderRadius: 3,
      "& svg": {
        color: "inherit",
      },
      "& svg:hover": {
        opacity: 0.7,
      },
    },
  },
  nameField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "4px 0",
    marginBottom: "0.5rem",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.85rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
      fontSize: "0.75rem",
    },
  },
  formContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    margin: "1rem 0",
  },
  substraction: {
    padding: 0,
    margin: 0,
    width: 35,
    minWidth: 35,
    height: 30,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: 2,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: theme.palette.success.dark,
      color: theme.palette.common.white,
    },
  },
  icon: {
    margin: "0 0.25rem",
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  qtyLabel: {
    position: "absolute",
    top: -25,
    marginLeft: 0,
    fontSize: "0.8rem",
    opacity: 1,
    fontWeight: 800,
  },
  qtyValue: {
    margin: "0 1rem",
    fontSize: "1rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.main,
    padding: "0 1rem",
    width: 50,
  },

  addition: {
    padding: 0,
    margin: 0,
    width: 35,
    minWidth: 35,
    height: 30,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: 2,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: theme.palette.success.dark,
      color: theme.palette.common.white,
    },
  },
  submitBtn: theme.mixins.submitBtn,
  list: {
    width: "100%",
    height: 400,
    maxWidth: "none",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #d0d0d0",
  },
  active: {
    background: "#f1f1f1",
    "&:hover": {
      background: "#f1f1f1",
    },
  },
}));

export { useStyles };

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import shortid from "shortid";
import clsx from "clsx";

/**
 * Material UI Imports
 */
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";

/**
 * Styles Imports
 */
import { useStyles } from "./InputSelect.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  margin: PropTypes.string,
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
  native: PropTypes.bool,
  fullWidth: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  helperText: PropTypes.string,
  showHelper: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: "selectInput",
  optionLabel: "",
  optionValue: "",
  options: [],
  label: "",
  value: "",
  onChange: () => {},
  name: "selectInput",
  required: false,
  className: "",
  margin: "none",
  autoFocus: false,
  variant: "standard",
  helperText: "",
  error: "",
  fullWidth: true,
  allowEmpty: true,
  native: false,
  showHelper: true,
};

/**
 * Displays the component
 */
const InputSelect = (props) => {
  const {
    id,
    optionLabel,
    optionValue,
    options,
    label,
    value,
    onChange,
    name,
    required,
    className,
    labelClass,
    disabled,
    margin,
    autoFocus,
    error,
    variant,
    defaultValue,
    loaderFn,
    helperText,
    native,
    fullWidth,
    allowEmpty,
    showHelper,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles(props);

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Defines the form control classes
   */
  const formControlClasses = clsx(classes.form, {
    [className]: className ? true : false,
  });

  /**
   * Defines the label classes
   */
  const labelClasses = clsx(classes.label, {
    [labelClass]: labelClass ? true : false,
  });

  /**
   * Customizes the select dropdown menu
   */
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 225,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  };

  /**
   * Gets the value
   */
  const getValue = (option, optionValue) => {
    if (optionValue.length > 0) return option[optionValue];
    return option;
  };

  /**
   * Gets the label
   */
  const getLabel = (option, optionLabel) => {
    if (optionLabel.length > 0) return option[optionLabel];
    return option;
  };

  /**
   * Handles the input change
   */
  const handleChange = (e) => {
    if (loaderFn) {
      loaderFn();
      onChange(e);
    } else {
      onChange(e);
    }
  };

  /**
   * Renders the select menu options
   */
  const renderOptions = () => {
    return (
      options &&
      options.map((option) => (
        <MenuItem
          key={shortid.generate()}
          value={getValue(option, optionValue)}
        >
          {getLabel(option, optionLabel)}
        </MenuItem>
      ))
    );
  };

  /**
   * Renders the select menu options
   */
  const renderNativeOptions = () => {
    return (
      options &&
      options.map((option) => (
        <option key={shortid.generate()} value={getValue(option, optionValue)}>
          {getLabel(option, optionLabel)}
        </option>
      ))
    );
  };

  /**
   * Displays the errors if any
   */
  const displayErrors = () =>
    error && (
      <Typography paragraph className={classes.error}>
        {error}
      </Typography>
    );

  const displayHelper = () => {
    if (!showHelper) return null;
    const isVisible = helperText !== "" && helperText !== "hidden";
    return (
      <Typography
        paragraph
        className={clsx(classes.helper, {
          [classes.hidden]: !isVisible,
        })}
      >
        {helperText}
      </Typography>
    );
  };

  /**
   * Applies autofocus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  /**
   * Gets the select type (Native / MUI)
   */
  const getSelectType = () => {
    return native ? (
      <NativeSelect
        id={id}
        name={name}
        margin={margin}
        value={value}
        onChange={onChange}
        className={classes.selectNative}
      >
        {allowEmpty && <option value="" />}
        {renderNativeOptions()}
      </NativeSelect>
    ) : (
      <Select
        id={id}
        inputRef={inputRef}
        disabled={disabled}
        name={name}
        margin={margin}
        required={required}
        value={value}
        onChange={handleChange}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        error={error ? true : false}
        MenuProps={MenuProps}
        className={classes.select}
      >
        {renderOptions()}
      </Select>
    );
  };

  return (
    <FormControl variant={variant} className={formControlClasses}>
      {value ? null : (
        <InputLabel
            error={error ? true : false}
            className={labelClasses}
            required={required}
        >
          {label}
        </InputLabel>
      )}
      {getSelectType()}
      {displayHelper()}
      {displayErrors()}
    </FormControl>
  );
};

InputSelect.propTypes = propTypes;
InputSelect.defaultProps = defaultProps;

export default InputSelect;
export {
  propTypes as InputSelectPropTypes,
  defaultProps as InputSelectDefaultProps,
};

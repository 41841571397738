import React from "react";
import PropTypes from "prop-types";

/**
 * Imports i18n
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";

/**
 * Imports the component styles
 */
import { useStyles } from "./Importer.styles";

/**
 * Hooks
 */
import {
  useUser,
  useMessage,
  useApiClient
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  downloadIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  importIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  downloadText: PropTypes.string,
  importText: PropTypes.string,
  onDownloadSuccess: PropTypes.func,
  onDownloadError: PropTypes.func,
  onImportSuccess: PropTypes.func,
  onImportError: PropTypes.func,
  onDownload: PropTypes.func,
  onImport: PropTypes.func,
  downloadApiParamSetter: PropTypes.string,
  importApiParamSetter: PropTypes.string,
  downloadDisabled: PropTypes.bool,
  importDisabled: PropTypes.bool,
  downloadLoading: PropTypes.bool,
  importLoading: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  loadingText: LoadingTextDefaultProps,
  /** Add your custom icons */
  downloadIcon: <SystemUpdateAltOutlinedIcon />,
  importIcon: <AttachmentOutlinedIcon />,
  /** Add your custom text */
  downloadText: "",
  importText: "",
  /** Add actions that can be triggered in the parent after the api calls succeed or fail */
  onDownloadSuccess: () => {},
  onDownloadError: () => {},
  onImportSuccess: () => {},
  onImportError: () => {},
  /** Completely overwrite the download or import click events if needed */
  onDownload: null,
  onImport: null,
  /** Choose a different api call setter */
  downloadApiParamSetter: "downloadData",
  importApiParamSetter: "importData",
  /** Disable buttons */
  downloadDisabled: true,
  importDisabled: true,
  /** Enable loading states */
  downloadLoading: false,
  importLoading: false,
};

/**
 * Displays the component
 */
const Importer = (props) => {
  const {
    button,
    loadingText,
    downloadIcon,
    importIcon,
    downloadText,
    importText,
    onDownloadSuccess,
    onDownloadError,
    onImportSuccess,
    onImportError,
    downloadDisabled,
    importDisabled,
    onDownload,
    onImport,
    downloadLoading,
    importLoading,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the api client
   */
   const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles the download
   */
  const handleDownload = async () => {
    if (onDownload) {
      onDownload();
      return;
    } else {
      /**
       * Defines the form body
       */
      const formBody = {};
      
      try {
      const result = await apiClient.post(`/account/${user.settings.account.uuid}/downloads`, formBody);

      if(result && result.data){
        onDownloadSuccess(result.data);
      }

      } catch (error) {
             /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
      if (onDownloadError) onDownloadError(error);
      }
    }
  };

  /**
   * Handles the import
   */
  const handleImport = async () => {
    if (onImport) {
      onImport();
      return;
    } else {
     /**
       * Defines the form body
       */
      const formBody = {};
      
      try {
      const result = await apiClient.post(`/account/${user.settings.account.uuid}/imports`, formBody);

      if(result && result.data){
        onImportSuccess(result.data);
      }

      } catch (error) {
             /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
      if (onImportError) onImportError(error);
      }
    }
  };

  return (
    <div className={classes.actions}>
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.downloadBtn}
        onClick={handleDownload}
        disabled={downloadDisabled}
      >
        <LoadingText
          {...loadingText}
          loading={downloadLoading}
          text={downloadText ? downloadText : t("download")}
          icon={downloadIcon ? downloadIcon : <SystemUpdateAltOutlinedIcon />}
        />
      </Button>
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.importBtn}
        onClick={handleImport}
        disabled={importDisabled}
      >
        <LoadingText
          {...loadingText}
          loading={importLoading}
          text={importText ? importText : t("import")}
          icon={importIcon ? importIcon : <AttachmentOutlinedIcon />}
        />
      </Button>
    </div>
  );
};

Importer.propTypes = propTypes;
Importer.defaultProps = defaultProps;

export default Importer;
export { propTypes as ImporterPropTypes, defaultProps as ImporterDefaultProps };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicSearchForm, {
  DynamicSearchFormPropTypes,
  DynamicSearchFormDefaultProps,
} from "../DynamicSearchForm";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  dynamicSearch: PropTypes.shape(DynamicSearchFormPropTypes),
  apiCallSetter: PropTypes.func,
  setBackdropLoading: PropTypes.func,
  resetFilters: PropTypes.bool,
  setResetFilters: PropTypes.func,
  setData: PropTypes.func,
  clientsList: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  dynamicSearch: DynamicSearchFormDefaultProps,
  apiCallSetter: () => {},
  setBackdropLoading: () => {},
  resetFilters: false,
  setResetFilters: () => {},
  setData: () => {},
  clientsList: [],
};

/**
 * Displays the component
 */
const ReportsAdvancedSearch = (props) => {
  const {
    dynamicSearch,
    setApiCallMade,
    setBackdropLoading,
    setData,
    clientsList,
    setActiveOrg,
    updateInputs,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the table options
   */
  const [searchOptions, setSearchOptions] = useState({});

  /**
   * Initializes the form options
   */
  const [formOptions, setFormOptions] = useState({});

  /**
   * Initializes the models
   */

  const [models, setModels] = useState({});

  /**
   * Initializes the user data ready flag
   */
  const [userDataReady, setUserDataReady] = useState(false);

  /**
   * Handles getting the organizations
   */
  const getUserOrganizations = () => {
    if (user.organizations) {
      const organizations = [
        {
          name: t("all"),
          id: "all",
        },
        ...user.organizations,
      ].map((org) => {
        return {
          name: org.name,
          id: org.id,
        };
      });

      return organizations;
    }
  };

  const getCarTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.car_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  const getWorkOrderTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.work_order_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  const getPaymentTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.payment_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  /**
   * Checks if the table options are ready
   */
  const isReady = (config) => Object.keys(config).length > 0;

  const carTypes = getCarTypes();
  const workOrderTypes = getWorkOrderTypes();
  const userOrganizaitons = getUserOrganizations();
  const paymentTypes = getPaymentTypes();

  const getDateIntervals = () => {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth() - 1;
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    const todayStart =
      formatDate(new Date(firstDay), "yyyy-MM-dd") + " 00:00:00";
    const todayEnd = formatDate(new Date(lastDay), "yyyy-MM-dd") + " 23:59:59";

    const unformattedStart = formatDate(new Date(firstDay), "yyyy-MM-dd");
    const unformattedEnd = formatDate(new Date(lastDay), "yyyy-MM-dd");

    return {
      startDate: todayStart,
      endDate: todayEnd,
      u_start: unformattedStart,
      u_end: unformattedEnd,
    };
  };

  /**
   * Handles getting the table options
   */
  const getSearchOptions = () => {
    const { u_start, u_end } = getDateIntervals();
    let searchOptions = {
      formID: "advanced-reports",
      separateContainers: true,
      updateState: {
        key: "organization_id",
        fn: setActiveOrg,
      },
      cleanModels: (models) => {
        let newModels = [];

        models.forEach((model) => {
          if (model.field === "organization_client_id") {
            newModels.push({
              ...model,
              type: "like",
              label: "Client Name",
              field: "client_name",
            });
            return;
          }
          if (model.field === "tyre_width") {
            newModels.push({
              ...model,
              field: "tyreService.tyre_width",
            });
            return;
          }

          if (model.field === "tyre_height") {
            newModels.push({
              ...model,
              field: "tyreService.tyre_height",
            });
            return;
          }

          if (model.field === "tyre_rim") {
            newModels.push({
              ...model,
              field: "tyreService.tyre_rim",
            });
            return;
          }

          if (model.field === "organization_id") {
            newModels.push({
              ...model,
              selected: model.selected !== "all" ? model.selected : null,
            });
            return;
          }

          if (model.field === "finished") {
            const dates = model.selected.trim().split(" - ");
            if (dates.length > 1) {
              const startDate = formatDate(
                new Date(dates[0]).setHours(0, 0, 0),
                "yyyy-MM-dd HH:mm"
              );
              const endDate = formatDate(
                new Date(dates[1]).setHours(23, 59, 59),
                "yyyy-MM-dd HH:mm"
              );

              newModels.push({
                ...model,
                selected: [startDate, endDate],
              });
              return;
            }
          }
          newModels.push({ ...model });
        });

        return newModels;
      },
      gridConfig: {
        justify: "flex-start",
        actionsContainer: {
          xs: 12,
          sm: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
      },
      submitBtnText: t("submitSearch"),
      grid: {
        form: {
          xs: 12,
          sm: 9,
        },
        actions: {
          xs: 12,
          sm: 3,
        },
      },
      defaultValues: {
        quick_text: "",
        uuid: "",
        organization_id: user.organization && user.organization.id,
        organization_client_id: "",
        work_order_type_id: workOrderTypes[2].id,
        payment_type_id: "",
        car_type_id: "",
        finished: `${u_start} - ${u_end}`,
        tyre_width: "",
        tyre_height: "",
        tyre_rim: "",
        order_by: "created_at",
        order_dir: "desc",
      },
    };

    return searchOptions;
  };

  const getFormOptions = () => {
    const formOptions = [
      {
        name: "quick_text",
        inputType: "text",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("quick_text"),
        props: {},
      },
      {
        name: "uuid",
        inputType: "text",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("uuid"),
        props: {},
      },
      {
        name: "organization_id",
        inputType: "select",
        label: t("organization_id"),
        grid: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 4,
          xl: 4,
        },
        props: {
          options: userOrganizaitons,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "organization_client_id",
        inputType: "autocomplete",
        label: t("organization_client_id"),
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
        },
        props: {
          options: clientsList,
          getOptionSelected: (option, value) => {
            return option.id === value.id;
          },
          freeSolo: true,
          autoComplete: false,
        },
      },
      {
        name: "work_order_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("work_order_type"),
        props: {
          options: workOrderTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "payment_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("payment_type_id"),
        props: {
          options: paymentTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "car_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("car_type_id"),
        props: {
          options: carTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "finished",
        inputType: "dateRange",
        grid: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        label: t("finished"),
        props: {
          required: true,
        },
      },
      {
        name: "tyre_width",
        inputType: "text",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("tyre_width"),
        props: {},
      },
      {
        name: "tyre_height",
        inputType: "text",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("tyre_height"),
        props: {},
      },
      {
        name: "tyre_rim",
        inputType: "text",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
        },
        label: t("tyre_rim"),
        props: {},
      },

      {
        name: "order_by",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
        },
        label: t("order_by"),
        props: {
          options: [{ id: "created_at", name: t("created_at") }],
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "order_dir",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
        },
        label: t("order_dir"),
        props: {
          options: [
            { id: "asc", name: t("asc") },
            { id: "desc", name: t("desc") },
          ],
          optionValue: "id",
          optionLabel: "name",
        },
      },
    ];

    return formOptions;
  };

  const getModels = () => {
    const { startDate, endDate } = getDateIntervals();

    const quickText_model = {
      label: "Quick Text",
      field: "quick_text",
      type: "like",
      order: 1,
      options: [],
      selected: null,
    };

    const workOrderId_model = {
      label: "Work Order ID",
      field: "uuid",
      type: "like",
      order: 6,
      options: [],
      selected: null,
    };

    const organizationID_model = {
      label: "Organization id",
      field: "organization_id",
      type: "dropdown",
      order: 2,
      options: [],
      selected: user && user.organization && user.organization.id,
    };

    const workOrderType_model = {
      label: "Work Order Type",
      field: "work_order_type_id",
      type: "dropdown",
      order: 1,
      options: [],
      selected: workOrderTypes[2].id,
    };

    const carTypeID_model = {
      label: "Car Type ID",
      field: "car_type_id",
      type: "equals",
      order: 1,
      options: [],
      selected: null,
    };

    const tyreWidth_model = {
      label: "Tyre Width",
      field: "tyre_width",
      type: "equal",
      order: 6,
      options: [],
      selected: null,
    };

    const tyreHeight_model = {
      label: "Tyre Height",
      field: "tyre_height",
      type: "equal",
      order: 6,
      options: [],
      selected: null,
    };

    const tyreRim_model = {
      label: "Tyre Rim",
      field: "tyre_rim",
      type: "equal",
      order: 6,
      options: [],
      selected: null,
    };

    const clientName_model = {
      label: "Organization Client ID",
      field: "organization_client_id",
      type: "equals",
      order: 6,
      options: [],
      selected: null,
    };

    const finished_model = {
      label: "Finished",
      field: "finished",
      type: "range",
      order: 1,
      options: [],
      selected: [startDate, endDate],
    };

    const paymentType_model = {
      label: "Work Order Type",
      field: "payment_type_id",
      type: "dropdown",
      order: 1,
      options: [],
      selected: null,
    };

    const models = {
      uuid: workOrderId_model,
      quick_text: quickText_model,
      organization_id: organizationID_model,
      car_type_id: carTypeID_model,
      payment_type_id: paymentType_model,
      work_order_type_id: workOrderType_model,
      tyre_width: tyreWidth_model,
      tyre_height: tyreHeight_model,
      tyre_rim: tyreRim_model,
      organization_client_id: clientName_model,
      finished: finished_model,
    };

    return models;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicSearchForm = () => {
    const ready =
      userDataReady && 
      isReady(searchOptions) &&
      isReady(formOptions) &&
      isReady(models);

    return ready ? (
      <DynamicSearchForm
        {...dynamicSearch}
        setApiCallMade={setApiCallMade}
        setBackdropLoading={setBackdropLoading}
        preventDefaultSearch={true}
        formOptions={formOptions}
        searchOptions={searchOptions}
        models={models}
        apiCallProps={{ 
          type: "work-order",
          format: "normal",
        }}
        setData={setData}
        updateInputs={updateInputs}
      />
    ) : null;
  };

  const getConfig = () => {
    const searchOptions = getSearchOptions();
    const formOptions = getFormOptions();
    const models = getModels();

    return { searchOptions, formOptions, models };
  };

  useEffect(() => {
    if (userOrganizaitons && carTypes && workOrderTypes && paymentTypes) {
      setUserDataReady(true);
    }
  }, [userOrganizaitons, carTypes, workOrderTypes, paymentTypes]);

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    if (userDataReady) {
      const { searchOptions, formOptions, models } = getConfig();

      setSearchOptions(searchOptions);
      setFormOptions(formOptions);
      setModels(models);
    }

    // eslint-disable-next-line
  }, [userDataReady]);

  return renderDynamicSearchForm();
};

ReportsAdvancedSearch.propTypes = propTypes;
ReportsAdvancedSearch.defaultProps = defaultProps;

export default ReportsAdvancedSearch;
export {
  propTypes as ReportsAdvancedSearchPropTypes,
  defaultProps as ReportsAdvancedSearchDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        background: "#f9f9f9",
        border: "1px solid #bfbfbf",
        borderRadius: 5,
        justifyContent: "space-between",
        paddingLeft: "1rem",
        marginBottom: "0.5rem"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        margin: "0 0.5rem",
        padding: 10,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    },
    divider: {
        height: 28,
        margin: 4
    }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreHotelsChoiceModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  button: ButtonDefaultProps,
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const TyreHotelsChoiceModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    open,
    closeModal,
    button,
    setActivePrint,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const printClient = () => {
    setActivePrint({ client: true, warehouse: false });
    closeModal();
  };
  const printWarehouse = () => {
    setActivePrint({ client: false, warehouse: true });
    closeModal();
  };

  return (
    <Modal
      {...modal}
      fullScreen={triggerFullScreen}
      maxWidth="xs"
      open={open}
      onClose={closeModal}
      keepMounted={true}
      hideBackdrop={false}
      scroll="paper"
    >
      <ModalTitle
        {...modalTitle}
        title={t("titleChoosePrintingMethod")}
        onClick={closeModal}
      />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid item container xs={12} className={classes.modulesContainer}>
          <div className={classes.choices}>
            <Button
              {...button}
              type="button"
              className={classes.clientButton}
              onClick={printClient}
            >
              {t("client")}
            </Button>
            <Button
              {...button}
              type="button"
              className={classes.warehouseButton}
              onClick={printWarehouse}
            >
              {t("warehouse")}
            </Button>
          </div>
        </Grid>
      </ModalContent>
    </Modal>
  );
};

TyreHotelsChoiceModal.propTypes = propTypes;
TyreHotelsChoiceModal.defaultProps = defaultProps;

export default TyreHotelsChoiceModal;
export {
  propTypes as TyreHotelsChoiceModalPropTypes,
  defaultProps as TyreHotelsChoiceModalDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 *  Imports Material UI Components
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import SaveIcon from "@material-ui/icons/Save";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

/**
 * Imports Component
 */
import Modal, { ModalTitle, ModalContent, ModalActions } from "../Modal";
import Form from "../Form";
import Button from "../Button";
import Input from "../Input";
import LoadingText from "../LoadingText";
import TyreServiceCreateModal from "../TyreServiceCreateModal";

/**
 * Imports hooks
 */
import { useTranslation } from "react-i18next";
import { useWorkOrder, useUser, useForm , useMessage } from "../../hooks";

/**
 * Imports the component styles
 */
import { useStyles } from "./NCreateWorkOrderModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  activeWork: PropTypes.array,
  setActiveWork: PropTypes.func,
  draftData: PropTypes.object,
  setDraftData: PropTypes.func,
  recentlyCreated: PropTypes.array,
  setRecentlyCreated: PropTypes.func,
  defaultValues: PropTypes.shape({
    work_order_type: PropTypes.string,
    plate_number: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  open: false,
  closeModal: () => {},
  activeWork: [],
  setActiveWork: () => {},
  draftData: {},
  setDraftData: () => {},
  recentlyCreated: [],
  setRecentlyCreated: () => {},
  defaultValues: {
    work_order_type: "",
    plate_number: "",
  },
};

/**
 * Displays the component
 */
const NCreateWorkOrderModal = (props) => {
  const {
    open,
    closeModal,
    activeWork,
    setActiveWork,
    draftData,
    setDraftData,
    recentlyCreated,
    setRecentlyCreated,
    defaultValues,
    onCreate
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the work order hook
   */
  const { workOrderType, setWorkOrderType, setWorkOrder } = useWorkOrder();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the work order types list
   */
  const [workOrderTypesList, setWorkOrderTypesList] = useState([]);

  /**
   * Initializes the draft checked flag
   */
  const [draftChecked, setDraftChecked] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
   const drafts = JSON.parse(localStorage.getItem('drafts'));
   if(drafts){
      const foundPlate = drafts.find(draft => draft.plateNumber === inputs.plate_number);
      if(foundPlate){
        return dispatchMessage({
          severity: "error",
          message: t("PlateNumberAlreadyInUse")
        })
      }
   }


    setWorkOrderType(inputs.work_order_type);
    setWorkOrder((prevState) => ({
      ...prevState,
      car: {
        plate_number: inputs.plate_number,
      },
    }));
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { plate_number, work_order_type } = inputs;
  const {
    work_order_type: work_order_typeError,
    plate_number: plate_numberError,
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    // cleanup edge case
    localStorage.removeItem("undefined_newTimer");
    setWorkOrder({});
    setDraftData({});
    setWorkOrderType("");
    resetInputs();
    closeModal();
  };

  /**
   * Gets the client id
   * @param {String} client_name
   */
  const getClientId = (client_name) => {
    if (!client_name) return "";
    const found = user.clients.find((client) => client.name === client_name);
    return found ? found.id : "";
  };

  /**
   * Handles filling up the car types list
   */
  useEffect(() => {
    if (user.settings) {
      const workOrderTypes = user.settings.account.work_order_types;
      const defaultType = workOrderTypes.find((wo) => wo.short_name === "VLC");

      /**
       * Only Tyre Service for now
       */
      setWorkOrderTypesList(workOrderTypes);

      setInputs((prevState) => ({
        ...prevState,
        work_order_type: defaultType ? defaultType.id : workOrderTypes[0].id,
      }));
    }
    // eslint-disable-next-line
  }, [user, open]);

  /**
   * Handles checking for draft data
   * Initializes the work order state with the draft data if a draft exists
   *
   * Will require refactoring in the future when we get new work order types
   */
  useEffect(() => {
    if (draftData.workOrderType) {
      setDraftChecked(true);
      setWorkOrderType(draftData.workOrderType);

      const { workOrder } = draftData;

      /**
       * Defines the work_order data
       */
      const work_order = {
        work_order_type: draftData.workOrderType,
        organization_client_id: getClientId(workOrder.organization_client_id),
        car_type_id: workOrder.car_type,
        client_name: workOrder.client_name,
        delegate_name: workOrder.delegate_name,
        duration: workOrder.duration,
        subtotal: workOrder.subtotal,
        discount: workOrder.discount,
        tax: workOrder.tax,
        total: workOrder.total,
        start_date: workOrder.start_date,
        end_date: workOrder.end_date,
        description: workOrder.description,
      };

      /**
       * Defines the workers
       */
      const workers = workOrder.workers;

      /**
       * Defines the car data
       */
      const car = {
        plate_number: draftData.plateNumber || workOrder.plate_number,
        type: workOrder.car_type,
        make: workOrder.make,
        model: workOrder.model,
        vin_number: workOrder.vin_number,
      };

      /**
       * Defines the tyre_service data
       */
      const tyre_service = {
        wheel_type: workOrder.wheel_type,
        car_km: workOrder.car_km,
        new_tyre: workOrder.new_tyre,
        tyre_width: workOrder.tyre_width,
        tyre_height: workOrder.tyre_height,
        tyre_rim: workOrder.tyre_rim,
        tyre_waste: workOrder.tyre_waste,
        tyre_pressure: workOrder.tyre_pressure,
        bolt_torque: workOrder.bolt_torque,
      };

      /**
       * Defines the products
       */
      const products = workOrder.products;

      /**
       * Defines the tyre_hotel data
       */
      const tyre_hotel = {
        tyre_width: workOrder.tyre_hotel_tyre_width,
        tyre_height: workOrder.tyre_hotel_tyre_height,
        tyre_rim: workOrder.tyre_hotel_tyre_rim,
        tyre_name: workOrder.tyre_hotel_tyre_name,
        tyre_quantity: workOrder.tyre_hotel_tyre_quantity,
        description: workOrder.tyre_hotel_description,
      };

      /**
       * Sets the work order state
       */
      setWorkOrder((prevState) => {
        return {
          ...prevState,
          work_order,
          workers,
          car,
          tyre_service,
          tyre_hotel,
          products,
          hasHotelService: workOrder.hasHotelService,
        };
      });
    }

    if (!draftData || !draftData.workOrderType) {
      setDraftChecked(true);
    }
    // eslint-disable-next-line
  }, [draftData]);

  /**
   * Renders the tyre service create wo modal if the type matches and the draft has been checked.
   */
  if (workOrderType && draftChecked) {
    return (
      <TyreServiceCreateModal
        activeWork={activeWork}
        setActiveWork={setActiveWork}
        recentlyCreated={recentlyCreated}
        setRecentlyCreated={setRecentlyCreated}
        draftData={draftData}
        open={open}
        closeModal={handleCloseModal}
        onCreate={onCreate}
      />
    );
  }

  return (
    <Modal
      fullScreen={triggerFullScreen}
      maxWidth="sm"
      open={open}
      onClose={handleCloseModal}
      keepMounted={false}
      scroll="paper"
    >
      <ModalTitle
        title={t("titleCreateNewWorkOrder")}
        onClick={handleCloseModal}
      />
      <ModalContent className={classes.modalContent}>
        <Grid item container xs={12}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank}>
                <CardContent className={classes.content}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Form onSubmit={handleSubmit} id="work-order-initializer">
                        <Card className={classes.stepCard}>
                          <CardContent className={classes.cardContent}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <Input
                                  className={classes.selectField}
                                  type="select"
                                  inputSelect={{
                                    required: true,
                                    id: "work_order_type",
                                    name: "work_order_type",
                                    value: work_order_type,
                                    variant: "outlined",
                                    onChange: handleInputChange,
                                    label: t("work_order_typeLabel"),
                                    labelClass: classes.label,
                                    options: workOrderTypesList,
                                    optionLabel: "name",
                                    optionValue: "id",
                                    error: work_order_typeError,
                                    autoFocus: getAutoFocus().work_order_type,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Input
                                  type="text"
                                  className={classes.field}
                                  inputText={{
                                    required: true,
                                    id: "plate_number",
                                    name: "plate_number",
                                    value: plate_number,
                                    onChange: handleInputChange,
                                    maxSize: 9,
                                    variant: "outlined",
                                    label: t("plate_numberLabel"),
                                    InputLabelProps: {
                                      className: classes.label,
                                    },
                                    error: plate_numberError,
                                    autoFocus: getAutoFocus().plate_number,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Form>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <CardActions className={classes.cardActions}> 
          <Button
            type="button"
            variant="filled"
            onClick={handleCloseModal}
            className={classes.closeBtn}
          >
            {t("close")}
          </Button>
          <Box className={classes.buttonsContainer}>
            <Button
              type="submit"
              form="work-order-initializer"
              variant="filled"
              onClick={handleSubmit}
              className={classes.saveBtn}
            >
              <LoadingText
                loading={false}
                text={t("save")}
                icon={<SaveIcon />}
              />
            </Button>
            <Button
              type="button"
              variant="filled"
              onClick={handleSubmit}
              className={classes.submitButton}
            >
              {t("next")}
              <NavigateNextIcon className={classes.nextIcon} />
            </Button>
          </Box>
        </CardActions>
      </ModalActions>
    </Modal>
  );
};

NCreateWorkOrderModal.propTypes = propTypes;
NCreateWorkOrderModal.defaultProps = defaultProps;

export default NCreateWorkOrderModal;
export {
  propTypes as NCreateWorkOrderModalPropTypes,
  defaultProps as NCreateWorkOrderModalDefaultProps,
};

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import {
  useHistory,
  useLocation,
  useRouteMatch,
  matchPath,
} from "react-router-dom";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import DeleteAccountModal, {
  DeleteAccountModalDefaultProps,
  DeleteAccountModalPropTypes,
} from "../DeleteAccountModal";

/**
 *  Material UI Imports
 */
import { useTheme, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Button from "../Button";
import LoadingText from "../LoadingText";

/**
 * Hooks
 */
import { useUser, useMessage, useSidebar, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */

import { useStyles } from "./Sidebar.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  deleteModal: PropTypes.shape(DeleteAccountModalPropTypes),
  moduleTitleFn: PropTypes.func,
  showDeleteAccount: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      iconType: PropTypes.string,
      onClick: PropTypes.func,
      component: PropTypes.any,
    })
  ),
};

/**
 * Defines the default props
 */

const defaultProps = {
  deleteModal: DeleteAccountModalDefaultProps,
  moduleTitleFn: () => {},
  showDeleteAccount: false,
  menu: [],
};

/**
 * Displays the component
 */
const Sidebar = (props) => {
  const { showDeleteAccount, deleteModal, menu } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the history object
   */
  const history = useHistory();

  const { toggleSidebar, open, closeSidebar } = useSidebar();

  const [isSuspended, setIsSuspended] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const { user, setUser } = useUser();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the state of the modal
   */
  const [modalState, setModalState] = useState({
    deleteAcc: false,
  });

  /**
   * Gets the theme breakpoints
   */
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the location path
   */
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const { path } = match;

  /**
   * Defines the routing function
   */
  const routeTo = (link) => {
    if (downMd) toggleSidebar();
    return history.push(link);
  };

  /**
   * Defines all routes
   */
  const goToModulesMenu = () => routeTo("/modules");

  /**
   * Handles recovering the account
   */
  const recoverAccount = async () => {
    try {
      const recoveredAcc = await apiClient.put("/accounts/restore");
      if (recoveredAcc) {
        dispatchMessage({
          message: t("accountRecovered"),
        });
        setUser((prevState) => {
          return { ...prevState, refresh: true };
        });
      }
    } catch (error) {
      dispatchMessage({
        severity: "error",
        message: error,
      });
    }
  };

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the Delete Account Modal Handlers
   */
  const openDeleteModal = () => handleOpenModal("deleteAcc");
  const closeDeleteModal = () => handleCloseModal("deleteAcc");

  /**
   * Handles rendering the sidebar menu
   */
  const renderSidebarMenu = () => {
    return (
      menu &&
      menu.length > 0 &&
      menu.map((menuItem, idx) => {
        const handleClick = () => {
          return menuItem.onClick ? menuItem.onClick() : routeTo(menuItem.path);
        };

        /**
         * Checks if the current path matches the path of the menu item
         */
        const checkPathMatch = () => {
          const result = matchPath(location.pathname, menuItem.path);
          return result ? (result.isExact ? true : false) : false;
        };

        /**
         * Defines the list item classes
         */
        const listItemClasses = clsx({
          [classes.activeMenu]: checkPathMatch(),
        });

        return (
          <ListItem
            key={idx}
            button
            className={listItemClasses}
            onClick={handleClick}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText>
              <Typography className={classes.menuItem}>
                {t(menuItem.text)}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      })
    );
  };

  useEffect(() => {
    if (user.redirect && redirect) {
      const timer = setTimeout(() => {
        setUser((prevState) => {
          return { ...prevState, redirect: false };
        });
        history.push("/modules");
      }, 100);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user.account) {
      const isSuspended = user.account.status === "suspended";
      const isDeleted = user.account.status === "deleted";
      const isAdmin = user.type === "admin";
      const accSettings = path.includes("/modules/account-settings");

      setIsSuspended(isSuspended);
      if (isSuspended && !isAdmin) history.push("/modules");
      if (isSuspended && !accSettings && isAdmin) history.push("/modules");
      if (isDeleted) history.push("/modules");
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles closing sidebar on path change
   */
  useEffect(() => {
    if (open) {
      closeSidebar();
    }
    // eslint-disable-next-line
  }, [path]);

  return (
    <Fragment>
      <MenuIcon
        className={clsx(classes.menuIconClosed, {
          [classes.isOpen]: open,
        })}
        onClick={toggleSidebar}
      />
      <Button
        type="button"
        variant="filled"
        onClick={goToModulesMenu}
        className={clsx(classes.goBackContainer, {
          [classes.isOpen]: open,
        })}
      >
        <LoadingText
          loading={false}
          text={t("modulesMenu")}
          icon={<ArrowBackIcon />}
        />
      </Button>
      <Drawer
        open={open}
        className={classes.drawer}
        variant="persistent"
        anchor={"left"}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
        onClose={toggleSidebar}
      >
        <Toolbar />
        <Box className={classes.drawerContent}>
          <Box className={classes.drawerSection}>
            <List>
              <Fragment>{renderSidebarMenu()}</Fragment>
              {showDeleteAccount && isSuspended && user.type === "admin" && (
                <ListItem button>
                  <ListItemIcon>
                    <SettingsBackupRestoreIcon
                      className={classes.recoveryIcon}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      onClick={recoverAccount}
                      className={clsx(classes.menuItem, classes.recoveryText)}
                    >
                      {t("recoverAcc")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {showDeleteAccount && (
                <ListItem button onClick={openDeleteModal}>
                  <ListItemIcon>
                    <DeleteSweepIcon className={classes.deleteIcon} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      className={clsx(classes.menuItem, classes.deleteText)}
                    >
                      {t(isSuspended ? "deleteAcc" : "suspendAcc")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
        <DeleteAccountModal
          {...deleteModal}
          open={modalState.deleteAcc}
          closeModal={closeDeleteModal}
          redirect={redirect}
          setRedirect={setRedirect}
        />
      </Drawer>
    </Fragment>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
export { propTypes as SidebarPropTypes, defaultProps as SidebarDefaultProps };

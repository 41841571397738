/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    container: {
        padding: 8
    }
}));

export { useStyles };

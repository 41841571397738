const en_en = {
  "30 Days": "30 days",
  "<": "less than",
  "<=": "less than or equal than",
  "<=_label": "less than or equal than",
  "<_label": "less than",
  "=": "equals",
  "=_label": "equals",
  ">": "bigger than",
  ">=": "bigger than or equal than",
  ">=_label": "bigger than or equal than",
  ">_label": "bigger than",
  "Account settings": "Account Settings",
  AccountSettingsAppointmentGroups: "AccountSettingsAppointmentGroups",
  AccountSettingsCarTypes: "AccountSettingsCarTypes",
  AccountSettingsMain: "AccountSettingsMain",
  AccountSettingsOrders: "AccountSettingsOrders",
  AccountSettingsOrganizations: "AccountSettingsOrganizations",
  AccountSettingsPage: "AccountSettingsPage",
  AccountSettingsPaymentMethods: "AccountSettingsPaymentMethods",
  AccountSettingsSidebar: "AccountSettingsSidebar",
  AccountSettingsUpgrade: "AccountSettingsUpgrade",
  AccountSettingsUsers: "AccountSettingsUsers",
  AccountSettingsWorkers: "AccountSettingsWorkers",
  AddAppointmentGroupModal: "AddAppointmentGroupModal",
  AddAppointmentModal: "AddAppointmentModal",
  AddCarTypeModal: "AddCarTypeModal",
  AddClientModal: "AddClientModal",
  AddContractModal: "AddContractModal",
  AddLoyaltyCardModal: "AddLoyaltyCardModal",
  AddOrgContactModal: "AddOrgContactModal",
  AddOrganizationModal: "AddOrganizationModal",
  AddPaymentMethodModal: "AddPaymentMethodModal",
  AddProductConditionModal: "AddProductConditionModal",
  AddProductModal: "AddProductModal",
  AddTyreHotelModal: "AddTyreHotelModal",
  AddUserModal: "AddUserModal",
  AddWorkOrderProductDraftModal: "AddWorkOrderProductDraftModal",
  AddWorkOrderProductModal: "AddWorkOrderProductModal",
  AddWorkOrderServiceDraftModal: "AddWorkOrderServiceDraftModal",
  AddWorkOrderServiceModal: "AddWorkOrderServiceModal",
  AddWorkOrderWorkerModal: "AddWorkOrderWorkerModal",
  AddWorkerModal: "AddWorkerModal",
  AppointmentGroupsTable: "AppointmentGroupsTable",
  AppointmentsScheduler: "AppointmentsScheduler",
  AttendanceTable: "AttendanceTable",
  AuthPage: "AuthPage",
  BarcodeReader: "BarcodeReader",
  CARD: "CARD",
  CASH: "CASH",
  "Car Service": "Car Service",
  "Car Wash": "Car Wash",
  CarServiceMain: "CarServiceMain",
  CarServicePage: "CarServicePage",
  CarServiceSidebar: "CarServiceSidebar",
  CarTypesTable: "CarTypesTable",
  CarWashMain: "CarWashMain",
  CarWashPage: "CarWashPage",
  CarWashSidebar: "CarWashSidebar",
  ClientsSearchForm: "ClientsSearchForm",
  ClientsTable: "ClientsTable",
  CompanyContactDataBox: "CompanyContactDataBox",
  CompanyDataForm: "CompanyDataForm",
  ConfirmUpgradeModal: "ConfirmUpgradeModal",
  ContactDataForm: "ContactDataForm",
  ContactItem: "ContactItem",
  ContractCard: "ContractCard",
  ContractsSearchForm: "ContractsSearchForm",
  ContractsTable: "ContractsTable",
  CreateButton: "CreateButton",
  CreateWorkOrderModal: "CreateWorkOrderModal",
  CreateWorkOrderStage1: "CreateWorkOrderStage1",
  CreateWorkOrderStage2: "CreateWorkOrderStage2",
  CreateWorkOrderStage3: "CreateWorkOrderStage3",
  CreateWorkOrderStage4: "CreateWorkOrderStage4",
  DataTable: "DataTable",
  DeleteAccountModal: "DeleteAccountModal",
  DeleteAppointmentModal: "DeleteAppointmentModal",
  DeleteButton: "DeleteButton",
  DeleteClientModal: "DeleteClientModal",
  DeleteContractModal: "DeleteContractModal",
  DeleteLoyaltyCardModal: "DeleteLoyaltyCardModal",
  DeleteModal: "DeleteModal",
  DeleteProductModal: "DeleteProductModal",
  DeleteTyreHotelModal: "DeleteTyreHotelModal",
  DeleteUserModal: "DeleteUserModal",
  DeleteWorkOrderModal: "DeleteWorkOrderModal",
  DeleteWorkOrderTimerModal: "DeleteWorkOrderTimerModal",
  DeleteWorkerModal: "DeleteWorkerModal",
  DetailedAttendanceModal: "DetailedAttendanceModal",
  DocumentModal: "DocumentModal",
  DocumentPage: "DocumentPage",
  DocumentViewer: "DocumentViewer",
  DynamicSearchForm: "DynamicSearchForm",
  DynamicTable: "DynamicTable",
  EditAppointmentGroupModal: "EditAppointmentGroupModal",
  EditAppointmentModal: "EditAppointmentModal",
  EditButton: "EditButton",
  EditCarTypeModal: "EditCarTypeModal",
  EditClientModal: "EditClientModal",
  EditContractModal: "EditContractModal",
  EditLoyaltyCardModal: "EditLoyaltyCardModal",
  EditOrgAddressModal: "EditOrgAddressModal",
  EditOrgContactModal: "EditOrgContactModal",
  EditOrganizationModal: "EditOrganizationModal",
  EditPaymentMethodModal: "EditPaymentMethodModal",
  EditProductConditionModal: "EditProductConditionModal",
  EditProductModal: "EditProductModal",
  EditTyreHotelModal: "EditTyreHotelModal",
  EditUserModal: "EditUserModal",
  EditWorkOrderModal: "EditWorkOrderModal",
  EditWorkOrderProductDraftModal: "EditWorkOrderProductDraftModal",
  EditWorkOrderProductModal: "EditWorkOrderProductModal",
  EditWorkOrderServiceDraftModal: "EditWorkOrderServiceDraftModal",
  EditWorkOrderServiceModal: "EditWorkOrderServiceModal",
  EditWorkOrderSettingsModal: "EditWorkOrderSettingsModal",
  EditWorkOrderStage1: "EditWorkOrderStage1",
  EditWorkOrderStage2: "EditWorkOrderStage2",
  EditWorkOrderStage3: "EditWorkOrderStage3",
  EditWorkerModal: "EditWorkerModal",
  ErrorMessages: "ErrorMessages",
  "FACT TERMENI": "FACT TERMENI",
  "For each consecutive workplace, there's an additional discount of 33%": " ",
  Form: "Form",
  GDPR: "GDPR",
  GridViewMode: "GridViewMode",
  GuestSidebar: "GuestSidebar",
  Importer: "Importer",
  InfoButton: "InfoButton",
  InputAutocomplete: "InputAutocomplete",
  InputDate: "InputDate",
  InputDateRange: "InputDateRange",
  InputDateTime: "InputDateTime",
  InputNumeric: "InputNumeric",
  InputPassword: "InputPassword",
  InputPostCode: "InputPassword",
  InputSearch: "InputSearch",
  ListBox: "ListBox",
  LoadingBackdrop: "LoadingBackdrop",
  LoadingText: "LoadingText",
  LoginForm: "LoginForm",
  LoginPage: "Login page",
  LoyaltyCard: "LoyaltyCard",
  LoyaltyCards: "LoyaltyCards",
  LoyaltyCardsSearchForm: "LoyaltyCardsSearchForm",
  MessagePopup: "MessagePopup",
  Modal: "Modal",
  ModalActions: "ModalActions",
  ModalContent: "ModalContent",
  ModalTitle: "ModalTitle",
  ModuleCard: "ModuleCard",
  ModuleCardMini: "ModuleCardMini",
  ModulePackageCard: "ModulePackageCard",
  ModulePackages: "ModulePackages",
  ModuleSelectionPage: "ModuleSelectionPage",
  Modules: "Modules",
  ModulesFooter: "ModulesFooter",
  ModulesHeader: "ModulesHeader",
  ModulesModal: "ModulesModal",
  Nou: "new",
  OrderCard: "OrderCard",
  OrderItem: "OrderItem",
  Orders: "Orders",
  OrganizationItem: "OrganizationItem",
  OrganizationsTabs: "OrganizationsTabs",
  PDFNavigation: "PDFNavigation",
  PROTOCOL: "PROTOCOL",
  PageNotFound: "PageNotFound",
  PaymentMethodCard: "PaymentMethodCard",
  PaymentMethods: "PaymentMethods",
  PaymentMethodsTable: "PaymentMethodsTable",
  Popper: "Popper",
  PrintTimesheetReport: "PrintTimesheetReport",
  PrintTyreHotels: "PrintTyreHotels",
  PrintTyreHotelsClient: "PrintTyreHotelsClient",
  ProductCard: "ProductCard",
  ProductsSearchForm: "ProductsSearchForm",
  ProductsTable: "ProductsTable",
  PromotionCodeForm: "PromotionCodeForm",
  Providers: "Providers",
  REG: "Serial number in the trade register",
  ReCaptcha: "ReCaptcha",
  ReactVrSelect: "ReactVrSelect",
  RegisterPage: "RegisterPage",
  ReportsAdvanced: "ReportsAdvanced",
  ReportsAdvancedSearch: "ReportsAdvancedSearch",
  ReportsAdvancedTable: "ReportsAdvancedTable",
  ReportsCompanies: "ReportsCompanies",
  ReportsCompaniesObservationsTable: "ReportsCompaniesObservationsTable",
  ReportsCompaniesSearch: "ReportsCompaniesSearch",
  ReportsCompaniesTable: "ReportsCompaniesTable",
  ReportsCompaniesTableTotalization: "ReportsCompaniesTableTotalization",
  ReportsDaily: "ReportsDaily",
  ReportsDailySearch: "ReportsDailySearch",
  ReportsDailyTable: "ReportsDailyTable",
  ReportsMonthly: "ReportsMonthly",
  ReportsMonthlySearch: "ReportsMonthlySearch",
  ReportsMonthlyTable: "ReportsMonthlyTable",
  ReportsPage: "ReportsPage",
  ReportsSidebar: "ReportsSidebar",
  ReportsTyreWaste: "ReportsTyreWaste",
  ReportsTyreWasteSearch: "ReportsTyreWasteSearch",
  ReportsTyreWasteTable: "ReportsTyreWasteTable",
  ReportsWorkers: "ReportsWorkers",
  ReportsWorkersSearch: "ReportsWorkersSearch",
  ReportsWorkersTable: "ReportsWorkersTable",
  Routes: "Routes",
  SchedulerStatusChanger: "SchedulerStatusChanger",
  ScrollToTop: "ScrollToTop",
  SelectWorkOrderPaymentType: "SelectWorkOrderPaymentType",
  "Serviciu de anvelope": "Tyre Service",
  "Setările contului": "Account Settings",
  "Setările stației de lucru": "Workstation Settings",
  Sidebar: "Sidebar",
  SmallModuleCards: "SmallModuleCards",
  Span: "Span",
  SubmoduleContainer: "SubmoduleContainer",
  SubmoduleTitle: "SubmoduleTitle",
  SubmoduleWrapper: "SubmoduleWrapper",
  SuperAdminMain: "SuperAdminMain",
  SuperAdminPage: "SuperAdminPage",
  SuperAdminSidebar: "SuperAdminSidebar",
  TabPanel: "TabPanel",
  TableActions: "TableActions",
  TableCellHeader: "TableCellHeader",
  TableCellValue: "TableCellValue",
  TableHeader: "TableHeader",
  TablePagination: "TablePagination",
  TablePaginationActions: "TablePaginationActions",
  TablePopover: "TablePopover",
  TableResultsNotFound: "TableResultsNotFound",
  TableRows: "TableRows",
  TableSearchInput: "TableSearchInput",
  Terminat: "done",
  TextCount: "TextCount",
  TextMask: "TextMask",
  "This contains all modules (Tyre Service, Car Wash, Car Service) with all functionalities":
    "This contains all modules (Tyre Service, Car Wash, Car Service) with all functionalities",
  "This is the car service account with all functionalities":
    "This is the car service account with all functionalities",
  "This is the car wash account with all functionalities":
    "This is the car wash account with all functionalities",
  "This is the tyre service account with all functionalities":
    "his is the tyre service account with all functionalities",
  TimesheetAdminView: "TimesheetAdminView",
  TimesheetLogEntry: "TimesheetLogEntry",
  TimesheetModal: "TimesheetModal",
  TimesheetReport: "TimesheetReport",
  Tooltip: "Tooltip",
  "Tyre Service": "Tyre Service",
  TyreHotelCard: "TyreHotelCard",
  TyreHotelsChoiceModal: "TyreHotelsChoiceModal",
  TyreHotelsSearchForm: "TyreHotelsSearchForm",
  TyreHotelsTable: "TyreHotelsTable",
  TyreServiceControlPanel: "TyreServiceControlPanel",
  TyreServiceCreateWorkOrders: "TyreServiceCreateWorkOrders",
  TyreServiceMain: "TyreServiceMain",
  TyreServicePage: "TyreServicePage",
  TyreServiceScheduler: "TyreServiceScheduler",
  TyreServiceSidebar: "TyreServiceSidebar",
  TyreServiceTyreHotels: "TyreServiceTyreHotels",
  TyreServiceWorkOrderTable: "TyreServiceWorkOrderTable",
  TyreServiceWorkOrdersTable: "TyreServiceWorkOrdersTable",
  UpgradeExplanation: "UpgradeExplanation",
  UpgradePackageModal: "UpgradePackageModal",
  "User with this username does not exist!": " ",
  UserCard: "UserCard",
  UserDataForm: "UserDataForm",
  UserOrganizations: "UserOrganizations",
  Users: "Users",
  UsersTable: "UsersTable",
  VAT: "C.I.F / C.U.I",
  ViewMode: "ViewMode",
  ViewOrgContactModal: "ViewOrgContactModal",
  ViewPriceConditionsModal: "ViewPriceConditionsModal",
  ViewWorkOrderModal: "ViewWorkOrderModal",
  Widget: "Widget",
  Widgets: "Widgets",
  WorkOrderCard: "WorkOrderCard",
  WorkOrderDisclaimer: "WorkOrderDisclaimer",
  WorkOrderHeader: "WorkOrderHeader",
  WorkOrderSearchForm: "WorkOrderSearchForm",
  WorkOrderServices: "WorkOrderServices",
  WorkOrderSignature: "WorkOrderSignature",
  WorkOrderTable: "WorkOrderTable",
  WorkOrderTimerCard: "WorkOrderTimerCard",
  WorkOrderView: "WorkOrderView",
  WorkOrderWorkers: "WorkOrderWorkers",
  WorkOrdersTable: "WorkOrdersTable",
  WorkerAttendanceCard: "WorkerAttendanceCard",
  WorkerAttendanceModal: "WorkerAttendanceModal",
  WorkerCard: "WorkerCard",
  WorkersTable: "WorkersTable",
  "Workstation settings": "Workstation Settings",
  WorkstationSettingsContracts: "WorkstationSettingsContracts",
  WorkstationSettingsLoyaltyCards: "WorkstationSettingsLoyaltyCards",
  WorkstationSettingsPage: "WorkstationSettingsPage",
  WorkstationSettingsProducts: "WorkstationSettingsProducts",
  WorkstationSettingsSidebar: "WorkstationSettingsSidebar",
  WorkstationSettingsTyreHotels: "WorkstationSettingsTyreHotels",
  WorkstationSettingsWorkOrders: "WorkstationSettingsWorkOrders",
  "Wrong password!": "Wrong password, try again.",
  aboutGDPR: "About GDPR",
  acceptTermsAndConditions: "I accept the terms and conditions.",
  accountChanged: "Account updated.",
  accountCreated: "Account created.",
  accountData: "Account data",
  accountDeleted: "Account deleted.",
  accountDeletedPermanently: "This account is permanently disabled.",
  accountDeletedSuccessfuly: "Account deleted.",
  accountRecovered: "The account was recovered.",
  accountSettings: "Account Settings",
  accountStatus: "Account status",
  accountType: "Account type",
  actions: "Operations",
  activate: "Activate",
  activated: "Service activated",
  active: "Active",
  activeService: "Active service",
  activeUntil: "Active until",
  add: "Add",
  addContact: "Add contact",
  addNew: "Add result",
  addNewCard: "Add a new card",
  addNewClient: "Add Client",
  addNewContract: "Add Contract",
  addNewHotel: "Add hotel",
  addNewOrganization: "Add Organization",
  addNewProduct: "Add Product",
  addNewUser: "Add User",
  addNewWorker: "Add worker",
  addProduct: "Add product",
  addService: "Add service",
  addWorkerBtn: "Add",
  address: "Address",
  admin: "Administrator",
  adrLabel: "Address",
  advSearchActive: "Advanced search (activated)",
  advSearchInactive: "Advanced search (deactivated)",
  advanced: "Detailed report",
  advancedReports: "Advanced reports",
  all: "All",
  allOrgs: "All",
  appointmentDeleted: "Appointment deleted.",
  appointmentGroups: "Appointment Groups",
  appointment_group_id: "Select group",
  areYouSureYouWantToDelete: "Are you sure you want to delete ",
  areYouSureYouWantToDeleteTheAppointment:
    "Are you sure you want to delete the appointment?",
  areYouSureYouWantToDeleteTheCard:
    "Are you sure you want to delete the card with the number ",
  areYouSureYouWantToDeleteTheClient:
    "Are you sure you want to delete the client ",
  areYouSureYouWantToDeleteTheContract:
    "Are you sure you want to delete the contract ",
  areYouSureYouWantToDeleteTheProduct:
    "Are you sure you want to delete the product ",
  areYouSureYouWantToDeleteTheWorkOrder:
    "Are you sure you want to delete the order ",
  areYouSureYouWantToDeleteTheWorker:
    "Are you sure you want to delete the worker ",
  areYouSureYouWantToDeleteTheAccount:
    "Are you sure you want to delete the account associated with ",
  asc: "Ascendent",
  attendanceReport: "Timesheet report",
  backToMenu: "Back to menu",
  baseHelper: "Only numbers / letters and spaces are allowed.",
  baseReductionExplanation: " ",
  billingInfo: "Billing info",
  birth_date: "Birth date",
  birth_dateLabel: "Birth date",
  boltTorque: "Bolt Torque",
  bolt_torqueLabel: "Bolt Torque",
  cancelLabel: "Cancel",
  car: "Car",
  carDescription: "Description",
  carKm: "Car km",
  carMake: "Car manufacturer",
  carModel: "Model",
  carPlate: "Car plate",
  carService: "Car Service",
  carType: "Car type",
  carTypeCreated: "Car type created.",
  carTypes: "Car types",
  carWash: "Car Wash",
  car_description: "DOT / Mentions",
  car_km: "Car km",
  car_kmLabel: "Car km",
  car_modelLabel: "Car model",
  car_number: "Car plate",
  car_numberLabel: "Car plate",
  car_plate_number: "Plate number",
  car_plate_numberLabel: "Car plate",
  car_type: "Type",
  car_typeLabel: "Car type",
  car_type_id: "Car type",
  car_types: "Car types",
  cardAdded: "Card added",
  cardDeleted: "Card deleted.",
  cardFound: "Loyalty card identified.",
  cardModified: "Card updated.",
  cardNotFound: "We're sorry, we haven't found a card with the code ",
  card_numberLabel: "Card number",
  card_typeLabel: "Card type",
  change_status: "Change status",
  changesUpdatedSuccessfuly: "Changes updated succesffuly.",
  charText: "characters",
  characters: "characters.",
  cityLabel: "City",
  cityPlaceholder: "Eg: Cluj-Napoca",
  clearStatus: "Clear status",
  client: "File - Client",
  clientCreated: "Client created",
  clientData: "Client data",
  clientDeleted: "Client deleted.",
  clientLabel: "Client name",
  clientModified: "Client updated.",
  clientName: "Name",
  clientType: "Client type",
  client_name: "Client",
  client_nameLabel: "Client name",
  clients: "Clients",
  clientsLabel: "Client",
  close: "Close",
  closeList: "Close",
  companies: "Company report",
  companiesReports: "Companies reports",
  company: "Legal entity",
  companyData: "Company data",
  companyName: "Company",
  company_emailLabel: "Email",
  company_emailPlaceholder: "Eg: office@pangus.ro",
  company_nameLabel: "Company name",
  company_namePlaceholder: "Eg: SC Atelierulmeu SRL",
  company_phoneLabel: "Phone number",
  company_phonePlaceholder: "Eg: 0123 123 123",
  company_regLabel: "Reg. Number",
  company_time_sheetLabel: "Timesheet",
  company_time_sheetPlaceholder:
    "Eg: M-F 08:00 - 18:00 | Sat 08:00 - 13:00 | Sun Closed",
  company_vatLabel: "C.U.I / C.I.F",
  company_webpageLabel: "Website",
  company_webpagePlaceholder: "Eg: www.pangus.ro",
  conditionAdded: "Condition added",
  conditionDeleted: "Condition deleted.",
  conditionLabel: "Condition",
  conditionModified: "Condition updated.",
  config: "Config",
  confirmAttendance: "Send",
  confirmYourPassword: "Confirm your password",
  confirm_textLabel: "Please confirm the company name",
  contactData: "Contact data",
  contactDeleted: "Contract deleted.",
  contactMe: "Contact us",
  contactUs: "Contact us",
  contacts: "Contacts",
  contractAdded: "Contract added",
  contractCreated: "Contact created",
  contractDeleted: "Contract deleted.",
  contractModified: "Contract updated.",
  contract_number: "Contract number",
  contract_numberLabel: "Contract number",
  contracts: "Contracts",
  controlPanel: "Control Panel",
  cookiePolicy: "Cookie policy",
  cookieSettings: "Cookie settings",
  copyRightText: "© {{year}} Atelierulmeu. All rights reserved.",
  count: "#",
  countSymbol: "#",
  countryLabel: "Country",
  countryPlaceholder: "Eg: Romania",
  countyLabel: "County",
  countyPlaceholder: "Eg: Cluj",
  create: "Create work order",
  createWorkOrder: "Create work order",
  createdBy: "Created by",
  createdSuccessfully: " created successfully.",
  created_at: "Created at",
  created_atLabel: "Created at",
  cuiLabel: "C.I.F / C.U.I",
  cumulatedReductionExplanation: " ",
  cumulatedReductionExplanationExample:
    "Eg: Workplace 1 has 33% discount, workplace 2 will have 66% discount. Workplace 3 will be free and workplace 4 will start again at 33% discount.",
  daily: "Daily report",
  dailyReports: "Daily reports",
  dashboard: "Dashboard",
  dataUpdated: "Data updated.",
  date: "Date",
  day: "Day",
  day_1: "Monday",
  day_2: "Tuesday",
  day_3: "Wednesday",
  day_4: "Thursday",
  day_5: "Friday",
  day_6: "Saturday",
  day_7: "Sunday",
  days: "days",
  delegate: "Delegate name",
  delegate_name: "First Name / Last Name",
  delegate_nameLabel: "Delegate name",
  deletHotel: "Delete hotel",
  delete: "Delete",
  deleteAcc: "Delete account",
  deleteAppointment: "Delete",
  deleteCard: "Delete card",
  deleteCondition: "Delete condition",
  deleteConfirm: "Delete",
  deleteConfirmation:
    "Are you sure you want to delete the account associated with ",
  deleteConfirmationText: "Are you sure you want to delete",
  deleteContact: "Delete",
  deleteItem: "Delete",
  deleteNoCardNum: "Are you sure you want to delete this card ?",
  deleteOrganization: "Delete",
  deleteSuccessMessage: "Deleted successfully",
  deleteUser: "Delete",
  deleteWorkOrder: "Delete order",
  deleted: "Account permanently suspended",
  deletedMessage: "Payment method successfully deleted.",
  deleted_success: "Account disabled.",
  deletegateName: "Delegate name",
  deletingAccount: " ",
  desc: "Descendent",
  description: "Description",
  descriptionLabel: "Description",
  descriptionLabel_1: "Mentions",
  descriptionLabel_10: "Description",
  descriptionLabel_11: "Description",
  descriptionLabel_12: "Description",
  descriptionLabel_13: "Mentions",
  descriptionLabel_14: "Description",
  descriptionLabel_15: "Description",
  descriptionLabel_16: "Description",
  descriptionLabel_17: "Description",
  descriptionLabel_18: "Description",
  descriptionLabel_19: "Description",
  descriptionLabel_2: "Description",
  descriptionLabel_20: "Description",
  descriptionLabel_21: "Description",
  descriptionLabel_22: "Description",
  descriptionLabel_23: "Description",
  descriptionLabel_3: "Client description",
  descriptionLabel_4: "Description",
  descriptionLabel_5: "Description",
  descriptionLabel_6: "Description",
  descriptionLabel_7: "Description",
  descriptionLabel_8: "Description",
  descriptionLabel_9: "Description",
  detailed: "Detailed",
  disabled: "Inactive module",
  disclaimer: "Only the orders within the last 12 hours are being displayed.",
  tyreServiceDisclaimerText1:
    "The service unit guarantees the executed work *) and its conformity **), according to OUG140/2021 * 3 months from the date of receipt of the vehicle, if the work did not require replacement of parts or if it was executed with the customer's parts",
  tyreServiceDisclaimerText2:
    "** Warranty of parts supplied by the unit, based on OUG140/2021 - according to the manufacturer's declaration / by agreement between the parties, conditionally, resp. the operating instructions provided by the vehicle manufacturer is ___ months",
  tyreServiceDisclaimerText3:
    "*** At the request of the BENEFICIARY, the unit will ensure the visualization of the activities carried out and records proving the performance of the final inspections.",
  tyreServiceDisclaimerText4: "**** We do not guarantee the parts brought by the customer.",
  tyreServiceDisclaimerTitle: "Certificate of quality and guarantee",
  discount: "Discount",
  discountCalcFormula: "Discount calculation formula",
  discountLabel: "Discount ",
  discountTotal: "Calculated discount",
  discount_typeLabel: "Discount type",
  discount_valueLabel: "Discount",
  document_prefixLabel: "Prefix document",
  document_prefixPlaceholder: " ",
  document_suffixLabel: "Sufix document",
  document_suffixPlaceholder: " ",
  documents: "Documents",
  done: "Done",
  doneWorkOrders: "Finished",
  downTrend: "The new price is lower than the standard price.",
  download: "Download",
  downloadLabel: "Download bill",
  duration: "Duration",
  durationLabel: "Service duration",
  edit: "Edit",
  editAddress: "Edit address",
  editAppointment: "Edit",
  editCard: "Edit card",
  editCondition: "Edit condition",
  editContact: "Edit contact",
  editHotel: "Edit hotel",
  editInfo: "Edit info",
  editItem: "Edit",
  editOrganization: "Edit organization data",
  editUser: "Edit worker",
  editWorkOrder: "Edit order",
  email: "Company email address",
  emailLabel: "Email",
  end: "End date",
  endAttendance: "End attendance",
  endDate: "End date",
  endDateLabel: "End date",
  error_emailInvalid: "Please provide a valid email.",
  error_invalidNumber: "Not a valid number.",
  error_invalidPostalCode: "Only numbers / letters and spaces are allowed.",
  error_isRequired: "Required field.",
  error_maxLength: "It must be at most ",
  error_minLength: "It must be at least ",
  error_noPasswordConfirm: "Please confirm your password",
  error_passwordsNotMatch: "Passwords must match",
  error_telNumInvalid: "Phone number is invalid.",
  error_telNumNoDigits: "Needs to have at least 4 digits",
  error_urlInvalid: "Please provide a valid URL.",
  expendable: "Expendable",
  expendable_products: "Expendable products",
  explanation: "How is the price calculated?",
  extend: "Extend",
  facebook: "Facebook",
  false: "No",
  female: "Female",
  fidelity: "Client fidel",
  fidelityLabel: "Loyalty client",
  fieldLabel: "Field",
  finalizeRegister: "Finalize",
  finished: "Finalized",
  finishedLabel: "Finished",
  firstPage: "First page",
  first_nameLabel: "First Name",
  flatTrend: "The new price is the same as the standard price.",
  forgotPassword: "Forgot your password?",
  free30: "Free for 30 days!",
  from: "From",
  fromLabel: "From",
  function: "Role",
  functionLabel: "Role",
  functionPlaceholder: "Eg: Tester",
  gender: "Sex",
  genderLabel: "Sex",
  genderMissing: " - ",
  generalAndWorkers: "General data / Workers",
  generalInfo: "General Info",
  groupCreated: "Group created successfully",
  groupless: "Groupless",
  groups: "Filter by groups",
  groupsPlaceholder: "Search group",
  guaranteeForms: "Guarantee forms",
  haveAnAccount: "I already have an account.",
  helperText: " ",
  home: "Home",
  hotelChanged: "Hotel updated.",
  hotelData: "Deposit tyres",
  hotels: "Tyre Hotels",
  hoursWorked: "Hours worked",
  id: "Order id",
  if: "if",
  import: "Upload excel",
  "in-progress": "In progress",
  invalid_promo: "Discount code is invalid.",
  ireversible: "This action is ireversible.",
  is: "is ",
  isCountPrice: "Price activated",
  is_active: "Activated",
  is_activeLabel: "Card activated",
  is_company: "Legal entity",
  is_companyLabel: "Legal entity",
  is_count_price: "Price activated",
  is_count_priceLabel: "Price activated",
  is_expendable: "Expendable",
  is_expendableLabel: "Expendable",
  is_inactive: "Deactivated",
  is_service: "Service",
  is_serviceLabel: "Service",
  itemName: "Name",
  lastAttendance: "Last update",
  lastPage: "Last page",
  lastWeek: "Last week",
  last_nameLabel: "Last Name",
  left: "left",
  linkedin: "LinkedIn",
  loginBtnText: "Login",
  logout: "Logout",
  loyaltyCard: "Loyalty card",
  loyaltyCards: "Loyalty cards",
  loyaltyClients: "Loyal clients",
  loyaltyTitle: "Loyalty card",
  loyalty_card: "Loyalty Card",
  main: "Dashboard",
  mainLogoTitle: "My Workshop",
  makeLabel: "Manufacturer",
  male: "Male",
  messages: "Messages",
  modelLabel: "Model",
  modifiedSuccessfuly: "Updated successfully.",
  "module.Reports": "Reports",
  modulePrice: "Module price",
  modules: "Modules",
  modulesMenu: "Back to menu",
  month: "(month)",
  monthly: "Monthly report",
  monthlyReports: "Monthly reports",
  months: "(months)",
  more: "More",
  moreActions: "More actions",
  mountingStation: "Mounting Stations",
  mountingStations: "Mounting stations",
  myAccount: "My account",
  name: "Product name",
  nameLabel: "Name",
  productNameLabel: "Product name",
  searchProductNameLabel: "Search for product by name",
  serviceNameLabel: "Service name",
  searchServiceNameLabel: "Search for service by name",
  new: "New",
  newTyre: "New tyres",
  new_priceBecomes: "the new price becomes",
  new_priceLabel: "New price",
  new_tyreLabel: "New tyre",
  new_tyres: "New tyres",
  next: "Next step",
  nextWeek: "Next week",
  noBarcode: "We're sorry, no barcode was detected.",
  noGDPR: "It is necessary to accept the cookies in order to continue.",
  noRecentlyAddedOrdersFound: "Not recently added orders found.",
  noResultsFound: "No results found. Please try the options bellow.",
  none: "None",
  normalClients: "Clients",
  notActivated: " Inactive price",
  notCountPriceExplanation:
    "The price of the product is deactivated, this needs to be modified in the 'Products' section.",
  notFound: "The page is not available",
  notFoundExtended: " ",
  notFoundNoHelpers:
    "No results found. Please try reseting the search filters.",
  now: "now ",
  numberLabel: "Number",
  observation: "Observation",
  observations: "Observations",
  ok: "OK",
  "old-data": "Old data",
  "on-hold": "On hold",
  operations: "Operations",
  operator: "Operator",
  operatorName: "Operator name",
  orderByLabel: "Ordered by",
  orderData: "Order details",
  orderDetails: "Order details",
  orderDirLabel: "Order",
  orderID: "Order number",
  orderNumber: "Order number",
  order_by: "Order by",
  order_dir: "Order",
  order_idLabel: "Order id",
  orders: "Orders",
  org: "Organization",
  orgLabel: "Company",
  organization: "Organization",
  organizationCreated: "Organization created",
  organizationLabel: "Organization",
  organization_client_id: "Client",
  organization_client_idLabel: "Select client",
  organization_id: "Organizations",
  organization_idLabel: "Workplace",
  organizations: "Organizations",
  organizationsCount: "Workplaces count",
  outOf: " out of ",
  page: "Page",
  pageNotAvailable: "Page not available",
  pageNotFound: "Page not found",
  pageSizeLabel: "Items per page",
  paid: "Paid",
  password: "Password",
  passwordHelperText: "The password must have at least 6 characters.",
  passwordLabel: "Password",
  pause: "Pause",
  payment: "Payment",
  paymentDetails: "Payment details",
  paymentMethodChanged: "Payment method updated.",
  paymentMethodCreated: "Payment method created.",
  paymentMethods: "Payment Methods",
  paymentType: "Payment method",
  paymentTypes: "Payment methods",
  payment_type: "Payment type",
  payment_typeLabel: "Payment method",
  payment_type_id: "Payment method",
  payment_type_idLabel: "Payment method",
  payment_types: "Payment methods",
  percent: "Percentage",
  percent_1: "Procentaj",
  percent_2: "Percentage",
  person: "Individual",
  piece: "PC",
  phone: "Phone number",
  phoneAllowedChars: "Only numbers, -, +, ( ) and spaces are allowed.",
  phoneLabel: "Phone",
  phone_fixLabel: "Fix phone",
  phone_mobileLabel: "Mobile phone",
  physical: "Physical",
  physical_products: "Physical products",
  plate: "Car plate",
  plate_number: "Plate number.",
  plate_numberLabel: "Plate number",
  popoverTitle: "Select status",
  post_codeLabel: "Post code",
  previous: "Previous step",
  price: "Price",
  priceCalcFormula: "Price calculation formula",
  priceLabel: "Price",
  price_conditions: "Price conditions",
  print: "Print",
  printReport: "Print report",
  printWorkOrder: "Print Work Order",
  privacy: "Privacy policy",
  privacyPolicy: "Privacy policy",
  product: "Product name",
  productAdded: "Product added.",
  productChanged: "Product updated.",
  productDeleted: "Product deleted.",
  products: "Products",
  productsEmpty: "Please add at least one product or service.",
  products_name: "New Products",
  programmingAdded: "Appointment added.",
  progress: "In progress",
  progress_saved: "Data updated.",
  promoText: "With us you can keep it all under control!",
  promotionLabel: "Discount coupon",
  promotion_codeLabel: "Discount Code",
  qty: "Quantity",
  quantity: "Quant",
  quantityLabel: "Quantity",
  quick_text: "Quick search",
  quick_textLabel: "Quick search",
  rangeLabel: "Time range",
  recentlyCreated: "Recently added",
  recoverAcc: "Recover account",
  regLabel: "Reg. number",
  reg_number: "Reg. Number",
  reg_numberLabel: "Serial number in the trade register",
  reg_numberPlaceholder: "Eg: J12/345/2000",
  registerLabel: "Don't have an account yet? Sign up!",
  requested_services: "Requested services",
  reset: "Reset",
  resetFilters: "Reset the searching options.",
  resetSearch: "Reset the search.",
  resume: "Resume",
  returnForms: "Return forms",
  reversible: "This action is reversible.",
  role: "Role",
  roleMissing: " - ",
  rotateLeft: "Rotate left",
  rotateRight: "Rotate right",
  rowsPerPage: "Rânduri pe pagină",
  save: "Save",
  scanCard: "Scan card",
  scanningPossible: "The scanning of a loyalty card is possible.",
  scheduler: "Appointments",
  selectModule: "Select module",
  selectProduct: "Select a service",
  send: "Send",
  serverDown:
    "Oops! The workshop seems to be unavailable at the moment. We are doing our best to bring the platform back online. Please try again later.",
  service: "Service",
  serviceAdded: "Service added.",
  serviceBought: "Service bought",
  serviceChanged: "Service updated.",
  serviceDeleted: "Service deleted.",
  serviceInterval: "Active service time interval",
  service_products: "Services",
  services: "Services",
  servicesExecuted: "Executed services",
  servicesRequested: "Requested services",
  short_name: "Short name",
  short_nameLabel: "Short name",
  shownResults: "Results displayed.",
  signature: "Signature",
  signatureClient: "Client signature",
  signatureCompany: "Company signature",
  skipped: "Skipped",
  socialMedia: "Social media",

  stage1: "General Data",
  stage2: "Client Data",
  stage3: "Products / Services",
  stage4: "Summary",
  editStage1: "Client Data",
  editStage2: "Products / Service",
  editStage3: "Summary",

  standardPrice: "Standard price",
  start: "Start",
  startAttendance: "Start attendance",
  startDate: "Start date",
  startDateLabel: "Start date",
  startTimer: "Start",
  status: "Status",
  statusLabel: "Order status",
  step0Message: "Please complete the form bellow with your company data.",
  step1Message: " Please provide your contact information.",
  step2Message: "Choose a username and a password.",
  step3Message:
    "If you have a discount code, you can input it bellow, otherwise you can finalize the process.",
  stop: "Stop",
  stopTimer: "Stop timer",
  streetLabel: "Street",
  streetPlaceholder: "Company headquarters",
  submitAdd: "Add",
  submitAddContact: "Add contact",
  submitConfirmOrder: "Confirm order",
  submitEdit: "Edit",
  submitFinalize: "Finalize",
  submitPlaceOrder: "Place order",
  submitSave: "Save",
  submitSend: "Send",
  submitSearch: "Search",
  submitSearchClient: "Search client",
  submitSearchContract: "Search contract",
  submitSearchWorker: "Search worker",
  submitSearchCard: "Search card",
  submitSearchProduct: "Search product",
  submitSearchHotel: "Search hotel",
  submitSearchOrder: "Search order",
  subtotal: "Subtotal",
  success: "Updated successfully.",
  successMessage: "Operation completed successfully",
  successMessageEntry: "Entry added.",
  successMessageExit: "Exit added.",
  successMessage_54: "Data updated.",
  successMessage_55: "Data updated.",
  successMessage_56: "Modified successfully",
  successMessage_57: "Status updated.",
  summary: "Summary",
  "super-admin": "Super Admin",
  superAdmin: "Super Admin",
  suspendAcc: "Suspend account",
  suspendConfirm: "Suspend",
  suspendConfirmation: " ",
  suspended: "Suspended",
  suspended_success: "Account suspended.",
  suspendingAccount: " ",
  tax: "Tax",
  terms: " ",
  termsAndConditions: "Terms and conditions",
  termsLabel: "Terms and conditions",
  then: " then ",
  time: "Time / Date",
  timeInterval: "Time interval",
  timeLeft: "Active service time left",
  time_interval: "Time range",
  time_sheetLabel: "Time sheet",
  time_sheetPlaceholder: "Ex",
  timesheet: "Timesheet",
  timesheetReport: "Timesheet report - ",
  titleAddAppointment: "Add appointment",
  titleAddCarType: "Add car type",
  titleAddClient: "Add Client",
  titleAddCondition: "Add condition",
  titleAddContact: "Add Contact",
  titleAddContract: "Add contract",
  titleAddGroup: "Add group",
  titleAddLoyaltyCard: "Add loyalty card",
  titleAddNewProduct: "Add a new product",
  titleAddNewService: "Add a new service",
  titleAddNewWorker: "Add worker",
  titleAddOrganization: "Add Organization",
  titleAddPaymentMethod: "Add payment method",
  titleAddProduct: "Add product",
  titleAddTyreHotel: "Add tyre hotel",
  titleAddUser: "Add user",
  titleAddWorker: "Add worker",
  titleApp: "My Workshop",
  titleAppointmentGroups: "Appointment Groups",
  titleAppointments: "Appointments",
  titleAttendanceRule: "Attendance rule",
  titleAvailableModules: "Available modules",
  titleCarTypes: "Car types",
  titleChooseDate: "Choose date",
  titleChoosePrintingMethod: "Please choose one of the printing methods.",
  titleClients: "Clients",
  titleCompanyReport: "Company reports",
  titleConfirmOrder: "Confirma order",
  titleContracts: "Contracts",
  titleControlPanel: "Control Panel",
  titleCreateNewWorkOrder: "Create new work order",
  titleCreateWorkOrders: "Create work orders",
  titleDashboard: "Dashboard",
  titleDeleteOrder: "Delete order ",
  titleDetailedReport: "Detailed report",
  titleDetailedTimesheet: "Detailed timesheet",
  titleEditAddress: " Edit address",
  titleEditAppointment: "Edit Appointment",
  titleEditCarType: "Edit car type - ",
  titleEditClient: "Edit Client",
  titleEditCondition: "Edit condition",
  titleEditContact: "Edit contact - ",
  titleEditContract: "Edit contract",
  titleEditGeneralInfo: " Edit general info",
  titleEditLoyaltyCard: "Edit loyalty card",
  titleEditOrganization: "Edit Organization - ",
  titleEditPaymentMethod: "Edit metoda de plată - ",
  titleEditProduct: "Edit product - ",
  titleEditService: "Edit serviciul - ",
  titleEditTyreHotel: "Edit tyre hotels",
  titleEditUser: "Edit User - ",
  titleEditWorkOrder: "Edit work order ",
  titleEditWorker: "Edit worker - ",
  titleLogin: "Login",
  titleLoyaltyCards: "Loyalty cards",
  titleModifyGroup: "Edit group - ",
  titleOrders: "Orders",
  titleOrganizations: "Organizations",
  titlePaymentMethods: "Payment Methods",
  titlePriceConditionsFor: "Condiții de preț pentru ",
  titleProducts: "Products",
  titleRegister: "Sign up",
  titleReportDaily: "Daily report",
  titleReportMonthly: "Monthly report",
  titleReportTyreWaste: "Waste report",
  titleReportWorkers: "Workers report",
  titleSelectModule: "Select one of the available modules",
  titleTimesheet: "Timesheet ",
  titleTyreHotels: "Tyre hotels",
  titleTyreHotelsDeposit: "Deposit / Tyre hotel",
  titleUpdateAccount: "Update",
  titleUpdateActiveService: "Update active service",
  titleUsers: "Users",
  titleWorkOrder: "Order ",
  titleWorkOrders: "Work orders",
  titleWorkers: "Workers",
  to: "To",
  toLabel: "To",
  today: "Today",
  total: "Total",
  totalHours: "Total hours worked",
  totalHoursWorked: "Total hours worked",
  totalLabel: "Total",
  totalTitle: "Total report",
  totalization: "Totalization",
  totalizationTitle: "Monthly report totalization",
  totalized: "Company monthly reports totalization",
  true: "Yes",
  type: "Account Type",
  typeLabel: "Account type",
  tyreDim: "Dim. Anvelope",
  tyreDimensions: "Tyre dimensions",
  tyreHotel: "Hotel / Deposit tyres",
  tyreHotelDeleted: "Hotel deleted.",
  tyreHotelDescription: "Description",
  tyreHotelsAdded: "Hotel added.",
  tyrePressure: "Tyre pressure",
  tyreService: "Tyre Service",
  tyre_dim: "Tyre dimensions",
  tyre_dimension: "Tyre dimensions",
  tyre_dimensions: "Tyre dimensions",
  tyre_height: "tyre height",
  tyre_heightLabel: "Tyre height",
  tyre_name: "Name",
  tyre_nameLabel: "Tyre name",
  tyre_pressure: "tyre pressure",
  tyre_pressureLabel: "Tyre pressure",
  tyre_quantity: "Quantity",
  tyre_quantityLabel: "Quantity",
  tyre_rim: "tyre rim",
  tyre_rimLabel: "Tyre rim",
  tyre_waste: "tyre waste",
  tyre_wasteLabel: "Tyre waste",
  tyre_width: "tyre width",
  tyre_widthLabel: "Tyre width",
  tyres: "Tyres",
  um: "UM",
  umLabel: "Unit of measure",
  undo: "Reset the search options",
  undoRotation: "Undo rotation",
  undoZoom: "Undo zoom",
  upTrend: "The new price is higher than the standard price.",
  updateStatus: "Edit statutul",
  updatedAt: "Date",
  upgrade: "Upgrade",
  user_idLabel: "Associated with the account ",
  username: "Name",
  usernameLabel: "Username",
  users: "Users",
  uuid: "Order number",
  value: "Value (RON)",
  valueLabel: "Value",
  value_1: "Value (RON)",
  value_2: "Value",
  vat_number: "C.I.F / C.U.I",
  vat_numberLabel: "C.I.F / C.U.I",
  vat_numberPlaceholder: "Eg: CIF: RO123456",
  view: "View",
  viewAdmin: "Admin view",
  viewContact: "View contact",
  viewMode: "View mode",
  viewNormal: "Workshop view",
  viewPriceConditions: "Open",
  viewWorkOrder: "View work order",
  views: "Time interval",
  vin_numberLabel: "Vin number",
  waitingWorkOrders: "In progress",
  warehouse: "Labels - Warehouse",
  waste: "Waste report",
  wasteReports: "Waste reports",
  webpageLabel: "Pagina web",
  week: "Week",
  welcomeBack: "Welcome back!",
  wheelTypes: "Wheel types",
  wheel_type: "Wheel type",
  wheel_typeLabel: "Wheel type",
  wheel_types: "Tyres",
  wheels: "Tyres",
  withGroupingLabel: "View per groups",
  "work-orders-daily": "Work Orders - Today",
  "work-orders-monthly": "Work Orders - Monthly",
  "work-orders-weekly": "Work Orders - Weekly",
  workInterval: "Work interval",
  workOrder: "Work Order",
  workOrderCreated: "Order created.",
  workOrderDeleted: "Order deleted.",
  workOrderLabel: "Order",
  workOrderSettings: "Work order settings",
  workOrderType: "Work order type",
  workOrders: "Work orders",
  workPlace: "Workplace",
  work_order_id: "Work order id",
  work_order_type: "Work order type",
  work_order_typeLabel: "Work order type",
  work_order_type_id: "Work order type",
  work_order_types: "Work Orders",
  work_order_uuid: "Work order id",
  work_order_uuidLabel: "Work order",
  work_orders_count: "Work orders totalized quantity",
  work_week: "Week",
  worked_hours: "Total worked hours",
  worker: "Worker",
  workerChanged: "Worker updated.",
  workerCreated: "Worker added.",
  workerDeleted: "Worker deleted.",
  workerLabel: "Utilizator",
  workers: "Workers",
  workersLabel: "Search by name",
  workersReports: "Workers reports",
  workersSearchLabel: "Search worker",
  workstationSettings: "Workstation Settings",
  zoomIn: "Zoom in",
  zoomOut: "Zoom out",
  addTyreHotel: "Add tyre hotel",
  deleteTyreHotelForm: "Remove tyre hotel",
  carServiceDisclaimerTitle: "Certificate of quality and guarantee",
  carServiceDisclaimerText1:
    "The terms of execution of the services ordered is  ……………………… . These terms are subject to change in teh following scenarios: a) For the execution of work at the moment of disassembly in case new defects are discovered, which require orders / parts and/or additional work. b) The parts ordered are not delivered according to the established terms. c) In case of force majeure or fortuitous events.",
  carServiceDisclaimerText2:
    "The unit can perform additional work and replacement parts up to the maximum amount of ……………… .. RON, these being accepted by the presence.",
  carServiceDisclaimerText3:
    "The customer undertakes to ensure the correct operation of the repaired vehicle in accordance with the requirements imposed by the manufacturer. The parts and materials brought by the customer do not benefit from the warranty.",
  carServiceDisclaimerText4:
    "The Beneficiary / Customer declares that I agree that the provider has the right of retention on the repaired vehicle until the full payment of the value of their service / repair invoice (s) accepted by me / our company.",
  guaranteeConditions: "WARRANTY CONDITIONS",
  ourUnitGrantsGuarantee: "Our unit provides warranty",
  forProducts: "for products",
  toIndividuals24Months:
    "for individuals: 24 months according to Law no. 449/2003",
  toCompanies12Months: "for legal entities: 12 months",
  toCompaniesExceptions6Months:
    "for legal entities (driving schools, taxi): 6 months",
  workGuarantee3Months:
    "for labor the warranty is 3 months from the date of receipt",
  manufacturing_yearLabel: "Manufacturing year",
  accounts: "Accounts",
  titleAccounts: "Accounts",
  accountNameLabel: "Account name",
  accountUsers: "Users count",
  inactive: "Inactive",
  activeFromLabel: "Active from",
  activeToLabel: "Active to",
  titleEditAccount: "Edit account - ",
  workOrderTypes: "Work order types",
  accountOrganizations: "Organizations count",
  accountUUID: "Account uuid",
  openCalendar: "Open Calendar",
  closeCalendar: "Close Calendar",
  GroupByDay: "Group by day",
  PlateNumberAlreadyInUse: "Plate number already in use."
};

export { en_en };

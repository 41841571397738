/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  services: {
    display: "inline-flex",
    flex: 1,
    alignItems: "center",
    flexWrap: "wrap",
    fontFamily: theme.font.family,
    fontSize: "0.75rem",
    color: theme.palette.secondary.light,
    fontWeight: 800,
  },
  tableTitle: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0 0.25rem",
    paddingBottom: 0,
    marginBottom: "0.5rem",
    borderBottom: `1px solid #e0e0e0`,
    display: "flex",
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  title: {
    fontSize: "0.9rem",
    fontFamily: theme.font.family,
    textAlign: "center",
    padding: "0.25rem 1rem",
    margin: "0.75rem 0",
    fontWeight: 800,
    borderBottom: `1px solid #d0d0d0`,
  },
  box: {
    padding: 5,
    paddingTop: 0,
    borderRadius: 3,
    "& $padding": {
      padding: "0 8px",
    },
  },
  padding: {
    padding: "0 8px",
  },
  spacing: {
    margin: "0 0.25rem",
  },
  boxItem: {
    margin: "0 0.25rem",
  },
  positionBottom: {
    position: "absolute",
    bottom: 0,
    paddingRight: "0.5rem",
  },
  container: {
    height: "118vh",
    position: "relative",
  },
}));

export { useStyles };

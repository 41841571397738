import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useMessage } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./DeleteWorkOrderTimerModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  handleDelete: PropTypes.func,
  deleteData: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  loadingText: LoadingTextDefaultProps,
  open: false,
  closeModal: () => {},
  handleDelete: () => {},
  deleteData: {},
};

/**
 * Displays the component
 */
const DeleteWorkOrderTimerModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    deleteData,
    button,
    loadingText,
    handleDelete,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the org name and id
   */
  const { plate_number } = deleteData;

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles the submit
   */
  const onDelete = () => {
    handleDelete(plate_number);
    /**
     * Dispatches the error message
     */
    dispatchMessage({
      delay: 300,
      message: t("successMessage"),
    });
    closeModal();
  };

  return (
    <Modal
      {...modal}
      fullScreen={triggerFullScreen}
      maxWidth="sm"
      open={open}
      onClose={closeModal}
      keepMounted={true}
      scroll="paper"
    >
      <ModalTitle {...modalTitle} onClick={closeModal} />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid item container xs={12} className={classes.modulesContainer}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank}>
                <CardContent className={classes.cardContent}>
                  <Grid item xs={12} container justify="center">
                    <Typography className={classes.label}>
                      {t("deleteConfirmationText")}
                      <Typography variant="caption" className={classes.value}>
                        {`${plate_number}`}
                      </Typography>
                      {` ?`}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions {...modalActions} onClick={closeModal}>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.submitBtn}
          onClick={onDelete}
        >
          <LoadingText
            {...loadingText}
            loading={false}
            text={t("deleteConfirm")}
          />
        </Button>
      </ModalActions>
    </Modal>
  );
};

DeleteWorkOrderTimerModal.propTypes = propTypes;
DeleteWorkOrderTimerModal.defaultProps = defaultProps;

export default DeleteWorkOrderTimerModal;
export {
  propTypes as DeleteWorkOrderTimerModalPropTypes,
  defaultProps as DeleteWorkOrderTimerModalDefaultProps,
};

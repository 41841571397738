import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import UserOrganizations, {
  UserOrganizationsDefaultProps,
  UserOrganizationsPropTypes,
} from "../UserOrganizations";
import AddOrganizationModal, {
  AddOrganizationModalDefaultProps,
  AddOrganizationModalPropTypes,
} from "../AddOrganizationModal";
import EditOrganizationModal, {
  EditOrganizationModalDefaultProps,
  EditOrganizationModalPropTypes,
} from "../EditOrganizationModal";
import EditOrgAddressModal, {
  EditOrgAddressModalDefaultProps,
  EditOrgAddressModalPropTypes,
} from "../EditOrgAddressModal";
import EditOrgInfoModal, {
  EditOrgInfoModalDefaultProps,
  EditOrgInfoModalPropTypes,
} from "../EditOrgInfoModal";
import AddOrgContactModal, {
  AddOrgContactModalDefaultProps,
  AddOrgContactModalPropTypes,
} from "../AddOrgContactModal";
import EditOrgContactModal, {
  EditOrgContactModalDefaultProps,
  EditOrgContactModalPropTypes,
} from "../EditOrgContactModal";
import ViewOrgContactModal, {
  ViewOrgContactModalDefaultProps,
  ViewOrgContactModalPropTypes,
} from "../ViewOrgContactModal";
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import DeleteOrganizationModal from "../DeleteOrganizationModal";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";

/**
 * Defines the prop types
 */
const propTypes = {
  organizations: PropTypes.shape(UserOrganizationsPropTypes),
  addOrgModal: PropTypes.shape(AddOrganizationModalPropTypes),
  editOrgModal: PropTypes.shape(EditOrganizationModalPropTypes),
  editAddressModal: PropTypes.shape(EditOrgAddressModalPropTypes),
  editInfoModal: PropTypes.shape(EditOrgInfoModalPropTypes),
  addOrgContactModal: PropTypes.shape(AddOrgContactModalPropTypes),
  editOrgContactModal: PropTypes.shape(EditOrgContactModalPropTypes),
  viewOrgContactModal: PropTypes.shape(ViewOrgContactModalPropTypes),
  createButton: PropTypes.shape(CreateButtonPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  organizations: UserOrganizationsDefaultProps,
  addOrgModal: AddOrganizationModalDefaultProps,
  editOrgModal: EditOrganizationModalDefaultProps,
  editAddressModal: EditOrgAddressModalDefaultProps,
  editInfoModal: EditOrgInfoModalDefaultProps,
  addOrgContactModal: AddOrgContactModalDefaultProps,
  editOrgContactModal: EditOrgContactModalDefaultProps,
  viewOrgContactModal: ViewOrgContactModalDefaultProps,
  createButton: CreateButtonDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: "/organizations",
};

/**
 * Displays the component
 */
const AccountSettingsOrganizations = (props) => {
  const {
    organizations,
    addOrgModal,
    editOrgModal,
    editAddressModal,
    editInfoModal,
    addOrgContactModal,
    editOrgContactModal,
    viewOrgContactModal,
    createButton,
    title,
    wrapper,
    container,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Initializes the data for the edit  modal
   */
  const [editData, setEditData] = useState({});

  /**
   * Initializes the data for the delete  modal
   */
  const [deleteData, setDeleteData] = useState({});

  /**
   * Initializes the data for the edit address modal
   */
  const [addressData, setAddressData] = useState({});

  /**
   * Initializes the data for the edit organization modal
   */
  const [orgData, setOrgData] = useState({});

  /**
   * Initializes the data for the edit contact modal
   */
  const [contactData, setContactData] = useState({});

  /**
   * Initializes the data for the view contact  modal
   */
  const [viewContactData, setViewContactData] = useState({});

  /**
   * Initializes the data for the add contact  modal
   */
  const [id, setId] = useState("");

  /**
   * Initializes the state of the expansion panel
   */
  const [expanded, setExpanded] = useState("");

  /**
   * Initializes the modal state
   */
  const [modal, setModal] = useState({
    addOrganization: false,
    editOrganization: false,
    deleteOrganization: false,
    editAddress: false,
    editInfo: false,
    viewContact: false,
    addContact: false,
    editContact: false,
  });

  /**
   *  Handles updating the modal props which triggers opening the modal
   * @param {string} type
   * @param {object} props
   */
  const openModal = (type, props) => {
    switch (type) {
      case "edit":
        return setEditData(props);
      case "delete":
        return setDeleteData(props);
      case "editAddress":
        return setAddressData(props);
      case "editOrganization":
        return setOrgData(props);
      case "editContact":
        return setContactData(props);
      case "viewContact":
        return setViewContactData(props);
      case "addContact":
        return setId(props);
      default:
        break;
    }
  };

  /**
   *  Handles updating the modal state
   * @param {string} type
   * Modal name (Eg. add / edit / delete etc)
   * @param {boolean} state
   * State of open / closed
   */
  const updateModal = (type, state) =>
    setModal((prevState) => ({ ...prevState, [type]: state }));

  /**
   * Defines the add organization modal handlers
   */
  const openAddOrganization = () => updateModal("addOrganization", true);
  const closeAddOrganization = () => updateModal("addOrganization", false);

  /**
   * Defines the edit organization modal handlers
   */
  const openEditOrganization = () => updateModal("editOrganization", true);
  const closeEditOrganization = () => updateModal("editOrganization", false);

  /**
   * Defines the delete organization modal handlers
   */
  const openDeleteOrganization = () => updateModal("deleteOrganization", true);
  const closeDeleteOrganization = () =>
    updateModal("deleteOrganization", false);

  /**
   * Defines the edit address modal handlers
   */
  const openEditAddressModal = () => updateModal("editAddress", true);
  const closeEditAddressModal = () => updateModal("editAddress", false);

  /**
   * Defines the edit organization info modal handlers
   */
  const openEditOrgInfoModal = () => updateModal("editInfo", true);
  const closeEditOrgInfoModal = () => updateModal("editInfo", false);

  /**
   * Defines the add contact modal
   */
  const openAddContactModal = () => updateModal("addContact", true);
  const closeAddContactModal = () => updateModal("addContact", false);

  /**
   * Defines the edit contact modal
   */
  const openEditContactModal = () => updateModal("editContact", true);
  const closeEditContactModal = () => updateModal("editContact", false);

  /**
   * Defines the view contact modal
   */
  const openViewContactModal = () => updateModal("viewContact", true);
  const closeViewContactModal = () => updateModal("viewContact", false);

  /**
   * Handles the change of the expansion panel state
   */
  const handleChange = (panel) =>
    panel === expanded ? setExpanded(false) : setExpanded(panel);

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles opening the edit organization modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEditOrganization();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the view contact modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(viewContactData)) openViewContactModal();
    // eslint-disable-next-line
  }, [viewContactData]);

  /**
   * Handles opening the delete organization modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDeleteOrganization();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Handles opening the edit addresss modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(addressData)) openEditAddressModal();
    // eslint-disable-next-line
  }, [addressData]);

  /**
   * Handles opening the edit organization modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(orgData)) openEditOrgInfoModal();
    // eslint-disable-next-line
  }, [orgData]);

  /**
   * Handles opening the edit contact modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(contactData)) openEditContactModal();
    // eslint-disable-next-line
  }, [contactData]);

  /**
   * Handles opening the add contact modal if the content is set
   */
  useEffect(() => {
    if (id) openAddContactModal();
    // eslint-disable-next-line
  }, [id]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<AccountTreeOutlinedIcon />}
        title={t("titleOrganizations")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid item xs={12}>
          <CreateButton
            {...createButton}
            onClick={openAddOrganization}
            text={t("addNewOrganization")}
          />
        </Grid>
        <UserOrganizations
          {...organizations}
          expanded={expanded}
          onChange={handleChange}
          modalHandler={openModal}
        />
        <AddOrganizationModal
          {...addOrgModal}
          open={modal.addOrganization}
          closeModal={closeAddOrganization}
        />
        <EditOrganizationModal
          {...editOrgModal}
          editData={editData}
          open={modal.editOrganization}
          closeModal={closeEditOrganization}
        />

        <DeleteOrganizationModal
          closeModal={closeDeleteOrganization}
          open={modal.deleteOrganization}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
        />
        <EditOrgAddressModal
          {...editAddressModal}
          addressData={addressData}
          open={modal.editAddress}
          closeModal={closeEditAddressModal}
        />
        <EditOrgInfoModal
          {...editInfoModal}
          orgData={orgData}
          open={modal.editInfo}
          closeModal={closeEditOrgInfoModal}
        />
        <AddOrgContactModal
          {...addOrgContactModal}
          id={id}
          setId={setId}
          open={modal.addContact}
          closeModal={closeAddContactModal}
        />
        <EditOrgContactModal
          {...editOrgContactModal}
          contactData={contactData}
          open={modal.editContact}
          closeModal={closeEditContactModal}
        />
        <ViewOrgContactModal
          {...viewOrgContactModal}
          contactData={viewContactData}
          open={modal.viewContact}
          closeModal={closeViewContactModal}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

AccountSettingsOrganizations.propTypes = propTypes;
AccountSettingsOrganizations.defaultProps = defaultProps;

export default AccountSettingsOrganizations;
export {
  propTypes as AccountSettingsOrganizationsPropTypes,
  defaultProps as AccountSettingsOrganizationsDefaultProps,
};

const quickTextModel = {
  label: "Quick Text",
  field: "quick_text",
  type: "like",
  order: 1,
  options: [],
  selected: null,
};

const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

const clientNameModel = {
  label: "Client Name",
  field: "organization_client_id",
  type: "like",
  order: 3,
  options: [],
  selected: null,
};

const contractNumberModel = {
  label: "Contract Number",
  field: "contract_number",
  type: "equal",
  order: 4,
  options: [],
  selected: null,
};

const fromDateModel = {
  label: "From",
  field: "from",
  type: "gte",
  order: 5,
  options: [],
  selected: null,
};

const toDateModel = {
  label: "To",
  field: "to",
  type: "lte",
  order: 6,
  options: [],
  selected: "",
};

const createdAtModel = {
  label: "Created",
  field: "created_at",
  type: "gte",
  order: 7,
  options: [],
  selected: "",
};

const defaults = {
  models: {
    quickTextModel,
    organizationIdModel,
    clientNameModel,
    contractNumberModel,
    fromDateModel,
    toDateModel,
    createdAtModel,
  },
  searchParams: {
    models: [
      quickTextModel,
      organizationIdModel,
      clientNameModel,
      contractNumberModel,
      fromDateModel,
      toDateModel,
      createdAtModel,
    ],
    order_by: "created_at",
    order_dir: "desc",
    page_size: Number(localStorage.getItem("contractsViewPageSize")) || 15,
  },
  orderBy: ["name", "created_at"],
  pageSizeOptions: [15, 30, 50, 100],
};

export { defaults };

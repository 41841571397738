import React, { useRef, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import PrintTyreHotels, {
  PrintTyreHotelsDefaultProps,
  PrintTyreHotelsPropTypes,
} from "../PrintTyreHotels";
import PrintTyreHotelsClient, {
  PrintTyreHotelsClientDefaultProps,
  PrintTyreHotelsClientPropTypes,
} from "../PrintTyreHotelsClient";
import TyreHotelsChoiceModal, {
  TyreHotelsChoiceModalDefaultProps,
  TyreHotelsChoiceModalPropTypes,
} from "../TyreHotelsChoiceModal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./TyreHotelsTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  printTyreHotels: PropTypes.shape(PrintTyreHotelsPropTypes),
  printTyreHotelsClient: PropTypes.shape(PrintTyreHotelsClientPropTypes),
  choiceModal: PropTypes.shape(TyreHotelsChoiceModalPropTypes),
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handleView: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFiltersReset: PropTypes.func,
  rowsPerPage: PropTypes.any,
  page: PropTypes.number,
  count: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  button: ButtonDefaultProps,
  printTyreHotels: PrintTyreHotelsDefaultProps,
  printTyreHotelsClient: PrintTyreHotelsClientDefaultProps,
  choiceModal: TyreHotelsChoiceModalDefaultProps,
  handleDelete: null,
  handleAdd: () => {},
  handleEdit: () => {},
  handleView: () => {},
  handlePageChange: () => {},
  handleChangeRowsPerPage: () => {},
  handleFiltersReset: () => {},
  rowsPerPage: 0,
  page: 0,
  count: 0,
};

/**
 * Displays the component
 */
const TyreHotelsTable = (props) => {
  const {
    button,
    editButton,
    deleteButton,
    dynamicTable,
    printTyreHotels,
    printTyreHotelsClient,
    choiceModal,
    handleAdd,
    handleEdit,
    handleView,
    handleDelete,
    handlePageChange,
    handleChangeRowsPerPage,
    handleFiltersReset,
    rowsPerPage,
    page,
    count,
    parent,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the print data
   */
  const [tyreHotelsData, setTyreHotelsData] = useState({});
  const [tyreHotelsClientData, setTyreHotelsClientData] = useState({});

  /**
   * Initializes the print state
   */
  const [activePrint, setActivePrint] = useState({
    client: false,
    warehouse: false,
  });

  /**
   * Intializes the modal state
   */
  const [open, setOpen] = useState(false);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Handles printing the component
   */
  const tyreHotelsRef = useRef();
  const tyreHotelsClientRef = useRef();

  const _printTyreHotels = useReactToPrint({
    content: () => tyreHotelsRef.current,
  });

  const _printTyreHotelsClient = useReactToPrint({
    content: () => tyreHotelsClientRef.current,
  });

  /**
   * Handles building the tyre dimensions string
   */
  const buildTyreDimString = (width, height, rim) => {
    if (!width || !height || !rim) return " - ";
    return `${width} / ${height} R${rim}`;
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  const handleOpenModal = (props) => {
    setTyreHotelsClientData(props);
    setTyreHotelsData(props);
    setOpen(true);
  };

  const handleCloseModal = () => setOpen(false);

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () =>
    user.tyre_hotels && user.tyre_hotels.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const {
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    } = props;

    let tableOptions = {
      withCount: true,
      withAdd: true,
      withSearch: true,
      withPagination: true,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: true,
        addResult: true,
      },
      pagination: {
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        total: count,
        currentPage: page,
      },
      addResult: handleAdd,
      resetFilters: handleFiltersReset,
      actionsWrapped: true,
      defaultOrderBy: "created_at",
      defaultOrder: "desc",
      fields: [
        {
          name: "created_at",
          label: t("created_at"),
          align: "center",
          minSize: 150,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: true,
          type: "date"
        },
        {
          name: "car_number",
          label: t("car_number"),
          align: "center",
          minSize: 150,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "work_order_uuid",
          label: t("work_order_uuid"),
          align: "center",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "client_name",
          label: t("client_name"),
          align: "center",
          minSize: 200,
          maxSize: 300,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "tyre_name",
          label: t("tyre_name"),
          align: "center",
          minSize: 150,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: true,
        },

        {
          name: "tyre_dim",
          label: t("tyre_dim"),
          align: "center",
          minSize: 150,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "tyre_quantity",
          label: t("tyre_quantity"),
          align: "center",
          key: true,
          searchField: false,
          sort: true,
        },
      ],
    };

    /**
     * Defines the action buttons
     */
    const buttons = [
      {
        name: "view_hotel_table",
        type: "view",
        icon: null,
        component: (
          <Button
            {...button}
            type="button"
            variant="filled"
            className={classes.viewButton}
            title={t("view")}
          >
            <VisibilityIcon />
          </Button>
        ),
        action: handleView,
      },
      {
        name: "edit_hotel",
        type: "edit",
        icon: null,
        component: (
          <EditButton
            {...editButton}
            className={classes.button}
            title={t("edit")}
          />
        ),
        action: handleEdit,
      },
      {
        name: "print_tyre_hotels",
        type: "print",
        icon: null,
        component: (
          <Button
            {...button}
            type="button"
            variant="filled"
            className={classes.printButton}
            title={t("printWorkOrder")}
          >
            <PrintIcon className={classes.icon} />
          </Button>
        ),
        action: handleOpenModal,
      },
    ];

    /**
     * If there's a handleDelete, add the action button
     */
    if (!!handleDelete) {
      buttons.push({
        name: "delete_hotel",
        type: "delete",
        icon: null,
        component: (
          <DeleteButton
            {...deleteButton}
            className={classes.button}
            title={t("delete")}
          />
        ),
        action: handleDelete,
      });
    }

    /**
     *  Push the action buttons to the fields
     */
    tableOptions.fields.push({
      name: "operations",
      label: t("operations"),
      type: "actions",
      key: false,
      align: "center",
      minSize: parent === "service" ? 150 : 100,
      maxSize: parent === "service" ? 150 : 100,
      searchField: false,
      buttons,
    });

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [count, page, rowsPerPage]);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.tyre_hotels) {
      const data = user.tyre_hotels.map((hotel) => {
        const { tyre_width, tyre_height, tyre_rim, created_at } = hotel;

        return {
          ...hotel,
          created_at: new Date(created_at),
          tyre_dim: buildTyreDimString(tyre_width, tyre_height, tyre_rim),
        };
      });
      setCollection(data);
    }
  }, [user]);

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (activePrint.client && Object.keys(tyreHotelsClientData).length > 0) {
      _printTyreHotelsClient();
      setActivePrint({
        client: false,
        warehouse: false,
      });
    }
    if (activePrint.warehouse && Object.keys(tyreHotelsData).length > 0) {
      _printTyreHotels();
      setActivePrint({
        client: false,
        warehouse: false,
      });
    }
    // eslint-disable-next-line
  }, [activePrint, tyreHotelsClientData, tyreHotelsData]);

  return (
    <Fragment>
      {renderDynamicTable()}
      <div style={{ display: "none" }}>
        <div ref={tyreHotelsRef}>
          <PrintTyreHotels {...printTyreHotels} printData={tyreHotelsData} />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={tyreHotelsClientRef}>
          <PrintTyreHotelsClient
            {...printTyreHotelsClient}
            printData={tyreHotelsClientData}
          />
        </div>
      </div>
      <TyreHotelsChoiceModal
        {...choiceModal}
        open={open}
        closeModal={handleCloseModal}
        setActivePrint={setActivePrint}
      />
    </Fragment>
  );
};

TyreHotelsTable.propTypes = propTypes;
TyreHotelsTable.defaultProps = defaultProps;

export default TyreHotelsTable;
export {
  propTypes as TyreHotelsTablePropTypes,
  defaultProps as TyreHotelsTableDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
}));

export { useStyles };

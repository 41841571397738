import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports Components
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Imports Styles
 */
import { useStyles } from "./ReportsWorkersTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  data: PropTypes.array,

  isPrinting: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  data: [],

  isPrinting: false,
};

/**
 * Displays the component
 */
const ReportsWorkersTable = (props) => {
  const { data, isPrinting } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const list = isPrinting
    ? [
        {
          name: t("name"),
          work_orders_count: t("work_orders_count"),
          total: t("total"),
          is_header: true,
        },
        ...data,
      ]
    : data;

  return (
    <TableContainer>
      <Table stickyHeader className={clsx(classes.table, "page-break")}>
        <TableHead
          className={clsx({
            [classes.hiddenHeader]: isPrinting,
          })}
        >
          <TableRow>
            <TableCell> #</TableCell>
            <TableCell align="right"> {t("name")}</TableCell>
            <TableCell align="right"> {t("work_orders_count")}</TableCell>
            <TableCell align="right"> {t("subtotal")} </TableCell>
            <TableCell align="right"> {t("discount")} </TableCell>
            <TableCell align="right"> {t("total")} </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            const {
              name,
              work_orders_count,
              total,
              is_header,
              subtotal,
              discount,
            } = item;

            const headerClasses =
              "MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader";

            const cellClass = clsx({
              [headerClasses]: is_header,
            });

            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row" className={cellClass}>
                  {isPrinting ? (index === 0 ? "#" : index) : index + 1}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {name}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {`${work_orders_count} BUC`}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {`${parseFloat(subtotal).toFixed(2)} RON`}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {`${parseFloat(discount).toFixed(2)} RON`}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {`${parseFloat(total).toFixed(2)} RON`}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportsWorkersTable.propTypes = propTypes;
ReportsWorkersTable.defaultProps = defaultProps;

export default ReportsWorkersTable;
export {
  propTypes as ReportsWorkersTablePropTypes,
  defaultProps as ReportsWorkersTableDefaultProps,
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { format as formatDate, isDate } from "date-fns";
import { enUS, ro, hu } from "date-fns/locale";

/**
 * Sets up translations
 *
 * - Every component manages it's own translations via namespaces
 * - Every component adds it's own translation items to `resources`
 *
 * @see https://github.com/i18next/react-i18next/issues/299
 * @see https://react.i18next.com/latest/usetranslation-hook#loading-namespaces
 *
 */
const resources = {};

/**
 *
 * @type {{en: *, ro: Locale | ro, hu: Locale | hu}}
 */
const locales = { enUS, ro, hu };

/**
 * Sets up i18next
 *
 * @see https://react.i18next.com/latest/using-with-hooks
 */
i18n
  /**
   * Detects user language
   *
   * @see https://github.com/i18next/i18next-browser-languageDetector
   */
  .use(LanguageDetector)
  /**
   * Passes the i18n instance to react-i18next.
   */
  .use(initReactI18next)
  /**
   * Inits i18next
   *
   * @see https://www.i18next.com/overview/configuration-options
   */
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || "en_ro",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
        format: (value, format, lng) => {
            if (isDate(value)) {
                const locale = locales[lng];
                return formatDate(value, format, { locale });
            }
        }
    }
  });

export default i18n;

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  name: {
    zIndex: 999,
    fontWeight: 800,
    fontFamily: theme.font.family,
    fontSize: "0.8rem",
    color: theme.palette.common.white,
    marginBottom: "0.5rem",
    padding: "0.25rem 2rem",
    marginTop: "0.5rem",
    background: theme.palette.primary.main,
    position: 'fixed',
    bottom: 40,
    right: 10,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.secondary.main
    }
  },
}));

export { useStyles };

import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";

/**
 * Styles Imports
 */
import { useStyles } from "./TablePopover.styles";
import { Zoom } from "@material-ui/core";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  buttonProps: PropTypes.object,
  popoverRender: PropTypes.func,
  icon: PropTypes.any,
  loading: PropTypes.bool,
  activeButton: PropTypes.string,
  id: PropTypes.string,
  row: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  buttonProps: {},
  popoverRender: () => { },
  icon: null,
  loading: false,
  activeButton: "",
  id: "",
  row: {},
};

/**
 * Displays the component
 */
const TablePopover = (props) => {
  const {
    buttonProps,
    popoverRender,
    rowInEdit,
    icon,
    loading,
    activeButton,
    id,
    button,
    row,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverID = open ? id : undefined;

  return (
    <Fragment>
      <Button
        {...button}
        {...buttonProps}
        className={clsx(buttonProps.className, {
          [activeButton]: open,
        })}
        onClick={handleClick}
      >
        {loading && rowInEdit === row.id ? (
          <CircularProgress size="1.45rem" className={classes.loader} />
        ) : (
            icon
          )}
      </Button>
      <Popover
        id={popoverID}
        open={open}
        anchorEl={anchorEl}
        TransitionComponent={Zoom}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {popoverRender(row, open, handleClose)}
      </Popover>
    </Fragment>
  );
};

TablePopover.propTypes = propTypes;
TablePopover.defaultProps = defaultProps;

export default TablePopover;
export {
  propTypes as TablePopoverPropTypes,
  defaultProps as TablePopoverDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 *  Material UI Imports
 */
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

/**
 * Styles Imports
 */
import { useStyles } from "./TablePaginationActions.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  componentName: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  componentName: "TablePaginationActions",
};

/**
 * Displays the component
 */
const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 1);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <div className={classes.tablePagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) }
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = propTypes;
TablePaginationActions.defaultProps = defaultProps;

export default TablePaginationActions;
export {
  propTypes as TablePaginationActionsPropTypes,
  defaultProps as TablePaginationActionsDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ModulePackages, {
  ModulePackagesDefaultProps,
  ModulePackagesPropTypes
} from "../ModulePackages";
import UpgradePackageModal, {
  UpgradePackageModalDefaultProps,
  UpgradePackageModalPropTypes
} from "../UpgradePackageModal";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes
} from "../SubmoduleContainer";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import UpdateIcon from "@material-ui/icons/Update";

/**
 * Hooks
 */
import { useUser, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsUpgrade.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  modulePackages: PropTypes.shape(ModulePackagesPropTypes),
  packageModal: PropTypes.shape(UpgradePackageModalPropTypes),
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  modulePackages: ModulePackagesDefaultProps,
  packageModal: UpgradePackageModalDefaultProps,
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: "/upgrade"
};

/**
 * Displays the component
 */
const AccountSettingsUpgrade = (props) => {
  const { title, wrapper, container, modulePackages, packageModal } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client with token
   */
  const { apiClient } = useApiClient({ withCredentials: false });

  /**
   * Initializes the modal state
   */
  const [modal, setModal] = useState(false);

  /**
   * Initializes the state that hold the data for the modals
   */
  const [modalData, setModalData] = useState({});

  /**
   * Initializes the renew flag used to update the active package state
   */
  const [renew, setRenew] = useState(false);

  /**
   * Gets the global user
   */
  const { setUser } = useUser();

  /**
   * Handles getting the module packages
   */
  const getModulePackages = async () => {
    const modulePackages = await apiClient.get("/module-packages");

    if (modulePackages) {
      const { data } = modulePackages;
      /**
       * Sorts the packages
       */
      const packages = data.sort(sortPackages);

      /**
       * Update the state
       */
      setRenew(false);
      setUser((prevState) => {
        return { ...prevState, packages };
      });
    }
  };

  /**
   * Handles opening the modal
   */
  const openEditModal = () => setModal(true);

  /**
   * Handles closing the modal
   */
  const closeEditModal = () => setModal(false);

  /**
   * Handles opening the edit modal
   */
  const updateModalData = (props) => setModalData(props);

  /**
   * Checks if the object is empty
   */
  const isEmptyObject = (obj) => Object.keys(obj).length < 1;

  /**
   * Defines the sort function for the packages
   * Sorts based on the default order coming from the API
   * Otherwise sort by price
   */
  const sortPackages = (a, b) => {
    if (a.order === b.order) {
      return parseFloat(a.price) - parseFloat(b.price);
    } else {
      return parseFloat(a.order) - parseFloat(b.order);
    }
  };

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmptyObject(modalData)) openEditModal();
    // eslint-disable-next-line
  }, [modalData]);

  /**
   * Gets the packages list
   */
  useEffect(() => {
    getModulePackages();
    // eslint-disable-next-line
  }, []);

  /**
   * Gets the account settings
   */
  useEffect(() => {
    if (renew) {
      setUser((prevState) => ({ ...prevState, refresh: true }));
    }
    // eslint-disable-next-line
  }, [renew]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<UpdateIcon />}
        title={t("titleUpdateAccount")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container className={classes.box} spacing={3} justify="center">
          <ModulePackages
            {...modulePackages}
            updateModalData={updateModalData}
          />
        </Grid>
      </SubmoduleWrapper>
      <UpgradePackageModal
        {...packageModal}
        open={modal}
        closeModal={closeEditModal}
        modalData={modalData}
        renew={renew}
        setRenew={setRenew}
        setModalData={setModalData}
      />
    </SubmoduleContainer>
  );
};

AccountSettingsUpgrade.propTypes = propTypes;
AccountSettingsUpgrade.defaultProps = defaultProps;

export default AccountSettingsUpgrade;
export {
  propTypes as AccountSettingsUpgradePropTypes,
  defaultProps as AccountSettingsUpgradeDefaultProps
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Styles Imports
 */
import { useStyles } from "./SubmoduleWrapper.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  children: null,
  className: ""
};

/**
 * Displays the component
 */
const SubmoduleWrapper = props => {
  const { children, className } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the component classes
   */
  const wrapperClasses = clsx(classes.wrapper, {
    [className]: !!className
  });

  return <div className={wrapperClasses}>{children}</div>;
};

SubmoduleWrapper.propTypes = propTypes;
SubmoduleWrapper.defaultProps = defaultProps;

export default SubmoduleWrapper;
export {
  propTypes as SubmoduleWrapperPropTypes,
  defaultProps as SubmoduleWrapperDefaultProps
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";
import Tooltip from "@material-ui/core/Tooltip";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderSearchForm.styles";

/**
 * Defaults Imports
 */
import { defaults } from "../WorkstationSettingsWorkOrders/WorkstationSettingsWorkOrders.defaults";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  defaultValues: PropTypes.shape({
    quick_text: PropTypes.string,
    work_order_type: PropTypes.string,
    range: PropTypes.string,
    payment_type: PropTypes.string,
    car_type: PropTypes.string,
    tyre_width: PropTypes.string,
    tyre_height: PropTypes.string,
    tyre_rim: PropTypes.string,
    client_name: PropTypes.string,
    order_id: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
  }),
  orderByList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  defaultValues: {
    quick_text: "",
    work_order_type: "",
    range: "",
    payment_type: "",
    car_type: "",
    tyre_width: "",
    tyre_height: "",
    tyre_rim: "",
    client_name: "",
    order_id: "",
    orderBy: "finished",
    orderDir: "desc",
  },
  orderByList: defaults.orderBy,
  pageSizeOptions: defaults.pageSizeOptions,
  path: "/work-orders",
};

/**
 * Displays the component
 */
const WorkOrderSearchForm = (props) => {
  const {
    form,
    input,
    button,
    defaultValues,
    tabChanged,
    setTabChanged,
    defaultSearch,
    setInputs: updateInputs,
    setModels: updateModels,
    setTotal,
    pageSize,
    pageCount,
    setPageSize,
    setPageCount,
    setBackdropLoading,
    selectedOrg,
    setUpdated,
    loadingText,
    resetFilters,
    setResetFilters,
    searchedValue,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the advanced search flag
   */
  const [advanced, setAdvanced] = useState(false);

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the state that holds the options for the select type search models
   */
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [workOrderTypes, setWorkOrderTypes] = useState([]);
  const [orderByOptions, setOrderByOptions] = useState([
    { label: t("finished"), value: "finished" },
  ]);

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Handles searching for the work orders
   */
  const searchWorkOrders = async (data) => {
    try {
      const workOrders = await apiClient.post("/work-orders/search", data);
      if (workOrders) {
        const { data } = workOrders;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, work_orders: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        updateModels(models);

        /**
         * Handles updating the inputs
         */
        const handlePrevState = (prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        };

        setInputs((prevState) => handlePrevState(prevState));
        updateInputs((prevState) => handlePrevState(prevState));

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];

    /**
     * Gets the inputs
     */
    const {
      quick_text,
      work_order_type,
      range,
      client_name,
      payment_type,
      car_type,
      tyre_width,
      tyre_height,
      tyre_rim,
      order_id,
    } = inputs;

    /**
     * Gets the default models
     */
    const {
      quickTextModel,
      workOrderTypeModel,
      workOrderDoneDateModel,
      clientNameModel,
      paymentTypeModel,
      carTypeModel,
      tyreWidthModel,
      tyreHeightModel,
      tyreRimModel,
      organizationIdModel,
      workOrderIdModel,
    } = defaults.models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      const modelItem = { ...model };
      modelItem["selected"] = input;
      searchModels.push(modelItem);
    };

    if (order_id) {
      if (modelExists("uuid")) {
        removeModel("uuid");
        addModel(order_id, workOrderIdModel);
      } else {
        addModel(order_id, workOrderIdModel);
      }
    }

    if (quick_text) {
      if (modelExists("quick_text")) {
        removeModel("quick_text");
        addModel(quick_text, quickTextModel);
      } else {
        addModel(quick_text, quickTextModel);
      }
    }

    if (work_order_type) {
      if (modelExists("work_order_type")) {
        removeModel("work_order_type");
        addModel(work_order_type, workOrderTypeModel);
      } else {
        addModel(work_order_type, workOrderTypeModel);
      }
    }

    if (range) {
      const rangeValue = range.split(" - ");
      const startDate = formatDate(
        new Date(rangeValue[0].trim()),
        "yyyy-MM-dd"
      );
      const endDate = formatDate(new Date(rangeValue[1].trim()), "yyyy-MM-dd");
      const result = [startDate, endDate];

      if (modelExists("finished")) {
        removeModel("finished");
        addModel(result, workOrderDoneDateModel);
      } else {
        addModel(result, workOrderDoneDateModel);
      }
    }

    if (client_name && advanced) {
      if (modelExists("organizationClient.id")) {
        removeModel("organizationClient.id");
        addModel(client_name, clientNameModel);
      } else {
        addModel(client_name, clientNameModel);
      }
    }

    if (payment_type && advanced) {
      if (modelExists("payment_type")) {
        removeModel("payment_type");
        addModel(payment_type, paymentTypeModel);
      } else {
        addModel(payment_type, paymentTypeModel);
      }
    }

    if (car_type && advanced) {
      if (modelExists("car_type")) {
        removeModel("car_type");
        addModel(car_type, carTypeModel);
      } else {
        addModel(car_type, carTypeModel);
      }
    }

    if (tyre_width && advanced) {
      if (modelExists("tyreService.tyre_width")) {
        removeModel("tyreService.tyre_width");
        addModel(tyre_width, tyreWidthModel);
      } else {
        addModel(tyre_width, tyreWidthModel);
      }
    }

    if (tyre_height && advanced) {
      if (modelExists("tyreService.tyre_height")) {
        removeModel("tyreService.tyre_height");
        addModel(tyre_height, tyreHeightModel);
      } else {
        addModel(tyre_height, tyreHeightModel);
      }
    }

    if (tyre_rim && advanced) {
      if (modelExists("tyreService.tyre_rim")) {
        removeModel("tyreService.tyre_rim");
        addModel(tyre_rim, tyreRimModel);
      } else {
        addModel(tyre_rim, tyreRimModel);
      }
    }

    if (selectedOrg !== "" && selectedOrg !== "any") {
      if (modelExists("organization_id")) {
        removeModel("organization_id");
        addModel(selectedOrg, organizationIdModel);
      } else {
        addModel(selectedOrg, organizationIdModel);
      }
    }
    return searchModels.length < 1 ? defaultSearch.models : searchModels;
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: pageCount,
    };

    searchWorkOrders(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const {
    inputs,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    handleDateChange,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    quick_text,
    work_order_type,
    range,
    payment_type,
    car_type,
    tyre_width,
    tyre_height,
    tyre_rim,
    client_name,
    order_id,
    orderBy,
    orderDir,
  } = inputs;

  /**
   * Handles resetting the filters
   */
  const handleFiltersReset = () => {
    resetInputs();
    setBackdropLoading(true);
    setModels(defaults.models);
    updateModels(defaults.models);
    setPageCount(1);
    handleDefaultSearch();
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const orgIdModel = { ...defaultSearch.models[1] };
    orgIdModel["selected"] = selectedOrg === "any" ? "" : selectedOrg;
    const data = {
      ...defaultSearch,
      models: [{ ...orgIdModel }],
      page_size: Number(pageSize) || Number(defaultSearch.page_size),
      page_count: 1,
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchWorkOrders(getDefaultSearch());
  };

  /**
   * Handles getting the advanced search label
   */
  const getAdvSearchLabel = () =>
    advanced ? t("advSearchActive") : t("advSearchInactive");

  /**
   * Handles triggering the advanced search form
   */
  const openAdvSearch = () => setAdvanced((prevState) => !prevState);

  /**
   * Defines the advanced search button classes
   */
  const advSearchClasses = clsx(classes.flash, { [classes.flashOn]: advanced });

  /**
   * Defines the tooltip classes
   */
  const tooltipClasses = { tooltip: classes.tooltip, popper: classes.popper };

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const paymentTypes = user.settings.account.payment_types;
      const workorderTypes = user.settings.account.work_order_types;
      const orderByOptions = [{ label: t("finished"), value: "finished" }];

      setCarTypes(carTypes);
      setPaymentTypes(paymentTypes);
      setWorkOrderTypes(workorderTypes);
      setOrderByOptions(orderByOptions);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (searchedValue) {
      setInputs((prevState) => ({ ...prevState, order_id: searchedValue }));
    }
    // eslint-disable-next-line
  }, [searchedValue]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    updateModels(defaultSearch.models);

    // eslint-disable-next-line
  }, []);

  /**
   * Handles the tab change
   */
  useEffect(() => {
    if (tabChanged) {
      updateModels(models);
      setTabChanged(false);
      setUpdated(true);
    }
    // eslint-disable-next-line
  }, [tabChanged]);

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false);
      handleFiltersReset();
    }
    // eslint-disable-next-line
  }, [resetFilters]);

  return (
    <Form {...form} id="search-user" onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "quick_text",
                  value: quick_text,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("quick_textLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "work_order_type",
                  value: work_order_type,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("work_order_typeLabel"),
                  options: workOrderTypes,
                  optionLabel: "name",
                  optionValue: "id",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Input
                className={classes.selectField}
                type="dateRange"
                inputDateRange={{
                  name: "range",
                  value: range,
                  onChange: handleDateChange,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  label: t("rangeLabel"),
                  startDateLabel: t("startDateLabel"),
                  endDateLabel: t("endDateLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderBy",
                  value: orderBy,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderByLabel"),
                  variant: "outlined",
                  options: orderByOptions,
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderDir",
                  value: orderDir,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderDirLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("asc"), value: "asc" },
                    { label: t("desc"), value: "desc" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
          </Grid>
          <Zoom in={advanced} timeout={300}>
            <Grid
              container
              spacing={1}
              className={clsx(classes.visibleContainer, {
                [classes.hideContainer]: !advanced,
              })}
            >
              <Grid item xs={12} md={2} lg={4} xl={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "client_name",
                    value: client_name,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label,
                    },
                    showHelper: false,
                    label: t("client_nameLabel"),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={4} xl={2}>
                <Input
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    name: "payment_type",
                    value: payment_type,
                    onChange: handleInputChange,
                    labelClass: classes.label,
                    variant: "outlined",
                    label: t("payment_typeLabel"),
                    options: paymentTypes,
                    optionLabel: "name",
                    optionValue: "id",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={4} xl={2}>
                <Input
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    name: "car_type",
                    value: car_type,
                    onChange: handleInputChange,
                    labelClass: classes.label,
                    variant: "outlined",
                    label: t("car_typeLabel"),
                    options: carTypes,
                    optionLabel: "name",
                    optionValue: "id",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={4} xl={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "tyre_width",
                    value: tyre_width,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label,
                    },
                    showHelper: false,
                    label: t("tyre_widthLabel"),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={4} xl={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "tyre_height",
                    value: tyre_height,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label,
                    },
                    showHelper: false,
                    label: t("tyre_heightLabel"),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={4} xl={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    name: "tyre_rim",
                    value: tyre_rim,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    labelClass: classes.label,
                    InputLabelProps: {
                      className: classes.label,
                    },
                    showHelper: false,
                    label: t("tyre_rimLabel"),
                  }}
                />
              </Grid>
            </Grid>
          </Zoom>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Grid container spacing={1} wrap={"nowrap"}>
            <Grid item xs={12} container justify="center" direction="column">
              <div className={classes.orderIDContainer}>
                <div className={classes.orderIDField}>
                  <Input
                    {...input}
                    type="text"
                    className={classes.field}
                    inputText={{
                      name: "order_id",
                      value: order_id,
                      onChange: handleInputChange,
                      variant: "outlined",
                      labelClass: classes.label,
                      InputLabelProps: {
                        className: classes.label,
                      },
                      showHelper: false,
                      label: t("order_idLabel"),
                    }}
                  />
                </div>
              </div>
              <div className={classes.btnContainer}>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  title={t("undo")}
                  onClick={handleFiltersReset}
                  className={classes.undoBtn}
                >
                  <UndoIcon />
                </Button>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  onClick={openAdvSearch}
                  className={advSearchClasses}
                >
                  <Tooltip
                    title={getAdvSearchLabel()}
                    TransitionComponent={Zoom}
                    placement="top"
                    classes={tooltipClasses}
                  >
                    <FlashOnOutlinedIcon />
                  </Tooltip>
                </Button>
                <Button
                  {...button}
                  type="submit"
                  variant="filled"
                  className={classes.submitBtn}
                >
                  <LoadingText
                    {...loadingText}
                    size={22}
                    loading={loading}
                    text={t("submitSearch")}
                    icon={<SearchIcon />}
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

WorkOrderSearchForm.propTypes = propTypes;
WorkOrderSearchForm.defaultProps = defaultProps;

export default WorkOrderSearchForm;
export {
  propTypes as WorkOrderSearchFormPropTypes,
  defaultProps as WorkOrderSearchFormDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Styles Imports
 */
import { useStyles } from "./ContractsSearchForm.styles";

/**
 * Defaults Imports
 */
import { defaults } from "../WorkstationSettingsContracts/WorkstationSettingsContracts.defaults";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  defaultValues: PropTypes.shape({
    quick_text: PropTypes.string,
    organization_id: PropTypes.string,
    client_name: PropTypes.string,
    contract_number: PropTypes.string,
    from: PropTypes.any,
    to: PropTypes.any,
    created_at: PropTypes.any,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
  }),
  orderByList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  defaultValues: {
    quick_text: "",
    organization_id: "",
    client_name: "",
    contract_number: "",
    from: new Date(),
    to: new Date(),
    created_at: new Date(),
    orderBy: "created_at",
    orderDir: "desc",
  },
  orderByList: defaults.orderBy,
  pageSizeOptions: defaults.pageSizeOptions,
  path: "/contracts",
};

/**
 * Displays the component
 */
const ContractsSearchForm = (props) => {
  const {
    form,
    input,
    button,
    defaultValues,
    defaultSearch,
    setInputs: updateInputs,
    setModels: updateModels,
    setTotal,
    pageSize,
    pageCount,
    setPageSize,
    setPageCount,
    setBackdropLoading,
    loadingText,
    resetFilters,
    setResetFilters,
    clients,
    handleDefaultSearch,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes organizations list
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  const [datesChanged, setDatesChanged] = useState({
    from: false,
    to: false,
    created_at: false,
  });

  const handleDate = (e, name) => {
    const dates = { ...datesChanged };
    dates[name] = true;
    setDatesChanged(dates);
    handleDateChange(e, name);
  };

  /**
   * Initializes the state that holds the options for the select type search models
   */
  const [orderByOptions, setOrderByOptions] = useState([
    { label: t("created_at"), value: "created_at" },
  ]);

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Handles searching for contracts
   */
  const searchContracts = async (data) => {
    try {
      const foundContracts = await apiClient.post("/contracts/search", data);
      if (foundContracts) {
        const { data } = foundContracts;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, contracts: items };
        });

        /**
         * Updates the search models and the inputs
         */
        updateModels(models);

        /**
         * Handles updating the inputs
         */
        const handlePrevState = (prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        };

        setInputs((prevState) => handlePrevState(prevState));
        updateInputs((prevState) => handlePrevState(prevState));

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  const getClientIDByName = (name) => {
    const found = clients.find((client) => {
      return client.name.toLowerCase() === name.toLowerCase();
    });
    return found ? found.id : null;
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];

    /**
     * Gets the inputs
     */
    const {
      quick_text,
      organization_id,
      client_name,
      contract_number,
      from,
      to,
      created_at,
    } = inputs;

    /**
     * Gets the default models
     */
    const {
      quickTextModel,
      organizationIdModel,
      clientNameModel,
      contractNumberModel,
      fromDateModel,
      toDateModel,
      createdAtModel,
    } = defaults.models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      const modelItem = { ...model };
      modelItem["selected"] = input;

      searchModels.push(modelItem);
    };

    if (quick_text) {
      if (modelExists("quick_text")) {
        removeModel("quick_text");
        addModel(quick_text, quickTextModel);
      } else {
        addModel(quick_text, quickTextModel);
      }
    }

    if (organization_id) {
      let org = organization_id !== "all" ? organization_id : null;

      if (modelExists("organization_id")) {
        removeModel("organization_id");
        addModel(org, organizationIdModel);
      } else {
        addModel(org, organizationIdModel);
      }
    }

    if (client_name) {
      const clientID = getClientIDByName(client_name);
      if (modelExists("organization_client_id")) {
        removeModel("organization_client_id");
        addModel(clientID, clientNameModel);
      } else {
        addModel(clientID, clientNameModel);
      }
    }

    if (contract_number) {
      if (modelExists("contract_number")) {
        removeModel("contract_number");
        addModel(contract_number, contractNumberModel);
      } else {
        addModel(contract_number, contractNumberModel);
      }
    }

    if (from && datesChanged.from) {
      if (modelExists("from")) {
        removeModel("from");
        addModel(from, fromDateModel);
      } else {
        addModel(from, fromDateModel);
      }
    }

    if (to && datesChanged.to) {
      if (modelExists("to")) {
        removeModel("to");
        addModel(to, toDateModel);
      } else {
        addModel(to, toDateModel);
      }
    }

    if (created_at && datesChanged.created_at) {
      if (modelExists("created_at")) {
        removeModel("created_at");
        addModel(created_at, createdAtModel);
      } else {
        addModel(created_at, createdAtModel);
      }
    }

    return searchModels.length < 1 ? defaultSearch.models : searchModels;
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: pageCount,
    };

    searchContracts(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const {
    inputs,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    handleDateChange,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    quick_text,
    organization_id,
    contract_number,
    client_name,
    from,
    to,
    created_at,
    orderBy,
    orderDir,
  } = inputs;

  /**
   * Handles resetting the filters
   */
  const handleFiltersReset = () => {
    resetInputs();
    setDatesChanged({ to: false, from: false, created_at: false });
    setBackdropLoading(true);
    updateModels(defaults.models);
    setPageCount(1);
    handleDefaultSearch();
  };

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.settings) {
      const orderByOptions = [
        { label: t("created_at"), value: "created_at" },
        { label: t("name"), value: "name" },
      ];

      setOrderByOptions(orderByOptions);
    }

    if (user.organizations) {
      const organizations = [
        {
          name: t("all"),
          id: "all",
        },
        ...user.organizations,
      ].map((org) => {
        return {
          label: org.name,
          value: org.id,
        };
      });

      setOrganizations(organizations);
    }

    if (user.organization && user.organization.id) {
      if (!inputs.organization_id) {
        setInputs((prevState) => ({
          ...prevState,
          organization_id: user.organization.id,
        }));
      }
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    updateModels(defaultSearch.models);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false);
      handleFiltersReset();
    }
    // eslint-disable-next-line
  }, [resetFilters]);

  useEffect(() => {
    updateInputs(inputs);
    // eslint-disable-next-line
  }, [inputs]);

  return (
    <Form {...form} id="search-contract" onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "quick_text",
                  value: quick_text,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("quick_textLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={3}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "client_name",
                  value: client_name,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("client_nameLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "organization_id",
                  value: organization_id,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  variant: "outlined",
                  label: t("organization_idLabel"),
                  options: organizations,
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={3}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "contract_number",
                  value: contract_number,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("contract_numberLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3}>
              <Input
                className={classes.dateField}
                type="date"
                inputDate={{
                  name: "from",
                  value: from,
                  onChange: handleDate,
                  margin: "normal",
                  label: t("fromLabel"),
                  variant: "outlined",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3}>
              <Input
                className={classes.dateField}
                type="date"
                inputDate={{
                  name: "to",
                  value: to,
                  onChange: handleDate,
                  margin: "normal",
                  label: t("toLabel"),
                  variant: "outlined",
                }}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.dateField}
                type="date"
                inputDate={{
                  name: "created_at",
                  value: created_at,
                  onChange: handleDate,
                  margin: "normal",
                  label: t("created_atLabel"),
                  variant: "outlined",
                }}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderBy",
                  value: orderBy,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderByLabel"),
                  variant: "outlined",
                  options: orderByOptions,
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderDir",
                  value: orderDir,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderDirLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("asc"), value: "asc" },
                    { label: t("desc"), value: "desc" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Grid container spacing={1} wrap={"nowrap"}>
            <Grid item xs={12} container justify="center" direction="column">
              <div className={classes.btnContainer}>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  title={t("undo")}
                  onClick={handleFiltersReset}
                  className={classes.undoBtn}
                >
                  <UndoIcon />
                </Button>
                <Button
                  {...button}
                  type="submit"
                  variant="filled"
                  className={classes.submitBtn}
                >
                  <LoadingText
                    {...loadingText}
                    size={22}
                    loading={loading}
                    text={t("submitSearch")}
                    icon={<SearchIcon />}
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

ContractsSearchForm.propTypes = propTypes;
ContractsSearchForm.defaultProps = defaultProps;

export default ContractsSearchForm;
export {
  propTypes as ContractsSearchFormPropTypes,
  defaultProps as ContractsSearchFormDefaultProps,
};

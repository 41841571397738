/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  notFoundText: {
    fontSize: "1.2rem",
    fontFamily: theme.font.display,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    borderTop: `1px solid #cacaca`,
    marginTop: "1rem",
    borderBottom: `1px solid #cacaca`,
    padding: "0.5rem",
    color: theme.palette.error.main,
  },
  listRoot: {
    display: "flex",
    flex: 4,
    justifyContent: "center",
  },
  list: {
    display: "flex",
    flex: 4,
    alignItems: "center",
    justifyContent: "center",
    "& .MuiPaginationItem-page.Mui-selected": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
    "& .MuiPaginationItem-page:hover": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
  },
  footer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "noWrap",
  },
  pageSizeContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  displayOption: {
    width: 230,
    marginTop: "0.25rem",
  },
  selectField: theme.mixins.searchSelect,
  label: theme.mixins.searchFieldLabel,
  viewContainer: {
    top: -50,
    right: 75,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    marginBottom: "2rem",
    marginTop: "2rem",
  },
  appbar: {
    boxShadow: "none",
    border: "none",
    background: "transparent",
    maxWidth: "inherit",
    width: "inherit",
    display: "flex",
    justifyContent: "center",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      display: "flex",
    },
    "& .MuiTab-wrapper": {
      border: `1px solid #ded6d6`,
      padding: "0.5rem 1rem",
      background: theme.palette.sidebar.main,
      textTransform: "none",
    },
  },
  addNewBtnContainer: {
    marginBottom: "1rem",
  },
  box: {
    position: "relative",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0",
    position: "relative",
  },
  products: {
    marginTop: "2rem",
  },
}));

export { useStyles };

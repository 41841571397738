import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FixedSizeList } from "react-window";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Typography from "@material-ui/core/Typography";

/**
 * Hooks
 */
import { useForm } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AddWorkOrderServiceDraftModal.styles";

/**
 * Validations Import
 */
import Validator from "./AddWorkOrderServiceDraftModal.validations";
import shortid from "shortid";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  form: PropTypes.shape(FormPropTypes),
  input: PropTypes.shape(InputPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  defaultValues: PropTypes.shape({
    organization_product_id: PropTypes.string,
    work_order_id: PropTypes.string,
    is_count_price: PropTypes.bool,
    is_expendable: PropTypes.bool,
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    total: PropTypes.number,
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  data: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  loadingText: LoadingTextDefaultProps,
  form: FormDefaultProps,
  input: InputDefaultProps,
  button: ButtonDefaultProps,
  defaultValues: {
    organization_product_id: "",
    work_order_id: "",
    is_count_price: false,
    is_expendable: false,
    name: "",
    quantity: 4,
    price: 0,
    total: 0,
  },
  open: false,
  closeModal: () => {},
  data: {},
};

/**
 * Displays the component
 */
const AddWorkOrderServiceDraftModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    button,
    loadingText,
    form,
    input,
    defaultValues,
    getProductData,
    products,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setSearch("");
    closeModal();
  };

  /**
   *  Initializes the open form state (qty / price / total)
   */
  const [allProducts, setAllProducts] = useState([]);
  const [localProducts, setLocalProducts] = useState([]);
  const [search, setSearch] = useState("");

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    /**
     * Defines the data object
     */
    const data = {
      id: inputs.id,
      price_conditions: inputs.price_conditions,
      organization_product_id: inputs.organization_product_id,
      name: inputs.name,
      is_count_price: inputs.is_count_price,
      is_expendable: inputs.is_expendable,
      is_service: inputs.is_service,
      quantity,
      price: parseFloat(inputs.price),
      total: parseFloat(parseFloat(inputs.price) * parseFloat(inputs.quantity)),
    };
    getProductData(data);
    handleCloseModal();
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    hasErrors,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { name, quantity, price, total } = inputs;
  const { price: priceError } = errors;

  /**
   * Handles incrementing the quantity
   */
  const handleAddition = () => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        quantity: (Number(prevInputs.quantity) + 1).toString(),
      };
    });
  };

  /**
   * Handles decrementing the quantity
   */
  const handleSubstraction = () => {
    if (quantity <= 1) return;
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        quantity: (Number(prevInputs.quantity) - 1).toString(),
      };
    });
  };

  const handleProductSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length < 1) {
      setLocalProducts(allProducts);
    } else {
      setLocalProducts(
        allProducts.filter((product) =>
          product.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const [activeProduct, setActiveProduct] = useState("");

  const activateProduct = (product) => {
    setSearch(`${product.name} (${product.price} RON)`);
    setActiveProduct(product);
    setLocalProducts(allProducts)
  };

  const renderProducts = (props) => {
    const { index, style, data } = props;

    const item = data[index];

    const isActive = search === `${item.name} (${item.price} RON)`;

    const last = item.lastFromUser;

    const handleActivation = () => activateProduct(item);

    return (
      <ListItem
        button
        style={style}
        key={index}
        onClick={handleActivation}
        className={clsx(isActive ? classes.active : "", {
          [classes.fromUser]: last,
        })}
      >
        <ListItemText primary={`${item.name} (${item.price} RON)`} />
      </ListItem>
    );
  };

  /**
   * Initializes the input timer
   */
  let inputTimer = React.useRef(null);

  /**
   * Handles formatting and updating the total value
   */
  const updateTotal = (quantity, price) => {
    const q = parseFloat(quantity).toFixed(2);
    const p = parseFloat(price).toFixed(2);

    if (Number.isNaN(q)) return;
    if (Number.isNaN(p)) return;
    const result = (q * p).toFixed(2);
    return result === "NaN" ? -1 : result;
  };

  useEffect(() => {
    clearTimeout(inputTimer.current);
    inputTimer.current = setTimeout(() => {
      const product = products.find(
        (product) => product.id === activeProduct.id
      );
      setInputs((prevState) => {
        return {
          ...prevState,
          id: product ? product.id : shortid.generate(),
          price_conditions: product ? product.price_conditions : "",
          is_service: product ? product.is_service : false,
          total: product ? (product.price * quantity).toString() : 0,
          price: product ? product.price.toString() : 0,
          name: product ? product.name : "",
          is_count_price: product ? product.price > 0 : false,
          is_expendable: product ? product.is_expendable : false,
          organization_product_id: product ? product.id : "",
        };
      });
    }, [100]);
    // eslint-disable-next-line
  }, [activeProduct]);

  /**
   * Handles updating the total when quantity or price change
   */
  useEffect(() => {
    const totalString = updateTotal(quantity, price);
    const total = parseFloat(totalString).toFixed(2);

    if (total >= 0) {
      setInputs((prevState) => {
        return {
          ...prevState,
          total,
        };
      });
    }
    // eslint-disable-next-line
  }, [quantity, price]);

  /**
   * Resets the form if the name of the product is blank
   */
  useEffect(() => {
    if (name.length < 1) {
      setInputs((prevState) => {
        return {
          ...prevState,
          quantity: 4,
          price: 0,
          total: 0,
        };
      });
    }
    // eslint-disable-next-line
  }, [name]);

  /**
   * Handles deciding if the submit button is disabled
   */
  const getDisabledState = () => {
    return hasErrors(errors) || name.length < 1 || price < 0;
  };

  useEffect(() => {
    setLocalProducts(products);
    setAllProducts(products);
  }, [products]);

  useEffect(() => {
    setSearch("");
    setActiveProduct("");
  }, [open]);

  return (
    <Modal
      {...modal}
      fullScreen={triggerFullScreen}
      maxWidth="sm"
      open={open}
      onClose={handleCloseModal}
      keepMounted={false}
      scroll="paper"
    >
      <ModalTitle
        {...modalTitle}
        title={`${t("titleAddNewService")}`}
        onClick={handleCloseModal}
      />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid item container xs={12}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank}>
                <CardContent className={classes.content}>
                  <Form
                    {...form}
                    onSubmit={handleSubmit}
                    id="add-work-order-service-modal"
                  >
                    <Zoom in={true}>
                      <Grid container justify="center">
                        <Grid item xs={12}>
                          <div className={classes.formContainer}>
                            <Button
                              {...button}
                              type="button"
                              variant="filled"
                              className={classes.substraction}
                              onClick={handleSubstraction}
                            >
                              <RemoveIcon className={classes.icon} />
                            </Button>

                            <Typography
                              variant="caption"
                              className={classes.qtyLabel}
                            >
                              {t("quantityLabel")}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.qtyValue}
                            >
                              {quantity}
                            </Typography>
                            <Button
                              {...button}
                              type="button"
                              variant="filled"
                              className={classes.addition}
                              onClick={handleAddition}
                            >
                              <AddIcon className={classes.icon} />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Zoom>
                    <Zoom in={true}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              required: true,
                              id: "price",
                              name: "price",
                              value: price,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("priceLabel"),
                              error: priceError,
                              autoFocus: getAutoFocus().price,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              required: true,
                              id: "total",
                              name: "total",
                              value: total,
                              disabled: true,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "outlined",
                              label: t("totalLabel"),
                              showHelper: false,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Zoom>
                    <Grid item xs={12} container direction="column">
                      <Input
                        {...input}
                        type="text"
                        className={classes.nameField}
                        inputText={{
                          id: "name",
                          required: true,
                          name: "name",
                          value: name,
                          onChange: handleInputChange,
                          variant: "outlined",
                          label: t("serviceNameLabel"),
                          showHelper: false,
                        }}
                      />
                      <Input
                        {...input}
                        type="text"
                        className={classes.field}
                        inputText={{
                          id: "search",
                          required: true,
                          name: "search",
                          value: search,
                          onChange: handleProductSearch,
                          variant: "outlined",
                          label: t("searchServiceNameLabel"),
                          showHelper: false,
                        }}
                      />
                      <div className={classes.list}>
                        <FixedSizeList
                          height={400}
                          width="100%"
                          itemSize={46}
                          itemCount={localProducts.length}
                          itemData={localProducts}
                        >
                          {renderProducts}
                        </FixedSizeList>
                      </div>
                    </Grid>
                  </Form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions {...modalActions} onClick={handleCloseModal}>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.submitBtn}
          disabled={getDisabledState()}
          onClick={handleSubmit}
        >
          <LoadingText
            {...loadingText}
            loading={false}
            text={t("submitAdd")}
          />
        </Button>
      </ModalActions>
    </Modal>
  );
};

AddWorkOrderServiceDraftModal.propTypes = propTypes;
AddWorkOrderServiceDraftModal.defaultProps = defaultProps;

export default AddWorkOrderServiceDraftModal;
export {
  propTypes as AddWorkOrderServiceDraftModalPropTypes,
  defaultProps as AddWorkOrderServiceDraftModalDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import DataTable, {
  DataTableDefaultProps,
  DataTablePropTypes,
} from "../DataTable";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

/**
 * Hooks
 */
import {
  useForm,
  useUser,
  useMessage,
  useLanguage,
  useApiClient,
} from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditWorkOrderStage1.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkOrderStage1.styles";

/**
 * Utils imports
 */
import { utils } from "./EditWorkOrderStage1.utils";

/**
 * Defines the prop types
 */
const propTypes = {
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  dataTable: PropTypes.shape(DataTablePropTypes),
  form: PropTypes.shape(FormPropTypes),
  input: PropTypes.shape(InputPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  workOrder: PropTypes.object,
  activeStep: PropTypes.number,
  nextStep: PropTypes.func,
  defaultValues: PropTypes.shape({
    client_name: PropTypes.string,
    organization_client_id: PropTypes.string,
    delegate_name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    plate_number: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    manufacturing_year: PropTypes.string,
    car_km: PropTypes.string,
    tyre_height: PropTypes.string,
    tyre_width: PropTypes.string,
    tyre_rim: PropTypes.string,
    new_tyre: PropTypes.bool,
    tyre_waste: PropTypes.number,
    tyre_pressure: PropTypes.string,
    bolt_torque: PropTypes.string,
    scanning: PropTypes.bool,
    setSelectedCarType: PropTypes.func,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  errorMessages: ErrorMessagesDefaultProps,
  dataTable: DataTableDefaultProps,
  form: FormDefaultProps,
  input: InputDefaultProps,
  button: ButtonDefaultProps,
  loadingText: LoadingTextDefaultProps,
  workOrder: {},
  activeStep: 0,
  nextStep: () => {},
  defaultValues: {
    client_name: "",
    organization_client_id: "",
    delegate_name: "",
    description: "",
    type: "",
    plate_number: "",
    make: "",
    model: "",
    manufacturing_year: "",
    car_km: "",
    tyre_height: "",
    tyre_width: "",
    tyre_rim: "",
    new_tyre: false,
    tyre_waste: 0,
    tyre_pressure: "",
    bolt_torque: "",
    workersArr: [],
    scanning: false,
    setSelectedCarType: () => {},
    setTyreService: () => {},
  },
};

/**
 * Displays the component
 */
const EditWorkOrderStage1 = (props) => {
  const {
    activeStep,
    nextStep,
    workOrder,
    dataTable,
    button,
    loadingText,
    form,
    input,
    defaultValues,
    errorMessages,
    setDataUpdated,
    triggerSave,
    setTriggerSave,
    setSaved,
    loyaltyCardData,
    setLoyaltyCardData,
    clientNames,
    scanning,
    setSelectedCarType,
    setTyreService,
    open,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage, message } = useMessage();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Intializes the tables data
   */
  const [tableData, setTableData] = useState([]);

  /**
   * Initializes the car types list
   */
  const [carTypesList, setCarTypesList] = useState([]);

  /**
   * Initializes the workers state
   */
  const [workers, setWorkers] = useState(props.workers);

  /**
   * Initializes the data modified flag
   */
  const [dataModified, setDataModified] = useState(false);

  /**
   * Gets the active language
   */
  const { activeLanguage } = useLanguage();

  /**
   * Initializes the step queue
   */
  const [goNextStep, setGoNextStep] = useState(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);
  const [manualSave, setManualSave] = useState(false);
  const [clientNameChanged, setClientNameChanged] = useState(false);

  const {
    buildTyresData,
    buildCarData,
    buildWorkOrderData,
    buildDataTable,
    objectHasData,
    checkIfDataChanged,
    initializeInputs,
  } = utils;

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    /**
     * Checks if the data sections have been modified
     */
    const { hasChanged } = checkIfDataChanged(getDataToCheck());
    if (hasChanged) {
      setGoNextStep(true);
      handleSave("submit");
    } else {
      nextStep();
    }
  };

  /**
   * Handles changing the workers list
   */
  const handleWorkerChange = (e, newValue) => {
    handleValidation("workersArr", newValue);
    setInputs((prevState) => {
      return { ...prevState, workersArr: newValue };
    });
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    resetInputs,
    handleValidation,
    checkInputs,
    hasErrors,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    client_name,
    organization_client_id,
    delegate_name,
    description,
    type,
    plate_number,
    make,
    model,
    manufacturing_year,
    car_km,
    tyre_height,
    tyre_width,
    tyre_rim,
    new_tyre,
    tyre_waste,
    tyre_pressure,
    bolt_torque,
    workersArr,
  } = inputs;

  const { client_name: client_nameError, workersArr: workersArrError } = errors;

  /**
   * Handles updating a work order by ID
   */
  const updateWorkOrderByID = async (data, id) => {
    try {
      const updatedWO = await apiClient.put(`/work-orders/${id}`, data);
      if (updatedWO) {
        setDataModified(true);
      }
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Handles updating tyres by id
   */
  const updateTyresByID = async (data, id) => {
    try {
      const updatedTyres = await apiClient.put(`/tyre-services/${id}`, data);
      if (updatedTyres) {
        setDataModified(true);
      }
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Handles updating a car by id
   */
  const updateCarByID = async (data, id) => {
    try {
      const updatedCar = await apiClient.put(`/cars/${id}`, data);
      if (updatedCar) {
        setDataModified(true);
      }
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Handles changing the client name
   */
  const handleClientName = (event, newValue) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        organization_client_id: newValue,
        client_name: newValue
          ? clientNames.find((client) => client.name === newValue)
            ? newValue
            : prevState.client_name
          : prevState.client_name,
      };
    });
  };

  /**
   * Gets the data used to check if anything was modified
   */
  const getDataToCheck = () => ({ workOrder, inputs, workersArr, clientNames });

  /**
   * Builds the workers array
   */
  const buildWorkersArray = () => workersArr.map((worker) => worker.id);

  /**
   * Defines the error handler
   */
  const handleError = (error) => {
    setLoading(false);
    setGoNextStep(false);
    setSaved(false);
    setDataUpdated(false);
    setTriggerSave(false);

    /**
     * Handles dispatching the error message
     */
    dispatchMessage({
      severity: "error",
      component: <ErrorMessages {...errorMessages} error={error} />,
    });
  };

  /**
   * Defines the success message handler
   */
  const handleSuccessMessage = () => {
    setLoading(false);

    if (manualSave) {
      dispatchMessage({
        message: t("successMessage"),
      });
      setManualSave(false);
    }

    setDataUpdated(true);

    if (goNextStep) nextStep();
  };

  /**
   * Handles saving the data
   */
  const handleSave = (mode) => {
    /**
     * Check inputs also submits the form
     */
    if (mode !== "submit") {
      /**
       * strict will not submit the form, just check the inputs
       */
      checkInputs(inputs, "strict");
      if (hasErrors(errors)) {
        setTriggerSave(false);
        return;
      }
    }

    /**
     * Gets the work order data
     */
    const { car, tyre_service, id: workOrderID } = workOrder;
    const { workOrderDataChanged, carDataChanged, tyresDataChanged } =
      checkIfDataChanged(getDataToCheck());

    /**
     * Handles updating the base work order data
     */
    if (workOrderDataChanged) {
      /**
       * Defines the init data
       */
      const initData = { client_name, workers: buildWorkersArray() };

      /**
       * Creates the build config
       */
      const buildConfig = { inputs, initData, clientNames };

      /**
       * Builds the work order data
       */
      const workOrderData = buildWorkOrderData(buildConfig);

      setLoading(true);
      updateWorkOrderByID(workOrderData, workOrderID);
    }

    /**
     * Handles updating the car data
     */
    if (carDataChanged) {
      const carData = buildCarData(inputs);
      setLoading(true);
      updateCarByID(carData, car.id);
    }

    /**
     * Handles updating the tyres data
     */
    if (tyresDataChanged) {
      const tyresData = buildTyresData(inputs, workOrder);
      setLoading(true);
      setTyreService(tyresData);
      updateTyresByID(tyresData, tyre_service.id);
    }
  };

  /**
   * Handles updating the workers state
   */
  useEffect(() => {
    if (props.workers && props.workers.length > 0) setWorkers(props.workers);
  }, [props.workers]);

  /**
   * Handles filling up the car types list
   */
  useEffect(() => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      setCarTypesList(carTypes);
    }
  }, [user]);

  /**
   * Handles updating the inputs with the data from the work order
   */
  useEffect(() => {
    if (!open) resetInputs();
    if (objectHasData(workOrder)) {
      /**
       * Builds the data table
       */
      const dataTable = buildDataTable(workOrder, { translate: t });

      /**
       * Sets the table data state
       */
      setTableData(dataTable);

      /**
       * Initializes the inputs
       */
      setInputs((prevState) =>
        initializeInputs(prevState, workOrder, clientNames)
      );

      setSelectedCarType(workOrder.car_type.id);
    }

    // eslint-disable-next-line
  }, [workOrder, clientNames, open]);

  /**
   * Handles triggering a save
   */
  useEffect(() => {
    if (triggerSave) {
      handleSave();
    }
    // eslint-disable-next-line
  }, [triggerSave]);

  /**
   * Handles going to the next step once the data is saved
   */
  useEffect(() => {
    if (goNextStep && message) {
      setGoNextStep(false);
      nextStep();
    }
    // eslint-disable-next-line
  }, [message]);

  /**
   * Handles displaying the success message
   */
  useEffect(() => {
    if (dataModified) {
      setSaved(true);
      handleSuccessMessage();
      setDataModified(false);
    }
    // eslint-disable-next-line
  }, [dataModified]);

  const getClientName = (client_id) => {
    if (!client_id) return "";

    const found = clientNames.find((client) => {
      return client.id === client_id;
    });

    return found ? found.name : "";
  };

  useEffect(() => {
    if (Object.keys(loyaltyCardData).length > 0) {
      const newClientName = getClientName(
        loyaltyCardData.organization_client_id
      );

      if (client_name !== newClientName) {
        setClientNameChanged(true);
      }
      setInputs((prevState) => ({
        ...prevState,
        client_name: newClientName,
      }));
    }
    // eslint-disable-next-line
  }, [loyaltyCardData]);

  const triggerManualSave = () => setManualSave(true);

  useEffect(() => {
    if (clientNameChanged) {
      setClientNameChanged(false);
      setLoyaltyCardData({});
    }
    // eslint-disable-next-line
  }, [clientNameChanged]);

  useEffect(() => {
    if (manualSave) handleSave("manual");
    // eslint-disable-next-line
  }, [manualSave]);

  return (
    <Form {...form} onSubmit={handleSubmit}>
      <Card className={classes.stepCard}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.flexCenter}>
                <Typography
                  variant="caption"
                  className={classes.scanningPossible}
                >
                  {t("scanningPossible")}
                </Typography>
                <LoadingText
                  {...loadingText}
                  loading={scanning}
                  text=""
                  classes={{ spinner: classes.spinner }}
                />
              </div>
            </Grid>
            <Grid item container xs={12} md={7} spacing={1}>
              <Grid item xs={12} md={5}>
                <Input
                  {...input}
                  type="autocomplete"
                  className={classes.field}
                  inputAutocomplete={{
                    id: "organization_client_id",
                    name: "organization_client_id",
                    inputValue: organization_client_id,
                    onChange: handleClientName,
                    variant: "outlined",
                    freeSolo: false,
                    autoComplete: true,
                    label: t("organization_client_idLabel"),
                    options: clientNames,
                    showHelper: false,
                    getOptionSelected: (option, value) => {
                      return option.id === value.id;
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "client_name",
                    name: "client_name",
                    value: client_name,
                    required: true,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    label: t("client_nameLabel"),
                    showHelper: !!client_nameError,
                    error: client_nameError,
                    autoFocus: getAutoFocus().client_nameError,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "delegate_name",
                    name: "delegate_name",
                    value: delegate_name,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    label: t("delegate_nameLabel"),
                    showHelper: false,
                  }}
                />
              </Grid>
              <DataTable
                {...dataTable}
                data={tableData}
                classes={{
                  value: classes.tableFontSize,
                  label: classes.tableFontSize,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Input
                {...input}
                type="textarea"
                className={classes.textarea}
                inputTextarea={{
                  id: "description",
                  name: "description",
                  value: description,
                  onChange: handleInputChange,
                  showCount: true,
                  rows: 6,
                  rowsMax: 6,
                  maxChars: 200,
                  size: "medium",
                  variant: "outlined",
                  label: t("descriptionLabel"),
                  ariaLabel: t("descriptionLabel"),
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.carTitle}>
                {t("car")}
              </Typography>
            </Grid>
            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Input
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    id: "type",
                    name: "type",
                    value: type,
                    variant: "outlined",
                    onChange: handleInputChange,
                    label: t("car_typeLabel"),
                    options: carTypesList,
                    optionLabel: "name",
                    optionValue: "id",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "plate_number",
                    name: "plate_number",
                    value: plate_number,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    label: t("plate_numberLabel"),
                    showHelper: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "make",
                    name: "make",
                    value: make,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    label: t("makeLabel"),
                    showHelper: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Input
                  {...input}
                  type="text"
                  className={classes.field}
                  inputText={{
                    id: "model",
                    name: "model",
                    value: model,
                    onChange: handleInputChange,
                    maxSize: 70,
                    variant: "outlined",
                    label: t("modelLabel"),
                    showHelper: false,
                  }}
                />
              </Grid>
              {workOrder?.work_order_type?.name === "Service Auto" && (
                <Grid item xs={12} md={2}>
                  <Input
                    {...input}
                    type="numeric"
                    className={classes.field}
                    inputNumeric={{
                      id: "manufacturing_year",
                      name: "manufacturing_year",
                      value: manufacturing_year,
                      onChange: handleInputChange,
                      variant: "outlined",
                      maxSize: 4,
                      minSize: 4,
                      label: t("manufacturing_yearLabel"),
                      showHelper: false,
                    }}
                  />
                </Grid>
              )}
              {workOrder?.work_order_type?.name !== "Spalatorie" && (
                <Grid item xs={12} sm={6} md={2} lg={1}>
                  <Input
                    {...input}
                    type="numeric"
                    className={classes.field}
                    inputNumeric={{
                      id: "car_km",
                      name: "car_km",
                      value: car_km,
                      onChange: handleInputChange,
                      variant: "outlined",
                      label: t("car_kmLabel"),
                      showHelper: false,
                    }}
                  />
                </Grid>
              )}

              {workOrder?.work_order_type?.name === "Vulcanizare" && (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={activeLanguage === "en_hu" ? 2 : 1}
                  >
                    <Input
                      {...input}
                      type="numeric"
                      className={classes.field}
                      inputNumeric={{
                        id: "tyre_width",
                        name: "tyre_width",
                        value: tyre_width,
                        onChange: handleInputChange,
                        maxSize: 5,
                        variant: "outlined",
                        label: t("tyre_widthLabel"),
                        showHelper: false,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={activeLanguage === "en_hu" ? 2 : 1}
                  >
                    <Input
                      {...input}
                      type="numeric"
                      className={classes.field}
                      inputNumeric={{
                        id: "tyre_height",
                        name: "tyre_height",
                        value: tyre_height,
                        onChange: handleInputChange,
                        maxSize: 5,
                        variant: "outlined",
                        label: t("tyre_heightLabel"),
                        showHelper: false,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={activeLanguage === "en_hu" ? 2 : 1}
                  >
                    <Input
                      {...input}
                      type="numeric"
                      className={classes.field}
                      inputNumeric={{
                        id: "tyre_rim",
                        name: "tyre_rim",
                        value: tyre_rim,
                        onChange: handleInputChange,
                        maxSize: 5,
                        variant: "outlined",
                        label: t("tyre_rimLabel"),
                        showHelper: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Input
                      {...input}
                      type="text"
                      className={classes.field}
                      inputText={{
                        id: "tyre_pressure",
                        name: "tyre_pressure",
                        value: tyre_pressure,
                        onChange: handleInputChange,
                        variant: "outlined",
                        label: t("tyre_pressureLabel"),
                        showHelper: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Input
                      {...input}
                      type="text"
                      className={classes.field}
                      inputText={{
                        id: "bolt_torque",
                        name: "bolt_torque",
                        value: bolt_torque,
                        onChange: handleInputChange,
                        variant: "outlined",
                        label: t("bolt_torqueLabel"),
                        showHelper: false,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2}
                    container
                    justify="center"
                  >
                    <Input
                      {...input}
                      type="checkbox"
                      className={classes.checkbox}
                      inputCheckbox={{
                        id: "new_tyre",
                        name: "new_tyre",
                        value: new_tyre,
                        onChange: handleInputChange,
                        variant: "outlined",
                        classes: { root: classes.checkboxChecked },
                        label: t("new_tyreLabel"),
                      }}
                    />
                  </Grid>
                  <Zoom in={new_tyre} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                      <Input
                        {...input}
                        type="numeric"
                        className={classes.field}
                        inputNumeric={{
                          id: "tyre_waste",
                          name: "tyre_waste",
                          value: tyre_waste,
                          onChange: handleInputChange,
                          variant: "outlined",
                          showHelper: false,
                          label: t("tyre_wasteLabel"),
                        }}
                      />
                    </Grid>
                  </Zoom>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <div className={classes.workersContainer}>
              <Input
                {...input}
                type="autocomplete"
                className={classes.field}
                inputAutocomplete={{
                  id: "workersArr",
                  required: true,
                  multiple: true,
                  limitTags: 7,
                  freeSolo: false,
                  fullObjectValue: true,
                  name: "workersArr",
                  value: workersArr,
                  onChange: handleWorkerChange,
                  styles: { root: classes.workersList },
                  variant: "outlined",
                  label: t("workers"),
                  placeholder: t("workersSearchLabel"),
                  options: workers,
                  getOptionSelected: (option, value) => {
                    return option.id === value.id;
                  },
                  error: workersArrError,
                  autoFocus: getAutoFocus().workersArr,
                }}
              />
            </div>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div className={classes.buttonsContainer}>
            <Button
              {...button}
              type="button"
              variant="filled"
              onClick={triggerManualSave}
              className={classes.saveBtn}
            >
              <LoadingText
                {...loadingText}
                loading={loading}
                text={t("save")}
                icon={<SaveIcon />}
              />
            </Button>
            <Button
              {...button}
              type="button"
              variant="filled"
              disabled={activeStep === 0}
              className={classes.submitButton}
            >
              <NavigateBeforeIcon className={classes.beforeIcon} />
              {t("previous")}
            </Button>
            <Button
              {...button}
              type="submit"
              variant="filled"
              disabled={activeStep === 2}
              className={classes.submitButton}
            >
              {t("next")}
              <NavigateNextIcon className={classes.nextIcon} />
            </Button>
          </div>
        </CardActions>
      </Card>
    </Form>
  );
};

EditWorkOrderStage1.propTypes = propTypes;
EditWorkOrderStage1.defaultProps = defaultProps;

export default EditWorkOrderStage1;
export {
  propTypes as EditWorkOrderStage1PropTypes,
  defaultProps as EditWorkOrderStage1DefaultProps,
};

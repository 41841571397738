/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  captcha: {
    margin: 0,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "1.5rem",
  },
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noGDPR: {
    color: "#e61919",
    fontSize: "0.8rem",
    fontWeight: 800,
    fontFamily: theme.font.family,
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  total: {
    color: theme.palette.secondary.dark
  },
  discount: {
    color: theme.palette.error.main
  },
  paid: {
    color: theme.palette.success.main
  },
  resultRow: {
    "& .MuiTableCell-sizeSmall": {
      borderBottom: "none",
      fontWeight: 800,
      paddingBottom: 2,
      paddingTop: 2
    }
  },
  tableTitle: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 0,
    marginBottom: "0.5rem",
    borderBottom: `1px solid #e0e0e0`,
    display: "flex"
  },
  table: {
    "& .MuiTableCell-root": {
      fontSize: "0.75rem"
    },
    "& .MuiTableCell-alignRight": {
      textAlign: "right"
    },
    "& .MuiTableCell-alignCenter": {
      textAlign: "center"
    },
    "& .MuiTableCell-head": {
      fontWeight: 800,
      paddingTop: 0,
      paddingBottom: 4,
      lineHeight: 1.2
    }
  }
}));

export { useStyles };

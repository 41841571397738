/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.5rem 0",
    background: theme.palette.common.white,
  },
  downloadBtn: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 3,
    fontWeight: 400,
    height: 30,
    margin: 0,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  importBtn: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 3,
    fontWeight: 400,
    height: 30,
    margin: "0 0.5rem",
    background: theme.palette.primary.accent,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import ViewPriceConditionsModal, {
  ViewPriceConditionsModalDefaultProps,
  ViewPriceConditionsModalPropTypes,
} from "../ViewPriceConditionsModal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import VisibilityIcon from "@material-ui/icons/Visibility";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./ProductsTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  viewConditions: PropTypes.shape(ViewPriceConditionsModalPropTypes),
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFiltersReset: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  viewConditions: ViewPriceConditionsModalDefaultProps,
  handleDelete: null,
  handleAdd: () => {},
  handleEdit: () => {},
  handlePageChange: () => {},
  handleChangeRowsPerPage: () => {},
  handleFiltersReset: () => {},
  rowsPerPage: 0,
  page: 0,
  count: 0,
};

/**
 * Displays the component
 */
const ProductsTable = (props) => {
  const {
    button,
    isService,
    isExpendable,
    editButton,
    deleteButton,
    dynamicTable,
    viewConditions,
    handleAdd,
    handleEdit,
    handleDelete,
    handlePageChange,
    handleChangeRowsPerPage,
    handleFiltersReset,
    rowsPerPage,
    page,
    count,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  const [viewData, setViewData] = useState({});

  /**
   * Initializes the modal state
   */
  const [open, setOpen] = useState(false);

  /**
   * Defines the modal controllers
   */
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Handles mapping the id  with the organization name
   * @param {String|Number} id
   */
  const getOrganizationName = (id) => {
    if (organizations) {
      const organization = organizations.find((org) => org.id === id);
      return organization ? organization.name : "";
    }
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () => user.products && user.products.length < 1;

  const handleView = (props) => {
    setViewData(props);
    handleOpenModal();
  };

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const {
      isService,
      isExpendable,
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    } = props;

    let tableOptions = {
      withCount: true,
      withAdd: true,
      withSearch: true,
      withPagination: true,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: true,
        addResult: true,
      },
      pagination: {
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        total: count,
        currentPage: page,
      },
      addResult: handleAdd,
      resetFilters: handleFiltersReset,
      defaultOrderBy: "name",
      fields: [
        {
          name: "name",
          label: t("name"),
          align: "start",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "um",
          label: t("um"),
          align: "end",
          key: true,
          searchField: true,
        },
        {
          name: "car_type_id",
          label: t("car_type_id"),
          align: "center",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "is_count_price",
          label: t("is_count_price"),
          type: "boolean",
          align: "center",
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "price",
          label: t("price"),
          align: "center",
          key: true,
          searchField: true,
          sort: true,
          displayFilter: (field, row, options) => {
            const { price } = row;

            return `${price} RON`;
          },
        },
      ],
    };

    if (isExpendable || isService) {
      tableOptions.fields.push({
        name: "price_conditions",
        label: t("price_conditions"),
        type: "action",
        align: "center",
        key: true,
        searchField: true,
        sort: true,
        showCount: true,
        buttons: [
          {
            name: "view_price_conditions",
            type: "view",
            icon: null,
            component: (
              <Button
                {...button}
                type="button"
                variant="filled"
                className={classes.viewBtn}
                title={t("view")}
              >
                <VisibilityIcon />
              </Button>
            ),
            action: handleView,
          },
        ],
      });
    }

    tableOptions.fields.push({
      name: "operations",
      label: t("operations"),
      type: "actions",
      key: false,
      align: "center",
      searchField: false,
      maxSize: 100,
      buttons: [
        {
          name: "edit_product",
          type: "edit",
          icon: null,
          component: (
            <EditButton
              {...editButton}
              className={classes.button}
              title={t("edit")}
            />
          ),
          action: handleEdit,
        },
        {
          name: "delete_product",
          type: "delete",
          icon: null,
          component: (
            <DeleteButton
              {...deleteButton}
              className={classes.button}
              title={t("delete")}
            />
          ),
          action: handleDelete,
        },
      ],
    });

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({
      isService,
      isExpendable,
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage,
    });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [count, page, rowsPerPage, isService, isExpendable]);

  const getCarTypeName = (car_types, id) => {
    const found = car_types.find((type) => type.id === id);
    return found ? found.name : "-";
  };

  const getWorkOrderTypeName = (work_order_types, id) => {
    const found = work_order_types.find((type) => type.id === id);
    return found ? found.name : "-";
  };

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.organizations) {
      setOrganizations(user.organizations);
    }
    if (user.products) {
      const car_types = user.settings.account.car_types;
      const work_order_types = user.settings.account.work_order_types;

      const data = user.products.map((product) => {
        const {
          organization_id,
          price,
          work_order_type_id,
          car_type_id,
        } = product;

        const productData = {
          ...product,
          price: price ? price : 0,
          organization_id: getOrganizationName(organization_id),
        };

        productData["car_type_id"] = getCarTypeName(car_types, car_type_id);
        productData["work_order_type_id"] = getWorkOrderTypeName(
          work_order_types,
          work_order_type_id
        );

        return {
          ...productData,
        };
      });
      setCollection(data);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (Object(viewData).length > 0) {
      handleOpenModal();
    }
  }, [viewData]);

  return (
    <Fragment>
      {renderDynamicTable()}
      <ViewPriceConditionsModal
        {...viewConditions}
        open={open}
        closeModal={handleCloseModal}
        viewData={viewData}
        setViewData={setViewData}
      />
    </Fragment>
  );
};

ProductsTable.propTypes = propTypes;
ProductsTable.defaultProps = defaultProps;

export default ProductsTable;
export {
  propTypes as ProductsTablePropTypes,
  defaultProps as ProductsTableDefaultProps,
};

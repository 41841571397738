/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: "0.75rem",
    border: `1px solid #cac2c2`,
    background: "#f3f3f3",
    boxShadow: "2px 1px 5px 1px #c5c5c5",
  },
  timerBox: {
    fontSize: "2.5rem",
    fontWeight: 800,
    color: theme.palette.primary.main,
    textAlign: "center",
    border: "1px solid #cac2c2",
    background: theme.palette.common.white,
    marginBottom: "1rem",
  },
  label: {
    display: "block",
    width: "100%",
    paddingLeft: "0.25rem",
    fontSize: "0.8rem",
    fontWeight: 800,
    color: theme.palette.sidebar.text,
  },
  value: {
    fontSize: "1.5rem",
    color: theme.palette.secondary.main,
    textAlign: "center",
    border: "1px solid #cac2c2",
    background: theme.palette.common.white,
    padding: "0.25rem",
    marginBottom: "1rem",
  },
  actions: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0.5rem",
    background: theme.palette.common.white,
    padding: "0.25rem",
    margin: "0.25rem",
    border: "1px solid #cac2c2",
  },
  timerBtn: {
    margin: "0.35rem",
    borderRadius: 3,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    transform: "scale(1.2)",
  },
  stopTimer: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
  crud: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editBtn: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
      boxShadow: "none",
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ContractsTable, {
  ContractsTableDefaultProps,
  ContractsTablePropTypes,
} from "../ContractsTable";
import AddContractModal, {
  AddContractModalDefaultProps,
  AddContractModalPropTypes,
} from "../AddContractModal";
import EditContractModal, {
  EditContractModalDefaultProps,
  EditContractModalPropTypes,
} from "../EditContractModal";
import DeleteContractModal, {
  DeleteContractModalDefaultProps,
  DeleteContractModalPropTypes,
} from "../DeleteContractModal";
import ContractsSearchForm, {
  ContractsSearchFormDefaultProps,
  ContractsSearchFormPropTypes,
} from "../ContractsSearchForm";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkstationSettingsContracts.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./WorkstationSettingsContracts.defaults";

/**
 * Hooks
 */
import {
  useLocalStorage,
  useUser,
  useMessage,
  useApiClient,
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  contractsTable: PropTypes.shape(ContractsTablePropTypes),
  deleteContractModal: PropTypes.shape(DeleteContractModalPropTypes),
  editContractModal: PropTypes.shape(EditContractModalPropTypes),
  searchForm: PropTypes.shape(ContractsSearchFormPropTypes),
  addContractModal: PropTypes.shape(AddContractModalPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  contractsTable: ContractsTableDefaultProps,
  deleteContractModal: DeleteContractModalDefaultProps,
  editContractModal: EditContractModalDefaultProps,
  searchForm: ContractsSearchFormDefaultProps,
  addContractModal: AddContractModalDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "loyalty_card",
  path: "/contracts",
};

/**
 * Displays the component
 */
const WorkstationSettingsContracts = (props) => {
  const {
    title,
    wrapper,
    container,
    contractsTable,
    editContractModal,
    defaultSearch,
    deleteContractModal,
    searchForm,
    loadingBackdrop,
    addContractModal,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    addContract: false,
    editContract: false,
    deleteContract: false,
  });

  /**
   * Initializes the state that hold the data for the modals
   */
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});

  /**
   * Handles searching for contracts
   */
  const searchContracts = async (data) => {
    try {
      const contracts = await apiClient.post("/contracts/search", data);
      if (contracts) {
        const { data } = contracts;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setUpdated(false);
        setDefaultSearchDone(true);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, contracts: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);

        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
        setDefaultSearchDone(true);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setUpdated(false);
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles opening the edit modal
   */
  const handleEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const handleDelete = (props) => setDeleteData(props);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAdd = () => handleOpenModal("addContract");
  const closeAdd = () => handleCloseModal("addContract");

  /**
   * Defines the edit User modal handlers
   */
  const openEdit = () => handleOpenModal("editContract");
  const closeEdit = () => handleCloseModal("editContract");

  /**
   * Defines the delete User modal handlers
   */
  const openDelete = () => handleOpenModal("deleteContract");
  const closeDelete = () => handleCloseModal("deleteContract");

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [clients, setClients] = useState([]);

  /**
   * Initializes the state related to the Pagination
   */
  const [pageSize, setPageSize] = useLocalStorage("contractsPageSize");
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [updated, setUpdated] = useState(false);

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  const [defaultSearchDone, setDefaultSearchDone] = useState(false);

  /**
   * Initializes the inputs state
   */
  const [inputs, setInputs] = useState({});

  /**
   * Gets the inputs
   */
  const { orderBy, orderDir } = inputs;

  /**
   * Initializes the reset filters flag
   */
  const [resetFilters, setResetFilters] = useState(false);

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (user.contracts && user.contracts.length < 1 && pageCount === 0) return;
    setBackdropLoading(true);

    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page,
    };

    searchContracts(data);
    window.scrollTo(0, 0);
  };

  /**
   * Handles getting the default model
   */
  const getDefaultModel = (model) => ({ ...defaults.models[model] });

  const getUserOrganizationModelValue = (mode, inputs) => {
    if (mode === "default") return user.organization && user.organization.id;
    if (inputs.organization_id) {
      const value =
        inputs.organization_id !== "all" ? inputs.organization_id : null;
      return value;
    }
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const organizationIdModel = getDefaultModel("organizationIdModel");
    organizationIdModel["selected"] = getUserOrganizationModelValue(
      "default",
      inputs
    );

    const filteredModels = defaultSearch.models.filter(
      (model) => model.field !== "organization_id"
    );

    const data = {
      ...defaultSearch,
      models: [...filteredModels, { ...organizationIdModel }],
      page_size: pageSize || defaultSearch.page_size,
      page_count: 1,
    };

    return data;
  };

  /**
   * Handles constructing the data object for the search
   */
  const getSearchParams = (pgCount) => {
    const data = {
      models,
      order_by: inputs.orderBy || defaultSearch.order_by,
      order_dir: inputs.orderDir || defaultSearch.order_dir,
      page_size: pageSize || defaultSearch.page_size,
      page_count: pgCount || pageCount || 1,
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchContracts(getDefaultSearch());
  };

  /**
   * Handles searching for work orders
   */
  const handleSearch = (pgCount) => {
    const data = getSearchParams(pgCount);

    if (Object.keys(data).length > 0) {
      searchContracts(data);
    }
  };

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    setPageSize(e.target.value);
  };

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setResetFilters(true);

  /**
   * Handles triggering a search if updated
   */
  useEffect(() => {
    if (updated) handleSearch();
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles searching on page change
   */
  useEffect(() => {
    if (defaultSearchDone) {
      window.scrollTo(0, 0);
      handleSearch();
    }
    // eslint-disable-next-line
  }, [pageSize]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEdit();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDelete();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    if (user.organization) {
      setModels(defaultSearch.models);
      handleDefaultSearch();
    }

    // eslint-disable-next-line
  }, [user.organization]);

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      if (user.type !== "admin") {
        const organizations = user.organizations;
        const orgFound = organizations.find(
          (org) => org.id === user.organization.id
        );
        setOrganizations([{ value: orgFound.id, label: orgFound.name }]);
      } else {
        const orgs = user.organizations.map((org) => {
          return {
            value: org.id,
            label: org.name,
          };
        });
        setOrganizations(orgs);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user.clients) {
      setClients(user.clients);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        className={classes.title}
        icon={<FontAwesomeIcon icon={faFileContract} />}
        title={t("titleContracts")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <ContractsSearchForm
                  {...searchForm}
                  setInputs={setInputs}
                  setModels={setModels}
                  setTotal={setTotal}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  setPageCount={setPageCount}
                  setBackdropLoading={setBackdropLoading}
                  setUpdated={setUpdated}
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                  clients={clients}
                  handleDefaultSearch={handleDefaultSearch}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.contracts}>
              <ContractsTable
                {...contractsTable}
                handleAdd={openAdd}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleFiltersReset={handleFiltersReset}
                clients={clients}
                rowsPerPage={pageSize}
                page={pageCount}
                count={total}
              />
            </Grid>
            <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
            <AddContractModal
              {...addContractModal}
              open={modalState.addContract}
              closeModal={closeAdd}
              organizations={organizations}
              clients={clients}
              setUpdated={setUpdated}
              defaultSearch={defaultSearch}
            />
            <EditContractModal
              {...editContractModal}
              open={modalState.editContract}
              closeModal={closeEdit}
              organizations={organizations}
              clients={clients}
              editData={editData}
              setEditData={setEditData}
              setUpdated={setUpdated}
            />
            <DeleteContractModal
              {...deleteContractModal}
              open={modalState.deleteContract}
              closeModal={closeDelete}
              deleteData={deleteData}
              setUpdated={setUpdated}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

WorkstationSettingsContracts.propTypes = propTypes;
WorkstationSettingsContracts.defaultProps = defaultProps;

export default WorkstationSettingsContracts;
export {
  propTypes as WorkstationSettingsContractsPropTypes,
  defaultProps as WorkstationSettingsContractsDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicSearchForm, {
  DynamicSearchFormPropTypes,
  DynamicSearchFormDefaultProps,
} from "../DynamicSearchForm";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  dynamicSearch: PropTypes.shape(DynamicSearchFormPropTypes),
  apiCallSetter: PropTypes.func,
  setBackdropLoading: PropTypes.func,
  resetFilters: PropTypes.bool,
  setResetFilters: PropTypes.func,
  setData: PropTypes.func,
  setActiveOrg: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  dynamicSearch: DynamicSearchFormDefaultProps,
  apiCallSetter: () => {},
  setBackdropLoading: () => {},
  resetFilters: false,
  setResetFilters: () => {},
  setData: () => {},
  setActiveOrg: () => {},
};

/**
 * Displays the component
 */
const ReportsDailySearch = (props) => {
  const {
    dynamicSearch,
    setApiCallMade,
    setData,
    setBackdropLoading,
    setActiveOrg,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the table options
   */
  const [searchOptions, setSearchOptions] = useState({});

  /**
   * Initializes the form options
   */
  const [formOptions, setFormOptions] = useState({});

  /**
   * Initializes the models
   */

  const [models, setModels] = useState({});

  /**
   * Initializes the user data ready flag
   */
  const [userDataReady, setUserDataReady] = useState(false);

  /**
   * Handles getting the organizations
   */
  const getUserOrganizations = () => {
    if (user.organizations) {
      const organizations = [
        {
          name: t("all"),
          id: "all",
        },
        ...user.organizations,
      ].map((org) => {
        return {
          name: org.name,
          id: org.id,
        };
      });

      return organizations;
    }
  };

  const getWorkOrderTypes = () => {
    return (
      user.settings &&
      user.settings.account &&
      user.settings.account.work_order_types.map((type) => {
        return {
          name: type.name,
          id: type.id,
        };
      })
    );
  };

  /**
   * Checks if the table options are ready
   */
  const isReady = (config) => Object.keys(config).length > 0;

  const workOrderTypes = getWorkOrderTypes();
  const userOrganizations = getUserOrganizations();

  /**
   * Handles getting the table options
   */
  const getSearchOptions = () => {
    const todayStart = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
    const todayEnd = formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59";

    let searchOptions = {
      formID: "daily-reports",
      separateContainers: false,
      updateState: {
        key: "organization_id",
        fn: setActiveOrg,
      },
      cleanModels: (models) => {
        let newModels = [];

        models.forEach((model) => {
          if (model.field === "organization_id") {
            newModels.push({
              ...model,
              selected: model.selected !== "all" ? model.selected : null,
            });
            return;
          }

          newModels.push({ ...model });
        });

        return newModels;
      },
      gridConfig: {
        justify: "flex-start",
        actionsContainer: {
          xs: 12,
          sm: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
      },
      submitBtnText: t("submitSearch"),
      grid: {
        form: {
          xs: 12,
        },
      },
      defaultValues: {
        organization_id: user.organization && user.organization.id,
        work_order_type_id: workOrderTypes[2].id,
        finished: [todayStart, todayEnd],
        order_by: "created_at",
        order_dir: "desc",
      },
    };

    return searchOptions;
  };

  const getFormOptions = () => {
    const formOptions = [
      {
        name: "organization_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
          xl: 3,
        },
        label: t("organization_id"),
        props: {
          options: userOrganizations,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "work_order_type_id",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 3,
          lg: 2,
          xl: 2,
        },
        label: t("work_order_type"),
        props: {
          options: workOrderTypes,
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "finished",
        inputType: "dateRange",
        label: "finished",
        props: {},
        hidden: true,
      },
      {
        name: "order_by",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 3,
          lg: 2,
          xl: 2,
        },
        label: t("order_by"),
        props: {
          options: [{ id: "created_at", name: t("created_at") }],
          optionValue: "id",
          optionLabel: "name",
        },
      },
      {
        name: "order_dir",
        inputType: "select",
        grid: {
          xs: 12,
          sm: 6,
          md: 3,
          lg: 2,
          xl: 2,
        },
        label: t("order_dir"),
        props: {
          options: [
            { id: "asc", name: t("asc") },
            { id: "desc", name: t("desc") },
          ],
          optionValue: "id",
          optionLabel: "name",
        },
      },
    ];

    return formOptions;
  };

  const getModels = () => {
    const todayStart = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
    const todayEnd = formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59";

    const organizationID_model = {
      label: "Organization id",
      field: "organization_id",
      type: "dropdown",
      order: 2,
      options: [],
      selected: user && user.organization && user.organization.id,
    };

    const workOrderType_model = {
      label: "Work Order Type",
      field: "work_order_type_id",
      type: "equals",
      order: 1,
      options: [],
      selected: workOrderTypes[2].id,
    };

    const finished_model = {
      label: "Finished",
      field: "finished",
      type: "range",
      order: 1,
      options: [],
      selected: [todayStart, todayEnd],
    };

    const models = {
      organization_id: organizationID_model,
      work_order_type_id: workOrderType_model,
      finished: finished_model,
    };

    return models;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicSearchForm = () => {
    const ready =
      userDataReady &&
      isReady(searchOptions) &&
      isReady(formOptions) &&
      isReady(models);

    return ready ? (
      <DynamicSearchForm
        {...dynamicSearch}
        formOptions={formOptions}
        searchOptions={searchOptions}
        models={models}
        apiCallProps={{
          type: "work-order",
          format: "normal",
        }}
        setBackdropLoading={setBackdropLoading}
        setApiCallMade={setApiCallMade}
        setData={setData}
      />
    ) : null;
  };

  const getConfig = () => {
    const searchOptions = getSearchOptions();
    const formOptions = getFormOptions();
    const models = getModels();

    return { searchOptions, formOptions, models };
  };

  useEffect(() => {
    if (userOrganizations && workOrderTypes) {
      setUserDataReady(true);
    }
  }, [userOrganizations, workOrderTypes]);

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    if (userDataReady) {
      const { searchOptions, formOptions, models } = getConfig();

      setSearchOptions(searchOptions);
      setFormOptions(formOptions);
      setModels(models);
    }

    // eslint-disable-next-line
  }, [userDataReady]);

  return renderDynamicSearchForm();
};

ReportsDailySearch.propTypes = propTypes;
ReportsDailySearch.defaultProps = defaultProps;

export default ReportsDailySearch;
export {
  propTypes as ReportsDailySearchPropTypes,
  defaultProps as ReportsDailySearchDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: theme.mixins.submoduleContainer,
  box: {
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.common.white,
    margin: "1rem 0",
    padding: "0.5rem 1rem",
    paddingBottom: "1rem",
    position: "relative"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  datePicker: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiIconButton-root:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "inherit",
      padding: "8px 14px",
    },
  },
}));

export { useStyles };

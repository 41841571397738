import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 * @see https://material-ui.com/components/pickers/#date-time-pickers
 */
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { ro } from "date-fns/locale";
import { format as formatDate } from "date-fns";
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports
 */
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

/**
 * Styles Imports
 */
import { useStyles } from "./InputDate.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  label: PropTypes.any,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  margin: PropTypes.string,
  format: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  className: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  label: "",
  disabled: false,
  minDate: new Date("1950"),
  maxDate: new Date("2099"),
  margin: "normal",
  format: "yyyy/MM/dd",
  name: "inputDate",
  onChange: () => {},
  value: "",
  required: false,
  className: "",
};

/**
 * Displays the component
 */
const InputDate = (props) => {
  const {
    label,
    disabled,
    minDate,
    maxDate,
    margin,
    format,
    name,
    onChange,
    value,
    required,
    className,
    variant,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const handleAccept = () => setOpen(false);

  /**
   * Handles the date change
   */
  const handleDateChange = (e) => {
    onChange(e, name);
  };

  /**
   * Renders the text field component
   */
  const TextFieldComponent = (props) => {
    /**
     * Formats the date
     */
    const date = formatDate(new Date(value), format);

    return (
      <TextField
        {...props}
        type="text"
        disabled={true}
        variant={variant}
        value={date}
        onClick={handleClick}
        className={clsx(classes.datePicker, {
          [className]: !!className,
        })}
      />
    );
  };

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      className={className}
      locale={ro}
    >
      <KeyboardDatePicker
        required={required}
        disabled={disabled}
        allowKeyboardControl
        format={format}
        open={open}
        minDate={minDate}
        maxDate={maxDate}
        onClose={handleClose}
        onAccept={handleAccept}
        onChange={handleDateChange}
        margin={margin}
        value={formatDate(new Date(value), format)}
        label={label}
        KeyboardButtonProps={{ title: t("titleChooseDate") }}
        TextFieldComponent={TextFieldComponent}
      />
    </MuiPickersUtilsProvider>
  );
};

InputDate.propTypes = propTypes;
InputDate.defaultProps = defaultProps;

export default InputDate;
export {
  propTypes as InputDatePropTypes,
  defaultProps as InputDateDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Imports i18n
 */
import { useTranslation } from "react-i18next";

/**
 *  Imports Material UI Components
 */
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Imports Component
 */
import CreateButton from "../CreateButton";
import Input from "../Input";
import AddClientModal from "../AddClientModal";

/**
 * Imports hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports the component styles
 */
import { useStyles } from "./TyreServiceCreateStep1.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    client_name: PropTypes.string,
    organization_client_id: PropTypes.string,
    delegate_name: PropTypes.string,
    work_order_type: PropTypes.any,
    description: PropTypes.string,
    car_description: PropTypes.string,
    car_type: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    vin_number: PropTypes.string,
    wheel_type: PropTypes.string,
    car_km: PropTypes.string,
    new_tyre: PropTypes.bool,
    tyre_width: PropTypes.string,
    tyre_height: PropTypes.string,
    tyre_rim: PropTypes.string,
    tyre_pressure: PropTypes.string,
    tyre_waste: PropTypes.number,
    bolt_torque: PropTypes.string,
    plate_number: PropTypes.string,
    workers: PropTypes.array,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    client_name: "",
    organization_client_id: "",
    delegate_name: "",
    work_order_type: "",
    description: "",
    car_description: "",
    car_type: "",
    make: "",
    model: "",
    vin_number: "",
    manufacturing_year: 0,
    wheel_type: "",
    car_km: "",
    new_tyre: false,
    tyre_width: "",
    tyre_height: "",
    tyre_rim: "",
    tyre_pressure: "",
    tyre_waste: 0,
    bolt_torque: "",
    plate_number: "",
    workers: [],
    formData: {},
  }, 
};

/**
 * Displays the component
 */
const TyreServiceCreateStep1 = (props) => {
  const {
    inputs,
    setInputs,
    errors,
    handleInputChange,
    getAutoFocus, 
    handleValidation,
    originalPlate
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the car types list
   */
  const [carTypesList, setCarTypesList] = useState([]);

  /**
   * Gets the user
   */
  const { user, setUser } = useUser();

  /**
   * Initializes the workers state
   */
  const [workersList, setWorkersList] = useState([]);

  /**
   * Initializes the wheel types list
   */
  const [wheelTypesList, setWheelTypesList] = useState([]);

  /**
   * Initializes the client names list
   */
  const [clientNames, setClientNames] = useState([]);

  /**
   * Initializes the add client modal state
   */
  const [addClientOpen, setAddClientOpen] = useState(false);

  /**
   *  Initializes the new client state
   */
  const [newClient, setNewClient] = useState("");

  /**
   * Gets the inputs
   */
  const {
    workers,
    organization_client_id,
    client_name,
    delegate_name,
    description,
    plate_number,
    car_type,
    make,
    model,
    vin_number,
    car_description,
    wheel_type,
    car_km,
    new_tyre,
    tyre_width,
    tyre_height,
    tyre_rim,
    tyre_waste,
    tyre_pressure,
    bolt_torque,
  } = inputs;

  /**
   * Gets the input errors
   */
  const {
    car_type: car_typeError,
    client_name: client_nameError,
    wheel_type: wheel_typeError,
    workers: workersError,
    plate_number: plate_numberError
  } = errors;
 
  /**
   * Handles changing the workers list
   */
  const handleWorkerChange = (e, newValue) => {
    handleValidation("workers", newValue);
    if (newValue) {
      setInputs((prevState) => {
        return { ...prevState, workers: newValue };
      });
    }
  };

  /**
   * Handles opening the add client modal
   */
  const openAddClientModal = () => setAddClientOpen(true);

  /**
   * Handles closing the add client modal
   */
  const closeAddClientModal = () => setAddClientOpen(false);

  /**
   * Handles changing the client name
   */
  const handleClientName = (event, newValue) => {
    if (newValue) {
      setInputs((prevState) => {
        return {
          ...prevState,
          organization_client_id: newValue,
          client_name: newValue
            ? clientNames.find((client) => client.name === newValue)
              ? newValue
              : prevState.client_name
            : prevState.client_name,
        };
      });
    }
  };

  /**
   * Sets the default inputs based on user data
   */
  useEffect(() => {
    if (user.clients) {
      setClientNames(user.clients);
    }

    if (user.workers) {
      setWorkersList(user.workers);
    }

    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const wheelTypes = user.settings.account.wheel_types;

      setWheelTypesList(wheelTypes);
      setCarTypesList(carTypes);
    }
    // eslint-disable-next-line
  }, [user]);

 
  /**
   * Updates the user clients when a new client is added
   */
  useEffect(() => {
    if (newClient) {
      const clients = user.clients;
      clients.push(newClient);

      setClientNames(clients);
      setUser((prevState) => ({ ...prevState, clients }));
      setInputs((prevState) => ({
        ...prevState,
        client_name: newClient.is_company
          ? newClient.company.name
          : newClient.name,
        delegate_name: newClient.delegate_name ? newClient.delegate_name : "",
      }));
      setNewClient("");
    }
    // eslint-disable-next-line
  }, [newClient]);

  useEffect(() => {
   if(plate_number && plate_number !== originalPlate){
     const oldPlates =  localStorage.getItem("oldPlates");
     if(oldPlates){
      localStorage.setItem("oldPlates", JSON.stringify([...JSON.parse(oldPlates), originalPlate]))
     } else {
      localStorage.setItem("oldPlates", JSON.stringify([originalPlate]))
     }
    
   }
    // eslint-disable-next-line
  },[plate_number])

  return (
    <Grid item container xs={12}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.blank}>
            <CardContent className={classes.content}>
              <Grid container>
                <Grid item xs={12}>
                  <Card className={classes.stepCard}>
                    <CardContent className={classes.cardContent}>
                      <Grid container>
                        <Grid item container spacing={1} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="caption"
                                  className={clsx(
                                    classes.sectionTitle,
                                    classes.noMarginBottom
                                  )}
                                >
                                  {t("client")}
                                  <CreateButton
                                    onClick={openAddClientModal}
                                    className={classes.smallBtn}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Input
                                  type="autocomplete"
                                  className={classes.field}
                                  inputAutocomplete={{
                                    id: "organization_client_id",
                                    name: "organization_client_id",
                                    inputValue: organization_client_id,
                                    onChange: handleClientName,
                                    variant: "outlined",
                                    freeSolo: false,
                                    autoComplete: true,
                                    label: t("organization_client_idLabel"),
                                    options: clientNames,
                                    showHelper: false,
                                    getOptionSelected: (option, value) => {
                                      return option.id === value.id;
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Input
                                  type="text"
                                  className={classes.field}
                                  inputText={{
                                    id: "client_name",
                                    name: "client_name",
                                    value: client_name,
                                    required: true,
                                    onChange: handleInputChange,
                                    maxSize: 70,
                                    variant: "outlined",
                                    label: t("client_nameLabel"),
                                    showHelper: !!client_nameError,
                                    error: client_nameError,
                                    autoFocus: getAutoFocus().client_nameError,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Input
                                  type="text"
                                  className={classes.field}
                                  inputText={{
                                    id: "delegate_name",
                                    name: "delegate_name",
                                    value: delegate_name,
                                    onChange: handleInputChange,
                                    maxSize: 70,
                                    variant: "outlined",
                                    label: t("delegate_nameLabel"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant="caption"
                                className={classes.sectionTitle}
                              >
                                {t("car")}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              spacing={1}
                              className={classes.carMain}
                            >
                              <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12} sm={4}>
                                  <Input
                                    className={classes.selectField}
                                    type="select"
                                    inputSelect={{
                                      required: true,
                                      id: "car_type",
                                      name: "car_type",
                                      value: car_type,
                                      variant: "outlined",
                                      onChange: handleInputChange,
                                      label: t("car_typeLabel"),
                                      labelClass: classes.label,
                                      options: carTypesList,
                                      optionLabel: "name",
                                      optionValue: "id",
                                      error: car_typeError,
                                      autoFocus: getAutoFocus().car_type,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Input
                                    type="text"
                                    className={classes.field}
                                    inputText={{
                                      required: true,
                                      id: "plate_number",
                                      name: "plate_number",
                                      value: plate_number,
                                      onChange: handleInputChange,
                                      variant: "outlined",
                                      label: t("plate_numberLabel"),
                                      showHelper: !!plate_numberError,
                                      error: plate_numberError,
                                      autoFocus: getAutoFocus().plate_number,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Input
                                    type="text"
                                    className={classes.field}
                                    inputText={{
                                      id: "make",
                                      name: "make",
                                      value: make,
                                      onChange: handleInputChange,
                                      maxSize: 70,
                                      variant: "outlined",
                                      label: t("makeLabel"),
                                      showHelper: false,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <Input
                                    type="text"
                                    className={classes.field}
                                    inputText={{
                                      id: "model",
                                      name: "model",
                                      value: model,
                                      onChange: handleInputChange,
                                      maxSize: 70,
                                      variant: "outlined",
                                      label: t("modelLabel"),
                                      showHelper: false,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Input
                                    type="numeric"
                                    className={classes.field}
                                    inputNumeric={{
                                      id: "car_km",
                                      name: "car_km",
                                      value: car_km,
                                      onChange: handleInputChange,
                                      variant: "outlined",
                                      label: t("car_kmLabel"),
                                      showHelper: false,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <Input
                                    type="text"
                                    className={classes.field}
                                    inputText={{
                                      id: "vin_number",
                                      name: "vin_number",
                                      value: vin_number,
                                      onChange: handleInputChange,
                                      variant: "outlined",
                                      label: t("vin_numberLabel"),
                                      showHelper: false,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="caption"
                                  className={clsx(
                                    classes.sectionTitle,
                                    classes.noMarginBottom
                                  )}
                                >
                                  {t("wheels")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Input
                                  className={classes.selectField}
                                  type="select"
                                  inputSelect={{
                                    required: true,
                                    id: "wheel_type",
                                    name: "wheel_type",
                                    value: wheel_type,
                                    variant: "outlined",
                                    onChange: handleInputChange,
                                    label: t("wheel_typeLabel"),
                                    labelClass: classes.label,
                                    options: wheelTypesList,
                                    optionLabel: "name",
                                    optionValue: "id",
                                    error: wheel_typeError,
                                    autoFocus: getAutoFocus().wheel_type,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Input
                                  type="numeric"
                                  className={classes.field}
                                  inputNumeric={{
                                    id: "tyre_width",
                                    name: "tyre_width",
                                    value: tyre_width,
                                    onChange: handleInputChange,
                                    maxSize: 5,
                                    variant: "outlined",
                                    label: t("tyre_widthLabel"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Input
                                  type="numeric"
                                  className={classes.field}
                                  inputNumeric={{
                                    id: "tyre_height",
                                    name: "tyre_height",
                                    value: tyre_height,
                                    onChange: handleInputChange,
                                    maxSize: 5,
                                    variant: "outlined",
                                    label: t("tyre_heightLabel"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Input
                                  type="numeric"
                                  className={classes.field}
                                  inputNumeric={{
                                    id: "tyre_rim",
                                    name: "tyre_rim",
                                    value: tyre_rim,
                                    onChange: handleInputChange,
                                    maxSize: 5,
                                    variant: "outlined",
                                    label: t("tyre_rimLabel"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Input
                                  type="text"
                                  className={classes.field}
                                  inputText={{
                                    id: "tyre_pressure",
                                    name: "tyre_pressure",
                                    value: tyre_pressure,
                                    onChange: handleInputChange,
                                    variant: "outlined",
                                    label: t("tyre_pressureLabel"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Input
                                  type="text"
                                  className={classes.field}
                                  inputText={{
                                    id: "bolt_torque",
                                    name: "bolt_torque",
                                    value: bolt_torque,
                                    onChange: handleInputChange,
                                    variant: "outlined",
                                    label: t("bolt_torqueLabel"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                lg={2}
                                container
                                justify="center"
                              >
                                <Input
                                  type="checkbox"
                                  className={classes.checkbox}
                                  inputCheckbox={{
                                    id: "new_tyre",
                                    name: "new_tyre",
                                    value: new_tyre,
                                    onChange: handleInputChange,
                                    variant: "outlined",
                                    classes: {
                                      root: classes.checkboxChecked,
                                    },
                                    label: t("new_tyreLabel"),
                                  }}
                                />
                              </Grid>
                              <Zoom in={new_tyre} mountOnEnter unmountOnExit>
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                  <Input
                                    type="numeric"
                                    className={classes.field}
                                    inputNumeric={{
                                      id: "tyre_waste",
                                      name: "tyre_waste",
                                      value: tyre_waste,
                                      onChange: handleInputChange,
                                      variant: "outlined",
                                      showHelper: false,
                                      label: t("tyre_wasteLabel"),
                                    }}
                                  />
                                </Grid>
                              </Zoom>
                              <Grid item xs={12}>
                                <Input
                                  type="text"
                                  className={classes.field}
                                  inputText={{
                                    id: "car_description",
                                    name: "car_description",
                                    value: car_description,
                                    onChange: handleInputChange,
                                    variant: "outlined",
                                    label: t("car_description"),
                                    showHelper: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant="caption"
                                className={clsx(
                                  classes.sectionTitle,
                                  classes.noMarginBottom,
                                  classes.withMarginTop
                                )}
                              >
                                {t("generalAndWorkers")}
                              </Typography>
                            </Grid>
                            <div className={classes.workersContainer}>
                              <Input
                                type="autocomplete"
                                className={classes.field}
                                inputAutocomplete={{
                                  id: "workers",
                                  required: true,
                                  multiple: true,
                                  limitTags: 4,
                                  freeSolo: false,
                                  fullObjectValue: true,
                                  name: "workers",
                                  value: workers,
                                  onChange: handleWorkerChange,
                                  styles: { root: classes.workersList },
                                  variant: "outlined",
                                  label: t("workers"),
                                  placeholder: t("workersSearchLabel"),
                                  options: workersList,
                                  showHelper: !!workersError,
                                  getOptionSelected: (option, value) => {
                                    return option.id === value.id;
                                  },
                                  error: workersError,
                                  autoFocus: getAutoFocus().workers,
                                }}
                              />
                            </div>
                            <Grid item xs={12} md={12}>
                              <Input
                                type="textarea"
                                className={classes.textarea}
                                inputTextarea={{
                                  id: "description",
                                  name: "description",
                                  value: description,
                                  onChange: handleInputChange,
                                  showCount: true,
                                  rows: 1,
                                  rowsMax: 2,
                                  maxChars: 200,
                                  size: "medium",
                                  variant: "outlined",
                                  label: t("descriptionLabel"),
                                  ariaLabel: t("descriptionLabel"),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <AddClientModal
                        open={addClientOpen}
                        closeModal={closeAddClientModal}
                        organizations={user.organizations}
                        parent="create-work-order"
                        setNewClient={setNewClient}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

TyreServiceCreateStep1.propTypes = propTypes;
TyreServiceCreateStep1.defaultProps = defaultProps;

export default TyreServiceCreateStep1;
export {
  propTypes as TyreServiceCreateStep1PropTypes,
  defaultProps as TyreServiceCreateStep1DefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  stepCard: {
    boxShadow: "none"
  },
  content: {
    padding: 4
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: "transparent"
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    height: 30,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    }
  },
  finalStep: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none"
    }
  },
  beforeIcon: {
    marginRight: "0.25rem"
  },
  nextIcon: {
    marginLeft: "0.25rem"
  }
}));

export { useStyles };

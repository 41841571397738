const quickTextModel = {
  label: "Quick Text",
  field: "quick_text",
  type: "like",
  order: 1,
  options: [],
  selected: null,
};

const organizationClientIdModel = {
  label: "Organization Client",
  field: "organization_client_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

const clientNameModel = {
  label: "Client Name",
  field: "client_name",
  type: "like",
  order: 2,
  options: [],
  selected: null,
};

const carNumberModel = {
  label: "Car Number",
  field: "car_number",
  type: "like",
  order: 2,
  options: [],
  selected: null,
};

const tyreWidthModel = {
  label: "Tyre Width",
  field: "tyre_width",
  type: "equal",
  order: 2,
  options: [],
  selected: null,
};

const tyreHeightModel = {
  label: "Tyre Height",
  field: "tyre_height",
  type: "equal",
  order: 2,
  options: [],
  selected: null,
};

const tyreRimModel = {
  label: "Tyre Rim",
  field: "tyre_rim",
  type: "equal",
  order: 3,
  options: [],
  selected: null,
};

const tyreNameModel = {
  label: "Tyre Name",
  field: "tyre_name",
  type: "like",
  order: 4,
  options: [],
  selected: null,
};

const defaults = {
  models: {
    quickTextModel,
    tyreWidthModel,
    tyreHeightModel,
    tyreRimModel,
    tyreNameModel,
    organizationIdModel,
    organizationClientIdModel,
    carNumberModel,
  },
  searchParams: {
    models: [
      quickTextModel,
      tyreWidthModel,
      tyreHeightModel,
      tyreRimModel,
      tyreNameModel,
      organizationIdModel,
      organizationClientIdModel,
      carNumberModel,
    ],
    order_by: "created_at",
    order_dir: "desc",
    page_size: Number(localStorage.getItem("tyreHotelViewPageSize")) || 15,
  },
  orderBy: ["tyre_name"],
  pageSizeOptions: [15, 30, 50, 100],
};

export { defaults, clientNameModel };

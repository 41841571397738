/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modules: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  modulesContainer: {
    minHeight: "74vh",
    marginBottom: "5rem",
    justifyContent: "center"
  },
  titleContainer: {
    margin: "5rem 0 1rem",
    [theme.breakpoints.down('sm')]: {
      margin: "6rem 0 1rem",
    }
  },
  title: {
    fontSize: "2rem",
    fontFamily: theme.font.display,
    color: theme.palette.sidebar.text
  },
  modulePage: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  deletedScreen: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: theme.palette.common.black,
    opacity: 0.8,
    zIndex: 10000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  deletedText: {
    color: theme.palette.common.white,
    background: "#333333",
    padding: "1rem",
    borderRadius: 3,
    fontSize: "2rem"
  },
  logout: {
    ...theme.mixins.submitBtn,
    fontSize: "1rem",
    fontFamily: theme.font.family,
    color: theme.palette.common.white,
    padding: "0.5rem 1.5rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    background: "#333333",
    marginTop: "1rem"
  },
  logoutIcon: {
    marginRight: "0.25rem"
  }
}));

export { useStyles };

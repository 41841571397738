import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import MaskedInput from "react-text-mask";

/**
 * Defines the prop types
 */
const propTypes = {
    inputRef: PropTypes.func.isRequired
};

/**
 * Defines the default props
 */
const defaultProps = {
    inputRef: () => {}
};

/**
 * Displays the component
 */
const TextMask = props => {
    const { inputRef, ...otherProps } = props;

    /**
     * Gets the ref
     */
    const getRef = ref => inputRef(ref ? ref.inputElement : null);

    /**
     * Sets up the mask
     */
    const mask = Array(10).fill(/[0-9a-zA-Z\s]/);

    /**
     * @see https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
     */
    return (
        <MaskedInput
            {...otherProps}
            ref={getRef}
            mask={mask}
            guide={false}
            keepCharPositions={false}
            placeholderChar={"\u2000"}
        />
    );
};

TextMask.propTypes = propTypes;
TextMask.defaultProps = defaultProps;

export default TextMask;
export { propTypes as TextMaskPropTypes, defaultProps as TextMaskDefaultProps };

import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";

/**
 * Hooks
 */
// import { useLocalStorage } from "./index";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultState: PropTypes.bool,
};

// /**
//  * Defines the default props
//  */
// const defaultProps = {
//   defaultState: false,
// };

/**
 * Provides the state of the modal
 * By default it will be open
 */
const useSidebarHook = (props) => {

  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen((prevState) => !prevState);
  };

  const openSidebar = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
  };

  return {
    open,
    setOpen,
    toggleSidebar,
    openSidebar,
    closeSidebar,
  };
};

/**
 * Sets the prop types
 */
useSidebarHook.propTypes = propTypes;

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const sidebarContext = createContext();

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const SidebarProvider = (props) => {
  const { children } = props;

  const data = useSidebarHook();

  return (
    <sidebarContext.Provider value={{ ...data }}>
      {children}
    </sidebarContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 * - To be used inside components
 */
const useSidebar = () => {
  return useContext(sidebarContext);
};

export { useSidebar, SidebarProvider };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem 0",
  },
  checkbox: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
  },
  wrapper: {
    margin: "1rem 0",
  },
  tableSection: {
    marginTop: "2rem",
    color: "#4e4e4e",
    padding: "0 0.25rem",
    fontSize: "0.95rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
  },
  mainContainer: {
    margin: "1rem 0",
    padding: "1rem",
    background: theme.palette.common.white,
    border: "1px solid #eaeaea",
  },
  printContainer: {
    padding: "1rem",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    justifyContent: "flex-end",
  },
  label: {
    fontSize: "0.95rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    padding: "0 0.25rem",
  },
  value: {
    fontSize: "0.95rem",
    color: theme.palette.sidebar.text,
    fontFamily: theme.font.family,
  },
  title: {
    fontSize: "1.5rem",
    color: theme.palette.sidebar.text,
    fontFamily: theme.font.family,
    marginBottom: "1rem",
  },
  titleMargins: {
    marginBottom: "2rem",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
    marginBottom: "0rem",
  },
  printButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.accent,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
  loader: {
    color: theme.palette.common.white,
    width: "1.5rem",
    height: "1.5rem",
  },
  icon: {
    width: "1em",
    height: "1em",
  },
  notFoundText: {
    fontSize: "1.2rem",
    fontFamily: theme.font.display,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    borderTop: `1px solid #cacaca`,
    borderBottom: `1px solid #cacaca`,
    padding: "0.5rem",
    color: theme.palette.error.main,
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    label: {
        fontSize: "0.85rem",
        color: theme.palette.secondary.main
    }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: theme.mixins.submoduleContainer,
  box: {
    marginTop: "0.5rem",
    padding: "0.5rem",
    paddingBottom: "1rem",
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderRight: `1px solid ${theme.palette.border.main}`,
      padding: "6px 12px",
      textAlign: "center",
      color: theme.palette.sidebar.text,
      border: `1px solid ${theme.palette.border.main}`,
      background: theme.palette.common.white,
      fontSize: "0.8rem",
    },
    "& .MuiTableCell-head": {
      fontWeight: 800,
      color: theme.palette.sidebar.text,
      background: theme.palette.sidebar.main,
      fontSize: "0.8rem",
    },
  },
  orgTableCell: {
    minWidth: 215,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    height: "100%",

    alignItems: "center",
  },
  action: {
    "& $icon": {
      width: "1em",
      height: "1em",
    },
    "& $loader": {
      color: theme.palette.common.white,
      width: "1.45rem",
      height: "1.45rem",
    },
  },
  icon: {
    width: "1em",
    height: "1em",
  },
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  statusBtn: {
    borderRadius: 0,
    color: "#fff",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  statusCancel: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      boxShadow: "none",
    },
  },
  statusActivate: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  statusContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  done: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    background: "rgba(97, 171, 79, 0.05)",
    minWidth: 50,
    height: 25,
  },
  cancel: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    background: "rgba(232, 91, 91, 0.05)",
    minWidth: 50,
    height: 25,
  },
  viewButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  printButton: {
    margin: "0.15rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  loader: {
    color: theme.palette.common.white,
    width: "1.5rem",
    height: "1.5rem",
  },
  selectField: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    backgroundColor: "transparent",
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      height: "0.95rem",
      padding: "9.5px 12px",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.9rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 14,
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined": {
      transform: "translate(10px, 12px) scale(1)",
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  tableInfo: {
    fontSize: "1.2rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    marginTop: 0,
    marginBottom: "0.35rem",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: "1.2rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    marginTop: "0.25rem",
    marginBottom: "0.35rem",
    justifyContent: "center",
    padding: "0.25rem 0.75rem",
    background: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.accent}`,
    borderRadius: 3,
    minWidth: 330,
  },
  dateTitle: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    marginTop: "0.25rem",
    marginBottom: "0.35rem",
    justifyContent: "center",
    padding: "0.25rem 0.75rem",
    background: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.accent}`,
    borderRadius: 3,
    minWidth: 330,
  }
}));

export { useStyles };

import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch } from "react-router-dom";

/**
 * Component Imports
 */
import CarServiceSidebar, {
  CarServiceSidebarDefaultProps,
  CarServiceSidebarPropTypes
} from "../CarServiceSidebar";
import CarServiceMain, {
  CarServiceMainDefaultProps,
  CarServiceMainPropTypes
} from "../CarServiceMain";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes
} from "../PageNotFound";

/**
 * Defines the prop types
 */

const propTypes = {
  sidebar: PropTypes.shape(CarServiceSidebarPropTypes),
  main: PropTypes.shape(CarServiceMainPropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  path: PropTypes.string,
  paths: PropTypes.shape({
    main: PropTypes.string
  })
};

/**
 * Defines the default props
 */
const defaultProps = {
  sidebar: CarServiceSidebarDefaultProps,
  main: CarServiceMainDefaultProps,
  notFound: PageNotFoundDefaultProps,
  path: "/modules/car-service",
  paths: {
    main: CarServiceMainDefaultProps.path
  }
};

/**
 * Displays the component
 */
const CarServicePage = props => {
  const { notFound, sidebar, main, path: basePath, paths } = props;

  /**
   * Gets the paths from the components
   */
  const { main: mainPath } = paths;

  /**
   * Defines the path building function
   * Attaches the base path of the module to each individual path
   */
  const buildPath = path => `${basePath}${path}`;

  /**
   * Defines and builds the paths
   */
  const buildedPaths = {
    main: buildPath(mainPath)
  };

  return (
    <Fragment>
      <CarServiceSidebar {...sidebar} paths={buildedPaths} />
      <Switch>
        <Route exact path={buildedPaths.main}>
          <CarServiceMain {...main} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
    </Fragment>
  );
};

CarServicePage.propTypes = propTypes;
CarServicePage.defaultProps = defaultProps;

export default CarServicePage;
export {
  propTypes as CarServicePagePropTypes,
  defaultProps as CarServicePageDefaultProps
};

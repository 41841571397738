/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  flash: {
    padding: "0.25rem",
    minWidth: 0,
    borderRadius: 0,
    height: 30,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.error.dark,
      boxShadow: "none",
    },
    margin: "0 0 0.25em",
    marginRight: "0.25rem",
  },
  flashOn: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #929292",
    color: theme.palette.common.white,
    fontSize: "0.75rem",
    maxWidth: "none",
  },
  popper: {
    marginTop: -5,
  },

  spacing: {
    marginBottom: "0.5rem",
  },
  field: {
    ...theme.mixins.searchField,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "7.3px",
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-marginDense": {
      transform: "translate(14px, 15px) scale(1) !important",
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiInputLabel-shrink": {
      transform: "translate(15px, -5px) scale(1) !important",
    },
  },
  selectField: theme.mixins.searchSelect,
  visibleContainer: {
    transition: "all .3s ease-in-out",
    [theme.breakpoints.down("lg")]: {
      minHeight: 90,
      height: 0,
    },
    [theme.breakpoints.only("md")]: {
      minHeight: 65,
      height: 0,
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 65,
      height: "auto",
      marginBottom: "0.5rem",
    },
  },
  hideContainer: {
    transition: "all .3s ease-in-out",
    minHeight: 40,
    [theme.breakpoints.down("lg")]: {
      minHeight: 0,
      height: 0,
    },
  },
  orderIDContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  orderIDField: {
    marginBottom: "1rem",
    marginTop: "0.65rem",
  },
  btnContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.35rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
    },
  },
  undoBtn: {
    padding: "0.25rem",
    minWidth: 0,
    borderRadius: 0,
    height: 30,
    background: theme.palette.button.light,
    color: theme.palette.secondary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
    margin: "0 0 0.25em",
    marginRight: "0.25rem",
  },
  submitBtn: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 0,
    fontWeight: 400,
    height: 30,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

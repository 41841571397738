/**
 *  Material UI Imports
 */
import { makeStyles, fade } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "70vh",
  },
  scheduler: {
    "& .MuiTableCell-root.MuiTableCell-body": {
      background: theme.palette.common.white,
      textAlign: "center",
      textTransform: "capitalize",
      "&:hover": {
        cursor: "pointer",
        background: "#f8f8f8",
      },
    },

    boxShadow: "none",
    border: "1px solid #e8e8e8",
    borderRadius: 0,
  },
  container: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  text: theme.typography.h6,
  formControlLabel: {
    ...theme.typography.caption,
    fontSize: "1rem",
  },
  label: theme.mixins.searchFieldLabel,
  selectField: {
    ...theme.mixins.searchSelect,
    marginBottom: "0.5rem",
    marginTop: "1rem",
  },
  todayCell: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
  },
  today: {
    backgroundColor: fade(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
  },
  toolbar: {
    background: theme.palette.common.white,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  editSmall: {
    padding: "0.15rem",
    margin: "0 0.25rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
    },
  },
  deleteButton: {
    padding: "0.15rem",
    marginLeft: "0.3rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
  },
  layout: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: 3,
      border: "1px solid #c7c7c7",
      width: 600,
    },
  },
  header: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #c7c7c7",
  },
  circlePrimary: {
    height: 30,
    width: 30,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    margin: "0 0.5rem",
  },
  circleSecondary: {
    background: theme.palette.warning.main,
  },
  circleSuccess: {
    background: theme.palette.success.main,
  },
  circleError: {
    background: theme.palette.error.main,
  },
  clientName: {
    display: "flex",
    alignItems: "center",
  },
  clientNameText: {
    fontSize: "1rem",
    fontFamily: theme.font.family,
    color: theme.palette.sidebar.text,
    fontWeight: 800,
  },
  createdBy: {
    fontSize: "0.85rem",
    "& span": {
      fontSize: "inherit",
      fontWeight: 800,
    },
  },
  footer: {
    padding: "0.5rem",
    minHeight: 40,
    borderTop: "1px solid #c7c7c7",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  content: {
    minHeight: 100,
  },
  sectionItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    "& svg": {
      margin: "0 0.35rem",
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      fontSize: "0.85rem",
      textTransform: "capitalize",
    },
  },
  sectionItemEnd: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    marginRight: "1rem",
    "& svg": {
      margin: "0 0.35rem",
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      fontSize: "0.85rem",
    },
  },
  time: {
    borderBottom: "1px solid #c7c7c7",
    paddingBottom: "0.5rem",
  },
  spacing: {
    maxWidth: "fit-content",
    margin: "0.5rem",
    maxHeight: 350,
    overflow: "auto",
  },
  tableFont: {
    fontSize: "0.85rem",
    padding: "0.25rem 0.5rem",
  },
  serviceItem: {
    listStyleType: "square",
    marginLeft: "1.5rem",
  },
  appointmentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  main: {
    display: "flex",
    flexDirection: "column",
  },
  mainClientName: {
    fontSize: "1rem",
    fontWeight: 800,
    color: theme.palette.common.white,
    paddingLeft: "0.5rem",
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "1rem",
      marginRight: "0.25rem",
    },
    "& span": {
      display: "flex",
      marginTop: 2,
    },
  },
  timeRange: {
    fontSize: "0.75rem",
    color: theme.palette.common.white,
    padding: "0.35rem",
    height: 15,
    paddingTop: 0,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "1rem",
      marginRight: "0.25rem",
    },
    "& span": {
      display: "flex",
      marginTop: 2,
    },
  },
  appointment: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  appointmentProgress: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  appointmentDone: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  appointmentSkipped: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paper: {
    boxShadow: "none",
    marginTop: "0.5rem",
    border: `1px solid #b9b9b9`,
  },
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  viewButton: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  changeStatus: {
    padding: "0.25rem 1rem",
    margin: "0.5rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.accent,
      boxShadow: "none",
    },
    display: "flex",
    flex: 1,
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem",
    },
  },
  popoverTitle: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "0.8rem",
    marginTop: "0.25rem",
  },
  doneStatus: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  progressStatus: {
    background: theme.palette.warning.main,
    "&:hover": {
      background: theme.palette.warning.dark,
      boxShadow: "none",
    },
  },
  holdStatus: {
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "70vh",
  },
  scheduler: {
    marginTop: "1rem",
    "& .MuiTableCell-root.MuiTableCell-body": {
      textAlign: "center",
      textTransform: "capitalize",
    },
    boxShadow: "none",
    border: "1px solid #e8e8e8",
    borderRadius: 0,
  },
  timeTableCell: {
    background: theme.palette.common.white,
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      background: "#f8f8f8",
    },
    "&:focus": {
      background: "#f8f8f8",
    },
  },
  container: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  text: theme.typography.h6,
  formControlLabel: {
    ...theme.typography.caption,
    fontSize: "1rem",
  },
  label: theme.mixins.searchFieldLabel,
  selectField: {
    ...theme.mixins.searchSelect,
    marginBottom: "0.5rem",
    marginTop: "1rem",
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "4px 0",
    "& .MuiAutocomplete-tagSizeSmall": {
      background: theme.palette.primary.accent,
      color: theme.palette.common.white,
      margin: 4,
      marginTop: 6,
      borderRadius: 3,
      "& svg": {
        color: "inherit",
      },
      "& svg:hover": {
        opacity: 0.7,
      },
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input": {
      padding: "6px 4px",
    },
  },
  weekDate: {
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& p": {
        color: "#fff",
      },
      "& div": {
        color: "#fff",
      },
    },
  },
  weekend: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    background: theme.palette.common.white,
  },
  emptyCell: {
    minWidth: 181,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  editSmall: {
    padding: "0.15rem",
    margin: "0 0.25rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
    },
  },
  deleteButton: {
    padding: "0.15rem",
    marginLeft: "0.3rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
  },
  layout: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: 3,
      border: "1px solid #c7c7c7",
      width: 600,
    },
  },
  header: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #c7c7c7",
  },
  circlePrimary: {
    height: 30,
    width: 30,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    margin: "0 0.5rem",
  },
  circleSecondary: {
    background: theme.palette.warning.main,
  },
  circleSuccess: {
    background: theme.palette.success.main,
  },
  circleError: {
    background: theme.palette.error.main,
  },
  clientName: {
    display: "flex",
    alignItems: "center",
    minHeight: 40,
  },
  clientNameText: {
    fontSize: "1rem",
    fontFamily: theme.font.family,
    color: theme.palette.sidebar.text,
    fontWeight: 800,
  },
  createdBy: {
    fontSize: "0.85rem",
    "& span": {
      fontSize: "inherit",
      fontWeight: 800,
    },
  },
  footer: {
    padding: "0.5rem",
    minHeight: 40,
    borderTop: "1px solid #c7c7c7",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  content: {
    minHeight: 100,
  },
  sectionItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    "& svg": {
      margin: "0 0.35rem",
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      fontSize: "0.85rem",
      textTransform: "capitalize",
    },
  },
  sectionItemEnd: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    marginRight: "1rem",
    "& svg": {
      margin: "0 0.35rem",
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      fontSize: "0.85rem",
    },
  },
  time: {
    borderBottom: "1px solid #c7c7c7",
    paddingBottom: "0.5rem",
  },
  spacing: {
    maxWidth: "fit-content",
    margin: "0.5rem",
    maxHeight: 350,
    overflow: "auto",
  },
  tableFont: {
    fontSize: "0.85rem",
    padding: "0.25rem 0.5rem",
  },
  serviceItem: {
    listStyleType: "square",
    marginLeft: "1.5rem",
  },
  appointmentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  extrainfo: {
    position: "absolute",
    right: 10,
    top: 5,
    fontSize: "0.8rem",
    fontWeight: 800,
    color: theme.palette.common.white,
    textTransform: "uppercase",
  },
  mainClientName: {
    fontSize: "1rem",
    fontWeight: 800,
    color: theme.palette.common.white,
    paddingLeft: "0.5rem",
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "1rem",
      marginRight: "0.25rem",
    },
    "& span": {
      display: "flex",
      marginTop: 2,
    },
  },
  timeRange: {
    fontSize: "0.75rem",
    color: theme.palette.common.white,
    padding: "0.35rem",
    height: 15,
    paddingTop: 0,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "1rem",
      marginRight: "0.25rem",
    },
    "& span": {
      display: "flex",
      marginTop: 2,
    },
  },
  appointment: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  appointmentProgress: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  appointmentDone: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  appointmentSkipped: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paper: {
    boxShadow: "none",
    marginTop: "0.5rem",
    border: `1px solid #b9b9b9`,
  },
  button: {
    padding: "0.15rem",
    margin: "0.15rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.15rem",
    },
  },
  viewButton: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  changeStatus: {
    padding: "0.25rem 1rem",
    margin: "0.5rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.accent,
      boxShadow: "none",
    },
    display: "flex",
    flex: 1,
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem",
    },
  },
  popoverTitle: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "0.8rem",
    marginTop: "0.25rem",
  },
  doneStatus: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  progressStatus: {
    background: theme.palette.warning.main,
    "&:hover": {
      background: theme.palette.warning.dark,
      boxShadow: "none",
    },
  },
  holdStatus: {
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
  overlay: {
    "& .MuiTableCell-root": {
      textAlign: "center",
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  checkbox: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
  },
  shadedAppointment: {
    backgroundColor: "#838b94",
    "&:hover": {
      backgroundColor: "#7a93af",
    },
  },
  shadedCell: {
    backgroundColor: "#f5f5f5",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  line: {
    height: "5px",
    borderTop: `5px ${theme.palette.secondary.main} solid`,
    width: "100%",
    transform: "translate(0, -3px)",
  },
  circle: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.secondary.main,
  },
  nowIndicator: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    top: ({ top }) => top,
  },
  noHeightLimit: {
    maxHeight: "none",
  },
  workersList: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import { useHistory } from "react-router-dom";

/**
 * Assets Import
 */
import accountSettingsImg from "./account_settings.png";
import carServiceImg from "./car_service.png";
import carWashImg from "./car_wash.png";
import tyreServiceImg from "./tyre_service.png";
import workstationSettingsImg from "./workstation_settings.png";
import reportsImg from "./reports.png";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Zoom from "@material-ui/core/Zoom";

/**
 * Styles Imports
 */
import { useStyles } from "./ModuleCard.styles";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  path: PropTypes.string,
  delay: PropTypes.number,
  name: PropTypes.string,
  active: PropTypes.bool,
  onModuleChange: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  path: "",
  delay: 0,
  name: "",
  active: false,
  onModuleChange: () => {},
};

/**
 * Displays the component
 */
const ModuleCard = (props) => {
  const { path, delay, name, icon, active: activeProp, onModuleChange } = props;

  /**
   * Initializes the active state
   */
  const [active, setActive] = useState(true);

  /**
   * Initializes the suspended acc state
   */
  const [isSuspended, setIsSuspended] = useState(false);

  /**
   * Initializes the deleted acc state
   */
  const [isDeleted, setIsDeleted] = useState(false);

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Handles getting the image src
   */
  const getImageSrc = () => {
    switch (icon) {
      case "car-wash":
        return carWashImg;
      case "car-service":
        return carServiceImg;
      case "tyre-service":
        return tyreServiceImg;
      case "account-settings":
        return accountSettingsImg;
      case "workstation-settings":
        return workstationSettingsImg;
      case "reports":
        return reportsImg;
      default:
        return null;
    }
  };

  /**
   * Handles clicking on the module card
   */
  const handleClick = () => {
    if (onModuleChange) onModuleChange();
    if (active) {
      if (path === "workstation-settings") {
        history.push(`/modules/${path}/work-orders`);
      } else {
        history.push(`/modules/${path}`);
      }
    }
  };

  const getActiveStatus = () => {
    const inactiveOrSuspended = !active || isSuspended;
    const isAdmin = user.type === "admin";
    const accSettings = path === "account-settings";

    return (
      (isSuspended && !isAdmin) ||
      (!active && !isAdmin) ||
      (inactiveOrSuspended && isAdmin && !accSettings) ||
      isDeleted
    );
  };

  /**
   * Defines the module container classes
   */
  const moduleContainerClasses = clsx(classes.moduleContainer, {
    [classes.disabledModuleContainer]: getActiveStatus(),
  });

  /**
   * Defines the module card classes
   */
  const moduleCardClasses = clsx(classes.moduleCard, {
    [classes.disabledModule]: getActiveStatus(),
  });

  /**
   * Defines the module card backdrop classes
   */
  const backdropClasses = clsx(classes.backdrop, {
    [classes.disabledBackdrop]: getActiveStatus(),
  });

  /**
   * Defines the module card img container classes
   */
  const imgContainerClasses = clsx(classes.imgContainer, {
    [classes.disabledContainer]: getActiveStatus(),
  });

  /**
   * Handles rendering the disabled or inactive status banner
   */
  const renderDisabledBanner = () => {
    const renderCondition = getActiveStatus();
    return (
      renderCondition && (
        <div className={classes.inactiveBox}>
          <Typography variant="h3" className={classes.inactiveTitle}>
            {t("disabled")}
          </Typography>
        </div>
      )
    );
  };

  /**
   * Updates the active state
   */
  useEffect(() => {
    if (activeProp) setActive(true);
    if (!activeProp) setActive(false);
  }, [activeProp]);

  useEffect(() => {
    if (user.account) {
      const isSuspended = user.account.status === "suspended";
      const isDeleted = user.account.status === "deleted";

      setIsSuspended(isSuspended);
      setIsDeleted(isDeleted);
    }
  }, [user]);

  return (
    <Zoom in={true} timeout={300} style={{ transitionDelay: delay }}>
      <Grid
        container
        justify="center"
        alignItems="center"
        onClick={handleClick}
      >
        <Grid item xs={12} sm={10} md={10} className={moduleContainerClasses}>
          <Card
            className={moduleCardClasses}
            title={!active ? t("disabled") : t(name)}
          >
            <CardContent>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h3" className={classes.moduleTitle}>
                    {t(name)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.container}>
                    <div className={backdropClasses}>
                      <div className={imgContainerClasses}>
                        <img
                          alt={name}
                          className={classes.img}
                          src={getImageSrc()}
                        />
                      </div>
                      {renderDisabledBanner()}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Zoom>
  );
};

ModuleCard.propTypes = propTypes;
ModuleCard.defaultProps = defaultProps;

export default ModuleCard;
export {
  propTypes as ModuleCardPropTypes,
  defaultProps as ModuleCardDefaultProps,
};

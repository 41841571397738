/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  signatureContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  signatureCont: {
    padding: "0.25rem",
    display: "flex",
    alignItems: "flex-end",
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    fontFamily: theme.font.family,
    fontSize: "0.75rem",
    fontWeight: 800,
  },
  signatureLine: {
    width: 150,
    maxWidth: 150,
    height: 1,
    background: "#717171",
    left: 90,
    bottom: 10,
  },
}));

export { useStyles };

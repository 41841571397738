import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import TableHeader, {
  TableHeaderDefaultProps,
  TableHeaderPropTypes,
} from "../TableHeader";
import TableRows, {
  TableRowsDefaultProps,
  TableRowsPropTypes,
} from "../TableRows";
import TablePagination, {
  TablePaginationDefaultProps,
  TablePaginationPropTypes,
} from "../TablePagination";
import TableResultsNotFound, {
  TableResultsNotFoundDefaultProps,
  TableResultsNotFoundPropTypes,
} from "../TableResultsNotFound";
import TableActions, {
  TableActionsPropTypes,
  TableActionsDefaultProps,
} from "../TableActions";

/**
 *  Material UI Imports
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Styles Imports
 */
import { useStyles } from "./DynamicTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  tableActions: PropTypes.shape(TableActionsPropTypes),
  tablePagination: PropTypes.shape(TablePaginationPropTypes),
  tableNotFound: PropTypes.shape(TableResultsNotFoundPropTypes),
  tableHeader: PropTypes.shape(TableHeaderPropTypes),
  tableRows: PropTypes.shape(TableRowsPropTypes),
  collection: PropTypes.array,
  options: PropTypes.object,
  preventScrollTopOnSearch: PropTypes.bool,
  specialStyles: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  tableActions: TableActionsDefaultProps,
  tablePagination: TablePaginationDefaultProps,
  tableNotFound: TableResultsNotFoundDefaultProps,
  tableHeader: TableHeaderDefaultProps,
  tableRows: TableRowsDefaultProps,
  collection: [],
  options: {
    pagination: {},
  },
  preventScrollTopOnSearch: false,
  specialStyles: [],
};

/**
 * Displays the component
 */
const DynamicTable = (props) => {
  const {
    tableActions,
    tablePagination,
    tableNotFound,
    tableHeader,
    tableRows,
    collection,
    options,
    notFound,
    preventScrollTopOnSearch,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the table options
   */
  const {
    withCount,
    withPrint,
    withPagination,
    withSort,
    helpers,
    pagination,
    resetFilters,
    defaultOrderBy,
    defaultOrder,
    fields,
    specialStyles,
    containerClassName,
  } = options;

  /**
   * Initializes the order (asc/desc)
   */
  const [order, setOrder] = useState(defaultOrder ? defaultOrder : "asc");

  /**
   * Initializes the order by field
   */
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  /**
   * Initializes the data state
   */
  const [data, setData] = useState(null);

  /**
   * Initializes the headers state
   */
  const [headers, setHeaders] = useState([]);

  /**
   * Initializes the search state
   */
  const [search, setSearch] = useState("");

  /**
   * Initializes the inputs ready flag
   * Used to debounce all inputs, updating the inputs object, used in the onSubmit function
   */
  const [searchReady, setSearchReady] = useState(false);

  /**
   * Initializes the search failed flag
   */
  const [searchFailed, setSearchFailed] = useState(false);

  /**
   * Initializes the search loading state
   */
  const [searchLoading, setSearchLoading] = useState(false);

  /**
   * Gets the col span
   */
  const getColSpan = () => headers.length;

  /**
   * Handles deciding if to display the not found component
   */
  const displayNotFound = () => notFound || searchFailed;

  /**
   * Gets the data count
   */
  const getDataCount = () => data && data.length;

  /**
   * Defines the comparator
   */
  const descendingComparator = (a, b, orderBy) => {
    const orderByData = fields.find(field => field.name === orderBy);

    if (orderByData.type === "date") {
      if (new Date(b[orderBy]).getTime() < new Date(a[orderBy]).getTime()) return -1;
      if (new Date(b[orderBy]).getTime() > new Date(a[orderBy]).getTime()) return 1;
      return 0;
    }


    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  /**
   * Handles getting the comparator
   */
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /**
   * Handles sorting the array
   */
  const stableSort = (array, comparator) => {
    /**
     * Formats the array
     */
    const formattedArray = array.map((el, index) => [el, index]);

    /**
     * Sorts the array
     */
    formattedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;
      return a[1] - b[1];
    });

    /**
     * Returns the newly sorted array
     */
    return formattedArray.map((el) => el[0]);
  };

  /**
   * Handles updating the sorting related states
   */
  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * Handles creating the sort handler
   */
  const createSortHandler = (property) => (event) => {
    if (property === t("count")) return;
    handleSort(event, property);
  };

  /**
   * Handles updating the search state
   */
  const handleSearch = (event) => setSearch(event.target.value);

  /**
   * Handles resetting the search state
   */
  const handleResetSearch = () => {
    setSearch("");
    setSearchFailed(false);
  };

  /**
   * Handles resetting the search filters
   */
  const handleResetFilters = () => {
    if (helpers.resetFilters) {
      setSearch("");
      setSearchFailed(false);
      resetFilters();
    }
  };

  /**
   * Handles getting the value
   * @param {Boolean} hasFilter Field has displayFilter
   * @param {Boolean} isCounter Field is a row counter
   * @param {Object} field  Field that contains the table options
   * @param {Object} row  Row that contains the collection data
   * @param {Array} options Extra options
   * @param {Number} idx  Index, used for the row counter
   */
  const getValue = (hasFilter, isCounter, field, row, options, idx) => {
    if (isCounter) return field.displayCount(idx);
    if (hasFilter) return field.displayFilter(field, row, options);

    const key = field.key ? field.name : "";

    if (row[key] instanceof Date) {
      return formatDate(new Date(row[key]), "dd-MM-yyyy HH:mm")
    }

    return row[key];
  };

  /**
   * Handles getting the table data
   * Also makes sure to sort it
   */
  const getTableData = () => {
    if (headers && headers.length > 0 && data && data.length > 0) {
      return stableSort(data, getComparator(order, orderBy));
    }
  };

  /**
   * Handles formatting the value, to normalize it for search comparisons
   * @param {String|Number|Boolean} value
   */
  const formatValue = (value, dict) => {
    const excessWhitespace = /^\s+|\s+$|\s+(?=\s)/g;
    if (!value) return "";
    return value
      .toString()
      .toLowerCase()
      .replace(excessWhitespace, "")
      .replace(/[^\w ]/g, function (char) {
        return dict[char] || char;
      });
  };

  /**
   * Handles getting the first match during search
   * @param {Object} col
   */
  const getFirstMatch = (col) => {
    let matchFound = false;
    headers.forEach((header) => {
      const { displayFilter, searchField, options } = header;
      if (matchFound) return;

      if (searchField) {
        const hasFilter = !!displayFilter;
        const headerOptions = options ? options : [];
        let dict = { â: "a", ă: "a", ș: "s", î: "i", ț: "t" };
        const value = getValue(hasFilter, false, header, col, headerOptions, 0);

        matchFound = formatValue(value, dict).includes(search.toLowerCase());
      }
    });

    return matchFound;
  };

  /**
   * Handles getting the headers as row data if printing
   */
  const getHeadersForPrint = () => {
    const tableHeaders = fields.map((field) => {
      return { key: field.name, value: field.label };
    });

    const header = {};
    tableHeaders.forEach((item) => {
      header[item.key] = item.value;
    });
    header["is_header"] = true;
    return header;
  };

  /**
   * Resets the failed search flag if there is data being shown
   */
  useEffect(() => {
    if (data && data.length > 0) setSearchFailed(false);
  }, [data]);

  /**
   * Updates the state with the collection coming from props
   */
  useEffect(() => {
    if (headers.length > 0) {
      const printHeader = getHeadersForPrint();
      withPrint
        ? setData([{ ...printHeader }, ...collection])
        : setData(collection);
    }
    // eslint-disable-next-line
  }, [collection, headers]);

  /**
   * Updates the state with the collection coming from props
   */
  useEffect(() => {
    if (fields && fields.length > 0) {
      const tableHeaders = fields.map((field) => {
        return { ...field, value: field.value ? field.value : field.name };
      });

      if (withCount) {
        tableHeaders.unshift({
          value: "count",
          name: "count",
          label: t("count"),
          align: "center",
          key: true,
          skipKey: true,
          searchField: false,
          displayCount: (index) =>
            withPrint ? (index === 0 ? "#" : index) : index + 1,
        });
      }

      setHeaders(tableHeaders);
    }
    // eslint-disable-next-line
  }, [fields]);

  /**
   * Handles updating the loading state or resetting the data
   */
  useEffect(() => {
    if (search.length >= 2) setSearchLoading(true);
    if (search.length < 2) setData(collection);
    // eslint-disable-next-line
  }, [search]);

  /**
   * Resets the search when the pagination data changes
   */
  useEffect(() => {
    if (withPagination) setSearch("");
  }, [withPagination, pagination]);

  /**
   * Handles triggering a search
   */
  useEffect(() => {
    if (searchLoading) {
      setTimeout(() => {
        setSearchReady(true);
      }, [400]);
    }
  }, [searchLoading]);

  /**
   * Handles the dynamic search
   */
  useEffect(() => {
    if (searchReady) {
      const newData = collection.filter((col) => getFirstMatch(col));

      if (newData.length < 1 && !searchFailed) setSearchFailed(true);
      if (newData.length > 0 && searchFailed) setSearchFailed(false);

      setData(newData);
      setSearchReady(false);
    }
    // eslint-disable-next-line
  }, [searchReady]);

  /**
   * Handles resetting the search loading state
   */
  useEffect(() => {
    if (!searchReady && searchLoading) {
      setTimeout(() => {
        setSearchLoading(false);
      }, [400]);
    }
    // eslint-disable-next-line
  }, [searchReady]);

  return (
    <TableContainer className={containerClassName}>
      <TableActions
        {...tableActions}
        options={options}
        pagination={pagination}
        dataCount={getDataCount()}
        loading={searchLoading}
        search={search}
        searchReady={searchReady}
        handleSearch={handleSearch}
        handleResetSearch={handleResetSearch}
        preventScrollTopOnSearch={preventScrollTopOnSearch}
      />
      <Table stickyHeader className={clsx(classes.table, "page-break")}>
        <TableHead
          className={clsx({
            [classes.tableHeaderHidden]: withPrint,
          })}
        >
          <TableRow>
            <TableHeader
              {...tableHeader}
              withSort={withSort}
              headers={headers}
              onClick={createSortHandler}
              orderBy={orderBy}
              order={order}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {collection.length > 0 && (
            <TableRows
              {...tableRows}
              specialStyles={specialStyles}
              collection={collection}
              options={options}
              headers={headers}
              getValue={getValue}
              withPrint={withPrint}
              tableData={getTableData()}
            />
          )}

          <TableResultsNotFound
            {...tableNotFound}
            collection={collection}
            helpers={helpers}
            options={options}
            resetSearch={handleResetSearch}
            resetFilters={handleResetFilters}
            search={search}
            render={displayNotFound()}
            colSpan={getColSpan()}
          />
        </TableBody>
      </Table>
      <TablePagination
        {...tablePagination}
        options={options}
        dataCount={getDataCount()}
        colSpan={getColSpan()}
        pagination={pagination}
      />
    </TableContainer>
  );
};

DynamicTable.propTypes = propTypes;
DynamicTable.defaultProps = defaultProps;

export default DynamicTable;
export {
  propTypes as DynamicTablePropTypes,
  defaultProps as DynamicTableDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Styles Imports
 */
import { useStyles } from "./LoyaltyCardsSearchForm.styles";

/**
 * Defaults Imports
 */
import { defaults } from "../WorkstationSettingsLoyaltyCards/WorkstationSettingsLoyaltyCards.defaults";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  defaultValues: PropTypes.shape({
    quick_text: PropTypes.string,
    is_active: PropTypes.bool,
    card_number: PropTypes.string,
    discount_type: PropTypes.string,
    discount_value: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
  }),
  orderByList: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  defaultValues: {
    quick_text: "",
    is_active: false,
    card_number: "",
    discount_type: "",
    discount_value: "",
    orderBy: "created_at",
    orderDir: "desc",
  },
  orderByList: defaults.orderBy,
  pageSizeOptions: defaults.pageSizeOptions,
  path: "/loyalty-cards",
};

/**
 * Displays the component
 */
const LoyaltyCardsSearchForm = (props) => {
  const {
    form,
    input,
    button,
    defaultValues,
    tabChanged,
    setTabChanged,
    defaultSearch,
    setInputs: updateInputs,
    setModels: updateModels,
    setTotal,
    pageSize,
    pageCount,
    setPageSize,
    setPageCount,
    setBackdropLoading,
    selectedOrg,
    setUpdated,
    loadingText,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Gets the global user
   */
  const { setUser } = useUser();

  /**
   * Handles searching for loyalty cards
   */
  const searchLoyaltyCards = async (data) => {
    try {
      const loyaltyCards = await apiClient.post("/loyalty-cards/search", data);
      if (loyaltyCards) {
        const { data } = loyaltyCards;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, loyalty_cards: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        updateModels(models);

        /**
         * Handles updating the inputs
         */
        const handlePrevState = (prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        };

        setInputs((prevState) => handlePrevState(prevState));
        updateInputs((prevState) => handlePrevState(prevState));

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    /**
     * Initializes the search models
     */
    let searchModels = [];

    /**
     * Gets the inputs
     */
    const {
      quick_text,
      is_active,
      card_number,
      discount_type,
      discount_value,
    } = inputs;

    /**
     * Gets the default models
     */
    const {
      quickTextModel,
      isActiveModel,
      organizationIdModel,
      cardNumberModel,
      discountTypeModel,
      discountValueModel,
    } = defaults.models;

    /**
     * Checks if the model exists
     */
    const modelExists = (mod) =>
      searchModels.find((model) => model.field === mod);

    /**
     * Removes the model
     */
    const removeModel = (mod) => {
      searchModels = searchModels.filter((model) => model.field === mod);
    };

    /**
     * Adds the model
     */
    const addModel = (input, model) => {
      const modelItem = { ...model };
      modelItem["selected"] = input;
      searchModels.push(modelItem);
    };

    if (quick_text) {
      if (modelExists("quick_text")) {
        removeModel("quick_text");
        addModel(quick_text, quickTextModel);
      } else {
        addModel(quick_text, quickTextModel);
      }
    }

    if (is_active) {
      if (modelExists("is_active")) {
        removeModel("is_active");
        addModel(is_active, isActiveModel);
      } else {
        addModel(is_active, isActiveModel);
      }
    }

    if (card_number) {
      if (modelExists("card_number")) {
        removeModel("card_number");
        addModel(card_number, cardNumberModel);
      } else {
        addModel(card_number, cardNumberModel);
      }
    }

    if (discount_type) {
      if (modelExists("discount_type")) {
        removeModel("discount_type");
        addModel(discount_type, discountTypeModel);
      } else {
        addModel(discount_type, discountTypeModel);
      }
    }

    if (discount_value) {
      if (modelExists("discount_value")) {
        removeModel("discount_value");
        addModel(discount_value, discountValueModel);
      } else {
        addModel(discount_value, discountValueModel);
      }
    }

    if (selectedOrg !== "" && selectedOrg !== "any") {
      if (modelExists("organization_client_id")) {
        removeModel("organization_client_id");
        addModel(selectedOrg, organizationIdModel);
      } else {
        addModel(selectedOrg, organizationIdModel);
      }
    }
    return searchModels.length < 1 ? defaultSearch.models : searchModels;
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: pageCount,
    };

    searchLoyaltyCards(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    quick_text,
    is_active,
    card_number,
    discount_type,
    discount_value,
    orderBy,
    orderDir,
  } = inputs;

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    resetInputs();
    setBackdropLoading(true);
    setModels(defaults.models);
    updateModels(defaults.models);
    setPageCount(1);
    handleDefaultSearch();
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const orgIdModel = { ...defaultSearch.models[1] };
    orgIdModel["selected"] = selectedOrg === "any" ? "" : selectedOrg;

    const data = {
      ...defaultSearch,
      models: [{ ...orgIdModel }],
      page_size: pageSize || defaultSearch.page_size,
      page_count: 1,
    };

    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchLoyaltyCards(getDefaultSearch());
  };

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    updateModels(defaultSearch.models);

    // eslint-disable-next-line
  }, []);

  /**
   * Handles the tab change
   */
  useEffect(() => {
    if (tabChanged) {
      updateModels(models);
      setTabChanged(false);
      setUpdated(true);
    }
    // eslint-disable-next-line
  }, [tabChanged]);

  return (
    <Form {...form} id="search-user" onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={9}>
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={12} sm={6} md={3} lg={6} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "quick_text",
                  value: quick_text,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("quick_textLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "card_number",
                  value: card_number,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("card_numberLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "discount_type",
                  value: discount_type,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("discount_typeLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("percent"), value: "PERCENT" },
                    { label: t("value"), value: "VALUE" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={2}>
              <Input
                {...input}
                type="text"
                className={classes.field}
                inputText={{
                  name: "discount_value",
                  value: discount_value,
                  onChange: handleInputChange,
                  maxSize: 70,
                  variant: "outlined",
                  labelClass: classes.label,
                  InputLabelProps: {
                    className: classes.label,
                  },
                  showHelper: false,
                  label: t("discount_valueLabel"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderBy",
                  value: orderBy,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderByLabel"),
                  variant: "outlined",
                  options: [{ label: t("created_at"), value: "created_at" }],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={2}>
              <Input
                className={classes.selectField}
                type="select"
                inputSelect={{
                  name: "orderDir",
                  value: orderDir,
                  onChange: handleInputChange,
                  labelClass: classes.label,
                  label: t("orderDirLabel"),
                  variant: "outlined",
                  options: [
                    { label: t("asc"), value: "asc" },
                    { label: t("desc"), value: "desc" },
                  ],
                  optionLabel: "label",
                  optionValue: "value",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Grid container spacing={1} wrap={"nowrap"}>
            <Grid item xs={12} container justify="center" direction="column">
              <div className={classes.btnContainer}>
                <Button
                  {...button}
                  type="button"
                  variant="filled"
                  title={t("undo")}
                  onClick={resetFilters}
                  className={classes.undoBtn}
                >
                  <UndoIcon />
                </Button>
                <Button
                  {...button}
                  type="submit"
                  variant="filled"
                  className={classes.submitBtn}
                >
                  <LoadingText
                    {...loadingText}
                    size={22}
                    loading={loading}
                    text={t("submitSearch")}
                    icon={<SearchIcon />}
                  />
                </Button>
              </div>
              <div className={classes.orderIDContainer}>
                <div className={classes.orderIDField}>
                  <Input
                    {...input}
                    type="checkbox"
                    className={classes.field}
                    inputCheckbox={{
                      name: "is_active",
                      value: is_active,
                      onChange: handleInputChange,
                      variant: "outlined",
                      labelClass: classes.label,
                      InputLabelProps: {
                        className: classes.label,
                      },
                      showHelper: false,
                      label: t("is_activeLabel"),
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

LoyaltyCardsSearchForm.propTypes = propTypes;
LoyaltyCardsSearchForm.defaultProps = defaultProps;

export default LoyaltyCardsSearchForm;
export {
  propTypes as LoyaltyCardsSearchFormPropTypes,
  defaultProps as LoyaltyCardsSearchFormDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: `2px solid #dedede`,
    padding: "0.25rem",
    backgroundColor: theme.palette.common.white,
    position: "relative",
    width: "100%",
  },
  text: {
    fontSize: "0.75rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.light,
  },
  value: {
    fontSize: "0.75rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.light,
    display: "flex",
    alignItems: "center",
    padding: "0 0.25rem",
  },
  contact: {
    display: "flex",
    flex: 1,
  },
  contactItem: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "#c1c1c1",
    fontSize: "1.2rem",
    marginRight: "0.25rem",
  },
  headerSection: {
    display: "flex",
    alignItems: "center",
    padding: "0 0.5rem",
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginTop: "1rem",
    background: "#f3f3f3",
    border: "1px solid #e2e2e2",
    "& .MuiTableCell-head": {
      fontWeight: 800,
      color: "#6f6f6f",
      textAlign: "center",
      background: "#ececec",
      borderRight: "1px solid #d8d8d8",
    },
    "& .MuiTableCell-body": {
      fontWeight: 500,
      color: "#505050",
      textAlign: "center",
      background: "#fff",
      borderRight: "1px solid #d8d8d8",
    },
    "& .MuiTableCell-root": {
      padding: 8,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "#6f6f6f",
    },
  },
  container: {
    padding: "1rem",
  },
}));

export { useStyles };

import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import WorkOrderView, {
  WorkOrderViewDefaultProps,
  WorkOrderViewPropTypes,
} from "../WorkOrderView";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";

/**
 * Styles Imports
 */
import { useStyles } from "./ViewWorkOrderModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  setViewData: PropTypes.func,
  workOrderID: PropTypes.number,
  data: PropTypes.object,
  workOrderView: PropTypes.shape(WorkOrderViewPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  open: false,
  closeModal: () => {},
  setViewData: () => {},
  workOrderID: 0,
  data: {},
  workOrderView: WorkOrderViewDefaultProps,
};

/**
 * Displays the component
 */
const ViewWorkOrderModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    loadingBackdrop,
    open,
    closeModal,
    workOrderID,
    workOrderView,
    setViewData,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the data received flag
   */
  const [orderID, setOrderID] = useState(0);

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    closeModal();
    setOrderID(0);
    setViewData(0);
  };

  return (
    <Modal
      {...modal}
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      keepMounted={false}
      scroll="paper"
    >
      <ModalTitle
        {...modalTitle}
        title={orderID ? `${t("titleWorkOrder")}${orderID}` : ""}
        onClick={handleCloseModal}
      />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <WorkOrderView
          {...workOrderView}
          data={props.data}
          setOrderID={setOrderID}
          workOrderID={workOrderID}
        />
        <LoadingBackdrop
          {...loadingBackdrop}
          open={!orderID}
          classes={{ backdrop: classes.backdrop }}
        />
      </ModalContent>
      <ModalActions {...modalActions} onClick={handleCloseModal} />
    </Modal>
  );
};

ViewWorkOrderModal.propTypes = propTypes;
ViewWorkOrderModal.defaultProps = defaultProps;

export default ViewWorkOrderModal;
export {
  propTypes as ViewWorkOrderModalPropTypes,
  defaultProps as ViewWorkOrderModalDefaultProps,
};

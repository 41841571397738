/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  attendanceIcon: {
    color: theme.palette.common.white,
    marginRight: "0.35rem",
  },
  attendance: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 0,
    fontWeight: 400,
    height: 30,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    boxShadow: "none",
    margin: 0,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
    "&.MuiButton-contained.Mui-disabled": {
      color: "#8a8a8a",
      fontWeight: 800,
      border: "2px solid #a5a5a5",
      cursor: "not-allowed",
      pointerEvents: "auto",
      "& svg": {
        color: "#8a8a8a",
      },
    },
  },
  startAttendance: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
    },
  },
  endAttendance: {
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  selectField: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    backgroundColor: "transparent",
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      height: "0.95rem",
      padding: "9px 12px",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.9rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 14,
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  field: {
    ...theme.mixins.searchField,
    marginTop: "0.5rem",
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 19,
    },
  },
  table: {
    minWidth: 650,
    marginTop: "1rem",
    background: "#f3f3f3",
    border: "1px solid #e2e2e2",
    "& .MuiTableCell-head": {
      fontWeight: 800,
      color: "#6f6f6f",
      textAlign: "center",
      background: "#ececec",
      borderRight: "1px solid #d8d8d8",
    },
    "& .MuiTableCell-body": {
      fontWeight: 500,
      color: "#505050",
      textAlign: "center",
      background: "#fff",
      borderRight: "1px solid #d8d8d8",
    },
    "& .MuiTableCell-root": {
      padding: 8,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "#6f6f6f",
    },
  },
  dateSelection: {
    display: "flex",
    alignItems: "center",
  },
  dateField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,

    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "inherit",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
      paddingRight: 0,
    },
  },
  resetButton: {
    padding: "0.15rem",
    minWidth: 30,
    minHeight: 30,
    borderRadius: 0,
    fontWeight: 400,
    boxShadow: "none",
    marginRight: "0.25rem",
    background: theme.palette.button.light,
    color: theme.palette.secondary.contrastText,
    marginBottom: 0,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  navButton: {
    padding: "0.15rem",
    minWidth: 30,
    minHeight: 30,
    borderRadius: 0,
    fontWeight: 400,
    boxShadow: "none",
    margin: "0 0.5rem",
    background: theme.palette.success.main,
    color: theme.palette.secondary.contrastText,
    marginBottom: 0,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  navPrev: {
    paddingRight: "1rem",
  },
  navNext: {
    paddingLeft: "1rem",
  },
  submitButton: {
    padding: "0.15rem 1rem",
    minWidth: 96,
    minHeight: 30,
    borderRadius: 0,
    fontWeight: 400,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    marginRight: "0.25rem",
    marginBottom: 0,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  bold: {
    fontWeight: 800,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  title: {
    fontSize: "0.9rem",
    fontWeight: 800,
    fontFamily: theme.font.family,
  },
  titleDates: {
    fontSize: "0.8rem",
    color: "#4c4c4c",
    border: `1px solid #b1adad`,
    padding: "0 0.5rem",
    background: "#ececec",
    marginTop: "0.5rem",
    borderRadius: 3,
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  viewButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  printButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.accent,
    color: "#fff",
    padding: "0.15rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    minWidth: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
  loader: {
    color: theme.palette.common.white,
    width: "1.5rem",
    height: "1.5rem",
  },
  icon: {
    width: "1em",
    height: "1em",
  },
  notFound: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "0.5rem",
    margin: "0.5rem",
  },
  notFoundText: {
    fontSize: "1.2rem",
    fontFamily: theme.font.display,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    borderTop: `1px solid #cacaca`,
    borderBottom: `1px solid #cacaca`,
    padding: "0.5rem",
    color: theme.palette.error.main,
  },
  weekDate: {
    display: "flex",
    alignItems: "center",
  },
}));

export { useStyles };

import { useState } from "react";
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  user: PropTypes.object,
  secretSlug: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  user: {},
  secretSlug: "3Oa8busgJMPAqtRCJH_8cD6CGJ7mR9"
};

/**
 * Displays the component
 */
const useUserApi = props => {
  const { user: defaultUser, secretSlug } = defaultProps;

  /**
   * Sets the default user global data.
   */
  const [user, setUser] = useState(defaultUser);

  /**
   * Initializes a ready state for when all the user data has been collected from the API
   */
  const [ready, setReady] = useState(false);

  /**
   * Resets the user objects to its default value
   */
  const resetUserData = () => {
    setUser({
      user: {}
    });
    setReady(false);
  };

  return {
    user,
    secretSlug,
    setUser,
    ready,
    setReady,
    resetUserData
  };
};

useUserApi.propTypes = propTypes;

export { useUserApi };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  disclaimer: {
    marginTop: "0.25rem",
    padding: "0.25rem",
    backgroundColor: theme.palette.common.white,
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: "0.7rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.main,
    padding: "0.15rem 0.25rem",
    borderBottom: `1px solid #cacaca`,
    fontWeight: 800,
  },
  textBold: {
    fontSize: "0.7rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.main,
    padding: "0.15rem 0.25rem",
    fontWeight: 800,
  },
  text: {
    fontSize: "0.7rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.main,
    padding: "0.15rem 0.25rem",
  },
  subtitle: {
    fontSize: "1.1rem",
    fontWeight: 800,
    margin: "10px 0",
    marginTop: 25
  },
  sectionContainer: {
    display :"flex",
    flexDirection : "column",
  },
  textContainer: {
    display :"flex",
    flexDirection : "column",
    "& ul": {
       listStyleType: "disc",
       marginLeft: "3.5rem"
    },
    "& ul ul": {
      listStyleType: "circle",
      paddingInlineStart: 40
    }
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 *  Material UI Imports
 */
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./LoadingText.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.shape({
    spinner: PropTypes.string,
    text: PropTypes.string
  })
};

/**
 * Defines the default props
 */
const defaultProps = {
  loading: false,
  text: "",
  icon: null,
  size: 20,
  classes: {
    spinner: "",
    text: ""
  }
};

/**
 * Displays the component
 */
const LoadingText = props => {
  const { loading, text, icon, size, classes } = props;

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Handles rendering the icon
   */
  const handleIconRendering = () => (icon ? icon : null);

  /**
   * Gets the spinner classes
   */
  const getSpinnerClasses = () => {
    return clsx(styles.spinner, {
      [classes.spinner]: classes.spinner ? true : false
    });
  };

  /**
   * Gets the text classes
   */
  const getTextClasses = () => {
    return clsx(styles.text, {
      [classes.text]: classes.text ? true : false
    });
  };

  /**
   * Handles rendering the dynamic content of the button
   */
  const renderLoadingText = () => {
    return loading ? (
      <CircularProgress size={size} className={getSpinnerClasses()} />
    ) : (
      <Typography variant="caption" className={getTextClasses()}>
        {handleIconRendering()}
        {text}
      </Typography>
    );
  };

  return renderLoadingText();
};

LoadingText.propTypes = propTypes;
LoadingText.defaultProps = defaultProps;

export default LoadingText;
export {
  propTypes as LoadingTextPropTypes,
  defaultProps as LoadingTextDefaultProps
};

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { ro } from "date-fns/locale";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./AddContractModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./AddContractModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  dateFormat: PropTypes.string,
  dateFormatOptions: PropTypes.object,
  defaultValues: PropTypes.shape({
    contract_number: PropTypes.string,
    organization_id: PropTypes.string,
    organization_client_id: PropTypes.string,
    name: PropTypes.string,
    payment_type_id: PropTypes.string,
    from: PropTypes.any,
    to: PropTypes.any,
    description: PropTypes.string
  }),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  dateFormat: "dd-MM-yyyy",
  dateFormatOptions: { locale: ro },
  defaultValues: {
    contract_number: "",
    organization_id: "",
    organization_client_id: "",
    name: "",
    payment_type_id: "",
    from: new Date(),
    to: new Date(),
    description: ""
  },
  organizations: [],
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const AddContractModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    clients,
    organizations,
    dateFormat,
    dateFormatOptions,
    setUpdated
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Initializes the payment types
   */
  const [paymentTypes, setPaymentTypes] = useState([]);

  /**
   * Initializes the clients list
   */
  const [clientsList, setClientsList] = useState(clients);

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    setInputs,
    resetInputs,
    handleSubmit,
    handleDateChange,
    handleInputChange,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    organization_id,
    payment_type_id,
    name,
    organization_client_id,
    contract_number,
    from,
    to,
    description
  } = inputs;

  const {
    name: nameError,
    payment_type_id: payment_type_idError,
    organization_id: organization_idError,
    organization_client_id: organization_client_idError,
    contract_number: contract_numberError,
    from: fromError,
    to: toError
  } = errors;

  /**
   * Handles creating a new contract
   */
  const createContract = async (data) => {
    try {
      const createdContract = await apiClient.post("/contracts", data);

      if (createdContract) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage")
        });

        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
        origin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    closeModal();
  };

  /**
   * Handles changing the product
   */
  const handleClientChange = (event, newValue) => {
    setInputs((prevState) => {
      return { ...prevState, organization_client_id: newValue };
    });
  };

  useEffect(() => {
    if (open) {
      const prefix = Math.floor(10000 + Math.random() * 90000);
      const dateText = formatDate(new Date(), dateFormat, dateFormatOptions);
      const paymentTypes = user.settings.account.payment_types;
      setPaymentTypes(paymentTypes);
      setInputs((prevState) => {
        return {
          ...prevState,
          contract_number: `${prefix}/${dateText}`,
          organization_id: user.organization.id
        };
      });
    }
    // eslint-disable-next-line
  }, [open]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const chosenClient = clientsList.find(
        (client) => client.name === organization_client_id
      );

      const data = {
        organization_id,
        organization_client_id: chosenClient.id,
        payment_type_id,
        name,
        contract_number,
        from,
        to
      };

      if (description) data["description"] = description;

      createContract(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    setClientsList(clients);
  }, [clients]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAddContract")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="add-contract-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              required: true,
                              id: "contract_number",
                              name: "contract_number",
                              value: contract_number,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("contract_numberLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: contract_numberError,
                              autoFocus: getAutoFocus().contract_number
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              required: true,
                              id: "organization_id",
                              name: "organization_id",
                              value: organization_id,
                              onChange: handleInputChange,
                              label: t("organizationLabel"),
                              labelClass: classes.label,
                              variant: "outlined",
                              options: organizations,
                              optionLabel: "label",
                              optionValue: "value",
                              error: organization_idError,
                              autoFocus: getAutoFocus().organization_id
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="autocomplete"
                            className={classes.field}
                            inputAutocomplete={{
                              id: "organization_client_id",
                              required: true,
                              name: "organization_client_id",
                              inputValue: organization_client_id,
                              onChange: handleClientChange,
                              variant: "outlined",
                              freeSolo: false,
                              autoComplete: false,
                              label: t("clientsLabel"),
                              error: organization_client_idError,
                              options: clientsList,
                              getOptionSelected: (option, value) => {
                                return option.id === value.id;
                              },
                              autoFocus: getAutoFocus().organization_client_id
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              required: true,
                              id: "name",
                              name: "name",
                              value: name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("nameLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label
                              },
                              error: nameError,
                              autoFocus: getAutoFocus().name
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              required: true,
                              name: "payment_type_id",
                              value: payment_type_id,
                              onChange: handleInputChange,
                              labelClass: classes.label,
                              variant: "outlined",
                              label: t("payment_type_idLabel"),
                              options: paymentTypes,
                              optionLabel: "name",
                              optionValue: "id",
                              error: payment_type_idError,
                              autoFocus: getAutoFocus().payment_type_id
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.dateField}
                            type="date"
                            inputDate={{
                              required: true,
                              name: "from",
                              value: from,
                              onChange: handleDateChange,
                              margin: "normal",
                              label: t("fromLabel"),
                              variant: "standard",
                              error: fromError,
                              autoFocus: getAutoFocus().from
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.dateField}
                            type="date"
                            inputDate={{
                              required: true,
                              name: "to",
                              value: to,
                              onChange: handleDateChange,
                              margin: "normal",
                              label: t("toLabel"),
                              variant: "standard",
                              error: toError,
                              autoFocus: getAutoFocus().to
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 6,
                              rowsMax: 14,
                              maxChars: 200,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel")
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitAdd")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

AddContractModal.propTypes = propTypes;
AddContractModal.defaultProps = defaultProps;

export default AddContractModal;
export {
  propTypes as AddContractModalPropTypes,
  defaultProps as AddContractModalDefaultProps
};

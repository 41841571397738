import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./AddUserModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./AddUserModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    organization_id: PropTypes.string,
    type: PropTypes.string
  }),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  open: PropTypes.bool,
  closeModal: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    username: "",
    password: "",
    organization_id: "",
    type: ""
  },
  organizations: [],
  types: [],
  open: false,
  closeModal: () => {}
};

/**
 * Displays the component
 */
const AddUserModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    organizations,
    types
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Gets the global user and the setter
   */
  const { user, setUser } = useUser();

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true
  };

  const {
    inputs,
    errors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { username, password, organization_id, type } = inputs;

  const {
    username: usernameError,
    password: passwordError,
    organization_id: organization_idError,
    type: typeError
  } = errors;

  /**
   * Handles creating a new user
   */
  const createUser = async (data) => {
    try {
      const createdUser = await apiClient.post("/users", data);
      if (createdUser) {
        const { data } = createdUser;
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage")
        });

        /**
         * Builds the new users state
         */
        const newUsers = [data, ...getPreviousUsers()];

        setUser((prevState) => {
          return { ...prevState, users: newUsers };
        });

        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
        origin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    closeModal();
  };

  /**
   * Handles getting the previous users, removes the last one
   */
  const getPreviousUsers = () => {
    const usersArray = user.users;
    usersArray.splice(-1, 1);

    return usersArray;
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        username,
        password,
        organization_id,
        type
      };

      createUser(data);
    }
    // eslint-disable-next-line
  }, [ready]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleAddUser")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="add-user-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "username",
                              required: true,
                              name: "username",
                              value: username,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("usernameLabel"),
                              error: usernameError,
                              autoFocus: getAutoFocus().username
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="password"
                            className={classes.field}
                            inputPassword={{
                              id: "password",
                              required: true,
                              name: "password",
                              value: password,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("passwordLabel"),
                              error: passwordError,
                              autoFocus: getAutoFocus().password
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "organization_id",
                              name: "organization_id",
                              value: organization_id,
                              onChange: handleInputChange,
                              label: t("organization_idLabel"),
                              options: organizations,
                              optionLabel: "label",
                              optionValue: "value",
                              error: organization_idError,
                              autoFocus: getAutoFocus().organization_id
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              id: "type",
                              name: "type",
                              value: type,
                              onChange: handleInputChange,
                              label: t("typeLabel"),
                              options: types,
                              optionLabel: "label",
                              optionValue: "value",
                              error: typeError,
                              autoFocus: getAutoFocus().type
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitAdd")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

AddUserModal.propTypes = propTypes;
AddUserModal.defaultProps = defaultProps;

export default AddUserModal;
export {
  propTypes as AddUserModalPropTypes,
  defaultProps as AddUserModalDefaultProps
};

import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import TableCellHeader, {
  TableCellHeaderDefaultProps,
  TableCellHeaderPropTypes,
} from "../TableCellHeader";

/**
 *  Material UI Imports
 */
import TableSortLabel from "@material-ui/core/TableSortLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

/**
 * Defines the prop types
 */
const propTypes = {
  cellHeader: PropTypes.shape(TableCellHeaderPropTypes),
  withSort: PropTypes.bool,
  headers: PropTypes.array,
  onClick: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  cellHeader: TableCellHeaderDefaultProps,
  withSort: false,
  headers: [],
  onClick: () => {},
  orderBy: "",
  order: "",
};

/**
 * Displays the component
 */
const TableHeader = (props) => {
  const { cellHeader, withSort, headers, onClick, orderBy, order } = props;

  /**
   * Handles rendering the table headers
   */
  const getTableHeader = () => {
    return headers
      ? headers.map((header, idx) => {
          const { label, name, sort, hidden } = header;

          if (hidden) return null;

          const active = orderBy === name;
          const sortDirection = active ? order : false;
          const isSortCell = sort && withSort;
          const direction = active ? order : "asc";
          const handleClick = onClick(name);

          /**
           * Defines the sorting cell
           */
          const sortingCell = (
            <TableSortLabel
              active={active}
              IconComponent={KeyboardArrowDownIcon}
              direction={direction}
              onClick={handleClick}
            >
              {label}
            </TableSortLabel>
          );

          /**
           * Defines the header props
           */
          const tableCellHeaderProps = {
            ...cellHeader,
            ...header,
            field: header,
            value: name,
            sortDirection: sortDirection,
            label: isSortCell ? sortingCell : label,
            key: `${idx}-${name}`,
          };

          return <TableCellHeader {...tableCellHeaderProps} />;
        })
      : null;
  };

  return getTableHeader();
};

TableHeader.propTypes = propTypes;
TableHeader.defaultProps = defaultProps;

export default TableHeader;
export {
  propTypes as TableHeaderPropTypes,
  defaultProps as TableHeaderDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import clsx from "clsx";
import { differenceInSeconds } from "date-fns";

/**
 * Imports Material UI components
 */
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

/**
 *  Imports components
 */
import Button from "../Button";

/**
 * Imports hooks
 */
import { useTranslation } from "react-i18next";
import { useUser, useTimer } from "../../hooks";

/**
 * Imports the component styles
 */
import { useStyles } from "./WorkOrderTimerCard.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  setDeleteData: PropTypes.func,
  handleEdit: PropTypes.func,
  setCardInEdit: PropTypes.func,
  draftData: PropTypes.object,
  cardInEdit: PropTypes.string,
  plateNumber: PropTypes.string,
  workOrder: PropTypes.object,
  localID: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  setDeleteData: () => {},
  handleEdit: () => {},
  setCardInEdit: () => {},
  draftData: {},
  cardInEdit: "",
  plateNumber: "",
  workOrder: {},
  localID: "",
};

/**
 * Displays the component
 */
const WorkOrderTimerCard = (props) => {
  const {
    setDeleteData,
    handleEdit,
    setCardInEdit,
    draftData,
    cardInEdit,
    plateNumber,
    workOrder,
    localID,
      // eslint-disable-next-line
    workOrderType
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the is editing flag
   */
  const [isEditing, setIsEditing] = useState(false);

  /**
   * Initializes the car types
   */
  const [carTypes, setCarTypes] = useState([]);

  /**
   * Initializes the work order types
   */
  const [workOrderTypes, setWorkOrderTypes] = useState([]);

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Gets the timer data from local storage
   */
  const timer = JSON.parse(localStorage.getItem(`${plateNumber}_newTimer`)) || null;

  /**
   * Handles getting the timestamp offset
   */
  const getTimestampOffset = () => {
    const offset = new Date();
    offset.setSeconds(
      offset.getSeconds() +
        differenceInSeconds(
          new Date(),
          new Date(timer ? timer.startTime : new Date())
        )
    );
    return offset;
  };

  /**
   * Initializes the timer
   */
  const { getCurrentTime, saveToLocalStorage, getDurationInSeconds } = useTimer(
    `${plateNumber}_newTimer`,
    {
      open: true,
      offsetTimestamp: getTimestampOffset(),
    }
  );

  /**
   * Handles deleting the timer
   */
  const deleteCard = () => setDeleteData({ plate_number: plateNumber });
 
  /**
   * Handles opening the edit modal
   */
  const openEditModal = () => setIsEditing(true);

  /**
   *  Handles getting the car type name based on it's id
   */
  const getCarTypeName = (car_type) => {
    const found = carTypes.find((carType) => carType.id === car_type);
    return found ? found.name : "-";
  };

    /**
   *  Handles getting the work order type name based on it's id
   */
        // eslint-disable-next-line
     const getWorkOrderType = (workOrderType) => {
      const found = workOrderTypes.find((woType) => woType.id === workOrderType);
      return found ? found.name : "-";
    };
  

  /**
   * Updates the car types array
   */
  useEffect(() => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      const woTypes = user.settings.account.work_order_types;

      setWorkOrderTypes(woTypes);
      setCarTypes(carTypes);
    }
  }, [user]);

  /**
   * Stops the timer while editing the card
   */
  useEffect(() => {
    if (isEditing) {
      handleEdit({
        ...props,
        duration: getDurationInSeconds(),
        id: plateNumber,
      });
    }
    // eslint-disable-next-line
  }, [isEditing]);

  /**
   * Restores the timer once editing is over
   * Also syncs up with the local storage updated timer
   */
  useEffect(() => {
    if (Object.keys(draftData).length < 1) {
      setIsEditing(false);
      setCardInEdit("");
    }
    // eslint-disable-next-line
  }, [draftData, cardInEdit, localID]);

  /**
   * Saves the data to localStorage when unmounting
   */
  useEffect(() => {
    return () => saveToLocalStorage();
  // eslint-disable-next-line
  }, []);

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.timerBox}>{getCurrentTime()}</Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.label}>
            {t("plate_number")}
          </Typography>
          <Box className={classes.value}>{plateNumber}</Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.label}>
            {t("car_type")}
          </Typography>
          <Box className={classes.value}>
            {getCarTypeName(workOrder.car_type)}
            {/* {getWorkOrderType(workOrderType)} */}
          </Box>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Typography variant="caption" className={classes.label}>
            {t("actions")}
          </Typography>
          <Box className={classes.actions}>
            <Box className={classes.crud}>
              <Button
                type="button"
                variant="filled"
                className={clsx(classes.timerBtn, classes.editBtn)}
                title={t("edit")}
                onClick={openEditModal}
              >
                <EditIcon />
              </Button>
              <Button
                type="button"
                variant="filled"
                className={clsx(classes.timerBtn, classes.deleteBtn)}
                title={t("delete")}
                onClick={deleteCard}
              >
                <DeleteIcon />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

WorkOrderTimerCard.propTypes = propTypes;
WorkOrderTimerCard.defaultProps = defaultProps;

export default WorkOrderTimerCard;
export {
  propTypes as WorkOrderTimerCardPropTypes,
  defaultProps as WorkOrderTimerCardDefaultProps,
};

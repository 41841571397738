import { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { useLocation } from "react-router-dom";

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

/**
 * Defines the default props
 */
const defaultProps = {
  children: "Content",
  location: {
    pathname: "/"
  }
};

/**
 * Displays the component
 */
const ScrollToTop = props => {
  const { children } = props;

  /**
   * Gets the current pathname
   */
  const location = useLocation();
  const { pathname } = location;

  /**
   * Resets the scroll position to top upon route change.
   */
  useEffect(() => {
    /**
     * Overwrites the scroll restoration imposed by some browsers
     */
    if (window.history.scrollRestoration === "auto")
      window.history.scrollRestoration = "manual";

    /**
     * Scrolls back to top
     */
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

ScrollToTop.propTypes = propTypes;
ScrollToTop.defaultProps = defaultProps;

export default ScrollToTop;
export {
  propTypes as ScrollToTopPropTypes,
  defaultProps as ScrollToTopDefaultProps
};

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditOrgContactModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditOrgContactModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_mobile: PropTypes.string,
    phone_fix: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
  }),
  contactData: PropTypes.object,
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    first_name: "",
    last_name: "",
    phone_mobile: "",
    phone_fix: "",
    email: "",
    gender: "",
  },
  contactData: {},
  genders: [
    { label: "Masculin", value: "male" },
    { label: "Feminin", value: "female" },
    { label: "Altul", value: "other" },
  ],
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */

const EditOrgContactModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    genders,
    contactData,
    errorMessages,
    loadingText,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user setter
   */
  const { setUser } = useUser();

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { first_name, last_name, phone_mobile, phone_fix, email, gender } =
    inputs;

  const {
    first_name: first_nameError,
    last_name: last_nameError,
    phone_mobile: phone_mobileError,
    phone_fix: phone_fixError,
    email: emailError,
    gender: genderError,
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setReady(false);
    setErrors({});
    closeModal();
  };

  /**
   * Handles updating the organization contact
   */
  const updateOrganizationContact = async (data, id) => {
    try {
      setLoading(false);

      getUserOrganizations();
    } catch (error) {
      setLoading(false);
      setReady(false);
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles getting the user organizations
   */
  const getUserOrganizations = async () => {
    try {
      const organizations = await apiClient.get("/organizations");
      if (organizations) {
        const { data } = organizations;

        setLoading(false);

        /**
         * Dispatch the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });

        setUser((prevState) => {
          return { ...prevState, organizations: data };
        });
        handleCloseModal();
      }
    } catch (error) {
      setReady(false);
      /**
       * Dispatch the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        first_name,
        last_name,
        phone_mobile,
      };

      if (phone_fix) data["phone_fix"] = phone_fix;
      if (email) data["email"] = email;
      if (gender) data["gender"] = gender;

      updateOrganizationContact(data, contactData.id);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the inputs with the contact data
   */
  useEffect(() => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        first_name: contactData.first_name ? contactData.first_name : "",
        last_name: contactData.last_name ? contactData.last_name : "",
        phone_fix: contactData.phone_fix ? contactData.phone_fix : "",
        phone_mobile: contactData.phone_mobile ? contactData.phone_mobile : "",
        gender: contactData.gender ? contactData.gender : "",
        email: contactData.email ? contactData.email : "",
      };
    });
    // eslint-disable-next-line
  }, [contactData]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={`${t("titleEditContact")} ${contactData.first_name} ${
            contactData.last_name
          }`}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-org-contact-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "first_name",
                              required: true,
                              name: "first_name",
                              value: first_name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("first_nameLabel"),
                              error: first_nameError,
                              autoFocus: getAutoFocus().first_name,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "last_name",
                              required: true,
                              name: "last_name",
                              value: last_name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("last_nameLabel"),
                              error: last_nameError,
                              autoFocus: getAutoFocus().last_name,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "email",
                              name: "email",
                              value: email,
                              onChange: handleInputChange,
                              placeholder: "",
                              variant: "standard",
                              label: t("emailLabel"),
                              error: emailError,
                              autoFocus: getAutoFocus().email,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="phone"
                            className={classes.field}
                            inputPhone={{
                              required: true,
                              id: "phone_mobile",
                              name: "phone_mobile",
                              value: phone_mobile,
                              onChange: handleInputChange,
                              placeholder: "",
                              variant: "standard",
                              label: t("phone_mobileLabel"),
                              error: phone_mobileError,
                              autoFocus: getAutoFocus().phone_mobile,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="phone"
                            className={classes.field}
                            inputPhone={{
                              id: "phone_fix",
                              name: "phone_fix",
                              value: phone_fix,
                              onChange: handleInputChange,
                              placeholder: "",
                              variant: "standard",
                              label: t("phone_fixLabel"),
                              error: phone_fixError,
                              autoFocus: getAutoFocus().phone_fix,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              name: "gender",
                              value: gender,
                              onChange: handleInputChange,
                              label: t("genderLabel"),
                              options: genders,
                              optionLabel: "label",
                              optionValue: "value",
                              error: genderError,
                              autoFocus: getAutoFocus().gender,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-org-contact-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditOrgContactModal.propTypes = propTypes;
EditOrgContactModal.defaultProps = defaultProps;

export default EditOrgContactModal;
export {
  propTypes as EditOrgContactModalPropTypes,
  defaultProps as EditOrgContactModalDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  expansionPanelRoot: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "& .MuiExpansionPanelSummary-content": {
      margin: 0
    }
  },
  panelSummaryRoot: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `2px solid #efefef`,
    marginBottom: 0,
    marginTop: 5,
    minHeight: 55,
    cursor: "unset",
    "&.Mui-expanded": {
      minHeight: 55
    }
  },
  panelSummaryContent: {
    "&.Mui-expanded": {
      margin: 0
    }
  },
  panelDetailsRoot: {
    padding: "0.5rem",
    width: "100%"
  },
  summary: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  summaryTitle: {
    fontSize: "1rem",
    color: theme.palette.secondary.light,
    fontFamily: theme.font.display,
    fontWeight: 800,
    display: "flex",
    flex: 3,
    flexDirection: "column"
  },
  summaryActions: {
    display: "flex",
    alignItems: "center"
  },
  description: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family
  },
  whiteTagFlex: {
    display: "flex",
    flex: 2,
    [theme.breakpoints.down("lg")]: {
      flex: 3
    },
    padding: "0.35rem",
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
      display: "flex",
      fontWeight: 800,
      color: theme.palette.secondary.main,
      alignItems: "center",
      justifyContent: "center",
      padding: "0.25rem 1rem",
      background: theme.palette.sidebar.main,
      border: `1px solid #dadada`,
      borderRadius: 5
    },
    "& svg": {
      color: theme.palette.primary.main,
      marginRight: "0.5rem"
    }
  },
  delete: {
    margin: "0 0.5rem",
    transition: "all .2s ease-in-out",
    border: `1px solid ${theme.palette.common.white}`,
    width: 35,
    height: 35,
    padding: "0.25rem",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    }
  },
  wrapper: {
    display: "flex",
    flex: 1
  },
  addressContainer: {
    display: "flex",
    alignItems: "center"
  },
  companyInfoBox: {
    margin: "0 0.5rem",
    display: "flex",
    flex: 1,
    alignItems: "center"
  },
  flexColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  addressName: {
    marginLeft: "0.5rem",
    marginBottom: 0,
    fontSize: "1rem",
    fontWeight: 800,
    fontFamily: theme.font.family,
    color: theme.palette.secondary.light,
    display: "flex",
    alignItems: "center"
  },
  buttonSmall: {
    padding: "0.20rem",
    minWidth: 0,
    "& svg": {
      margin: 0
    }
  },
  button: {
    padding: "0.25rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff"
    }
  },
  deleteButton: {
    padding: "0.25rem",
    marginLeft: 0,
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff"
    }
  },
  addressDescription: {
    marginLeft: "0.5rem",
    marginBottom: 0,
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    color: theme.palette.secondary.light,
    display: "flex",
    alignItems: "center"
  },
  descriptionLabel: {
    fontWeight: 800,
    marginRight: "0.25rem",
    height: 19
  },
  descriptionValue: {
    height: 19,
    opacity: 0.9
  },
  companyTitle: {
    marginTop: "1rem",
    marginLeft: "1rem",
    marginBottom: 0,
    fontSize: "1rem",
    fontWeight: 800,
    color: theme.palette.secondary.light,
    display: "flex",
    alignItems: "center"
  },
  contactsHeader: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1rem"
  },
  contactsTitle: {
    marginLeft: "1rem",
    fontSize: "1rem",
    fontWeight: 800,
    color: theme.palette.secondary.light
  },
  contactsAction: {
    display: "flex",
    alignItems: "center"
  },
  newButton: {
    padding: "0.25rem 1rem",
    minWidth: 0,
    [theme.breakpoints.down("md")]: {
      padding: "0.25rem 0.6rem"
    }
  },
  contactsBox: {
    margin: "5px 0.5rem 1rem",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxHeight: 315,
    overflowY: "auto"
  },
  expanded: {},
  popper: {
    marginTop: -5
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #929292",
    color: theme.palette.common.white,
    fontSize: "1rem",
    maxWidth: "none"
  }
}));

export { useStyles };

import { useState, useEffect, useCallback } from "react";
import { PropTypes } from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.any,
  submitFn: PropTypes.func,
  validator: PropTypes.func,
  validateAfterSubmit: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoScroll: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {},
  submitFn: () => {},
  validator: null,
  validateAfterSubmit: false,
  autoFocus: false,
  autoScroll: false,
};

/**
 * Defines the main hook
 */
const useForm = (props) => {
  const {
    defaultValues,
    autoFocus,
    autoScroll,
    validator,
    submitFn,
    validateAfterSubmit,
  } = props;

  /**
   * Initializes the inputs and the errors
   */
  const [inputs, setInputs] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);

  /**
   * Initializes the submitted state
   */
  const [submitted, setSubmitted] = useState(false);

  /**
   * initializes the state used for auto focusing on errored inputs
   */
  const [focused, setFocused] = useState(false);

  /**
   * Gets the input value based on type
   */
  const getInputValue = (target, type) => {
    switch (type) {
      case "text":
        return target.value;
      case "checkbox":
        return target.checked;
      default:
        return target.value;
    }
  };

  /**
   * Checks if there are any errors
   */
  const hasErrors = (obj) => {
    return Object.keys(obj).some((error) => {
      return (
        obj[error] !== null && obj[error] !== undefined && obj[error] !== {}
      );
    });
  };

  /**
   * Validates the inputs
   */
  const validateInputs = (inputs) => {
    let newErrors = {};
    const entries = Object.entries(inputs);

    entries.forEach((entry) => {
      const name = entry[0];
      const value = entry[1];

      /**
       * Gets the individual error
       */
      const inputError = validator ? validator({ name, value, inputs }) : {};
      newErrors[name] = inputError;
    });

    setIsError(hasErrors(newErrors));
    setErrors({
      ...newErrors,
    });
    return newErrors;
  };

  /**
   * Handles the submit event
   */
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    /**
     * Checks if the validations are active inline or after submit.
     * It's looking for a validator function.
     */
    const validationsActive = hasValidator();

    /**
     * Handles the validation if validateAfterSubmit is true - inline validations inactive
     */
    if (validateAfterSubmit && validationsActive) {
      checkInputs(inputs);
    }

    /**
     * If there are no errors found - inline validations active
     * - Performs a final check on the inputs, in case some inputs have not been filled in at all.
     * Inline validations only trigger when the user is typing in the input.
     */
    if (!validateAfterSubmit && validationsActive) {
      checkInputs(inputs);
    } else if (!validationsActive) {
      /**
       * If there is no validator it will submit the form as is.
       */
      submitFn(inputs, errors, true);
      setSubmitted(true);
    }
  };

  /**
   * Checks the inputs before submitting
   */
  const checkInputs = (inputs, mode) => {
    const newErrors = validateInputs(inputs);

    if (!hasErrors(newErrors)) {
      setSubmitted(true);
      if (autoScroll) window.scrollTo(0, 0);
      if (mode !== "strict" || !mode) {
        submitFn(inputs, errors, true);
      }
    } else {
      /**
       * This will trigger the auto focus on the first input that errors out
       * The order is established when providing the default values
       */
      if (autoFocus) setFocused(true);
    }
  };

  /**
   * Verifies if the validator has been provided to the hook
   */
  const hasValidator = () => {
    if (typeof validator === "function") return true;
    return false;
  };

  /**
   * Handles the validation
   */
  const handleValidation = (name, value) => {
    if (validateAfterSubmit && !submitted) {
      return;
    }

    if (validator) {
      /**
       * Gets the error message for the specific input
       */
      const inputError = validator({ name, value, inputs });

      /**
       * Builds the errors object
       */
      const newErrors = {
        ...errors,
        [name]: inputError,
      };

      /**
       * Sets a boolean value that can be used to disable submit buttons
       */
      setIsError(hasErrors(newErrors));

      /**
       * Updates the state which will result in displaying the errors
       */
      setErrors({
        ...newErrors,
      });
    }
  };

  /**
   * Handles the input change
   */
  const handleInputChange = (event) => {
    const { target } = event;
    const { name, type } = target;

    /**
     * Calling event.persist() on the synthetic event removes the event from the pool allowing references to the event to be retained asynchronously.
     * @see https://medium.com/trabe/react-syntheticevent-reuse-889cd52981b6
     */
    event.persist();

    /**
     * Gets the value of the input
     */
    const newValue = getInputValue(target, type);

    /**
     * Handles the validation
     */
    handleValidation(name, newValue);

    /**
     * Updates the input state
     */
    setInputs((inputs) => ({
      ...inputs,
      [name]: newValue,
    }));
  };

  /**
   * Updates the inputs state as a whole
   * Often used with default data from an API
   */
  const updateInputs = (inputs) => {
    setInputs(inputs);
    validateInputs(inputs);
  };

  /**
   * Resets the input state
   *  Also resets the errors state
   */
  const resetInputs = () => {
    setInputs(defaultValues);
    setErrors({});
  };

  /**
   * Handles updating date inputs
   */
  const handleDateChange = (date, name) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: date,
    }));
  };

  /**
   * Enables auto focus for the first element that has invalid input
   * This happens only when submitting the form.
   * It can be enabled with a boolean when calling useForm
   * The function needs to be called on the input and the input must have an id for the autofocus to work.
   */
  const getAutoFocus = useCallback(() => {
    const keys = Object.keys(inputs);

    let autoFocus = {};
    keys.forEach((key) => (autoFocus[key] = false));

    /**
     * Finds y value of given object
     * @see https://stackoverflow.com/questions/4801655/how-to-go-to-a-specific-element-on-page/11986153#11986153
     */
    const findPosition = (obj) => {
      let currentTop = 0;
      if (obj.offsetParent) {
        do {
          currentTop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        /**
         * - 250 represents the offset due to the fixed navigation top
         * - as well as the fixed Site Location on some pages.
         */
        return currentTop - 250;
      }
    };
    if (focused) {
      for (let i = 0; i < keys.length; i++) {
        if (errors[keys[i]]) {
          autoFocus[keys[i]] = true;
          if (autoScroll)
            window.scroll(0, findPosition(document.getElementById(keys[i])));
          break;
        }
      }
      return { ...autoFocus };
    }
    return { ...autoFocus };
  }, [focused, autoScroll, errors, inputs]);

  /**
   * Runs the auto focus function
   */
  useEffect(() => {
    if (focused) getAutoFocus();
    // eslint-disable-next-line
  }, [focused]);

  /**
   * Resets the focused state as otherwise the input would jump
   * to the next error (if any) as soon as the user enters the bare minimal correct input
   */
  useEffect(() => {
    setFocused(false);
  }, [getAutoFocus]);

  return {
    handleSubmit,
    handleInputChange,
    handleDateChange,
    checkInputs,
    validateInputs,
    resetInputs,
    handleValidation,
    updateInputs,
    submitted,
    setSubmitted,
    getAutoFocus,
    inputs,
    setFocused,
    setInputs,
    errors,
    isError,
    setErrors,
    hasErrors,
  };
};

useForm.propTypes = propTypes;
useForm.defaultProps = defaultProps;

export default useForm;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 *  Material UI Imports
 */
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

/**
 * Styles Imports
 */
import { useStyles } from "./LoadingBackdrop.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  open: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  screen: PropTypes.bool,
  classes: PropTypes.shape({
    backdrop: PropTypes.string,
    spinner: PropTypes.string
  })
};

/**
 * Defines the default props
 */
const defaultProps = {
  open: false,
  size: 40,
  text: null,
  screen: false,
  classes: {
    backdrop: "",
    spinner: ""
  }
};

/**
 * Displays the component
 */
const LoadingBackdrop = props => {
  const { open, size, text, screen, classes } = props;

  const [extraLife, setExtraLife] = useState(false);
  const [isOpen, setIsOpen] = useState(open);

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Gets the spinner classes
   */
  const getBackdropClasses = () => {
    return clsx(styles.backdrop, {
      [classes.backdrop]: classes.backdrop ? true : false
    });
  };

  /**
   * Gets the text classes
   */
  const getSpinnerClasses = () => {
    return clsx(styles.spinner, {
      [classes.spinner]: classes.spinner ? true : false
    });
  };

  useEffect(() => {
    if (open) {
      setExtraLife(true);
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (extraLife && !open) {
      setTimeout(() => {
        setIsOpen(false);
      }, [500]);
    }
  }, [extraLife, open]);

  const handleScreen = () => {
    if (screen) {
      return (
        <Fade in={isOpen} mountOnEnter unmountOnExit timeout={1000}>
          <div className={styles.screen} />
        </Fade>
      );
    }
  };

  return (
    <Backdrop className={getBackdropClasses()} open={isOpen}>
      <CircularProgress
        color="inherit"
        size={size}
        thickness={6}
        disableShrink
        className={getSpinnerClasses()}
      />
      {text && <div className={styles.textContainer}>{text}</div>}
      {handleScreen()}
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = propTypes;
LoadingBackdrop.defaultProps = defaultProps;

export default LoadingBackdrop;
export {
  propTypes as LoadingBackdropPropTypes,
  defaultProps as LoadingBackdropDefaultProps
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 5,
    fontSize: "0.85rem",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
    },
  },
  modalContent: {
    maxHeight: 900,
    padding: 0
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
  stepCard: {
    boxShadow: "none",
  },
  content: {
    padding: 4,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  appbar: {
    boxShadow: "none",
    border: "none",
    background: "transparent",
    maxWidth: "inherit",
    width: "inherit",
    display: "flex",
    justifyContent: "center",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      display: "flex",
    },
    "& .MuiTab-wrapper": {
      border: `1px solid #ded6d6`,
      padding: "0.5rem 1rem",
      background: theme.palette.sidebar.main,
      textTransform: "none",
    },
  },
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Redirect } from "react-router-dom";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import AppointmentsScheduler, {
  AppointmentsSchedulerDefaultProps,
  AppointmentsSchedulerPropTypes,
} from "../AppointmentsScheduler";

/**
 *  Material UI Imports
 */
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreServiceScheduler.styles";

/**
 * Hooks
 */
import { useSidebar, useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  scheduler: PropTypes.shape(AppointmentsSchedulerPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  /**
   * Used in TyreServicePage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  scheduler: AppointmentsSchedulerDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  /**
   * Used in TyreServicePage
   * @see defaultProps.paths
   */
  path: "/scheduler",
};

/**
 * Displays the component
 */
const TyreServiceScheduler = (props) => {
  const { scheduler, title, wrapper, container } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  const { open: isSidebarOpen } = useSidebar();

  /**
   * Gets the component styles
   */
  const classes = useStyles({ open: isSidebarOpen });

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the loading flag
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the authorized state
   */
  const [isAuthorized, setIsAuthorized] = useState(true);

  /**
   * Checks the authority of the user to access the module
   */
  const checkAuthority = () => {
    if (user.type !== "worker") {
      setIsAuthorized(true);
      return;
    } else {
      setIsAuthorized(false);
    }
  };

  /**
   * Checks the authority
   */
  useEffect(() => {
    if (user.type) checkAuthority();
    // eslint-disable-next-line
  }, [user]);

  if (isAuthorized !== undefined && !isAuthorized)
    return <Redirect to="/modules" />;

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<EventOutlinedIcon />}
        title={t("titleAppointments")}
      />
      <SubmoduleWrapper {...wrapper} className={classes.wrapper}>
        <AppointmentsScheduler
          {...scheduler}
          loading={loading}
          setLoading={setLoading}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

TyreServiceScheduler.propTypes = propTypes;
TyreServiceScheduler.defaultProps = defaultProps;

export default TyreServiceScheduler;
export {
  propTypes as TyreServiceSchedulerPropTypes,
  defaultProps as TyreServiceSchedulerDefaultProps,
};

import React, { useState, useEffect } from "react";

/**
 * External Imports
 */
import { useHistory, useLocation } from "react-router-dom";

/**
 *  Material UI Imports
 */
import AddToPhotosOutlinedIcon from "@material-ui/icons/AddToPhotosOutlined";

/**
 * Imports Components
 */
import CreateButton from "../CreateButton";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Styles Imports
 */
import { useStyles } from "./CreateWorkOrderButton.styles";

/**
 * Displays the component
 */
const CreateWorkOrderButton = () => {
  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the create wo page flag
   */
  const [createWOPage, setCreateWOPage] = useState(false);

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Gets the location
   */
  const location = useLocation();

  const handleClick = () => {
    history.push("/modules/tyre-service/create");
  };

  useEffect(() => {
    if (location.pathname) {
      const { pathname } = location;

      if (pathname === "/modules/tyre-service/create") {
        setCreateWOPage(true);
      } else {
        setCreateWOPage(false);
      }
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (createWOPage) return null;

  return (
    <CreateButton
      className={classes.addBtn}
      text={t("titleCreateNewWorkOrder")}
      onClick={handleClick}
      icon={<AddToPhotosOutlinedIcon />}
    />
  );
};

export default CreateWorkOrderButton;

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports
 */
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./TextCount.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  text: PropTypes.string,
  maxChars: PropTypes.number,
  className: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  text: "",
  maxChars: 500,
  className: "",
};

/**
 * Displays the component
 */
const TextCount = (props) => {
  const { text, maxChars, className } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the text count classes
   */
  const textCountClasses = clsx(classes.textCount, { [className]: true });

  /**
   * Displays the count
   */
  const displayCount = () => {
    if (text) {
      const { length: textLength } = text;
      return `${textLength} / ${maxChars} ${t("charText")}.`;
    }
    return `0 / ${maxChars} ${t("charText")}.`;
  };

  /**
   * Gets the count
   */
  const count = displayCount();

  return (
    <Typography variant="h4" className={textCountClasses}>
      {count}
    </Typography>
  );
};

TextCount.propTypes = propTypes;
TextCount.defaultProps = defaultProps;

export default TextCount;
export {
  propTypes as TextCountPropTypes,
  defaultProps as TextCountDefaultProps,
};

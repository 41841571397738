/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      minHeight: 41,
    },
  },
  adornment: {
    marginLeft: -10,
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  modal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 700,
    },
  },
  modalContent: {
    "& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd": {
      width: "100%",
    },
  },
  submitButton: theme.mixins.submitBtn,
}));

export { useStyles };

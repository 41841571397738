import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditLoyaltyCardModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditLoyaltyCardModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    discount_type: PropTypes.string,
    discount_value: PropTypes.number,
    is_active: PropTypes.bool,
    card_number: PropTypes.string,
    card_type: PropTypes.string,
  }),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    discount_type: "",
    discount_value: 0,
    is_active: false,
    card_number: "",
    card_type: "",
  },
  organizations: [],
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditLoyaltyCardModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    setUpdated,
    editData,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { card_number, card_type, is_active, discount_type, discount_value } =
    inputs;

  const {
    card_number: card_numberError,
    card_type: card_typeError,
    discount_type: discount_typeError,
    discount_value: discount_valueError,
  } = errors;

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setReady(false);
    closeModal();
  };

  /**
   * Handles updating a contract by id
   */
  const updateLoyaltyCard = async (data, id) => {
    try {
      const updatedLoyaltyCard = await apiClient.put(
        `/loyalty-cards/${id}`,
        data
      );
      if (updatedLoyaltyCard) {
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });

        /**
         * Triggers a new search using the current search model
         */
        setUpdated(true);
        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  useEffect(() => {
    if (open) {
      setInputs((prevState) => {
        return {
          ...prevState,
          is_active: editData.is_active,
          discount_value: editData.discount_value
            ? editData.discount_value
            : "0",
          card_type: editData.card_type ? editData.card_type : "FIDELITY",
          discount_type: editData.discount_type
            ? editData.discount_type
            : "PERCENT",
          card_number: editData.card_number ? editData.card_number : "",
        };
      });
    }
    // eslint-disable-next-line
  }, [open]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        discount_type,
        discount_value,
        is_active,
      };

      if (card_number) data["card_number"] = card_number;
      if (card_type) data["card_type"] = card_type;

      updateLoyaltyCard(data, editData.id);
    }
    // eslint-disable-next-line
  }, [ready]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleEditLoyaltyCard")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="add-loyalty-card-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "card_type",
                              name: "card_type",
                              value: card_type,
                              onChange: handleInputChange,
                              maxSize: 70,
                              label: t("card_typeLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label,
                              },
                              error: card_typeError,
                              autoFocus: getAutoFocus().card_type,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Input
                            {...input}
                            type="numeric"
                            className={classes.field}
                            inputNumeric={{
                              required: true,
                              id: "discount_value",
                              name: "discount_value",
                              value: discount_value,
                              variant: "outlined",
                              onChange: handleInputChange,
                              label: t("discount_valueLabel"),
                              InputLabelProps: {
                                className: classes.label,
                              },
                              error: discount_valueError,
                              autoFocus: getAutoFocus().discount_value,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            className={classes.selectField}
                            type="select"
                            inputSelect={{
                              required: true,
                              id: "discount_type",
                              name: "discount_type",
                              value: discount_type,
                              onChange: handleInputChange,
                              label: t("discount_typeLabel"),
                              labelClass: classes.label,
                              variant: "outlined",
                              options: [
                                {
                                  label: t("percent"),
                                  value: "PERCENT",
                                },
                                {
                                  label: t("value"),
                                  value: "VALUE",
                                },
                              ],
                              optionLabel: "label",
                              optionValue: "value",
                              error: discount_typeError,
                              autoFocus: getAutoFocus().discount_type,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              required: true,
                              id: "card_number",
                              name: "card_number",
                              value: card_number,
                              onChange: handleInputChange,
                              maxSize: 14,
                              label: t("card_numberLabel"),
                              variant: "outlined",
                              InputLabelProps: {
                                className: classes.label,
                              },
                              error: card_numberError,
                              autoFocus: getAutoFocus().card_number,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Input
                            {...input}
                            type="checkbox"
                            className={classes.field}
                            inputCheckbox={{
                              name: "is_active",
                              value: is_active,
                              onChange: handleInputChange,
                              variant: "outlined",
                              labelClass: classes.label,
                              InputLabelProps: {
                                className: classes.label,
                              },
                              showHelper: false,
                              label: t("is_activeLabel"),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditLoyaltyCardModal.propTypes = propTypes;
EditLoyaltyCardModal.defaultProps = defaultProps;

export default EditLoyaltyCardModal;
export {
  propTypes as EditLoyaltyCardModalPropTypes,
  defaultProps as EditLoyaltyCardModalDefaultProps,
};

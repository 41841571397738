import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import "date-fns";
import { format as formatDate } from "date-fns";
import { useReactToPrint } from "react-to-print";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import ReportsDailySearch, {
  ReportsDailySearchDefaultProps,
  ReportsDailySearchPropTypes,
} from "../ReportsDailySearch";
import ReportsDailyTable, {
  ReportsDailyTableDefaultProps,
  ReportsDailyTablePropTypes,
} from "../ReportsDailyTable";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ReportsDaily.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  search: PropTypes.shape(ReportsDailySearchPropTypes),
  table: PropTypes.shape(ReportsDailyTablePropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
  dateFormat: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  search: ReportsDailySearchDefaultProps,
  table: ReportsDailyTableDefaultProps,
  button: ButtonDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  /**
   * Used in ReportsPage
   * @see defaultProps.paths
   */
  path: "/daily",
  dateFormat: "yyyy-MM-dd HH:mm:ss",
};

/**
 * Displays the component
 */
const ReportsDaily = (props) => {
  const {
    title,
    wrapper,
    container,
    search,
    table,
    button,
    loadingBackdrop,
    dateFormat,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the print loading state
   */
  const [printLoading, setPrintLoading] = useState(false);

  /**
   * Initializes the data
   */
  const [data, setData] = useState({});

  const { user } = useUser();

  /**
   * Initializes the init api call flag
   */
  const [apiCallMade, setApiCallMade] = useState(false);

  /**
   * Initializes the print state
   */
  const [readyToPrint, setReadyToPrint] = useState(false);

  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the component ref, used for printing the component
   */
  const componentRef = useRef();

  /**
   * Defines the handle print function
   */
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**
   * Handles formatting the number
   * @param {String} value
   */
  const formatNumber = (value) => `${value.toFixed(2)} RON`;

  /**
   * Handles getting the start date
   */
  const getStartDate = () => {
    const date = formatDate(new Date().setHours(0, 0, 0), dateFormat);
    return formatDate(new Date(date), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles getting the end date
   */
  const getEndDate = () => {
    const date = formatDate(new Date().setHours(23, 59, 59), dateFormat);
    return formatDate(new Date(date), "dd-MM-yyyy HH:mm");
  };

  /**
   * Handles initiating the printing
   */
  const printComponent = () => {
    setPrintLoading(true);
  };

  /**
   * Handles getting the print button content
   */
  const getPrintButtonContent = () =>
    printLoading ? (
      <CircularProgress size="1.45rem" className={classes.loader} />
    ) : (
      <PrintIcon className={classes.icon} />
    );

  /**
   * Defines the Print Button component
   */
  const PrintButton = () => {
    return (
      <Button
        {...button}
        type="button"
        variant="filled"
        className={classes.printButton}
        title={t("printReport")}
        onClick={printComponent}
      >
        {getPrintButtonContent()}
      </Button>
    );
  };

  /**
   * Defines the Flex Center Component
   */
  const FlexCenter = ({ children }) => (
    <div className={classes.flexCenter}>{children}</div>
  );

  /**
   * Defines the Flex End Component
   */
  const FlexEnd = ({ children }) => (
    <div className={classes.flexEnd}>{children}</div>
  );

  /**
   * Defines the Flex Column Component
   */
  const FlexColumn = ({ children }) => (
    <div className={classes.flexColumn}>{children}</div>
  );

  /**
   * Defines the Container Component
   */
  const Container = ({ children }) => (
    <div className={classes.container}>{children}</div>
  );

  /**
   * Defines the Label Component
   */
  const Label = (props) => {
    const { value } = props;
    return <span className={classes.label}>{value}</span>;
  };

  /**
   * Defines the Value Component
   */
  const Value = (props) => {
    const { value } = props;

    return <span className={classes.value}>{value}</span>;
  };

  /**
   * Defines the Report Data Component
   */
  const ReportData = () => {
    if (data.report_data) {
      const { report_data } = data;
      const { total, discount, subtotal, new_tyre_quantity } = report_data;

      return (
        <Container>
          <FlexCenter>
            <Label value={t("new_tyres")} />
            <Value value={new_tyre_quantity} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("subtotal")} />
            <Value value={formatNumber(subtotal)} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("discount")} />
            <Value value={formatNumber(discount)} />
          </FlexCenter>
          <FlexCenter>
            <Label value={t("total")} />
            <Value value={formatNumber(total)} />
          </FlexCenter>
        </Container>
      );
    }
    return null;
  };

  const getUserOrganizationByID = (id) => {
    if (user.organizations) {
      const found = user.organizations.find((org) => org.id === id);
      const name = found ? found.name : false;
      const companyBase = user.account ? user.account.name : "";
      if (!name) return "";
      return name.replace(`${companyBase} - `, "");
    }
  };

  const getReportTitle = () => {
    if (activeOrg === "all") return t("totalTitle");
    const activeOrgName = getUserOrganizationByID(activeOrg);
    return `${t("titleReportDaily")} - ${activeOrgName}`;
  };

  /**
   * Defines the Report Title Component
   */
  const ReportTitle = () => {
    return (
      <FlexColumn>
        <Typography variant="caption" className={classes.title}>
          {getReportTitle()}
        </Typography>
        <FlexCenter>
          <Label value={t("from")} />
          <Value value={getStartDate()} />
          <Label value="/" />
          <Label value={t("to")} />
          <Value value={getEndDate()} />
        </FlexCenter>
      </FlexColumn>
    );
  };

  /**
   * Defines the Report Payment Types Data Component
   */
  const ReportPaymentTypesData = () => {
    if (data.report_data && data.report_data.payment_type_sum) {
      const paymentTypes = Object.entries(data.report_data.payment_type_sum);

      return (
        <Container>
          {paymentTypes.map((entry, index) => {
            const label = entry[0];
            const values = entry[1];

            const { total } = values;

            return (
              <FlexEnd key={index}>
                <Label value={`${label}:`} />
                <Value value={formatNumber(total)} />
              </FlexEnd>
            );
          })}
        </Container>
      );
    }
    return null;
  };

  /**
   * Handles opening the print window
   */
  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
      setPrintLoading(false);
    }
    // eslint-disable-next-line
  }, [readyToPrint]);

  /**
   * Resets the loading state
   */
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoading(false);
    }
  }, [data]);

  /**
   * Handles triggering the printing
   */
  useEffect(() => {
    if (printLoading) {
      setTimeout(() => {
        setReadyToPrint(true);
      }, [1000]);
    }
  }, [printLoading]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<EventNoteOutlinedIcon />}
        title={t("dailyReports")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12}>
            <ReportsDailySearch
              {...search}
              setData={setData}
              apiCallMade={apiCallMade}
              setApiCallMade={setApiCallMade}
              setBackdropLoading={setLoading}
              setActiveOrg={setActiveOrg}
            />
          </Grid>
          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <Grid container className={classes.printContainer}>
                <Grid item container xs={12}>
                  <Grid item xs={3}>
                    <ReportData />
                  </Grid>
                  <Grid item xs={6}>
                    <ReportTitle />
                  </Grid>
                  <Grid item xs={3}>
                    <ReportPaymentTypesData />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <ReportsDailyTable
                    {...table}
                    isPrinting={true}
                    data={data.items}
                    apiCallMade={apiCallMade}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className={classes.mainContainer}>
            <Grid item container xs={12}>
              <Grid item xs={3}>
                <PrintButton />
                <ReportData />
              </Grid>
              <Grid item xs={6}>
                <ReportTitle />
              </Grid>
              <Grid item xs={3}>
                <ReportPaymentTypesData />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <ReportsDailyTable
                {...table}
                data={data.items}
                apiCallMade={apiCallMade}
              />
            </Grid>
          </Grid>
          <LoadingBackdrop {...loadingBackdrop} open={loading} />
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

ReportsDaily.propTypes = propTypes;
ReportsDaily.defaultProps = defaultProps;

export default ReportsDaily;
export {
  propTypes as ReportsDailyPropTypes,
  defaultProps as ReportsDailyDefaultProps,
};

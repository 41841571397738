/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "10rem",
    maxWidth: 600,
    padding: "1rem",
    textAlign: "center",
    color: theme.palette.sidebar.text
  },
  errCode: {
    fontSize: "10rem"
  },
  errMessage: {
    fontSize: "2rem",
    marginTop: "0.5rem",
    background: "#efefef",
    borderRadius: 5,
    padding: "0.5rem"
  },
  errMessageExtended: {
    fontSize: "1.2rem",
    marginTop: "1rem"
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: "1rem"
  },
  button: theme.mixins.submitBtn
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  stepCard: {
    boxShadow: "none",
    width: "100%",
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardActions: {
    paddingTop: 0,
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    height: 30,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  beforeIcon: {
    marginRight: "0.25rem",
  },
  nextIcon: {
    marginLeft: "0.25rem",
  },
  addProductBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    marginLeft: 0,
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  smallBtn: {
    padding: "0.1rem",
    fontSize: "0.7rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      width: 24,
      height: 24,
    },
  },
  addServiceBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  addTyreHotelBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
  deleteTyreHotelBtn: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.error.dark,
      boxShadow: "none",
    },
  },
  smallBtnMargins: {
    margin: "0.25rem 0.15rem",
  },
  itemCount: {
    width: 30,
    paddingLeft: 6,
    paddingRight: 0,
  },
  name: {
    minWidth: 235,
    paddingLeft: 0,
  },
  actions: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  section: {
    background: "#f7f7f7",
    border: `1px solid #cacaca`,
    margin: "0.5rem 0",
    padding: "0 0.5rem",
  },
  sectionPadding: {
    padding: "1rem",
  },
  delIcon: {
    marginRight: "0.5rem",
  },
  sectionTitle: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 0,
    borderBottom: `1px solid #e0e0e0`,
    display: "flex",
    alignItems: "center",
    margin: "0.75rem 0",
  },
  container: {
    maxHeight: 350,
  },
  selectField: {
    ...theme.mixins.searchSelect,
    marginBottom: "0.5rem",
    "& .MuiInputBase-input": {
      color: "#000",
      height: "0.95rem",
      padding: "8px 12.5px",
      backgroundColor: "#fff",
    },
  },
  table: {
    "& .MuiTableCell-root": {
      fontSize: "0.75rem",
    },
    "& .MuiTableCell-alignRight": {
      textAlign: "right",
    },
    "& .MuiTableCell-alignCenter": {
      textAlign: "center",
    },
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#f7f7f7",
    },
    "& .MuiTableCell-head": {
      fontWeight: 800,
      paddingTop: 0,
      paddingBottom: 4,
      lineHeight: 1.2,
    },
    "& .MuiTableCell-sizeSmall": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiTableHead-root .MuiTableCell-root.MuiTableCell-sizeSmall": {
      paddingBottom: 8,
    },
  },
  field: {
    ...theme.mixins.searchField,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.9rem",
      padding: 10,
      fontSize: "0.9rem",
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined": {
      transform: "translate(10px, 12px) scale(1)",
      fontSize: "0.8rem",
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  formContainer: {
    marginTop: "0.5rem",
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  pricingContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.25rem",
  },
  pricing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.15rem",
    borderBottom: "1px solid #d6d6d6",
    minWidth: 150,
  },
  pricingLabel: {
    fontWeight: 800,
    fontSize: "0.85rem",
  },
  pricingValue: {
    fontSize: "0.85rem",
  },
  saveBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  itemMain: {
    display: "flex",
    alignItems: "center",
  },
  notCountPriceCont: {
    display: "inline-block",
  },
  notCountPrice: {
    cursor: "pointer",
    color: theme.palette.common.white,
    padding: "0.1rem 0.5rem",
    marginLeft: "0.35rem",
    height: 20,
    background: theme.palette.warning.main,
    display: "flex",
    alignItems: "center",
    "& svg": {
      color: "inherit",
      marginRight: "0.2rem",
      height: "0.9rem",
      width: "0.9rem",
    },
  },
  popper: {
    marginTop: -5,
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #929292",
    color: theme.palette.common.white,
    fontSize: "0.85rem",
    maxWidth: "none",
  },
  choice: {
    background: "transparent",
    display: "flex",
    alignItems: "center",
  },
  paper: {
    boxShadow: "none",
    marginTop: "1rem",
    border: `1px solid #b9b9b9`,
  },
}));

export { useStyles };

const quickTextModel = {
  label: "Quick Text",
  field: "quick_text",
  type: "like",
  order: 1,
  options: [],
  selected: null,
};

const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

const paymentTypeModel = {
  label: "Payment",
  field: "payment_type_id",
  type: "dropdown",
  order: 4,
  options: [],
  selected: null,
};

const carTypeModel = {
  label: "Car Type",
  field: "car_type_id",
  type: "dropdown",
  order: 5,
  options: [],
  selected: null,
};

const workOrderTypeModel = {
  label: "Work Order Type",
  field: "work_order_type_id",
  type: "dropdown",
  order: 6,
  options: [],
  selected: null,
};

const workOrderDoneDateModel = {
  label: "Work Order Done Date",
  field: "finished",
  type: "range",
  order: 7,
  options: [],
  selected: null,
};

const tyreWidthModel = {
  label: "Tyre Width",
  field: "tyreService.tyre_width",
  type: "equal",
  order: 6,
  options: [],
  selected: null,
};

const tyreHeightModel = {
  label: "Tyre Height",
  field: "tyreService.tyre_height",
  type: "equal",
  order: 6,
  options: [],
  selected: null,
};

const tyreRimModel = {
  label: "Tyre Rim",
  field: "tyreService.tyre_rim",
  type: "equal",
  order: 6,
  options: [],
  selected: null,
};

const clientNameModel = {
  label: "Organization Client id",
  field: "organizationClient.id",
  type: "like",
  order: 6,
  options: [],
  selected: null,
};

const workOrderIdModel = {
  label: "Work Order ID",
  field: "uuid",
  type: "like",
  order: 6,
  options: [],
  selected: null,
};

const defaults = {
  models: {
    quickTextModel,
    organizationIdModel,
    paymentTypeModel,
    carTypeModel,
    workOrderTypeModel,
    workOrderDoneDateModel,
    tyreWidthModel,
    tyreHeightModel,
    tyreRimModel,
    clientNameModel,
    workOrderIdModel,
  },
  searchParams: {
    models: [
      quickTextModel,
      organizationIdModel,
      paymentTypeModel,
      carTypeModel,
      workOrderTypeModel,
      workOrderDoneDateModel,
      tyreWidthModel,
      tyreHeightModel,
      tyreRimModel,
      clientNameModel,
      workOrderIdModel,
    ],
    order_by: "finished",
    order_dir: "desc",
    page_size: 15,
    page_count: 1,
  },
  pageSizeOptions: [15, 30, 50, 100],
};

export { defaults };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useAuth, useMessage, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./DeleteAccountModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteData: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  loadingText: LoadingTextDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  open: false,
  closeModal: () => {},
  deleteData: {},
};

/**
 * Displays the component
 */
const DeleteAccountModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    button,
    errorMessages,
    loadingText,
    loadingBackdrop,
    setRedirect,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user setter
   */
  const { user, setUser } = useUser();

  /**
   * Gets the auth controllers
   */
  const { auth } = useAuth();

  const [isSuspended, setIsSuspended] = useState(false);

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [ready, setReady] = useState(false);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Handles deleting the account
   */
  const deleteAccount = async (type) => {
    try {
      const deletedAccount = await apiClient.delete(`/accounts/${type}`);
      if (deletedAccount) setReady(true);
    } catch (error) {
      setLoading(false);

      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles the submit
   */
  const handleDelete = () => {
    setLoading(true);
    setBackdropLoading(true);
    const deleteType = isSuspended ? "force" : "soft";
    deleteAccount(deleteType);
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (ready) {
      const timer = setTimeout(() => {
        setRedirect(true);

        /**
         * Dispatches the success message
         */

        dispatchMessage({
          icon: false,
          delay: 300,
          message: isSuspended ? t("deleted_success") : t("suspended_success"),
        });
        const acc = { ...user.account };
        acc["status"] =
          user.account.status === "suspended" ? "deleted" : "suspended";

        setUser((prevState) => {
          return {
            ...prevState,
            redirect: true,
            suspended: true,
            account: acc,
          };
        });
      }, 3000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the account state
   */
  useEffect(() => {
    if (user.account) {
      const isSuspended = user.account.status !== "active";
      setIsSuspended(isSuspended);
    }
  }, [user]);

  const getDeleteConfirmationText = () => {
    return isSuspended ? t("deleteConfirmation") : t("suspendConfirmation");
  };

  const getSubmitBtnText = () => {
    return isSuspended ? t("deleteConfirm") : t("suspendConfirm");
  };

  const getBackdropText = () => {
    return isSuspended ? t("deletingAccount") : t("suspendingAccount");
  };

  const getWarningText = () => {
    return isSuspended ? t("ireversible") : t("reversible");
  };

  const warningClasses = clsx(classes.label, {
    [classes.ireversible]: isSuspended,
    [classes.reversible]: !isSuspended,
  });

  return (
    <Modal
      {...modal}
      fullScreen={triggerFullScreen}
      maxWidth="sm"
      open={open}
      onClose={handleCloseModal}
      keepMounted={true}
      scroll="paper"
    >
      <ModalTitle {...modalTitle} onClick={handleCloseModal} />
      <ModalContent {...modalContent} className={classes.modalContent}>
        <Grid item container xs={12} className={classes.modulesContainer}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card className={classes.blank}>
                <CardContent className={classes.cardContent}>
                  <Grid item xs={12} container justify="center">
                    <Typography className={classes.label}>
                      {getDeleteConfirmationText()}{" "}
                      <span className={classes.value}> {auth.username} </span> ?
                    </Typography>
                    <Typography className={warningClasses}>
                      {getWarningText()}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <LoadingBackdrop
          {...loadingBackdrop}
          open={backdropLoading}
          screen={true}
          text={getBackdropText()}
          classes={{ spinner: classes.spinner }}
        />
      </ModalContent>
      <ModalActions {...modalActions} onClick={handleCloseModal}>
        <Button
          {...button}
          type="button"
          variant="filled"
          className={classes.submitBtn}
          onClick={handleDelete}
        >
          <LoadingText
            {...loadingText}
            loading={loading}
            text={getSubmitBtnText()}
          />
        </Button>
      </ModalActions>
    </Modal>
  );
};

DeleteAccountModal.propTypes = propTypes;
DeleteAccountModal.defaultProps = defaultProps;

export default DeleteAccountModal;
export {
  propTypes as DeleteAccountModalPropTypes,
  defaultProps as DeleteAccountModalDefaultProps,
};

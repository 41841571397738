import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  error: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  error: "",
};

/**
 * Displays the component
 */
const ErrorMessages = (props) => {
  const { error } = props;

  /**
   * Initializes the errors state
   */
  const [errors, setErrors] = useState([]);

  /**
   * Handles rendering the errors
   */
  const renderErrors = () => {
    if (error && (typeof error === "string" || typeof error.message === "string")) {
      return <li>{error.message ? error.message : error}</li>;
    } else {
      return errors
        ? errors.map((error, idx) => {
            return <li key={idx}> {error} </li>;
          })
        : null;
    }
  };

  /**
   * Updates the errors state
   */
  useEffect(() => {
    if (error) {
      if (Array.isArray(error)) {
        setErrors(error);
      } else {
        const errorsList = Object.values(error);
        setErrors(errorsList);
      }
    }
  }, [error]);

  return <ul>{renderErrors()}</ul>;
};

ErrorMessages.propTypes = propTypes;
ErrorMessages.defaultProps = defaultProps;

export default ErrorMessages;
export {
  propTypes as ErrorMessagesPropTypes,
  defaultProps as ErrorMessagesDefaultProps,
};

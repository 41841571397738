import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { useLocation } from "react-router-dom";
import queryString from "query-string";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import WorkOrdersTable, {
  WorkOrdersTableDefaultProps,
  WorkOrdersTablePropTypes,
} from "../WorkOrdersTable";
import ViewWorkOrderModal, {
  ViewWorkOrderModalDefaultProps,
  ViewWorkOrderModalPropTypes,
} from "../ViewWorkOrderModal";
import EditWorkOrderModal, {
  EditWorkOrderModalDefaultProps,
  EditWorkOrderModalPropTypes,
} from "../EditWorkOrderModal";
import DeleteWorkOrderModal, {
  DeleteWorkOrderModalDefaultProps,
  DeleteWorkOrderModalPropTypes,
} from "../DeleteWorkOrderModal";
import WorkOrderSearchForm, {
  WorkOrderSearchFormDefaultProps,
  WorkOrderSearchFormPropTypes,
} from "../WorkOrderSearchForm";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import OrganizationsTabs, {
  OrganizationsTabsDefaultProps,
  OrganizationsTabsPropTypes,
} from "../OrganizationsTabs";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";
import Importer, { ImporterDefaultProps, ImporterPropTypes } from "../Importer";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkstationSettingsWorkOrders.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./WorkstationSettingsWorkOrders.defaults";

/**
 * Hooks
 */
import {
  useLocalStorage,
  useUser,
  useMessage,
  useApiClient,
} from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  importer: PropTypes.shape(ImporterPropTypes),
  workOrdersTable: PropTypes.shape(WorkOrdersTablePropTypes),
  viewModal: PropTypes.shape(ViewWorkOrderModalPropTypes),
  editModal: PropTypes.shape(EditWorkOrderModalPropTypes),
  deleteModal: PropTypes.shape(DeleteWorkOrderModalPropTypes),
  searchForm: PropTypes.shape(WorkOrderSearchFormPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  organizationsTabs: PropTypes.shape(OrganizationsTabsPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  panelName: PropTypes.string,
  defaultSearch: PropTypes.object,
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  importer: ImporterDefaultProps,
  workOrdersTable: WorkOrdersTableDefaultProps,
  viewModal: ViewWorkOrderModalDefaultProps,
  editModal: EditWorkOrderModalDefaultProps,
  deleteModal: DeleteWorkOrderModalDefaultProps,
  searchForm: WorkOrderSearchFormDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  organizationsTabs: OrganizationsTabsDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  defaultSearch: defaults.searchParams,
  panelName: "workorder",
  path: "/work-orders",
};

/**
 * Displays the component
 */
const WorkstationSettingsWorkOrders = (props) => {
  const {
    title,
    wrapper,
    container,
    workOrdersTable,
    defaultSearch,
    viewModal,
    editModal,
    deleteModal,
    searchForm,
    loadingBackdrop,
    organizationsTabs,
    importer,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Initializes the selected organization
   */
  const [selectedOrg, setSelectedOrg] = useState("");

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    viewWorkOrder: false,
    editWorkOrder: false,
    deleteWorkOrder: false,
  });

  /**
   * Initializes the state that hold the data for the modals
   */
  const [viewData, setViewData] = useState(0);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [searchedValue, setSearchedValue] = useState("");

  /**
   * Handles opening the edit modal
   */
  const handleView = (props) => setViewData(props.id);
  const location = useLocation();

  /**
   * Handles opening the edit modal
   */
  const handleEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const handleDelete = (props) => setDeleteData(props);

  useEffect(() => {
    const searchedValue = queryString.parse(location.search);
    if (searchedValue && searchedValue.search)
      setSearchedValue(searchedValue.search);
  }, [location]);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openView = () => handleOpenModal("viewWorkOrder");
  const closeView = () => handleCloseModal("viewWorkOrder");

  /**
   * Defines the edit User modal handlers
   */
  const openEdit = () => handleOpenModal("editWorkOrder");
  const closeEdit = () => handleCloseModal("editWorkOrder");

  /**
   * Defines the delete User modal handlers
   */
  const openDelete = () => handleOpenModal("deleteWorkOrder");
  const closeDelete = () => handleCloseModal("deleteWorkOrder");

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the state related to the Pagination
   */
  const [pageSize, setPageSize] = useLocalStorage("workOrdersPageSize");
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the reset filters flag
   */
  const [resetFilters, setResetFilters] = useState(false);

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Initializes the tab changed flag
   */
  const [tabChanged, setTabChanged] = useState(false);

  /**
   * Initializes the inputs state
   */
  const [inputs, setInputs] = useState({});

  /**
   * Initializes the changed value state of the tabs
   */
  const [valueChanged, setValueChanged] = useState(false);

  /**
   * Gets the inputs
   */
  const { orderBy, orderDir } = inputs;

  /**
   * Handles searching for the work orders
   */
  const searchWorkOrders = async (data) => {
    try {
      const workOrders = await apiClient.post("/work-orders/search", data);
      if (workOrders) {
        const { data } = workOrders;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems,
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setUpdated(false);
        setTabChanged(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, work_orders: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setSearchedValue("");
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setUpdated(false);
      /**
       * Dispatches the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        message: error.message,
      });
    }
  };

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setResetFilters(true);

  /**
   * Handles getting the organization data based on the active tab
   */
  const getUserOrganization = (idx) => {
    if (idx === 0) return "any";
    let count = idx - 1;

    if (user.organizations) {
      const org = user.organizations[count];
      return org ? org.id : "";
    }
  };

  /**
   * Handles changing the tab value flag
   */
  const handleTabValueChange = () => {
    setValueChanged(true);
    setPageCount(1);
  };

  /**
   * Handles changing the tab value flag
   */
  const handleTabChange = () => {
    setTabChanged(true);
  };

  const handleTabUpdate = (newValue) => {
    setSelectedOrg(getUserOrganization(newValue));
    handleTabValueChange();
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (user.work_orders && user.work_orders.length < 1 && pageCount === 0)
      return;
    setBackdropLoading(true);

    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page,
    };

    searchWorkOrders(data);
    window.scrollTo(0, 0);
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const orgIdModel = { ...defaultSearch.models[1] };
    orgIdModel["selected"] = selectedOrg === "any" ? "" : selectedOrg;

    const data = {
      ...defaultSearch,
      models: [...defaultSearch.models, { ...orgIdModel }],
      page_size: Number(pageSize) || Number(defaultSearch.page_size),
      page_count: 1,
    };

    return data;
  };

  /**
   * Handles constructing the data object for the search
   */
  const getSearchParams = (pgCount) => {
    /**
     * Defines the org id model
     */
    const orgIdModel = { ...defaultSearch.models[1] };
    orgIdModel["selected"] = selectedOrg === "any" ? "" : selectedOrg;

    /**
     * Eliminate potential duplicate org_ids objects
     */
    const modelsFiltered = models.filter(
      (model) => model.field !== "organization_id"
    );

    const data = {
      models: [...modelsFiltered, { ...orgIdModel }],
      order_by: inputs.orderBy || defaultSearch.order_by,
      order_dir: inputs.orderDir || defaultSearch.order_dir,
      page_size: Number(pageSize) || Number(defaultSearch.page_size),
      page_count: pgCount || pageCount || 1,
    };

    return data;
  };

  const handleQueryStringSearch = () => {
    /**
     * Defines the org id model
     */
    const orgIdModel = { ...defaultSearch.models[1] };
    orgIdModel["selected"] = selectedOrg === "any" ? "" : selectedOrg;

    /**
     * Eliminate potential duplicate org_ids objects
     */
    const modelsFiltered = models.filter(
      (model) => model.field !== "organization_id"
    );

    const data = {
      models: [
        ...modelsFiltered,
        { ...orgIdModel },
        {
          label: "Work Order ID",
          field: "uuid",
          type: "like",
          order: 6,
          options: [],
          selected: searchedValue,
        },
      ],
      order_by: inputs.orderBy || defaultSearch.order_by,
      order_dir: inputs.orderDir || defaultSearch.order_dir,
      page_size: Number(pageSize) || Number(defaultSearch.page_size),
      page_count: 1,
    };
    
    searchWorkOrders(data);
  };

  useEffect(() => {
    if (searchedValue) {
      handleQueryStringSearch();
    }
    // eslint-disable-next-line
  }, [searchedValue]);

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    searchWorkOrders(getDefaultSearch());
  };

  /**
   * Handles searching for work orders
   */
  const handleSearch = (pgCount) => {
    const data = getSearchParams(pgCount);

    if (Object.keys(data).length > 0) {
      searchWorkOrders(data);
    }
  };

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    setPageSize(e.target.value);
    setUpdated(true);
    window.scrollTo(0, 0);
  };

  /**
   * Handles triggering a search if updated
   */
  useEffect(() => {
    if (updated) handleSearch();
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles performing a search on tab change
   */
  useEffect(() => {
    if (valueChanged) {
      setBackdropLoading(true);
      handleTabChange();
      setValueChanged(false);
    }
  }, [valueChanged]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (viewData) openView();
    // eslint-disable-next-line
  }, [viewData]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEdit();
    // eslint-disable-next-line
  }, [editData]);

  useEffect(() => {
    if (!modalState.editWorkOrder && !isEmpty(editData)) {
      setEditData({});
    }
    // eslint-disable-next-line
  }, [modalState.editWorkOrder]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDelete();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    handleDefaultSearch();
    // eslint-disable-next-line
  }, []);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<AssignmentOutlinedIcon />}
        title={t("titleWorkOrders")}
      />
      <SubmoduleWrapper {...wrapper}>
        <OrganizationsTabs
          {...organizationsTabs}
          triggerChange={handleTabUpdate}
          setBackdropLoading={setBackdropLoading}
        />
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <WorkOrderSearchForm
                  {...searchForm}
                  setInputs={setInputs}
                  setModels={setModels}
                  setTotal={setTotal}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  setPageCount={setPageCount}
                  setBackdropLoading={setBackdropLoading}
                  selectedOrg={selectedOrg}
                  tabChanged={tabChanged}
                  setTabChanged={setTabChanged}
                  setUpdated={setUpdated}
                  resetFilters={resetFilters}
                  setResetFilters={setResetFilters}
                  searchedValue={searchedValue}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.workOrderContainer}>
              <Importer {...importer} />
              <WorkOrdersTable
                {...workOrdersTable}
                handleEdit={handleEdit}
                handleView={handleView}
                handleDelete={handleDelete}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleFiltersReset={handleFiltersReset}
                rowsPerPage={Number(pageSize)}
                page={pageCount}
                count={total}
              />
            </Grid>
            <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
            <ViewWorkOrderModal
              {...viewModal}
              setViewData={setViewData}
              open={modalState.viewWorkOrder}
              closeModal={closeView}
              workOrderID={viewData}
            />
            <EditWorkOrderModal
              {...editModal}
              open={modalState.editWorkOrder}
              closeModal={closeEdit}
              data={editData}
              setEditData={setEditData}
              setUpdated={setUpdated}
            />
            <DeleteWorkOrderModal
              {...deleteModal}
              open={modalState.deleteWorkOrder}
              closeModal={closeDelete}
              data={deleteData}
              setUpdated={setUpdated}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

WorkstationSettingsWorkOrders.propTypes = propTypes;
WorkstationSettingsWorkOrders.defaultProps = defaultProps;

export default WorkstationSettingsWorkOrders;
export {
  propTypes as WorkstationSettingsWorkOrdersPropTypes,
  defaultProps as WorkstationSettingsWorkOrdersDefaultProps,
};

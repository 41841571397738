import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Route, Switch, Redirect } from "react-router-dom";

/**
 * Component Imports
 */
import ReportsSidebar, {
  ReportsSidebarDefaultProps,
  ReportsSidebarPropTypes,
} from "../ReportsSidebar";
import ReportsDaily, {
  ReportsDailyDefaultProps,
  ReportsDailyPropTypes,
} from "../ReportsDaily";
import ReportsMonthly, {
  ReportsMonthlyDefaultProps,
  ReportsMonthlyPropTypes,
} from "../ReportsMonthly";
import ReportsCompanies, {
  ReportsCompaniesDefaultProps,
  ReportsCompaniesPropTypes,
} from "../ReportsCompanies";
import ReportsTyreWaste, {
  ReportsTyreWasteDefaultProps,
  ReportsTyreWastePropTypes,
} from "../ReportsTyreWaste";
import ReportsWorkers, {
  ReportsWorkersDefaultProps,
  ReportsWorkersPropTypes,
} from "../ReportsWorkers";
import ReportsAdvanced, {
  ReportsAdvancedDefaultProps,
  ReportsAdvancedPropTypes,
} from "../ReportsAdvanced";
import PageNotFound, {
  PageNotFoundDefaultProps,
  PageNotFoundPropTypes,
} from "../PageNotFound";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(ReportsSidebarPropTypes),
  daily: PropTypes.shape(ReportsDailyPropTypes),
  monthly: PropTypes.shape(ReportsMonthlyPropTypes),
  companies: PropTypes.shape(ReportsCompaniesPropTypes),
  waste: PropTypes.shape(ReportsTyreWastePropTypes),
  workers: PropTypes.shape(ReportsWorkersPropTypes),
  advanced: PropTypes.shape(ReportsAdvancedPropTypes),
  notFound: PropTypes.shape(PageNotFoundPropTypes),
  path: PropTypes.string,
  paths: PropTypes.shape({
    daily: PropTypes.string,
    monthly: PropTypes.string,
    companies: PropTypes.string,
    waste: PropTypes.string,
    workers: PropTypes.string,
    advanced: PropTypes.string,
    orders: PropTypes.string,
    upgrade: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  sidebar: ReportsSidebarDefaultProps,
  daily: ReportsDailyDefaultProps,
  monthly: ReportsMonthlyDefaultProps,
  companies: ReportsCompaniesDefaultProps,
  waste: ReportsTyreWasteDefaultProps,
  workers: ReportsWorkersDefaultProps,
  advanced: ReportsAdvancedDefaultProps,
  notFound: PageNotFoundDefaultProps,
  path: `/modules/reports`,
  paths: {
    daily: ReportsDailyDefaultProps.path,
    monthly: ReportsMonthlyDefaultProps.path,
    companies: ReportsCompaniesDefaultProps.path,
    waste: ReportsTyreWasteDefaultProps.path,
    workers: ReportsWorkersDefaultProps.path,
    advanced: ReportsAdvancedDefaultProps.path,
  },
};

/**
 * Displays the component
 */
const ReportsPage = (props) => {
  const {
    sidebar,
    daily,
    monthly,
    companies,
    waste,
    workers,
    advanced,
    notFound,
    path: basePath,
    paths,
  } = props;

  /**
   * Gets the paths from the components
   */
  const {
    daily: dailyPath,
    monthly: monthlyPath,
    companies: companiesPath,
    waste: wastePath,
    workers: workersPath,
    advanced: advancedPath,
    orders: ordersPath,
    upgrade: upgradePath,
  } = paths;

  /**
   * Defines the path building function
   * Attaches the base path of the module to each individual path
   */
  const buildPath = (path) => `${basePath}${path}`;

  /**
   * Defines and builds the paths
   */
  const buildedPaths = {
    daily: buildPath(dailyPath),
    monthly: buildPath(monthlyPath),
    companies: buildPath(companiesPath),
    waste: buildPath(wastePath),
    workers: buildPath(workersPath),
    advanced: buildPath(advancedPath),
    orders: buildPath(ordersPath),
    upgrade: buildPath(upgradePath),
  };

  return (
    <Fragment>
      <ReportsSidebar {...sidebar} paths={buildedPaths} />
      <Switch>
        <Route exact path={basePath}>
          <Redirect to={buildedPaths.daily} />
        </Route>
        <Route exact path={buildedPaths.daily}>
          <ReportsDaily {...daily} />
        </Route>
        <Route exact path={buildedPaths.monthly}>
          <ReportsMonthly {...monthly} />
        </Route>
        <Route exact path={buildedPaths.companies}>
          <ReportsCompanies {...companies} />
        </Route>
        <Route exact path={buildedPaths.waste}>
          <ReportsTyreWaste {...waste} />
        </Route>
        <Route exact path={buildedPaths.workers}>
          <ReportsWorkers {...workers} />
        </Route>
        <Route exact path={buildedPaths.advanced}>
          <ReportsAdvanced {...advanced} />
        </Route>
        <Route>
          <PageNotFound {...notFound} />
        </Route>
      </Switch>
    </Fragment>
  );
};

ReportsPage.propTypes = propTypes;
ReportsPage.defaultProps = defaultProps;

export default ReportsPage;
export {
  propTypes as ReportsPagePropTypes,
  defaultProps as ReportsPageDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./SubmoduleTitle.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: "",
  icon: null,
  className: "",
};

/**
 * Displays the component
 */
const SubmoduleTitle = (props) => {
  const { title, icon, className } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the component classes
   */
  const titleClasses = clsx(classes.title, { [className]: !!className });

  /**
   * Handles rendering the icon
   */
  const renderIcon = () => (icon ? icon : null);

  return (
    <div className={titleClasses}>
      {renderIcon()}
      <Typography variant="h1">{title}</Typography>
    </div>
  );
};

SubmoduleTitle.propTypes = propTypes;
SubmoduleTitle.defaultProps = defaultProps;

export default SubmoduleTitle;
export {
  propTypes as SubmoduleTitlePropTypes,
  defaultProps as SubmoduleTitleDefaultProps,
};

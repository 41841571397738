import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import { ro } from "date-fns/locale";
import {
  addMinutes,
  subMinutes,
  format as formatDate,
  formatDistanceToNow,
  differenceInSeconds,
} from "date-fns";

/**
 * Imports i18n
 */

import { useTranslation } from "react-i18next";

/**
 * Scheduler Imports
 */
import {
  ViewState,
  EditingState,
  IntegratedEditing,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  DayView,
  Toolbar,
  DateNavigator,
  TodayButton,
  Appointments,
  AppointmentTooltip,
  DragDropProvider,
  GroupingPanel,
  Resources,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";

/**
 * Component Imports
 */
import Input, { InputDefaultProps, InputPropTypes } from "../Input";
import AddAppointmentModal, {
  AddAppointmentModalDefaultProps,
  AddAppointmentModalPropTypes,
} from "../AddAppointmentModal";
import EditAppointmentModal, {
  EditAppointmentModalDefaultProps,
  EditAppointmentModalPropTypes,
} from "../EditAppointmentModal";
import DeleteAppointmentModal, {
  DeleteAppointmentModalDefaultProps,
  DeleteAppointmentModalPropTypes,
} from "../DeleteAppointmentModal";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import DataTable, {
  DataTableDefaultProps,
  DataTablePropTypes,
} from "../DataTable";
import SchedulerStatusChanger, {
  SchedulerStatusChangerDefaultProps,
  SchedulerStatusChangerPropTypes,
} from "../SchedulerStatusChanger";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TodayIcon from "@material-ui/icons/Today";
import ScheduleIcon from "@material-ui/icons/Schedule";

/**
 * Hooks
 */
import { useUser, useApiClient, useMessage } from "../../hooks";

/**
 * Imports the component styles
 */
import { useStyles } from "./AppointmentsScheduler.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  statusChanger: PropTypes.shape(SchedulerStatusChangerPropTypes),
  input: PropTypes.shape(InputPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  dataTable: PropTypes.shape(DataTablePropTypes),
  editButton: PropTypes.shape(EditButtonPropTypes),
  delModal: PropTypes.shape(DeleteAppointmentModalPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  addModal: PropTypes.shape(AddAppointmentModalPropTypes),
  editModal: PropTypes.shape(EditAppointmentModalPropTypes),
  defaultActiveView: PropTypes.string,
  viewOnly: PropTypes.bool,
  allowChangeView: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  statusChanger: SchedulerStatusChangerDefaultProps,
  input: InputDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  dataTable: DataTableDefaultProps,
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  addModal: AddAppointmentModalDefaultProps,
  editModal: EditAppointmentModalDefaultProps,
  delModal: DeleteAppointmentModalDefaultProps,
  defaultActiveView: "Work Week",
  viewOnly: false,
  allowChangeView: true,
};

/**
 * Displays the component
 */
const AppointmentsScheduler = (props) => {
  const {
    errorMessages,
    loadingBackdrop,
    statusChanger,
    input,
    dataTable,
    editButton,
    deleteButton,
    addModal,
    editModal,
    delModal,
    defaultActiveView,
    viewOnly,
    allowChangeView,
    loading,
    setLoading,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the organizations list
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes all appointments
   */
  const [allAppointments, setAllAppointments] = useState([]);

  /**
   * Initializes the withGrouping flag
   */
  const [withGrouping, setWithGrouping] = useState(false);

  /**
   * Initializes the appointments state
   */
  const [data, setData] = useState([]);

  /**
   * Initializes the active view
   */
  const [activeView, setActiveView] = useState(defaultActiveView);

  /**
   * Initializes the loading state
   */
  const [backdropLoading, setBackdropLoading] = useState(false);

  /**
   * Initializes the init mount
   */
  const [initMount, setInitMount] = useState(true);

  /**
   * Initializes the update flag
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the appointment groups
   */
  const [appointmentGroups, setAppointmentGroups] = useState([]);

  /**
   * Initializes the resources
   */
  const [resources, setResources] = useState([]);

  /**
   * Initializes the grouping
   */
  const [grouping, setGrouping] = useState([]);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  /**
   * Initializes the current date
   */
  const [currentDate, setCurrentDate] = useState(new Date());

  /**
   * Initializes the state that holds any newly added appointment
   */
  const [addedAppointment, setAddedAppointment] = useState({});

  /**
   * Initializes the add modal data
   */
  const [addData, setAddData] = useState({});

  /**
   * Initializes the edit modal data
   */
  const [editData, setEditData] = useState({});

  /**
   * Initializes the delete modal data
   */
  const [deleteData, setDeleteData] = useState({});

  /**
   * Initializes the flag that indicates if any appointments have been deleted
   */
  const [appointmentDeleted, setAppointmentDeleted] = useState(false);

  /**
   * Initializes the appointment being created flag
   */
  const [isAppointmentBeingCreated, setIsAppointmentBeingCreated] =
    useState(false);

  /**
   * Initializes the active resources
   */
  const [activeResources, setActiveResources] = useState([]);

  /**
   * Initializes the active resource options
   */
  const [activeResourcesOptions, setActiveResourcesOptions] = useState([]);

  /**
   * Defines the scheduler views
   */
  const views = [
    { value: "Day", label: t("day") },
    { value: "Work Week", label: t("work_week") },
  ];

  /**
   * Handles updating the appointment
   */
  const updateAppointment = async (data, id) => {
    try {
      const updatedAppointment = await apiClient.put(
        `/appointments/${id}`,
        data
      );
      if (updatedAppointment) {
        /**
         * Handles dispatching the error message
         */
        dispatchMessage({
          severity: "success",
          message: t("successMessage"),
        });
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Gets the appointments
   */
  const getAppointments = async () => {
    try {
      const result = await apiClient.get("/appointments/search/calendar");
      if (result && result.data) {
        const formattedAppointments = formatAppointments(result.data);
        setUpdated(false);
        setLoading(false);
        const filteredAppointments = formattedAppointments.filter((item) => {
          if (activeOrg === "all") return true;

          return item.organization_id.toString() === activeOrg.toString();
        });

        const _appointmentGroups = user.appointmentGroups
          ? user.appointmentGroups.filter(
              (group) =>
                activeOrg === "all" ||
                group.organization_id.toString() === activeOrg.toString()
            )
          : [];

        const grouplessAppointments = filteredAppointments.filter(
          (appointment) => appointment.appointment_group_id === null
        );

        const appointmentGroups = [
          {
            description: null,
            id: null,
            name: t("groupless"),
            organization_id: null,
            short_name: "",
          },
          ..._appointmentGroups,
        ];

        if (grouplessAppointments.length < 1) appointmentGroups.shift();

        const resources = appointmentGroups.map((group) => {
          return {
            text: group.name,
            id: group.id,
          };
        });

        setActiveResourcesOptions(resources);
        setAllAppointments(formattedAppointments);
        setActiveResources(resources);

        setData(filteredAppointments);
        setBackdropLoading(false);
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
      setBackdropLoading(false);
    }
  };

  const handleGroupingChange = () => {
    setWithGrouping((prevState) => !prevState);
  };

  /**
   * Handles formatting the appointments data model
   * @param {Array} appointments
   */
  const formatAppointments = (appointments) => {
    const timezoneOffsetDate = new Date();
    const offset = Math.abs(timezoneOffsetDate.getTimezoneOffset());

    const newAppointments = appointments.map((appointment) => {
      return {
        ...appointment,
        startDate: subMinutes(new Date(appointment.from), offset),
        endDate: subMinutes(new Date(appointment.to), offset),
        title: appointment.client_name,
      };
    });
    return newAppointments;
  };

  /**
   * Handles changing the scheduler's current date
   * @param {String} currentDate
   */

  const handleDateChange = (currentDate) => setCurrentDate(currentDate);

  /**
   * Handles viewing a specific date in Day mode
   * @param {String} date
   */
  const handleViewDate = (date) => {
    if (allowChangeView) {
      handleDateChange(date);
      setActiveView("Day");
    }
  };

  /**
   * Handles updating the scheduler's view
   * @param {Event} event
   */
  const handleViewChange = (event) => {
    if (allowChangeView) {
      setActiveView(event.target.value);
    }
  };

  /**
   * Handles getting the data of the changed appointment
   * @param {Number} id
   */
  const getChangedData = (id) => {
    const appointmentData = data.find((appointment) => {
      return appointment.id.toString() === id.toString();
    });
    return appointmentData
      ? {
          car_plate_number: appointmentData.car_plate_number,
          client_name: appointmentData.client_name,
        }
      : {};
  };

  /**
   * Handles getting the distance to now
   * @param {Date} time
   */
  const handleTimeSpent = (time) => {
    let diffInSeconds = differenceInSeconds(new Date(), new Date(time));
    if (diffInSeconds < 60) {
      return `${t("now")} 1 minut.`;
    } else {
      return `${t("now")} ${formatDistanceToNow(new Date(time), {
        locale: ro,
      })}`;
    }
  };

  /**
   * Handles updating the active organization
   * @param {Event} event
   */
  const handleOrganizationChange = (event) => setActiveOrg(event.target.value);

  /**
   * Handles opening the add appointment modal
   * @param {Object} props
   */
  const handleOpenAdd = (props) => setAddData(props);

  /**
   * Handles opening the edit appointment modal
   * @param {Object} props
   */
  const handleOpenEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete appointment modal
   * @param {Object} props
   */
  const handleOpenDelete = (props) => setDeleteData(props);

  /**
   * Handles closing all the modals
   */
  const closeModal = () => {
    setModalState({ add: false, edit: false, delete: false });
  };

  /**
   * Handles rendering the appointment tooltip
   */
  const renderTooltip = useCallback(() => {
    const getTooltipProps = () => {
      const props = {
        headerComponent: AppointmentHeader,
        layoutComponent: AppointmentTooltipLayout,
        contentComponent: AppointmentTooltipContent,
        showOpenButton: true,
        showDeleteButton: true,
      };

      if (modalState.delete || updated || modalState.add || modalState.edit) {
        props["visible"] = false;
      }
      return props;
    };

    return <AppointmentTooltip {...getTooltipProps()} />;
    // eslint-disable-next-line
  }, [appointmentDeleted, updated, modalState]);

  /**
   * Handles commiting the changes to the appointments
   */
  const onCommitChanges = useCallback(
    ({ added, changed, deleted }) => {
      if (added) {
        setData(formatAppointments([...data, { ...added }]));
      }
      if (changed) {
        const changedID = Object.keys(changed)[0];
        const changedData = Object.values(changed)[0];
        const timezoneOffsetDate = new Date();
        const offset = Math.abs(timezoneOffsetDate.getTimezoneOffset());

        const formData = {
          ...getChangedData(changedID),
          ...changedData,
          startDate: addMinutes(new Date(changedData.startDate), offset),
          endDate: addMinutes(new Date(changedData.endDate), offset),
          from: addMinutes(new Date(changedData.startDate), offset),
          to: addMinutes(new Date(changedData.endDate), offset),
        };

        updateAppointment(formData, changedID);

        setData(
          data.map((appointment) =>
            changed[appointment.id]
              ? { ...appointment, ...changed[appointment.id] }
              : appointment
          )
        );
      }
      if (deleted !== undefined) {
        setData(data.filter((appointment) => appointment.id !== deleted));
        setAllAppointments(
          allAppointments.filter((appointment) => appointment.id !== deleted)
        );
      }
    },
    // eslint-disable-next-line
    [setData, isAppointmentBeingCreated, data, addedAppointment]
  );

  /**
   * Handles pre-commiting the newly added appointment
   */
  const onAddedAppointmentChange = useCallback((appointment) => {
    setAddedAppointment(appointment);
    setIsAppointmentBeingCreated(true);
    // eslint-disable-next-line
  }, []);

  /**
   * Customizes the Table Cell
   */
  const TimeTableCell = useCallback(
    memo((props) => {
      const { isShaded } = props;
      /**
       * Handles opening the add appointment modal
       */
      const handleClick = () => {
        if (!viewOnly && !isShaded) handleOpenAdd(props);
      };

      return (
        <WeekView.TimeTableCell
          {...props}
          onClick={handleClick}
          onDoubleClick={handleClick}
          className={clsx(classes.timeTableCell, {
            [classes.shadedCell]: isShaded,
          })}
        />
      );
    }),
    []
  );

  /**
   * Customizes the Time Indicator
   */
  const TimeIndicator = ({ top, ...restProps }) => {
    const classes = useStyles({ top });
    return (
      <div {...restProps}>
        <div className={clsx(classes.nowIndicator, classes.circle)} />
        <div className={clsx(classes.nowIndicator, classes.line)} />
      </div>
    );
  };

  /**
   * Customizes the Appointment Toolbar
   */
  const AppointmentToolbar = useCallback(
    memo((props) => {
      return <Toolbar.Root {...props} className={classes.toolbar} />;
    }),
    []
  );

  /**
   * Customizes the Appointment Header
   */
  const AppointmentHeader = useCallback(
    (props) => {
      const { appointmentData } = props;
      const { client_name, status } = appointmentData;

      const handleEdit = () => {
        if (!viewOnly) {
          handleOpenEdit(props);
        }
      };
      const handleDelete = () => {
        if (!viewOnly) {
          handleOpenDelete(props);
        }
      };

      return (
        <div className={classes.header}>
          <div className={classes.clientName}>
            <div
              className={clsx(classes.circlePrimary, {
                [classes.circleSecondary]: status === "in-progress",
                [classes.circleSuccess]: status === "done",
                [classes.circleError]: status === "skipped",
              })}
            />
            <Typography variant="caption" className={classes.clientNameText}>
              {client_name}
            </Typography>
          </div>

          {!viewOnly && (
            <div className={classes.actions}>
              <SchedulerStatusChanger
                {...statusChanger}
                data={appointmentData}
                setUpdated={setUpdated}
                disabled={viewOnly}
              />

              <EditButton
                {...editButton}
                className={classes.editSmall}
                onClick={handleEdit}
                title={t("editAppointment")}
                disabled={viewOnly}
              />
              <DeleteButton
                {...deleteButton}
                className={classes.deleteButton}
                onClick={handleDelete}
                title={t("deleteAppointment")}
                disabled={viewOnly}
              />
            </div>
          )}
        </div>
      );
    },
    // eslint-disable-next-line
    [data]
  );

  /**
   * Customizes the Appointment Tooltip Layout
   */
  const AppointmentTooltipLayout = useCallback(
    (props) => {
      return (
        <AppointmentTooltip.Layout {...props} className={classes.layout} />
      );
    },
    // eslint-disable-next-line
    [data]
  );

  /**
   * Customizes the Appointment Date Navigation Overlay
   */
  const AppointmentDateNavigation = useCallback(
    (props) => {
      return <DateNavigator.Overlay {...props} className={classes.overlay} />;
    },
    // eslint-disable-next-line
    [data]
  );

  /**
   * Customizes the Appointment Tooltip Content
   */
  const AppointmentTooltipContent = useCallback(
    (props) => {
      const { appointmentData } = props;
      const {
        created_at,
        created_by,
        startDate,
        endDate,
        car_plate_number,
        car_model,
        phone,
        description,
        requested_services,
      } = appointmentData;

      if (Object.keys(appointmentData).length < 1) return null;

      const from = formatDate(
        new Date(startDate ? startDate : new Date()),
        "eeee, dd LLLL yyyy",
        {
          locale: ro,
        }
      );

      const timeRange = `${formatDate(
        new Date(startDate ? startDate : new Date()),
        "HH:mm",
        {
          locale: ro,
        }
      )} - ${formatDate(new Date(endDate ? endDate : new Date()), "HH:mm", {
        locale: ro,
      })}`;

      /**
       * Defines the car data
       */
      const render = true;

      const displayData = [
        { label: t("carPlate"), value: car_plate_number, render },
        { label: t("carModel"), value: car_model, render },
        { label: t("phone"), value: phone, render },
        { label: t("description"), value: description, render },
      ];

      if (requested_services && requested_services.length > 0) {
        displayData.push({
          label: t("requested_services"),
          value: (
            <ul>
              {requested_services &&
                requested_services.length > 0 &&
                requested_services.map((service, idx) => (
                  <li
                    key={`${service.id}_${idx}`}
                    className={classes.serviceItem}
                  >
                    {service.name}
                  </li>
                ))}
            </ul>
          ),
          render,
        });
      }

      return (
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.time}
            >
              <Grid item xs={12}>
                <div className={classes.sectionItem}>
                  <TodayIcon />
                  <Typography>{from}</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.sectionItem}>
                  <ScheduleIcon />
                  <Typography>{timeRange}</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <DataTable
                  {...dataTable}
                  data={displayData}
                  className={classes.spacing}
                  classes={{
                    label: classes.tableFont,
                    value: classes.tableFont,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            <Typography variant="caption" className={classes.createdBy}>
              {t("createdBy")}{" "}
              {created_by ? <span>{created_by.username}</span> : ""}{" "}
              {handleTimeSpent(created_at)}
            </Typography>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line
    [data]
  );

  /**
   * Customizes the Appointment Component
   */
  const Appointment = ({ children, style, ...restProps }) => {
    const { data, isDragging, onClick, isShaded } = restProps;

    const { car_plate_number, startDate, endDate, description, status } = data;

    const timeRange = `${formatDate(new Date(startDate), "HH:mm", {
      locale: ro,
    })} - ${formatDate(new Date(endDate), "HH:mm", {
      locale: ro,
    })}`;

    function truncateString(str, num) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (str.length <= num) {
        return str;
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num);
    }

    return (
      <Appointments.Appointment
        data={data}
        draggable={restProps.draggable}
        resources={restProps.resources}
        onClick={onClick}
        onDoubleClick={() => {}}
        className={clsx(classes.appointment, {
          [classes.shadedAppointment]: isShaded,
          [classes.appointmentProgress]: status === "in-progress",
          [classes.appointmentDone]: status === "done",
          [classes.appointmentSkipped]: status === "skipped",
        })}
        style={{
          ...style,
          width: "104%",
          maxWidth: 210,
          borderRadius: 3,
          opacity: isDragging ? 0.4 : 1,
        }}
      >
        <div className={classes.appointmentContainer}>
          <div className={classes.main}>
            <Typography variant="caption" className={classes.mainClientName}>
              <span>{car_plate_number}</span>
            </Typography>
            <Typography variant="caption" className={classes.timeRange}>
              <ScheduleIcon />
              <span>{timeRange}</span>
            </Typography>
            {description && description.length > 0 && (
              <Typography variant="caption" className={classes.extrainfo}>
                {truncateString(description, 5)}
              </Typography>
            )}
          </div>
        </div>
      </Appointments.Appointment>
    );
  };

  /**
   * Customizes the Week Cells
   * @param {Object} props
   */
  const DayScaleCellBase = (props) => {
    const { startDate, today } = props;
    if (today) {
      return (
        <WeekView.DayScaleCell
          {...props}
          className={classes.weekDate}
          onClick={() => handleViewDate(props.startDate)}
        />
      );
    }
    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
      return (
        <WeekView.DayScaleCell
          {...props}
          className={classes.weekDate}
          onClick={() => handleViewDate(props.startDate)}
        />
      );
    }
    return (
      <WeekView.DayScaleCell
        {...props}
        className={classes.weekDate}
        onClick={() => handleViewDate(props.startDate)}
      />
    );
  };

  /**
   * Customize the Empty Day Cells
   * @param {Object} props
   */
  const DayScaleEmptyCell = useCallback(
    memo((props) => {
      return (
        <WeekView.DayScaleEmptyCell {...props} className={classes.emptyCell} />
      );
    }),
    [withGrouping]
  );

  /**
   * Defines the Source Appointment - component used for the dragging
   * @param {Object} props
   */
  const SourceAppointment = (props) => {
    return <Appointment {...props} isDragging={true} />;
  };

  /**
   * Defines the Drag Appointment - component used for the dragging
   * @param {Object} props
   */
  const DragAppointment = (props) => {
    return <Appointment {...props} />;
  };

  /**
   * Defines the custom today button
   * @param {Object} props
   */
  const CustomTodayButton = (props) => {
    const handleClick = () => {
      setCurrentDate(new Date());
      setActiveView("Day");
    };
    return <TodayButton.Button {...props} setCurrentDate={handleClick} />;
  };

  /**
   * Customizes the Table Cell
   */
  const CustomScheduler = useCallback(
    memo(() => {
      return grouping.length > 0 ? (
        <Scheduler data={data} locale="ro-RO">
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={handleDateChange}
            currentViewName={activeView}
          />
          <EditingState
            onCommitChanges={onCommitChanges}
            addedAppointment={addedAppointment}
            onAddedAppointmentChange={onAddedAppointmentChange}
          />
          {withGrouping && grouping.length > 0 && (
            <GroupingState
              grouping={grouping}
              groupOrientation={() => "Vertical"}
            />
          )}

          <WeekView
            name="Work Week"
            excludedDays={[0]}
            startDayHour={7}
            endDayHour={19}
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCellBase}
            dayScaleEmptyCellComponent={DayScaleEmptyCell}
          />
          <DayView
            dayScaleCellComponent={DayScaleCellBase}
            timeTableCellComponent={TimeTableCell}
            startDayHour={7}
            endDayHour={19}
          />

          <Toolbar rootComponent={AppointmentToolbar} />
          <DateNavigator overlayComponent={AppointmentDateNavigation} />
          <TodayButton
            messages={{ today: t("today") }}
            buttonComponent={CustomTodayButton}
          />
          <Appointments appointmentComponent={Appointment} />
          {withGrouping && grouping.length > 0 && (
            <Resources
              data={resources}
              mainResourceName={grouping.resourceName}
            />
          )}
          <IntegratedEditing />
          {renderTooltip()}

          {withGrouping && grouping.length > 0 && <IntegratedGrouping />}
          {withGrouping && grouping.length > 0 && <GroupingPanel />}

          <DragDropProvider
            draftAppointmentComponent={DragAppointment}
            sourceAppointmentComponent={SourceAppointment}
            allowDrag={() => (viewOnly ? false : true)}
            allowResize={() => (viewOnly ? false : true)}
          />
          <CurrentTimeIndicator
            shadePreviousCells={true}
            shadePreviousAppointments={true}
            updateInterval={60000}
            indicatorComponent={TimeIndicator}
          />
        </Scheduler>
      ) : null;
    }),
    [withGrouping, data, activeView, currentDate, grouping, resources]
  );

  /**
   * Gets the appointments when the data has been updated
   */
  useEffect(() => {
    if (
      updated &&
      !backdropLoading &&
      !initMount &&
      activeOrg &&
      user.appointmentGroups
    ) {
      setBackdropLoading(true);
      getAppointments();
    }
    // eslint-disable-next-line
  }, [updated, activeOrg, user]);

  /**
   * Handles the initial request for the appointments
   */
  useEffect(() => {
    if (initMount && activeOrg && user.appointmentGroups) {
      setInitMount(false);
      setBackdropLoading(true);
      getAppointments();
    }
    // eslint-disable-next-line
  }, [initMount, activeOrg, user]);

  useEffect(() => {
    if (activeResources.length > 0) {
      setResources([
        {
          fieldName: "appointment_group_id",
          title: t("appointmentGroup"),
          instances: activeResources,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [activeResources]);

  useEffect(() => {
    if (user.appointmentGroups) {
      const _appointmentGroups = user.appointmentGroups.sort(
        (a, b) => b.name - a.name
      );

      const appointmentGroups = [
        {
          description: null,
          id: null,
          name: t("groupless"),
          organization_id: null,
          short_name: "",
        },
        ..._appointmentGroups.filter(
          (group) => group.organization_id === user.organization.id
        ),
      ];

      const resources = appointmentGroups.map((group, idx) => {
        return {
          text: group.name,
          id: group.id,
        };
      });

      setActiveResourcesOptions(resources);

      setResources([
        {
          fieldName: "appointment_group_id",
          title: t("appointmentGroup"),
          instances: resources,
        },
      ]);
      setGrouping([{ resourceName: "appointment_group_id" }]);
      setAppointmentGroups([
        {
          name: t("none"),
          id: "none",
        },
        ...user.appointmentGroups,
      ]);
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Handles opening the add appointment modal
   */
  useEffect(() => {
    if (Object.keys(addData).length > 0) {
      setModalState({ add: true, edit: false, delete: false });
    }
    // eslint-disable-next-line
  }, [addData]);

  /**
   * Handles opening the edit appointment modal
   */
  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      setModalState({ add: false, edit: true, delete: false });
    }
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete appointment modal
   */
  useEffect(() => {
    if (Object.keys(deleteData).length > 0) {
      setModalState({ add: false, edit: false, delete: true });
    }
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Handles resetting the appointment deleted flag
   */
  useEffect(() => {
    if (appointmentDeleted) {
      setTimeout(() => {
        setAppointmentDeleted(false);
      }, 300);
    }
  }, [appointmentDeleted]);

  /**
   * Handles filtering the appointments based on the active organization
   */
  useEffect(() => {
    if (activeOrg && !backdropLoading && !initMount && user.appointmentGroups) {
      setBackdropLoading(true);
      getAppointments();
    }
    // eslint-disable-next-line
  }, [activeOrg, user]);

  /**
   * Updates the active organization and the organizations list
   */
  useEffect(() => {
    const { organization, organizations } = user;

    if (organization) setActiveOrg(organization.id);
    if (organizations) {
      const list = [
        {
          name: t("all"),
          id: "all",
        },
        ...organizations,
      ];
      setOrganizations(list);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleAppointmentGroupsFilter = (e, newValue) => {
    if (newValue.length < 1) {
      if (
        activeResources.length === 1 &&
        activeResources[0].text === activeResourcesOptions[0].text
      )
        return;

      setActiveResources([
        {
          ...activeResourcesOptions[0],
          name:
            activeResourcesOptions[0].text || activeResourcesOptions[0].name,
          text:
            activeResourcesOptions[0].text || activeResourcesOptions[0].name,
        },
      ]);
    } else {
      setActiveResources(
        newValue
          .map((item) => {
            return {
              ...item,
              name: item.text || item.name,
              text: item.text || item.name,
            };
          })
          .sort((a, b) => a.id - b.id)
      );
    }
  };

  if (loading) return <> </>;

  return (
    <Grid container spacing={1}>
      <Grid item container xs={12}>
        <Grid container item xs={12}>
          <Grid container item xs={12} md={6} spacing={1}>
            {user.type === "admin" && organizations.length > 0 && (
              <Grid item xs={12} md={4} lg={4} xl={5}>
                <Input
                  {...input}
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    value: activeOrg,
                    onChange: handleOrganizationChange,
                    label: t("organization_idLabel"),
                    labelClass: classes.label,
                    variant: "outlined",
                    options: organizations,
                    optionLabel: "name",
                    optionValue: "id",
                  }}
                />
              </Grid>
            )}
            {allowChangeView && (
              <Grid item xs={12} md={4} lg={4} xl={3}>
                <Input
                  {...input}
                  className={classes.selectField}
                  type="select"
                  inputSelect={{
                    value: activeView,
                    onChange: handleViewChange,
                    label: t("views"),
                    labelClass: classes.label,
                    variant: "outlined",
                    options: views,
                    optionLabel: "label",
                    optionValue: "value",
                    showHelper: false,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4} className={classes.checkboxContainer}>
              <Input
                {...input}
                type="checkbox"
                className={classes.checkbox}
                inputCheckbox={{
                  id: "withGrouping",
                  name: "withGrouping",
                  value: withGrouping,
                  onChange: handleGroupingChange,
                  variant: "standard",
                  label: t("withGroupingLabel"),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {withGrouping && (
          <Grid item xs={12} md={6}>
            <Input
              {...input}
              type="autocomplete"
              className={classes.field}
              inputAutocomplete={{
                id: "activeResources",
                multiple: true,
                limitTags: 4,
                freeSolo: false,
                fullObjectValue: true,
                name: "activeResources",
                value: activeResources,
                onChange: handleAppointmentGroupsFilter,
                variant: "outlined",
                label: t("groups"),
                placeholder: t("groupsPlaceholder"),
                options: activeResourcesOptions,
                showHelper: false,
                renderOption: (option) => (option.text ? option.text : ""),
                getOptionLabel: (option) => (option.text ? option.text : ""),
                getOptionSelected: (option, value) => {
                  return option.id === value.id;
                },
              }}
            />
          </Grid>
        )}

        <Paper
          className={clsx(classes.scheduler, {
            [classes.noHeightLimit]: viewOnly || activeView === "Day",
          })}
        >
          <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
          <CustomScheduler />

          <AddAppointmentModal
            {...addModal}
            open={modalState.add}
            closeModal={closeModal}
            onAddedAppointmentChange={onAddedAppointmentChange}
            setAddedAppointment={setAddedAppointment}
            setIsAppointmentBeingCreated={setIsAppointmentBeingCreated}
            addData={addData}
            setAddData={setAddData}
            data={data}
            setData={setData}
            setUpdated={setUpdated}
            appointmentGroups={appointmentGroups.filter(
              (appointment) => appointment.id !== "none"
            )}
          />
          <EditAppointmentModal
            {...editModal}
            open={modalState.edit}
            closeModal={closeModal}
            onAddedAppointmentChange={onAddedAppointmentChange}
            setAddedAppointment={setAddedAppointment}
            setIsAppointmentBeingCreated={setIsAppointmentBeingCreated}
            editData={editData}
            setEditData={setEditData}
            setData={setData}
            setUpdated={setUpdated}
            appointmentGroups={appointmentGroups}
          />
          <DeleteAppointmentModal
            {...delModal}
            open={modalState.delete}
            closeModal={closeModal}
            deleteData={deleteData.appointmentData}
            data={data}
            setData={setData}
            setDeleteData={setDeleteData}
            setAppointmentDeleted={setAppointmentDeleted}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

AppointmentsScheduler.propTypes = propTypes;
AppointmentsScheduler.defaultProps = defaultProps;

export default AppointmentsScheduler;
export {
  propTypes as AppointmentsSchedulerPropTypes,
  defaultProps as AppointmentsSchedulerDefaultProps,
};

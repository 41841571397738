/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    footer: {
        ...theme.mixins.flex.rowCenter,
        background: theme.palette.primary.main,
        fontFamily: theme.font.family,
        color: theme.palette.common.white,
        flex: 1,
        fontSize: "1.1rem",
        padding: 0,
        width: "100%",
        minHeight: 30,
        zIndex: 1200,
        position: "relative",
        bottom: 0,
        alignSelf: "flex-end",
    },
    footerItems: {
        display: "flex",
        flex: 4,
        alignItems: "center"
    },
    copyRight: {
        display: "flex",
        alignItems: "center"
    },
    footerItem: {
        fontSize: "0.85rem",
        fontFamily: theme.font.family,
        fontWeight: 800,
        color: theme.palette.common.white,
        padding: "0.5rem",
        display: "flex",
        alignItems: "center"
    },
    copyRightText: {
        fontSize: "0.85rem",
        fontFamily: theme.font.family,
        fontWeight: 800,
        color: theme.palette.common.white,
        padding: "0.5rem"
    },
    companyInfo: {
        display: "flex",
        flex: 1
    },
    info: {
        fontSize: "0.85rem",
        fontFamily: theme.font.family,
        fontWeight: 800,
        color: theme.palette.common.white,
        padding: "0.5rem",
        display: "flex",
        alignItems: "center"
    },
    infoIcon: {
        marginRight: "0.25rem",
        color: theme.palette.common.white,
    },
    popper: {
        marginTop: -5
    },
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        border: "1px solid #929292",
        color: theme.palette.common.white,
        fontSize: "1rem",
        maxWidth: "none"
    },
    copyright: {
        fontWeight: 800,
        fontSize: "0.85rem",
        color: theme.palette.common.white,
    },
    links: {
        display: "flex",
        // flex: 1,
        flexWrap: "wrap",
        "& .MuiTypography-root": {
            margin: "0 0.5rem",
            fontWeight: 800,
            fontSize: "0.85rem",
            color: theme.palette.common.white,
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.hover.main
            }
        },
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        },
        [theme.breakpoints.only("xs")]: {
            justifyContent: "center",
            flexDirection: "row"
        }
    },
    footerItemsContainer: {
        justifyContent: "space-between",
        padding: "10px 30px"
    }
}));

export { useStyles };

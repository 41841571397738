import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import shortid from "shortid";

/**
 * Material UI Imports
 */
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./TableSearchInput.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.any,
  disabled: PropTypes.bool,
  noValidate: PropTypes.bool,
  debounce: PropTypes.bool,
  debounceTime: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  validate: PropTypes.array,
  hasToMatch: PropTypes.any,
  palette: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    adornment: PropTypes.string,
    adornmentText: PropTypes.string,
    input: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sufix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preventScrollTopOnSearch: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: "",
  value: "",
  name: "",
  label: "",
  placeholder: "",
  helperText: "",
  required: false,
  fullWidth: true,
  autoFocus: false,
  disabled: false,
  noValidate: false,
  debounce: false,
  debounceTime: 500,
  min: 0,
  max: 255,
  onChange: () => {},
  onError: () => {},
  validate: [],
  hasToMatch: null,
  palette: "default",
  classes: {
    root: "",
    adornment: "",
    adornmentText: "",
    input: "",
    borderColor: "",
  },
  prefix: null,
  sufix: null,
  preventScrollTopOnSearch: false,
};

/**
 * Displays the component
 */
const TableSearchInput = (props) => {
  const {
    id,
    value,
    name,
    label,
    placeholder,
    required,
    fullWidth,
    autoFocus,
    disabled,
    noValidate,
    debounce,
    debounceTime,
    max,
    onChange,
    classes,
    prefix,
    sufix,
    preventScrollTopOnSearch,
  } = props;

  /**
   * Gets the component styles
   */
  const _classes = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Initializes the local input state
   */
  const [input, setInput] = useState(value);

  /**
   * Initializes the event state
   * Used to store the debounced event for later use
   */
  const [event, setEvent] = useState("");

  /**
   * Initializes the input verified flag
   * Used to update the useForm inputs and trigger the submit function
   */
  const [inputVerified, setInputVerified] = useState(false);

  /**
   * Initializes the shrink state
   */
  const [shrink, setShrink] = useState(false);

  /**
   * Initializes the focus state
   */
  const [focused, setFocused] = useState(false);

  /**
   * Handles updating the form input on Enter
   */
  const handleKeyPress = (e) => {
    if (!preventScrollTopOnSearch) {
      window.scrollTo(0, 0);
    }

    e.key === "Enter" && input && onChange(event);
  };

  /**
   * Handles generating a random string
   */
  const generateRandomString = () => shortid.generate();

  /**
   * Handles getting the input ID
   */
  const getInputID = () => {
    if (id) return id;
    if (noValidate && !id) return generateRandomString();
    return name ? name : generateRandomString();
  };

  /**
   * Gets the input value based on type
   */
  const getInputValue = (target, type) => {
    switch (type) {
      case "text":
        return target.value;
      case "checkbox":
        return target.checked;
      default:
        return target.value;
    }
  };

  /**
   * Handles getting the autoFocus flag
   */
  const isFocused = () => (name ? autoFocus[name] : false);

  /**
   * Handles updating the focused state
   */
  const handleOnFocus = () => setFocused(true);

  /**
   * Handles resetting the focused state
   */
  const handleOnBlur = () => setFocused(false);

  /**
   * Checks if input is a string
   * @param {any} input
   */
  const isString = (input) => typeof input === "string";

  /**
   * Displays the value
   */
  const displayValue = (value) => (value ? value : "");

  /**
   * Checks initial value
   */
  const isValidValue = (value) => value !== null && value !== undefined;

  /**
   * Handles the local state change
   * Stores and persists the event
   * Updates the local input state
   */
  const handleChange = (event) => {
    const { target } = event;
    const { type } = target;

    /**
     * Calling event.persist() on the synthetic event removes the event from the pool allowing references to the event to be retained asynchronously.
     * @see https://medium.com/trabe/react-syntheticevent-reuse-889cd52981b6
     */
    event.persist();

    /**
     * Gets the value of the input
     */
    const newValue = getInputValue(target, type);

    /**
     * Stores the event for later use
     */
    setEvent(event);

    /**
     * Updates the local state
     */
    setInput(newValue);
  };

  /**
   * Initializes the input timer
   */
  let inputTimer = useRef(null);

  /**
   * @param {string} type
   * @param {string} adornment Can also be a React node
   */
  const getInputAdornment = (type, adornment) => {
    const inputClasses = getInputAdornmentClasses();

    /**
     * Handles rendering the component
     */
    const renderComponent = () => {
      /**
       * Handles getting the text classes
       */
      const textClasses = getTextAdornmentClasses();

      return isString(adornment) ? (
        <Typography variant="caption" className={textClasses}>
          {adornment}
        </Typography>
      ) : (
        adornment
      );
    };

    return (
      <InputAdornment position={type} className={inputClasses}>
        {renderComponent()}
      </InputAdornment>
    );
  };

  /**
   * Handles getting the input base classes
   */
  const getInputBaseClasses = () => {
    return clsx(_classes.input, {
      [classes.input]: !!classes.input,
    });
  };

  /**
   * Handles getting the input outline classes
   */
  const getOutlineClasses = () => {
    return clsx(_classes.inputOutlined);
  };

  /**
   * Handles getting the adornment classes (prefix || sufix)
   * @param {string} position Adornment position
   */
  const getAdornedClasses = (position) => {
    let positionClass = null;

    switch (position) {
      case "start":
        positionClass = _classes.adornedStart;
        break;
      case "end":
        positionClass = _classes.adornedEnd;
        break;
      default:
        break;
    }

    const classNames = clsx(positionClass, {
      [classes.adornment]: !!classes.adornment,
    });

    return classNames;
  };

  /**
   * Gets the input adornment classes
   * The parent container for the prefix/sufix component
   */
  const getInputAdornmentClasses = () => {
    return clsx({ [_classes.error]: false });
  };

  /**
   * Handles getting the text adornment classes
   * Used when the prefix/sufix is a string
   */
  const getTextAdornmentClasses = () => {
    return clsx(_classes.adornmentText, {
      [classes.adornmentText]: !!classes.adornmentText,
    });
  };

  /**
   * Handles getting the margin dense classes (label component)
   */
  const getMarginDenseClasses = () => {
    const withAdornment = prefix && !focused && !shrink;

    return clsx({
      [_classes.marginDense]: !prefix,
      [_classes.marginDenseAdornment]: withAdornment,
    });
  };

  /**
   * Handles getting the shrink classes (label component)
   */
  const getShrinkClasses = () => {
    const isShrink = (focused && shrink) || isString(prefix);

    return clsx(_classes.shrink, {
      [_classes.shrinkAdornment]: isShrink,
    });
  };

  /**
   * Handles getting the input props
   */
  const getInputProps = () => {
    const inputProps = {
      classes: {
        input: getInputBaseClasses(),
        root: _classes.inputRoot,
        focused: _classes.inputFocused,
        notchedOutline: getOutlineClasses(),
        adornedStart: getAdornedClasses("start"),
        adornedEnd: getAdornedClasses("end"),
      },
      notched: false,
    };

    if (prefix) {
      const startAdornment = getInputAdornment("start", prefix);
      inputProps["startAdornment"] = startAdornment;
    }

    if (sufix) {
      const endAdornment = getInputAdornment("end", sufix);
      inputProps["endAdornment"] = endAdornment;
    }

    return inputProps;
  };

  /**
   * Handles getting the label props
   */
  const getInputLabelProps = () => {
    const labelProps = {
      classes: {
        outlined: _classes.labelOutlined,
        marginDense: getMarginDenseClasses(),
        shrink: getShrinkClasses(),
      },
    };

    if (prefix) {
      isString(prefix)
        ? (labelProps["shink"] = "true")
        : (labelProps["shrink"] = shrink);
    }

    return labelProps;
  };

  /**
   * Handles getting the helper text props
   */
  const getFormHelperTextProps = () => {
    return {
      classes: {
        contained: _classes.helperMargins,
        error: _classes.helperMargins,
      },
    };
  };

  /**
   * Handles getting the base input props
   */
  const getInputBaseProps = () => {
    return {
      maxLength: max,
    };
  };

  /**
   * Handles updating the shrink flag for the label component
   */
  useEffect(() => {
    if (prefix && (input || focused)) {
      setShrink(true);
    }
  }, [prefix, input, focused]);

  /**
   * Handles shrinking the label if the prefix is a string
   */
  useEffect(() => {
    if (prefix && isString(prefix)) setShrink(true);
  }, [prefix]);

  /**
   * Handles resetting the shrink
   */
  useEffect(() => {
    if (!input && !focused && shrink) setShrink(false);
  }, [input, shrink, focused]);

  /**
   * Handles cleaning the input before setting the state
   * It converts nulls and undefines to plain empty string.
   */
  useEffect(() => {
    isValidValue(value) ? setInput(value) : setInput("");
    // eslint-disable-next-line
  }, [value]);

  /**
   * Handles debouncing (updating) the form inputs state
   * Syncs the local input with the forms inputs
   */
  useEffect(() => {
    if (event) {
      clearTimeout(inputTimer.current);
      inputTimer.current = setTimeout(() => {
        onChange(event);
      }, [debounceTime]);
    }
    // eslint-disable-next-line
  }, [input, event]);

  /**
   * Handles debouncing (updating) the form inputs state
   * Syncs the local input state with the forms input state
   * Used to trigger the onSubmit function
   */
  useEffect(() => {
    if (inputVerified && input) {
      onChange(event);
      setInputVerified(false);
    }
    // eslint-disable-next-line
  }, [inputVerified]);

  /**
   * Handles updating the form inputs if the form was submitted
   * This effect happens across all rendered inputs
   */
  useEffect(() => {
    if (debounce) setInputVerified(true);
    // eslint-disable-next-line
  }, [debounce]);

  /**
   * Handles getting the text field props
   */
  const getTextFieldProps = () => {
    const inputProps = {
      inputRef: inputRef,
      name: name,
      label: label,
      placeholder: placeholder,
      required: required,
      disabled: disabled,
      fullWidth: fullWidth,
      onChange: handleChange,
      onKeyPress: handleKeyPress,
      onFocus: handleOnFocus,
      onBlur: handleOnBlur,
      size: "small",
      variant: "outlined",
      className: clsx(_classes.root, {
        [classes.root]: !!classes.root,
      }),
      value: displayValue(input),
      id: getInputID(),
      autoFocus: isFocused(),
      inputProps: getInputBaseProps(),
      InputProps: getInputProps(),
      InputLabelProps: getInputLabelProps(),
      FormHelperTextProps: getFormHelperTextProps(),
    };

    return inputProps;
  };

  return <TextField {...getTextFieldProps()} />;
};

TableSearchInput.propTypes = propTypes;
TableSearchInput.defaultProps = defaultProps;

export default TableSearchInput;
export {
  propTypes as TableSearchInputPropTypes,
  defaultProps as TableSearchInputDefaultProps,
};

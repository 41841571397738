/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    border: "1px solid #cfcfcf",
    marginTop: 30,
    padding: 15,
    borderRadius: 3,
    "&:first-of-type": {
      marginTop: 5
    }
  },
  title: {
    border: "1px solid #cfcfcf",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 5,
    borderRadius: 3,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    fontSize: 15,
    "& svg": {
      color: theme.palette.common.white,
    }
  },
  field: {
    ...theme.mixins.searchField,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.9rem",
      padding: 10,
      fontSize: "0.9rem",
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined": {
      transform: "translate(10px, 12px) scale(1)",
      fontSize: "0.8rem",
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
}));

export { useStyles };

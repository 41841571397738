/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  viewBtn: {
    padding: "0 1rem",
    margin: 0,
    borderRadius: 0,
    fontWeight: 400,
    boxShadow: "none",
    height: 20,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "& svg": {
      marginRight: "0.35rem",
      width: "0.75em",
      height: "0.75em",
    },
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  cardContainer: {
    margin: "0.25rem",
  },
  card: {
    background:
      "linear-gradient(90deg, rgb(43 123 204) 0%, rgb(81 132 177) 50%, rgb(50 65 78) 100%)",
    boxShadow: "0px 2px 4px 0px #969696",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: 385,
    maxWidth: 385,
  },
  cardHeader: {
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "0 0.25rem",
    position: "relative",
  },
  activatedStatus: {
    color: theme.palette.common.white,
  },
  name: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: 800,
    letterSpacing: "2px",
    textTransform: "uppercase",
    wordBreak: "break-all",
    fontFamily: theme.font.display,

    color: theme.palette.common.white,
    marginLeft: "0.5rem",
    padding: "0.5rem",
    "& svg": {
      color: theme.palette.primary.main,
      marginRight: "0.5rem",
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    padding: "0.25rem",
    minWidth: 0,
    marginBottom: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
  },
  deleteButton: {
    padding: "0.25rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff",
    },
  },
  cardBody: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "1rem",
    position: "relative",
  },
  cardNumber: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.8rem",
    letterSpacing: 5,
    fontWeight: 800,
    wordBreak: "break-all",
    fontFamily: theme.font.display,
    color: theme.palette.common.white,
    padding: "0.5rem",
  },
  cardCompany: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.7rem",
    fontWeight: 800,
    letterSpacing: "1px",
    textTransform: "uppercase",
    wordBreak: "break-all",
    fontFamily: theme.font.display,
    color: theme.palette.common.white,
    padding: "0.5rem",
  },
  tableValue: {
    fontSize: "0.8rem",
  },
  tableLabel: {
    fontSize: "0.8rem",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  activated: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0.5rem",
    marginRight: "1rem",
  },
  iconContainer: {
    background: theme.palette.error.main,
    padding: "0.35rem",
    display: "flex",
    marginBottom: "0.25rem",
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  activatedCard: {
    background: theme.palette.success.main,
  },
  discount: {
    position: "absolute",
    top: -30,
    fontSize: "1rem",
    background: theme.palette.success.main,
    padding: "0.5rem",
    color: theme.palette.common.white,
    borderRadius: 5,
    marginLeft: "0.25rem",
  },
  value: {
    fontSize: "1rem",
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

export { useStyles };

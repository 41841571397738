import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Styles Imports
 */
import { useStyles } from "./DeleteButton.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.string,
  loading: PropTypes.bool,
  spinnerSize: PropTypes.number,
  disabled: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  type: "button",
  variant: "filled",
  onClick: () => {},
  className: "",
  icon: "",
  text: "",
  title: "",
  loading: false,
  spinnerSize: 24,
  disabled: false,
};

/**
 * Displays the component
 */
const DeleteButton = (props) => {
  const {
    button,
    type,
    variant,
    onClick,
    className,
    icon,
    text,
    title,
    loading,
    spinnerSize,
    disabled,
    ...other
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the button classes
   */
  const btnClasses = clsx(classes.deleteButton, {
    [className]: className ? true : false,
  });

  /**
   * Handles rendering the icon
   */
  const renderIcon = () => {
    if (icon !== false) {
      return icon ? icon : <DeleteIcon className={classes.icon} />;
    }
  };

  const handleLoading = () => {
    return (
      loading && (
        <CircularProgress size={spinnerSize} className={classes.fabProgress} />
      )
    );
  };

  return (
    <Button
      {...button}
      {...other}
      type={type}
      variant={variant}
      onClick={onClick}
      className={btnClasses}
      title={title}
      disabled={disabled}
    >
      {loading ? (
        handleLoading()
      ) : (
        <Fragment>
          {renderIcon()}
          {text}
        </Fragment>
      )}
    </Button>
  );
};

DeleteButton.propTypes = propTypes;
DeleteButton.defaultProps = defaultProps;

export default DeleteButton;
export {
  propTypes as DeleteButtonPropTypes,
  defaultProps as DeleteButtonDefaultProps,
};

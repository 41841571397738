import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * External Imports
 */
import { useLocation, matchPath } from "react-router-dom";

/**
 * Component Imports
 */
import Sidebar, { SidebarDefaultProps, SidebarPropTypes } from "../Sidebar";

/**
 *  Material UI Imports
 */
import GraphicEqOutlinedIcon from "@material-ui/icons/GraphicEqOutlined";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AddToPhotosOutlinedIcon from "@material-ui/icons/AddToPhotosOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TyreServiceSidebar.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(SidebarPropTypes),
  paths: PropTypes.shape({
    controlPanel: PropTypes.string,
    hotels: PropTypes.string,
    create: PropTypes.string,
    scheduler: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */

const defaultProps = {
  sidebar: SidebarDefaultProps,
  paths: {
    controlPanel: "/",
    hotels: "/",
    create: "/",
    scheduler: "/",
  },
};

/**
 * Displays the component
 */
const TyreServiceSidebar = (props) => {
  const { sidebar, paths } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the paths from the components
   */
  const { controlPanel, hotels, create, scheduler } = paths;

  /**
   * Gets the location path
   */
  const location = useLocation();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Checks if the current path matches the path of the menu item
   */
  const checkPathMatch = (pathName) => {
    const result = matchPath(location.pathname, pathName);
    return result ? (result.isExact ? true : false) : false;
  };

  /**
   * Gets the module title
   */
  const getModuleTitle = () => {
    /**
     * Initializes the title variable
     */
    let title = "";

    Object.entries(paths).forEach((path) => {
      if (checkPathMatch(path[1])) {
        title = t(path[0]);
        return;
      }
    });

    return title ? title : t("notFound");
  };

  /**
   * Handles getting the menu items
   */
  const getMenuItems = () => {
    const items = [
      {
        path: controlPanel,
        text: "titleControlPanel",
        icon: <GraphicEqOutlinedIcon className={classes.icon} />,
      },
      {
        path: hotels,
        text: "titleTyreHotels",
        icon: <HomeWorkIcon className={classes.icon} />,
      },
      {
        path: create,
        text: "createWorkOrder",
        icon: <AddToPhotosOutlinedIcon className={classes.icon} />,
      },
    ];

    if (user.type !== "worker") {
      items.push({
        path: scheduler,
        text: "scheduler",
        icon: <EventOutlinedIcon className={classes.icon} />,
      });
    }

    return items;
  };

  /**
   * Configures the sidebar menu
   */
  const config = {
    moduleTitleFn: getModuleTitle,
    showDeleteAccount: false,
    menu: getMenuItems(),
  };

  return <Sidebar {...sidebar} {...config} />;
};

TyreServiceSidebar.propTypes = propTypes;
TyreServiceSidebar.defaultProps = defaultProps;

export default TyreServiceSidebar;
export {
  propTypes as TyreServiceSidebarPropTypes,
  defaultProps as TyreServiceSidebarDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import ModulePackageCard, {
  ModulePackageCardDefaultProps,
  ModulePackageCardPropTypes
} from "../ModulePackageCard";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  card: PropTypes.shape(ModulePackageCardPropTypes),
  updateModalData: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  card: ModulePackageCardDefaultProps,
  updateModalData: () => {}
};

/**
 * Displays the component
 */
const ModulePackages = props => {
  const { card, updateModalData } = props;

  /**
   * Gets the global user object
   */
  const { user } = useUser();

  /**
   * Handles getting the active package
   */
  const getActivePackage = () =>
    user.settings ? user.settings.active_package : "";

  /**
   * Handles rendering the users
   */
  const renderModulePackages = () => {
    const { packages } = user;
    const activePackage = getActivePackage();

    return packages && packages.length > 0
      ? packages.map(_package => {
          const { uuid: key } = _package;

          return (
            <Zoom key={key} in={true} timeout={500}>
              <Grid item xs={12} md={3}>
                <ModulePackageCard
                  {...card}
                  {..._package}
                  updateModalData={updateModalData}
                  activePackage={activePackage}
                />
              </Grid>
            </Zoom>
          );
        })
      : null;
  };

  return renderModulePackages();
};

ModulePackages.propTypes = propTypes;
ModulePackages.defaultProps = defaultProps;

export default ModulePackages;
export {
  propTypes as ModulePackagesPropTypes,
  defaultProps as ModulePackagesDefaultProps
};

import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";

/**
 * Imports i18n
 */
import i18n from "../../i18n";
import { en_ro } from "./LanguageProvider.lang.en-ro";
import { en_hu } from "./LanguageProvider.lang.en-hu";
import { en_en } from "./LanguageProvider.lang.en-en";

/**
 * Defines the prop types
 */
const propTypes = {
  localStorageKey: PropTypes.string,
  defaultLanguage: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  localStorageKey: "i18nextLng",
  defaultLanguage: "en_ro",
};

/**
 * Provides the state of the modal
 * By default it will be open
 */
const useLanguageHook = (props) => {
  const { localStorageKey, defaultLanguage } = defaultProps;

  /**
   *  Initializes the active language
   */
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem(localStorageKey)
  );

  return {
    setActiveLanguage,
    activeLanguage,
    defaultLanguage,
  };
};

/**
 * Sets the prop types
 */
useLanguageHook.propTypes = propTypes;

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const messageContext = createContext();

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const LanguageProvider = (props) => {
  const { children } = props;

  /**
   * Collects the data from the hook
   */
  const data = useLanguageHook();

  /**
   * Handles the translations
   */
  i18n.addResourceBundle("en_ro", "LanguageProvider", en_ro);
  i18n.addResourceBundle("en_hu", "LanguageProvider", en_hu);
  i18n.addResourceBundle("en_en", "LanguageProvider", en_en);

  return (
    <messageContext.Provider value={{ ...data }}>
      {children}
    </messageContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 * - To be used inside components
 */
const useLanguage = () => {
  return useContext(messageContext);
};

export { useLanguage, LanguageProvider };

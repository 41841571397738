import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import EditIcon from "@material-ui/icons/Edit";

/**
 * Styles Imports
 */
import { useStyles } from "./EditButton.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.any,
  text: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  type: "button",
  variant: "filled",
  onClick: () => {},
  className: "",
  icon: "",
  text: "",
  title: "",
  disabled: false,
};

/**
 * Displays the component
 */
const EditButton = (props) => {
  const {
    button,
    type,
    variant,
    onClick,
    className,
    icon,
    text,
    title,
    disabled,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the button classes
   */
  const btnClasses = clsx(classes.editButton, {
    [className]: className ? true : false,
  });

  /**
   * Handles rendering the icon
   */
  const renderIcon = () => {
    return icon ? icon : <EditIcon className={classes.icon} />;
  };

  return (
    <Button
      {...button}
      type={type}
      variant={variant}
      onClick={onClick}
      className={btnClasses}
      title={title}
      disabled={disabled}
    >
      {renderIcon()}
      {text}
    </Button>
  );
};

EditButton.propTypes = propTypes;
EditButton.defaultProps = defaultProps;

export default EditButton;
export {
  propTypes as EditButtonPropTypes,
  defaultProps as EditButtonDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    minWidth: 125,
    borderRadius: 0,
    boxShadow: "none",
    fontWeight: 400,
    height: 30,
    "&:hover": {
      background: theme.palette.hover.main,
      color: theme.palette.secondary.contrastText,
      boxShadow: "none"
    }
  },
  actions: {
    borderTop: "1px solid #e2e2e2",
    padding: "0.5rem 1rem"
  }
}));

export { useStyles };

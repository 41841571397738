/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: -175,
  },
  button: {
    textTransform: "none",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    fontSize: "0.75rem",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  tooltipBase: {
    background: theme.palette.common.white,
    border: "1px solid #c5c5c5",
    color: "#797979",
    fontSize: "0.85rem",
    maxWidth: 600,
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem 0",
    "& ul": {
      marginLeft: "3rem",
      listStyleType: "disc",
    },
  },
  subtitle: {
    fontWeight: 800,
  },
  formula: {
    display: "flex",
    "& span": {
      padding: "0 0.15rem",
    },
  },
  label: {
    fontWeight: 800,
    marginRight: "0.25rem",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0.5rem",
    borderTop: "1px solid #e2e2e2",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0.5rem",
    borderBottom: "1px solid #e2e2e2",
    "& svg": {
      cursor: "pointer",
      "&:hover": {
        background: "#e2e2e2",
        borderRadius: "50%",
      },
    },
  },
  closeBtn: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    minWidth: 125,
    borderRadius: 0,
    boxShadow: "none",
    fontWeight: 400,
    height: 30,
    "&:hover": {
      background: theme.palette.hover.main,
      color: theme.palette.secondary.contrastText,
      boxShadow: "none",
    },
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: "1px solid #c2b9b3",
  },
  labelContainer: {
    backgroundColor: "#f0edec",
    padding: "0.25rem",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
  },
  label: {
    paddingLeft: "0.25rem",
    color: "rgba(0,0,0,.8)",
    fontSize: "0.7rem",
    height: "100%",
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
  },
  valueContainer: {
    backgroundColor: "#fff",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    borderLeft: "none",
    display: "flex",
    alignItems: "center",
  },
  value: {
    color: "rgba(0,0,0,.9)",
    fontSize: "0.7rem",
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
    fontFamily: theme.font.family,
    height: "100%",
    wordBreak: "break-all",
    paddingLeft: "0.25rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
  },
}));

export { useStyles };

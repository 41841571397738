/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  services: {
    display: "inline-flex",
    flex: 1,
    alignItems: "center",
    flexWrap: "wrap"
  },
  serviceBox: {
    fontFamily: theme.font.family,
    fontSize: "0.75rem",
    padding: "0.25rem 1rem",
    color: theme.palette.secondary.light,
    border: `1px solid #c2c5c7`,
    borderRadius: 3,
    background: theme.palette.sidebar.main,
    margin: "0.15rem",
    fontWeight: 800
  },
  tableTitle: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 0,
    marginBottom: "0.5rem",
    borderBottom: `1px solid #e0e0e0`,
    display: "flex"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  title: {
    fontSize: "1.5rem",
    fontFamily: theme.font.family,
    textAlign: "center",
    padding: "1rem",
    marginTop: "1rem"
  },
  box: {
    border: `1px solid ${theme.palette.border.main}`,
    background: "#fbfbfb",
    padding: 10,
    paddingTop: 0,
    borderRadius: 3,
    marginBottom: "2rem"
  },

  spacing: {
    margin: "0 0.5rem"
  },
  boxItem: {
    margin: "0.5rem 0.5rem 0"
  }
}));

export { useStyles };

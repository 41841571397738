import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkOrderTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  currency: PropTypes.string,
  title: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.strnig,
      align: PropTypes.string,
    })
  ),
  data: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  currency: "RON",
  title: "",
  headers: [
    { label: "#", align: "left" },
    { label: "itemName", align: "left" },
    { label: "um", align: "left" },
    { label: "quantity", align: "left" },
    { label: "price", align: "right" },
    { label: "totalLabel", align: "right" },
  ],
  data: {
    items: [],
    discount: 0,
  },
};

/**
 * Displays the component
 */
const WorkOrderTable = (props) => {
  const { currency, headers, title, data, triggerHide } = props;

  /**
   * Gets the discount
   */
  const { discount, specialDiscount } = data;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the items state
   */
  const [items, setItems] = useState([]);

  /**
   * Initializes the total state
   */
  const [total, setTotal] = useState(0);

  /**
   * Handle rendering the products
   */
  const renderTableData = () => {
    return (
      items &&
      items.map((item, idx) => {
        const { name, um, quantity, price, total, is_count_price } = item;

        /**
         * Handles the price
         */
        const handlePrice = (value) => {
          if (is_count_price && price > 0) {
            return `${value.toFixed(2)} ${currency}`;
          }
          return "-";
        };

        const visibleCell = price > 0 && total > 0;

        return (
          <TableRow key={idx}>
            <TableCell component="th" scope="row">
              {idx + 1}
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{um ? um : t("piece")}</TableCell>
            <TableCell>{Number(quantity).toFixed(2)}</TableCell>
            <TableCell align="right">
              {visibleCell ? handlePrice(price) : "-"}
            </TableCell>
            <TableCell align="right">
              {visibleCell ? handlePrice(total) : "-"}
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  /**
   * Handles rendering empty cells
   */
  const renderEmptyCells = (count) => {
    if (!count) return;
    let cells = Array(count)
      .fill(null)
      .map((_, idx) => idx);
    return cells && cells.map((cell) => <TableCell key={cell}></TableCell>);
  };

  const calculateDiscount = (discount, specialDiscount) => {
    if (!specialDiscount) return discount ? discount : 0;
    if (specialDiscount && Number(specialDiscount) && Number(discount)) {
      return parseFloat(discount) + parseFloat(specialDiscount);
    }

    if (specialDiscount && Number(specialDiscount) && !Number(discount)) {
      return specialDiscount;
    }
  };

  /**
   * Handle rendering the product table result
   */
  const renderTableDataResults = () => {
    if (total <= 0 && triggerHide) return;

    /**
     * Formats the values
     */
    const totalValue = total ? total.toFixed(2) : 0;
    let tempDiscount = calculateDiscount(discount, specialDiscount);
    let discountValue = Math.abs(tempDiscount)
      ? Math.abs(tempDiscount).toFixed(2)
      : 0;

    if (triggerHide) discountValue = 0;
    const result =
      Math.abs(discount) > total
        ? 0
        : parseFloat(totalValue - discountValue).toFixed(2);

    /**
     * Defines the results array
     */
    const results = [
      {
        label: t("subtotal"),
        value: `${totalValue} ${currency}`,
      },
    ];

    if (!triggerHide) {
      results.push({
        label: t("discount"),
        value: `-${discountValue} ${currency}`,
      });
    }
    results.push({ label: t("total"), value: `${result} ${currency}` });
    // results.push({ label: t("paid"), value: `${result} ${currency}` });

    return results.map((result, idx) => {
      const { label, value } = result;

      /**
       * Defines the value classes
       */
      const valueClasses = clsx({
        [classes.total]: label === t("total"),
        [classes.discount]: label === t("discount"),
        [classes.paid]: label === t("paid"),
      });

      return (
        <TableRow key={idx} className={classes.resultRow}>
          {renderEmptyCells(4)}
          <TableCell align="right"> {label}</TableCell>
          <TableCell align="right" className={valueClasses}>
            {value}
          </TableCell>
        </TableRow>
      );
    });
  };

  /**
   * Calculates the total
   */
  const calculateTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      if (
        (item.original_product && item.original_product.is_count_price) ||
        item.price > 0
      ) {
        return (total += item.total);
      }
    });
    setTotal(total);
  };

  /**
   * Handles updating the total products value
   */
  useEffect(() => {
    if (data && data.items && data.items.length > 0) {
      const { items } = data;

      calculateTotal(items);
      setItems(items);
    }
  }, [data]);

  return items.length > 0 ? (
    <Grid item container xs={12} direction="column">
      <Typography variant="caption" className={classes.tableTitle}>
        {title}
      </Typography>
      <Grid container>
        <TableContainer>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                {headers &&
                  headers.map((header, idx) => {
                    const { align, label } = header;

                    return (
                      <TableCell key={idx} align={align}>
                        {t(label)}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              <Fragment>
                {renderTableData()}
                {renderTableDataResults()}
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  ) : null;
};

WorkOrderTable.propTypes = propTypes;
WorkOrderTable.defaultProps = defaultProps;

export default WorkOrderTable;
export {
  propTypes as WorkOrderTablePropTypes,
  defaultProps as WorkOrderTableDefaultProps,
};

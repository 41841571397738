import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import TablePaginationActions, {
  TablePaginationActionsDefaultProps,
  TablePaginationActionsPropTypes,
} from "../TablePaginationActions";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TablePagination as MuiTablePagination } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

/**
 * Styles Imports
 */
import { useStyles } from "./TablePagination.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  options: PropTypes.object,
  dataCount: PropTypes.number,
  colSpan: PropTypes.number,
  pagination: PropTypes.object,
  paginationActions: PropTypes.shape(TablePaginationActionsPropTypes),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

/**
 * Defines the default props
 */
const defaultProps = {
  options: {},
  dataCount: 0,
  colSpan: 0,
  pagination: {},
  paginationActions: TablePaginationActionsDefaultProps,
  rowsPerPageOptions: [5, 10, 15, 30, 50, 100],
};

/**
 * Displays the component
 */
const TablePagination = (props) => {
  const {
    options,
    dataCount,
    colSpan,
    pagination,
    paginationActions,
    rowsPerPageOptions,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the breakpoints for lower rez
   */
  const theme = useTheme();
  const isOnlyXL = useMediaQuery(theme.breakpoints.only("xl"));
  const isDownXl = useMediaQuery(theme.breakpoints.down("xl"));
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Gets the table options
   */
  const { withPagination } = options;

  /**
   * Defines the render condition
   */
  const render = dataCount && withPagination;
  if (!render) return null;

  /**
   * Gets the pagination data
   */
  const {
    handlePageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    total,
    currentPage,
  } = pagination;

  const rows = Number(rowsPerPage);
  /**
   * Handles rendering the pagination
   */
  const getPagination = () => {
    /**
     * Handles pagination responsiveness by getting dynamic siblings
     */
    const getSiblingsCount = () => {
      let siblingsCount = 4;
      if (total > 105 && rows <= 15 && currentPage < 500) return 4;
      if (total > 999 && currentPage > 500) return 2;
      if (isOnlyXL) return 3;
      if (isDownXl) siblingsCount = 3;
      if (isDownMd || isDownLg) siblingsCount = 2;
      if (isDownSm) siblingsCount = 1;
      return siblingsCount;
    };

    /**
     * Handles pagination responsiveness by getting dynamic boundaries
     */
    const getBoundaryCount = () => {
      let boundaryCount = 4;
      if (total > 105 && rows <= 15 && currentPage < 500) return 4;
      if (total > 999 && currentPage > 500) return 2;
      if (isOnlyXL) return 3;
      if (isDownXl || isDownMd || isDownLg) boundaryCount = 2;
      if (isDownSm) boundaryCount = 1;
      return boundaryCount;
    };

    /**
     * Defines the pagination properties
     */
    const paginationCount = Math.ceil(total / rows);
    const siblingCount = getSiblingsCount();
    const boundaryCount = getBoundaryCount();

    const paginationClasses = { root: classes.listRoot, ul: classes.list };

    return (
      <Fragment>
        <Pagination
          count={Number(paginationCount)}
          page={Number(currentPage)}
          siblingCount={siblingCount}
          size="small"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          boundaryCount={boundaryCount}
          classes={paginationClasses}
        />
        <table>
          <tbody>
            <tr>
              <MuiTablePagination
                classes={{
                  root: classes.muiPagination,
                }}
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={colSpan}
                count={total}
                rowsPerPage={rows}
                page={Number(currentPage) - 1}
                SelectProps={{
                  inputProps: {
                    "aria-label": t("rowsPerPage"),
                  },
                  native: true,
                }}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                ActionsComponent={(props) => (
                  <TablePaginationActions
                    {...props}
                    {...paginationActions}
                    onChangePage={handlePageChange}
                    page={Number(currentPage)}
                    count={total}
                  />
                )}
              />
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  };

  return <div className={classes.pagination}>{getPagination()}</div>;
};

TablePagination.propTypes = propTypes;
TablePagination.defaultProps = defaultProps;

export default TablePagination;
export {
  propTypes as TablePaginationPropTypes,
  defaultProps as TablePaginationDefaultProps,
};

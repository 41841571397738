import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * External Imports
 */
import { useLocation, matchPath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

/**
 * Component Imports
 */
import Sidebar, { SidebarDefaultProps, SidebarPropTypes } from "../Sidebar";

/**
 *  Material UI Imports
 */
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import DeveloperBoardOutlinedIcon from "@material-ui/icons/DeveloperBoardOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

/**
 * Styles Imports
 */
import { useStyles } from "./WorkstationSettingsSidebar.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(SidebarPropTypes),
  paths: PropTypes.shape({
    workOrders: PropTypes.string,
    clients: PropTypes.string,
    products: PropTypes.string,
    loyaltyCards: PropTypes.string,
    hotes: PropTypes.string,
    contracts: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */

const defaultProps = {
  sidebar: SidebarDefaultProps,
  paths: {},
};

/**
 * Displays the component
 */

const WorkstationSettingsSidebar = (props) => {
  const { sidebar, paths } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the paths from the components
   */
  const {
    workOrders,
    clients,
    products,
    loyaltyCards,
    hotels,
    contracts,
  } = paths;

  /**
   * Gets the location path
   */
  const location = useLocation();

  /**
   * Checks if the current path matches the path of the menu item
   */
  const checkPathMatch = (pathName) => {
    const result = matchPath(location.pathname, pathName);
    return result ? (result.isExact ? true : false) : false;
  };

  /**
   * Gets the module title
   */
  const getModuleTitle = () => {
    /**
     * Initializes the title variable
     */
    let title = "";

    Object.entries(paths).forEach((path) => {
      if (checkPathMatch(path[1])) {
        title = t(path[0]);
        return;
      }
    });

    return title ? title : t("notFound");
  };

  /**
   * Configures the sidebar menu
   */

  const config = {
    moduleTitleFn: getModuleTitle,
    menu: [
      {
        path: workOrders,
        text: "workOrders",
        icon: <AssignmentOutlinedIcon className={classes.icon} />,
      },
      {
        path: clients,
        text: "clients",
        icon: <PeopleAltOutlinedIcon className={classes.icon} />,
      },
      {
        path: products,
        text: "products",
        icon: <DeveloperBoardOutlinedIcon className={classes.icon} />,
      },
      {
        path: loyaltyCards,
        text: "loyaltyCards",
        icon: <CardGiftcardIcon className={classes.icon} />,
      },
      {
        path: hotels,
        text: "hotels",
        icon: <HomeWorkIcon className={classes.icon} />,
      },
      {
        path: contracts,
        text: "contracts",
        icon: (
          <FontAwesomeIcon icon={faFileContract} className={classes.faIcon} />
        ),
      },
    ],
  };

  return <Sidebar {...sidebar} {...config} />;
};

WorkstationSettingsSidebar.propTypes = propTypes;
WorkstationSettingsSidebar.defaultProps = defaultProps;

export default WorkstationSettingsSidebar;
export {
  propTypes as WorkstationSettingsSidebarPropTypes,
  defaultProps as WorkstationSettingsSidebarDefaultProps,
};

import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultMessage: PropTypes.string,
  defaultSeverity: PropTypes.string,
  defaultComponent: PropTypes.any,
  delay: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultMessage: "",
  defaultSeverity: "success",
  defaultComponent: "",
  delay: 1,
};

/**
 * Provides the state of the modal
 * By default it will be open
 */
const useMessageHook = (props) => {
  const {
    defaultMessage,
    defaultSeverity,
    defaultComponent,
    delay: defaultDelay,
  } = defaultProps;

  /**
   * Sets the language state with whatever is in the localstorage with the respective key
   */
  const [message, setMessage] = useState(defaultMessage);
  const [severity, setSeverity] = useState(defaultSeverity);
  const [component, setComponent] = useState(defaultComponent);
  const [delay, setDelay] = useState(defaultDelay);
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState(true);
  const [timer, setTimer] = useState(3000);
  const [perma, setPerma] = useState(false);

  /**
   * Dispatches the message
   */
  const dispatchMessage = (props) => {
    setOpen(true);
    setPerma(props.perma ? props.perma : false);
    setIcon(props.icon ? props.icon : icon);
    setSeverity(props.severity ? props.severity : defaultSeverity);
    setMessage(props.message ? props.message : defaultMessage);
    setComponent(props.component ? props.component : defaultComponent);
    setDelay(props.delay ? props.delay : defaultDelay);
    setTimer(props.timer ? props.timer : 6000);
  };

  /**
   * Resets the message
   */
  const resetMessage = () => {
    setOpen(false);
    setIcon(icon);
    setPerma(false);
    setSeverity(defaultSeverity);
    setComponent(defaultComponent);
    setMessage(defaultMessage);
    setDelay(defaultDelay);
  };

  return {
    open,
    setOpen,
    icon,
    dispatchMessage,
    resetMessage,
    severity,
    setSeverity,
    component,
    setComponent,
    message,
    setMessage,
    delay,
    perma,
    timer,
  };
};

/**
 * Sets the prop types
 */
useMessageHook.propTypes = propTypes;

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const messageContext = createContext();

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const MessageProvider = (props) => {
  const { children } = props;

  const message = useMessageHook();

  return (
    <messageContext.Provider value={{ ...message }}>
      {children}
    </messageContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 * - To be used inside components
 */
const useMessage = () => {
  return useContext(messageContext);
};

export { useMessage, MessageProvider };

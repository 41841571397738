import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import ModuleCardMini, {
  ModuleCardMiniDefaultProps,
  ModuleCardMiniPropTypes,
} from "../ModuleCardMini";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";

/**
 * Styles Imports
 */
import { useStyles } from "./SmallModuleCards.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  card: PropTypes.shape(ModuleCardMiniPropTypes),
  modules: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  card: ModuleCardMiniDefaultProps,
  modules: [],
};

/**
 * Displays the component
 */
const SmallModuleCards = (props) => {
  const { card, modules } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Handles rendering the module cards
   */
  const renderCards = () => {
    return (
      modules &&
      modules.map((module, idx) => {
        return <ModuleCardMini key={idx} {...card} {...module} />;
      })
    );
  };

  return (
    <div className={classes.container}>
      <Typography variant="caption" className={classes.title}>
        {t("titleAvailableModules")}
      </Typography>
      <div className={classes.cards}>{renderCards()}</div>
    </div>
  );
};

SmallModuleCards.propTypes = propTypes;
SmallModuleCards.defaultProps = defaultProps;

export default SmallModuleCards;
export {
  propTypes as SmallModuleCardsPropTypes,
  defaultProps as SmallModuleCardsDefaultProps,
};

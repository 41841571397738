import React from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 *  Material UI Imports
 */
import { Tooltip as MUITooltip } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

/**
 * Styles Imports
 */
import { useStyles } from "./Tooltip.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  classes: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: "",
  icon: null,
  classes: {},
};

/**
 * Displays the component
 */
const Tooltip = (props) => {
  const { title, icon, classes } = props;

  /**
   * Gets the component styles
   */
  const styles = useStyles();

  /**
   * Defines the tooltip classes
   */
  const tooltipClasses = {
    tooltip: clsx(styles.tooltip, { [classes.tooltip]: !!classes.tooltip }),
    popper: clsx(styles.popper, { [classes.popper]: !!classes.popper }),
  };

  return (
    <MUITooltip
      title={title}
      TransitionComponent={Zoom}
      placement="top"
      classes={tooltipClasses}
    >
      {icon}
    </MUITooltip>
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
export { propTypes as TooltipPropTypes, defaultProps as TooltipDefaultProps };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  AuthPage: {
    border: "1px solid",
    padding: "0.5rem",
    margin: "0.5rem",
    backgroundColor: theme.palette.common.white,
  },
  container: {
    position: "absolute",
    right: 50,
    top: 0,
  },
  route: {
    minHeight: "100vh",
    background: theme.palette.primary.main,
  },
  promo: {
    display: "flex",
    // flexDirection: "column",
    // textAlign: "center",
    // alignSelf: "center",
    // height: "fit-content",
    // marginTop: "125px",
    // minHeight: "200px",
    alignItems: "center",
    paddingTop: "50px",
    // alignSelf: "center",
    [theme.breakpoints.only("sm")]: {
      // marginTop: "50px",
      // marginBottom: "50px"
    }
  },
  promoTitle: {
    color: theme.palette.primary.accent,
    fontSize: "2.8rem",
    fontWeight: 800,
    fontFamily: theme.font.display,
    position: "relative",
  },
  white: {
    color: theme.palette.common.white
  },
  promoText: {
    color: theme.palette.common.white,
    padding: "0.5rem 0",
    fontSize: "1.2rem",
    fontWeight: 800,
    fontFamily: theme.font.family
  },
  promoTitleSubText: {
    position: "absolute",
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    fontWeight: 800,
    bottom: "-10px",
    right: "-25px"
  }
}));

export { useStyles };

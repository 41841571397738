/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  expansionPanelRoot: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: 0
    }
  },
  panelSummaryRoot: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `2px solid #efefef`,
    marginBottom: 0,
    marginTop: 5,
    minHeight: 55,
    cursor: "unset",
    "&.Mui-expanded": {
      minHeight: 55
    }
  },
  panelSummaryContent: {
    "&.Mui-expanded": {
      margin: 0
    }
  },
  panelDetailsRoot: {
    padding: "1rem",
    width: "100%"
  },
  labelContainer: {
    backgroundColor: "#f0edec",
    padding: "0.25rem",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    display: "flex",
    alignItems: "center"
  },
  label: {
    paddingLeft: "0.25rem",
    color: "rgba(0,0,0,.8)",
    fontSize: "0.9rem",
    height: "100%",
    display: "flex",
    flex: 1,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem"
    }
  },
  valueContainer: {
    backgroundColor: "#fff",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    borderLeft: "none"
  },
  value: {
    color: "rgba(0,0,0,.9)",
    fontSize: "0.95rem",
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
    fontFamily: theme.font.family,
    height: "100%",
    paddingLeft: "0.25rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem"
    }
  },
  packageContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: " 1px solid #c2b9b3",
    borderBottom: "none",
    borderLeft: "none",
    position: "relative",
    "& svg": {
      cursor: "pointer",
      position: "absolute",
      right: 5,
      color: theme.palette.warning.main,
      marginRight: "0.15rem"
    }
  },
  packageName: {
    paddingLeft: "0.5rem",
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "0.95rem",
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
    fontFamily: theme.font.display,
    height: "100%",
    "& svg": {
      position: "absolute",
      right: 5,
      color: theme.palette.warning.main,
      marginRight: "0.15rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
      paddingLeft: "0.25rem"
    }
  },
  summary: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  transactionID: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.light,
    padding: "0.25rem",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem",
      color: theme.palette.primary.main
    }
  },
  createdAt: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "0.25rem",
      color: theme.palette.primary.main
    }
  },
  wrapper: {
    borderBottom: "1px solid #c2b9b3",
    display: "flex",
    flex: 1
  },
  actions: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  billingTitle: {
    marginTop: "1rem",
    fontSize: "0.9rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem"
  },
  popper: {
    marginTop: -5
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid #929292",
    color: theme.palette.common.white,
    fontSize: "1rem",
    maxWidth: "none"
  },
  expanded: {}
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";

/**
 *  Material UI Imports
 */
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

/**
 * Styles Imports
 */
import { useStyles } from "./ContactItem.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleViewContact: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone_mobile: PropTypes.string,
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: "",
  handleEdit: () => {},
  handleDelete: () => {},
  handleViewContact: () => {},
  first_name: "",
  last_name: "",
  phone_mobile: "",
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
};

/**
 * Displays the component
 */
const ContactItem = (props) => {
  const {
    id,
    openModal,
    handleDelete,
    first_name,
    last_name,
    phone_mobile,
    editButton,
    deleteButton,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Handles opening the edit contact modal
   */
  const editContact = (e) => {
    e.stopPropagation();
    openModal("editContact", props);
  };

  /**
   * Handles opening the view contact modal
   */
  const handleView = () => {
    openModal("viewContact", props);
  };

  /**
   * Handles deleting the contact
   */
  const deleteContact = () => {
    handleDelete(id);
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.contactContainer}
        onClick={handleView}
        title={t("viewContact")}
      >
        <div className={classes.contactName}>
          <AccountCircleIcon className={classes.contactIconLg} />
          {first_name} {last_name}
        </div>
        <div className={classes.contactInfo}>
          <PhoneAndroidIcon className={classes.contactIcon} />
          {phone_mobile}
        </div>
      </div>
      <div className={classes.actions}>
        <EditButton
          {...editButton}
          className={classes.editSmall}
          onClick={editContact}
          title={t("editContact")}
        />
        <DeleteButton
          {...deleteButton}
          className={classes.deleteButton}
          onClick={deleteContact}
          title={t("deleteContact")}
        />
      </div>
    </div>
  );
};

ContactItem.propTypes = propTypes;
ContactItem.defaultProps = defaultProps;

export default ContactItem;
export {
  propTypes as ContactItemPropTypes,
  defaultProps as ContactItemDefaultProps,
};

const defaults = {
	searchParams: {
		models: [
			{
				label: 'username',
				field: 'username',
				type: 'equal',
				order: 1,
				options: [],
				selected: ''
			},
			{
				label: 'Type',
				field: 'type',
				type: 'dropdown',
				order: 1,
				options: [],
				selected: ''
			},
			{
				label: 'Organization',
				field: 'organization_id',
				type: 'dropdown',
				order: 1,
				options: [],
				selected: ''
			}
		],
		order_by: 'created_at',
		order_dir: 'desc',
		page_size: Number(localStorage.getItem('usersViewPageSize')) || 10,
		page_count: 1
	},
	accountTypes: [ 'admin', 'worker', 'operator' ],
	orderBy: [ 'created_at', 'username', 'type' ],
	pageSizeOptions: [ 6, 10, 20, 50, 100 ]
};

export { defaults };

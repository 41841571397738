import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import shortid from "shortid";
import clsx from "clsx";

/**
 * Material UI Imports
 */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

/**
 * Styles Imports
 */
import { useStyles } from "./InputAutocomplete.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  inputValue: PropTypes.any,
  maxSize: PropTypes.number,
  limitTags: PropTypes.number,
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  showHelper: PropTypes.bool,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoSelect: PropTypes.bool,
  fullObjectValue: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  getOptionSelected: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  groupBy: PropTypes.func,
  styles: PropTypes.object,
  InputLabelProps: PropTypes.any,
  options: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  label: "",
  placeholder: "",
  value: "",
  inputValue: "",
  maxSize: null,
  limitTags: -1,
  error: "",
  id: "autocomplete_input",
  name: "autocomplete_input",
  required: false,
  autoFocus: false,
  disabled: false,
  showHelper: true,
  freeSolo: true,
  multiple: false,
  autoComplete: true,
  autoSelect: false,
  fullObjectValue: false,
  className: "",
  size: "small",
  getOptionLabel: null,
  renderOption: null,
  getOptionSelected: null,
  onChange: () => {},
  onClose: () => {},
  groupBy: null,
  styles: {},
  InputLabelProps: null,
  options: [],
};

/**
 * Displays the component
 */
const InputAutocomplete = (props) => {
  const {
    label,
    placeholder,
    value,
    inputValue,
    maxSize,
    limitTags,
    error,
    id,
    name,
    required,
    autoFocus,
    disabled,
    showHelper,
    freeSolo,
    multiple,
    className,
    size,
    getOptionLabel,
    getOptionSelected,
    renderOption,
    onChange,
    onClose,
    styles,
    InputLabelProps,
    options,
    autoSelect,
    autoComplete,
    fullObjectValue,
    groupBy,
    open,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Renders the hidden text if there's no erros or helper messages
   */
  const hiddenText = () => <span className={classes.hidden}>error_msg</span>;

  /**
   * Define sthe default option handlers
   */
  const defaultGetOptionLabel = (option) => (option.name ? option.name : "");
  const defaultRenderOption = (option) => (option.name ? option.name : "");

  /**
   * Gets the helper text/or error
   */
  const getHelperText = () => (error ? error : hiddenText());
  const helperText = getHelperText();

  /**
   * Handles rendering the base text field input
   */
  const renderInput = (params) => (
    <TextField
      {...params}
      id={`${id}-${shortid.generate()}`}
      name={name}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      inputRef={inputRef}
      label={label}
      className={clsx(classes.autocompleteField, {
        [className]: className ? true : false,
      })}
      variant="outlined"
      inputProps={{
        ...params.inputProps,
        autoFocus: autoFocus,
        maxLength: maxSize,
      }}
      InputLabelProps={InputLabelProps}
      helperText={showHelper ? helperText : false}
      error={error ? true : false}
    />
  );

  /**
   * Prepares the component props
   */
  const getAutocompleteProps = () => {
    let baseProps = {
      size,
      autoComplete,
      autoSelect,
      multiple,
      limitTags,
      freeSolo,
      options,
      disabled: disabled,
      autoHighlight: true,
      selectOnFocus: !freeSolo,
      getOptionLabel: getOptionLabel ? getOptionLabel : defaultGetOptionLabel,
      renderOption: renderOption ? renderOption : defaultRenderOption,
      classes: styles,
      renderInput,
    };

    if (multiple) {
      baseProps["value"] = value;
      baseProps["onChange"] = onChange;
    } else {
      baseProps["inputValue"] = inputValue;
      baseProps["onInputChange"] = onChange;
    }

    if (open) {
      baseProps["open"] = open;
    }

    if (fullObjectValue) {
      baseProps["value"] = value;
      baseProps["onChange"] = onChange;
    }

    if (groupBy) baseProps["groupBy"] = groupBy;
    if (onClose) baseProps["onClose"] = onClose;
    if (getOptionSelected) baseProps["getOptionSelected"] = getOptionSelected;

    return { ...baseProps };
  };

  /**
   * Gets the textarea props;
   */
  const componentProps = getAutocompleteProps();

  /**
   * Applies autofocus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  return <Autocomplete {...componentProps} />;
};

InputAutocomplete.propTypes = propTypes;
InputAutocomplete.defaultProps = defaultProps;

export default InputAutocomplete;
export {
  propTypes as InputAutocompletePropTypes,
  defaultProps as InputAutocompleteDefaultProps,
};

import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";
import Echo from "laravel-echo";
// eslint-disable-next-line
import socketClient from "socket.io-client";

/**
 * Hooks
 */
import { useLocalStorage } from "./";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * Defines the key for storing auth status in local storage
   */
  localStorageKey: PropTypes.string,
  localStorageKeyWebSocket: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  localStorageKey: "auth",
  localStorageKeyWebSocket: "webSocket",
};

/**
 * Provides the state of the modal
 * By default it will be open
 */
const useAuthHook = (props) => {
  // eslint-disable-next-line
  const { localStorageKey, localStorageKeyWebSocket } = defaultProps;

  /**
   * Sets the auth state with whatever is in the localstorage with the respective key
   */
  const [auth, setAuth] = useLocalStorage(localStorageKey);

  /**
   * Checks if the local storage has the token
   */
  const hasToken = auth && auth.access_token ? true : false;

  /**
   * Manages auth state
   * It's initial value is based upon whether or not there is a token in local storage.
   */
  const [isAuthenticated, setIsAuthenticated] = useState(hasToken);

  /**
   * Exposes the token to a state variable which can be exported for use in other components
   */
  const [token, setToken] = useState(auth ? auth.access_token : "");

  /**
   * Websocket options
   * @type {Echo}
   */
  const webSocketOptions = new Echo({
    // @TODO - Now is deactiated
    // broadcaster: 'socket.io',
    // host: window.location.hostname + ':6001',
    // transports: ['websocket', 'polling'],
    // client: socketClient,
    // auth: {
    //   headers: {
    //     Accept: 'application/json',
    //     Authorization: `Bearer ${token}`
    //   }
    // }
  });

  /**
   * Sets up websocket client
   */
  const [webSocket, setWebSocket] = useState(webSocketOptions);

  /**
   * Defines the login function
   * props = the auth object coming from the /auth endpoint if the request was sucessfull
   */
  const login = (props) => {
    if (props) {
      const { access_token } = props;
      if (access_token) {
        const auth = {
          username: props.user.username,
          user: props.user,
          access_token: props.access_token,
          expires_in: props.expires_in,
        };
        const webSocketOptions = new Echo({
          // broadcaster: 'socket.io',
          // host: window.location.hostname + ':6001',
          // transports: ['websocket', 'polling'],
          // client: socketClient,
          // auth: {
          //   headers: {
          //     Authorization: `Bearer ${access_token}`
          //   }
          // }
        });
        setAuth(auth);
        setToken(access_token);
        setIsAuthenticated(true);
        setWebSocket(webSocketOptions);
      }
    }
  };

  /**
   * Defines the logout function
   * Resets all values to their defaults
   */
  const logout = () => {
    setAuth("");
    setToken("");
    setIsAuthenticated(false);
    setWebSocket(new Echo({}));
  };

  return {
    isAuthenticated,
    auth,
    setAuth,
    token,
    webSocket,
    login,
    logout,
  };
};

/**
 * Sets the prop types
 */
useAuthHook.propTypes = propTypes;

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const authContext = createContext();

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const AuthProvider = (props) => {
  const { children } = props;

  const completionData = useAuthHook();

  return (
    <authContext.Provider value={{ ...completionData }}>
      {children}
    </authContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 * - To be used inside components
 */
const useAuth = () => {
  return useContext(authContext);
};

export { useAuth, AuthProvider };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  createButton: {
    margin: "0.5rem",
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    padding: "0.45rem 1rem",
    lineHeight: 1.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    fontWeight: 400,
    fontFamily: theme.font.family,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none"
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.45rem 0.6rem"
    }
  },
  icon: {
    marginRight: "0.5rem"
  }
}));

export { useStyles };

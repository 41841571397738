/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modules: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "100%",
    marginTop: "5rem",
    backgroundColor: theme.palette.common.white
  },
  modulesContainer: {
    margin: "0.5rem 0",
    justifyContent: "center"
  },
  modalContent: {
    maxHeight: 660
  },
  modulePage: {
    // background: "#eaeaea",
    background: "#212121",
    display: "flex",
    // flex: 1,
    // flexDirection: "column"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  modalContent: {
    maxHeight: 660
  },
  modulesContainer: {
    margin: "0.5rem 0"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  label: {
    fontFamily: theme.font.display,
    color: theme.palette.secondary.main,
    fontWeight: 800,
    fontSize: "0.9rem",
    marginBottom: "0.25rem",
    display: "inline",
    wordBreak: "break-all"
  },
  value: {
    fontFamily: theme.font.display,
    color: theme.palette.error.main,
    fontWeight: 800,
    fontSize: "0.9rem",
    marginBottom: 0
  },
  submitBtn: theme.mixins.deleteBtn
}));

export { useStyles };

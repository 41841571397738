import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import Reaptcha from "reaptcha";

/**
 *  Material UI Imports
 */
import Box from "@material-ui/core/Box";

/**
 * Hooks
 */
import { useLanguage } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./ReCaptcha.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  siteKey: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  siteKey: "6Lc0z-IUAAAAALJvFFWU6kHZhUeNLcsSKKVnpxHM",
  className: "",
};

/**
 * Displays the component
 */
const ReCaptcha = (props) => {
  const {
    siteKey,
    handleCaptcha,
    setCaptcha,
    resetCaptcha,
    setResetCaptcha,
    className,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Defines the captcha container's classes
   */
  const captchaContainerClasses = clsx(classes.captcha, {
    [className]: true,
  });

  /**
   * Initializes a ref state
   */
  const [ref, setRef] = useState("");

  /**
   * Gets the active language setter
   */
  const { activeLanguage } = useLanguage();

  /**
   * Gets the ref
   */
  const getRef = (e) => {
    setRef(e);
  };

  /**
   * Handles the response
   */
  const handleChange = (response) => {
    handleCaptcha(response);
  };

  /**
   * Handles potential errors
   */
  const handleError = (error) => {
    console.log(error);
  };

  /**
   * Handles reseting the captcha state if it expired
   */
  const handleExpire = () => {
    ref.reset();
    if (setCaptcha) setCaptcha("");
  };

  /**
   * Handles providing the recaptcha with the correct language string config
   * @param {String} language
   */
  const getRecaptchaLanguage = (language) => {
    switch (language) {
      case "en_en":
        return "en";
      case "en_ro":
        return "ro";
      case "en_hu":
        return "hu";
      default:
        return "ro";
    }
  };

  /**
   * Handles reseting the captcha
   */
  useEffect(() => {
    if (resetCaptcha) {
      ref.reset();
      setResetCaptcha(false);
    }
    // eslint-disable-next-line
  }, [resetCaptcha, activeLanguage]);

  return (
    <Box className={captchaContainerClasses}>
      <Reaptcha
        key={activeLanguage}
        ref={getRef}
        sitekey={siteKey}
        onVerify={handleChange}
        onError={handleError}
        onExpire={handleExpire}
        hl={getRecaptchaLanguage(activeLanguage)}
      />
    </Box>
  );
};

ReCaptcha.propTypes = propTypes;
ReCaptcha.defaultProps = defaultProps;

export default ReCaptcha;
export {
  propTypes as ReCaptchaPropTypes,
  defaultProps as ReCaptchaDefaultProps,
};

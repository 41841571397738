/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  stepCard: {
    boxShadow: "none",
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop: 5,
  },
  content: {
    paddingBottom: 15,
  },
  cardActions: {
    paddingTop: 0,
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  submitButton: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    height: 30,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none",
    },
  },
  beforeIcon: {
    marginRight: "0.25rem",
  },
  nextIcon: {
    marginLeft: "0.25rem",
  },
  //////////
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: "4px 0",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.85rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
      fontSize: "0.75rem",
    },
    "& .MuiAutocomplete-tagSizeSmall": {
      background: theme.palette.primary.accent,
      color: theme.palette.common.white,
      margin: 4,
      marginTop: 6,
      borderRadius: 3,
      "& svg": {
        color: "inherit",
      },
      "& svg:hover": {
        opacity: 0.7,
      },
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input":
      {
        padding: "6px 4px",
      },
  },
  textarea: {
    marginTop: "1rem",
  },
  inputWrapper: {
    marginBottom: "1rem",
  },
  smallBtn: {
    padding: "0.1rem",
    fontSize: "0.7rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      width: 24,
      height: 24,
    },
  },
  sectionTitle: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: "0.25rem",
    paddingBottom: 0,
    borderBottom: `1px solid #e0e0e0`,
    display: "flex",
    alignItems: "center",
    margin: "0.75rem 0",
    marginTop: 0,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  withMarginTop: {
    marginTop: "1rem",
  },
  selectField: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: "4px 0",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.85rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
      background: theme.palette.common.white,
      padding: "0 0.5rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 15,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "#fff",
      transform: "translate(15px, -5px) scale(1)",
      fontSize: "0.7rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
      fontSize: "0.75rem",
    },
  },
  checkbox: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem",
    },
  },
  checkboxChecked: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  workersContainer: {
    display: "flex",
    flex: 1,
    width: "100%",
    marginTop: "1rem",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  workersList: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  saveBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 2,
    fontSize: "0.8rem",
    fontWeight: 400,
    height: 30,
    boxShadow: "none",
    minWidth: 125,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  blank: {
    boxShadow: "none",
    "& $content": {
      paddingBottom: 0,
    },
  },
}));

export { useStyles };

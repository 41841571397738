import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Orders, { OrdersDefaultProps, OrdersPropTypes } from "../Orders";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes
} from "../SubmoduleContainer";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import ListAltIcon from "@material-ui/icons/ListAlt";

/**
 * Hooks
 */
import { useUser, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsOrders.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  orders: PropTypes.shape(OrdersPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  orders: OrdersDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: "/orders"
};

/**
 * Displays the component
 */
const AccountSettingsOrders = (props) => {
  const { title, wrapper, container, orders } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user
   */
  const { setUser } = useUser();

  /**
   * Initializes the state of the expansion panel
   */
  const [expanded, setExpanded] = useState("");

  /**
   * Handles getting the orders
   */
  const getOrders = async () => {
    const orders = await apiClient.get("/accounts/orders");

    if (orders) {
      const { data } = orders;

      setUser((prevState) => {
        return {
          ...prevState,
          orders: data
        };
      });
    }
  };

  /**
   * Handles the change of the expansion panel state
   */
  const handleChange = (panel) =>
    panel === expanded ? setExpanded(false) : setExpanded(panel);

  /**
   * Handles getting the payment methods
   */
  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, []);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<ListAltIcon />}
        title={t("titleOrders")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Grid container className={classes.box}>
          <Grid container spacing={1}>
            <Orders
              {...orders}
              expanded={expanded}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

AccountSettingsOrders.propTypes = propTypes;
AccountSettingsOrders.defaultProps = defaultProps;

export default AccountSettingsOrders;
export {
  propTypes as AccountSettingsOrdersPropTypes,
  defaultProps as AccountSettingsOrdersDefaultProps
};

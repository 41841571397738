/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  }
}));

export { useStyles };

import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import LoyaltyCard, {
  LoyaltyCardDefaultProps,
  LoyaltyCardPropTypes,
} from "../LoyaltyCard";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  initMount: PropTypes.bool,
  loyaltyCard: PropTypes.shape(LoyaltyCardPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  initMount: true,
  loyaltyCard: LoyaltyCardDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  handleEdit: () => {},
  handleDelete: () => {},
};

/**
 * Displays the component
 */
const LoyaltyCards = (props) => {
  const {
    initMount,
    loyaltyCard,
    loadingBackdrop,
    handleEdit,
    handleDelete,
    clients,
  } = props;

  /**
   * Gets the global user object
   */
  const { user } = useUser();

  /**
   * Gets the transition delay time
   */
  const getTransitionDelay = (index) => index * 50;

  /**
   * Handles rendering the users
   */
  const renderLoyaltyCards = () => {
    const { loyalty_cards } = user;

    /**
     * Initializes the cards
     */
    let cards = null;

    /**
     * Defines the init loader
     */
    const initLoader = initMount && (
      <LoadingBackdrop {...loadingBackdrop} open={true} />
    );

    /**
     * Defines the render condition
     */
    const itemsFound = loyalty_cards && loyalty_cards.length > 0;

    if (itemsFound) {
      /**
       * Builds the loyalty cards components
       */
      cards = loyalty_cards.map((card, idx) => {
        /**
         * Gets the transition delay
         */
        const transitionDelay = getTransitionDelay(idx);

        /**
         * Defines the transition props
         */
        const transitionProps = {
          in: true,
          timeout: 400,
          key: idx,
          style: { transitionDelay },
        };

        /**
         * Defines the loyalty card props
         */
        const loyaltyCardProps = {
          ...loyaltyCard,
          ...card,
          clients,
          handleEdit,
          handleDelete,
        };

        return (
          <Zoom {...transitionProps}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <LoyaltyCard {...loyaltyCardProps} />
            </Grid>
          </Zoom>
        );
      });
    }

    return itemsFound ? cards : initLoader;
  };

  return renderLoyaltyCards();
};

LoyaltyCards.propTypes = propTypes;
LoyaltyCards.defaultProps = defaultProps;

export default LoyaltyCards;
export {
  propTypes as LoyaltyCardsPropTypes,
  defaultProps as LoyaltyCardsDefaultProps,
};

import React from "react";
import PropTypes from "prop-types";

import InputText, {
  InputTextPropTypes,
  InputTextDefaultProps,
} from "../InputText";
import InputCheckbox, {
  InputCheckboxPropTypes,
  InputCheckboxDefaultProps,
} from "../InputCheckbox";
import InputPassword, {
  InputPasswordDefaultProps,
  InputPasswordPropTypes,
} from "../InputPassword";
import InputSelect, {
  InputSelectDefaultProps,
  InputSelectPropTypes,
} from "../InputSelect";
import InputTextarea, {
  InputTextareaDefaultProps,
  InputTextareaPropTypes,
} from "../InputTextarea";
import InputDate, {
  InputDateDefaultProps,
  InputDatePropTypes,
} from "../InputDate";
import InputPhone, {
  InputPhoneDefaultProps,
  InputPhonePropTypes,
} from "../InputPhone";
import InputSearch, {
  InputSearchDefaultProps,
  InputSearchPropTypes,
} from "../InputSearch";
import InputPostCode, {
  InputPostCodeDefaultProps,
  InputPostCodePropTypes,
} from "../InputPostCode";
import InputDateRange, {
  InputDateRangeDefaultProps,
  InputDateRangePropTypes,
} from "../InputDateRange";
import InputDateTime, {
  InputDateTimeDefaultProps,
  InputDateTimePropTypes,
} from "../InputDateTime";
import InputNumeric, {
  InputNumericDefaultProps,
  InputNumericPropTypes,
} from "../InputNumeric";
import InputAutocomplete, {
  InputAutocompleteDefaultProps,
  InputAutocompletePropTypes,
} from "../InputAutocomplete";

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.oneOf([
    "text",
    "password",
    "checkbox",
    "select",
    "textarea",
    "date",
    "phone",
    "search",
    "postCode",
    "dateRange",
    "dateTime",
    "numeric",
    "autocomplete",
  ]),
  inputText: PropTypes.shape(InputTextPropTypes),
  inputPassword: PropTypes.shape(InputPasswordPropTypes),
  inputCheckbox: PropTypes.shape(InputCheckboxPropTypes),
  inputSelect: PropTypes.shape(InputSelectPropTypes),
  inputPhone: PropTypes.shape(InputPhonePropTypes),
  inputTextarea: PropTypes.shape(InputTextareaPropTypes),
  inputDate: PropTypes.shape(InputDatePropTypes),
  inputDateRange: PropTypes.shape(InputDateRangePropTypes),
  inputDateTime: PropTypes.shape(InputDateTimePropTypes),
  inputSearch: PropTypes.shape(InputSearchPropTypes),
  inputPostCode: PropTypes.shape(InputPostCodePropTypes),
  inputNumeric: PropTypes.shape(InputNumericPropTypes),
  inputAutocomplete: PropTypes.shape(InputAutocompletePropTypes),
  className: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: "text",
  inputText: InputTextDefaultProps,
  inputPassword: InputPasswordDefaultProps,
  inputCheckbox: InputCheckboxDefaultProps,
  inputSelect: InputSelectDefaultProps,
  inputPhone: InputPhoneDefaultProps,
  inputTextarea: InputTextareaDefaultProps,
  inputDate: InputDateDefaultProps,
  inputDateRange: InputDateRangeDefaultProps,
  inputDateTime: InputDateTimeDefaultProps,
  inputSearch: InputSearchDefaultProps,
  inputPostCode: InputPostCodeDefaultProps,
  inputNumeric: InputNumericDefaultProps,
  inputAutocomplete: InputAutocompleteDefaultProps,
  className: "",
};

const renderInput = (props) => {
  const {
    type,
    inputText,
    inputPassword,
    inputCheckbox,
    inputSelect,
    inputPhone,
    inputTextarea,
    inputDate,
    inputDateRange,
    inputDateTime,
    inputSearch,
    inputPostCode,
    inputNumeric,
    inputAutocomplete,
    className,
  } = props;

  switch (type) {
    case "text":
      return <InputText {...inputText} className={className} />;
    case "password":
      return <InputPassword {...inputPassword} className={className} />;
    case "checkbox":
      return <InputCheckbox {...inputCheckbox} className={className} />;
    case "select":
      return <InputSelect {...inputSelect} className={className} />;
    case "textarea":
      return <InputTextarea {...inputTextarea} className={className} />;
    case "date":
      return <InputDate {...inputDate} className={className} />;
    case "dateRange":
      return <InputDateRange {...inputDateRange} className={className} />;
    case "dateTime":
      return <InputDateTime {...inputDateTime} className={className} />;
    case "phone":
      return <InputPhone {...inputPhone} className={className} />;
    case "numeric":
      return <InputNumeric {...inputNumeric} className={className} />;
    case "search":
      return <InputSearch {...inputSearch} className={className} />;
    case "postCode":
      return <InputPostCode {...inputPostCode} className={className} />;
    case "autocomplete":
      return <InputAutocomplete {...inputAutocomplete} className={className} />;
    default:
      return <InputText {...inputText} className={className} />;
  }
};
/**
 * Displays the component
 */
const Input = (props) => {
  return renderInput(props);
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
export { propTypes as InputPropTypes, defaultProps as InputDefaultProps };

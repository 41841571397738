/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  blank: {
    height: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  fabProgress: {
    color: theme.palette.primary.main,
    margin: "0.1rem 0"
  },
  pdfContainer: {
    maxHeight: "90vh"
  },
  navbarWrapper: {
    backgroundColor: "#0a0a0a",
    padding: "0.5rem",
    color: "#fff",
    width: "100%"
  },
  canvasCss: {
    maxHeight: "90vh",
    overflow: "hidden",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
      justifyContent: "flex-start",
      maxHeight: "100%"
    }
  },
  wrapper: {
    width: "100%"
  }
}));

export { useStyles };

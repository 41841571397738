import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useUser, useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditOrgAddressModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditOrgAddressModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    county: PropTypes.string,
    number: PropTypes.string,
    post_code: PropTypes.string,
    street: PropTypes.string,
    description: PropTypes.string,
  }),
  addressData: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    name: "",
    city: "",
    country: "",
    county: "",
    number: "",
    post_code: "",
    street: "",
    description: "",
  },
  addressData: {
    address: {},
  },
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditOrgAddressModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    addressData,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    errorMessages,
    loadingText,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the global user setter
   */
  const { setUser } = useUser();

  /**
   * Gets the address data
   */
  const { address } = addressData;

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const {
    name,
    city,
    country,
    county,
    number,
    post_code,
    street,
    description,
  } = inputs;

  const {
    name: nameError,
    city: cityError,
    country: countryError,
    county: countyError,
    number: numberError,
    post_code: post_codeError,
    street: streetError,
    description: descriptionError,
  } = errors;

  /**
   * Handles updating the user organization address
   */
  const updateUserOrganizationAddress = async (data, id) => {
    try {
      const updatedAddress = await apiClient.put(`/addresses/${id}`, data);
      if (updatedAddress) {
        getUserOrganizations();
      }
    } catch (error) {
      setLoading(false);
      setReady(false);

      /**
       * Dispatch the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles getting the user organizations
   */
  const getUserOrganizations = async () => {
    try {
      const organizations = await apiClient.get("/organizations");
      if (organizations) {
        setLoading(false);
        resetInputs();

        /**
         * Dispatch the success message
         */
        dispatchMessage({
          icon: false,
          delay: 300,
          message: t("successMessage"),
        });

        setUser((prevState) => {
          return { ...prevState, organizations: organizations.data };
        });
        handleCloseModal();
      }
    } catch (error) {
      setReady(false);
      /**
       * Dispatch the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setReady(false);
    setErrors({});
    closeModal();
  };

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        city,
        country,
        county,
        number,
        street,
      };

      if (post_code) data["post_code"] = post_code;
      if (description) data["description"] = description;
      if (name) data["name"] = name;

      updateUserOrganizationAddress(data, address.id);
    }
    // eslint-disable-next-line
  }, [ready]);

  /**
   * Handles updating the input with the address data
   */
  useEffect(() => {
    if (address) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          name: address.name ? address.name : "",
          city: address.city ? address.city : "",
          country: address.country ? address.country : "",
          county: address.county ? address.county : "",
          number: address.number ? address.number : "",
          post_code: address.post_code ? address.post_code : "",
          street: address.street ? address.street : "",
          description: address.description ? address.description : "",
        };
      });
    }
    // eslint-disable-next-line
  }, [address, open]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleEditAddress")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            <Form
              {...form}
              id="edit-org-address-modal"
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "name",
                              name: "name",
                              value: name,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("nameLabel"),
                              error: nameError,
                              autoFocus: getAutoFocus().name,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "city",
                              required: true,
                              name: "city",
                              value: city,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("cityLabel"),
                              placeholder: t("cityPlaceholder"),
                              error: cityError,
                              autoFocus: getAutoFocus().city,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "country",
                              required: true,
                              name: "country",
                              value: country,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("countryLabel"),
                              placeholder: t("countryPlaceholder"),
                              error: countryError,
                              autoFocus: getAutoFocus().country,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "county",
                              required: true,
                              name: "county",
                              value: county,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("countyLabel"),
                              placeholder: t("countyPlaceholder"),
                              error: countyError,
                              autoFocus: getAutoFocus().county,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "number",
                              required: true,
                              name: "number",
                              value: number,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("numberLabel"),
                              error: numberError,
                              autoFocus: getAutoFocus().number,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "post_code",
                              name: "post_code",
                              value: post_code,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("post_codeLabel"),
                              error: post_codeError,
                              autoFocus: getAutoFocus().post_code,
                            }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Input
                            {...input}
                            type="text"
                            className={classes.field}
                            inputText={{
                              id: "street",
                              required: true,
                              name: "street",
                              value: street,
                              onChange: handleInputChange,
                              maxSize: 70,
                              variant: "standard",
                              label: t("streetLabel"),
                              placeholder: t("streetPlaceholder"),
                              error: streetError,
                              autoFocus: getAutoFocus().street,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            {...input}
                            type="textarea"
                            className={classes.textarea}
                            inputTextarea={{
                              id: "description",
                              name: "description",
                              value: description,
                              onChange: handleInputChange,
                              showCount: true,
                              rows: 6,
                              rowsMax: 14,
                              maxChars: 50,
                              size: "medium",
                              variant: "outlined",
                              label: t("descriptionLabel"),
                              ariaLabel: t("descriptionLabel"),
                              error: descriptionError,
                              autoFocus: getAutoFocus().description,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="submit"
            variant="filled"
            form="edit-org-address-modal"
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditOrgAddressModal.propTypes = propTypes;
EditOrgAddressModal.defaultProps = defaultProps;

export default EditOrgAddressModal;
export {
  propTypes as EditOrgAddressModalPropTypes,
  defaultProps as EditOrgAddressModalDefaultProps,
};

import React, { useContext, createContext } from "react";

/**
 * Hooks
 */
import { useUserApi } from "./useUserApi";

/**
 * Defines a context where user is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the `useUser` hook fetch the current user, the hook simply calls useContext to get the data from the top level provider
 */
const userContext = createContext();

/**
 * Provides a top level user wrapper with the user context
 *
 * - This is the main user provider
 * - It makes the user object available to any child component that calls `useUser`.
 */
const UserProvider = props => {
  const { children } = props;

  const userData = useUserApi();

  return (
    <userContext.Provider value={{ ...userData }}>
      {children}
    </userContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the auth context / object
 * - To be used inside components
 */
const useUser = () => {
  return useContext(userContext);
};

export { useUser, UserProvider };

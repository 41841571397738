import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import AddWorkerModal, {
  AddWorkerModalDefaultProps,
  AddWorkerModalPropTypes
} from "../AddWorkerModal";
import DeleteWorkerModal, {
  DeleteWorkerModalDefaultProps,
  DeleteWorkerModalPropTypes
} from "../DeleteWorkerModal";
import EditWorkerModal, {
  EditWorkerModalDefaultProps,
  EditWorkerModalPropTypes
} from "../EditWorkerModal";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes
} from "../LoadingText";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes
} from "../LoadingBackdrop";
import WorkersTable, {
  WorkersTableDefaultProps,
  WorkersTablePropTypes
} from "../WorkersTable";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes
} from "../SubmoduleContainer";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import SearchIcon from "@material-ui/icons/Search";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Hooks
 */
import { useLocalStorage, useUser, useForm, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsWorkers.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./AccountSettingsWorkers.defaults";

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  workersTable: PropTypes.shape(WorkersTablePropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  addWorkerModal: PropTypes.shape(AddWorkerModalPropTypes),
  editWorkerModal: PropTypes.shape(EditWorkerModalPropTypes),
  deleteWorkerModal: PropTypes.shape(DeleteWorkerModalPropTypes),
  defaultSearch: PropTypes.object,
  accountTypes: PropTypes.array,
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    organization: PropTypes.string,
    function: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string
  }),
  path: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  workersTable: WorkersTableDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  addWorkerModal: AddWorkerModalDefaultProps,
  editWorkerModal: EditWorkerModalDefaultProps,
  deleteWorkerModal: DeleteWorkerModalDefaultProps,
  defaultSearch: defaults.searchParams,
  accountTypes: defaults.accountTypes,
  orderByList: defaults.orderBy,
  defaultValues: {
    name: "",
    organization: "",
    function: "",
    orderBy: "",
    orderDir: ""
  },
  path: "/workers"
};

/**
 * Displays the component
 */
const AccountSettingsWorkers = (props) => {
  const {
    title,
    wrapper,
    container,
    workersTable,
    form,
    input,
    loadingText,
    loadingBackdrop,
    defaultValues,
    addWorkerModal,
    editWorkerModal,
    deleteWorkerModal,
    button,
    defaultSearch,
    orderByList
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Gets the tablet breakpoint
   */
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Initializes the reset filters flag
   */
  const [filtersReset, setFiltersReset] = useState(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the state related to the Pagination
   */
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useLocalStorage("workersViewPageSize");
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the state that holds the options for the select type search models
   */
  const [organizations, setOrganizations] = useState([]);
  const [orderByOptions, setOrderByOptions] = useState([]);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    addWorker: false,
    editWorker: false,
    deleteWorker: false
  });

  /**
   * Initializes the updated flag - used for retriggering the search when needed
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the state that hold the data for the modals
   */
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});

  /**
   * Handles opening the edit modal
   */
  const openEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const openDelete = (props) => setDeleteData(props);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAddWorker = () => handleOpenModal("addWorker");
  const closeAddWorker = () => handleCloseModal("addWorker");

  /**
   * Defines the edit User modal handlers
   */
  const openEditWorker = () => handleOpenModal("editWorker");
  const closeEditWorker = () => handleCloseModal("editWorker");

  /**
   * Defines the delete User modal handlers
   */
  const openDeleteWorker = () => handleOpenModal("deleteWorker");
  const closeDeleteWorker = () => handleCloseModal("deleteWorker");

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setFiltersReset(true);

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles searching for workers
   */
  const searchWorkers = async (data) => {
    try {
      const workers = await apiClient.post("/workers/search", data);

      if (workers) {
        const { data } = workers;

        const {
          items,
          models,
          page_count,
          page_size,
          order_by,
          order_dir,
          total: totalItems
        } = data;

        /**
         * Resets the states
         */
        setBackdropLoading(false);
        setLoading(false);
        setUpdated(false);

        /**
         * Updates the users array
         */
        setUser((prevState) => {
          return { ...prevState, workers: items };
        });

        /**
         * Updates the search models and the inputs
         */
        setModels(models);
        setInputs((prevState) => {
          return { ...prevState, orderBy: order_by, orderDir: order_dir };
        });

        /**
         * Updates the pagination related states
         */
        setPageCount(page_count);
        setPageSize(page_size);
        setTotal(totalItems);
      }
    } catch (error) {
      /**
       * Resets the states
       */
      setLoading(false);
      setBackdropLoading(false);
      setUpdated(false);
    }
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: inputs.orderBy,
      order_dir: inputs.orderDir,
      page_size: pageSize,
      page_count: 1
    };

    searchWorkers(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { name, organization, function: role, orderBy, orderDir } = inputs;

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: inputs.orderBy,
      order_dir: inputs.orderDir,
      page_size: e.target.value,
      page_count: 1
    };

    searchWorkers(data);
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    return [
      {
        label: "name",
        field: "name",
        type: "like",
        order: 1,
        options: [],
        selected: inputs.name
      },
      {
        label: "function",
        field: "function",
        type: "dropdown",
        order: 1,
        options: [],
        selected: inputs.function
      },
      {
        label: "Organization",
        field: "organization_id",
        type: "dropdown",
        order: 1,
        options: [],
        selected: inputs.organization
      }
    ];
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const data = {
      ...defaultSearch,
      page_size: Number(pageSize) || defaultSearch.page_size,
      page_count: pageCount || 1
    };
    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => searchWorkers(getDefaultSearch());

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    resetInputs();
    setModels(defaultSearch.models);
    handleDefaultSearch();
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (user.workers && user.workers.length < 1 && pageCount === 0) return;
    setBackdropLoading(true);

    /**
     * Defines the request data
     */
    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page
    };

    searchWorkers(data);
    window.scrollTo(0, 0);
  };

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    handleDefaultSearch();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles making a search when updated is triggered
   */
  useEffect(() => {
    if (updated) {
      setModels(defaultSearch.models);

      /**
       * Defines the request data
       */
      const data = {
        ...defaultSearch,
        page_size: pageSize || defaultSearch.page_size,
        page_count: pageCount || 1
      };
      searchWorkers(data);
    }
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles updating the page size with the default if the value can't be found in localstorage
   */
  useEffect(() => {
    if (!pageSize) setPageSize(defaultSearch.page_size);
    // eslint-disable-next-line
  }, [pageSize]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEditWorker();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDeleteWorker();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name
        };
      });
      setOrganizations(orgs);
    }
  }, [user]);

  /**
   * Handles updating the options list for the search model of account types and order by
   */
  useEffect(() => {
    if (orderByList) {
      const orderBys = orderByList.map((orderBy) => {
        return {
          label: t(orderBy),
          value: orderBy
        };
      });

      setOrderByOptions(orderBys);
    }
    // eslint-disable-next-line
  }, [orderByList]);

  useEffect(() => {
    if (filtersReset) {
      setFiltersReset(false);
      resetFilters();
    }
    // eslint-disable-next-line
  }, [filtersReset]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<ContactMailOutlinedIcon />}
        title={t("titleWorkers")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Zoom in={true} timeout={500}>
          <Grid container>
            <Grid item xs={12}>
              <Form {...form} id="search-user" onSubmit={handleSubmit}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Card className={classes.blank}>
                      <CardContent className={classes.cardContent}>
                        <Grid
                          container
                          spacing={isTablet ? 2 : 1}
                          wrap={isTablet ? "wrap" : "nowrap"}
                        >
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                name: "name",
                                value: name,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "outlined",
                                labelClass: classes.label,
                                InputLabelProps: {
                                  className: classes.label
                                },
                                label: t("nameLabel")
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                name: "function",
                                value: role,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "outlined",
                                labelClass: classes.label,
                                InputLabelProps: {
                                  className: classes.label
                                },
                                label: t("functionLabel")
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "organization",
                                value: organization,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("organizationLabel"),
                                options: organizations,
                                optionLabel: "label",
                                optionValue: "value"
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "orderBy",
                                value: orderBy,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                label: t("orderByLabel"),
                                variant: "outlined",
                                options: orderByOptions,
                                optionLabel: "label",
                                optionValue: "value"
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "orderDir",
                                value: orderDir,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                label: t("orderDirLabel"),
                                variant: "outlined",
                                options: [
                                  { label: t("asc"), value: "asc" },
                                  { label: t("desc"), value: "desc" }
                                ],
                                optionLabel: "label",
                                optionValue: "value"
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} lg={3} xl={2}>
                            <div className={classes.btnContainer}>
                              <Button
                                {...button}
                                type="button"
                                variant="filled"
                                title={t("undo")}
                                onClick={resetFilters}
                                className={classes.undoBtn}
                              >
                                <UndoIcon />
                              </Button>
                              <Button
                                {...button}
                                type="submit"
                                variant="filled"
                                onClick={handleSubmit}
                                className={classes.submitButton}
                              >
                                <LoadingText
                                  {...loadingText}
                                  size={24}
                                  loading={loading}
                                  text={t("submitSave")}
                                  icon={<SearchIcon />}
                                />
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </Zoom>
        <Grid container>
          <WorkersTable
            {...workersTable}
            handleAdd={openAddWorker}
            handleEdit={openEdit}
            handleDelete={openDelete}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleFiltersReset={handleFiltersReset}
            rowsPerPage={Number(pageSize)}
            page={pageCount}
            count={total}
          />
        </Grid>
        <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
        <AddWorkerModal
          {...addWorkerModal}
          open={modalState.addWorker}
          closeModal={closeAddWorker}
          organizations={organizations}
          setUpdated={setUpdated}
          defaultSearch={defaultSearch}
        />
        <EditWorkerModal
          {...editWorkerModal}
          open={modalState.editWorker}
          closeModal={closeEditWorker}
          editData={editData}
          setEditData={setEditData}
          setUpdated={setUpdated}
          organizations={organizations}
        />
        <DeleteWorkerModal
          {...deleteWorkerModal}
          open={modalState.deleteWorker}
          closeModal={closeDeleteWorker}
          deleteData={deleteData}
          setUpdated={setUpdated}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

AccountSettingsWorkers.propTypes = propTypes;
AccountSettingsWorkers.defaultProps = defaultProps;

export default AccountSettingsWorkers;
export {
  propTypes as AccountSettingsWorkersPropTypes,
  defaultProps as AccountSettingsWorkersDefaultProps
};

const defaults = {
  searchParams: {
    models: [
      {
        label: "name",
        field: "name",
        type: "like",
        order: 3,
        options: [],
        selected: ""
      },
      {
        label: "function",
        field: "function",
        type: "equal",
        order: 4,
        options: [],
        selected: ""
      },
      {
        label: "Organization",
        field: "organization_id",
        type: "dropdown",
        order: 2,
        options: [],
        selected: ""
      }
    ],
    order_by: "created_at",
    order_dir: "desc",
    page_size: Number(localStorage.getItem("workersViewPageSize")) || 10,
    page_count: 1
  },
  accountTypes: ["operator"],
  orderBy: ["created_at", "name", "function", "birth_date", "gender"],
  pageSizeOptions: [6, 10, 20, 50, 100]
};

export { defaults };

import { useState, useEffect } from "react";

/**
 * External Imports
 */
import { format as formatDate } from "date-fns";

/**
 * Imports hooks
 */
import { useStopwatch } from "react-timer-hook";
import { useLocalStorage } from "./index";

/**
 * Defines the date format
 */
const dateFormat = "yyyy-MM-dd HH:mm:ss";

/**
 * Defines the main hook
 */
export const useTimer = (timerId, options) => {
  const { open, offsetTimestamp } = options;

  /**
   * Initializes a start time
   */
  const [startTime, setStartTime] = useState("");

  /**
   * Initializes the timer
   */
  const [timer, setTimer] = useLocalStorage(timerId, {});

  /**
   * Starts the stopwatch and gets the hours/mins/seconds
   */
  const { hours, minutes, seconds } = useStopwatch({
    autoStart: open,
    offsetTimestamp: offsetTimestamp,
  });

  /**
   * Gets the timer from local storage
   * @param {String} timerId
   */
  const getTimer = (timerId) => JSON.parse(localStorage.getItem(timerId));

  /**
   * Removes a timer from local storage
   */
  const removeTimer = (timerId) => localStorage.removeItem(timerId);

  /**
   * Handles getting the duration in seconds
   */
  const getDurationInSeconds = () => hours * 60 * 60 + minutes * 60 + seconds;

  /**
   * Gets the current time - formatted
   */
  const getCurrentTime = () => {
    const h = hours > 9 ? hours : `0${hours}`;
    const m = minutes > 9 ? minutes : `0${minutes}`;
    const s = seconds > 9 ? seconds : `0${seconds}`;

    return `${h}:${m}:${s}`;
  };

  /**
   * Handles getting the local start time
   */
  const getLocalStartTime = () => {
    if (timer.startTime) {
      return timer.startTime;
    }
    return formatDate(new Date(startTime), dateFormat);
  };

  /**
   * Handles saving the timer data to local storage
   */
  const saveToLocalStorage = () => {
    /**
     * Formats hours
     */
    const h = hours > 9 ? hours : `0${hours}`;

    /**
     * Formats minutes
     */
    const m = minutes > 9 ? minutes : `0${minutes}`;

    /**
     * Formats seconds
     */
    const s = seconds > 9 ? seconds : `0${seconds}`;

    setTimer((prevState) => ({
      ...prevState,
      time: `${h}:${m}:${s}`,
      startTime: getLocalStartTime(),
      endTime: formatDate(new Date(), dateFormat),
      duration: getDurationInSeconds(),
    }));
  };

  /**
   * Initializes the start time
   */
  useEffect(() => {
    setStartTime(new Date());
  }, []);


  return {
    timer,
    setTimer,
    getTimer,
    removeTimer,
    saveToLocalStorage,
    getCurrentTime,
    startTime,
    getDurationInSeconds,
  };
};

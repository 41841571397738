/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "1rem",
    justifyContent: "center",
  },
  addBtn: {
    ...theme.mixins.submitBtn,
    background: theme.palette.success.main,
    marginBottom: "1rem",
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  submitBtn: {
    padding: "0.5rem 1.5rem",
    minWidth: 220,
    marginLeft: 10,
    borderRadius: 0,
    fontWeight: 400,
    fontSize: 20,
    height: 40,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: "none",
    },
  },
  calendarOpen: {
    backgroundColor: theme.palette.error.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      boxShadow: "none",
    },
  }
}));

export { useStyles };

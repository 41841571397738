import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports Components
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Imports Styles
 */
import { useStyles } from "./ReportsCompaniesTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  data: PropTypes.array,
  isPrinting: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  data: [],
  isPrinting: false,
};

/**
 * Displays the component
 */
const ReportsCompaniesTable = (props) => {
  const { data, isPrinting } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the collection
   */
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const list = isPrinting
        ? [
            {
              uuid: t("uuid"),
              car_plate_number: t("car_plate_number"),
              client_name: t("companyName"),
              delegate_name: t("name"),
              tyre_dimension: t("tyre_dimension"),
              products_name: t("products_name"),
              total: t("total"),
              payment_type_name: t("payment"),
              finished: t("finished"),
              is_header: true,
              is_company: true,
            },
            ...data,
          ]
        : data;

      setCollection(list);
    }
    // eslint-disable-next-line
  }, [isPrinting, data]);

  return (
    <TableContainer>
      <Table stickyHeader className={clsx(classes.table, "page-break")}>
        <TableHead
          className={clsx({
            [classes.hiddenHeader]: isPrinting,
          })}
        >
          <TableRow>
            <TableCell> #</TableCell>
            <TableCell align="right"> {t("uuid")}</TableCell>
            <TableCell align="right"> {t("car_plate_number")}</TableCell>
            <TableCell align="right"> {t("companyName")} </TableCell>
            <TableCell align="right">{t("name")}</TableCell>
            <TableCell align="right">{t("tyre_dimension")}</TableCell>
            <TableCell align="right">{t("products_name")}</TableCell>
            <TableCell align="right" style={{ textAlign: "right" }}>
              {t("total")}
            </TableCell>
            <TableCell align="right">{t("payment")}</TableCell>
            <TableCell align="right">{t("finished")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection.map((item, index) => {
            const {
              uuid,
              car_plate_number,
              client_name,
              delegate_name,
              tyre_dimension,
              total,
              payment_type_name,
              is_company,
              is_header,
              products_name,
              finished,
            } = item;

            const headerClasses =
              "MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader";

            const cellClass = clsx({
              [headerClasses]: is_header,
            });

            const alignRight = { textAlign: "right" };

            return (
              <TableRow key={uuid}>
                <TableCell component="th" scope="row" className={cellClass}>
                  {isPrinting ? (index === 0 ? "#" : index) : index + 1}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {uuid}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {car_plate_number}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {is_company ? client_name : "-"}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {is_company
                    ? delegate_name
                      ? delegate_name
                      : "-"
                    : client_name}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {tyre_dimension}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {products_name}
                </TableCell>
                <TableCell
                  align="right"
                  className={cellClass}
                  style={alignRight}
                >
                  {isPrinting ? total : parseFloat(total).toFixed(2)}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {payment_type_name}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {finished}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportsCompaniesTable.propTypes = propTypes;
ReportsCompaniesTable.defaultProps = defaultProps;

export default ReportsCompaniesTable;
export {
  propTypes as ReportsCompaniesTablePropTypes,
  defaultProps as ReportsCompaniesTableDefaultProps,
};

const quickTextModel = {
  label: "Quick Text",
  field: "quick_text",
  type: "like",
  order: 1,
  options: [],
  selected: null,
};

const organizationIdModel = {
  label: "Organization",
  field: "organization_id",
  type: "dropdown",
  order: 2,
  options: [],
  selected: null,
};

const isActiveModel = {
  label: "Active",
  field: "is_active",
  type: "boolean",
  order: 2,
  options: [],
  selected: null,
};

const cardNumberModel = {
  label: "Card Number",
  field: "card_number",
  type: "equal",
  order: 2,
  options: [],
  selected: null,
};

const discountTypeModel = {
  label: "Discount Type",
  field: "discount_type",
  type: "dropdown",
  order: 3,
  options: [],
  selected: null,
};

const discountValueModel = {
  label: "Discount Value",
  field: "discount_value",
  type: "equal",
  order: 4,
  options: [],
  selected: null,
};

const defaults = {
  models: {
    quickTextModel,
    organizationIdModel,
    isActiveModel,
    cardNumberModel,
    discountTypeModel,
    discountValueModel,
  },
  searchParams: {
    models: [
      quickTextModel,
      organizationIdModel,
      isActiveModel,
      cardNumberModel,
      discountTypeModel,
      discountValueModel,
    ],
    order_by: "created_at",
    order_dir: "desc",
    page_size: Number(localStorage.getItem("loyaltyCardsViewPageSize")) || 15,
  },
  orderBy: ["created_at"],
  pageSizeOptions: [15, 30, 50, 100],
};

export { defaults };

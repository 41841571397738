import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Imports assets
 */
import ro_flag from "./ro_flag.png";
import hu_flag from "./hu_flag.png";
import en_flag from "./en_flag.png";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

/**
 * Hooks
 */
import { useLanguage } from "../../hooks";

/**
 * Imports i18n
 */
import i18n from "../../i18n";

/**
 * Imports the component styles
 */
import { useStyles } from "./LanguageChanger.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  localStorageKey: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  localStorageKey: "i18nextLng",
};

/**
 * Displays the component
 */
const LanguageChanger = (props) => {
  // eslint-disable-next-line
  const { localStorageKey } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the active language setter
   */
  const { activeLanguage, setActiveLanguage } = useLanguage();

  /**
   * Initializes the language dropdown menu
   */
  const [openDropdown, setOpenDropdown] = useState(false);

  /**
   * Handles toggling the language dropdown menu
   */
  const toggleLanguageMenuDropdown = () =>
    setOpenDropdown((prevState) => !prevState);

  /**
   * Handles changing the language
   * @param {String} lang
   */
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
    setOpenDropdown(false);
  };

  const renderActiveLanguageMenuItem = () => {
    if (activeLanguage === "en_ro") {
      return (
        <div
          className={classes.activeLanguageItem}
          onClick={toggleLanguageMenuDropdown}
        >
          <img src={ro_flag} alt="Romanian" /> RO
        </div>
      );
    }

    if (activeLanguage === "en_hu") {
      return (
        <div
          className={classes.activeLanguageItem}
          onClick={toggleLanguageMenuDropdown}
        >
          <img src={hu_flag} alt="Hungarian" /> HU
        </div>
      );
    }

    if (activeLanguage === "en_en") {
      return (
        <div
          className={classes.activeLanguageItem}
          onClick={toggleLanguageMenuDropdown}
        >
          <img src={en_flag} alt="English" /> EN
        </div>
      );
    }
  };

  return (
    <Typography
      variant="h3"
      className={classes.timesheet}
      style={{ position: "relative" }}
    >
      {renderActiveLanguageMenuItem()}

      {openDropdown && (
        <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
          <div className={classes.dropdownContainer}>
            <div
              onClick={() => changeLanguage("en_ro")}
              className={classes.langMenuItem}
            >
              <img src={ro_flag} alt="Romanian" /> RO
              {activeLanguage === "en_ro" && <CheckIcon />}
            </div>
            <div
              onClick={() => changeLanguage("en_hu")}
              className={classes.langMenuItem}
            >
              <img src={hu_flag} alt="Hungarian" /> HU
              {activeLanguage === "en_hu" && <CheckIcon />}
            </div>
            <div
              onClick={() => changeLanguage("en_en")}
              className={classes.langMenuItem}
            >
              <img src={en_flag} alt="English" /> EN
              {activeLanguage === "en_en" && <CheckIcon />}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </Typography>
  );
};

LanguageChanger.propTypes = propTypes;
LanguageChanger.defaultProps = defaultProps;

export default LanguageChanger;
export {
  propTypes as LanguageChangerPropTypes,
  defaultProps as LanguageChangerDefaultProps,
};

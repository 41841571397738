import React, { forwardRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Material UI Imports
 */
import { Button as MUIButton } from "@material-ui/core";

/**
 * Styles Import
 */
import { useStyles } from "./Button.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
  type: PropTypes.string,
  component: PropTypes.any,
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.any,
  title: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  children: "Button",
  variant: "filled",
  type: "button",
  component: "button",
  to: "/",
  className: "",
  disabled: false,
  form: null,
  title: ""
};

/**
 * Displays the component
 */
const Button = forwardRef((props, ref) => {
  const {
    disabled,
    children,
    variant,
    type,
    to,
    className,
    component,
    onClick,
    form,
    title
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the variant flags
   */
  const isFilled = variant === "filled";
  const isOutlined = variant === "outlined";

  /**
   * Gets the component variant based on the flags
   */
  const getVariant = () => (isFilled ? "contained" : "outlined");
  const buttonVariant = getVariant();

  /**
   * Builds the button base classes
   */
  const buttonBaseClasses = clsx(classes.button, {
    [classes.filled]: isFilled,
    [classes.outlined]: isOutlined,
    [className]: true
  });

  /**
   * Defines the button properties
   */
  const btnProps = {
    className: buttonBaseClasses,
    variant: buttonVariant,
    ref,
    disabled,
    form,
    onClick,
    type,
    component,
    to,
    title
  };

  return <MUIButton {...btnProps}> {children} </MUIButton>;
});

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
export { propTypes as ButtonPropTypes, defaultProps as ButtonDefaultProps };

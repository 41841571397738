/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  modalContent: {
    maxHeight: 660,
    [theme.breakpoints.down("md")]: {
      maxHeight: "none"
    }
  },
  modulesContainer: {
    margin: "0.5rem 0"
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start"
    }
  },
  form: {
    background: "transparent",
    boxShadow: "none"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  checkboxContainer: {
    marginTop: "1rem",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main
    }
  },
  checkbox: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  field: theme.mixins.searchField,
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white
    }
  },
  selectField: theme.mixins.searchSelect,
  submitBtn: theme.mixins.submitBtn,
  formSection: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    color: theme.palette.sidebar.text,
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem"
    }
  },
  textarea: {
    marginTop: "0.5rem"
  }
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Material UI Imports Components
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/**
 * Imports Styles
 */
import { useStyles } from "./ReportsTyreWasteTable.styles";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Defines the prop types
 */
const propTypes = {
  data: PropTypes.array,
  dateFormat: PropTypes.string,
  isPrinting: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  data: [],
  dateFormat: "dd-MM-yyyy HH:mm",
  isPrinting: false,
};

/**
 * Displays the component
 */
const ReportsTyreWasteTable = (props) => {
  const { data, isPrinting, dateFormat } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the car types
   */
  const [carTypes, setCarTypes] = useState([]);

  const getCarTypeName = (id) => {
    const found = carTypes.find((type) => type.id === id);
    return found ? found.name : "-";
  };

  /**
   * Handles filling up the car types list
   */
  useEffect(() => {
    if (user.settings) {
      const carTypes = user.settings.account.car_types;
      setCarTypes(carTypes);
    }
  }, [user]);

  const list = isPrinting
    ? [
        {
          created_at: t("created_at"),
          plate_number: t("plate_number"),
          client_name: t("client_name"),
          delegate_name: t("delegate_name"),
          work_order_uuid: t("id"),
          car_type_id: t("car_type_id"),
          tyre_dimension: t("tyre_dimension"),
          tyre_quantity: t("tyre_quantity"),
          is_header: true,
          is_company: true,
        },
        ...data,
      ]
    : data;

  return (
    <TableContainer>
      <Table stickyHeader className={clsx(classes.table, "page-break")}>
        <TableHead
          className={clsx({
            [classes.hiddenHeader]: isPrinting,
          })}
        >
          <TableRow>
            <TableCell> #</TableCell>
            <TableCell align="right"> {t("created_at")}</TableCell>
            <TableCell align="right"> {t("plate_number")}</TableCell>
            <TableCell align="right"> {t("client_name")} </TableCell>
            <TableCell align="right">{t("delegate_name")}</TableCell>
            <TableCell align="right">{t("id")}</TableCell>
            <TableCell align="right">{t("car_type_id")}</TableCell>
            <TableCell align="right">{t("tyre_dimension")}</TableCell>
            <TableCell align="right">{t("tyre_quantity")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            const {
              created_at,
              plate_number,
              client_name,
              delegate_name,
              work_order_uuid,
              car_type_id,
              tyre_width,
              tyre_height,
              tyre_rim,
              tyre_quantity,
              is_header,
              is_company,
            } = item;

            const headerClasses =
              "MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader";

            const cellClass = clsx({
              [headerClasses]: is_header,
            });

            return (
              <TableRow key={work_order_uuid}>
                <TableCell component="th" scope="row" className={cellClass}>
                  {isPrinting ? (index === 0 ? "#" : index) : index + 1}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {isPrinting
                    ? index === 0
                      ? t("created_at")
                      : formatDate(new Date(created_at), dateFormat)
                    : formatDate(new Date(created_at), dateFormat)}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {plate_number}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {client_name}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {is_company
                    ? delegate_name
                      ? delegate_name
                      : "-"
                    : client_name}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {work_order_uuid}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {getCarTypeName(car_type_id)}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {`${tyre_width}/${tyre_height} ${tyre_rim}`}
                </TableCell>
                <TableCell align="right" className={cellClass}>
                  {tyre_quantity}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportsTyreWasteTable.propTypes = propTypes;
ReportsTyreWasteTable.defaultProps = defaultProps;

export default ReportsTyreWasteTable;
export {
  propTypes as ReportsTyreWasteTablePropTypes,
  defaultProps as ReportsTyreWasteTableDefaultProps,
};

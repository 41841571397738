import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps,
} from "../DynamicTable";

/**
 * Defines the prop types
 */
const propTypes = {
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  data: PropTypes.array,
  apiCallMade: PropTypes.bool,
  isPrinting: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  dynamicTable: DynamicTableDefaultProps,
  data: [],
  apiCallMade: false,
  isPrinting: false,
};

/**
 * Displays the component
 */
const ReportsDailyTable = (props) => {
  const { data, dynamicTable, apiCallMade, isPrinting } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () => data && data.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = () => {
    let tableOptions = {
      withCount: true,
      withAdd: false,
      withSearch: false,
      withPagination: false,
      withStats: false,
      withSort: true,
      withPrint: isPrinting,
      helpers: {
        resetSearch: false,
        resetFilters: false,
        addResult: false,
      },
      pagination: {},
      defaultOrderBy: "client_name",
      fields: [
        {
          name: "uuid",
          label: t("uuid"),
          align: "center",
          minSize: 150,
          maxSize: 150,
          key: true,
          searchField: false,
          sort: false,
        },
        {
          name: "car_plate_number",
          label: t("car_plate_number"),
          align: "center",
          minSize: 50,
          maxSize: 100,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "client_name",
          label: t("companyName"),
          align: "center",
          minSize: 200,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "delegate_name",
          label: t("name"),
          align: "center",
          minSize: 100,
          maxSize: 100,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "tyre_dimension",
          label: t("tyre_dimension"),
          align: "center",
          minSize: 100,
          maxSize: 100,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "products_name",
          label: t("products_name"),
          align: "center",
          minSize: 200,
          maxSize: 200,
          key: true,
          searchField: true,
          sort: false,
        },
        {
          name: "total",
          label: t("total"),
          align: "right",
          minSize: 0,
          maxSize: 150,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "payment_type_name",
          label: t("payment"),
          align: "center",
          minSize: 100,
          maxSize: 100,
          key: true,
          searchField: true,
          sort: true,
        },
        {
          name: "finished",
          label: t("finished"),
          align: "center",
          minSize: 150,
          maxSize: 150,
          key: true,
          searchField: true,
          sort: true,
          type: "date"
        },
      ],
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound && apiCallMade}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions();
    setTableOptions(options);
    // eslint-disable-next-line
  }, []);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    const collection = data.map((item) => {
      const { delegate_name, is_company, client_name, total, finished } = item;

      return {
        ...item,
        finished: new Date(finished),
        total: parseFloat(total).toFixed(2),
        client_name: is_company ? client_name : null,
        delegate_name: is_company
          ? delegate_name
            ? delegate_name
            : "-"
          : client_name,
      };
    });
    setCollection(collection);
    // eslint-disable-next-line
  }, [data]);

  return renderDynamicTable();
};

ReportsDailyTable.propTypes = propTypes;
ReportsDailyTable.defaultProps = defaultProps;

export default ReportsDailyTable;
export {
  propTypes as ReportsDailyTablePropTypes,
  defaultProps as ReportsDailyTableDefaultProps,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";

/**
 * Material UI Imports
 */
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

/**
 * Styles Import
 */
import { useStyles } from "./InputCheckbox.styles";

/**
 * Defines the prop types
 */
const propTypes = {
    value: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
    value: false,
    name: "checkbox",
    label: "",
    required: false,
    onChange: () => {},
    className: ""
};

/**
 * Displays the component
 */
const InputCheckbox = props => {
    const {
        value,
        name,
        label,
        required,
        onChange,
        className,
        classes,
        fieldset,
        checkComponent,
        uncheckComponent,
        disabled
    } = props;

    /**
     * Gets the component styles
     */
    const baseClasses = useStyles();

    /**
     * Initializes the default checked state
     */

    const [checkedValue, setCheckedValue] = useState(value);

    /**
     * Renders the appropriate icon
     */
    const getIcon = () => {
        if (value) {
            if (checkComponent) {
                return checkComponent;
            } else {
                return <CheckBoxIcon />;
            }
        } else {
            if (uncheckComponent) {
                return uncheckComponent;
            } else {
                return <CheckBoxOutlineBlankIcon />;
            }
        }
    };

    /**
     * Renders the label
     */
    const getLabel = () => {
        const hasLabel = label.length > 0;
        return hasLabel ? (
            <Typography
                className={clsx(baseClasses.label, { [className]: true })}
            >
                {label}
            </Typography>
        ) : null;
    };


    /**
     * Renders the base checkbox component
     */
    const getControl = () => (
        <Checkbox
            classes={classes}
            name={name}
            checked={checkedValue}
            icon={getIcon()}
            checkedIcon={getIcon()}
            onChange={onChange}
            value={checkedValue}
            required={required}
            disabled={disabled}
        />
    );

    useEffect(() => {
        setCheckedValue(value);
    }, [value]);

    return (
        <FormControl component="fieldset" className={fieldset}>
            <FormGroup>
                <FormControlLabel control={getControl()} label={getLabel()} />
            </FormGroup>
        </FormControl>
    );
};

InputCheckbox.propTypes = propTypes;
InputCheckbox.defaultProps = defaultProps;

export default InputCheckbox;
export {
    propTypes as InputCheckboxPropTypes,
    defaultProps as InputCheckboxDefaultProps
};

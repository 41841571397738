/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    marginTop: "0.5rem",
    border: `1px solid #b9b9b9`,
  },
  loader: {
    color: theme.palette.common.white,
    width: "1.5rem",
    height: "1.5rem",
  },
}));

export { useStyles };

/**
 * Hooks
 */
import { useValidator } from "../../hooks";

/**
 * Defines the validator
 */
const Validator = (props) => {
  const { translator: t, activeStep } = props;
  const { isEmpty, isLength, isEmail, isUrl } = useValidator();

  /**
   * Checks if the input has a certain length
   */
  const isLengthTextBuilder = (value, options) => {
    let { min, max } = options;

    if (!min) min = 1;
    if (!max) max = 1;

    const minLength = t("LanguageProvider:error_minLength");
    const maxLength = t("LanguageProvider:error_maxLength");
    const chars = t("Form:characters");

    if (value.length < min) return `${minLength} ${min} ${chars}`;
    if (value.length > max) return `${maxLength} ${max} ${chars}`;

    return true;
  };

  const validatorFn = (props) => {
    const { name, value } = props;
    const options = { min: 6, max: 65 };
    let error = null;

    switch (name) {
      case "first_name":
      case "last_name":
      case "phone":
        if (activeStep === 1 && isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "username":
        if (activeStep === 2 && isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "password":
        if (activeStep === 2 && isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        if (activeStep === 2 && !isLength(value, options))
          return (error = isLengthTextBuilder(value, options));
        return error;
      case "company_name":
      case "vat_number":
      case "reg_number":
      case "city":
      case "number":
      case "street":
      case "county":
      case "country":
      case "company_phone":
        if (activeStep === 0 && isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        return error;
      case "company_email":
        if (activeStep === 0 && isEmpty(value))
          return (error = t("LanguageProvider:error_isRequired"));
        if (activeStep === 0 && !isEmail(value))
          return (error = t("LanguageProvider:error_emailInvalid"));
        return error;
      case "company_webpage":
        if (activeStep === 0 && value && !isUrl(value))
          return (error = t("LanguageProvider:error_urlInvalid"));
        return error;
      default:
        return error;
    }
  };

  return validatorFn;
};

export default Validator;

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import { format as formatDate } from "date-fns";
import { ro } from "date-fns/locale";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import TimesheetLogEntry, {
  TimesheetLogEntryDefaultProps,
  TimesheetLogEntryPropTypes,
} from "../TimesheetLogEntry";
import TimesheetAdminView, {
  TimesheetAdminViewDefaultProps,
  TimesheetAdminViewPropTypes,
} from "../TimesheetAdminView";
import TimesheetReport, {
  TimesheetReportDefaultProps,
  TimesheetReportPropTypes,
} from "../TimesheetReport";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import TodayIcon from "@material-ui/icons/Today";
import Typography from "@material-ui/core/Typography";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TimesheetModal.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  logEntry: PropTypes.shape(TimesheetLogEntryPropTypes),
  adminView: PropTypes.shape(TimesheetAdminViewPropTypes),
  report: PropTypes.shape(TimesheetReportPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  dateFormat: PropTypes.string,
  dateFormatOptions: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  logEntry: TimesheetLogEntryDefaultProps,
  adminView: TimesheetAdminViewDefaultProps,
  report: TimesheetReportDefaultProps,
  button: ButtonDefaultProps,
  organizations: [],
  dateFormat: "dd MMMM yyyy",
  dateFormatOptions: { locale: ro },
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const TimesheetModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    logEntry,
    adminView,
    report,
    open,
    closeModal,
    button,
    organizations,
    dateFormat,
    dateFormatOptions,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the active views
   */
  const [activeView, setActiveView] = useState(0);

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Gets the global theme
   */
  const theme = useTheme();

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Handles buidling the modal title string
   */
  const buildModalTitleString = () => {
    const today = new Date();
    const dateString = formatDate(today, dateFormat, dateFormatOptions);
    const timesheetString = t("titleTimesheet");

    return `${timesheetString} - ${dateString}`;
  };

  /**
   * Defines the Title component used in the Modal Title component
   */
  const Title = () => {
    return (
      <Typography variant="caption" className={classes.title}>
        <Typography variant="caption" className={classes.dateText}>
          {buildModalTitleString()}
          <TodayIcon />
        </Typography>
      </Typography>
    );
  };

  const switchToNormalView = () => setActiveView(0);
  const switchToAdminView = () => setActiveView(1);
  const switchToReportsView = () => setActiveView(2);

  /**
   * Handles rendering the admin actions
   */
  const renderAdminActions = () => {
    const { type } = user;

    if (type !== "admin") return null;
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <div className={classes.timesheetActions}>
            <Button
              {...button}
              type="button"
              variant="filled"
              onClick={switchToNormalView}
              className={clsx(classes.tabBtn, {
                [classes.activeTabBtn]: activeView === 0,
              })}
            >
              {t("viewNormal")}
            </Button>
            <Button
              {...button}
              type="button"
              variant="filled"
              onClick={switchToAdminView}
              className={clsx(classes.tabBtn, {
                [classes.activeTabBtn]: activeView === 1,
              })}
            >
              {t("viewAdmin")}
            </Button>
            <Button
              {...button}
              type="button"
              variant="filled"
              onClick={switchToReportsView}
              className={clsx(classes.tabBtn, {
                [classes.activeTabBtn]: activeView === 2,
              })}
            >
              {t("attendanceReport")}
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth={activeView !== 1 ? "lg" : "lg"}
        open={open}
        onClose={closeModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle {...modalTitle} title={<Title />} onClick={closeModal} />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12} className={classes.modulesContainer}>
            {renderAdminActions()}
            <TimesheetLogEntry
              {...logEntry}
              activeView={activeView}
              organizations={organizations}
            />
            <TimesheetAdminView
              {...adminView}
              activeView={activeView}
              organizations={organizations}
            />
            <TimesheetReport
              {...report}
              activeView={activeView}
              organizations={organizations}
            />
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={closeModal} />
      </Modal>
    </Fragment>
  );
};

TimesheetModal.propTypes = propTypes;
TimesheetModal.defaultProps = defaultProps;

export default TimesheetModal;
export {
  propTypes as TimesheetModalPropTypes,
  defaultProps as TimesheetModalDefaultProps,
};

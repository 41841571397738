import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import "date-fns";
import { format as formatDate } from "date-fns";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import WorkerAttendanceModal, {
  WorkerAttendanceModalDefaultProps,
  WorkerAttendanceModalPropTypes
} from "../WorkerAttendanceModal";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./TimesheetLogEntry.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  attendanceModal: PropTypes.shape(WorkerAttendanceModalPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  activeView: PropTypes.number
};

/**
 * Defines the default props
 */
const defaultProps = {
  attendanceModal: WorkerAttendanceModalDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  organizations: [],
  activeView: null
};

/**
 * Displays the component
 */
const TimesheetLogEntry = (props) => {
  const { activeView, input, button, organizations, attendanceModal } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Initializes the password confirm modal for the attendance entry
   */
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  /**
   * Initializes the modal data for the above mentioned modal, (name, id of worker)
   */
  const [modalData, setModalData] = useState({});

  /**
   * Initializes the updated flag, used to trigger getting the workers after an attendance update
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the workers list
   */
  const [workers, setWorkers] = useState([]);

  /**
   * Initializes the workers options used in the autoComplete input
   */
  const [workersOptions, setWorkersOptions] = useState([]);

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState("");

  /**
   * Initializes the workers table
   */
  const [workersTable, setWorkersTable] = useState([]);

  /**
   * Initializes the attendance ID
   * Used in the confirmation modal when ending your attendance for the day
   */
  const [attendanceID, setAttendanceID] = useState(0);

  /**
   * Initializes the search state
   */
  const [search, setSearch] = useState("");

  /**
   * Gets the global user and the setter
   */
  const { user } = useUser();

  /**
   * Handles updating the active organization
   */
  const handleOrganizationChange = (e) => setActiveOrg(e.target.value);

  /**
   * Handles updating the modal data
   */
  const updateModalData = (props) => setModalData(props);

  /**
   * Checks if the object is empty
   */
  const isEmptyObject = (obj) => Object.keys(obj).length < 1;

  /**
   * Returns the group by field (firstLetter)
   * Used in the workers search input (autoComplete)
   */
  const groupByFirstLetter = (option) => option.firstLetter;

  /**
   * Handles selecting a value only if the condition is met
   * Used in the workers search input (autoComplete)
   */
  const getOptionSelected = (option, value) => option.name === value;

  /**
   * Handles formatting the first letter, "0-9" if it's numerical, and the letter if it's not.
   */
  const formatFirstLetter = (firstLetter) =>
    /[0-9]/.test(firstLetter) ? "0-9" : firstLetter;

  /**
   * Handles grouping the workers by the first letter of their name
   * Will also sort the final results alphabetically.
   */
  const groupWorkersByFirstLetter = (workers) => {
    const options = workers.map((worker) => {
      /**
       * Gets the first letter from the workers name
       */
      const firstLetter = worker.name[0].toUpperCase();

      return {
        firstLetter: formatFirstLetter(firstLetter),
        ...worker
      };
    });
    return options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    setModalData({});
    setConfirmModalOpen(false);
  };

  /**
   * Handles changing the product
   */
  const handleWorkerSearch = (event, newValue) => {
    setSearch(newValue);
    if (newValue.length < 1) {
      setWorkersTable(workersOptions);
    }
    if (newValue.length > 1) {
      const newWorkers = workersOptions.filter((worker) => {
        return worker.name.toLowerCase().includes(newValue.toLowerCase());
      });
      setWorkersTable(newWorkers);
    }
  };

  /**
   * Handles updating the attendance ID
   */
  const updateAttendanceID = (option) => {
    if (option.attendance_today && option.attendance_today.id) {
      setAttendanceID(option.attendance_today.id);
    }
  };

  /**
   * Gets the attendance time range
   */
  const getAttendanceTimeRange = ({ option, type }) => {
    /**
     * Gets todays attendance object
     */
    const { attendance_today } = option;

    /**
     * Defines the time format
     */
    const timeFormat = "H:mm";

    /**
     * Defines the condition for enabling the start date display
     */
    const started =
      attendance_today && attendance_today.start_date
        ? attendance_today.start_date
        : null;

    /**
     * Defines the condition for enabling the end date display
     */
    const ended =
      attendance_today && attendance_today.end_date
        ? attendance_today.end_date
        : null;

    /**
     * Defines the start and end dates
     */
    const startDate = started ? new Date(attendance_today.start_date) : null;
    const endDate = ended ? new Date(attendance_today.end_date) : null;

    return {
      render: type === "start" ? started : ended,
      startDate: started ? formatDate(startDate, timeFormat) : "",
      endDate: ended ? formatDate(endDate, timeFormat) : ""
    };
  };

  /**
   * Defines the Attendance Button component
   */
  const AttendanceButton = (props) => {
    const { type, openStart, openEnd, startDate } = props;

    const icon =
      type === "start" ? (
        <VpnKeyIcon className={classes.attendanceIcon} />
      ) : (
        <ExitToAppIcon className={classes.attendanceIcon} />
      );
    const text = type === "start" ? t("startAttendance") : t("endAttendance");
    const onClickHandler = type === "start" ? openStart : openEnd;
    const className =
      type === "start" ? classes.startAttendance : classes.endAttendance;

    return (
      <Button
        {...button}
        type="button"
        variant="filled"
        disabled={!startDate && type === "end"}
        onClick={onClickHandler}
        className={clsx(classes.attendance, className)}
      >
        {icon} {text}
      </Button>
    );
  };

  /**
   * Defines the AttendanceStart component
   */
  const Attendance = (props) => {
    const { type, option } = props;

    /**
     * Defines the handler that opens the start attendance modal
     */
    const openStart = () => updateModalData({ ...option });

    /**
     * Defines the handler that opens the end attendance modal
     */
    const openEnd = () => {
      updateModalData({ ...option });
      updateAttendanceID(option);
    };

    const { render, startDate, endDate } = getAttendanceTimeRange(props);

    const date = type === "start" ? startDate : endDate;

    return render ? (
      date
    ) : (
      <AttendanceButton
        type={type}
        startDate={startDate}
        openStart={openStart}
        openEnd={openEnd}
      />
    );
  };

  /**
   * Defines the WorkersTable Component
   */
  const WorkersTable = () => {
    return workersTable && workersTable.length > 0
      ? workersTable.map((option, idx) => {
          return (
            <TableRow key={`${option.name}_${idx}`}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>{option.name}</TableCell>
              <TableCell>
                <Attendance type="start" option={option} />
              </TableCell>
              <TableCell>
                <Attendance type="end" option={option} />
              </TableCell>
            </TableRow>
          );
        })
      : null;
  };

  /**
   * Handles updating the workers option and table data
   */
  useEffect(() => {
    if (workers.length > 0 && activeOrg) {
      const options = workers.filter(
        (worker) => worker.organization_id === activeOrg
      );
      setWorkersOptions(options);
      setWorkersTable(options);
    }
  }, [workers, activeOrg]);

  /**
   * Gets the workers by setting the api call params
   */
  useEffect(() => {
    // if (activeView === 0 || updated) setGetWorkersParams(getWorkers());
    // eslint-disable-next-line
  }, [activeView, updated]);

  /**
   * Handles updating the active organization on mount
   */
  useEffect(() => {
    if (activeView === 0 && organizations && organizations.length > 0) {
      setActiveOrg(user.organization ? user.organization.id : "");
    }
    // eslint-disable-next-line
  }, [activeView, organizations]);

  /**
   * Handles resetting the search input when changing the active organization
   */
  useEffect(() => {
    if (activeOrg) setSearch("");
  }, [activeOrg]);

  /**
   * Handles opening the confirm modal when the modal data is ready
   */
  useEffect(() => {
    if (!isEmptyObject(modalData)) setConfirmModalOpen(true);
  }, [modalData]);

  useEffect(() => {
    if (user.workers) {
      const workers = groupWorkersByFirstLetter(user.workers);
      setAttendanceID(0);
      setWorkers(workers);
      setUpdated(false);
    }
    // eslint-disable-next-line
  }, [user]);

  return activeView === 0 ? (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Card className={classes.blank}>
          <CardContent>
            <Grid container item xs={12}>
              <Grid item container xs={12} md={8} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    className={classes.selectField}
                    type="select"
                    inputSelect={{
                      value: activeOrg,
                      onChange: handleOrganizationChange,
                      label: t("organization_idLabel"),
                      labelClass: classes.label,
                      variant: "outlined",
                      options: organizations,
                      optionLabel: "label",
                      optionValue: "value"
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    {...input}
                    type="autocomplete"
                    className={classes.field}
                    inputAutocomplete={{
                      inputValue: search,
                      onChange: handleWorkerSearch,
                      variant: "outlined",
                      freeSolo: false,
                      autoComplete: true,
                      autoSelect: true,
                      label: t("workersLabel"),
                      options: workersOptions,
                      groupBy: groupByFirstLetter,
                      getOptionSelected
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer className={classes.tableContainer}>
                  <Table className={classes.table} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("countSymbol")}</TableCell>
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("startDate")}</TableCell>
                        <TableCell>{t("endDate")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <WorkersTable />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
          <WorkerAttendanceModal
            {...attendanceModal}
            open={confirmModalOpen}
            data={modalData}
            attendanceID={attendanceID}
            closeModal={handleCloseModal}
            setUpdated={setUpdated}
          />
        </Card>
      </Grid>
    </Grid>
  ) : null;
};

TimesheetLogEntry.propTypes = propTypes;
TimesheetLogEntry.defaultProps = defaultProps;

export default TimesheetLogEntry;
export {
  propTypes as TimesheetLogEntryPropTypes,
  defaultProps as TimesheetLogEntryDefaultProps
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import { Redirect, useHistory } from "react-router-dom";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import ReCaptcha, {
  ReCaptchaDefaultProps,
  ReCaptchaPropTypes,
} from "../ReCaptcha";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import { RegisterPageDefaultProps } from "../RegisterPage";

/**
 *  Material UI Imports
 */
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Hooks
 */
import { useForm, useAuth, useUser, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./LoginForm.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./LoginForm.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  reCaptcha: PropTypes.shape(ReCaptchaPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  defaultValues: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  reCaptcha: ReCaptchaDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  defaultValues: {
    username: "",
    password: "",
  },
};

/**
 * Displays the component
 */
const LoginForm = (props) => {
  const { button, input, defaultValues, reCaptcha, form, loadingText } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Gets the login controller and the global user setter
   */
  const { login, isAuthenticated } = useAuth();

  /**
   * Gets the global user setter
   */
  const { setUser } = useUser();

  /**
   * Initialize the states for loading/success/focus
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the reCaptcha ready state
   */
  const [readyRecaptcha, setReadyRecaptcha] = useState(false);

  /**
   * Initialize the state flag responsible for resetting the captcha
   */
  const [resetCaptcha, setResetCaptcha] = useState(false);

  /**
   * Initialize errors state for api errors
   */
  const [apiErrors, setApiErrors] = useState({
    username: "",
    password: "",
  });

  /**
   * Handles logging a user in
   */
  const loginUser = async (data) => {
    try {
      const loggedIn = await apiClient.post("/login", data);
      if (loggedIn) {
        const { data } = loggedIn;
        setLoading(false);
        setUser(data.user);
        login(data);
      }
    } catch (error) {
      setApiErrors(error);
      setLoading(false);
    }
  };

  /**
   * Handles capturing the captcha token
   */
  const handleCaptcha = (token) => {
    setInputs((inputs) => {
      return { ...inputs, captcha: token };
    });
  };

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    loginUser(inputs);
  };

  /**
   * Handles routing to the register page
   */
  const handleRegisterPageRouting = () =>
    history.push(RegisterPageDefaultProps.path);

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    setInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { username, password } = inputs;
  const { username: usernameError, password: passwordError } = errors;

  /**
   * Handles showing the recaptcha
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setReadyRecaptcha(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Handles updating the inputs if the default values change
   */
  useEffect(() => {
    setInputs((inputs) => {
      return { ...inputs, ...defaultValues };
    });
    // eslint-disable-next-line
  }, [defaultValues]);

  /**
   * Stop the loading animation if there's any errors.
   */
  useEffect(() => {
    if (apiErrors.username || apiErrors.password) {
      setLoading(false);
    }
  }, [usernameError, passwordError, apiErrors]);

  /**
   * Resets the api error messages if the user has changed the input
   */
  useEffect(() => {
    if (usernameError && apiErrors.username && apiErrors.username.length > 0) {
      setApiErrors((prevState) => {
        return { ...prevState, username: "" };
      });
    }
  }, [usernameError, apiErrors]);

  /**
   * Resets the api error message if the input is changed
   */
  useEffect(() => {
    if (passwordError && apiErrors.password && apiErrors.password.length > 0) {
      setApiErrors((prevState) => {
        return { ...prevState, password: apiErrors.password[0] };
      });
    }
  }, [passwordError, apiErrors]);

  /**
   * Resets the api errors if the user types in again
   */
  useEffect(() => {
    if (username && apiErrors.username) {
      setApiErrors((prevState) => {
        return { ...prevState, username: "" };
      });
    }
    if (password && apiErrors.password) {
      setApiErrors((prevState) => {
        return { ...prevState, password: "" };
      });
    }
    // eslint-disable-next-line
  }, [username, password]);

  /**
   * Redirects to the modules page if is authenticated
   */
  if (isAuthenticated) return <Redirect to="/modules" />;

  return (
    <Form {...form} className={classes.loginForm} onSubmit={handleSubmit}>
      <Box className={classes.inputContainer}>
        <Input
          {...input}
          type="text"
          className={classes.field}
          inputText={{
            id: "username",
            required: true,
            name: "username",
            value: username,
            onChange: handleInputChange,
            maxSize: 70,
            variant: "standard",
            label: t("usernameLabel"),
            error: usernameError || t(apiErrors.username),
            autoFocus: getAutoFocus().username,
          }}
        />
      </Box>
      <Box className={classes.inputContainer}>
        <Input
          {...input}
          type="password"
          className={classes.field}
          inputPassword={{
            id: "password",
            required: true,
            name: "password",
            value: password,
            variant: "standard",
            onChange: handleInputChange,
            label: t("password"),
            error: passwordError || t(apiErrors.password),
            autoFocus: getAutoFocus().password,
            maxSize: 64,
            showHelper: false,
          }}
        />
      </Box>
      {readyRecaptcha ? (
        <ReCaptcha
          {...reCaptcha}
          setResetCaptcha={setResetCaptcha}
          resetCaptcha={resetCaptcha}
          handleCaptcha={handleCaptcha}
        />
      ) : (
        <Box className={classes.spinnerContainer}>
          <CircularProgress size={45} className={classes.spinnerLg} />
        </Box>
      )}

      <Box className={classes.authActions}>
        <Typography
          variant="subtitle1"
          onClick={handleRegisterPageRouting}
          className={classes.label}
        >
          {t("registerLabel")}
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          {...button}
          type="submit"
          variant="filled"
          disabled={!inputs.captcha}
          className={classes.submitBtn}
        >
          <LoadingText {...loadingText} loading={loading} text={t("send")} />
        </Button>
      </Box>
    </Form>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
export {
  propTypes as LoginFormPropTypes,
  defaultProps as LoginFormDefaultProps,
};

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 * @see https://material-ui.com/components/pickers/#date-time-pickers
 */
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { ro } from "date-fns/locale";
import { format as formatDate } from "date-fns";
import clsx from "clsx";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";

/**
 * Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

/**
 * Styles Import
 */
import { useStyles } from "./InputDateRange.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  format: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  className: PropTypes.any,
  formatValue: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  format: "yyyy/MM/dd",
  label: "",
  startDateLabel: "",
  endDateLabel: "",
  disabled: false,
  minDate: new Date("1950"),
  maxDate: new Date("2099"),
  margin: "normal",
  name: "InputDateRange",
  onChange: () => {},
  value: "",
  required: false,
  className: "",
  formatValue: null,
};

/**
 * Displays the component
 */
const InputDateRange = (props) => {
  const {
    label,
    button,
    format,
    name,
    onChange,
    value,
    required,
    className,
    InputLabelProps,
    modal,
    modalTitle,
    modalContent,
    modalActions,
    startDateLabel,
    endDateLabel,
    formatValue,
  } = props;

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Gets the component styles
   */
  const baseClasses = useStyles();

  /**
   * Initializes the modal state
   */
  const [open, setOpen] = useState(false);

  /**
   * Initializes the start and end date
   */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  /**
   * Handles changing the start and end dates
   */
  const handleStartDate = (date) => setStartDate(date);
  const handleEndDate = (date) => setEndDate(date);

  /**
   * Handles formatting the final input and changing the state of the base text field component
   */
  const handleChange = () => {
    const value = formatValue
      ? formatValue(startDate, endDate)
      : `${formatDate(new Date(startDate), format)} - ${formatDate(
          new Date(endDate),
          format
        )} `;
    onChange(value, name);
    handleCloseModal();
  };

  /**
   * Handles the modal state changes
   */
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  /**
   * Handles the subtle validation of the dates
   */
  useEffect(() => {
    if (startDate > endDate) setStartDate(endDate);
    if (endDate < startDate) setEndDate(startDate);
  }, [startDate, endDate]);

  return (
    <Fragment>
      <TextField
        required={required}
        value={value}
        label={label}
        variant="outlined"
        className={clsx(baseClasses.margin, baseClasses.textField, className)}
        disabled={true}
        InputLabelProps={InputLabelProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={baseClasses.adornment}>
              <IconButton button="true" onClick={handleOpenModal}>
                <DateRangeIcon className={baseClasses.icon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Modal
        {...modal}
        classes={{ root: baseClasses.modal }}
        fullScreen={triggerFullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle {...modalTitle} onClick={handleCloseModal} />
        <ModalContent {...modalContent} className={baseClasses.modalContent}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ro}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date-picker-inline"
                label={startDateLabel}
                value={startDate}
                onChange={handleStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                margin="normal"
                id="end-date-picker-inline"
                label={endDateLabel}
                value={endDate}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleChange}
            className={baseClasses.submitButton}
          >
            OK
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

InputDateRange.propTypes = propTypes;
InputDateRange.defaultProps = defaultProps;

export default InputDateRange;
export {
  propTypes as InputDateRangePropTypes,
  defaultProps as InputDateRangeDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  conditonPrice: {
    fontWeight: 800,
    fontFamily: theme.font.family,
    padding: "0.25rem",
    paddingLeft: "0.5rem",
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    display: "flex",
    flex: 1,
    width: "100%"
  },
  flat: {
    background: theme.palette.primary.main
  },
  up: {
    background: theme.palette.success.main
  },
  priceCondition: {
    padding: 0,
    margin: "0.5rem",
    background: "#fff",
    color: theme.palette.sidebar.text,
    borderRadius: 3,
    fontFamily: theme.font.family,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    border: "1px solid #bfbfbf",
    "& svg": {
      width: "0.9em",
      height: "0.9em"
    }
  },
  conditionText: {
    padding: "0.25rem",
    paddingRight: "0.5rem",
    background: theme.palette.sidebar.text,
    color: theme.palette.common.white,
    display: "flex",
    flex: 1,
    width: "100%"
  },
  conditionActions: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    padding: "0.25rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff"
    }
  },
  deleteButton: {
    padding: "0.25rem",
    marginLeft: 0,
    minWidth: 0,
    "& svg": {
      margin: 0,
      color: "#fff"
    }
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    alignItems: "center",
    flex: 1,
    marginTop: "1rem",
    background: "#f0edec",
    border: "1px solid #d6d6d6",
    padding: "0.25rem 0.5rem"
  },
  priceConditionsTitle: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem"
    }
  },
  smallBtn: {
    margin: "0 0.5rem",
    padding: "0.1rem",
    fontSize: "0.7rem",
    minWidth: 0,
    "& svg": {
      margin: 0,
      width: 24,
      height: 24
    }
  },
  priceLabel: {
    display: "flex",
    alignItems: "center",
    fontWeight: 800
  },
  priceValue: {
    fontWeight: 800,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "0.15rem 1.5rem",
    borderRadius: 3,
    marginLeft: "0.5rem"
  },
  priceConditions: {
    background: "#f0edec",
    border: "1px solid #d6d6d6",
    borderTop: "none",
    padding: "0.25rem"
  },
  downIcon: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: 2,
    borderRadius: "50%",
    marginBottom: "0.3rem"
  },
  conditionsContainer: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column"
  },
  section: {
    border: `1px solid #b3b3b3`,
    borderBottom: "none",
    borderTop: "none"
  },
  title: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.sidebar.text,
    fontFamily: theme.font.family,
    fontSize: "0.8rem",
    margin: "0 0.5rem",
    padding: "0 0.5rem",
    borderBottom: `1px solid #b3b3b3`,
    "& svg": {
      marginRight: "0.35rem",
      fontSize: "1.6rem",
      width: "1.7rem",
      height: "1.7rem"
    }
  },
  flatIcon: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 2,
    borderRadius: "50%",
    marginBottom: "0.3rem"
  },
  upIcon: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    padding: 2,
    borderRadius: "50%",
    marginBottom: "0.3rem"
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white
    }
  },
  modalContent: {
    maxHeight: 660,
    [theme.breakpoints.down("md")]: {
      maxHeight: "none"
    }
  },
  modulesContainer: {
    margin: "0.5rem 0"
  },
  formContainer: {
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start"
    }
  },
  form: {
    background: "transparent",
    boxShadow: "none"
  },
  blank: {
    background: "transparent",
    boxShadow: "none"
  },
  field: theme.mixins.searchField,
  selectField: theme.mixins.searchSelect,
  formSection: {
    fontSize: "0.85rem",
    fontFamily: theme.font.family,
    color: theme.palette.sidebar.text,
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem"
    }
  },
  textarea: {
    marginTop: "0.5rem"
  },
  submitBtn: theme.mixins.submitBtn
}));

export { useStyles };

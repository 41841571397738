import axios from "axios";

/**
 * Defines the api client function
 */
export const getApiClient = (config) => {
  const { withCredentials, logout, dispatchMessage, token, t } = config;

  /**
   * Handles getting the base api url
   */
  const getApiUrl = () => {
    if (process.env.NODE_ENV === "development") {
      return "http://localhost:3000/api/v1"; // with proxy
    }
    return "https://app-api.atelierulmeu.ro/api/v1";
  };

  /**
   * Creates the api client by configuring axios
   */
  const apiClient = axios.create({
    baseURL: getApiUrl(),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Api-Version": "v1",
    },
  });

  /**
   * Intercepts each request and handles the response / errors and headers
   */
  apiClient.interceptors.request.use((request) => {
    if (withCredentials) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;
  });

  apiClient.interceptors.response.use(
    (response) => {
      return (response && response.data) || {};
    },
    async (error) => {
      if (error.response) {
        const data = error.response.data;

        if (error.response.status === 500) {
          return dispatchMessage({
            severity: "error",
            message: data.errorMessage,
          });
        }

        if (error.response.status === 401) {
          dispatchMessage({
            message: t(data.errorMessage),
            severity: "error",
          });
          return logout();
        }

        return dispatchMessage({
          message: t(data.errorMessage),
          severity: "error",
        });
      }

      return Promise.reject(error);
    }
  );

  return { apiClient };
};

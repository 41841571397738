import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

/**
 * Defines the prop types
 */
const propTypes = {
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  onSubmit: () => {},
  className: "",
  children: null,
  id: "",
};

/**
 * Displays the component
 */
const Form = (props) => {
  const { className, onSubmit, children, id } = props;

  /**
   * Adds the translations to the resource bundle
   */

  /**
   * Defines the form props
   */
  const formProps = {
    noValidate: true,
    onSubmit,
    className,
  };

  if (id) formProps["id"] = id;

  return <form {...formProps}>{children}</form>;
};

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
export { propTypes as FormPropTypes, defaultProps as FormDefaultProps };

/**
 * Material UI Imports
 */
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

/**
 * Assets Imports
 */
import dotGrid from "../components/Modules/dot-grid.png";

/**
 * Creates the breakpoints
 */
const breakpoints = createBreakpoints({});

/**
 * Defines the drawer width
 */
const drawerWidth = 280;

/**
 * Defines the nav height
 */
const navHeight = 45;

/**
 * Defines the palette of colors
 */
const palette = {
  common: {
    black: "#000",
    white: "#fff",
  },
  primary: {
    // light: "#6e8ba9",
    main: "#282828",
    // main: "#5881ab",
    // dark: "#4c6886",
    // accent: "#3454DB",
    accent: "#13C1CC",
    contrastText: "#fff",
  },
  hover: {
    main: "#13C1CC"
  },
  secondary: {
    lighter: "#3c4850",
    light: "#6b6e70",
    main: "#42474a",
    dark: "#222223",
    contrastText: "#fff",
  },
  sidebar: {
    main: "#f3f3f3",
    text: "#282828",
    // text: "#4e4e4e",
    active: "#b7b7b7",
  },
  button: {
    light: "#616163",
    main: "#181b23",
    dark: "#222223",
    contrastText: "#fff",
  },
  error: {
    light: "#e57373",
    main: "#e85b5b",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  warning: {
    light: "#ffb74d",
    main: "#f3a025",
    dark: "#f57c00",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  info: {
    light: "#64b5f6",
    main: "#2196f3",
    dark: "#1976d2",
    contrastText: "#fff",
  },
  success: {
    light: "#81c784",
    main: "#61ab4f",
    dark: "#339c07",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  border: {
    main: "#eaeaea",
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#d5d5d5",
    A200: "#aaaaaa",
    A300: "#35363d",
    A400: "#303030",
    A700: "#616161",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
  },
  background: {
    default: "#fff",
    paper: "#fff",
  },
};

/**
 * Defines the font families and general font properties
 */
const font = {
  family: "Roboto, sans-serif",
  display: "Roboto, sans-serif",
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bolder: 600,
    bold: 700,
  },
  size: {
    xs: "0.8em",
    s: "0.9em",
    m: "1em",
    l: "1.4em",
    xl: "2em",
  },
};

/**
 * Defines mixins that are re-usable across the app
 */
const mixins = {
  flex: {
    column: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
    },
    columnCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    rowCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    columnEnd: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    rowEnd: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    spacer: {
      display: "flex",
      flex: 1,
    },
  },
  submitBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 0,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    height: 30,
    background: palette.primary.main,
    color: palette.common.white,
    "&:hover": {
      background: palette.hover.main,
      boxShadow: "none",
    },
  },
  deleteBtn: {
    padding: "0.25rem 1rem",
    margin: "0 0.25rem",
    borderRadius: 0,
    fontWeight: 400,
    boxShadow: "none",
    minWidth: 125,
    height: 30,
    background: palette.error.main,
    color: palette.common.white,
    "&:hover": {
      background: palette.error.dark,
      boxShadow: "none",
    },
  },
  searchSelect: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    backgroundColor: "transparent",
    "& .MuiSelect-select:focus": {
      backgroundColor: palette.common.white,
    },
    "& .MuiInputBase-input": {
      backgroundColor: palette.common.white,
      color: palette.common.black,
      height: "0.95rem",
      padding: 12.5,
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.9rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 14,
    },
  },
  searchField: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    backgroundColor: "transparent",
    "& .MuiInputBase-input": {
      color: palette.common.black,
      height: "0.95rem",
      padding: 13,
      fontSize: "0.95rem",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.9rem",
    },
  },
  searchFieldLabel: {
    fontSize: "0.9rem",
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: palette.common.white,
      transform: "translate(15px, -5px) scale(1)",
      padding: "0 0.75rem 0 0.5rem",
      fontSize: "0.7rem",
    },
    "&.MuiInputLabel-outlined": {
      transform: "translate(10px, 15px) scale(1)",
      fontSize: "0.7rem",
    },
  },
  submoduleTitle: {
    margin: "0 2rem",
    borderBottom: "none",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontFamily: "Roboto, sans-serif",
      color: "#3c4850",
    },
    "& svg": {
      color: palette.primary.main,
      fontSize: "2rem",
      width: "1.2em",
      height: "1.2em",
      marginRight: "0.25rem",
    },
  },
  submoduleContainer: {
    marginTop: "5rem",
    marginBottom: "5rem",
    // marginLeft: drawerWidth,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
    [breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
};

/**
 * Defines the zIndex values used across the app
 */
const zIndex = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

/**
 * Creates the Theme
 */
let theme = {
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "transparent",
        },
        html: {
          background: "transparent",
          backgroundImage: `url(${dotGrid})`,
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: palette.common.white,
      },
      paperScrollPaper: {
        display: "flex",
        maxHeight: "calc(100% - 50px)",
        flexDirection: "column",
      },
    },
    MuiPaginationItem: {
      root: {
        background: palette.common.white,
      },
    },
    MuiAutocomplete: {
      paper: {
        background: palette.common.white,
      },
    },
    MuiInputBase: {
      input: {
        height: "1em",
        padding: "6px 0 7px",
        fontSize: "0.9rem",
      },
    },
    MuiInputLabel: {
      filled: {
        fontSize: "0.7rem",
      },
      outlined: {
        fontSize: "0.7rem",
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: palette.common.white,
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.primary.main,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-checked": {
          color: palette.primary.main,
        },
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: palette.common.white,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: palette.common.white,
      },
    },
    MuiFilledInput: {
      underline: {
        "&:hover:before": {
          cursor: "pointer",
          borderBottom: `2px solid ${palette.primary.main}`,
        },
        "&:before": {
          borderBottom: `2px solid ${palette.primary.main}`,
        },
        "&:after": {
          borderBottom: `2px solid ${palette.common.white}`,
        },
      },
      input: {
        position: "relative",
        padding: "12px 12px 12px",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiAlert: {
      icon: {
        alignItems: "center",
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "none"
        }
      },
      contained: {
        "&:hover": {
          boxShadow: "unset",
          backgroundColor: "none",
        }
      }
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: palette.hover.main,
          color: palette.common.white,
          "& svg": {
            color: palette.common.white
          },
        }
      }
    }
  },
  drawerWidth,
  navHeight,
  palette,
  font,
  mixins,
  zIndex,
}
 

export { theme as darkTheme };

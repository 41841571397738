/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  actions: (props) => {
    const { wrapped } = props;

    const styles = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    if (wrapped) styles["flexWrap"] = "wrap";

    return styles;
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  counter: {
    position: "absolute",
    zIndex: 100,
    background: theme.palette.primary.main,
    width: 20,
    height: 20,
    borderRadius: 20,
    left: 19,
    top: -5,
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    padding: "0.1rem",
  },
  checkbox: {
    "&.MuiCheckbox-colorSecondary.Mui-checked":{
      color: theme.palette.success.main
    }
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  loginForm: {
    ...theme.mixins.flex.spacer,
    flexDirection: "column",
    justifyContent: "center",
    width: "100%"
  },
  label: {
    fontSize: "0.8rem",
    color: "#282828",
    margin: "0 0.35rem",
    marginTop: "1.5rem",
    cursor: "pointer",
    fontWeight: 800,
    "&:hover": {
      color: theme.palette.hover.main
    }
  },
  field: {
    ...theme.mixins.flex.column,
    fontWeight: "normal",
    backgroundColor: "transparent",
    flex: 1,
    margin: 0,
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      height: "0.95rem"
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.95rem"
    }
  },
  submitBtn: {
    ...theme.mixins.submitBtn,
    marginTop: "0.5rem",
    "&:hover": {
      backgroundColor: theme.palette.hover.main
    }
  },
  inputContainer: {
    ...theme.mixins.flex.rowCenter,
    flexDirection: "column",
    alignItems: "unset",
    margin: "0 1rem",
    marginBottom: "0.35rem",
    flex: 1
  },
  authActions: theme.mixins.flex.rowCenter,
  buttonContainer: {
    ...theme.mixins.flex.rowCenter,
    margin: "0 0.5rem"
  },
  loginModal: {
    padding: "0 0.5rem"
  },
  spinnerContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  spinnerLg: {
    color: theme.palette.primary.main
  },
  content: {
    padding: "0.5rem",
    marginTop: "1rem"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  CarServiceMain: {
    border: "1px solid",
    padding: "0.5rem",
    margin: "0.5rem",
    backgroundColor: theme.palette.common.white
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: "0 2rem",
    borderTop: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh"
  }
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  year: {
    position: "absolute",
    left: -50,
    top: 7,
    fontSize: "1rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  widget: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    padding: "0.25rem 0.75rem",
    background: theme.palette.sidebar.main,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
  },
  title: {
    fontSize: "0.8rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    textTransform: "uppercase",
    color: theme.palette.sidebar.text,
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  value: {
    fontSize: "1rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
}));

export { useStyles };

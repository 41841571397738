import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import shortid from "shortid";

/**
 * Material UI Imports
 */
import TextField from "@material-ui/core/TextField";

/**
 * Styles Imports
 */
import { useStyles } from "./InputNumeric.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
  inputClasses: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.any,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  InputLabelProps: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: "text",
  label: "",
  value: "",
  variant: "filled",
  inputClasses: {},
  onChange: () => {},
  name: "textInput",
  error: "",
  required: false,
  className: "",
  placeholder: "",
  margin: "none",
  fullWidth: false,
  autoFocus: false,
  id: "textInput",
  maxSize: 1000,
  disabled: false,
  InputLabelProps: {},
  showHelper: true
};

/**
 * Displays the component
 */
const InputNumeric = props => {
  const {
    type,
    label,
    value,
    variant,
    inputClasses,
    onChange,
    name,
    error,
    required,
    className,
    placeholder,
    margin,
    fullWidth,
    autoFocus,
    id,
    maxSize,
    disabled,
    inputProps,
    InputLabelProps,
    showHelper
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Defines the Input Component Props
   */
  const InputProps = { ...inputClasses, ...inputProps };

  /**
   * Defines the base input props
   */
  const baseInputProps = { autoFocus: autoFocus, maxLength: maxSize };

  /**
   * Applies autofocus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  /**
   * Renders the hidden text if there's no erros or helper messages
   */
  const hiddenText = () => <span className={classes.hidden}>error_msg</span>;

  /**
   * Gets the helper text/or error
   */
  const getHelperText = () => (error ? error : hiddenText());
  const helperText = getHelperText();

  /**
   * Handles numeric inputs
   */
  const handleNumericInput = e => {
    /**
     * Allow special chars such as tab, delete, backspace, slash
     * @see https://keycode.info/
     */
    const allowedCharacters = [
      8,
      9,
      13,
      27,
      32,
      37,
      39,
      46,
      54,
      109,
      110,
      111,
      188,
      189,
      190,
      191
    ];

    /**
     * Allows: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
     */
    const specialCommands =
      (e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
      (e.ctrlKey === true || e.metaKey === true);

    /**
     * Allows numbers
     */
    const isNumber =
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105);

    if (allowedCharacters.indexOf(e.keyCode) !== -1 || specialCommands) {
      /**
       * Let it happen, don't do anything
       */
      return;
    }

    /**
     * Ensure that it is a number and stop the keypress
     */
    if (isNumber) {
      e.preventDefault();
    }
  };

  return (
    <TextField
      id={`${id}-${shortid.generate()}`}
      disabled={disabled}
      inputRef={inputRef}
      className={className}
      name={name}
      label={label}
      placeholder={placeholder}
      variant={variant}
      margin={margin}
      error={error ? true : false}
      helperText={showHelper ? helperText : null}
      autoFocus={autoFocus}
      type={type}
      InputProps={InputProps}
      onKeyDown={handleNumericInput}
      inputProps={baseInputProps}
      InputLabelProps={InputLabelProps}
      value={value ? value : ""}
      fullWidth={fullWidth}
      onChange={onChange}
      required={required}
    />
  );
};

InputNumeric.propTypes = propTypes;
InputNumeric.defaultProps = defaultProps;

export default InputNumeric;
export {
  propTypes as InputNumericPropTypes,
  defaultProps as InputNumericDefaultProps
};

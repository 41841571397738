import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import TableSearchInput, {
  TableSearchInputPropTypes,
  TableSearchInputDefaultProps,
} from "../TableSearchInput";
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";

/**
 *  Material UI Imports
 */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import UndoIcon from "@material-ui/icons/Undo";

/**
 * Styles Imports
 */
import { useStyles } from "./TableActions.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  createButton: PropTypes.shape(CreateButtonPropTypes),
  searchInput: PropTypes.shape(TableSearchInputPropTypes),
  options: PropTypes.object,
  pagination: PropTypes.object,
  dataCount: PropTypes.number,
  loading: PropTypes.bool,
  search: PropTypes.string,
  searchReady: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleResetSearch: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  createButton: CreateButtonDefaultProps,
  searchInput: TableSearchInputDefaultProps,
  options: {},
  pagination: {},
  dataCount: 0,
  loading: false,
  search: "",
  searchReady: false,
  handleSearch: () => {},
  handleResetSearch: () => {},
};

/**
 * Displays the component
 */
const TableActions = (props) => {
  const {
    options,
    pagination,
    dataCount,
    loading,
    search,
    searchReady,
    handleSearch,
    handleResetSearch,
    button,
    createButton,
    searchInput,
    preventScrollTopOnSearch,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the table options
   */
  const { withSearch, withAdd, withPagination, withStats, addResult } = options;

  /**
   * Gets the pagination count
   */
  const getPaginationCount = () =>
    withPagination && pagination && pagination.total;

  /**
   * Decides if to render the component
   */
  const render = withSearch || withAdd || withSearch || withStats;

  /**
   * Gets the pagination count
   */
  const count = getPaginationCount();

  /**
   * Gets the stats grid size
   */
  const getStatsSize = () => {
    return !withSearch && !withAdd ? 12 : 7;
  };

  /**
   * Gets the table stats text
   */
  const getStatsText = () => {
    const total = count ? count : dataCount;

    return `${dataCount} ${t("outOf")} ${total} ${t("shownResults")}`;
  };

  /**
   * Gets the search input prefix
   */
  const getPrefix = () => {
    return loading ? (
      <CircularProgress size={20} color="primary" />
    ) : (
      <SearchIcon className={classes.icon} />
    );
  };

  /**
   * Handles displaying the search
   */
  const displaySearch = () => {
    return withSearch ? (
      <Grid item xs={12} sm={3}>
        <TableSearchInput
          {...searchInput}
          id="search_input"
          classes={{ root: classes.field }}
          placeholder={t("quick_textLabel")}
          value={search}
          onChange={handleSearch}
          debounce={searchReady}
          prefix={getPrefix()}
          preventScrollTopOnSearch={preventScrollTopOnSearch}
        />
      </Grid>
    ) : null;
  };

  /**
   * Handles displaying the add action
   */
  const displayAdd = () => {
    return withAdd ? (
      <CreateButton
        {...createButton}
        className={classes.addBtn}
        onClick={addResult}
      />
    ) : null;
  };

  /**
   * Handles displaying the search reset
   */
  const displaySearchReset = () => {
    return withSearch ? (
      <Button
        {...button}
        type="button"
        variant="filled"
        title={t("undo")}
        onClick={handleResetSearch}
        className={classes.undoBtn}
      >
        <UndoIcon />
      </Button>
    ) : null;
  };

  /**
   * Handles displaying the actions
   */
  const displayActions = () => {
    return (
      (withAdd || withSearch) && (
        <Grid item xs={3} sm={2}>
          <div className={classes.flex}>
            {displayAdd()}
            {displaySearchReset()}
          </div>
        </Grid>
      )
    );
  };

  /**
   * Handles displaying the stats
   */
  const displayStats = () => {
    return withStats ? (
      <Grid item xs={12} sm={getStatsSize()}>
        <Typography variant="caption" className={classes.stats}>
          {getStatsText()}
        </Typography>
      </Grid>
    ) : null;
  };

  return render ? (
    <div className={classes.actions}>
      <Grid container alignItems="center" spacing={1}>
        {displaySearch()}
        {displayActions()}
        {displayStats()}
      </Grid>
    </div>
  ) : null;
};

TableActions.propTypes = propTypes;
TableActions.defaultProps = defaultProps;

export default TableActions;
export {
  propTypes as TableActionsPropTypes,
  defaultProps as TableActionsDefaultProps,
};

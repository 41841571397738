/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    ...theme.mixins.submoduleContainer,
    [theme.breakpoints.down('sm')]: {
      marginTop: "6rem",
    }
  },
  drawerClosed: {
    ...theme.mixins.submoduleContainer,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: "6rem",
    }
  },
}));

export { useStyles };

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0.25rem",
  },
  box: {
    border: `1px solid ${theme.palette.sidebar.text}`,
    background: "#fff",
    margin: "0.25rem",
  },
  border: {
    borderRight: `1px solid ${theme.palette.sidebar.text}`,
  },
  label: {
    fontSize: "0.9rem",
    fontFamily: theme.font.family,
    fontWeight: 800,
    padding: "0.5rem",
    color: theme.palette.sidebar.text,
    display: "flex",
    alignItems: "center",
  },
  value: {
    fontSize: "2.3rem",
    fontFamily: theme.font.family,
    padding: "0.5rem",
    fontWeight: 800,
    color: theme.palette.sidebar.text,
  },
  valueMedium: {
    fontSize: "1.4rem",
    fontFamily: theme.font.family,
    padding: "0.5rem",
    fontWeight: 800,
    color: theme.palette.sidebar.text,
  },
  valueSmall: {
    fontSize: "0.9rem",
    fontFamily: theme.font.family,
    padding: "0.5rem",

    fontWeight: 800,
    color: theme.palette.sidebar.text,
  },
  row: {
    borderBottom: `1px solid ${theme.palette.sidebar.text}`,
  },
}));

export { useStyles };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Imports Components
 */
import DynamicTable, {
  DynamicTablePropTypes,
  DynamicTableDefaultProps
} from "../DynamicTable";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes
} from "../DeleteButton";

/**
 * Hooks
 */
import { useUser } from "../../hooks";

/**
 * Imports styles
 */
import { useStyles } from "./ClientsTable.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  editButton: PropTypes.shape(EditButtonPropTypes),
  deleteButton: PropTypes.shape(DeleteButtonPropTypes),
  dynamicTable: PropTypes.shape(DynamicTablePropTypes),
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFiltersReset: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
  clients: PropTypes.array
};

/**
 * Defines the default props
 */
const defaultProps = {
  editButton: EditButtonDefaultProps,
  deleteButton: DeleteButtonDefaultProps,
  dynamicTable: DynamicTableDefaultProps,
  handleDelete: null,
  handleAdd: () => {},
  handleEdit: () => {},
  handlePageChange: () => {},
  handleChangeRowsPerPage: () => {},
  handleFiltersReset: () => {},
  rowsPerPage: 0,
  page: 0,
  count: 0,
  clients: []
};

/**
 * Displays the component
 */
const ClientsTable = (props) => {
  const {
    editButton,
    deleteButton,
    dynamicTable,
    handleAdd,
    handleEdit,
    handleDelete,
    handlePageChange,
    handleChangeRowsPerPage,
    handleFiltersReset,
    rowsPerPage,
    page,
    count,
    clients
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the global user
   */
  const { user } = useUser();

  /**
   * Initializes the students collection
   */
  const [collection, setCollection] = useState([]);

  /**
   * Initializes the table options
   */
  const [tableOptions, setTableOptions] = useState({});

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState([]);

  /**
   * Handles mapping the id  with the organization name
   * @param {String|Number} id
   */
  const getOrganizationName = (id) => {
    if (organizations) {
      const organization = organizations.find((org) => org.id === id);
      return organization ? organization.name : "";
    }
  };

  /**
   * Checks if the table options are ready
   */
  const tableOptionsReady = () => Object.keys(tableOptions).length > 0;

  /**
   * Gets the NotFound Condition
   */
  const getNotFoundCondition = () => user.clients && user.clients.length < 1;

  /**
   * Handles getting the table options
   */
  const getTableOptions = (props) => {
    const {
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage
    } = props;

    let tableOptions = {
      withCount: true,
      withAdd: true,
      withSearch: true,
      withPagination: true,
      withStats: true,
      withSort: true,
      helpers: {
        resetSearch: true,
        resetFilters: true,
        addResult: true
      },
      pagination: {
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        total: count,
        currentPage: page
      },
      addResult: handleAdd,
      resetFilters: handleFiltersReset,
      defaultOrderBy: "name",
      fields: [
        {
          name: "is_company",
          label: t("is_company"),
          type: "boolean",
          align: "center",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "name",
          label: t("name"),
          align: "start",
          minSize: 150,
          maxSize: 150,
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "vat_number",
          label: t("vat_number"),
          align: "start",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "reg_number",
          label: t("reg_number"),
          align: "start",
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "description",
          label: t("description"),
          align: "start",
          minSize: 350,
          maxSize: 350,
          key: true,
          searchField: true,
          sort: true
        },
        {
          name: "operations",
          label: t("operations"),
          type: "actions",
          key: false,
          align: "center",
          searchField: false,
          maxSize: 100,
          buttons: [
            {
              name: "edit_user",
              type: "edit",
              icon: null,
              component: (
                <EditButton
                  {...editButton}
                  className={classes.button}
                  title={t("edit")}
                />
              ),
              action: handleEdit
            },
            {
              name: "delete_user",
              type: "delete",
              icon: null,
              component: (
                <DeleteButton
                  {...deleteButton}
                  className={classes.button}
                  title={t("delete")}
                />
              ),
              action: handleDelete
            }
          ]
        }
      ]
    };

    return tableOptions;
  };

  /**
   * Handles rendering the dynamic table
   */
  const renderDynamicTable = () => {
    const ready = tableOptionsReady();
    const notFound = getNotFoundCondition();

    return ready ? (
      <DynamicTable
        {...dynamicTable}
        notFound={notFound}
        collection={collection}
        options={tableOptions}
      />
    ) : null;
  };

  /**
   * Handles setting the content filter
   */
  useEffect(() => {
    const options = getTableOptions({
      count,
      page,
      rowsPerPage,
      handlePageChange,
      handleChangeRowsPerPage
    });
    setTableOptions(options);
    // eslint-disable-next-line
  }, [count, page, rowsPerPage]);

  /**
   * Handles updating the collection
   */
  useEffect(() => {
    if (user.organizations) {
      setOrganizations(user.organizations);
    }
    if (clients) {
      const data = clients.map((client) => {
        const { organization_id, type, description, is_company, company } =
          client;

        const companyDataExists = company && Object.keys(company).length > 0;

        return {
          ...client,
          reg_number:
            is_company && companyDataExists && company.reg_number
              ? company.reg_number
                ? company.reg_number
                : " - "
              : " - ",
          vat_number:
            is_company && companyDataExists && company.vat_number
              ? company.vat_number
                ? company.vat_number
                : " - "
              : " - ",
          description: description ? description : "-",
          type: t(type),
          organization_id: getOrganizationName(organization_id)
        };
      });
      setCollection(data);
    }
    // eslint-disable-next-line
  }, [user, clients]);

  return renderDynamicTable();
};

ClientsTable.propTypes = propTypes;
ClientsTable.defaultProps = defaultProps;

export default ClientsTable;
export {
  propTypes as ClientsTablePropTypes,
  defaultProps as ClientsTableDefaultProps
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  routes: {
    fontFamily: theme.font.family,
    padding: 0,
    maxWidth: "none"
  },
  backdrop: {
    background: "rgba(0,0,0,0.3)",
    zIndex: 1200
  }
}));

export { useStyles };

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import TextMask from "../TextMask";

/**
 * Material UI Imports
 */
import TextField from "@material-ui/core/TextField";

/**
 * Styles Imports
 */
import { useStyles } from "./InputPostCode.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.any,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  showHelper: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  error: "",
  label: "",
  name: "postcodeInput",
  onChange: () => {},
  value: "",
  variant: "outlined",
  required: false,
  className: "",
  margin: "none",
  placeholder: "",
  fullWidth: false,
  type: "text",
  autoFocus: false,
  id: "postcodeInput",
  showHelper: true,
};

/**
 * Displays the component
 */
const InputPostCode = (props) => {
  const {
    label,
    value,
    variant,
    inputClasses,
    onChange,
    name,
    error,
    required,
    className,
    placeholder,
    margin,
    fullWidth,
    autoFocus,
    id,
    showHelper,
    type,
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Renders the hidden text if there's no erros or helper messages
   */
  const hiddenText = () => <span className={classes.hidden}>error_msg</span>;

  /**
   * Gets the helper text/or error
   */
  const getHelperText = () =>
    error ? error : showHelper ? t("error_invalidPostalCode") : hiddenText();
  const helperText = getHelperText();

  /**
   * Applies focus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  return (
    <TextField
      id={id}
      inputRef={inputRef}
      className={className}
      name={name}
      label={label}
      placeholder={placeholder}
      variant={variant}
      margin={margin}
      error={error ? true : false}
      helperText={helperText}
      type={type}
      InputProps={{
        ...inputClasses,
        inputComponent: TextMask,
      }}
      inputProps={{ autoFocus: autoFocus, maxLength: 10 }}
      value={value}
      fullWidth={fullWidth}
      onChange={onChange}
      required={required}
    />
  );
};

InputPostCode.propTypes = propTypes;
InputPostCode.defaultProps = defaultProps;

export default InputPostCode;
export {
  propTypes as InputPostCodePropTypes,
  defaultProps as InputPostCodeDefaultProps,
};

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import clsx from "clsx";
import { useHistory } from "react-router-dom";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import CreateButton, {
  CreateButtonDefaultProps,
  CreateButtonPropTypes,
} from "../CreateButton";
import EditButton, {
  EditButtonDefaultProps,
  EditButtonPropTypes,
} from "../EditButton";
import DeleteButton, {
  DeleteButtonDefaultProps,
  DeleteButtonPropTypes,
} from "../DeleteButton";
import AddWorkOrderProductModal, {
  AddWorkOrderProductModalDefaultProps,
  AddWorkOrderProductModalPropTypes,
} from "../AddWorkOrderProductModal";
import EditWorkOrderProductModal, {
  EditWorkOrderProductModalDefaultProps,
  EditWorkOrderProductModalPropTypes,
} from "../EditWorkOrderProductModal";
import AddWorkOrderServiceModal, {
  AddWorkOrderServiceModalDefaultProps,
  AddWorkOrderServiceModalPropTypes,
} from "../AddWorkOrderServiceModal";
import EditWorkOrderServiceModal, {
  EditWorkOrderServiceModalDefaultProps,
  EditWorkOrderServiceModalPropTypes,
} from "../EditWorkOrderServiceModal";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import AddTyreHotelModal, {
  AddTyreHotelModalDefaultProps,
  AddTyreHotelModalPropTypes,
} from "../AddTyreHotelModal";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import EditTyreHotels from "../EditTyreHotels";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SaveIcon from "@material-ui/icons/Save";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import AddIcon from "@material-ui/icons/Add";

/**
 * Hooks
 */
// eslint-disable-next-line
import { useUser, useValidator, useMessage, useApiClient, useDebounce } from "../../hooks";

/**
 * Utils imports
 */
import { utils } from "./EditWorkOrderStage2.utils";

/**
 * Styles Imports
 */
import { useStyles } from "./EditWorkOrderStage2.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  button: PropTypes.shape(ButtonPropTypes),
  createBtn: PropTypes.shape(CreateButtonPropTypes),
  editBtn: PropTypes.shape(EditButtonPropTypes),
  deleteBtn: PropTypes.shape(DeleteButtonPropTypes),
  addTyreModal: PropTypes.shape(AddTyreHotelModalPropTypes),
  addProductModal: PropTypes.shape(AddWorkOrderProductModalPropTypes),
  addServiceModal: PropTypes.shape(AddWorkOrderServiceModalPropTypes),
  editProductModal: PropTypes.shape(EditWorkOrderProductModalPropTypes),
  editServiceModal: PropTypes.shape(EditWorkOrderServiceModalPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  input: PropTypes.shape(InputPropTypes),
  workOrder: PropTypes.object,
  workOrderID: PropTypes.number,
  activeStep: PropTypes.number,
  currency: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.strnig,
      align: PropTypes.string,
    })
  ),
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  setDataUpdated: PropTypes.func,
  carType: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  button: ButtonDefaultProps,
  createBtn: CreateButtonDefaultProps,
  editBtn: EditButtonDefaultProps,
  deleteBtn: DeleteButtonDefaultProps,
  addTyreModal: AddTyreHotelModalDefaultProps,
  addProductModal: AddWorkOrderProductModalDefaultProps,
  addServiceModal: AddWorkOrderServiceModalDefaultProps,
  editProductModal: EditWorkOrderProductModalDefaultProps,
  editServiceModal: EditWorkOrderServiceModalDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  loadingText: LoadingTextDefaultProps,
  input: InputDefaultProps,
  workOrder: {},
  workOrderID: 0,
  activeStep: 0,
  currency: "RON",
  headers: [
    { label: "#", align: "left" },
    { label: "itemName", align: "left" },
    { label: "um", align: "left" },
    { label: "quantity", align: "left" },
    { label: "price", align: "right" },
    { label: "totalLabel", align: "right" },
  ],
  nextStep: () => {},
  prevStep: () => {},
  setDataUpdated: () => {},
  carType: "",
};

/**
 * Displays the component
 */
const EditWorkOrderStage2 = (props) => {
  const {
    button,
    createBtn,
    editBtn,
    deleteBtn,
    addProductModal,
    addServiceModal,
    editProductModal,
    editServiceModal,
    errorMessages, 
    loadingText,
    input,
    workOrder,
    workOrderID,
    activeStep,
    currency,
    headers,
    prevStep,
    nextStep,
    setDataUpdated,
    carType,
    tyreService: parentTyreService,
    setTriggerUpdate,
    workOrderItems,
    setWorkOrderItems,
    setWorkOrder,
    addTyreModal,
    clients,
    setWorkOrderSummary,
    setTriggerPricingUpdate
  } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Initializes the products
   */
  const [products, setProducts] = useState([]);

  /**
   * Initializes the services
   */
  const [services, setServices] = useState([]);

  /**
   * Initializes a cache for the deleted item data
   */
  const [deleteTarget, setDeleteTarget] = useState({});

  /**
   * Initializes the total state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the product data for the edit modal
   */
  const [productData, setProductData] = useState({});

  /**
   * Initializes the service data for the edit modal
   */
  const [serviceData, setServiceData] = useState({});

  /**
   * Initializes the hotel service flag
   */
  const [hasHotelService, setHasHotelService] = useState(false);

  /**
   * Init the add tyre hotel modal
   */
  const [addTyreHotelModal, setAddTyreHotelModal] = useState(false);

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Gets the validation functions
   */
  const { isNumber } = useValidator();

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Initializes the base total (derived from products/services)
   */
  const [baseTotal, setBaseTotal] = useState(0);

  /**
   * Initializes a flag that determines if the initial value has been set or not
   * Init value is coming from the work order
   */
  const [initValueSet, setInitValueSet] = useState(false);

  /**
   * Initializes the discount state
   */
  const [discount, setDiscount] = useState(0);

  /**
   * Initializes the tyre service
   */
  const [tyreService, setTyreService] = useState({});

  /**
   * Initializes the products list
   */
  const [productsList, setProductsList] = useState(workOrderItems.products);

  /**
   * Initializes the services list
   */
  const [servicesList, setServicesList] = useState(workOrderItems.services);

  /**
   * Initializes the subtotal state
   */
  const [subtotal, setSubtotal] = useState(workOrder.subtotal);

  /**
   * Initializes the tax state
   */
  const [tax, setTax] = useState(workOrder.tax);

  /**
   * Initializes the total state
   */
  const [total, setTotal] = useState(workOrder.total);

  /**
   * Initializes the discount error state
   */
  const [discountError, setDiscountError] = useState("");

  /**
   * Initializes 2 flags that will trigger a step change under the correct conditions
   * Used in order to be able to save the data if it changed before moving on to another step.
   */
  const [goNext, setGoNext] = useState(false);
  const [goPrev, setGoPrev] = useState(false);

  /**
   * Initializes the modal states
   */
  const [modalState, setModalState] = useState({
    addProduct: false,
    editProduct: false,
    addService: false,
    editService: false,
  });

  const [manualSave, setManualSave] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentType, setPaymentType] = useState(null);

  /**
   * Gets the user object
   */
  const { user, setUser } = useUser();

  /**
   * Init the tyre service flag
   */
  const [isTyreService, setIsTyreService] = useState(false);

  /**
   * Init the updated flag
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Handles deleting a work order product by id
   */
  const deleteWorkOrderProduct = async (id) => {
    try {
      const deletedProduct = await apiClient.delete(
        `/work-order-products/${id}`
      );
      if (deletedProduct) {
        /**
         * Resets the loading state
         */
        setLoading(false);

        /**
         * Triggers an updated get request to get the new work order data
         */
        setDataUpdated(true);

        /**
         * Defines the delete message
         */
        const message = deleteTarget.is_service
          ? t("serviceDeleted")
          : t("productDeleted");

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({ icon: false, message });
      }
    } catch (error) {
      handleAPIError(error);
    }
  };

  /**
   * Handles updating a work order by id
   */
  const updateWorkOrderByID = async (data, id) => {
    try {
      const updatedWo = await apiClient.put(`/work-orders/${id}`, data);
      if (updatedWo) {
        /**
         * Resets the loading state
         */
        setLoading(false);

        if (manualSave) {
          dispatchMessage({
            message: t("successMessage"),
          });
          setManualSave(false);
        }

        setWorkOrder(updatedWo.data);
        setWorkOrderSummary(updatedWo.data);
        setTriggerPricingUpdate(false);

        /**
         * Handles going to the next step if this action was triggered
         */
        if (goNext) {
          setGoNext(false);
          nextStep();
        }

        /**
         * Handles going to the prev step if this action was triggered
         */
        if (goPrev) {
          setGoPrev(false);
          prevStep();
        }
      }
    } catch (error) {
      handleAPIError(error);
    }
  };

  /**
   * Handles searching for products
   */
  const searchProducts = async (data) => {
    try {
      const products = await apiClient.post("/products/search", data);
      if (products) {
        const items = products.data.items;

        const filteredProducts = items
          .filter((product) => {
            if (
              product.car_type_id === null ||
              product.car_type_id === carType
            ) {
              return true;
            }
            return false;
          })
          .sort((a, b) => a.organization_id - b.organization_id);
        setUser((prevState) => ({ ...prevState, products: filteredProducts }));
        setProductsList(filteredProducts);
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles searching for services
   */
  const searchServices = async (data) => {
    try {
      const services = await apiClient.post("/products/search", data);
      if (services) {
        const items = services.data.items;

        function findLastIndex(array, searchKey, searchValue) {
          var index = array
            .slice()
            .reverse()
            .findIndex((x) => x[searchKey] === searchValue);
          var count = array.length - 1;
          var finalIndex = index >= 0 ? count - index : index;

          return finalIndex;
        }

        const filteredServices = items
          .filter((product) => {
            if(workOrder?.work_order_type?.id) {
             return !!(workOrder.work_order_type.id === product.work_order_type_id && ((
                 product.car_type_id === null ||
                 product.car_type_id === carType
             )));
            } else {
              return !!(
                  product.car_type_id === null ||
                  product.car_type_id === carType
              );
            }
          })
          .map((item, index, services) => {
            const itemIndex = findLastIndex(
              services,
              "organization_id",
              user.organization.id
            );

            return {
              ...item,

              topOrder:
                item.organization_id === user.organization.id ? true : null,
              lastFromUser: index === itemIndex,
            };
          });

        const servicesList = filteredServices.sort((a, b) => {
          if (b.topOrder) {
            return 1;
          }
          if (a.topOrder) {
            return -1;
          }
          return a.organization_id - b.organization_id;
        });

        setUser((prevState) => ({ ...prevState, services: servicesList }));
        setServicesList(servicesList);
      }
    } catch (error) {
      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles opening the tyre hotel modal
   */
  const openTyreHotel = () => setAddTyreHotelModal(true);

  /**
   * Handles closing the tyre hotel modal
   */
  const closeTyreHotel = () => setAddTyreHotelModal(false);

  /**
   * Handles updating the discount input
   */
  const handleDiscount = (e) => {
    const value = e.target.value;

    if (value === "00") {
      setDiscount((prevState) => prevState);
      return;
    }

    if (value && value.length > 0 && value[0] === "0") {
      if (isNumber(value[1])) return;
    }

    if (value.includes("00.") && value.length < 6) {
      setDiscount(value);
      return;
    }

    if (value.includes("00.") && value.length > 5) {
      return;
    }

    if (value === "") {
      setDiscount(value);
      return;
    }

    if (!isNumber(Number(value)) && value[0] !== "-") return;

    if (value.includes("..")) return;

    if (parseFloat(value) > parseFloat(subtotal)) {
      setDiscount(subtotal);
    } else {
      if (
        value.toString().split(".")[1] &&
        value.toString().split(".")[1].length > 2
      ) {
        return;
      } else {
        if (value.length > 5) {
          setDiscount(value.replace(/^0+/, ""));
        } else {
          setDiscount(value);
        }
      }
    }
  };

  const handleInputChange = (e) => setPaymentType(e.target.value);

  /**
   * Checks if the number is invalid
   */
  const isInvalidNum = (number) =>
    number && !isNumber(number) && number[0] !== "-";

  /**
   * Parses the input as a float with 2 decimals.
   */
  const formatInputNum = (number) => parseFloat(number).toFixed(2);

  /**
   * Handles checking if an object has any data
   */
  const objectHasData = (data) => Object.keys(data).length > 0;

  /**
   * Checks if the data has changed
   */
  const hasDataChanged = () => {
    if (discount !== workOrder.discount) return true;
    if (paymentType && !workOrder.payment_type) return true;
    const defaultPaymentType = workOrder.payment_type
      ? workOrder.payment_type.id
      : paymentType;
    if (paymentType !== defaultPaymentType) return true;
    return false;
  };

  /**
   * Handles getting the product by name
   */
  const getProductByName = (products, productData) =>
    products && products.find((product) => product.name === productData.name);

  /**
   * Handles rendering empty cells
   */
  const renderEmptyCells = (count) => {
    if (!count) return;
    let cells = Array(count)
      .fill(null)
      .map((_, idx) => idx);
    return cells && cells.map((cell) => <TableCell key={cell}></TableCell>);
  };

  /**
   * Handles formatting the number
   */
  const handleItemCostRendering = (item, key) => {
    const { is_service, price, is_count_price } = item;

    if ((is_count_price || !is_service) && price > 0) {
      return `${item[key].toFixed(2)} ${currency}`;
    }

    return `0.00 ${currency}`;
  };

  /**
   * Calculates the subtotal
   */
  const calculateSubtotal = () => {
    /**
     * Initializes the subtotal
     */
    let subtotal = 0;

    /**
     * Handles incrementing the result;
     */
    const incrementResult = (array) => {
      array.forEach((item) => {
        const { total, is_count_price, price } = item;
        if (is_count_price || price > 0) {
          subtotal += total;
        }
      });
    };

    /**
     * Increments the total using the table data
     */
    incrementResult(services);
    incrementResult(products);

    return subtotal;
  };

  /**
   * Handles rerouting to the products page
   */
  const goToProductsPage = () =>
    history.push("/modules/workstation-settings/products");

  /**
   * Handles getting the base pricing.
   */
  const getBasePricing = () => {
    /**
     * Initializes the pricing
     */
    let total = 0;
    let tax = 0;
    let subtotal = calculateSubtotal();

    /**
     * Calculates the pricing
     */
    total = tax + subtotal;

    return {
      subtotal: formatInputNum(subtotal),
      tax: formatInputNum(tax),
      total: formatInputNum(total),
    };
  };

  /**
   * Handles saving the data
   */
  const handleSave = () => {
    let data = {
      subtotal: parseFloat(subtotal),
      discount: parseFloat(discount),
      tax: parseFloat(tax),
      total: parseFloat(total),
    };

    if (paymentType) data.payment_type_id = paymentType;
    updateWorkOrderByID(data, workOrderID);

    setLoading(true);
    setTriggerUpdate(true);
  };

  /**
   * Handles going to the next step
   */
  const handleNext = () => {
    if(hasDataChanged()) handleSave();
    return nextStep();

    // handleSave();
    // hasDataChanged() ? setGoNext(true) : nextStep();
  };

  /**
   * Handles going to the previous step
   */
  const handlePrev = () => {
    if(hasDataChanged()) handleSave();
    return prevStep();

    // handleSave();
    // hasDataChanged() ? setGoPrev(true) : prevStep();
  };

  /**
   * Handles the pricing state
   */
  const handlePricing = (discount) => {
    /**
     * Defines the error message
     */
    const errorMessage = t("LanguageProvider:error_invalidNumber");

    if (parseFloat(discount).toFixed(2) === parseFloat(baseTotal).toFixed(2)) {
      setTotal(0);
      return;
    }

    /**
     * Handles edge cases
     */
    if (isInvalidNum(discount)) return setDiscountError(errorMessage);
    if (
      Number(parseFloat(discount).toFixed(2)) >=
      Number(parseFloat(baseTotal).toFixed(2))
    )
      return setTotal(0);

    if (Number(parseFloat(discount).toFixed(2) === 0)) {
      const total = getBasePricing().total;

      return setTotal(total);
    }

    /**
     * Calculates the new total
     */
    const float_total = formatInputNum(baseTotal);
    const float_discount = formatInputNum(discount);
    const newTotal = float_total - float_discount;

    if (newTotal <= 0) return setTotal(0);
    setTotal(formatInputNum(newTotal));
  };

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Handles closing the edit modals
   */
  const closeEdit = (type) => {
    if (type === "product") {
      setProductData({});
      handleCloseModal("editProduct");
    } else {
      setServiceData({});
      handleCloseModal("editService");
    }
  };

  /**
   * Defines the delete function
   */
  const handleDelete = (id) => {
    setLoading(true);
    deleteWorkOrderProduct(id);
  };

  /**
   * Defines the product modal handlers
   */
  const openAddProduct = () => handleOpenModal("addProduct");
  const closeAddProduct = () => handleCloseModal("addProduct");
  const openEditProduct = (props) => setProductData({ ...props });

  const closeEditProduct = () =>  {
    closeEdit("product");
    debounce(() => {
      triggerManualSave();
    }, 150)
  }

  /**
   * Defines the service modal handlers
   */
  const openAddService = () => handleOpenModal("addService");
  const closeAddService = () => handleCloseModal("addService");
  const openEditService = (props) => setServiceData({ ...props });
  const closeEditService = () => {
    closeEdit("service");
    debounce(() => {
      triggerManualSave();
    }, 150)
  }

  const handleUpdatingWorkOrder = (response) => {
    const { data } = response;
    setWorkOrder((prevState) => {
      const prevTyreHotels = prevState.tyre_hotels ? prevState.tyre_hotels : [];
      const updatedTyreHotels = [{ ...data }, ...prevTyreHotels];
      return {
        ...prevState,
        tyre_hotels: updatedTyreHotels,
      };
    });
  };

  /**
   * Defines the api error handler
   */
  const handleAPIError = (error) => {
    setLoading(false);
    /**
     * Handles dispatching the error message
     */
    dispatchMessage({
      icon: false,
      severity: "error",
      component: <ErrorMessages {...errorMessages} error={error} />,
      origin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  /**
   * Handle rendering the products
   */
  const renderTableData = (items) => {
    return (
      items &&
      items.map((item, idx) => {
        const { id, name, um, quantity, is_service, is_count_price } = item;

        /**
         * Handles deleting the item
         */
        const deleteItem = () => {
          handleDelete(id);
          setDeleteTarget({ id, is_service });
        };

        /**
         * Defines the table cell data
         */
        const count = idx + 1;
        const price = handleItemCostRendering(item, "price");
        const total = handleItemCostRendering(item, "total");
        const quant = quantity.toFixed(2);
        const unitMeasure = um ? um : t("piece");

        /**
         * Defines the action button classes
         */
        const btnClasses = clsx(classes.smallBtn, classes.smallBtnMargins);
        const handleEdit = () =>
          is_service ? openEditService(item) : openEditProduct(item);

        return (
          <TableRow key={id}>
            <TableCell
              component="th"
              scope="row"
              size="small"
              className={classes.itemCount}
            >
              {count}
            </TableCell>
            <TableCell className={classes.name}>
              <div className={classes.itemMain}>
                <span className={classes.nameSpan}>{name}</span>
                {!is_count_price && item.price <= 0 && (
                  <Tooltip
                    title={t("notCountPriceExplanation")}
                    TransitionComponent={Zoom}
                    placement="top"
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.popper,
                    }}
                  >
                    <span
                      className={classes.notCountPrice}
                      onClick={goToProductsPage}
                    >
                      <WarningIcon />
                      {t("notActivated")}
                    </span>
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell>{unitMeasure}</TableCell>
            <TableCell>{quant}</TableCell>
            <TableCell align="right">{price}</TableCell>
            <TableCell align="right">{total}</TableCell>
            <TableCell>
              <div className={classes.actions}>
                <EditButton
                  {...editBtn}
                  title={t("editItem")}
                  className={btnClasses}
                  onClick={handleEdit}
                />
                <DeleteButton
                  {...deleteBtn}
                  title={t("deleteItem")}
                  className={btnClasses}
                  loading={loading && deleteTarget.id === id}
                  spinnerSize={24}
                  onClick={deleteItem}
                />
              </div>
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  const checkCondition = (a, b, condition) => {
    if (condition === "=") {
      return a === b;
    } else if (condition === ">=") {
      return a >= b;
    } else if (condition === "<=") {
      return a <= b;
    } else if (condition === ">") {
      return a > b;
    } else if (condition === "<") {
      return a < b;
    }
  };

  /**
   * Handles rendering the table headers
   */
  const renderTableHeaders = (dataTable) => {
    return (
      dataTable.length > 0 &&
      headers.map((header, idx) => {
        const { align, label } = header;

        /**
         * Gets the table cell classes
         */
        const getClassName = () => {
          if (idx === 0) return classes.itemCount;
          if (idx === 1) return classes.name;
        };

        return (
          <TableCell key={idx} align={align} className={getClassName()}>
            {t(label)}
          </TableCell>
        );
      })
    );
  };

  const triggerManualSave = () => setManualSave(true);

  const applyPriceConditions = (services) => {
    let newServices = [];

    const keyFields = {
      tyre_rim: "tyre_rim",
      tyre_height: "tyre_height",
      tyre_width: "tyre_width",
    };

    services.forEach((service) => {
      if (service.price_conditions && service.price_conditions.length > 0) {
        let newPrice = service.price;

        const price_cond = service.price_conditions;
        price_cond.forEach((condition) => {
          if (keyFields[condition.field]) {
            const key = keyFields[condition.field];
            let value = tyreService[key];
            const conditionOK = checkCondition(
              Number(value),
              Number(condition.value),
              condition.condition
            );
            if (conditionOK) {
              newPrice = condition.new_price;
              return;
            } 
          }
        });

        newServices.push({
          ...service,
          old_price: service.price,
          total: parseFloat(newPrice) * parseFloat(service.quantity),
          price: parseFloat(newPrice),
        });
      } else {
        newServices.push(service);
      }
    });
    return newServices;
  };

  // const calculateDiscountSpecial = (products) => {
  //   if (products.length > 0) {
  //     const specialServicesKeys = [
  //       "Demontat+Montat Anvelopa",
  //       "Demontat+Montat Roata",
  //       "Echilibrat Roti",
  //     ];
  //
  //     const specialArr = products
  //       .filter((product) => {
  //         if(product.is_service) return false;
  //         return true;
  //       });
  //
  //     if (specialArr.length < 1) return 0;
  //
  //     let dict = { â: "a", ă: "a", ș: "s", î: "i", ț: "t" };
  //     let specialQty = 0;
  //     let specialDiscount = 0;
  //
  //     specialArr.forEach(product => {
  //       specialQty += product.quantity
  //     })
  //
  //     services.forEach((product) => {
  //       let matchFound = false;
  //       const productName = formatValue(product.name, dict);
  //       specialServicesKeys.forEach((key) => {
  //         const keyValue = formatValue(key, dict);
  //         const carTypename = getCarTypeName();
  //         console.log('EditWorkOrderStage2 carType', carType);
  //         if (productName.includes("echilibra") && carType === "Camion") {
  //           return;
  //         } else if (productName.includes(keyValue)) {
  //           matchFound = true;
  //           return;
  //         }
  //       });
  //
  //       if (matchFound) {
  //         if (product.quantity <= specialQty) {
  //           specialDiscount += product.quantity * product.price;
  //         }
  //
  //         if (product.quantity > specialQty) {
  //           specialDiscount += specialQty * product.price;
  //         }
  //       }
  //     });
  //
  //     return specialDiscount
  //       ? parseFloat(Number(specialDiscount)).toFixed(2)
  //       : 0;
  //   }
  // };

  /**
   * Handles prefilling the product options list upon mounting
   */
  useEffect(() => {
    if ((user.products && user.products.length < 0) || !user.products) {
      const data = {
        models: [
          {
            label: "Is Service",
            field: "is_service",
            type: "boolean",
            order: 5,
            options: [],
            selected: false,
          },
        ],
        order_by: "name",
        order_dir: "asc",
        page_size: 500000, // needed in order to get all products
        page_count: 1,
      };
      searchProducts(data);
    } else {
      setProductsList(user.products);
    }

    // eslint-disable-next-line
  }, [productsList]);

  /**
   * Handles prefilling the product options list upon mounting
   */
  useEffect(() => {
      const data = {
        models: [
          {
            label: "Is Service",
            field: "is_service",
            type: "boolean",
            order: 5,
            options: [],
            selected: true,
          },
        ],
        order_by: "name",
        order_dir: "asc",
        page_size: 500000, // needed in order to get all products
        page_count: 1,
      };
      searchServices(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productsList.length > 0 && servicesList.length > 0) {
      setWorkOrderItems({
        products: productsList,
        services: servicesList,
      });
    }
    // eslint-disable-next-line
  }, [servicesList, productsList]);

  useEffect(() => {
    if (manualSave) handleSave();
    // eslint-disable-next-line
  }, [manualSave]);

  /**
   * Handles updating the pricing
   */
  useEffect(() => {
    if (initValueSet && baseTotal > 0) {
      setTimeout(() => {
        handlePricing(discount);
      }, [300]);
    }
    // eslint-disable-next-line
  }, [discount, initValueSet, baseTotal]);

  /**
   * Handles initializing the discount value
   */
  useEffect(() => {
    setDiscount(workOrder.discount);
    setPaymentType(workOrder.payment_type ? workOrder.payment_type.id : null);
    setInitValueSet(true);
    setTyreService(workOrder.tyre_service);
    setHasHotelService(
      workOrder.tyre_hotels && workOrder.tyre_hotels.length > 0
    );
  }, [workOrder]);

  useEffect(() => {
    if (Object.keys(parentTyreService).length > 0) {
      setTyreService(parentTyreService);
    }
  }, [parentTyreService]);

  /**
   * Handles opening the edit product modal if the data is set
   */
  useEffect(() => {
    if (objectHasData(productData)) handleOpenModal("editProduct");
  }, [productData]);

  /**
   * Handles opening the edit service modal if the data is set
   */
  useEffect(() => {
    if (objectHasData(serviceData)) handleOpenModal("editService");
  }, [serviceData]);

  /**
   * Handles updating the inputs with the data from the work order
   */
  useEffect(() => {
    if (objectHasData(workOrder)) {
      const { services, products } = utils.getServicesAndProducts(workOrder);

      setProducts(products);
      setServices(services);
    }
    // eslint-disable-next-line
  }, [workOrder]);

  /**
   * Updates the select dropdown options for the search
   */
  useEffect(() => {
    if (user.settings) {
      const paymentTypes = user.settings.account.payment_types;
      setPaymentTypes(paymentTypes);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (subtotal > 0) {
      if (Math.sign(Number(total)) === -1) {
        setTotal(0);
        setDiscount(subtotal);
      }
    }

    // eslint-disable-next-line
  }, [total, subtotal, discount, products, services]);

  useEffect(() => {
    if (!Number(total) && total !== 0) {
      setTotal(getBasePricing().total);
    }
    // eslint-disable-next-line
  }, [total]);

  /**
   * Handles updating the base pricing using the data from the tables
   */
  useEffect(() => {
    const { total, subtotal, tax } = getBasePricing();

    setBaseTotal(total);
    setSubtotal(subtotal);
    setTotal(total - discount);
    setTax(tax);
    // eslint-disable-next-line
  }, [services, products]);

  useEffect(() => {
    if (products.length < 1 && services.length < 1 && subtotal > 0) {
      setSubtotal(0);
      setTotal(0);
    }
    // eslint-disable-next-line
  }, [products, services, subtotal]);

  //  /**
  // * Handles the api call response
  // */
  //   useEffect(() => {
  //    const { successful, error } = getAPICallStatus(clientsResult);
  //
  //    if (error) {
  //      /**
  //       * Resets the states
  //       */
  //      setUpdated(false);
  //      /**
  //       * Dispatches the error message
  //       */
  //      dispatchMessage({
  //        severity: "error",
  //        message: error.message,
  //      });
  //    }
  //
  //    if (successful) setClients(clientsResult.data);
  //    // eslint-disable-next-line
  //  }, [clientsResult]);

  useEffect(() => {
    if (workOrder && workOrder.work_order_type) {
      const type = workOrder.work_order_type.name;
      if (type === "Vulcanizare") {
        setIsTyreService(true);
      }
    }
  }, [workOrder]);

  return (
    <Card className={classes.stepCard} key={`edit_work_order_${updated}`}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="caption" className={classes.sectionTitle}>
              {t("products")}
              <CreateButton
                {...createBtn}
                className={classes.smallBtn}
                onClick={openAddProduct}
              />
            </Typography>
            <TableContainer>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <Fragment>{renderTableHeaders(services)}</Fragment>
                    {renderEmptyCells(1)}
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableData(products)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="caption" className={classes.sectionTitle}>
              {t("services")}
              <CreateButton
                {...createBtn}
                className={classes.smallBtn}
                onClick={openAddService}
              />
            </Typography>
            <TableContainer>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <Fragment>{renderTableHeaders(services)}</Fragment>
                    {renderEmptyCells(1)}
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableData(services)}</TableBody>
              </Table>
            </TableContainer>
            <AddTyreHotelModal
              {...addTyreModal}
              open={addTyreHotelModal}
              closeModal={closeTyreHotel}
              setUpdated={setUpdated}
              clients={clients} // @TODO-SZABI
              workOrder={workOrder}
              onSuccess={handleUpdatingWorkOrder}
              resetOnClose={false}
            />
            <AddWorkOrderProductModal
              {...addProductModal}
              workOrder={workOrder}
              open={modalState.addProduct}
              setDataUpdated={setDataUpdated}
              closeModal={closeAddProduct}
              products={productsList}
            />
            <EditWorkOrderProductModal
              {...editProductModal}
              workOrder={workOrder}
              editData={productData}
              open={modalState.editProduct}
              setDataUpdated={setDataUpdated}
              woProduct={getProductByName(products, productData)}
              closeModal={closeEditProduct}
              products={productsList}
              mainProducts={products}
              setProducts={setProducts}
            />
            <AddWorkOrderServiceModal
              {...addServiceModal}
              open={modalState.addService}
              closeModal={closeAddService}
              setDataUpdated={setDataUpdated}
              products={applyPriceConditions(servicesList)}
              workOrder={workOrder}
            />

            <EditWorkOrderServiceModal
              {...editServiceModal}
              workOrder={workOrder}
              editData={serviceData}
              open={modalState.editService}
              setDataUpdated={setDataUpdated}
              woProduct={getProductByName(services, serviceData)}
              closeModal={closeEditService}
              products={applyPriceConditions(servicesList)}
              mainServices={services}
              setServices={setServices}
            />
          </Grid>
          {isTyreService && (
            <Button
              {...button}
              type="button"
              variant="filled"
              disabled={activeStep === 0}
              onClick={openTyreHotel}
              className={classes.submitButton}
            >
              <AddIcon className={classes.beforeIcon} />
              {t("addTyreHotel")}
            </Button>
          )}

          <Grid container>
            <Grid container item xs={12}>
              {hasHotelService && (
                <Grid item xs={12} sm={8}>
                  <EditTyreHotels
                    tyre_hotels={workOrder.tyre_hotels}
                    workOrder={workOrder}
                  />
                </Grid>
              )}{" "}
              <Grid item xs={12} sm={hasHotelService ? 4 : 12}>
                <div className={classes.formContainer}>
                  <div className={classes.form}>
                    <Input
                      className={classes.selectField}
                      type="select"
                      inputSelect={{
                        name: "paymentType",
                        value: paymentType,
                        onChange: handleInputChange,
                        labelClass: classes.label,
                        variant: "outlined",
                        label: t("payment_typeLabel"),
                        options: paymentTypes,
                        optionLabel: "name",
                        optionValue: "id",
                      }}
                    />
                    <Input
                      {...input}
                      type="numeric"
                      className={classes.field}
                      inputNumeric={{
                        id: "discount",
                        name: "discount",
                        value: discount,
                        onChange: handleDiscount,
                        variant: "outlined",
                        InputLabelProps: {
                          className: classes.label,
                        },
                        label: t("discountLabel"),
                        error: discountError,
                        autoFocus: discountError.length > 0,
                      }}
                    />
                    <div className={classes.pricingContainer}>
                      <div className={classes.pricing}>
                        <Typography className={classes.pricingLabel}>
                          {t("subtotal")}
                        </Typography>
                        <Typography className={classes.pricingValue}>
                          {subtotal} {currency}
                        </Typography>
                      </div>
                      <div className={classes.pricing}>
                        <Typography className={classes.pricingLabel}>
                          {t("discountLabel")}
                        </Typography>
                        <Typography className={classes.pricingValue}>
                          {discount} {currency}
                        </Typography>
                      </div>
                      <div className={classes.pricing}>
                        <Typography className={classes.pricingLabel}>
                          {t("total")}
                        </Typography>
                        <Typography className={classes.pricingValue}>
                          {parseFloat(total).toFixed(2)} {currency}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <div className={classes.buttonsContainer}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={triggerManualSave}
            className={classes.saveBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("save")}
              icon={<SaveIcon />}
            />
          </Button>
          <Button
            {...button}
            type="button"
            variant="filled"
            disabled={activeStep === 0}
            onClick={handlePrev}
            className={classes.submitButton}
          >
            <NavigateBeforeIcon className={classes.beforeIcon} />
            {t("previous")}
          </Button>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleNext}
            disabled={activeStep === 2}
            className={classes.submitButton}
          >
            {t("next")}
            <NavigateNextIcon className={classes.nextIcon} />
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

EditWorkOrderStage2.propTypes = propTypes;
EditWorkOrderStage2.defaultProps = defaultProps;

export default EditWorkOrderStage2;
export {
  propTypes as EditWorkOrderStage2PropTypes,
  defaultProps as EditWorkOrderStage2DefaultProps,
};

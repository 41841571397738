import React from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * External Imports
 */
import { useLocation, matchPath } from "react-router-dom";

/**
 * Component Imports
 */
import Sidebar, { SidebarDefaultProps, SidebarPropTypes } from "../Sidebar";

/**
 *  Material UI Imports
 */
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DonutSmallOutlinedIcon from "@material-ui/icons/DonutSmallOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UpdateIcon from "@material-ui/icons/Update";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsSidebar.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  sidebar: PropTypes.shape(SidebarPropTypes),
  paths: PropTypes.shape({
    main: PropTypes.string,
    organizations: PropTypes.string,
    users: PropTypes.string,
    workers: PropTypes.string,
    paymentMethods: PropTypes.string,
    appointmentGroups: PropTypes.string,
    carTypes: PropTypes.string,
    orders: PropTypes.string,
    upgrade: PropTypes.string,
  }),
};

/**
 * Defines the default props
 */

const defaultProps = {
  sidebar: SidebarDefaultProps,
  paths: {
    main: "/",
    organizations: "/",
    users: "/",
    workers: "/",
    paymentMethods: "/",
    appointmentGroups: "/",
    carTypes: "/",
    orders: "/",
    upgrade: "/",
  },
};

/**
 * Displays the component
 */
const AccountSettingsSidebar = (props) => {
  const { sidebar, paths } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the paths from the components
   */
  const {
    main,
    organizations,
    users,
    workers,
    paymentMethods,
    carTypes,
    orders,
    upgrade,
    appointmentGroups,
  } = paths;

  /**
   * Gets the location path
   */
  const location = useLocation();

  /**
   * Checks if the current path matches the path of the menu item
   */
  const checkPathMatch = (pathName) => {
    const result = matchPath(location.pathname, pathName);
    return result ? (result.isExact ? true : false) : false;
  };

  /**
   * Gets the module title
   */
  const getModuleTitle = () => {
    /**
     * Initializes the title variable
     */
    let title = "";

    Object.entries(paths).forEach((path) => {
      if (checkPathMatch(path[1])) {
        title = t(path[0]);
        return;
      }
    });

    return title ? title : t("notFound");
  };

  /**
   * Configures the sidebar menu
   */
  const config = {
    moduleTitleFn: getModuleTitle,
    showDeleteAccount: true,
    menu: [
      {
        path: main,
        text: "dashboard",
        icon: <DonutSmallOutlinedIcon className={classes.icon} />,
      },
      {
        path: organizations,
        text: "organizations",
        icon: <AccountTreeOutlinedIcon className={classes.icon} />,
      },
      {
        path: users,
        text: "users",
        icon: <SupervisedUserCircleOutlinedIcon className={classes.icon} />,
      },
      {
        path: workers,
        text: "workers",
        icon: <ContactMailOutlinedIcon className={classes.icon} />,
      },
      {
        path: paymentMethods,
        text: "paymentTypes",
        icon: <CreditCardIcon className={classes.icon} />,
      },
      {
        path: carTypes,
        text: "carTypes",
        icon: <DirectionsCarIcon className={classes.icon} />,
      },
      {
        path: appointmentGroups,
        text: "appointmentGroups",
        icon: <MeetingRoomIcon className={classes.icon} />,
      },
      {
        path: orders,
        text: "orders",
        icon: <ListAltIcon className={classes.icon} />,
      },
      {
        path: upgrade,
        text: "upgrade",
        icon: <UpdateIcon className={classes.icon} />,
      },
    ],
  };

  return <Sidebar {...sidebar} {...config} />;
};

AccountSettingsSidebar.propTypes = propTypes;
AccountSettingsSidebar.defaultProps = defaultProps;

export default AccountSettingsSidebar;
export {
  propTypes as AccountSettingsSidebarPropTypes,
  defaultProps as AccountSettingsSidebarDefaultProps,
};

/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flex: 1,
    padding: "0.35rem",
    "& svg": {
      color: theme.palette.primary.main,
      marginRight: "0.5rem",
      cursor: "pointer"
    }
  },
  value: {
    fontSize: "0.9rem",
    display: "flex",
    fontWeight: 800,
    color: theme.palette.sidebar.text,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    border: `1px solid #cecece`,
    background: theme.palette.common.white,
    padding: "0.25rem 1rem"
  }
}));

export { useStyles };

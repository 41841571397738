import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import UsersTable, {
  UsersTableDefaultProps,
  UsersTablePropTypes,
} from "../UsersTable";
import AddUserModal, {
  AddUserModalDefaultProps,
  AddUserModalPropTypes,
} from "../AddUserModal";
import DeleteUserModal, {
  DeleteUserModalDefaultProps,
  DeleteUserModalPropTypes,
} from "../DeleteUserModal";
import EditUserModal, {
  EditUserModalDefaultProps,
  EditUserModalPropTypes,
} from "../EditUserModal";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";
import LoadingBackdrop, {
  LoadingBackdropDefaultProps,
  LoadingBackdropPropTypes,
} from "../LoadingBackdrop";
import SubmoduleTitle, {
  SubmoduleTitleDefaultProps,
  SubmoduleTitlePropTypes,
} from "../SubmoduleTitle";
import SubmoduleWrapper, {
  SubmoduleWrapperDefaultProps,
  SubmoduleWrapperPropTypes,
} from "../SubmoduleWrapper";
import SubmoduleContainer, {
  SubmoduleContainerDefaultProps,
  SubmoduleContainerPropTypes,
} from "../SubmoduleContainer";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";

/**
 * Hooks
 */
import { useLocalStorage, useUser, useForm, useApiClient } from "../../hooks";

/**
 * Styles Imports
 */
import { useStyles } from "./AccountSettingsUsers.styles";

/**
 * Defaults Imports
 */
import { defaults } from "./AccountSettingsUsers.defaults";

/**
 * Defines the prop types
 */
const propTypes = {
  usersTable: PropTypes.shape(UsersTablePropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  loadingBackdrop: PropTypes.shape(LoadingBackdropPropTypes),
  addUserModal: PropTypes.shape(AddUserModalPropTypes),
  editUserModal: PropTypes.shape(EditUserModalPropTypes),
  deleteUserModal: PropTypes.shape(DeleteUserModalPropTypes),
  title: PropTypes.shape(SubmoduleTitlePropTypes),
  wrapper: PropTypes.shape(SubmoduleWrapperPropTypes),
  container: PropTypes.shape(SubmoduleContainerPropTypes),
  defaultSearch: PropTypes.object,
  accountTypes: PropTypes.array,
  defaultValues: PropTypes.shape({
    username: PropTypes.string,
    organization: PropTypes.string,
    type: PropTypes.string,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
  }),
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  usersTable: UsersTableDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  loadingBackdrop: LoadingBackdropDefaultProps,
  addUserModal: AddUserModalDefaultProps,
  editUserModal: EditUserModalDefaultProps,
  deleteUserModal: DeleteUserModalDefaultProps,
  title: SubmoduleTitleDefaultProps,
  wrapper: SubmoduleWrapperDefaultProps,
  container: SubmoduleContainerDefaultProps,
  defaultSearch: defaults.searchParams,
  accountTypes: defaults.accountTypes,
  orderByList: defaults.orderBy,
  defaultValues: {
    username: "",
    organization: "",
    type: "",
    orderBy: "",
    orderDir: "",
  },
  /**
   * Used in AccountSettingsPage
   * @see defaultProps.paths
   */
  path: "/users",
};

/**
 * Displays the component
 */
const AccountSettingsUsers = (props) => {
  const {
    title,
    wrapper,
    container,
    form,
    input,
    loadingText,
    loadingBackdrop,
    defaultValues,
    usersTable,
    addUserModal,
    editUserModal,
    deleteUserModal,
    button,
    accountTypes,
    defaultSearch,
    orderByList,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global user
   */
  const { user, setUser } = useUser();

  /**
   * Handles searching for users
   */
  const searchUsers = async (data) => {
    const users = await apiClient.post("/users/search", data);

    if (users) {
      const { data } = users;

      const {
        items,
        models,
        page_count,
        page_size,
        order_by,
        order_dir,
        total: totalItems,
      } = data;

      /**
       * Resets the states
       */
      setBackdropLoading(false);
      setLoading(false);
      setUpdated(false);

      /**
       * Updates the users array
       */
      setUser((prevState) => {
        return { ...prevState, users: items };
      });

      /**
       * Updates the search models and the inputs
       */
      setModels(models);
      setInputs((prevState) => {
        return { ...prevState, orderBy: order_by, orderDir: order_dir };
      });

      /**
       * Updates the pagination related states
       */
      setPageCount(page_count);
      setPageSize(page_size);
      setTotal(totalItems);
    }
  };

  /**
   * Gets the tablet breakpoint
   */
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Initializes the search models
   */
  const [models, setModels] = useState([]);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);
  const [backdropLoading, setBackdropLoading] = useState(true);

  /**
   * Initializes the state related to the Pagination
   */
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useLocalStorage("usersViewPageSize");
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the state that holds the options for the select type search models
   */
  const [organizations, setOrganizations] = useState([]);
  const [accountTypesList, setAccountTypesList] = useState([]);
  const [orderByOptions, setOrderByOptions] = useState([]);

  /**
   * Initializes the modal state
   */
  const [modalState, setModalState] = useState({
    addUser: false,
    editUser: false,
    deleteUser: false,
  });

  /**
   * Initializes the updated flag - used for retriggering the search when needed
   */
  const [updated, setUpdated] = useState(false);

  /**
   * Initializes the reset filters flag
   */
  const [filtersReset, setFiltersReset] = useState(false);

  /**
   * Initializes the state that hold the data for the modals
   */
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});

  /**
   * Handles opening the edit modal
   */
  const openEdit = (props) => setEditData(props);

  /**
   * Handles opening the delete modal
   */
  const openDelete = (props) => setDeleteData(props);

  /**
   * Handles Opening the modal
   */
  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: true };
    });
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = (type) => {
    setModalState((prevState) => {
      return { ...prevState, [type]: false };
    });
  };

  /**
   * Defines the add organization modal handlers
   */
  const openAddUser = () => handleOpenModal("addUser");
  const closeAddUser = () => handleCloseModal("addUser");

  /**
   * Defines the edit User modal handlers
   */
  const openEditUser = () => handleOpenModal("editUser");
  const closeEditUser = () => handleCloseModal("editUser");

  /**
   * Defines the delete User modal handlers
   */
  const openDeleteUser = () => handleOpenModal("deleteUser");
  const closeDeleteUser = () => handleCloseModal("deleteUser");

  /**
   * Checks if the object is empty
   */
  const isEmpty = (obj) => Object.keys(obj).length < 1;

  /**
   * Handles the submit
   */
  const onSubmit = (inputs) => {
    setLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: inputs.orderBy,
      order_dir: inputs.orderDir,
      page_size: pageSize,
      page_count: 1,
    };

    searchUsers(data);
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
  };

  const { inputs, setInputs, resetInputs, handleSubmit, handleInputChange } =
    useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { username, organization, type, orderBy, orderDir } = inputs;

  /**
   * Handles updating the reset filters flag
   */
  const handleFiltersReset = () => setFiltersReset(true);

  /**
   * Handles the page size change
   */
  const handleChangeRowsPerPage = (e) => {
    setBackdropLoading(true);
    const data = {
      models: mapInputsToModels(inputs),
      order_by: inputs.orderBy,
      order_dir: inputs.orderDir,
      page_size: e.target.value,
      page_count: 1,
    };

    searchUsers(data);
  };

  /**
   * Handles mapping the inputs to the models array
   */
  const mapInputsToModels = (inputs) => {
    return [
      {
        label: "username",
        field: "username",
        type: "like",
        order: 1,
        options: [],
        selected: inputs.username,
      },
      {
        label: "Type",
        field: "type",
        type: "dropdown",
        order: 1,
        options: [],
        selected: inputs.type,
      },
      {
        label: "Organization",
        field: "organization_id",
        type: "dropdown",
        order: 1,
        options: [],
        selected: inputs.organization,
      },
    ];
  };

  /**
   * Handles getting the default search data;
   */
  const getDefaultSearch = () => {
    const data = {
      ...defaultSearch,
      page_size: pageSize || defaultSearch.page_size,
      page_count: pageCount || 1,
    };
    return data;
  };

  /**
   * Updates the search params with the default data
   */
  const handleDefaultSearch = () => {
    const data = getDefaultSearch();
    searchUsers(data);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    resetInputs();
    setModels(defaultSearch.models);
    handleDefaultSearch();
  };

  /**
   * Handles changing the page of the pagination
   */
  const handlePageChange = (e, page) => {
    if (page < 1) return;
    if (page === 1 && pageCount === 1) return;
    if (user.users && user.users.length < 1 && pageCount === 0) return;
    setBackdropLoading(true);

    /**
     * Defines the request data
     */
    const data = {
      models,
      order_by: orderBy,
      order_dir: orderDir,
      page_size: pageSize,
      page_count: page,
    };

    searchUsers(data);
    window.scrollTo(0, 0);
  };

  /**
   * Makes the initial search
   */
  useEffect(() => {
    setModels(defaultSearch.models);
    handleDefaultSearch();
    // eslint-disable-next-line
  }, []);

  /**
   * Handles making a search when updated is triggered
   */
  useEffect(() => {
    if (updated) {
      setModels(defaultSearch.models);

      /**
       * Defines the request data
       */
      const data = {
        ...defaultSearch,
        page_size: pageSize || defaultSearch.page_size,
        page_count: pageCount || 1,
      };

      searchUsers(data);
    }
    // eslint-disable-next-line
  }, [updated]);

  /**
   * Handles updating the page size with the default if the value can't be found in localstorage
   */
  useEffect(() => {
    if (!pageSize) setPageSize(defaultSearch.page_size);
    // eslint-disable-next-line
  }, [pageSize]);

  /**
   * Handles opening the edit user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(editData)) openEditUser();
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Handles opening the delete user modal if the content is set
   */
  useEffect(() => {
    if (!isEmpty(deleteData)) openDeleteUser();
    // eslint-disable-next-line
  }, [deleteData]);

  /**
   * Handles updating the organizations search model with the data coming from the API
   */
  useEffect(() => {
    if (user.organizations) {
      const orgs = user.organizations.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      });
      setOrganizations(orgs);
    }
  }, [user]);

  /**
   * Handles updating the options list for the search model of account types and order by
   */
  useEffect(() => {
    if (accountTypes && orderByList) {
      const accTypes = accountTypes.map((type) => {
        return {
          label: t(type),
          value: type,
        };
      });

      const orderBys = orderByList.map((orderBy) => {
        return {
          label: t(orderBy),
          value: orderBy,
        };
      });

      setAccountTypesList(accTypes);
      setOrderByOptions(orderBys);
    }
    // eslint-disable-next-line
  }, [accountTypes, orderByList]);

  useEffect(() => {
    if (filtersReset) {
      setFiltersReset(false);
      resetFilters();
    }
    // eslint-disable-next-line
  }, [filtersReset]);

  return (
    <SubmoduleContainer {...container}>
      <SubmoduleTitle
        {...title}
        icon={<SupervisedUserCircleOutlinedIcon />}
        title={t("titleUsers")}
      />
      <SubmoduleWrapper {...wrapper}>
        <Zoom in={true} timeout={500}>
          <Grid container>
            <Grid item xs={12}>
              <Form {...form} id="search-user" onSubmit={handleSubmit}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Card className={classes.blank}>
                      <CardContent className={classes.cardContent}>
                        <Grid
                          container
                          spacing={isTablet ? 2 : 1}
                          wrap={isTablet ? "wrap" : "nowrap"}
                        >
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                name: "username",
                                value: username,
                                onChange: handleInputChange,
                                maxSize: 70,
                                variant: "outlined",
                                labelClass: classes.label,
                                InputLabelProps: {
                                  className: classes.label,
                                },
                                label: t("usernameLabel"),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "organization",
                                value: organization,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                variant: "outlined",
                                label: t("organizationLabel"),
                                options: organizations,
                                optionLabel: "label",
                                optionValue: "value",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "type",
                                value: type,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                label: t("typeLabel"),
                                variant: "outlined",
                                options: accountTypesList,
                                optionLabel: "label",
                                optionValue: "value",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "orderBy",
                                value: orderBy,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                label: t("orderByLabel"),
                                variant: "outlined",
                                options: orderByOptions,
                                optionLabel: "label",
                                optionValue: "value",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2} xl={2}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                name: "orderDir",
                                value: orderDir,
                                onChange: handleInputChange,
                                labelClass: classes.label,
                                label: t("orderDirLabel"),
                                variant: "outlined",
                                options: [
                                  { label: t("asc"), value: "asc" },
                                  { label: t("desc"), value: "desc" },
                                ],
                                optionLabel: "label",
                                optionValue: "value",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} lg={3} xl={2}>
                            <div className={classes.btnContainer}>
                              <Button
                                {...button}
                                type="button"
                                variant="filled"
                                title={t("undo")}
                                onClick={resetFilters}
                                className={classes.undoBtn}
                              >
                                <UndoIcon />
                              </Button>
                              <Button
                                {...button}
                                type="submit"
                                variant="filled"
                                onClick={handleSubmit}
                                className={classes.submitBtn}
                              >
                                <LoadingText
                                  {...loadingText}
                                  size={22}
                                  loading={loading}
                                  text={t("submitSearch")}
                                  icon={<SearchIcon />}
                                />
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </Zoom>
        <Grid container>
          <UsersTable
            {...usersTable}
            handleAdd={openAddUser}
            handleEdit={openEdit}
            handleDelete={openDelete}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleFiltersReset={handleFiltersReset}
            rowsPerPage={pageSize}
            page={pageCount}
            count={total}
          />
        </Grid>
        <LoadingBackdrop {...loadingBackdrop} open={backdropLoading} />
        <AddUserModal
          {...addUserModal}
          open={modalState.addUser}
          closeModal={closeAddUser}
          organizations={organizations}
          types={accountTypesList}
          defaultSearch={defaultSearch}
        />
        <EditUserModal
          {...editUserModal}
          open={modalState.editUser}
          closeModal={closeEditUser}
          editData={editData}
          setUpdated={setUpdated}
          organizations={organizations}
          types={accountTypesList}
        />
        <DeleteUserModal
          {...deleteUserModal}
          open={modalState.deleteUser}
          closeModal={closeDeleteUser}
          deleteData={deleteData}
          setUpdated={setUpdated}
        />
      </SubmoduleWrapper>
    </SubmoduleContainer>
  );
};

AccountSettingsUsers.propTypes = propTypes;
AccountSettingsUsers.defaultProps = defaultProps;

export default AccountSettingsUsers;
export {
  propTypes as AccountSettingsUsersPropTypes,
  defaultProps as AccountSettingsUsersDefaultProps,
};

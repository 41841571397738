/**
 * Checks if 2 values are different
 */
const areDifferent = (a, b) => {
  const item1 = a ? a : "";
  const item2 = b ? b : "";
  return item1 !== item2;
};

/**
 * Checks if 2 arrays are equal
 */
const areArraysDifferent = (a, b) => {
  if (a.length !== b.length) return true;
  for (let i = a.length; i--; ) {
    if (a[i].name.toString() !== b[i].name.toString()) return true;
  }
  return false;
};

/**
 * Handles getting the organization ID by using the client name
 */
const getOrgIDByName = (value, clients) => {
  if (clients.length < 1) return;
  if (!value) return;

  const client = clients.find((client) => client.name === value);

  return client ? client.id : "-";
};

const getOrgName = (value, clients) => {
  if (clients.length < 1) return;

  const client = clients.find((client) => client.id === value);
  return client.name;
};

/**
 * Handles checking if an object has any data
 */
const objectHasData = (data) => Object.keys(data).length > 0;

/**
 * Checks if the work oder data has been modified
 */
const isWorkOrderDataChanged = (props) => {
  const { workOrder, inputs, workersArr, clientNames } = props;
  const {
    client_name,
    delegate_name,
    organization_client_id,
    description,
    workers,
    car_type,
  } = workOrder;

  const carTypeID = car_type ? car_type.id : "";

  return (
    areDifferent(
      getOrgIDByName(inputs.organization_client_id, clientNames),
      organization_client_id
    ) ||
    areDifferent(inputs.delegate_name, delegate_name) ||
    areDifferent(inputs.type, carTypeID) ||
    areDifferent(inputs.client_name, client_name) ||
    areDifferent(inputs.description, description) ||
    areArraysDifferent(workers, workersArr)
  );
};

/**
 * Checks if the car data has been modified
 */
const isCarDataChanged = (props) => {
  const { workOrder, inputs } = props;
  const { car } = workOrder;

  return (
    areDifferent(inputs.plate_number, car.plate_number) ||
    areDifferent(inputs.make, car.make) ||
    areDifferent(inputs.model, car.model)
  );
};

/**
 * Checks if the tyres data has been modified
 */
const isTyresDataChanged = (props) => {
  const { workOrder, inputs } = props;
  const { tyre_service, work_order_type } = workOrder;

  return (
    areDifferent(inputs.car_km, tyre_service.car_km) ||
    areDifferent(inputs.new_tyre, tyre_service.new_tyre) ||
    areDifferent(inputs.tyre_width, tyre_service.tyre_width) ||
    areDifferent(inputs.tyre_height, tyre_service.tyre_height) ||
    areDifferent(inputs.tyre_rim, tyre_service.tyre_rim) ||
    areDifferent(inputs.tyre_pressure, tyre_service.tyre_pressure) ||
    areDifferent(inputs.tyre_waste, tyre_service.tyre_waste) ||
    areDifferent(inputs.bolt_torque, tyre_service.bolt_torque) ||
    (work_order_type.name === "Service Auto" &&
      areDifferent(inputs.manufacturing_year, tyre_service.manufacturing_year))
  );
};

/**
 * Handles building the data object
 * Uses only the allowed keys if they have any values
 */
const handleBuild = (inputs, initData, allowedKeys) => {
  const data = initData;
  const keys = Object.keys(inputs);
  const filteredInputs = keys.filter((key) => allowedKeys.includes(key));

  for (let i = 0; i < keys.length; i++) {
    if (filteredInputs.includes(keys[i]) && !!inputs[keys[i]]) {
      data[keys[i]] = inputs[keys[i]];
    }
  }
  return { data };
};

/**
 * Converts an object's value to a string
 */
const convertToString = (data, key) => (data[key] = data[key].toString());

/**
 * Converts an object's value to an integer
 */
const convertToInt = (data, key) => (data[key] = parseInt(data[key]));

/**
 * Handles building the tyres data object
 */
const buildTyresData = (inputs, workOrder) => {
  const { work_order_type } = workOrder;

  /**
   * Defines the allowed keys
   */
  const allowedKeys = [
    "car_km",
    "tyre_width",
    "tyre_height",
    "tyre_rim",
    "tyre_pressure",
    "bolt_torque",
    "tyre_waste",
  ];

  if (work_order_type.name === "Service Auto")
    allowedKeys.push("manufacturing_year");

  /**
   * Initializes the data that is required
   */
  const initData = { new_tyre: inputs.new_tyre };

  /**
   * Gets the built data object
   */
  const { data } = handleBuild(inputs, initData, allowedKeys);

  /**
   * Handles edge cases
   */
  if (data["tyre_waste"]) convertToString(data, "tyre_waste");
  if (data["manufacturing_year"]) convertToInt(data, "manufacturing_year");

  return data;
};

/**
 * Handles building the car data object
 */
const buildCarData = (inputs) => {
  /**
   * Defines the allowed keys
   */
  const allowedKeys = ["plate_number", "make", "model"];

  /**
   * Initializes the data
   */
  const initData = {};

  /**
   * Gets the built data object
   */
  const { data } = handleBuild(inputs, initData, allowedKeys);

  return data;
};

/**
 * Handles building the work order data object
 */
const buildWorkOrderData = (props) => {
  /**
   * Gets the data properties
   */
  const { inputs, initData, clientNames } = props;

  /**
   * Defines the allowed keys
   */
  const allowedKeys = [
    "client_name",
    "organization_client_id",
    "delegate_name",
    "description",
    "car_type_id",
  ];

  /**
   * Gets the built data object
   */
  const { data } = handleBuild(inputs, initData, allowedKeys);

  /**
   * Gets the organization_id
   */
  const orgID = getOrgIDByName(inputs.organization_client_id, clientNames);

  /**
   * Handles edge cases
   */
  if (inputs.type) data["car_type_id"] = inputs.type;
  if (orgID) data["organization_client_id"] = orgID;

  return data;
};

/**
 * Checks if any of the data sections have changed
 */
const checkIfDataChanged = (data) => {
  /**
   * Checks if the data sections have been modified
   */
  const workOrderDataChanged = isWorkOrderDataChanged(data);
  const carDataChanged = isCarDataChanged(data);
  const tyresDataChanged = isTyresDataChanged(data);
  const hasChanged = workOrderDataChanged || carDataChanged || tyresDataChanged;

  return {
    hasChanged,
    workOrderDataChanged,
    carDataChanged,
    tyresDataChanged,
  };
};

/**
 * Handles building the data table to show the uneditable data of the work order
 */
const buildDataTable = (dataSeed, translator) => {
  const { uuid, finished, organization_client, work_order_type } = dataSeed;

  /**
   * Gets the translator function
   */
  const { translate: t } = translator;

  /**
   * Defines the table data
   */
  const dataTable = [
    {
      label: t("orderID"),
      value: uuid,
      render: true,
    },
    {
      label: t("updatedAt"),
      value: finished,
      render: true,
    },
    {
      label: t("clientType"),
      value: t(organization_client ? organization_client.type : "-"),
      render: true,
    },
    {
      label: t("workOrderType"),
      value: work_order_type ? work_order_type.name : "-",
      render: true,
    },
  ];

  return dataTable;
};

/**
 * Sets the inputs state using the data from the work order
 */
const initializeInputs = (prevState, workOrder, clientNames) => {
  /**
   * Gets the work order data
   */
  const {
    client_name,
    organization_client_id,
    delegate_name,
    description,
    car,
    car_type,
    tyre_service: tyreService,
    workers,
  } = workOrder;

  /**
   * Handles formatting the input
   */
  const formatInput = (input) => {
    const isBool = typeof input === "boolean";
    if (isBool) return input ? input : false;
    return input ? input : "";
  };

  const tyre_service = tyreService ? tyreService : {};

  return {
    ...prevState,
    organization_client_id: organization_client_id
      ? getOrgName(organization_client_id, clientNames)
      : "",
    client_name: formatInput(client_name),
    delegate_name: formatInput(delegate_name),
    type: formatInput(car_type.id),
    plate_number: formatInput(car.plate_number),
    description: formatInput(description),
    make: formatInput(car.make),
    model: formatInput(car.model),
    car_km: formatInput(tyre_service.car_km),
    tyre_height: formatInput(tyre_service.tyre_height),
    tyre_width: formatInput(tyre_service.tyre_width),
    tyre_rim: formatInput(tyre_service.tyre_rim),
    bolt_torque: formatInput(tyre_service.bolt_torque),
    new_tyre: formatInput(tyre_service.new_tyre),
    tyre_waste: formatInput(tyre_service.tyre_waste),
    tyre_pressure: formatInput(tyre_service.tyre_pressure),
    workersArr: workers,
  };
};

const utils = {
  buildTyresData,
  buildCarData,
  buildWorkOrderData,
  buildDataTable,
  objectHasData,
  initializeInputs,
  checkIfDataChanged,
};

export { utils };

import { PropTypes } from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  emailRegex: PropTypes.any,
  urlRegex: PropTypes.any,
  telNumberRegex: PropTypes.any,
  postalCodeRegex: PropTypes.any,
  passwordRegex: PropTypes.shape({
    lowerCase: PropTypes.any,
    upperCase: PropTypes.any,
    number: PropTypes.any,
    special: PropTypes.any,
    minLength: PropTypes.number,
    maxLength: PropTypes.number
  })
};

/**
 * Defines the default props
 * Disabled linting for the regex
 * @see https://eslint.org/docs/rules/no-useless-escape
 */
const defaultProps = {
  // eslint-disable-next-line
  emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line
  urlRegex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  telNumberRegex: /[+-\d()\s]/,
  postalCodeRegex: /^[0-9a-zA-Z\s]*$/,
  passwordRegex: {
    lowerCase: /[a-z]/,
    upperCase: /[A-Z]/,
    number: /[0-9]/,
    special: /[$@$!%*?&]/,
    minLength: 8,
    maxLength: 64
  }
};

/**
 * Defines the main hook
 */
const useValidator = props => {
  const {
    emailRegex,
    urlRegex,
    telNumberRegex,
    passwordRegex,
    postalCodeRegex
  } = defaultProps;

  /**
   * Validation pipeline
   *
   * Returns the first error
   */
  const validate = (...fns) => props => {
    let error;
    for (let i = 0; i < fns.length; i++) {
      error = fns[i](props);
      if (error) break;
    }
    return error;
  };

  const isDate = date => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };

  /**
   * Checks if the input is empty
   */
  const isEmpty = value => {
    if (isDate(value)) return false;

    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0) ||
      (Array.isArray(value) && value.length === 0)
    );
  };

  /**
   * Checks if the input is a valid email
   */
  const isEmail = value => {
    if (!value.match(emailRegex)) return false;
    return true;
  };

  /**
   * Checks if the input is a valid phone number
   */
  const isPhoneNum = value => {
    if (!value.match(telNumberRegex)) return false;
    return true;
  };

  /**
   *  Checks if the input contains digits
   */
  const hasDigits = (value, target) => {
    const isDigit = input => /^\d+$/.test(input);
    let digitCount = 0;

    for (var i = 0; i < value.length; i++) {
      if (isDigit(value[i])) digitCount++;
    }
    if (digitCount < target) return false;
    return true;
  };

  /**
   * Checks if the input is a secure password (weak version)
   */
  const isSecurePassword = value => {
    if (!value.match(passwordRegex.lowerCase)) return false;
  };

  /**
   * Checks if the input is a number
   */
  const isNumber = num => {
    const not_a_number = isNaN(num);
    if (not_a_number) return false;
    return true;
  };

  /**
   * Checks if 2 strings are equal
   */
  const equals = (str1, str2) => {
    return str1 === str2;
  };

  /**
   * Checks if the input is a valid postal code
   */
  const isPostalCode = value => {
    const correctFormat = postalCodeRegex.test(value);
    if (!correctFormat) return false;
    return true;
  };

  /**
   * Checks if the input has a certain length
   */
  const isLength = (data, options) => {
    let { min, max } = options;

    if (min > max)
      throw new Error(
        "You provided a min value that is higher than the max value."
      );
    if (!min) min = 1;
    if (!max) max = 1;
    if (data.length < min) return false;
    if (data.length > max) return false;
    return true;
  };

  /**
   * Checks if the input is a valid url
   */
  const isUrl = url => {
    if (!url.match(urlRegex)) return false;
    return true;
  };

  return {
    validate,
    isEmpty,
    isUrl,
    isEmail,
    isPhoneNum,
    equals,
    isLength,
    isNumber,
    isSecurePassword,
    isPostalCode,
    hasDigits
  };
};

useValidator.propTypes = propTypes;
useValidator.defaultProps = defaultProps;

export default useValidator;

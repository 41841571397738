import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import shortid from "shortid";

/**
 * Material UI Imports
 */
import TextField from "@material-ui/core/TextField";

/**
 * Styles Imports
 */
import { useStyles } from "./InputText.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
  inputClasses: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.any,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  InputLabelProps: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: "text",
  label: "",
  value: "",
  variant: "filled",
  inputClasses: {},
  onChange: () => {},
  name: "textInput",
  error: "",
  required: false,
  className: "",
  placeholder: "",
  margin: "none",
  fullWidth: false,
  autoFocus: false,
  id: "textInput",
  maxSize: 1000,
  disabled: false,
  InputLabelProps: {},
  showHelper: true,
};

/**
 * Displays the component
 */
const InputText = (props) => {
  const {
    type,
    label,
    value,
    variant,
    inputClasses,
    onChange,
    name,
    error,
    required,
    className,
    placeholder,
    margin,
    fullWidth,
    autoFocus,
    id,
    maxSize,
    disabled,
    inputProps,
    InputLabelProps,
    showHelper,
  } = props;

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Creates a ref used for autofocus
   */
  const inputRef = useRef();

  /**
   * Defines the Input Component Props
   */
  const InputProps = { ...inputClasses, ...inputProps };

  /**
   * Defines the base input props
   */
  const baseInputProps = { autoFocus: autoFocus, maxLength: maxSize };

  /**
   * Applies autofocus on the input upon error
   */
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  /**
   * Renders the hidden text if there's no erros or helper messages
   */
  const hiddenText = () => <span className={classes.hidden}>error_msg</span>;

  /**
   * Gets the helper text/or error
   */
  const getHelperText = () => (error ? error : hiddenText());
  const helperText = getHelperText();

  const getTextFieldProps = () => {
    const props = {
      id: `${id}-${shortid.generate()}`,
      disabled,
      inputRef,
      className,
      name,
      label,
      placeholder,
      variant,
      margin,
      error: error ? true : false,
      autoFocus,
      type,
      InputProps,
      inputProps: baseInputProps,
      InputLabelProps,
      value: value ? value : "",
      fullWidth,
      onChange,
      required,
    };

    if (showHelper) props["helperText"] = helperText;

    return props;
  };

  return <TextField {...getTextFieldProps()} />;
};

InputText.propTypes = propTypes;
InputText.defaultProps = defaultProps;

export default InputText;
export {
  propTypes as InputTextPropTypes,
  defaultProps as InputTextDefaultProps,
};

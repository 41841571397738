/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 660,
    padding: "0 24px",
  },
  modulesContainer: {
    margin: 0,
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
  },
  choices: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  clientButton: {
    ...theme.mixins.submitBtn,
    borderRadius: 3,
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    margin: "0.5rem",
  },
  warehouseButton: {
    ...theme.mixins.submitBtn,
    borderRadius: 3,
    color: theme.palette.common.white,
    margin: "0.5rem",
  },
}));

export { useStyles };

/**
 * Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
    fieldDefault: {
        ...theme.mixins.flex.column,
        flex: 1,
        "& .MuiInputBase-input": {
            color: theme.palette.common.black
        }
    },
    icon: {
        color: "#707070"
    },
    errorColor: {
        color: theme.palette.error.main
    },
    passwordIconBtn: {
        padding: 0
    },
    hidden: {
        visibility: "hidden"
    }
}));

export { useStyles };

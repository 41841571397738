import React from "react";
import PropTypes from "prop-types";

/**
 * Component Imports
 */
import LoginForm, {
  LoginFormDefaultProps,
  LoginFormPropTypes,
} from "../LoginForm";

/**
 * i18n Imports
 */
import { useTranslation } from "react-i18next";

/**
 *  Material UI Imports
 */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";

/**
 * Styles Imports
 */
import { useStyles } from "./LoginPage.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  loginForm: PropTypes.shape(LoginFormPropTypes),
  path: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  loginForm: LoginFormDefaultProps,
  path: "/",
};

/**
 * Displays the component
 */
const LoginPage = (props) => {
  const { loginForm } = props;

  /**
   * Handles the translations
   */
  const { t } = useTranslation("LanguageProvider");

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  return (
    <Grid container item alignContent="flex-start">
      <div className={classes.formContainer}>
        <Typography className={classes.title} color="textSecondary">
          {t("titleLogin")}
        </Typography>
        <Zoom in={true} timeout={500}>
          <Grid container justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className={classes.form}
            >
              <div className={classes.messageContainer}>
                <Typography className={classes.title} color="textSecondary">
                  {t("titleLogin")}
                </Typography>
                <LoginForm {...loginForm} />
              </div>
            </Grid>
          </Grid>
        </Zoom>
      </div>
    </Grid>
  );
};

LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;

export default LoginPage;
export {
  propTypes as LoginPagePropTypes,
  defaultProps as LoginPageDefaultProps,
};

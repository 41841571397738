import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * i18n Imports
 */

import { useTranslation } from "react-i18next";

/**
 * Component Imports
 */

import Modal, {
  ModalDefaultProps,
  ModalPropTypes,
  ModalTitle,
  ModalTitleDefaultProps,
  ModalTitlePropTypes,
  ModalContent,
  ModalContentPropTypes,
  ModalContentDefaultProps,
  ModalActions,
  ModalActionsPropTypes,
  ModalActionsDefaultProps,
} from "../Modal";
import Form, { FormDefaultProps, FormPropTypes } from "../Form";
import Input, { InputPropTypes, InputDefaultProps } from "../Input";
import Button, { ButtonDefaultProps, ButtonPropTypes } from "../Button";
import ErrorMessages, {
  ErrorMessagesDefaultProps,
  ErrorMessagesPropTypes,
} from "../ErrorMessages";
import LoadingText, {
  LoadingTextDefaultProps,
  LoadingTextPropTypes,
} from "../LoadingText";

/**
 *  Material UI Imports
 */
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

/**
 * Hooks
 */
import { useForm, useMessage, useApiClient } from "../../hooks";

/**
 * Validations Import
 */
import Validator from "./EditProductConditionModal.validations";

/**
 * Styles Imports
 */
import { useStyles } from "./EditProductConditionModal.styles";
import { Typography } from "@material-ui/core";

/**
 * Defines the prop types
 */
const propTypes = {
  modal: PropTypes.shape(ModalPropTypes),
  modalTitle: PropTypes.shape(ModalTitlePropTypes),
  modalContent: PropTypes.shape(ModalContentPropTypes),
  modalActions: PropTypes.shape(ModalActionsPropTypes),
  button: PropTypes.shape(ButtonPropTypes),
  input: PropTypes.shape(InputPropTypes),
  form: PropTypes.shape(FormPropTypes),
  loadingText: PropTypes.shape(LoadingTextPropTypes),
  errorMessages: PropTypes.shape(ErrorMessagesPropTypes),
  defaultValues: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    condition: PropTypes.string,
    new_price: PropTypes.number,
  }),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  modal: ModalDefaultProps,
  modalTitle: ModalTitleDefaultProps,
  modalContent: ModalContentDefaultProps,
  modalActions: ModalActionsDefaultProps,
  button: ButtonDefaultProps,
  input: InputDefaultProps,
  form: FormDefaultProps,
  loadingText: LoadingTextDefaultProps,
  errorMessages: ErrorMessagesDefaultProps,
  defaultValues: {
    field: "",
    value: "",
    condition: "",
    new_price: 0,
  },
  open: false,
  closeModal: () => {},
};

/**
 * Displays the component
 */
const EditProductConditionModal = (props) => {
  const {
    modal,
    modalTitle,
    modalContent,
    modalActions,
    open,
    closeModal,
    defaultValues,
    form,
    input,
    button,
    loadingText,
    errorMessages,
    id,
    editData,
    setEditData,
    fields,
    conditions,
    setConditions,
  } = props;

  /**
   * Handles the translations
   */

  const { t } = useTranslation("LanguageProvider");

  /**
   * Triggers the modal to be on full screen on lower screen resolutions
   */
  const theme = useTheme();
  const triggerFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Gets the component styles
   */
  const classes = useStyles();

  /**
   * Gets the api client
   */
  const { apiClient } = useApiClient({ withCredentials: true });

  /**
   * Gets the global message dispatcher
   */
  const { dispatchMessage } = useMessage();

  /**
   * Initializes the render condition
   */
  const [renderCondition, setRenderCondition] = useState(false);

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the ready flag
   */
  const [ready, setReady] = useState(false);

  /**
   * Defines the conditionals
   */
  const conditionals = [
    { label: t("="), value: "=" },
    { label: t("<"), value: "<" },
    { label: t(">"), value: ">" },
    { label: t(">="), value: ">=" },
    { label: t("<="), value: "<=" },
  ];

  /**
   * Handles the submit
   */
  const onSubmit = () => {
    setLoading(true);
    setReady(true);
  };

  /**
   *  Sets the validation translator function
   */
  const validatorConfig = {
    translator: t,
  };

  /**
   * Configures the useForm hook
   */
  const useFormConfig = {
    defaultValues,
    submitFn: onSubmit,
    validator: Validator(validatorConfig),
    autoFocus: true,
  };

  const {
    inputs,
    errors,
    hasErrors,
    resetInputs,
    setInputs,
    handleSubmit,
    handleInputChange,
    getAutoFocus,
  } = useForm(useFormConfig);

  /**
   * Gets the inputs and errors
   */
  const { field, value, condition, new_price } = inputs;

  const {
    field: fieldError,
    value: valueError,
    condition: conditionError,
    new_price: new_priceError,
  } = errors;

  /**
   * Handles updating a product condition by id
   */
  const updateProductCondition = async (data, id) => {
    try {
      const updatedCondition = await apiClient.put(
        `/product-conditions/${id}`,
        data
      );
      if (updatedCondition) {
        const { data } = updatedCondition;
        /**
         * Resets the states
         */
        setLoading(false);
        setReady(false);

        /**
         * Handles dispatching the success message
         */
        dispatchMessage({
          icon: false,
          delay: 600,
          message: t("successMessage"),
        });

        /**
         * Gets the previous conditions
         */
        const prevConditions = conditions.filter(
          (condition) => condition.id !== id
        );

        /**
         * Builds the new conditions
         */
        const newConditions = [...prevConditions, { ...data }];
        setConditions(newConditions);

        handleCloseModal();
      }
    } catch (error) {
      /**
       * Resets the state
       */
      setLoading(false);
      setReady(false);

      /**
       * Handles dispatching the error message
       */
      dispatchMessage({
        icon: false,
        severity: "error",
        component: <ErrorMessages {...errorMessages} error={error} />,
      });
    }
  };

  /**
   * Handles closing the modal
   */
  const handleCloseModal = () => {
    resetInputs();
    setEditData({});
    setReady(false);
    closeModal();
  };

  /**
   * Handles rendering the helper text
   */
  const renderHelperText = () => {
    return (
      renderCondition &&
      !hasErrors(errors) && (
        <Typography className={classes.helperText}>
          {t("if")} {t(field)} {t("is")}
          {t(`${condition}_label`)} {value}
          {t("then")}
          {t("new_priceBecomes")} {new_price}
        </Typography>
      )
    );
  };

  /**
   * Updates the inputs
   */
  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      setInputs((prevState) => {
        return {
          ...prevState,
          field: editData.field,
          value: editData.value,
          condition: editData.condition,
          new_price: editData.new_price ? editData.new_price : "0",
        };
      });
      if (
        editData.field.length > 0 &&
        editData.value.length > 0 &&
        editData.condition.length > 0 &&
        editData.new_price.length > 0
      ) {
        setRenderCondition(true);
      }
    }
    // eslint-disable-next-line
  }, [editData]);

  /**
   * Updates the render condition flag
   */
  useEffect(() => {
    if (!!field && !!value && !!condition && !!new_price) {
      setRenderCondition(true);
    } else {
      setRenderCondition(false);
    }
    // eslint-disable-next-line
  }, [inputs]);

  /**
   * Handles making the api call if ready
   */
  useEffect(() => {
    if (ready) {
      const data = {
        field,
        value,
        condition,
        new_price,
      };

      updateProductCondition(data, id);
    }
    // eslint-disable-next-line
  }, [ready]);

  return (
    <Fragment>
      <Modal
        {...modal}
        fullScreen={triggerFullScreen}
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        keepMounted={true}
        scroll="paper"
      >
        <ModalTitle
          {...modalTitle}
          title={t("titleEditCondition")}
          onClick={handleCloseModal}
        />
        <ModalContent {...modalContent} className={classes.modalContent}>
          <Grid item container xs={12}>
            <Form
              {...form}
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.form}>
                  <Card className={classes.blank}>
                    <CardContent>
                      <Grid container>
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={12} md={3}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "field",
                                required: true,
                                name: "field",
                                value: field,
                                onChange: handleInputChange,
                                label: t("fieldLabel"),
                                options: fields,
                                optionLabel: "label",
                                optionValue: "value",
                                error: fieldError,
                                autoFocus: getAutoFocus().field,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Input
                              className={classes.selectField}
                              type="select"
                              inputSelect={{
                                id: "condition",
                                required: true,
                                name: "condition",
                                value: condition,
                                onChange: handleInputChange,
                                label: t("conditionLabel"),
                                options: conditionals,
                                optionLabel: "label",
                                optionValue: "value",
                                error: conditionError,
                                autoFocus: getAutoFocus().condition,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="text"
                              className={classes.field}
                              inputText={{
                                id: "value",
                                name: "value",
                                value: value,
                                required: true,
                                onChange: handleInputChange,
                                maxSize: 5,
                                variant: "standard",
                                label: t("valueLabel"),
                                error: valueError,
                                autoFocus: getAutoFocus().value,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Input
                              {...input}
                              type="numeric"
                              className={classes.field}
                              inputNumeric={{
                                required: true,
                                id: "new_price",
                                name: "new_price",
                                value: new_price,
                                variant: "standard",
                                onChange: handleInputChange,
                                label: t("new_priceLabel"),
                                error: new_priceError,
                                autoFocus: getAutoFocus().new_price,
                              }}
                            />
                          </Grid>
                          {renderHelperText()}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </ModalContent>
        <ModalActions {...modalActions} onClick={handleCloseModal}>
          <Button
            {...button}
            type="button"
            variant="filled"
            onClick={handleSubmit}
            disabled={!renderCondition}
            className={classes.submitBtn}
          >
            <LoadingText
              {...loadingText}
              loading={loading}
              text={t("submitSave")}
            />
          </Button>
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

EditProductConditionModal.propTypes = propTypes;
EditProductConditionModal.defaultProps = defaultProps;

export default EditProductConditionModal;
export {
  propTypes as EditProductConditionModalPropTypes,
  defaultProps as EditProductConditionModalDefaultProps,
};

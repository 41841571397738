/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "5rem",
    marginBottom: "5rem",
    marginLeft: theme.drawerWidth,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0
    }
  },
  title: {
    ...theme.mixins.submoduleTitle,
    "& svg": {
      marginRight: "0.5rem",
      color: "#348cbf",
      fontSize: "2rem",
      width: "1.2em",
      height: "1.2em"
    }
  },
  wrapper: {
    margin: "0 2rem",
    borderTop: "none",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh"
  },
  blank: {
    boxShadow: "none",
    marginTop: "1rem"
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: 0,
      padding: 8
    }
  },
  field: theme.mixins.searchField,
  label: theme.mixins.searchFieldLabel,
  selectField: theme.mixins.searchSelect,
  btnContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.25rem"
  },
  undoBtn: {
    padding: "0.25rem",
    minWidth: 0,
    borderRadius: 0,
    background: theme.palette.button.light,
    color: theme.palette.secondary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    },
    margin: "0 0 0.25em",
    marginRight: "0.25rem"
  },
  submitButton: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 0,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.hover.main,
      boxShadow: "none"
    }
  },
  addNewBtnContainer: {
    marginLeft: "-0.5rem"
  },
  box: {
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.sidebar.main,
    margin: "1rem 0",
    padding: "0.5rem 1rem",
    paddingBottom: "1rem",
    position: "relative"
  },
  displayOption: {
    position: "absolute",
    right: 0,
    bottom: -65,
    display: "flex",
    flex: 1,
    width: 200
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0"
  },
  list: {
    "& .MuiPaginationItem-page.Mui-selected": {
      background: theme.palette.primary.main,
      color: "#fff"
    },
    "& .MuiPaginationItem-page:hover": {
      background: theme.palette.primary.main,
      color: "#fff"
    }
  }
}));

export { useStyles };

import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * External Imports
 */
import PDFViewer from "pdf-viewer-reactjs";

/**
 * Component Imports
 */
import PDFNavigation, {
  PDFNavigationDefaultProps,
  PDFNavigationPropTypes,
} from "../PDFNavigation";

/**
 *  Material UI Imports
 */
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Styles Imports
 */
import { useStyles } from "./DocumentViewer.styles";

/**
 * Defines the prop types
 */
const propTypes = {
  documentName: PropTypes.string,
  document: PropTypes.any,
  pdfNav: PropTypes.shape(PDFNavigationPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  documentName: "",
  document: "",
  pdfNav: PDFNavigationDefaultProps,
};

/**
 * Displays the component
 */
const DocumentViewer = memo((props) => {
  const { document, documentName, pdfNav } = props;

  const classes = useStyles();

  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");

  useEffect(() => {
    if (document) setDoc(document);
  }, [document]);

  useEffect(() => {
    if (documentName) setDocName(documentName);
  }, [documentName]);

  /**
   * Renders a loader component that will be shown while the pdf is loading
   */
  const PDFLoader = () => {
    return (
      <div className={classes.blank}>
        <CircularProgress size={65} className={classes.fabProgress} />
      </div>
    );
  };

  return doc ? (
    <PDFViewer
      loader={<PDFLoader />}
      navbarOnTop
      css={classes.wrapper}
      document={{ url: doc }}
      navigation={(props) => (
        <PDFNavigation {...pdfNav} {...props} documentName={docName} />
      )}
      canvasCss={classes.canvasCss}
    />
  ) : (
    "Loading"
  );
});

DocumentViewer.propTypes = propTypes;
DocumentViewer.defaultProps = defaultProps;

export default DocumentViewer;
export {
  propTypes as DocumentViewerPropTypes,
  defaultProps as DocumentViewerDefaultProps,
};

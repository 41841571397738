/**
 *  Material UI Imports
 */
import { makeStyles } from "@material-ui/core/styles";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  attendanceIcon: {
    color: theme.palette.common.white,
    marginRight: "0.35rem",
  },
  attendance: {
    padding: "0.25rem 1rem",
    minWidth: 155,
    borderRadius: 0,
    fontWeight: 400,
    height: 30,
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    boxShadow: "none",
    margin: 0,
    "&:hover": {
      background: theme.palette.success.dark,
      boxShadow: "none",
    },
    "&.MuiButton-contained.Mui-disabled": {
      color: "#8a8a8a",
      fontWeight: 800,
      border: "2px solid #a5a5a5",
      cursor: "not-allowed",
      pointerEvents: "auto",
      "& svg": {
        color: "#8a8a8a",
      },
    },
  },
  startAttendance: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
    },
  },
  endAttendance: {
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
  blank: {
    background: "transparent",
    boxShadow: "none",
    minHeight: "60vh",
  },
  selectField: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "normal",
    flex: 1,
    margin: 0,
    backgroundColor: "transparent",
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      height: "0.95rem",
      padding: "9.5px 12px",
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.9rem",
    },
    "& label + .MuiInput-formControl": {
      marginTop: 14,
    },
  },
  label: {
    ...theme.mixins.searchFieldLabel,
    "&.MuiInputLabel-outlined": {
      transform: "translate(10px, 12px) scale(1)",
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: "0 5px",
      fontSize: "0.7rem",
      transform: "translate(12px, -5px) scale(1)",
      background: theme.palette.common.white,
    },
  },
  field: {
    ...theme.mixins.searchField,
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 19,
    },
  },
  table: {
    minWidth: 650,
    background: "#f3f3f3",
    "& .MuiTableCell-head.MuiTableCell-stickyHeader": {
      fontWeight: 800,
      color: "#6f6f6f",
      textAlign: "center",
    },
    "& .MuiTableCell-body": {
      fontWeight: 500,
      color: "#505050",
      textAlign: "center",
    },
  },
  tableContainer: {
    maxHeight: 450,
    minHeight: 450,
    border: "1px solid #e2e2e2",
  },
}));

export { useStyles };
